import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';
import { Link } from 'react-router-dom';

import RecruitFormModel from '../../model/RecruitFormModel';
import { initComma, toQueryString } from '../../util/common';
import { PAGE_SIZE } from '../../constants';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';

const RecruitForm = ({ location, history }) => {
  const params = location.state || queryString.parse(location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    search_text: params.search_text ? params.search_text : '',
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilter] = useState(default_filter.current);
  const [recruitForms, setRecruitForms] = useState([]);
  const [recruitFormsCount, setRecruitFormsCount] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    setFilter(
      Object.keys(params).length > 0
        ? { ...default_filter.current, ...params }
        : default_filter.current,
    );

    RecruitFormModel.getRecruitForms({
      ...params,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ bgd_recruit_form, total_count }) => {
      setRecruitForms(bgd_recruit_form);
      setRecruitFormsCount(total_count);
      setIsLoading(false);
    });

    setIsLoading(false);
  }, [location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    history.push(`?${queryString}`, params);
  };

  const onFilterSubmit = () => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Breadcrumb parent='서비스' title='모집폼 목록' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Col className='text-right mb-4'>
              <Link className={'btn btn-primary btn-pill'} to={'/recruit-form/0'}>
                모집폼 생성
              </Link>
            </Col>

            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={9} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총: <span style={{ color: 'coral' }}>{initComma(recruitFormsCount)}</span>
                          개
                        </p>
                        <Loading isLoading={isLoading} />
                      </Col>
                      <Col md={3} className='text-right'>
                        <FormGroup>
                          <InputGroup>
                            <Input
                              className='form-control'
                              type='text'
                              name='search_text'
                              onChange={onFilterChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onFilterSubmit(e);
                                }
                              }}
                              value={filter_set.search_text}
                              placeholder='제목을 입력하세요.'
                            />
                            <InputGroupAddon
                              addonType='append'
                              style={{ cursor: 'pointer' }}
                              onClick={onFilterSubmit}
                            >
                              <InputGroupText>
                                <i className='icofont icofont-ui-search' />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <div className='table-responsive'>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th style={{ width: '20px' }}>ID</th>
                      <th style={{ width: '70px' }}>제목</th>
                      <th style={{ width: '20px' }}>이벤트 코드</th>
                      <th style={{ width: '20px' }}>생성일</th>
                      <th style={{ width: '20px' }}>지원 시작일</th>
                      <th style={{ width: '20px' }}>지원 종료일</th>
                      <th style={{ width: '20px' }}>강의 등록 시작일</th>
                      <th style={{ width: '20px' }}>강의 등록 종료일</th>
                      <th style={{ width: '20px' }}>결과 발표일</th>
                    </tr>
                  </thead>

                  <tbody>
                    {recruitForms?.map((recruitForm) => {
                      let url = 'recruit-form/' + recruitForm.bgd_recruit_form_id;
                      const onTdClick = () => {
                        history.push(url);
                      };

                      return (
                        <tr key={recruitForm.bgd_recruit_form_id}>
                          <td
                            className='text-center'
                            onClick={onTdClick}
                            role='button'
                            style={{ verticalAlign: 'middle' }}
                          >
                            {recruitForm.bgd_recruit_form_id}
                          </td>

                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div className='f-w-900'>{recruitForm.title}</div>
                          </td>

                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {recruitForm.event_code}
                          </td>

                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(recruitForm.create_at)).format('YYYY-MM-DD')}
                          </td>

                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(recruitForm.apply_start_time)).format('YYYY-MM-DD')}
                          </td>

                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(recruitForm.apply_end_time)).format('YYYY-MM-DD')}
                          </td>

                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(recruitForm.course_register_start_time)).format(
                              'YYYY-MM-DD',
                            )}
                          </td>

                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(recruitForm.course_register_end_time)).format(
                              'YYYY-MM-DD',
                            )}
                          </td>

                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(recruitForm.result_notice_time)).format('YYYY-MM-DD')}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size ? recruitFormsCount / params.size : recruitFormsCount / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RecruitForm;
