import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import { initComma, toQueryString } from '../../util/common';
import LogModel from '../../model/LogModel';
import CustomDateRangeFilter from '../_common/component/CustomDateRangeFilter';
import { PAGE_SIZE } from '../../constants';

const TicketLog = (props) => {
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    search_text: params.search_text ? params.search_text : '',
    order_key: params.order_key ? params.order_key : '',
    date_start: params.date_start ? params.date_start : '',
    date_end: params.date_end ? params.date_end : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const [course_ticket_log_set, setCourseTicketLogSet] = useState([]);
  const [course_ticket_log_count, setCourseTicketLogCountSet] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilter] = useState(default_filter.current);

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    LogModel.getCourseTicketLog({
      ...params,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ course_ticket_log_set, total_count }) => {
      setCourseTicketLogSet(course_ticket_log_set);
      setCourseTicketLogCountSet(total_count);
      setIsLoading(false);
    });
  }, [props.location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onFilterSubmit = (e) => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      order_key: order_key,
      order_type: !filter_set.order_type || filter_set.order_type === 'asc' ? 'desc' : 'asc', // 정렬을 기존 기준 -> "없을 경우 or asc = desc로 변경" / "desc 일 경우 = asc 로 변경"
    };

    // 이전에 정렬한적 있고, 새로운 정렬을 적용.
    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }

    pushHistory(newFilterSet);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    });
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <Breadcrumb parent='프로모션' title='수강코드 사용내역 목록' />

      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <Row>
                  <Col md={4} style={{ margin: 'auto' }}>
                    <p style={{ marginTop: '7px', display: 'inline' }}>
                      총:{' '}
                      <span style={{ color: 'coral' }}>{initComma(course_ticket_log_count)}</span>개
                    </p>
                    <Loading isLoading={isLoading} />
                  </Col>
                  <CustomDateRangeFilter
                    style={[
                      { col: 2, label_disable: true },
                      { label_disable: true },
                      { label_disable: true },
                    ]}
                    setFilter={setFilter}
                    filter_set={filter_set}
                  />
                  <Col md={3} className='text-right'>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          className='form-control'
                          type='text'
                          name='search_text'
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              onFilterSubmit(e);
                            }
                          }}
                          value={filter_set.search_text}
                          placeholder="'수강코드' 명/코드, '유저정보'를 입력하세요."
                        />
                        <InputGroupAddon
                          addonType='append'
                          style={{ cursor: 'pointer' }}
                          onClick={onFilterSubmit}
                        >
                          <InputGroupText>
                            <i className='icofont icofont-ui-search' />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <div className='table-responsive'>
                  <Table>
                    <thead>
                      <tr style={{ textAlign: 'center' }}>
                        <th style={{ width: '70px' }}>ID</th>
                        <th style={{ textAlign: 'left' }}>수강코드/수강코드텍스트</th>
                        <th style={{ width: '162px' }}>사용유저</th>
                        <th
                          style={{ width: '162px' }}
                          className='table-sort-th'
                          onClick={() => {
                            onOrderChange('purchase_info');
                          }}
                        >
                          {filter_set.order_key === 'purchase_info' ? (
                            <span style={{ color: '#ff4c3b' }}>결제ID</span>
                          ) : (
                            <span>결제ID</span>
                          )}
                        </th>
                        <th
                          style={{ width: '150px' }}
                          className='table-sort-th'
                          onClick={() => {
                            onOrderChange('id');
                          }}
                        >
                          {filter_set.order_key === 'id' ? (
                            <span style={{ color: '#ff4c3b' }}>사용일시</span>
                          ) : (
                            <span>사용일시</span>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {course_ticket_log_set.map((course_ticket_log) => {
                        return (
                          <tr key={course_ticket_log.id}>
                            <td className='text-center' style={{ verticalAlign: 'middle' }}>
                              {initComma(course_ticket_log.id)}
                            </td>
                            <td style={{ verticalAlign: 'middle', height: '67px' }}>
                              <Link to={`ticket/${course_ticket_log.course_ticket}`}>
                                <div className={'text-truncate f-w-900'} style={{ width: '400px' }}>
                                  {course_ticket_log.ticket_title}
                                </div>
                              </Link>
                              <div className={'text-truncate'} style={{ width: '400px' }}>
                                {course_ticket_log.course_ticket_ticket_text}
                              </div>
                            </td>
                            <td className='text-center' style={{ verticalAlign: 'middle' }}>
                              <Link
                                to={`/account/${course_ticket_log.account}`}
                                className={'text-truncate'}
                              >
                                {course_ticket_log.account_name}
                              </Link>
                            </td>
                            <td className='text-center' style={{ verticalAlign: 'middle' }}>
                              <Link to={`/sale/${course_ticket_log.purchase_info}`}>
                                {course_ticket_log.purchase_info}
                              </Link>
                            </td>
                            <td className='text-center' style={{ verticalAlign: 'middle' }}>
                              {course_ticket_log.used_time &&
                                moment(new Date(course_ticket_log.used_time)).format(
                                  'YYYY-MM-DD hh:mm:ss',
                                )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </CardHeader>

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size
                      ? course_ticket_log_count / params.size
                      : course_ticket_log_count / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TicketLog;
