import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import {
  apiDatetimeReplace,
  initComma,
  onAirStatus,
  onBooleanBadgeStyle,
  toQueryString,
} from '../../util/common';
import { PAGE_SIZE } from '../../constants';

export const TRIGGER_EVENT_TYPE_PAGE_URL = 1;
export const TRIGGER_MATCH_TYPE_EQUAL = 1;
export const TRIGGER_MATCH_TYPE_REGEX = 2;
export const PLATFORM_TYPE_BEARU = 1;
export const PLATFORM_TYPE_B2B = 2;

const Trigger = (props) => {
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    search_text: params.search_text ? params.search_text : '',
    banner_type: params.banner_type ? params.banner_type : '',
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const triggerEventType = (trigger) => {
    switch (trigger) {
      case TRIGGER_EVENT_TYPE_PAGE_URL:
        return 'URL';
    }
  };

  const triggerMatchType = (trigger) => {
    switch (trigger) {
      case TRIGGER_MATCH_TYPE_EQUAL:
        return '같음';
      case TRIGGER_MATCH_TYPE_REGEX:
        return '정규표현식';
    }
  };

  const platformType = (trigger) => {
    switch (trigger) {
      case PLATFORM_TYPE_BEARU:
        return '베어유';
      case PLATFORM_TYPE_B2B:
        return 'B2B';
    }
  };

  const [trigger_set, setTriggerSet] = useState([]);
  const [trigger_count, setTriggerCountSet] = useState(0);

  const [trigger_event_type_set, setTriggerEventTypeSet] = useState([]);
  const [trigger_match_type_set, setTriggerMatchTypeSet] = useState([]);
  const [platform_type_set, setPlatformTypeSet] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilter] = useState(default_filter.current);

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'trigger_event_type' }).then(({ code_set }) => {
      setTriggerEventTypeSet(code_set);
    });

    CommonModel.staticCode({ code_name: 'trigger_match_type' }).then(({ code_set }) => {
      setTriggerMatchTypeSet(code_set);
    });

    CommonModel.staticCode({ code_name: 'platform_type' }).then(({ code_set }) => {
      setPlatformTypeSet(code_set);
    });
  }, []);

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    CommonModel.getTrigger({
      ...params,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ trigger_set, total_count }) => {
      setTriggerSet(trigger_set);
      setTriggerCountSet(total_count);
      setIsLoading(false);
    });
  }, [props.location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onFilterSubmit = (e) => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      order_key: order_key,
      order_type: !filter_set.order_type || filter_set.order_type === 'asc' ? 'desc' : 'asc', // 정렬을 기존 기준 -> "없을 경우 or asc = desc로 변경" / "desc 일 경우 = asc 로 변경"
    };

    // 이전에 정렬한적 있고, 새로운 정렬을 적용.
    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }

    pushHistory(newFilterSet);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    });
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <Breadcrumb parent='서비스' title='트리거 목록' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Col className='text-right mb-4'>
              <Link className={'btn btn-primary btn-pill'} to={'/trigger/0'}>
                트리거 생성
              </Link>
            </Col>
            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={9} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총: <span style={{ color: 'coral' }}>{initComma(trigger_count)}</span>개
                        </p>
                        <Loading isLoading={isLoading} />
                      </Col>
                      <Col md={3} className='text-right'>
                        <FormGroup>
                          <InputGroup>
                            <Input
                              className='form-control'
                              type='text'
                              name='search_text'
                              onChange={onFilterChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onFilterSubmit(e);
                                }
                              }}
                              value={filter_set.search_text}
                              placeholder='트리거명을 입력하세요.'
                            />
                            <InputGroupAddon
                              addonType='append'
                              style={{ cursor: 'pointer' }}
                              onClick={onFilterSubmit}
                            >
                              <InputGroupText>
                                <i className='icofont icofont-ui-search' />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <div className='table-responsive'>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th
                        style={{
                          width: '20px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('id');
                        }}
                      >
                        {filter_set.order_key === 'id' ? (
                          <span style={{ color: '#ff4c3b' }}>ID</span>
                        ) : (
                          <span>ID</span>
                        )}
                      </th>
                      <th scope='col' className='text-center col-md-2'>
                        제목
                      </th>
                      <th scope='col' className='text-center col-md-2'>
                        값
                      </th>
                      <th
                        style={{
                          width: '20px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('trigger_event_type');
                        }}
                      >
                        {filter_set.order_key === 'trigger_event_type' ? (
                          <span style={{ color: '#ff4c3b' }}>이벤트 타입</span>
                        ) : (
                          <span>이벤트 타입</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '20px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('trigger_match_type');
                        }}
                      >
                        {filter_set.order_key === 'trigger_match_type' ? (
                          <span style={{ color: '#ff4c3b' }}>매칭 타입</span>
                        ) : (
                          <span>매칭 타입</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '20px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('platform_type');
                        }}
                      >
                        {filter_set.order_key === 'platform_type' ? (
                          <span style={{ color: '#ff4c3b' }}>플랫폼 타입</span>
                        ) : (
                          <span>플랫폼 타입</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '20px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('create_time');
                        }}
                      >
                        {filter_set.order_key === 'create_time' ? (
                          <span style={{ color: '#ff4c3b' }}>생성일</span>
                        ) : (
                          <span>생성일</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '20px',
                        }}
                        onClick={() => {
                          onOrderChange('update_time');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'update_time' ? (
                          <span style={{ color: '#ff4c3b' }}>수정일</span>
                        ) : (
                          <span>수정일</span>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {trigger_set.map((trigger) => {
                      let url = 'trigger/' + trigger.id;
                      const onTdClick = () => {
                        props.history.push(url);
                      };
                      return (
                        <tr key={trigger.id}>
                          <td
                            className='text-center'
                            onClick={onTdClick}
                            role='button'
                            style={{ verticalAlign: 'middle' }}
                          >
                            {trigger.id}
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div>{trigger.title}</div>
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div className={'text-truncate f-w-900'}>{trigger.value}</div>
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div>{triggerEventType(trigger.trigger_event_type)}</div>
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div>{triggerMatchType(trigger.trigger_match_type)}</div>
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div>{platformType(trigger.platform_type)}</div>
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(trigger.create_time)).format('YYYY-MM-DD')}
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(trigger.update_time)).format('YYYY-MM-DD')}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size ? trigger_count / params.size : trigger_count / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Trigger;
