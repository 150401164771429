import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import { initComma, onAirStatus, onBooleanBadgeStyle, toQueryString } from '../../util/common';
import { PAGE_SIZE } from '../../constants';

const Bgd = ({ location, history }) => {
  // 기존 history.push 로는 state를 가져온다 / 직접 url에 값을 때려 박으면 state에 값이 없어서 search에서 가져와야한다 / 이도저도 아닐 경우
  const params = location.state || queryString.parse(location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    search_text: params.search_text ? params.search_text : '',
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilter] = useState(default_filter.current);

  // 빡공단 용
  const [bgds, setBgds] = useState([]);
  const [bgdsCount, setBgdsCount] = useState(0);

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? { ...default_filter.current, ...params }
        : default_filter.current,
    );

    // 빡공단
    CommonModel.getBgd({
      ...params,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ bgd, total_count }) => {
      setBgds(bgd);
      setBgdsCount(total_count);
      setIsLoading(false);
    });
  }, [location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    history.push(`?${queryString}`, params);
  };

  const onFilterSubmit = () => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    });
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <Breadcrumb parent='서비스' title='빡공단 목록' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Col className='text-right mb-4'>
              <Link className={'btn btn-primary btn-pill'} to={'/bgd/0'}>
                빡공단 생성
              </Link>
            </Col>
            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={9} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총: <span style={{ color: 'coral' }}>{initComma(bgdsCount)}</span>개
                        </p>
                        <Loading isLoading={isLoading} />
                      </Col>
                      <Col md={3} className='text-right'>
                        <FormGroup>
                          <InputGroup>
                            <Input
                              className='form-control'
                              type='text'
                              name='search_text'
                              onChange={onFilterChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onFilterSubmit(e);
                                }
                              }}
                              value={filter_set.search_text}
                              placeholder='제목을 입력하세요.'
                            />
                            <InputGroupAddon
                              addonType='append'
                              style={{ cursor: 'pointer' }}
                              onClick={onFilterSubmit}
                            >
                              <InputGroupText>
                                <i className='icofont icofont-ui-search' />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <div className='table-responsive'>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th style={{ width: '20px' }} className='table-sort-th'>
                        <span>ID</span>
                      </th>
                      <th style={{ width: '70px', textAlign: 'center' }}>제목</th>
                      <th scope='col' className='text-center col-md-1'>
                        이벤트 코드
                      </th>
                      <th style={{ width: '20px' }} className='table-sort-th'>
                        <span>생성일</span>
                      </th>
                      <th style={{ width: '20px' }} className='table-sort-th'>
                        <span>활동 시작일</span>
                      </th>
                      <th style={{ width: '20px' }} className='table-sort-th'>
                        <span>활동 종료일</span>
                      </th>
                      <th style={{ width: '20px' }} className='table-sort-th'>
                        <span>참여인원</span>
                      </th>
                      <th style={{ width: '20px' }} className='table-sort-th'>
                        <span>수료율</span>
                      </th>
                      <th style={{ width: '50px' }} className='table-sort-th'>
                        <span>onAir</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {bgds.map((bgd) => {
                      let url = 'bgd/' + bgd.bgd_id;
                      const onTdClick = () => {
                        history.push(url);
                      };
                      return (
                        <tr key={bgd.bgd_id}>
                          <td
                            className='text-center'
                            onClick={onTdClick}
                            role='button'
                            style={{ verticalAlign: 'middle' }}
                          >
                            {bgd.bgd_id}
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div className='f-w-900'>{bgd.bgd_title}</div>
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div>{bgd.event_code}</div>
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(bgd.created_at)).format('YYYY-MM-DD')}
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(bgd.start_date)).format('YYYY-MM-DD')}
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(bgd.end_date)).format('YYYY-MM-DD')}
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {bgd.bgd_details_count}
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div className='f-w-900'>{bgd.mission_complete_percentage}%</div>
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <span>{bgd.bgd_current_status}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size ? bgdsCount / params.size : bgdsCount / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Bgd;
