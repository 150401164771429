// CustomSelect용 회사의 가입/상태 표시값과 value 객체의 배열
export const COMPANY_STATUS_SELECT_OPTIONS = [
  { name: '만료', id: 0 },
  { name: '이용대기', id: 1 },
  { name: '이용중', id: 2 },
];

export const EMPLOYEE_STATUS_USING = 1; // 이용중
export const EMPLOYEE_STATUS_DOSE_NOT_USE = 2; // 비활성
export const EMPLOYEE_STATUS_DELETED = 3; // 삭제됨

// CustomSelect용 직원 상태 표시값과 value 객체의 배열
export const EMPLOYEE_STATUS_SELECT_OPTIONS = [
  { name: '이용중', id: EMPLOYEE_STATUS_USING },
  { name: '비활성', id: EMPLOYEE_STATUS_DOSE_NOT_USE },
];
// CustomSelect용 직원 성별 표시값과 value 객체의 배열
export const EMPLOYEE_GENDER_SELECT_OPTIONS = [
  { name: '남자', id: 'male' },
  { name: '여자', id: 'female' },
];
export const ADD_ACCOUNT_GENDER_SELECT_OPTIONS = [
  {
    name: '선택',
    id: '',
  },
  {
    name: '남자',
    id: 'male',
  },
  {
    name: '여자',
    id: 'female',
  },
];

export const INIT_PAGE_COUNT = 1; // 페이지 넘버링

export const LINE_TAB_EMPLOYEE = 1; // 임직원 목록
export const LINE_TAB_COURSE = 2; // 강의 내역

export const COMPANY_LIST_SHOW_COUNT = 20; // 기업 목록의 한 페이지 당 보여줄 갯수
export const EMPLOYEE_LIST_SHOW_COUNT = 10; // 임직원 목록의 한 페이지 당 보여줄 갯수
export const COURSE_LIST_SHOW_COUNT = 5; // 강의 내역의 한 페이지 당 보여줄 갯수

// CompanyCourseList의 카테고리
export const CATEGORY_COURSE = 1;
export const CATEGORY_PACKAGE = 2;

// 정렬의 기준 토글
export const TOGGLE_SORT_NUM_INIT = 0;
export const TOGGLE_SORT_NUM_FIRST = 1;
export const TOGGLE_SORT_NUM_SECOND = 2;
export const TOGGLE_SORT_NUM_THIRD = 3;

// 강의 날짜 설정 모달 토글
export const TOGGLE_DATE_NUM_ONE_MONTH = 0;
export const TOGGLE_DATE_NUM_THREE_MONTH = 1;
export const TOGGLE_DATE_NUM_SIX_MONTH = 2;
export const TOGGLE_DATE_NUM_ONE_YEAR = 3;

// 강의 날짜 객체
export const COURSE_DATE_OBJECT_INIT_SET = {
  rental_time: null,
  rental_expire_time: null,
};

export const COMPANY_STATUS_EXPIRED = 0; // 기업 가입상태 만료됨
export const COMPANY_STATUS_WAITING = 1; // 기업 가입상태 이용대기
export const COMPANY_STATUS_USING = 2; // 기업 가입상태 이용중

export const COMPANY_INIT_SET = {
  name: null,
  manager_account_name_set: [],
  licensed_employee_count: null,
};

// CompanyList의 thead
export const TABLE_HEAD_COMPANY = [
  {
    title: '가입상태',
    target: 'status_id',
    need_toggle: true,
    toggle_num: TOGGLE_SORT_NUM_FIRST,
    class_name: null,
    style: {
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  {
    title: '기업명',
    target: 'company_name',
    need_toggle: true,
    toggle_num: TOGGLE_SORT_NUM_SECOND,
    class_name: null,
    style: {
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '담당자',
    target: 'name',
    need_toggle: false,
    toggle_num: null,
    class_name: null,
    style: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '이메일(계정)',
    target: 'account_name',
    need_toggle: false,
    toggle_num: null,
    class_name: 'col-md-2',
    style: { whiteSpace: 'nowrap' },
  },
  {
    title: '연락처',
    target: 'phone',
    need_toggle: false,
    toggle_num: null,
    class_name: null,
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '계약시작일',
    target: 'min_rental_time',
    need_toggle: false,
    toggle_num: null,
    class_name: 'col-md-2',
    style: { whiteSpace: 'nowrap' },
  },
  {
    title: '계약종료일',
    target: 'max_rental_expire_time',
    need_toggle: false,
    toggle_num: null,
    class_name: 'col-md-2',
    style: { whiteSpace: 'nowrap' },
  },
  {
    title: '보유강의수',
    target: 'course_count',
    need_toggle: false,
    toggle_num: null,
    class_name: null,
    style: { whiteSpace: 'nowrap' },
  },
];

// EmployeeList의 thead
export const TABLE_HEAD_EMPLOYEE = [
  {
    title: 'ID',
    target: 'id',
    need_toggle: false,
    toggle_num: null,
    class_name: null,
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '이름',
    target: 'name',
    need_toggle: true,
    toggle_num: TOGGLE_SORT_NUM_FIRST,
    class_name: null,
    style: {
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '계정',
    target: 'account_name',
    need_toggle: false,
    toggle_num: null,
    class_name: null,
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '생년월일',
    target: 'birth',
    need_toggle: false,
    toggle_num: null,
    class_name: null,
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '휴대폰',
    target: 'phone',
    need_toggle: false,
    toggle_num: null,
    class_name: null,
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '마지막 수강시간',
    target: 'last_attendance_time',
    need_toggle: true,
    toggle_num: TOGGLE_SORT_NUM_SECOND,
    class_name: null,
    style: {
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '전체 진도율',
    target: 'total_attendance_rate',
    need_toggle: true,
    toggle_num: TOGGLE_SORT_NUM_THIRD,
    class_name: null,
    style: {
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '상태',
    target: 'status_id',
    need_toggle: false,
    toggle_num: null,
    class_name: null,
    style: {
      whiteSpace: 'nowrap',
    },
  },
];

// CourseList의 class thead
export const TABLE_HEAD_COURSE = [
  {
    title: '강의명',
    target: 'course_title',
    need_toggle: false,
    toggle_num: null,
    class_name: 'col-md-6 text-left',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '수강시작일',
    target: 'rental_time',
    need_toggle: false,
    toggle_num: null,
    class_name: 'col-md-2',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '수강만료일',
    target: 'rental_expire_time',
    need_toggle: false,
    toggle_num: null,
    class_name: 'col-md-2',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '평균 진도율',
    target: 'attendance_rate',
    need_toggle: true,
    toggle_num: TOGGLE_SORT_NUM_FIRST,
    class_name: 'col-md-2',
    style: {
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    },
  },
];

// CourseList의 package thead
export const TABLE_HEAD_PACKAGE = [
  {
    title: '패키지명',
    target: 'product_title',
    need_toggle: false,
    toggle_num: null,
    class_name: 'col-md-4 text-left',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '세부강의항목',
    target: 'course_set',
    need_toggle: false,
    toggle_num: null,
    class_name: 'col-md-4 text-center',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '수강시작일',
    target: 'rental_time',
    need_toggle: false,
    toggle_num: null,
    class_name: 'col-md-2',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '수강만료일',
    target: 'rental_expire_time',
    need_toggle: false,
    toggle_num: null,
    class_name: 'col-md-2',
    style: {
      whiteSpace: 'nowrap',
    },
  },
];

// EmployeeCourseDetail의 thead
export const TABLE_HEAD_COURSE_DETAIL = [
  {
    title: '강의',
    target: 'index_name',
    class_name: 'col-md-1 text-center',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '과목명',
    target: 'title',
    class_name: 'col-md-4',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '수강완료',
    target: 'is_viewed',
    class_name: 'col-md-1 text-center',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '수강완료시점',
    target: 'complete_time',
    class_name: 'text-center',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '최초조회',
    target: 'view_time',
    class_name: 'text-center',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: '총 재생시간',
    target: 'total_second',
    class_name: 'text-center',
    style: {
      whiteSpace: 'nowrap',
    },
  },
];

export const TABLE_HEAD_BOOKMARK_USER = [
  {
    title: 'ID',
    target: 'user_account_id',
    class_name: null,
    style: null,
  },
  {
    title: '이름',
    target: 'name',
    class_name: null,
    style: null,
  },
  {
    title: '성별',
    target: 'gender',
    class_name: null,
    style: null,
  },
  {
    title: '휴대폰',
    target: 'phone',
    class_name: null,
    style: null,
  },
  {
    title: '직업',
    target: 'job_class_id',
    class_name: null,
    style: null,
  },
  {
    title: '가입경로',
    target: 'provider_type_id',
    class_name: null,
    style: null,
  },
  {
    title: '계정타입',
    target: 'account_type_id',
    class_name: null,
    style: null,
  },
  {
    title: '계정명',
    target: 'account_name',
    class_name: null,
    style: null,
  },
  {
    title: '북마크 업데이트 날짜',
    target: 'update_time',
    class_name: null,
    style: null,
  },
  {
    title: '구매여부',
    target: 'has_course',
    class_name: null,
    style: null,
  },
];
