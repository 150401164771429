import React from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const Breadcrumbs = (props) => {
  return props.type !== 'b2b' ? (
    <Container fluid={true}>
      <div className='page-header'>
        <Row>
          <Col lg='6' className='main-header'>
            <h2>{props.title}</h2>
          </Col>
          <Col lg='6' className='breadcrumb-right'>
            <Breadcrumb>
              <BreadcrumbItem>
                <i className='pe-7s-home' />
              </BreadcrumbItem>
              <BreadcrumbItem>{props.parent}</BreadcrumbItem>
              <BreadcrumbItem active>{props.title}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      </div>
    </Container>
  ) : (
    <Container fluid={true}>
      <div className='page-header p-3'>
        <Row>
          <Col lg='12' className='breadcrumb-right'>
            <Breadcrumb>
              <BreadcrumbItem
                style={{
                  fontWeight: 'bold',
                }}
              >
                {props.parent}
              </BreadcrumbItem>
              {props.subParent ? (
                !props.subSubParent ? (
                  <>
                    <BreadcrumbItem>{props.subParent}</BreadcrumbItem>
                    <BreadcrumbItem
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {props.title}
                    </BreadcrumbItem>
                  </>
                ) : (
                  <>
                    <BreadcrumbItem>{props.subParent}</BreadcrumbItem>
                    <BreadcrumbItem
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {props.subSubParent}
                    </BreadcrumbItem>
                    <BreadcrumbItem
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {props.title}
                    </BreadcrumbItem>
                  </>
                )
              ) : (
                <BreadcrumbItem active>{props.title}</BreadcrumbItem>
              )}
            </Breadcrumb>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Breadcrumbs;
