import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CustomInput from '../../_common/component/CustomInput';
import CustomSelectInput from '../../_common/component/CustomSelectInput';
import {
  add_ebook_sale,
  set_ebook_sale,
  delete_ebook_sale,
  stored_ebook_sale,
  clear_ebook_sale,
  set_ebook_sale_price,
} from '../../../redux/ebook/sale/action';
import { connect } from 'react-redux';
import CommonModel from '../../../model/CommonModel';
import EbookModel from '../../../model/EbookModel';

import SweetAlert from 'sweetalert2';
import history from '../../../history';
import Loading from '../../_common/Loading';

const Sale = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currency_code_set, SetCurrencyCodeSet] = useState([]);
  const [purchase_type_set, SetPurchaseTypeSet] = useState([]);

  const initData = () => {
    setIsLoading(true);

    if (parseInt(props.obj_id)) {
      EbookModel.getEbookSale(props.obj_id).then(({ sale_set }) => {
        props.stored_ebook_sale(sale_set);
      });
    }

    CommonModel.staticCode({ code_name: 'currency_code_set' }).then(({ code_set }) => {
      SetCurrencyCodeSet(code_set);
    });
    CommonModel.staticCode({ code_name: 'purchase_type_set' }).then(({ code_set }) => {
      SetPurchaseTypeSet(code_set);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    initData();
    return () => {
      props.clear_ebook_sale();
    };
  }, []);

  // const deleteSale = async (e, sale, index) => {
  //     if (!sale.id) {
  //         props.delete_ebook_sale(index);
  //     } else {
  //         SweetAlert.fire({
  //             title: "제거하시겠습니까?",
  //             text: "이미 발급된 수강코드와 연계 되었던 상품이 제거되면 \n더이상 해당 수강코드를 등록 할수 없게 됩니다.\n(수강코드가 모두 소진, 만료된 경우만 제거하세요)\n정말로 제거할까요?",
  //             showCancelButton: true,
  //             cancelButtonText: "cancel",
  //             confirmButtonText: "Ok",
  //             reverseButtons: false,
  //         }).then((result) => {
  //             if (result.value) {
  //                 try {
  //                     EbookModel.putEbookSaleStatus(props.obj_id, sale.id, 1).then(({ ebook_sale_id }) => {
  //                         if (ebook_sale_id) {
  //                             history.replace("/ebook/" + props.obj_id);
  //                             window.location.reload();
  //                         }
  //                     });
  //                 } catch (e) {
  //                     console.log(e);
  //                 }
  //             }
  //         });
  //     }
  // };

  const saveSale = async () => {
    setIsLoading(true);
    props.stored_ebook_sale(await EbookModel.postEbookSale(props.obj_id, EbookSale));
    setIsEdit(false);
    setIsLoading(false);
  };

  const EbookSale = props.EbookSale;

  return (
    <>
      <Row>
        <Col>
          <Loading isLoading={isLoading} />
        </Col>
        <Col className='text-right'>
          {isEdit && (
            <>
              <Button
                className='btn-pill'
                color='primary'
                style={{ marginRight: '8px' }}
                onClick={saveSale}
              >
                저장
              </Button>
            </>
          )}
          {
            <>
              <Button
                className={isEdit ? 'btn-pill btn btn-light' : 'btn-pill'}
                color={isEdit ? '' : 'primary'}
                style={{ marginRight: '8px' }}
                onClick={() => {
                  if (isEdit) {
                    initData();
                  }
                  setIsEdit(!isEdit);
                }}
              >
                {isEdit ? <>취소</> : <>편집</>}
              </Button>
            </>
          }
        </Col>
      </Row>
      {EbookSale
        ? EbookSale.map((sale, sale_index) => {
            return (
              <Card
                key={sale_index}
                className='input-air-primary card-absolute'
                style={{ marginTop: '20px' }}
              >
                {/* {isEdit && (
                        <CardHeader
                            style={{ backgroundColor: "#fff", border: "none", right: "0", left: "auto" }}
                        >
                            <Button
                                className="btn-sm btn-danger btn-pill"
                                style={{ fontSize: "10px" }}
                                onClick={(e) => {
                                    deleteSale(e, sale, sale_index);
                                }}
                            >
                                삭제
                            </Button>
                        </CardHeader>
                    )} */}
                <CardBody>
                  <Row>
                    <Col sm={3}>
                      <CustomInput
                        type={'text'}
                        name={'sale_short_name'}
                        disabled={!isEdit}
                        placeholder='ex)n개월 수강권 / 8자 이내 추천'
                        value={sale.sale_short_name}
                        label={'항목명'}
                        is_required={true}
                        tooltip_desc={'판매 항목의 이름, 클라이언트(사용자)에 표시됨'}
                        onChange={(e) => {
                          props.set_ebook_sale(sale_index, e.target.name, e.target.value);
                        }}
                      />
                    </Col>
                    <Col sm={3}>
                      <CustomInput
                        type={'number'}
                        name={'original_price'}
                        is_required={true}
                        placeholder='제품의 원가를 입력해주세요.'
                        disabled={!isEdit}
                        label={'제품원가'}
                        value={sale.price_info[sale.price_info.length - 1]?.original_price}
                        tooltip_desc={'제품의 원판매가, [판매금액]과 비교하여 할인율등으로 표기됨'}
                        onChange={(e) => {
                          props.set_ebook_sale_price(sale_index, e.target.name, e.target.value);
                        }}
                      />
                    </Col>
                    <Col sm={3}>
                      <CustomInput
                        type='number'
                        name={'price'}
                        is_required={true}
                        placeholder='실제 판매가로 보일 값을 입력하세요.'
                        disabled={!isEdit}
                        label={'실판매가'}
                        tooltip_desc={
                          '유저가 실제 구매하는 판매금액, 해당 상품의 판매가는 반드시 키트가격을 제외한 가격을 입력하세요.\n구매시 키트의 판매가 및 갯수에 따라 자동으로 추산됩니다. (키트가격이 0원이어야 하는 특수한 경우 제외)'
                        }
                        value={sale.price_info[sale.price_info.length - 1]?.price}
                        onChange={(e) => {
                          props.set_ebook_sale_price(sale_index, e.target.name, e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '20px' }}>
                    <Col sm={3}>
                      <CustomSelectInput
                        name={'currency_code'}
                        disabled={!isEdit}
                        value={sale.price_info.length > 0 ? sale.price_info[0].currency_code : 0}
                        label={'화폐종류'}
                        is_required={true}
                        onChange={(e) =>
                          props.set_ebook_sale(sale_index, e.target.name, e.target.value)
                        }
                        options={currency_code_set}
                        sub_option_type={'description'}
                      />
                    </Col>
                    <Col sm={3}>
                      <CustomSelectInput
                        name={'purchase_type'}
                        disabled={!isEdit}
                        value={sale.purchase_type}
                        label={'구매종류'}
                        is_required={true}
                        onChange={(e) =>
                          props.set_ebook_sale(sale_index, e.target.name, e.target.value)
                        }
                        options={purchase_type_set}
                      />
                    </Col>
                    {/* <Col sm={2}>
                                <CustomInput
                                    type="number"
                                    name={"rental_time_in_day"}
                                    is_required={true}
                                    placeholder="일자를 숫자로 입력하세요."
                                    disabled={!isEdit}
                                    label={"이용기간(일)"}
                                    value={sale.rental_time_in_day}
                                    onChange={(e) => {
                                        props.set_course_sale(sale_index, e.target.name, e.target.value);
                                    }}
                                />
                            </Col> */}
                    <Col sm={2}>
                      <CustomSelectInput
                        name={'is_hide'}
                        disabled={!isEdit}
                        value={sale.is_hide || 0}
                        label={'숨김여부'}
                        is_required={true}
                        tooltip_desc={
                          '숨김 처리시 판매항목에서 보여지지 않습니다.\n단 수강코드 등록 등에 사용 가능, 모든 구매 형태를 제한 하려면 판매항목을 제거 해야 합니다.'
                        }
                        onChange={(e) =>
                          props.set_ebook_sale(sale_index, e.target.name, e.target.value)
                        }
                        options={[
                          { name: '예', id: 1 },
                          { name: '아니오', id: 0 },
                        ]}
                      />
                    </Col>
                    <Col sm={2}>
                      <CustomInput
                        type='number'
                        name={'order_number'}
                        placeholder='일자를 숫자로 입력하세요.'
                        disabled={!isEdit}
                        label={'노출 순서'}
                        tooltip_desc={
                          '값이 낮을수록 상단 배치 되며, 상단배치 및 숨김, 제거 되지 않은 판매항목을 기준으로,\n[연장하기] 기능의 금액 산출 근거 데이터로 사용 됩니다'
                        }
                        value={sale.order_number}
                        onChange={(e) => {
                          props.set_ebook_sale(sale_index, e.target.name, e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            );
          })
        : null}
      {EbookSale && EbookSale.length === 0 && (
        <Row>
          <Col>
            <Button
              hidden={!isEdit}
              className='btn-pill'
              color='primary'
              style={{ width: '100%' }}
              onClick={props.add_ebook_sale}
            >
              판매정보 추가
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  EbookSale: state.EbookSaleStore,
});

const mapDispatchToProps = (dispatch) => {
  return {
    stored_ebook_sale: (data) => dispatch(stored_ebook_sale(data)),
    add_ebook_sale: () => dispatch(add_ebook_sale()),
    clear_ebook_sale: () => dispatch(clear_ebook_sale()),
    delete_ebook_sale: (sale_index) => dispatch(delete_ebook_sale(sale_index)),
    set_ebook_sale: (sale_index, name, value) => dispatch(set_ebook_sale(sale_index, name, value)),
    set_ebook_sale_price: (sale_index, name, value) =>
      dispatch(set_ebook_sale_price(sale_index, name, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sale);
