import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import { Button, Card, Col, Container, Input, Row, Table } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import { initComma } from '../../util/common';
import InvoiceModel from '../../model/InvoiceModel';
import moment from 'moment';
import Loading from '../_common/Loading';
import { isMobile } from 'react-device-detect';

const MasterDetail = (props) => {
  const {
    match: {
      params: { account_id },
    },
    history,
  } = props;
  const [master_set, setMasterSet] = useState([]);
  const [assume_price, setAssumePrice] = useState(0);
  const [invoice_month_set, setInvoiceMonthSet] = useState([]);
  let year =
    moment().format('MM') !== '12'
      ? Number(moment().format('YYYY'))
      : Number(moment().format('YYYY')) + 1;
  let month =
    moment().format('MM') !== '12'
      ? moment().format('MM').substr(0, 1) === '0'
        ? moment().format('MM').substr(1, 1)
        : moment().format('MM')
      : '1';
  const [selected_year, setSelectedYear] = useState(year);
  const [isLoading, setIsLoading] = useState(true);

  const calculate_invoice = (invoice_channel_set) => {
    let invoice = {
      i_total_after_tax_price: 0,
      c_total_after_tax_price: 0,
      tf_total_after_tax_price: 0,
      title: '',
      type_flag: 0,
      total_invoice_price: 0,
      attach_message: null,
      attach_archive_file_url: null,
      attach_archive_file_password: null,
    };

    for (const invoice_channel of invoice_channel_set) {
      // package is_hidden
      if (invoice_channel.is_hidden) continue;
      if (invoice_channel.invoice_user_type === 1) {
        invoice.i_total_after_tax_price += invoice_channel.invoice_price;
        invoice.title = '원천세 항목';
        invoice.type_flag = invoice_channel.invoice_user_type;
      } else if (invoice_channel.invoice_user_type === 2) {
        invoice.c_total_after_tax_price += invoice_channel.invoice_price;
        invoice.title = '부가세 항목';
        invoice.type_flag = invoice_channel.invoice_user_type;
      } else if (invoice_channel.invoice_user_type === 3) {
        invoice.tf_total_after_tax_price += invoice_channel.invoice_price;
      }
    }

    invoice.c_total_after_tax_price = Math.round(invoice.c_total_after_tax_price * 1.1);
    invoice.i_total_after_tax_price = Math.round(invoice.i_total_after_tax_price * 0.967);
    invoice.tf_total_after_tax_price = Math.round(invoice.tf_total_after_tax_price);

    if (invoice.type_flag === 2) {
      invoice.total_invoice_price =
        invoice.tf_total_after_tax_price + invoice.c_total_after_tax_price;
    } else {
      invoice.total_invoice_price =
        invoice.tf_total_after_tax_price + invoice.i_total_after_tax_price;
    }
    setAssumePrice(invoice.total_invoice_price);
  };
  const getSummary = () => {
    InvoiceModel.getMasterSummary(account_id, year, month).then((data) => {
      const { invoice_channel_set } = data;
      for (const invoice of invoice_channel_set) {
        invoice['commission_rate'] = invoice.commission_rate * 100;
        if (invoice['course_id']) invoice['is_hidden'] = false;
        else invoice['is_hidden'] = true;
      }
      calculate_invoice(invoice_channel_set);
      setIsLoading(false);
    });

    setInvoiceMonthSet(() => {
      const month_result = [];
      while (year > 2018) {
        month_result.push(year);
        year -= 1;
      }
      return month_result;
    });
  };
  const getSummaryList = () => {
    const monthly_invoice_set = [];
    for (let i = 0; i < 12; i++) {
      monthly_invoice_set[i] = {
        year: selected_year,
        month: i + 1,
        total_invoice_price: 0,
        is_invoice_done: false,
      };
    }
    InvoiceModel.getMasterSummaryList(account_id, selected_year)
      .then((data) => {
        const { invoice_set } = data;
        monthly_invoice_set.filter((item) =>
          invoice_set.forEach((elem) => {
            if (item.month === elem.month) {
              item.total_invoice_price = elem.total_invoice_price;
              item.is_invoice_done = true;
            }
          }),
        );
        setMasterSet(monthly_invoice_set.reverse());
        setIsLoading(false);
      })
      .catch((error) => {
        setMasterSet(monthly_invoice_set.reverse());
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSummaryList();
  }, [selected_year]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSummaryList();
    getSummary();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent='정산' title='강사 요약 정보' />
      <Container fluid={true}>
        <Row>
          <Col lg='12 xl-100'>
            <Card>
              <CardBody>
                <Col className='text-center'>
                  <Loading isLoading={isLoading} />
                </Col>
                <Row>
                  <Col className='text-center'>
                    <h3 style={{ fontWeight: '300' }}>
                      <span style={{ fontWeight: '500' }}>
                        {' '}
                        {moment().format('YYYY')}년 {moment().format('MM')}월 정산 예정 금액
                      </span>
                      은{' '}
                      <span
                        style={{
                          color: '#7e37d8',
                          fontWeight: '700',
                        }}
                      >
                        {initComma(assume_price)}
                      </span>
                      원 입니다.
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-center'>
                    판매기간 : {moment().format('YYYY-MM-01')} ~{' '}
                    {moment().endOf('month').format('YYYY-MM-DD')} | 정산예정일:{' '}
                    <span
                      style={{
                        fontWeight: '500',
                        color: '#864ecd',
                      }}
                    >
                      {moment().add(1, 'month').format('YYYY-MM-20')}
                    </span>{' '}
                    (공휴일에 따라 일정이 변동될 수 있습니다.)
                  </Col>
                </Row>
                <Row style={{ marginTop: '40px', marginBottom: '10px' }}>
                  <Col className='text-center'>
                    <Button
                      className='btn btn-pill'
                      style={{ width: '100%' }}
                      onClick={() => {
                        history.push(
                          `${account_id}/${moment().format('YYYY')}-${moment().format('MM') !== '12' ? (moment().format('MM').substr(0, 1) === '0' ? moment().format('MM').substr(1, 1) : moment().format('MM')) : '1'}`,
                        );
                      }}
                    >
                      정산 상세정보 조회
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {!isLoading ? (
              <Card>
                <CardBody>
                  <Row
                    className='justify-content-center'
                    style={{ marginBottom: '40px', marginTop: '10px' }}
                  >
                    <Col className={isMobile ? 'col-4' : 'col-1'} style={{ marginRight: '-30px' }}>
                      <span
                        style={{
                          lineHeight: '2.5',
                          fontWeight: '600',
                          fontSize: '16px',
                        }}
                      >
                        년도별 필터
                      </span>
                    </Col>
                    <Col className='col-4'>
                      <Input
                        type='select'
                        value={selected_year}
                        onChange={(e) => {
                          setSelectedYear(e.target.value);
                        }}
                      >
                        {invoice_month_set.map((year, index) => (
                          <option key={index} value={year}>
                            {year}년
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                  <Row>
                    <div className='table-responsive'>
                      <Table>
                        <thead>
                          <tr style={{ textAlign: 'center' }}>
                            <th>매출 발생월</th>
                            <th>정산금액</th>
                          </tr>
                        </thead>
                        <tbody>
                          {master_set
                            ? master_set.map((master, index) => {
                                return (
                                  <tr
                                    key={index}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      lineHeight: '4',
                                    }}
                                    onClick={() => {
                                      history.push(`${account_id}/${master.year}-${master.month}`);
                                    }}
                                  >
                                    <td className='mobile-none'>
                                      {master.year}년 {master.month}월
                                    </td>
                                    <td className='mobile-none'>
                                      {!master.is_invoice_done ? (
                                        <Button className='btn btn-pill btn-sm'>
                                          정산 예정 상세페이지 이동
                                        </Button>
                                      ) : (
                                        initComma(master.total_invoice_price) + '원'
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            : null}
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            ) : (
              <div style={{ textAlign: 'center', marginTop: '15%' }}>
                <h4>
                  <Loading
                    custom_style={{ marginLeft: '20px', marginRight: '20px' }}
                    isLoading={true}
                  />
                </h4>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MasterDetail;
