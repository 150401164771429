import {
  STORED_EBOOK_INFO,
  CLEAR_EBOOK_INFO,
  SET_EBOOK_INFO,
  SET_EBOOK_TAG,
  REMOVE_EBOOK_TAG,
  SET_EBOOK_KIT,
  SET_EBOOK_SUB_CATEGORY,
  SET_EBOOK_BODY,
} from '../../action_type';

export const stored_ebook_info = (data) => ({
  type: STORED_EBOOK_INFO,
  data,
});

export const clear_ebook_info = () => ({
  type: CLEAR_EBOOK_INFO,
});

export const set_ebook_info = (name, value) => ({
  type: SET_EBOOK_INFO,
  name,
  value,
});

export const set_ebook_tag = (value) => ({
  type: SET_EBOOK_TAG,
  value,
});

export const remove_ebook_tag = (index) => ({
  type: REMOVE_EBOOK_TAG,
  index,
});

export const set_ebook_kit = (kit_set) => ({
  type: SET_EBOOK_KIT,
  kit_set,
});

export const set_ebook_sub_category = (sub_category_set) => ({
  type: SET_EBOOK_SUB_CATEGORY,
  sub_category_set,
});

export const set_ebook_body = (name, value) => ({
  type: SET_EBOOK_BODY,
  name,
  value,
});
