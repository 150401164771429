import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CourseModel from '../../model/CourseModel';
import Loading from '../_common/Loading';

import { Card, CardHeader, Col, Container, Row, Button } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import Form from 'reactstrap/es/Form';

import SweetAlert from 'sweetalert2';
import CommonModel from '../../model/CommonModel';
import moment from 'moment';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import CustomTypeahead from '../_common/component/CustomTypeahead';
import { errMessageOccur } from '../../util/common';
import TextEditor from '../_common/component/TextEditor';
import CustomLabel from '../_common/component/CustomLabel';

const obj_err_msg = {
  title: "'큐레이션명'",
  description: "'설명'",
  short_description: "'짧은설명'",
};

const CurationDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.curation_id));
  const [isLoading, setIsLoading] = useState(false);

  // 선택 정보 가지고 있기(get_or_create), 삭제한 정보 가지고 있기(delete)
  const [course_select, setCourseSelect] = useState([]);
  const [product_select, setProductSelect] = useState([]);

  const [course_set, setCourseSet] = useState([]);
  const [product_set, setProductSet] = useState([]);

  const [curation, setCuration] = useState({
    id: '',
    title: '',
    short_description: '',
    description: '',
    youtube_id: '',
    is_enabled: 0,
    show_in_application: 0,
    create_time: '',
    update_time: '',
    content_html: '',
    curation_course_set: [],
    curation_product_set: [],
  });
  const onInputChange = (e) => {
    setCuration({ ...curation, [e.target.name]: e.target.value });
  };

  const onInputSubmit = async (e) => {
    e.preventDefault();

    const result_course_select = course_select.map((e) => e.id);
    const result_product_select = product_select.map((e) => e.id);

    const err_msg = errMessageOccur(curation, obj_err_msg);

    if (!result_course_select?.length && !result_product_select?.length) {
      err_msg.push("'강의' 혹은 '상품' 1개 이상 선택해주세요.");
    }

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      if (curation.id) {
        await CourseModel.putCurationDetail({
          ...curation,
          result_course_select: result_course_select,
          result_product_select: result_product_select,
        }).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        CourseModel.postCuration({
          ...curation,
          result_course_select: result_course_select,
          result_product_select: result_product_select,
        }).then(({ curation_id }) => {
          props.history.replace('/curation/' + curation_id);
          window.location.reload();
        });
      }
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const requestDetail = () => {
    CourseModel.getCurationDetail(Number(props.match.params.curation_id)).then(({ curation }) => {
      if (curation) {
        setCuration(curation);

        const _course_select = curation.curation_course_set.map((e) => {
          let obj = {};
          obj['id'] = e.course_id;
          obj['combine_name'] = e.combine_name;
          return obj;
        });
        setCourseSelect(_course_select);

        const _product_select = curation.curation_product_set.map((e) => {
          let obj = {};
          obj['id'] = e.product_id;
          obj['combine_name'] = e.combine_name;
          return obj;
        });
        setProductSelect(_product_select);
      }
    });
  };

  useEffect(() => {
    CommonModel.getSearch('cms_curation_accessible_course').then((data) => {
      setCourseSet(data);
    });

    CommonModel.getSearch('cms_accessible_product').then((data) => {
      setProductSet(data);
    });

    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent='큐레이션'
        title={`큐레이션 ${props.match.params.curation_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form onSubmit={onInputSubmit}>
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`큐레이션 ${
                        props.match.params.curation_id === '0' ? '생성' : '정보'
                      }`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      {
                        <>
                          {Number(props.match.params.curation_id) && !isEdit ? (
                            <>
                              <a
                                className='btn btn-pill btn-info mr-2'
                                href={`${process.env.REACT_APP_API_URL}/curation/${Number(
                                  props.match.params.curation_id,
                                )}`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                미리보기
                              </a>
                            </>
                          ) : (
                            ''
                          )}
                          <Button
                            className='btn-pill'
                            color='primary'
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              // 생성(0)일 경우 수정(0 이상)일 경우
                              if (!!Number(props.match.params.curation_id)) {
                                // 초기화
                                if (isEdit) {
                                  requestDetail();
                                }
                                setIsEdit(!isEdit);
                              } else {
                                window.location.href = '/curation';
                              }
                            }}
                          >
                            {isEdit ? '취소' : '편집'}
                          </Button>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={7} style={{ borderRight: '2px solid #F5F5F5' }}>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'textarea'}
                            name={'title'}
                            disabled={!isEdit}
                            style={{ resize: 'none' }}
                            value={curation.title || ''}
                            is_required={true}
                            label={'큐레이션명'}
                            tooltip_desc={
                              '유저에게 노출됨으로, 신중히 기입해주세요.\n개행문자 가능하며 최대 2줄까지 지원함. (최대 24자)'
                            }
                            onChange={onInputChange}
                            validator={(value) => {
                              return (
                                value.length <= 24 &&
                                value.indexOf('\n', value.indexOf('\n') + 1) < 0
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'textarea'}
                            name={'description'}
                            disabled={!isEdit}
                            value={curation.description || ''}
                            style={{ height: 200 }}
                            is_required={true}
                            label={'설명'}
                            tooltip_desc={
                              '유저에게 노출됨으로, 신중히 기입해주세요.\n2줄 정도 추천함. (최대 100자 내외)'
                            }
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 100;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'short_description'}
                            disabled={!isEdit}
                            value={curation.short_description || ''}
                            is_required={true}
                            label={'짧은설명'}
                            tooltip_desc={'유저에게 노출됨으로, 신중히 기입해주세요.\n(최대 20자)'}
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 20;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={'12 mb-3'}>
                          <CustomLabel label={'콘텐츠영역 작성'} />
                          <div className='editor-img-100'>
                            <TextEditor
                              id={Number(props.match.params.curation_id)}
                              html_data={curation.content_html || ''}
                              onChange={(value) => {
                                setCuration((prevState) => ({
                                  ...prevState,
                                  content_html: value,
                                }));
                              }}
                              disabled={!!!Number(props.match.params.curation_id) || !isEdit}
                              img_type={'curation'}
                              items={[
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                'Underline',
                                'Strikethrough',
                                '|',
                                'FontSize',
                                'FontColor',
                                'FontBackgroundColor',
                                '|',
                                'imageUpload',
                                'link',
                                '|',
                                'blockQuote',
                                'bulletedList',
                                'numberedList',
                                'alignment',
                                '|',
                                'undo',
                                'redo',
                              ]}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <Col md='4 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'youtube_id'}
                            disabled={!isEdit}
                            value={curation.youtube_id || ''}
                            label={'유튜브ID'}
                            tooltip_desc={'맛보기 영상용으로 사용됩니다.'}
                            onChange={onInputChange}
                            validator={(value) => value.length <= 16}
                          />
                        </Col>
                        <Col md='4 mb-3'>
                          <CustomSelectInput
                            name={'is_enabled'}
                            disabled={!!!Number(props.match.params.curation_id) || !isEdit}
                            value={curation.is_enabled || ''}
                            label={'활성화여부'}
                            is_required={true}
                            tooltip_desc={
                              '활성화인 경우에 큐레이션 상세페이지에 접근 가능하나,\n비활성화된 경우는 접근이 불가하며 메시지와 함께 메인페이지로 이동됩니다.'
                            }
                            onChange={onInputChange}
                            options={[
                              {
                                id: '0',
                                name: '비활성화',
                              },
                              {
                                id: '1',
                                name: '활성화',
                              },
                            ]}
                          />
                        </Col>
                        <Col md='4 mb-3'>
                          <CustomSelectInput
                            name={'show_in_application'}
                            disabled={!!!Number(props.match.params.curation_id) || !isEdit}
                            value={curation.show_in_application || ''}
                            label={'서비스내표시여부'}
                            is_required={true}
                            tooltip_desc={
                              '숨김 :\n홈페이지에서 노출이 아예 없음\n\n노출 :\n홈, 클래스 페이지에서 리스트형태로 노출될수 있음'
                            }
                            onChange={onInputChange}
                            options={[
                              {
                                id: '0',
                                name: '숨김',
                              },
                              {
                                id: '1',
                                name: '노출',
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomTypeahead
                            disabled={!isEdit}
                            is_required={true}
                            multiple={true}
                            label={'강의 설정'}
                            tooltip_desc={
                              '대상 상품목록으로 지정하더라도, 해당 상품이 상세페이지를 구성할수 없는 경우 노출되지 않습니다\n(해당 상품에 메인이미지가 없는 경우 등).'
                            }
                            selected={course_select}
                            selectedHandler={setCourseSelect}
                            onTokenClick={(target) => {
                              window.open('/course/' + target.id, '_blank');
                            }}
                            labelKey={'combine_name'}
                            customToken={(target) => {
                              return `${target.combine_name}`;
                            }}
                            options={course_set}
                            placeholder={'강의를 선택 해주세요.'}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <CustomTypeahead
                            disabled={!isEdit}
                            is_required={true}
                            multiple={true}
                            label={'상품 설정'}
                            tooltip_desc={
                              '대상 상품목록으로 지정하더라도, 해당 상품이 상세페이지를 구성할수 없는 경우 노출되지 않습니다\n(해당 상품에 메인이미지가 없는 경우 등).'
                            }
                            selected={product_select}
                            selectedHandler={setProductSelect}
                            onTokenClick={(target) => {
                              window.open('/product/' + target.id, '_blank');
                            }}
                            customToken={(target) => {
                              return `${target.combine_name}`;
                            }}
                            labelKey={'combine_name'}
                            options={product_set}
                            placeholder={'상품를 선택 해주세요.'}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    {Number(props.match.params.curation_id) ? (
                      <Col md='12'>
                        <div className='text-right'>
                          생성일 :{' '}
                          {moment(new Date(curation.create_time)).format('YYYY-MM-DD') || ''}
                        </div>
                        <div className='text-right'>
                          갱신일 :{' '}
                          {moment(new Date(curation.update_time)).format('YYYY-MM-DD') || ''}
                        </div>
                      </Col>
                    ) : (
                      ''
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CurationDetail;
