import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';

import { Card, CardHeader, Col, Container, Row, Button, Label } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import ImageUploader from '../_common/component/ImageUploader';

import Form from 'reactstrap/es/Form';
import SweetAlert from 'sweetalert2';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import TextEditor from '../_common/component/TextEditor';
import { errMessageOccur } from '../../util/common';
import CustomLabel from '../_common/component/CustomLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const obj_err_msg = {
  title: "'제목'",
  event_id: "'이벤트 ID'",
  typeform_id: "'타입폼 ID'",
  not_started_message: "'시작전 메세지'",
  recruitment_start_time: "'모집 시작일'",
  recruitment_end_time: "'모집 종료일'",
  release_time: "'결과 발표일'",
  active_start_time: "'활동 시작일'",
  active_end_time: "'활동 종료일'",
  youtube_id_1: '유튜브 ID 1',
  youtube_id_2: '유튜브 ID 2',
};

const HardStudyDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.hardstudy_id));
  const [isLoading, setIsLoading] = useState(false);

  // 빡공단
  const [hardStudy, sethardStudy] = useState({
    title: '',
    event_id: '',
    typeform_id: '',
    create_time: '',
    not_started_message: '',
    alarm_redirect_url: '',
    recruitment_start_time: '',
    recruitment_end_time: '',
    release_time: '',
    active_start_time: '',
    active_end_time: '',
    on_air: '',
    content: '',
    mobile_content: '',
    youtube_id_1: '',
    youtube_id_2: '',
  });

  const onDateTimeChange = (e, name) => {
    const date = moment(e);
    if (date.year() < 10000) {
      sethardStudy({ ...hardStudy, [name]: e && date.format('YYYY-MM-DDTHH:mm:ss') });
    }
  };

  const onInputChange = (e) => {
    sethardStudy({ ...hardStudy, [e.target.name]: e.target.value });
  };

  const onInputSubmit = (e) => {
    e.preventDefault();

    const err_msg = errMessageOccur(hardStudy, obj_err_msg);

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      if (hardStudy.id) {
        CommonModel.putHardStudyDetail(hardStudy).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        CommonModel.postHardStudy(hardStudy).then(({ hardstudy_id }) => {
          props.history.replace('/hardstudy/' + hardstudy_id);
          window.location.reload();
        });
      }
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const requestDetail = () => {
    CommonModel.getHardStudyDetail(Number(props.match.params.hardstudy_id)).then(
      ({ hardstudy_info }) => {
        if (hardstudy_info) {
          sethardStudy(hardstudy_info);
        }
      },
    );
  };

  useEffect(() => {
    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent='빡공단'
        title={`빡공단 ${props.match.params.hardstudy_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form
              onSubmit={(e) => {
                onInputSubmit(e);
              }}
            >
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`빡공단 ${props.match.params.hardstudy_id === '0' ? '생성' : '정보'}`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      {
                        <>
                          <Button
                            className='btn-pill'
                            color='primary'
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              // 생성(0)일 경우 수정(0 이상)일 경우
                              if (!!Number(props.match.params.hardstudy_id)) {
                                if (isEdit) {
                                  CommonModel.getHardStudyDetail(
                                    Number(props.match.params.hardstudy_id),
                                  ).then(({ hardStudy }) => {
                                    if (hardStudy) {
                                      sethardStudy(hardStudy);
                                    }
                                  });
                                }
                                setIsEdit(!isEdit);
                              } else {
                                window.location.href = '/hardstudy';
                              }
                            }}
                          >
                            {isEdit ? <>취소</> : <>편집</>}
                          </Button>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <Row>
                        <Col md='2 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'title'}
                            disabled={!isEdit}
                            value={hardStudy.title || ''}
                            is_required={true}
                            label={'제목'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='2 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'event_id'}
                            disabled={!isEdit}
                            value={hardStudy.event_id || ''}
                            is_required={true}
                            label={'이벤트 ID'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='2 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'typeform_id'}
                            disabled={!isEdit}
                            value={hardStudy.typeform_id || ''}
                            is_required={true}
                            label={'타입폼 ID'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'not_started_message'}
                            disabled={!isEdit}
                            tooltip_desc={'예시: 27기 모집이 곧 시작될 예정입니다'}
                            value={hardStudy.not_started_message || ''}
                            is_required={true}
                            label={'시작전 메세지'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomLabel label={'생성일'} tooltip_desc={''} />
                          <DatePicker
                            autoComplete='off'
                            className='form-control digits'
                            name='create_time'
                            selectsStart
                            disabled={true}
                            onChange={(e) => {
                              onDateTimeChange(e, 'create_time');
                            }}
                            locale='ko'
                            // 선택하는 날짜
                            selected={
                              hardStudy.create_time ? new Date(hardStudy.create_time) : null
                            }
                            timeFormat='HH:mm:ss'
                            showTimeSelect
                            dateFormat='yyyy-MM-dd HH:mm:ss'
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='6 mb-3'>
                          <Row>
                            <Col md={12}>
                              <CustomLabel
                                is_required={true}
                                label={'모집 시작일'}
                                tooltip_desc={''}
                              />
                              <DatePicker
                                autoComplete='off'
                                className='form-control digits'
                                name='recruitment_start_time'
                                selectsStart
                                disabled={!isEdit}
                                onChange={(e) => {
                                  onDateTimeChange(e, 'recruitment_start_time');
                                }}
                                locale='ko'
                                // 선택하는 날짜
                                selected={
                                  hardStudy.recruitment_start_time
                                    ? new Date(hardStudy.recruitment_start_time)
                                    : null
                                }
                                // 시작일 (선택하는 날짜)
                                startDate={
                                  hardStudy.recruitment_start_time
                                    ? new Date(hardStudy.recruitment_start_time)
                                    : null
                                }
                                // 종료일 (종료되는 날짜 입력)
                                endDate={
                                  hardStudy.recruitment_end_time
                                    ? new Date(hardStudy.recruitment_end_time)
                                    : null
                                }
                                // 선택할 수 있는 최대 날짜
                                maxDate={
                                  hardStudy.recruitment_end_time
                                    ? new Date(hardStudy.recruitment_end_time)
                                    : null
                                }
                                timeFormat='HH:mm:ss'
                                showTimeSelect
                                dateFormat='yyyy-MM-dd HH:mm:ss'
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md='6 mb-3'>
                          <Row>
                            <Col md={12}>
                              <CustomLabel
                                is_required={true}
                                label={'모집 종료일'}
                                tooltip_desc={''}
                              />
                              <DatePicker
                                autoComplete='off'
                                className='form-control digits'
                                name='recruitment_end_time'
                                selectsStart
                                disabled={!isEdit}
                                onChange={(e) => {
                                  onDateTimeChange(e, 'recruitment_end_time');
                                }}
                                locale='ko'
                                selected={
                                  hardStudy.recruitment_end_time
                                    ? new Date(hardStudy.recruitment_end_time)
                                    : null
                                }
                                startDate={
                                  hardStudy.recruitment_start_time
                                    ? new Date(hardStudy.recruitment_start_time)
                                    : null
                                }
                                endDate={
                                  hardStudy.recruitment_end_time
                                    ? new Date(hardStudy.recruitment_end_time)
                                    : null
                                }
                                minDate={
                                  hardStudy.recruitment_start_time
                                    ? new Date(hardStudy.recruitment_start_time)
                                    : null
                                }
                                timeFormat='HH:mm:ss'
                                showTimeSelect
                                dateFormat='yyyy-MM-dd HH:mm:ss'
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='6 mb-3'>
                          <Row>
                            <Col md={12}>
                              <CustomLabel
                                is_required={true}
                                label={'활동 시작일'}
                                tooltip_desc={''}
                              />
                              <DatePicker
                                autoComplete='off'
                                className='form-control digits'
                                name='active_start_time'
                                selectsStart
                                disabled={!isEdit}
                                onChange={(e) => {
                                  onDateTimeChange(e, 'active_start_time');
                                }}
                                locale='ko'
                                selected={
                                  hardStudy.active_start_time
                                    ? new Date(hardStudy.active_start_time)
                                    : null
                                }
                                startDate={
                                  hardStudy.active_start_time
                                    ? new Date(hardStudy.active_start_time)
                                    : null
                                }
                                endDate={
                                  hardStudy.active_end_time
                                    ? new Date(hardStudy.active_end_time)
                                    : null
                                }
                                maxDate={
                                  hardStudy.active_end_time
                                    ? new Date(hardStudy.active_end_time)
                                    : null
                                }
                                minDate={
                                  hardStudy.recruitment_end_time
                                    ? new Date(hardStudy.recruitment_end_time)
                                    : null
                                }
                                timeFormat='HH:mm:ss'
                                showTimeSelect
                                dateFormat='yyyy-MM-dd HH:mm:ss'
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md='6 mb-3'>
                          <Row>
                            <Col md={12}>
                              <CustomLabel
                                is_required={true}
                                label={'활동 종료일'}
                                tooltip_desc={''}
                              />
                              <DatePicker
                                autoComplete='off'
                                className='form-control digits'
                                name='active_end_time'
                                selectsStart
                                disabled={!isEdit}
                                onChange={(e) => {
                                  onDateTimeChange(e, 'active_end_time');
                                }}
                                locale='ko'
                                selected={
                                  hardStudy.active_end_time
                                    ? new Date(hardStudy.active_end_time)
                                    : null
                                }
                                startDate={
                                  hardStudy.active_start_time
                                    ? new Date(hardStudy.active_start_time)
                                    : null
                                }
                                endDate={
                                  hardStudy.active_end_time
                                    ? new Date(hardStudy.active_end_time)
                                    : null
                                }
                                minDate={
                                  hardStudy.active_start_time
                                    ? new Date(hardStudy.active_start_time)
                                    : null
                                }
                                timeFormat='HH:mm:ss'
                                showTimeSelect
                                dateFormat='yyyy-MM-dd HH:mm:ss'
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='6 mb-3'>
                          <Row>
                            <Col md={12}>
                              <CustomLabel
                                is_required={true}
                                label={'결과 발표일'}
                                tooltip_desc={'결과 발표일이 지나면 다음 기수가 노출됩니다.'}
                              />
                              <DatePicker
                                autoComplete='off'
                                className='form-control digits'
                                name='release_time'
                                selectsStart
                                disabled={!isEdit}
                                onChange={(e) => {
                                  onDateTimeChange(e, 'release_time');
                                }}
                                locale='ko'
                                selected={
                                  hardStudy.release_time ? new Date(hardStudy.release_time) : null
                                }
                                startDate={
                                  hardStudy.release_time ? new Date(hardStudy.release_time) : null
                                }
                                endDate={
                                  hardStudy.release_time ? new Date(hardStudy.release_time) : null
                                }
                                minDate={
                                  hardStudy.recruitment_end_time
                                    ? new Date(hardStudy.recruitment_end_time)
                                    : null
                                }
                                timeFormat='HH:mm:ss'
                                showTimeSelect
                                dateFormat='yyyy-MM-dd HH:mm:ss'
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md='6 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'alarm_redirect_url'}
                            disabled={!isEdit}
                            tooltip_desc={'예시: https://bear-u.com/service/survey/LZjTSe25'}
                            value={hardStudy.alarm_redirect_url || ''}
                            is_required={true}
                            label={'알림받을 리다이렉트 주소'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='6 md-3'>
                          <CustomInput
                            type={'text'}
                            name={'alarm_redirect_url'}
                            disabled={true}
                            tooltip_desc={''}
                            value={
                              hardStudy.on_air === 0
                                ? '종료'
                                : hardStudy.on_air === 1
                                  ? '진행'
                                  : '대기'
                            }
                            label={'onAir'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'youtube_id_1'}
                            disabled={!isEdit}
                            value={hardStudy.youtube_id_1 || ''}
                            is_required={true}
                            label={'유튜브 ID 1'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'youtube_id_2'}
                            disabled={!isEdit}
                            value={hardStudy.youtube_id_2 || ''}
                            is_required={true}
                            label={'유튜브 ID 2'}
                            onChange={onInputChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '20px' }}>
                    <Col md='6 mb-3'>
                      <CustomLabel
                        tooltip_desc={'[준비중] PC에서 보여질 내용을 입력하세요.'}
                        label={'콘텐츠 영역'}
                      />
                      <div className='editor-img-100'>
                        <TextEditor
                          html_data={hardStudy.content || ''}
                          onChange={(value) => {
                            sethardStudy((prevState) => ({ ...prevState, content: value }));
                          }}
                          disabled={!isEdit}
                          img_type={'hardstudy'}
                        />
                      </div>
                    </Col>
                    <Col md='6 mb-3'>
                      <CustomLabel
                        tooltip_desc={'[준비중] 모바일에서 보여질 내용을 입력하세요.'}
                        label={'모바일 콘텐츠 영역'}
                      />
                      <div className='editor-img-100'>
                        <TextEditor
                          html_data={hardStudy.mobile_content || ''}
                          onChange={(value) => {
                            sethardStudy((prevState) => ({ ...prevState, mobile_content: value }));
                          }}
                          disabled={!isEdit}
                          img_type={'hardstudy'}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default HardStudyDetail;
