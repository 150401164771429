import React, { createContext, useRef, useMemo, useContext } from 'react';

// broad casts : value -> object
export const BROADCAST_SEARCH = 'BROAD_SEARCH'; // CompanyList의 searchBar 용
export const BROADCAST_EDITABLE = 'BROADCAST_EDITABLE'; // EditButton 용
export const BROADCAST_ON_FORM_SUBMIT = 'BROADCAST_ON_FORM_SUBMIT'; // CustomTextInput의 값이 변경될 때 변경된 값과 보내는 key
export const BROADCAST_SET_MAXIMUM_COUNT = 'BROADCAST_SET_MAXIMUM_COUNT'; // AddCompanyForm 용
export const BROADCAST_ON_SELECT_MANAGER_ACCOUNT = 'BROADCAST_ON_SELECT_MANAGER_ACCOUNT'; // ManagerEditModal에서 관리자 계정 선택 후 등록 -> CustomTypeachead2에서 받기
export const BROADCAST_NEED_UPDATE = 'BROADCAST_NEED_UPDATE'; // 여러곳에서 사용 -> 부모 컴포넌트의 update가 필요할 때(get등의 재요청)
// broad casts end

const application_context = createContext();
export const ApplicationContextProvider = ({ children }) => {
  const session_storage = useRef({});
  const broadcasts = useRef([]);

  const addBroadCast = (keys, callback) => {
    let broadcast = { keys, callback };
    broadcasts.current.push(broadcast);
    return broadcast;
  };

  const removeBroadCast = (broadcast) => {
    broadcasts.current = broadcasts.current.filter((_broadcast) => {
      return _broadcast.callback !== broadcast.callback;
    });
  };

  const _session = useMemo(() => {
    return {
      get: (key) => {
        return session_storage.current[key];
      },
      save: (key, saved_instance) => {
        session_storage.current[key] = saved_instance;
      },
      clear: (key) => {
        session_storage.current[key] = null;
      },
    };
  }, []);

  const _broadcast = useMemo(() => {
    return {
      listen: (keys, callback) => {
        return addBroadCast(keys, callback);
      },
      unlisten: (broadcast) => {
        return removeBroadCast(broadcast);
      },
      send: (key, value) => {
        broadcasts.current.forEach((_broadcast) => {
          _broadcast.keys.forEach((_key) => {
            if (_key === key) {
              _broadcast.callback({ key, value });
            }
          });
        });
      },
    };
  }, []);

  const actions = {
    session: _session,
    broadcast: _broadcast,
  };

  return <application_context.Provider value={actions}>{children}</application_context.Provider>;
};

export const useApplicationContext = (init_params) => {
  return useContext(application_context);
};
