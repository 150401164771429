import { Label } from 'reactstrap';
import { Tooltip } from 'react-tippy';
import React from 'react';

const CustomLabel = (props) => {
  return (
    <div>
      {props.tooltip_desc && (
        <Tooltip
          html={
            <div style={{ whiteSpace: 'pre' }} className='p-2 rounded bg-dark'>
              {props.tooltip_desc}
            </div>
          }
          title=''
          position='top'
          trigger='mouseenter'
        >
          <i style={{ marginRight: '5px' }} className='pe-7s-help1' />
        </Tooltip>
      )}
      <Label style={props.label_style || {}}>
        {props.is_required ? <span style={{ color: '#fd517d' }}> * </span> : ' '}
        {props.label}
      </Label>
      {props.file_name && (
        <>
          <a href={props.file_name} target={'_blank'} rel='noreferrer' download>
            <span className='badge badge-primary' style={{ marginLeft: '6px', cursor: 'pointer' }}>
              파일 다운로드
            </span>
          </a>
          {props.file_password ? (
            <span style={{ fontSize: '12px', marginLeft: '5px' }}>
              압축 비밀번호: {props.file_password}
            </span>
          ) : null}
        </>
      )}
      {props.right_component && (
        <div
          style={{
            float: 'right',
            display: 'inline',
          }}
        >
          {props.right_component}
        </div>
      )}
    </div>
  );
};

export default CustomLabel;
