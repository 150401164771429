import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Loading from '../Loading';
import CommonModel from '../../../model/CommonModel';
import PurchaseModel from '../../../model/PurchaseModel';
import CustomLabel from './CustomLabel';
import { initComma } from '../../../util/common';

const RefundModal = (props) => {
  const [refund_course_set, setRefundCourseSet] = useState([]);
  const [purchasekitinfo_set, setPurchaseKitInfoSet] = useState([]);

  const [refund_reason, setRefundReason] = useState('');
  const [kit_cancel_price_info, setKitCancelPriceInfo] = useState({
    available_cancel_price: 0,
    cancel_price: 0,
  });
  const [else_kit_cancel_price, setElseKitCancelPrice] = useState('');

  const [virtual_account_info, setVirtualAccountInfo] = useState({
    refund_holder: '',
    refund_bank: '',
    refund_account: '',
  });

  const [is_full_refund, setIsFullRefund] = useState(true);

  const [expire_refund_course_set, setExpireRefundCourseSet] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [is_refund_loading, setIsRefundLoading] = useState(false);
  const [refund_result, setRefundResult] = useState({
    status: null,
    message: '',
    receipt_url: '',
  });

  const [course_set, setCourseSet] = useState([]);

  const calcRefundKitInfo = (our_saled_price, discounted_price, refund_price, ordered_count) => {
    const user_used_price = our_saled_price - discounted_price;

    // 진짜 1개
    const real_one_kit_price = user_used_price / ordered_count;
    // 반올림한 1개 (할인 금액 때문에 이상하게 개수가 소수점으로 잡힐 수 있어 반올림)
    const one_kit_price = Math.round(real_one_kit_price);

    return { one_kit_price, real_one_kit_price };
  };

  // 환불할 키트가 존재하는지 & 존재하면 몇개의 키트를 환불할 수 있는지
  const initializePurchaseKitInfo = () => {
    if (props.purchase.purchasekitinfo_set.length) {
      const left_kit_purchasekitinfo = props.purchase.purchasekitinfo_set
        // 공짜 키트 경우 환불 제외
        .filter((kit_info) => {
          return kit_info.ordered_count - kit_info.refunded_count > 0;
        })
        .map((kit_info) => {
          // 환불 후, 남은 개수
          const { one_kit_price, real_one_kit_price } = calcRefundKitInfo(
            kit_info.our_saled_price,
            kit_info.discounted_price,
            kit_info.refund_price,
            kit_info.ordered_count,
          );

          const left_count = kit_info.ordered_count - kit_info.refunded_count;

          return {
            ...kit_info,
            one_kit_price,
            real_one_kit_price,
            left_count,
            refund_count: left_count,
          };
        });

      setPurchaseKitInfoSet(left_kit_purchasekitinfo);

      // 키트 환불할 수 있는 모든 금액
      if (left_kit_purchasekitinfo.length > 0) {
        const left_kit_price = left_kit_purchasekitinfo
          .map(
            (kit_info) =>
              kit_info.our_saled_price - kit_info.discounted_price - kit_info.refund_price,
          )
          .reduce((previousValue, currentValue) => previousValue + currentValue);

        setKitCancelPriceInfo({
          available_cancel_price: left_kit_price,
          cancel_price: left_kit_price,
        });

        setElseKitCancelPrice(
          String(props.purchase.purchased_price - props.purchase.refund_price - left_kit_price),
        );
      } else {
        setElseKitCancelPrice(String(props.purchase.purchased_price - props.purchase.refund_price));
      }
    } else {
      setElseKitCancelPrice(String(props.purchase.purchased_price - props.purchase.refund_price));
    }
  };

  const onRefundKitCountChange = (e) => {
    let value;
    let cancel_price;
    // 전체 고르면 반올림한 것들 무시 하는 flag
    let is_all = true;

    const _index = purchasekitinfo_set.findIndex((kit_info) => {
      return kit_info.id === Number(e.target.name.split('_')[2]);
    });

    if (Number(e.target.value) >= purchasekitinfo_set[_index].left_count) {
      value = purchasekitinfo_set[_index].left_count;
    } else {
      value = Number(e.target.value);
      is_all = false;
    }

    purchasekitinfo_set[_index] = { ...purchasekitinfo_set[_index], refund_count: value };

    const add_refund_count_purchasekitinfo_set = [...purchasekitinfo_set];

    setPurchaseKitInfoSet(add_refund_count_purchasekitinfo_set);

    // 만약에 count가 전체일 경우 cancel_price 는 반올림 무시 모든 가격
    // 기존에 환불한 가격 들에서 뺸 나머지들
    if (is_all) {
      cancel_price = add_refund_count_purchasekitinfo_set
        .map((kit_info, index) => {
          if (_index === index) {
            return kit_info.our_saled_price - kit_info.discounted_price - kit_info.refund_price;
          }
          return kit_info.one_kit_price * kit_info.refund_count;
        })
        .reduce((previousValue, currentValue) => previousValue + currentValue);
    } else {
      cancel_price = add_refund_count_purchasekitinfo_set
        .map((kit_info) => kit_info.one_kit_price * kit_info.refund_count)
        .reduce((previousValue, currentValue) => previousValue + currentValue);
    }

    setKitCancelPriceInfo((prevState) => ({
      ...prevState,
      cancel_price: cancel_price,
    }));
  };

  // 구매한 강의 정보 타입에 따라 ID 만 가져오기
  const initializeRefundCourseIds = () => {
    const purchase_info = JSON.parse(props.purchase.user_data);

    if (
      purchase_info.type === 'purchase-product' ||
      purchase_info.type === 'purchase-course-ticket'
    ) {
      setRefundCourseSet(purchase_info.courseIds.map((id) => Number(id)));
      // status 가 환불이 아닐 경우에만
      if (props.purchase.purchase_status_id !== 3) {
        setExpireRefundCourseSet(purchase_info.courseIds.map((id) => Number(id)));
      }
    } else {
      setRefundCourseSet([Number(purchase_info.courseId)]);
      // status 가 환불이 아닐 경우에만
      if (props.purchase.purchase_status_id !== 3) {
        setExpireRefundCourseSet([Number(purchase_info.courseId)]);
      }
    }
  };

  const initializeState = async () => {
    initializeRefundCourseIds();
    initializePurchaseKitInfo();
  };

  const onSubmitClick = async (e) => {
    e.preventDefault();
    setIsRefundLoading((prevState) => !prevState);

    // 총 total_refund_count 환불할 개수
    const total_refund_count = purchasekitinfo_set.reduce((acc, cur) => {
      return acc + cur.refund_count;
    }, 0);

    // 남은 금액은 purchased_price 안에 이미 discount_price 가 차감된 값이 들어간다.
    const left_refund_price = props.purchase.purchased_price - props.purchase.refund_price;

    if (
      Number(else_kit_cancel_price) + Number(total_refund_count) > 0 ||
      (is_full_refund && left_refund_price !== 0)
    ) {
      const { refund_result, message, receipt_url } = await PurchaseModel.requestRefund({
        refund_reason,
        is_full_refund,
        expire_refund_course_set,
        purchasekitinfo_set,
        ...virtual_account_info,
        cancel_price: Number(kit_cancel_price_info.cancel_price) + Number(else_kit_cancel_price),
        merchant_uid: props.purchase.merchant_uid,
        imp_uid: props.purchase.imp_uid,
      });
      setRefundResult({ status: refund_result, message, receipt_url });
    } else {
      alert('환불 시, 0 원 이상 혹은 키트가 환불 되어야 합니다.');
    }
    setIsRefundLoading((prevState) => !prevState);
  };

  const courseIdToTitle = (course_id) => {
    return course_set.find((course) => course.id === Number(course_id))?.title;
  };

  const onExpireCourseCheckClick = (e) => {
    const course_id = Number(e.target.id.split('_')[2]);

    if (e.target.checked) {
      setExpireRefundCourseSet((prevState) => [...prevState, course_id]);
    } else {
      setExpireRefundCourseSet((prevState) => prevState.filter((id) => id !== course_id));
    }
  };

  const onCancelPriceChange = (e) => {
    // 키트를 제외한 것들 환불처리용
    if (
      Number(e.target.value) >
      props.purchase.purchased_price -
        props.purchase.refund_price -
        kit_cancel_price_info.available_cancel_price
    ) {
      setElseKitCancelPrice(
        String(
          props.purchase.purchased_price -
            props.purchase.refund_price -
            kit_cancel_price_info.available_cancel_price,
        ),
      );
    } else {
      setElseKitCancelPrice(e.target.value);
    }
  };

  const onFullRefundChange = (e) => {
    if (e.target.value === 'full') {
      setIsFullRefund(true);
      // 전체 선택할 경우 키트에서 뺀 환불액
      setElseKitCancelPrice(
        String(
          props.purchase.purchased_price -
            props.purchase.refund_price -
            kit_cancel_price_info.available_cancel_price,
        ),
      );
      // 전체 선택할 경우 키트 모든 가격으로 되돌리기
      setKitCancelPriceInfo((prevState) => ({
        ...prevState,
        cancel_price: prevState.available_cancel_price,
      }));
      // 전체 선택할 경우 키트 개수 원래대로
      setPurchaseKitInfoSet(
        purchasekitinfo_set.map((kit_info) => ({ ...kit_info, refund_count: kit_info.left_count })),
      );
    } else {
      setIsFullRefund(false);
    }
  };

  const onVirtualInfoChange = (e) => {
    setVirtualAccountInfo((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  useEffect(async () => {
    const data = await CommonModel.getSearch('course');
    setCourseSet(data);
    // const data = await CommonModel.getSearch("course");
    // setCourseSet(data);
    await initializeState();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (props.is_modal) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'inherit';
    };
  }, []);

  const onCloseModalBtn = () => {
    props.setModal(false);
  };

  return (
    <div style={td_modal_style}>
      <div style={td_dimmed} />
      <Card style={modal_content}>
        <CardHeader style={{ padding: '16px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h5 className='mb-0'>환불 정보</h5>
            {refund_result.status === null && (
              <i
                className='icofont icofont-ui-close'
                style={{ cursor: 'pointer' }}
                onClick={onCloseModalBtn}
              />
            )}
          </div>
        </CardHeader>
        <CardBody style={{ padding: '15px' }}>
          {isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <Loading isLoading />
            </div>
          ) : (
            <form onSubmit={onSubmitClick}>
              <Row className={'mb-3'}>
                <Col>
                  <Input
                    type={'textarea'}
                    name={'refund_reson'}
                    placeholder={'환불사유'}
                    onChange={(e) => {
                      setRefundReason(e.target.value);
                    }}
                    value={refund_reason || ''}
                    maxLength={100}
                    required
                  />
                </Col>
              </Row>
              {refund_course_set.filter((id) => id).length > 0 &&
                props.purchase.purchase_status_id === 2 && (
                  <>
                    <hr />
                    <Row>
                      <Col>
                        <div className='mb-1 f-w-900'>만료/차감 시킬 강의 선택하세요.</div>
                        <div style={{ maxHeight: '150px', overflowY: 'auto', padding: '10px 0' }}>
                          {refund_course_set.map((course_id) => {
                            return (
                              <Label key={course_id} className='d-block' for='chk-ani'>
                                <Input
                                  className='checkbox_animated'
                                  id={`course_id_${course_id}`}
                                  type='checkbox'
                                  onChange={onExpireCourseCheckClick}
                                  defaultChecked
                                />{' '}
                                <span style={{ fontSize: '13px' }}>
                                  {courseIdToTitle(course_id)}
                                </span>
                              </Label>
                            );
                          })}
                        </div>
                        <small style={{ color: 'red' }}>
                          강의 만료/차감은 최초에만 가능합니다.
                        </small>
                      </Col>
                    </Row>
                  </>
                )}
              {purchasekitinfo_set.length > 0 && (
                <>
                  <hr />
                  <Row>
                    <Col>
                      <div className='mb-1 f-w-900'>환불될 키트를 결정하세요.</div>
                      {purchasekitinfo_set.map((kit, index) => {
                        return (
                          <div key={index} style={{ marginTop: '4px' }}>
                            <div
                              style={{
                                fontSize: '12px',
                                textAlign: 'right',
                                color: '#888888',
                                marginTop: '6px',
                              }}
                            >
                              개당 {kit.real_one_kit_price} 원
                            </div>
                            <div
                              key={kit.id}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <span style={{ flexShrink: '0' }}>
                                {kit.kit_info.title} ({kit.ordered_count}개)
                              </span>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                  className='mx-2'
                                  style={{ width: '50px' }}
                                  name={`kit_id_${kit.id}`}
                                  type='text'
                                  value={String(kit.refund_count) || ''}
                                  onChange={(e) => {
                                    const regexp = /^[0-9\b]*$/;
                                    if (regexp.test(e.target.value)) {
                                      onRefundKitCountChange(e);
                                    }
                                  }}
                                  placeholder={'환불될 갯수'}
                                  disabled={is_full_refund}
                                  required
                                />
                                <span style={{ flexShrink: '0' }}>개 환불</span>
                              </div>
                            </div>
                            {!!Number(kit.refund_price) && (
                              <div
                                style={{
                                  fontSize: '12px',
                                  textAlign: 'right',
                                  color: '#888888',
                                  marginTop: '6px',
                                }}
                              >
                                이전에 환불한 개수 {kit.ordered_count - kit.left_count} 개
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                </>
              )}
              {props.purchase.purchase_paymethod_id === 4 && (
                <>
                  <hr />
                  <div className='mb-1 f-w-900'>환불계좌정보</div>
                  <div style={{ display: 'flex' }}>
                    <select
                      className='form-control'
                      style={{ width: '110px', flexShrink: '0' }}
                      name={'refund_bank'}
                      placeholder='은행'
                      value={virtual_account_info.refund_bank || ''}
                      onChange={onVirtualInfoChange}
                      required
                    >
                      <option value=''>[선택]</option>
                      <option value='90'>카카오뱅크</option>
                      <option value='89'>케이뱅크</option>
                      <option value='39'>경남은행</option>
                      <option value='34'>광주은행</option>
                      <option value='04'>국민은행</option>
                      <option value='03'>기업은행</option>
                      <option value='11'>농협중앙회</option>
                      <option value='31'>대구은행</option>
                      <option value='20'>우리은행</option>
                      <option value='32'>부산은행</option>
                      <option value='02'>산업은행</option>
                      <option value='50'>상호저축은행</option>
                      <option value='45'>새마을금고연합회</option>
                      <option value='07'>수협중앙회</option>
                      <option value='88'>신한은행</option>
                      <option value='48'>신협중앙회</option>
                      <option value='05'>외환은행</option>
                      <option value='37'>전북은행</option>
                      <option value='71'>정보통신부 우체국</option>
                      <option value='35'>제주은행</option>
                      <option value='81'>하나은행</option>
                      <option value='27'>한국씨티은행</option>
                      <option value='23'>SC은행</option>
                      <option value='12'>농협회원조합</option>
                      <option value='64'>산림조합</option>
                      <option value='26'>서울은행</option>
                    </select>
                    <Input
                      className='ml-1'
                      style={{ width: '100px' }}
                      name={'refund_holder'}
                      type='text'
                      placeholder='예금주'
                      value={virtual_account_info.refund_holder || ''}
                      onChange={onVirtualInfoChange}
                      disabled={virtual_account_info.refund_bank === ''}
                      required
                    />
                    <Input
                      className='ml-1'
                      name={'refund_account'}
                      type='text'
                      placeholder='계좌번호'
                      value={virtual_account_info.refund_account || ''}
                      onChange={(e) => {
                        const regexp = /^[0-9\b]*$/;
                        if (regexp.test(e.target.value)) {
                          onVirtualInfoChange(e);
                        }
                      }}
                      disabled={virtual_account_info.refund_bank === ''}
                      required
                    />
                  </div>
                </>
              )}
              <hr />
              <FormGroup className='m-checkbox-inline mb-0 custom-radio-ml'>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div className='radio radio-primary'>
                    <Input
                      id='radioinline1'
                      type='radio'
                      name='radio1'
                      value='full'
                      onChange={onFullRefundChange}
                      checked={is_full_refund}
                    />
                    <Label className='mb-0' for='radioinline1'>
                      <span className='digits'>전액취소</span>
                    </Label>
                  </div>
                  <div className='radio radio-primary'>
                    <Input
                      id='radioinline2'
                      type='radio'
                      name='radio1'
                      value='part'
                      onChange={onFullRefundChange}
                      checked={!is_full_refund}
                    />
                    <Label className='mb-0' for='radioinline2'>
                      <span className='digits'>부분취소</span>
                    </Label>
                  </div>
                </div>
              </FormGroup>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className='mx-2' style={{ alignSelf: 'center' }}>
                  <CustomLabel label='기타환불액' />
                  <Input
                    style={{ width: '120px' }}
                    name={'else_kit_cancel_price'}
                    type='text'
                    value={else_kit_cancel_price || ''}
                    onChange={(e) => {
                      const regexp = /^[0-9\b]*$/;
                      if (regexp.test(e.target.value)) {
                        onCancelPriceChange(e);
                      }
                    }}
                    placeholder={'기타취소가격'}
                    disabled={is_full_refund}
                    required
                  />{' '}
                </div>
                <div className='mx-2' style={{ alignSelf: 'center' }}>
                  <CustomLabel label='키트환불액' />
                  <Input
                    style={{ width: '120px' }}
                    name={'kit_cancel_price_info'}
                    type='text'
                    value={String(kit_cancel_price_info.cancel_price) || ''}
                    placeholder={'키트취소가격'}
                    disabled
                  />{' '}
                </div>
                <div>
                  <div className='my-2 p-1' style={{ border: '1px solid', borderRadius: '10px' }}>
                    <div style={{ textAlign: 'center' }}>총 환불 가능 금액</div>
                    <div className='mx-2'>
                      <div>
                        기타 :{' '}
                        {String(
                          initComma(
                            props.purchase.purchased_price -
                              props.purchase.refund_price -
                              Number(kit_cancel_price_info.available_cancel_price),
                          ),
                        )}
                      </div>
                      <div>
                        키트 :{' '}
                        {String(initComma(Number(kit_cancel_price_info.available_cancel_price)))}
                      </div>
                      <div>
                        총 :{' '}
                        {String(
                          initComma(props.purchase.purchased_price - props.purchase.refund_price),
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='my-2 p-1' style={{ border: '1px solid', borderRadius: '10px' }}>
                    <div style={{ textAlign: 'center' }}>환불할 총 금액</div>
                    <div style={{ textAlign: 'center' }}>
                      {initComma(
                        Number(kit_cancel_price_info.cancel_price) + Number(else_kit_cancel_price),
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className='mb-1 f-w-900'>결제 취소 및 환불처리하시겠습니까?</div>
              <div className='mt-3' style={{ display: 'flex', justifyContent: 'center' }}>
                {is_refund_loading ? (
                  <div style={{ textAlign: 'center' }}>
                    <Loading isLoading />
                  </div>
                ) : refund_result.status === null ? (
                  <Button type='submit' className='btn-danger btn-pill'>
                    적용
                  </Button>
                ) : refund_result.status === true ? (
                  <div style={{ textAlign: 'center' }}>
                    {refund_result.receipt_url && (
                      <div>
                        <a target={'_blank'} rel='noreferrer' href={refund_result.receipt_url}>
                          영수증보기
                        </a>
                      </div>
                    )}
                    <div>환불성공😀 : {refund_result.message}</div>
                    <div
                      style={{ cursor: 'pointer', color: '#7e37d8' }}
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      완료
                    </div>
                  </div>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <div>환불실패😥 : {refund_result.message}</div>
                    <div
                      style={{ cursor: 'pointer', color: '#7e37d8' }}
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      다시시도
                    </div>
                  </div>
                )}
              </div>
            </form>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default RefundModal;

const td_modal_style = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  zIndex: '9999',
  left: '0',
  top: '0',
};

const td_dimmed = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

const modal_content = {
  width: '100%',
  maxWidth: '480px',
  maxHeight: '1000px',
  backgroundColor: '#ffffff',
  position: 'absolute',
  zIndex: '1002',
  left: '50%',
  top: '50%',
  borderRadius: '10px',
  transform: 'translate(-50%, -50%)',
};
