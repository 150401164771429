export const total_progress_data = {
  series: [],
  options: {
    chart: {
      height: 350,
      type: 'radialBar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: '#fff',
          strokeWidth: '100%',
          margin: 0, // margin is in pixels
          opacity: 0.03,
          color: '#fff',
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 10,
            opacity: 1,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -20,
            show: true,
            color: '#111',
            fontSize: '14px',
          },
          value: {
            offsetY: 8,
            formatter: function (val) {
              return parseInt(val) + '%';
            },
            color: '#111',
            fontSize: '36px',
            show: true,
          },
        },
      },
    },
    colors: ['#4800ff'],
    stroke: {
      lineCap: 'round',
    },
    labels: ['총 진도율'],
  },
};

export const top_5_chart_data = {
  series: [
    {
      name: [],
      data: [],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      height: 'auto',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: '50%',
        horizontal: true,
        borderRadius: 5,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -10,
      style: {
        fontSize: '14px',
        colors: ['#fff'],
      },
      formatter: function (val) {
        return parseInt(val) + '%';
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#ddd'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: [],
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    colors: ['#4800ff'],
    grid: {
      show: false,
    },
  },
};

export const monthly_progress_data = {
  series: [
    {
      name: '총 학습 시간 (분)',
      type: 'column',
      data: [],
    },
    {
      name: '학습 인원 (명)',
      type: 'line',
      data: [],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: true,
        offsetX: -15,
        offsetY: -20,
        tools: {
          download: `<img
                            src="https://s3.ap-northeast-2.amazonaws.com/dev-img.bear-u.com/b2b_static/cms/comon/ic_download_b.png"
                            alt="다운로드 아이콘"
                            width="35"
                        />`,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            columnDelimiter: ',',
            headerCategory: '학습 년/월',
            headerValue: 'value',
          },
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '25%',
        borderRadius: 5,
      },
      show: true,
    },
    stroke: {
      width: [0, 5],
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      background: {
        padding: 8,
      },
    },
    labels: [],
    xaxis: {
      type: 'category',
    },
    yaxis: [
      {
        title: {
          text: '총 학습 시간 (분)',
        },
      },
      {
        opposite: true,
        title: {
          text: '학습 인원 (명)',
        },
      },
    ],
    colors: ['#b092f6', '#4800ff'],
  },
};
