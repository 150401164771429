import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Collapse } from 'reactstrap';
import Apexcharts from 'react-apexcharts';
import { top_5_chart_data } from '../../../constant/chart_option';
import Loading from '../../_common/Loading';

/*
    props
    company_graph_data: {}      기업의 그래프 정보
 */
const Top5ProgressChart = (props) => {
  // state
  const [is_chart_spread, setChartSpread] = useState(true);
  const [chart_series, setChartSeries] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [is_data_empty, setIsDataEmpty] = useState(false);

  // ref
  const chart_options = useRef(top_5_chart_data.options);

  useEffect(() => {
    if (props.company_graph_data) {
      if (!props.company_graph_data.top_5_attendance_rate.length) {
        setIsDataEmpty(true);
      } else {
        const category = [];
        const series = [];
        props.company_graph_data.top_5_attendance_rate.map((one_data) => {
          category.push(one_data.short_title);
          series.push(Math.round(one_data.attendance_rate * 100));
          return null;
        });

        chart_options.current.xaxis.categories = category;
        setChartSeries([{ data: series, name: '진행률(%)' }]);
      }

      setIsLoading(false);
    }
  }, [props.company_graph_data]);

  return (
    <Card>
      <div className='default-according style-1' id='accordionoc'>
        <CardHeader style={{ padding: '20px 20px 20px 30px', lineHeight: '38px' }}>
          <Button
            color='black pl-0'
            data-toggle='collapse'
            onClick={() => setChartSpread(!is_chart_spread)}
            data-target='#collapseicon'
            aria-expanded={is_chart_spread}
            aria-controls='collapseicon'
          >
            <h5 className='mb-0'>진도율 Top 5</h5>
          </Button>
        </CardHeader>
      </div>
      <Collapse isOpen={is_chart_spread}>
        <CardBody className='p-1'>
          <div className='top5'>
            {isLoading ? (
              <div className='text-center'>
                <Loading
                  isLoading={isLoading}
                  custom_style={{ display: 'flex' }}
                  is_card_body={true}
                />
              </div>
            ) : is_data_empty ? (
              <Col
                md={'12'}
                className={'text-primary text-center p-30'}
                style={{ fontSize: '16px', fontWeight: 'bold' }}
              >
                현재 수강중 또는 학습하신 강의가 없습니다
              </Col>
            ) : (
              <Apexcharts
                type='bar'
                options={chart_options.current}
                series={chart_series}
                height={267}
              />
            )}
          </div>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default Top5ProgressChart;
