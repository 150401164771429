import React from 'react';
import { Input } from 'reactstrap';
import '../../../assets/scss/common/cmscustominput.scss';
import PropTypes from 'prop-types';
import CustomLabel from './CustomLabel';

const CustomInput = (props) => {
  const { validator, is_required, ...input_props } = props;

  return (
    <div className='mb-2'>
      {props.label && (
        <CustomLabel
          tooltip_desc={props.tooltip_desc}
          label={props.label}
          is_required={is_required}
        />
      )}
      <Input
        innerRef={(el) => {
          if (el) el.style.setProperty('border-radius', '0.25rem', 'important');
        }}
        {...input_props}
        {...(props.type === 'number' && { min: 0 })}
        onChange={(e) => {
          if (props.validator) {
            if (props.validator(e.target.value)) {
              props.onChange(e);
            }
          } else {
            props.onChange(e);
          }
        }}
      />
    </div>
  );
};

CustomInput.propTypes = {
  props: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    tooltip_desc: PropTypes.string,
    is_required: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.array,
  }),
};

export default CustomInput;
