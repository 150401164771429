import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Table,
  Collapse,
  Input,
  InputGroupAddon,
  Label,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import AccountModel from '../../model/AccountModel';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import { accountBadgeStyle, accountGender, initComma } from '../../util/common';
import CustomDateRangeFilter from '../_common/component/CustomDateRangeFilter';
import { PAGE_SIZE } from '../../constants';

const Account = (props) => {
  // 기존 props.history.push 로는 state를 가져온다 / 직접 url에 값을 때려 박으면 state에 값이 없어서 search에서 가져와야한다 / 이도저도 아닐 경우
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    id: params.id ? params.id : '',
    name: params.name ? params.name : '',
    account_name: params.account_name ? params.account_name : '',
    nickname: params.nickname ? params.nickname : '',
    email: params.email ? params.email : '',
    phone: params.phone ? params.phone : '',
    gender: params.gender ? params.gender : '',
    age_start: params.age_start ? params.age_start : '',
    age_end: params.age_end ? params.age_end : '',
    job_class: params.job_class ? params.job_class : '',
    account_provider: params.account_provider ? params.account_provider : '',
    account_status: params.account_status ? params.account_status : '',
    account_type: params.account_type ? params.account_type : '',
    has_course: params.has_course ? params.has_course : '',
    has_purchase: params.has_purchase ? params.has_purchase : '',
    date_start: params.date_start ? params.date_start : '',
    date_end: params.date_end ? params.date_end : '',
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const [account_set, setAccountSet] = useState([]);
  const [account_count, setAccountCountSet] = useState(0);

  const [provider_type_set, setProviderTypeSet] = useState([]);
  const [account_status_set, setAccountStatusSet] = useState([]);
  const [account_type_set, setAccountTypeSet] = useState([]);
  const [job_set, setJobSet] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [is_download, setDownload] = useState(false);
  const [is_filer_spread, setIsFilterSpread] = useState(!!params.is_filer_spread);
  const [filter_set, setFilter] = useState(default_filter.current);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onCSVRequset = async (e) => {
    if (!is_download) {
      e.target.disabled = true;
      setDownload(true);

      const _a = document.createElement('a');
      _a.style = 'display: none';
      const _file = await AccountModel.getCSV({
        ...params,
      });

      const url = window.URL.createObjectURL(_file.data);
      _a.href = url;
      _a.download = `user-info-${moment(new Date()).format('YYYY-MM-DD')}.csv`;
      _a.click();
      window.URL.revokeObjectURL(url);

      e.target.disabled = false;
      setDownload(false);
    }
  };

  const onFilterSubmit = (e) => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
      is_filer_spread: is_filer_spread,
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onPhoneChange = (e) => {
    const regexp = /^[0-9 \-\b]+$/;
    let phone = e.target.value.replaceAll('-', '').replaceAll(' ', '');

    if (phone === '' || regexp.test(phone)) {
      setFilter({ ...filter_set, phone: phone });
    }
    return phone;
  };

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      order_key: order_key,
      is_filer_spread: is_filer_spread,
      order_type: !filter_set.order_type || filter_set.order_type === 'asc' ? 'desc' : 'asc', // 정렬을 기존 기준 -> "없을 경우 or asc = desc로 변경" / "desc 일 경우 = asc 로 변경"
    };

    // 이전에 정렬한적 있고, 새로운 정렬을 적용.
    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }

    pushHistory(newFilterSet);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      is_filer_spread: is_filer_spread,
    });
    window.scrollTo(0, 0);
  };

  // @TODO 창우 - 어디로 갈지 생각
  const toQueryString = (object) => {
    let str = '';
    for (let key in object) {
      if (object[key]) {
        if (str) {
          str += '&';
        }
        str += key + '=' + encodeURIComponent(object[key]);
      }
    }
    return str;
  };

  const staticIdToName = (static_type, int) => {
    let name = null;

    if (static_type === 'job_class') {
      job_set.forEach((job_class) => {
        if (job_class.id === Number(int)) {
          name = job_class.name;
          return false;
        }
      });
    } else if (static_type === 'provider_type') {
      provider_type_set.forEach((provider_type) => {
        if (provider_type.id === Number(int)) {
          name = provider_type.name;
          return false;
        }
      });
    } else if (static_type === 'account_status_type') {
      account_status_set.forEach((account_status_type) => {
        if (account_status_type.id === Number(int)) {
          name = account_status_type.name;
          return false;
        }
      });
    } else if (static_type === 'account_type') {
      account_type_set.forEach((account_type) => {
        if (account_type.id === Number(int)) {
          name = account_type.name;
          return false;
        }
      });
    }

    return name;
  };

  const memo_account_set = useMemo(() => {
    return account_set.map((account) => {
      let url = 'account/' + account.id;
      const onTdClick = () => {
        props.history.push(url);
      };
      return (
        <tr key={account.id} onClick={onTdClick} style={{ cursor: 'pointer' }}>
          <td className='text-center' style={{ verticalAlign: 'middle' }}>
            {account.id}
          </td>
          <td className='text-center' style={{ verticalAlign: 'middle' }}>
            <small>{staticIdToName('provider_type', account.account_provider_id)}</small>
            <br />
            <span className={accountBadgeStyle(parseInt(account.account_status_id))}>
              {staticIdToName('account_status_type', account.account_status_id)}
            </span>
          </td>

          <td style={{ verticalAlign: 'middle' }}>
            {account.account_name}
            <br />
            {account.info ? account.info.email || '-' : '-'}
          </td>
          <td className='text-center' style={{ verticalAlign: 'middle' }}>
            <small>{staticIdToName('account_type', account.account_type_id)}</small>
            <br />
            {account.info ? account.info.name || '-' : '-'}
          </td>
          <td className='text-center' style={{ verticalAlign: 'middle' }}>
            {account.info ? accountGender(account.info.gender) || '-' : '-'}
          </td>
          <td className='text-center' style={{ verticalAlign: 'middle' }}>
            {account.info ? account.info.birth || '-' : '-'}
          </td>
          <td className='text-center' style={{ verticalAlign: 'middle' }}>
            {account.info ? account.info.phone || '-' : '-'}
          </td>
          <td
            className='text-center'
            style={{
              verticalAlign: 'middle',
              fontSize: '12px',
            }}
          >
            {account.info ? account.info.job_class || '-' : '-'}
          </td>
          <td
            style={{
              verticalAlign: 'middle',
              fontSize: '12px',
            }}
          >
            {account.info ? account.info.address : ''}
          </td>
          <td className='text-center' style={{ verticalAlign: 'middle' }}>
            {account.has_course_count}
          </td>
          <td className='text-center' style={{ verticalAlign: 'middle' }}>
            {account.create_time}
          </td>
        </tr>
      );
    });
  }, [account_set]);

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'job_class_set' }).then(({ code_set }) => {
      setJobSet(code_set);
    });

    CommonModel.staticCode({ code_name: 'provider_type_set' }).then(({ code_set }) => {
      setProviderTypeSet(code_set);
    });

    CommonModel.staticCode({ code_name: 'account_status_type_set' }).then(({ code_set }) => {
      setAccountStatusSet(code_set);
    });

    CommonModel.staticCode({ code_name: 'account_type_set' }).then(({ code_set }) => {
      setAccountTypeSet(code_set);
    });
  }, []);

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    AccountModel.getList({
      ...params,
      // paging 초기 값
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ account, total_count }) => {
      setAccountSet(account);
      setAccountCountSet(total_count);
      setIsLoading(false);
    });
  }, [props.location.search]);

  return (
    <Fragment>
      <Breadcrumb parent='사용자' title='사용자목록' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <div className='default-according style-1' id='accordionoc'>
                <CardHeader style={{ padding: '12px 30px' }}>
                  <Button
                    color='black pl-0'
                    data-toggle='collapse'
                    onClick={() => setIsFilterSpread(!is_filer_spread)}
                    data-target='#collapseicon'
                    aria-expanded={is_filer_spread}
                    aria-controls='collapseicon'
                  >
                    <h5 className='mb-0'>검색필터</h5>
                  </Button>
                </CardHeader>
              </div>
              <Collapse isOpen={is_filer_spread}>
                <CardBody>
                  <div>
                    <div className='form-row form-group'>
                      <Col md='1'>
                        <Label htmlFor='id'>유저 번호</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='id'
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit(e);
                            }
                          }}
                          value={filter_set.id}
                          placeholder='유저 번호'
                        />
                      </Col>
                      <Col md='1'>
                        <Label htmlFor='account_provider'>계정종류</Label>
                        <Input
                          type='select'
                          name='account_provider'
                          className='form-control'
                          onChange={onFilterChange}
                          value={filter_set.account_provider}
                        >
                          <option value=''>전체</option>
                          {provider_type_set.map((provider_type) => {
                            return (
                              <option key={provider_type.id} value={provider_type.id}>
                                {provider_type.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                      <Col md='1'>
                        <Label htmlFor='gender'>성별</Label>
                        <Input
                          type='select'
                          name='gender'
                          onChange={onFilterChange}
                          className='form-control'
                          value={filter_set.gender}
                        >
                          <option value=''>전체</option>
                          <option value='male'>남자</option>
                          <option value='female'>여자</option>
                        </Input>
                      </Col>
                      <Col md='2'>
                        <Label htmlFor='account_name'>계정명</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='account_name'
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit(e);
                            }
                          }}
                          value={filter_set.account_name}
                          placeholder='계정명'
                        />
                      </Col>
                      <Col md='2'>
                        <Label htmlFor='name'>이름</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='name'
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit(e);
                            }
                          }}
                          value={filter_set.name}
                          placeholder='이름'
                        />
                      </Col>
                      <Col md='2'>
                        <Label htmlFor='nickname'>닉네임</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='nickname'
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit(e);
                            }
                          }}
                          value={filter_set.nickname}
                          placeholder='닉네임'
                        />
                      </Col>
                      <Col md='3'>
                        <Label htmlFor='email'>이메일</Label>
                        <InputGroup>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>@</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className='form-control'
                            type='email'
                            name='email'
                            onChange={onFilterChange}
                            onKeyDown={(e) => {
                              if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                                onFilterSubmit(e);
                              }
                            }}
                            value={filter_set.email}
                            placeholder='이메일'
                          />
                        </InputGroup>
                      </Col>
                    </div>
                    <hr style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }} />
                    <div className='form-row form-group'>
                      <Col md='2'>
                        <Label htmlFor='account_type'>회원등급</Label>
                        <Input
                          type='select'
                          name='account_type'
                          className='form-control'
                          onChange={onFilterChange}
                          value={filter_set.account_type}
                        >
                          <option value=''>전체</option>
                          {account_type_set.map((account_type) => {
                            return (
                              <option key={account_type.id} value={account_type.id}>
                                {account_type.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                      <Col md='2'>
                        <Label htmlFor='accountStatusId'>계정상태</Label>
                        <Input
                          type='select'
                          name='account_status'
                          className='form-control'
                          onChange={onFilterChange}
                          value={filter_set.account_status}
                        >
                          <option value=''>전체</option>
                          {account_status_set.map((account_status) => {
                            return (
                              <option key={account_status.id} value={account_status.id}>
                                {account_status.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                      <Col md='2'>
                        <Label htmlFor='job_class'>직종정보</Label>
                        <Input
                          type='select'
                          name='job_class'
                          className='form-control'
                          onChange={onFilterChange}
                          value={filter_set.job_class}
                        >
                          <option value=''>전체</option>
                          {job_set.map((job) => {
                            return (
                              <option key={job.id} value={job.id}>
                                {job.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                      <Col md='2'>
                        <Label htmlFor='age'>나이 범위</Label>
                        <InputGroup>
                          <Input
                            className='form-control'
                            name='age_start'
                            type='number'
                            placeholder='시작'
                            onChange={onFilterChange}
                            onKeyDown={(e) => {
                              if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                                onFilterSubmit(e);
                              }
                            }}
                            value={filter_set.age_start}
                            aria-describedby='inputGroupPrepend3'
                          />
                          <Input
                            className='form-control'
                            name='age_end'
                            type='number'
                            placeholder='끝'
                            onChange={onFilterChange}
                            onKeyDown={(e) => {
                              if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                                onFilterSubmit(e);
                              }
                            }}
                            value={filter_set.age_end}
                            aria-describedby='inputGroupPrepend3'
                          />
                        </InputGroup>
                      </Col>
                      <Col md='2'>
                        <Label htmlFor='phone'>휴대폰번호</Label>
                        <Input
                          className='form-control digits'
                          type='text'
                          name='phone'
                          onChange={onPhoneChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit(e);
                            }
                          }}
                          value={filter_set.phone}
                          placeholder='휴대폰번호'
                        />
                      </Col>
                      <Col md='1'>
                        <Label htmlFor='hasCourse'>강의 보유여부</Label>
                        <Input
                          type='select'
                          name='has_course'
                          className='form-control'
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit(e);
                            }
                          }}
                          value={filter_set.has_course}
                        >
                          <option value=''>전체</option>
                          <option value='1'>아니요</option>
                          <option value='2'>예</option>
                        </Input>
                      </Col>
                      <Col md='1'>
                        <Label htmlFor='hasPurchase'>실결제 여부</Label>
                        <Input
                          type='select'
                          name='has_purchase'
                          className='form-control'
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit(e);
                            }
                          }}
                          value={filter_set.has_purchase}
                        >
                          <option value=''>전체</option>
                          <option value='1'>아니요</option>
                          <option value='2'>예</option>
                        </Input>
                      </Col>
                    </div>
                    <hr style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }} />
                    <div className='form-row'>
                      <Col md={'5'}>
                        <CustomDateRangeFilter setFilter={setFilter} filter_set={filter_set} />
                      </Col>
                      <Col md='5' />
                      <Col md='1'>
                        <Label htmlFor='size'>
                          검색단위 <small>(개수)</small>
                        </Label>
                        <Input
                          className='form-control'
                          name='size'
                          type='number'
                          placeholder='검색단위'
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit(e);
                            }
                          }}
                          value={filter_set.size}
                        />
                      </Col>

                      <Col className='text-right mt-auto'>
                        <Button
                          className='btn btn-primary btn-pill'
                          color='success'
                          onClick={(e) => {
                            onFilterSubmit(e);
                          }}
                        >
                          검색
                        </Button>
                      </Col>
                    </div>
                  </div>
                </CardBody>
              </Collapse>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={8} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총: <span style={{ color: 'coral' }}>{initComma(account_count)}</span>개
                        </p>
                        <Loading isLoading={isLoading || is_download} />
                      </Col>
                      <Col className='text-right'>
                        <Button
                          className='btn btn-primary btn-pill'
                          color='info'
                          onClick={onCSVRequset}
                        >
                          CSV 다운로드
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <div className='table-responsive'>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th
                        style={{
                          width: '80px',
                        }}
                      >
                        ID
                      </th>
                      <th
                        style={{
                          width: '120px',
                        }}
                      >
                        가입/상태
                      </th>
                      <th
                        style={{
                          width: '250px',
                        }}
                      >
                        계정명/이메일
                      </th>
                      <th
                        style={{
                          width: '100px',
                        }}
                      >
                        등급/이름
                      </th>
                      <th
                        style={{
                          width: '80px',
                        }}
                      >
                        성별
                      </th>
                      <th
                        onClick={() => {
                          onOrderChange('birth');
                        }}
                        style={{ width: '110px' }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'birth' ? (
                          <span style={{ color: '#ff4c3b' }}>생년월일</span>
                        ) : (
                          <span>생년월일</span>
                        )}
                      </th>
                      <th style={{ width: '120px' }}>휴대폰</th>
                      <th style={{ width: '100px' }}>직종</th>
                      <th style={{ width: '230px' }}>주소</th>
                      <th
                        style={{
                          width: '90px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('has_course_count');
                        }}
                      >
                        {filter_set.order_key === 'has_course_count' ? (
                          <span style={{ color: '#ff4c3b' }}>보유강의</span>
                        ) : (
                          <span>보유강의</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '150px',
                        }}
                        onClick={() => {
                          onOrderChange('id');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'id' ? (
                          <span style={{ color: '#ff4c3b' }}>생성일</span>
                        ) : (
                          <span>생성일</span>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{memo_account_set}</tbody>
                </Table>
              </div>

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size ? account_count / params.size : account_count / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Account;
