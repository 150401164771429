import axios from 'axios';

class EventModel {
  async getEvent(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/event', {
      params: filter,
    });
    return data;
  }

  async postEvent(event) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/event', event);
    return data;
  }

  async getEventDetail(event_id) {
    if (event_id) {
      const {
        data: { data },
      } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/event/' + event_id);
      return data;
    } else {
      return { event: false };
    }
  }

  async putEventDetail(event) {
    const {
      data: { data },
    } = await axios.put(process.env.REACT_APP_API_URL + '/cms-api/v1/event/' + event.id, event);
    return data;
  }

  async checkEventCode(event_code) {
    const {
      data: { data },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/event/unique-check/' + event_code,
    );
    return data;
  }

  async expireEvent(event_id) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/event/' + event_id + '/expire-time',
    );
    return data;
  }

  async sendEvent(event) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/event/event-send', event);
    return data;
  }

  async multipleEvent(event) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/event/event-multiple', event);
    return data;
  }
}

export default new EventModel();
