import React, { Fragment, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import { initComma, onBooleanBadgeStyle, toQueryString } from '../../util/common';
import moment from 'moment';

import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import queryString from 'query-string';
import CourseModel from '../../model/CourseModel';
import { Link } from 'react-router-dom';

const Curation = (props) => {
  // 기존 props.history.push 로는 state를 가져온다 / 직접 url에 값을 때려 박으면 state에 값이 없어서 search에서 가져와야한다 / 이도저도 아닐 경우
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : 10,
    search_text: params.search_text ? params.search_text : '',
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const [curation_set, setCurationSet] = useState([]);
  const [curation_count, setCurationCountSet] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilter] = useState(default_filter.current);

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    CourseModel.getCuration({
      ...params,
    }).then(({ curation, total_count }) => {
      setCurationSet(curation);
      setCurationCountSet(total_count);
      setIsLoading(false);
    });
  }, [props.location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onFilterSubmit = (e) => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      order_key: order_key,
      order_type: !filter_set.order_type || filter_set.order_type === 'asc' ? 'desc' : 'asc', // 정렬을 기존 기준 -> "없을 경우 or asc = desc로 변경" / "desc 일 경우 = asc 로 변경"
    };

    // 이전에 정렬한적 있고, 새로운 정렬을 적용.
    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }

    pushHistory(newFilterSet);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <Breadcrumb parent='서비스' title='큐레이션 목록' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Col className='text-right mb-4'>
              <Link className={'btn btn-primary btn-pill'} to={'/curation/0'}>
                큐레이션생성
              </Link>
            </Col>
            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={9} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총: <span style={{ color: 'coral' }}>{initComma(curation_count)}</span>개
                        </p>
                        <Loading isLoading={isLoading} />
                      </Col>
                      <Col md={3} className='text-right'>
                        <FormGroup>
                          <InputGroup>
                            <Input
                              className='form-control'
                              type='text'
                              name='search_text'
                              onChange={onFilterChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onFilterSubmit(e);
                                }
                              }}
                              value={filter_set.search_text}
                              placeholder='제목 및 설명을 입력하세요.'
                            />
                            <InputGroupAddon
                              addonType='append'
                              style={{ cursor: 'pointer' }}
                              onClick={onFilterSubmit}
                            >
                              <InputGroupText>
                                <i className='icofont icofont-ui-search' />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <div className='table-responsive'>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th style={{ width: '70px' }}>ID</th>
                      <th className='text-left' style={{}}>
                        제목/짧은설명
                      </th>
                      <th style={{ width: '150px' }}>유튜브ID</th>
                      <th
                        onClick={() => {
                          onOrderChange('update_time');
                        }}
                        style={{ width: '103px' }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'update_time' ? (
                          <span style={{ color: '#ff4c3b' }}>갱신일</span>
                        ) : (
                          <span>갱신일</span>
                        )}
                      </th>
                      <th
                        onClick={() => {
                          onOrderChange('id');
                        }}
                        style={{ width: '103px' }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'id' ? (
                          <span style={{ color: '#ff4c3b' }}>생성일</span>
                        ) : (
                          <span>생성일</span>
                        )}
                      </th>
                      <th
                        onClick={() => {
                          onOrderChange('is_enabled');
                        }}
                        style={{ width: '100px' }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'is_enabled' ? (
                          <span style={{ color: '#ff4c3b' }}>활성화</span>
                        ) : (
                          <span>활성화</span>
                        )}
                      </th>
                      <th
                        onClick={() => {
                          onOrderChange('show_in_application');
                        }}
                        style={{ width: '140px' }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'show_in_application' ? (
                          <span style={{ color: '#ff4c3b' }}>서비스내표시여부</span>
                        ) : (
                          <span>서비스내표시여부</span>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {curation_set.map((curation) => {
                      let url = 'curation/' + curation.id;
                      const onTdClick = () => {
                        props.history.push(url);
                      };
                      return (
                        <tr key={curation.id}>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {curation.id}
                          </td>
                          <td style={{ verticalAlign: 'middle' }} role='button' onClick={onTdClick}>
                            <div className={'f-w-900'}>{curation.title}</div>
                            <div>{curation.short_description}</div>
                          </td>
                          <td className='text-center' style={{ verticalAlign: 'middle' }}>
                            <a
                              href={`https://www.youtube.com/watch?v=${curation.youtube_id}`}
                              target='_blank'
                              rel='noreferrer'
                            >
                              {curation.youtube_id || '-'}
                            </a>
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {curation.update_time &&
                              moment(new Date(curation.update_time)).format('YYYY-MM-DD')}
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(curation.create_time)).format('YYYY-MM-DD')}
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {curation.is_enabled ? (
                              <span className={onBooleanBadgeStyle(curation.is_enabled)}>
                                활성화
                              </span>
                            ) : (
                              <span className={onBooleanBadgeStyle(curation.is_enabled)}>
                                비활성화
                              </span>
                            )}
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {curation.show_in_application ? (
                              <span className={onBooleanBadgeStyle(curation.show_in_application)}>
                                노출
                              </span>
                            ) : (
                              <span className={onBooleanBadgeStyle(curation.show_in_application)}>
                                숨김
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size ? curation_count / params.size : curation_count / 10,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Curation;
