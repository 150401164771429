import React from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import CompanyListTable from './component/CompanyListTable';
import SearchBarInput from '../_common/component/SearchBarInput';
import { TABLE_HEAD_COMPANY } from '../../constant/options';
import { useDispatch } from 'react-redux';
import { useMountEffect } from '../../util/b2b_Hooks';
import { clearTemporary } from '../../redux/b2b_store/temporary/actions';
import { clearCompany } from '../../redux/b2b_store/company/actions';

const CompanyList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useMountEffect(() => {
    // 기업리스트 페이지로 돌아올 때마다 temp_reducer 초기화 -> 기업등록하다가 다시 뒤로갔을 경우
    dispatch(clearTemporary());
    dispatch(clearCompany());
  });

  return (
    <Container fluid={true}>
      <Row>
        <Col xl='12' className='text-right'>
          <Button
            className='btn-pill btn-air-primary m-t-20'
            color='primary'
            onClick={() => {
              history.push('/add-company');
            }}
            style={{
              padding: '5px 15px 5px 15px',
            }}
          >
            기업 추가
          </Button>
        </Col>

        <Col
          xl='12'
          className='m-t-20'
          style={{
            padding: '0',
          }}
        >
          <Card>
            <CardBody
              style={{
                padding: '10px 0',
              }}
            >
              <SearchBarInput />
              <Col md='12' className='m-t-3'>
                <div className='table-responsive'>
                  <CompanyListTable table_head={TABLE_HEAD_COMPANY} />
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CompanyList;
