import React from 'react';
import '../../assets/scss/common/loading.scss';

const Loading = (props) => {
  let load_compo;

  if (props.isLoading) {
    load_compo = (
      <div
        className='loader-box'
        style={props.custom_style ? props.custom_style : { marginLeft: '8px', height: 'auto' }}
      >
        <div
          className={props.type ? 'loader-white' : 'loader-19'}
          style={props.is_card_body && { width: '30px', height: '30px' }}
        />
      </div>
    );
  } else {
    load_compo = <span className='complete_text'>처리완료</span>;
  }

  return <>{load_compo}</>;
};

export default Loading;
