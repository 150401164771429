import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import Loading from '../Loading';
import CommonModel from '../../../model/CommonModel';
import PurchaseModel from '../../../model/PurchaseModel';

const CourseExpireModal = (props) => {
  const [expire_course_set, setExpireCourseSet] = useState([]);

  const [selected_expire_course_set, setSelectedExpiredCourseSet] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [is_expire_loading, setIsExpireLoading] = useState(false);
  const [expire_result, setExpireResult] = useState({
    status: null,
    message: '',
  });

  const [course_set, setCourseSet] = useState([]);

  // 구매한 강의 정보 타입에 따라 ID 만 가져오기
  const initializeExpireCourseIds = () => {
    const purchase_info = JSON.parse(props.purchase.user_data);

    if (
      purchase_info.type === 'purchase-product' ||
      purchase_info.type === 'purchase-course-ticket'
    ) {
      setExpireCourseSet(purchase_info.courseIds.map((id) => Number(id)));
      // status 가 환불이 아닐 때, 선택된 값 없도록 설정하기 위함
      if (props.purchase.purchase_status_id !== 3) {
        setSelectedExpiredCourseSet(purchase_info.courseIds.map((id) => Number(id)));
      }
    } else {
      setExpireCourseSet([Number(purchase_info.courseId)]);
      // status 가 환불이 아닐 때, 선택된 값 없도록 설정하기 위함
      if (props.purchase.purchase_status_id !== 3) {
        setSelectedExpiredCourseSet([Number(purchase_info.courseId)]);
      }
    }
  };

  const initializeState = async () => {
    initializeExpireCourseIds();
  };

  const onSubmitClick = async (e) => {
    e.preventDefault();
    setIsExpireLoading((prevState) => !prevState);

    const { expire_result, message } = await PurchaseModel.courseExpire({
      selected_expire_course_set,
      merchant_uid: props.purchase.merchant_uid,
    });
    setExpireResult({ status: expire_result, message });

    setIsExpireLoading((prevState) => !prevState);
  };

  const courseIdToTitle = (course_id) => {
    return course_set.find((course) => course.id === Number(course_id))?.title;
  };

  const onExpireCourseCheckClick = (e) => {
    const course_id = Number(e.target.id.split('_')[2]);

    if (e.target.checked) {
      setSelectedExpiredCourseSet((prevState) => [...prevState, course_id]);
    } else {
      setSelectedExpiredCourseSet((prevState) => prevState.filter((id) => id !== course_id));
    }
  };

  useEffect(async () => {
    const data = await CommonModel.getSearch('course');
    setCourseSet(data);

    await initializeState();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (props.is_modal) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'inherit';
    };
  }, []);

  const onCloseModalBtn = () => {
    props.setModal(false);
  };

  return (
    <div style={td_modal_style}>
      <div style={td_dimmed} />
      <Card style={modal_content}>
        <CardHeader style={{ padding: '16px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h5 className='mb-0'>회수 정보</h5>
            {expire_result.status === null && (
              <i
                className='icofont icofont-ui-close'
                style={{ cursor: 'pointer' }}
                onClick={onCloseModalBtn}
              />
            )}
          </div>
        </CardHeader>
        <CardBody style={{ padding: '15px' }}>
          {isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <Loading isLoading />
            </div>
          ) : (
            <form onSubmit={onSubmitClick}>
              {expire_course_set.filter((id) => id).length > 0 &&
                props.purchase.purchase_status_id === 2 && (
                  <Row>
                    <Col>
                      <div className='mb-1 f-w-900'>만료/차감 시킬 강의 선택하세요.</div>
                      <div style={{ maxHeight: '150px', overflowY: 'auto', padding: '10px 0' }}>
                        {expire_course_set.map((course_id) => {
                          return (
                            <Label key={course_id} className='d-block' for='chk-ani'>
                              <Input
                                className='checkbox_animated'
                                id={`course_id_${course_id}`}
                                type='checkbox'
                                onChange={onExpireCourseCheckClick}
                                defaultChecked
                              />{' '}
                              <span style={{ fontSize: '13px' }}>{courseIdToTitle(course_id)}</span>
                            </Label>
                          );
                        })}
                      </div>
                      <small style={{ color: 'red' }}>강의 만료/차감은 최초에만 가능합니다.</small>
                    </Col>
                  </Row>
                )}

              <hr />

              <div className='mt-3' style={{ display: 'flex', justifyContent: 'center' }}>
                {is_expire_loading ? (
                  <div style={{ textAlign: 'center' }}>
                    <Loading isLoading />
                  </div>
                ) : expire_result.status === null ? (
                  <Button
                    type='submit'
                    className='btn-danger btn-pill'
                    disabled={!selected_expire_course_set.length}
                  >
                    적용
                  </Button>
                ) : expire_result.status === true ? (
                  <div style={{ textAlign: 'center' }}>
                    <div>회수성공😀</div>
                    <div
                      style={{ cursor: 'pointer', color: '#7e37d8' }}
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      완료
                    </div>
                  </div>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <div>회수실패😥 : {expire_result.message}</div>
                    <div
                      style={{ cursor: 'pointer', color: '#7e37d8' }}
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      다시시도
                    </div>
                  </div>
                )}
              </div>
            </form>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default CourseExpireModal;

const td_modal_style = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  zIndex: '9999',
  left: '0',
  top: '0',
};

const td_dimmed = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

const modal_content = {
  width: '100%',
  maxWidth: '480px',
  maxHeight: '800px',
  backgroundColor: '#ffffff',
  position: 'absolute',
  zIndex: '1002',
  left: '50%',
  top: '50%',
  borderRadius: '10px',
  transform: 'translate(-50%, -50%)',
};
