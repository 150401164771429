import {
  ADD_COMPANY_EMPLOYEE_SET,
  ADD_COMPANY_ID,
  ADD_COMPANY_INFO,
  CLEAR_COMPANY,
} from '../../action_type';

const init_state = {};

export default (state = init_state, action) => {
  switch (action.type) {
    case ADD_COMPANY_ID:
      return {
        company_id: action.company_id,
      };

    case ADD_COMPANY_INFO:
      return {
        ...state,
        company_info: {
          ...action.info,
        },
      };

    case ADD_COMPANY_EMPLOYEE_SET:
      return {
        ...state,
        employee_set: action.employee_set,
      };

    case CLEAR_COMPANY:
      return init_state;

    default:
      return state;
  }
};
