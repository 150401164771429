import {
  STORED_RECRUIT_FORM_INFO,
  CLEAR_RECRUIT_FORM_INFO,
  SET_RECRUIT_FORM_INFO,
  SET_RECRUIT_FORM_BODY,
} from '../../action_type';

export const stored_recruit_form_info = (data) => ({
  type: STORED_RECRUIT_FORM_INFO,
  data,
});

export const clear_recruit_form_info = () => ({
  type: CLEAR_RECRUIT_FORM_INFO,
});

export const set_recruit_form_info = (name, value) => ({
  type: SET_RECRUIT_FORM_INFO,
  name,
  value,
});

export const set_recruit_form_body = (name, value) => ({
  type: SET_RECRUIT_FORM_BODY,
  name,
  value,
});
