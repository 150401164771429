import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import SweetAlert from 'sweetalert2';
import DatePicker from 'react-datepicker';

import {
  stored_recruit_form_info,
  set_recruit_form_info,
  clear_recruit_form_info,
  set_recruit_form_body,
} from '../../../redux/bgd/info/action';
import { clear_delete_img } from '../../../redux/common/action';
import RecruitFormModel from '../../../model/RecruitFormModel';
import { errMessageOccur } from '../../../util/common';

import CustomInput from '../../_common/component/CustomInput';
import CustomSelectInput from '../../_common/component/CustomSelectInput';
import Loading from '../../_common/Loading';
import CustomLabel from '../../_common/component/CustomLabel';
import RecruitFormTextEditor from './RecruitFormTextEditor';

const Info = ({
  RecruitFormInfo,
  additionalProps,
  recruit_form_id,
  stored_recruit_form_info,
  set_recruit_form_info,
  set_recruit_form_body,
  clear_delete_img,
  clear_recruit_form_info,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState('');

  const initData = () => {
    setIsLoading(true);

    RecruitFormModel.getRecruitForm(additionalProps.recruit_form_id).then((res) => {
      stored_recruit_form_info(res);
      setQuestions(res.questions || []);
      setThumbnailUrl(res.thumbnail_img || '');
    });

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    if (parseInt(additionalProps.recruit_form_id)) {
      initData();
    } else {
      setIsEdit(true);
      setIsLoading(false);
    }

    return () => {
      clear_recruit_form_info();
      clear_delete_img('recruit_form_info_img');
    };
  }, []);

  const validationFields = {
    thumbnail_img: '썸네일',
    type: '타입',
    event_code: '이벤트 코드',
    title: '모집폼 제목',
    apply_start_time: '모집기간 시작일',
    apply_end_time: '모집기간 종료일',
    course_register_start_time: '클래스 신청 시작일',
    course_register_end_time: '클래스 신청 종료일',
    result_notice_time: '결과 발표일',
    questions: '질문',
    content_html: '콘텐츠 영역',
  };

  const formatDate = (date) => {
    return moment(date).isValid() ? moment(date).format('YYYY-MM-DD') + 'T00:00:00' : null;
  };

  const handleThumbnailUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const response = await RecruitFormModel.postUploadThumbnail(file);
        setThumbnailUrl(response[0].file_path);
      } catch (error) {
        console.error(error);
        SweetAlert.fire({
          title: '이미지 업로드 실패',
          text: '이미지 업로드에 실패했습니다. 다시 시도해주세요.',
        });
      }
    }
  };

  const saveInfo = async () => {
    setIsLoading(true);

    const updatedRecruitFormInfo = {
      ...RecruitFormInfo,
      thumbnail_img: thumbnailUrl,
      questions:
        questions.length > 0
          ? questions.map(({ id, options, ...rest }) => ({
              ...rest,
              options: options ? options.map(({ id, ...restOption }) => restOption) : [],
            }))
          : '',
    };

    const errMsg = errMessageOccur(updatedRecruitFormInfo, validationFields, { use: false });

    if (!RecruitFormInfo.content_html) {
      errMsg.push(validationFields.content_html);
    }

    if (errMsg.length) {
      SweetAlert.fire({
        title: '아래 입력 값을 확인해주세요',
        text: errMsg.join('\n'),
      });
      setIsLoading(false);
      return;
    }

    if (RecruitFormInfo.id) {
      // 수정 로직
    } else {
      try {
        if (updatedRecruitFormInfo.type === '빡공단X') {
          const selectCourseQuestion = {
            question_type: 'select',
            title: '참여코스선택',
            options: [],
          };

          updatedRecruitFormInfo.questions = [
            selectCourseQuestion,
            ...updatedRecruitFormInfo.questions,
          ];
        }

        const res = await RecruitFormModel.postRecruitForm({
          ...updatedRecruitFormInfo,
          apply_start_time: formatDate(updatedRecruitFormInfo.apply_start_time),
          apply_end_time: formatDate(updatedRecruitFormInfo.apply_end_time),
          course_register_start_time: formatDate(updatedRecruitFormInfo.course_register_start_time),
          course_register_end_time: formatDate(updatedRecruitFormInfo.course_register_end_time),
          result_notice_time: formatDate(updatedRecruitFormInfo.result_notice_time),
          questions:
            updatedRecruitFormInfo.questions.length > 0 ? updatedRecruitFormInfo.questions : '',
          thumbnail_img: updatedRecruitFormInfo.thumbnail_img || '',
          theme_color_hex: '#000000',
        });

        if (res.code !== 200) {
          SweetAlert.fire({
            title: '저장 실패하였습니다',
            text: '다시 시도해주세요',
          });
        } else {
          window.location.href = `/recruit-form/${res.data.bgd_recruit_form.bgd_recruit_form_id}`;
        }
      } catch (error) {
        SweetAlert.fire({
          title: '저장 실패하였습니다',
          text: '다시 시도해주세요',
        });
      }
    }

    setIsLoading(false);
  };

  const multipleType = ['checkbox', 'select', 'radio'];

  const addShortAnswer = () => {
    const newQuestionId = `text_${Date.now()}`;
    const newQuestion = {
      id: newQuestionId,
      question_type: 'text',
      title: '',
      value: '',
    };
    setQuestions([...questions, newQuestion]);
  };

  const addLongAnswer = () => {
    const newQuestionId = `textarea_${Date.now()}`;
    const newQuestion = {
      id: newQuestionId,
      question_type: 'textarea',
      title: '',
      value: '',
      option_min: '',
      option_max: '',
    };

    setQuestions([...questions, newQuestion]);
  };

  const addMultipleChoice = () => {
    const newQuestionId = `checkbox_${Date.now()}`;
    const newQuestion = {
      id: newQuestionId,
      question_type: 'checkbox',
      title: '',
      options: [
        { id: `checkbox_${Date.now()}`, text: '' },
        { id: `checkbox_${Date.now() + 1}`, text: '' },
      ],
    };

    setQuestions([...questions, newQuestion]);
  };

  const addSingleChoice = () => {
    const newQuestionId = `radio_${Date.now()}`;
    const newQuestion = {
      id: newQuestionId,
      question_type: 'radio',
      title: '',
      options: [
        { id: `radio_${Date.now()}`, text: '' },
        { id: `radio_${Date.now() + 1}`, text: '' },
      ],
    };
    setQuestions([...questions, newQuestion]);
  };

  const addDropdownSelect = () => {
    const newQuestionId = `select_${Date.now()}`;
    const newQuestion = {
      id: newQuestionId,
      question_type: 'select',
      title: '',
      options: [
        { id: `select_${Date.now()}`, text: '' },
        { id: `select_${Date.now() + 1}`, text: '' },
      ],
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleInputChange = (id, name, value) => {
    const updatedInputs = questions.map((question) => {
      if (question.id === id) {
        return { ...question, [name]: value };
      }
      return question;
    });

    setQuestions(updatedInputs);
  };

  const handleTitleChange = (id, title) => {
    const updatedInputs = questions.map((question) => {
      if (question.id === id) {
        return { ...question, title: title };
      }
      return question;
    });

    setQuestions(updatedInputs);
  };

  const handleOptionChange = (questionId, optionId, text) => {
    const updatedInputs = questions.map((question) => {
      if (question.id === questionId && multipleType.includes(question.question_type)) {
        const updatedOptions = question.options.map((option) => {
          if (option.id === optionId) {
            return { ...option, text: text };
          }
          return option;
        });
        return { ...question, options: updatedOptions };
      }
      return question;
    });

    setQuestions(updatedInputs);
  };

  const handleOptionDelete = (questionId, optionId) => {
    const updatedInputs = questions.map((question) => {
      if (question.id === questionId) {
        if (question.options.length > 1) {
          const updatedOptions = question.options.filter((option) => option.id !== optionId);
          return { ...question, options: updatedOptions };
        } else {
          SweetAlert.fire({
            title: '최소 1개는 입력되어야 합니다!',
            text: '옵션은 최소 한 개가 있어야 합니다.',
            icon: 'warning',
            confirmButtonText: '확인',
          });
        }
      }
      return question;
    });

    setQuestions(updatedInputs);
  };

  const addOption = (questionId) => {
    const updatedInputs = questions.map((question) => {
      if (question.id === questionId && multipleType.includes(question.question_type)) {
        const newOption = { id: `checkbox_${Date.now()}`, text: '' };
        return { ...question, options: [...question.options, newOption] };
      }
      return question;
    });

    setQuestions(updatedInputs);
  };

  const removeQuestion = (id) => {
    const updatedInputs = questions.filter((question) => question.id !== id);

    setQuestions(updatedInputs);
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <Loading isLoading={isLoading} />
              </Col>

              <Col sm={6} className='text-right'>
                {isEdit && (
                  <>
                    <Button
                      className='btn-pill'
                      color='primary'
                      style={{ marginRight: '8px' }}
                      onClick={saveInfo}
                      type='submit'
                    >
                      저장
                    </Button>
                  </>
                )}
                {RecruitFormInfo.id && (
                  <Button
                    className={isEdit ? 'btn-pill btn btn-light' : 'btn-pill'}
                    color={isEdit ? '' : 'primary'}
                    style={{ marginRight: '8px' }}
                    onClick={() => {
                      if (isEdit) {
                        initData();
                      }
                      setIsEdit(!isEdit);
                    }}
                    type='submit'
                  >
                    {isEdit ? <>취소</> : <>편집</>}
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>

          <CardBody>
            <Row>
              <Col>
                <p>썸네일</p>
                {isEdit && (
                  <input
                    type='file'
                    name='thumbnail_img'
                    label='썸네일 이미지'
                    onChange={handleThumbnailUpload}
                    disabled={!isEdit}
                    style={{ marginBottom: '20px' }}
                  />
                )}
                {thumbnailUrl && (
                  <img src={thumbnailUrl} alt='Thumbnail' style={{ maxWidth: '200px' }} />
                )}
              </Col>
            </Row>

            <Row style={{ marginTop: '30px' }}>
              <Col>
                <CustomSelectInput
                  name={'type'}
                  disabled={!isEdit}
                  value={RecruitFormInfo.type || 0}
                  label={'타입'}
                  is_required={true}
                  tooltip_desc={'빡공단 or 빡공단X를 선택해주세요. (기본값: 빡공단)'}
                  onChange={(e) => set_recruit_form_info(e.target.name, e.target.value)}
                  options={[
                    { name: '빡공단', id: '빡공단' },
                    { name: '빡공단X', id: '빡공단X' },
                  ]}
                />
              </Col>
              <Col>
                <CustomInput
                  type={'text'}
                  name={'event_code'}
                  is_required={true}
                  placeholder={'이벤트 코드를 입력하세요.'}
                  value={RecruitFormInfo.event_code || ''}
                  label='이벤트 코드'
                  onChange={(e) => set_recruit_form_info(e.target.name, e.target.value)}
                  disabled={!isEdit}
                />
              </Col>
              <Col>
                <CustomInput
                  type={'title'}
                  name={'title'}
                  is_required={true}
                  placeholder={'제목을 입력하세요.'}
                  value={RecruitFormInfo.title || ''}
                  label='모집폼 제목'
                  onChange={(e) => set_recruit_form_info(e.target.name, e.target.value)}
                  disabled={!isEdit}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: '30px' }}>
              <Col>
                <Label>모집 시작일</Label>
                <DatePicker
                  className='form-control'
                  dateFormat='yyyy-MM-dd'
                  name='apply_start_time'
                  selected={
                    RecruitFormInfo.apply_start_time
                      ? new Date(RecruitFormInfo.apply_start_time)
                      : null
                  }
                  onChange={(date) => {
                    set_recruit_form_info('apply_start_time', moment(date).format('YYYY-MM-DD'));
                  }}
                  startDate={
                    RecruitFormInfo.apply_start_time
                      ? new Date(RecruitFormInfo.apply_start_time)
                      : null
                  }
                  endDate={
                    RecruitFormInfo.apply_end_time ? new Date(RecruitFormInfo.apply_end_time) : null
                  }
                  minDate={new Date()}
                  maxDate={
                    RecruitFormInfo.apply_end_time ? new Date(RecruitFormInfo.apply_end_time) : null
                  }
                  disabled={!isEdit}
                />
              </Col>
              <Col>
                <Label>모집 종료일</Label>
                <DatePicker
                  className='form-control'
                  dateFormat='yyyy-MM-dd'
                  name='apply_end_time'
                  selected={
                    RecruitFormInfo.apply_end_time ? new Date(RecruitFormInfo.apply_end_time) : null
                  }
                  onChange={(date) => {
                    set_recruit_form_info('apply_end_time', moment(date).format('YYYY-MM-DD'));
                  }}
                  startDate={
                    RecruitFormInfo.apply_start_time
                      ? new Date(RecruitFormInfo.apply_start_time)
                      : null
                  }
                  endDate={
                    RecruitFormInfo.apply_end_time ? new Date(RecruitFormInfo.apply_end_time) : null
                  }
                  minDate={
                    RecruitFormInfo.apply_start_time
                      ? new Date(RecruitFormInfo.apply_start_time)
                      : new Date()
                  }
                  disabled={!isEdit}
                />
              </Col>
              <Col>
                <Label>클래스 신청 시작일</Label>
                <DatePicker
                  className='form-control'
                  dateFormat='yyyy-MM-dd'
                  name='course_register_start_time'
                  selected={
                    RecruitFormInfo.course_register_start_time
                      ? new Date(RecruitFormInfo.course_register_start_time)
                      : null
                  }
                  onChange={(date) => {
                    set_recruit_form_info(
                      'course_register_start_time',
                      moment(date).format('YYYY-MM-DD'),
                    );
                  }}
                  startDate={
                    RecruitFormInfo.course_register_start_time
                      ? new Date(RecruitFormInfo.course_register_start_time)
                      : null
                  }
                  endDate={
                    RecruitFormInfo.course_register_end_time
                      ? new Date(RecruitFormInfo.course_register_end_time)
                      : null
                  }
                  minDate={new Date()}
                  maxDate={
                    RecruitFormInfo.course_register_end_time
                      ? new Date(RecruitFormInfo.course_register_end_time)
                      : null
                  }
                  disabled={!isEdit}
                />
              </Col>
              <Col>
                <Label>클래스 신청 종료일</Label>
                <DatePicker
                  className='form-control'
                  dateFormat='yyyy-MM-dd'
                  name='course_register_end_time'
                  selected={
                    RecruitFormInfo.course_register_end_time
                      ? new Date(RecruitFormInfo.course_register_end_time)
                      : null
                  }
                  onChange={(date) => {
                    set_recruit_form_info(
                      'course_register_end_time',
                      moment(date).format('YYYY-MM-DD'),
                    );
                  }}
                  startDate={
                    RecruitFormInfo.course_register_start_time
                      ? new Date(RecruitFormInfo.course_register_start_time)
                      : null
                  }
                  endDate={
                    RecruitFormInfo.course_register_end_time
                      ? new Date(RecruitFormInfo.course_register_end_time)
                      : null
                  }
                  minDate={
                    RecruitFormInfo.course_register_start_time
                      ? new Date(RecruitFormInfo.course_register_start_time)
                      : new Date()
                  }
                  disabled={!isEdit}
                />
              </Col>
              <Col>
                <Label>결과 발표일</Label>
                <DatePicker
                  className='form-control'
                  dateFormat='yyyy-MM-dd'
                  name='result_notice_time'
                  selected={
                    RecruitFormInfo.result_notice_time
                      ? new Date(RecruitFormInfo.result_notice_time)
                      : null
                  }
                  onChange={(date) => {
                    set_recruit_form_info('result_notice_time', moment(date).format('YYYY-MM-DD'));
                  }}
                  minDate={
                    RecruitFormInfo.apply_start_time
                      ? new Date(RecruitFormInfo.apply_start_time)
                      : new Date()
                  }
                  disabled={!isEdit}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: '40px' }}>
              <Col>
                <CustomLabel
                  tooltip_desc={'상세페이지에 업로드될 이미지를 업로드해주세요.'}
                  label={'콘텐츠 영역'}
                />
                <div className='editor-img-100'>
                  <RecruitFormTextEditor
                    id={recruit_form_id}
                    name='content_html'
                    disabled={!isEdit}
                    html_data={RecruitFormInfo.content_html ?? ''}
                    img_type={'recruit_form'}
                    onChange={(value) => {
                      set_recruit_form_body('content_html', value);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: '50px' }}>
              <Col sm={3}>
                {isEdit && (
                  <div className='select-nav'>
                    <Button
                      className='btn-pill'
                      color='primary'
                      type='button'
                      onClick={addShortAnswer}
                    >
                      단답형
                    </Button>

                    <Button
                      className='btn-pill'
                      color='primary'
                      type='button'
                      onClick={addLongAnswer}
                    >
                      장문형
                    </Button>

                    <Button
                      className='btn-pill'
                      color='primary'
                      type='button'
                      onClick={addMultipleChoice}
                    >
                      다중선택형
                    </Button>

                    <Button
                      className='btn-pill'
                      color='primary'
                      type='button'
                      onClick={addSingleChoice}
                    >
                      단일선택형
                    </Button>

                    <Button
                      className='btn-pill'
                      color='primary'
                      type='button'
                      onClick={addDropdownSelect}
                    >
                      드롭다운형
                    </Button>
                  </div>
                )}
              </Col>

              <Col className='detail-part'>
                {questions.map((question, idx) => (
                  <div key={idx} className='question-content'>
                    <div className='question-box'>
                      <CustomInput
                        type={'text'}
                        name={`${idx}. ${question.question_type}`}
                        placeholder={'질문을 입력하세요.'}
                        label={`${idx + 1}. ${
                          question.question_type === 'text'
                            ? '단답형'
                            : question.question_type === 'radio'
                              ? '단일선택형'
                              : question.question_type === 'textarea'
                                ? '장문형'
                                : question.question_type === 'select'
                                  ? '드롭다운형'
                                  : question.question_type === 'checkbox'
                                    ? '다중선택형'
                                    : ''
                        }`}
                        onChange={(e) => handleTitleChange(question.id, e.target.value)}
                        disabled={!isEdit}
                        value={question.title || ''}
                      />

                      {question.question_type === 'textarea' && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            marginTop: '10px',
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <CustomInput
                              type={'text'}
                              name={`${idx}_${question.question_type}`}
                              placeholder={'최소 입력 글자수'}
                              onChange={(e) => {
                                handleInputChange(question.id, 'option_min', e.target.value);
                              }}
                              disabled={!isEdit}
                              value={question.option_min || ''}
                            />
                            <span>자 이상</span>
                          </div>

                          <CustomInput
                            type={'text'}
                            name={`${idx}_${question.question_type}`}
                            placeholder={'최대 입력 글자수'}
                            onChange={(e) =>
                              handleInputChange(question.id, 'option_max', e.target.value)
                            }
                            disabled={!isEdit}
                            value={question.option_max || ''}
                          />
                          <span>자 이하</span>
                        </div>
                      )}

                      {multipleType.includes(question.question_type) && (
                        <ul className='option-list'>
                          {question.options.map((option, idx) => (
                            <li className='item' key={idx}>
                              <span style={{ minWidth: '40px' }}>선택지</span>
                              <Input
                                placeholder='선택지를 입력하세요'
                                type='text'
                                required={true}
                                onChange={(e) =>
                                  handleOptionChange(question.id, option.id, e.target.value)
                                }
                                disabled={!isEdit}
                                value={question.options[idx].text || ''}
                              />
                              {isEdit && (
                                <button
                                  type='button'
                                  className='remove-btn-sm'
                                  onClick={() => handleOptionDelete(question.id, option.id)}
                                  disabled={!isEdit}
                                >
                                  옵션 제거
                                </button>
                              )}
                            </li>
                          ))}

                          {isEdit && (
                            <button
                              type='button'
                              className='option-btn'
                              onClick={() => addOption(question.id)}
                              disabled={!isEdit}
                            >
                              옵션 추가
                            </button>
                          )}
                        </ul>
                      )}
                    </div>

                    {isEdit && (
                      <button
                        type='button'
                        className='remove-btn'
                        onClick={() => removeQuestion(question.id)}
                        disabled={!isEdit}
                      >
                        질문 삭제
                      </button>
                    )}
                  </div>
                ))}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  RecruitFormInfo: state.RecruitFormStore,
  CommonStore: state.CommonStore,
});

const mapDispatchToProps = (dispatch) => {
  return {
    set_recruit_form_info: (name, value) => dispatch(set_recruit_form_info(name, value)),
    set_recruit_form_body: (name, value) => dispatch(set_recruit_form_body(name, value)),
    clear_recruit_form_info: () => dispatch(clear_recruit_form_info()),
    stored_recruit_form_info: (data) => dispatch(stored_recruit_form_info(data)),
    clear_delete_img: (img_type) => dispatch(clear_delete_img(img_type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Info);
