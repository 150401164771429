import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import CompanyModel from '../../../model/CompanyModel';
import Loading from '../../_common/Loading';
import Apexcharts from 'react-apexcharts';
import { top_5_chart_data } from '../../../constant/chart_option';
import { lang } from '../../../lang';

/*
    props
    product_info: {}                            패지키 정보
    is_toggle_progress_modal: state(boole)      progress 모달의 열고 닫기 여부
    toggleModal: func => void                   progress 모달 열고 닫기 func -> CompanyCourseList.js의 toggleProgressModal 참고
    openEditModal: func => void                 edit 모달 열기 func -> CompanyCourseList.js의 openEditModal 참고
 */
const ProductProgressModal = (props) => {
  const { params } = useParameters();
  const { showAlert } = useShowDialog();

  // state
  const [chart_series, setChartSeries] = useState();
  const [chart_height, setChartHeight] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // ref
  const company_id = useRef(params.company_id);
  const chart_options = useRef(top_5_chart_data.options);

  useEffect(() => {
    if (props.product_info) {
      updateUi();
    }
  }, [props.product_info]);

  const updateUi = async () => {
    setIsLoading(true);

    const category = [];
    const series = [];

    // 해당 패키지에 등록된 course의 진행률 가져오기 API
    const course_by_product_res = await CompanyModel.getCourseByProduct(
      company_id.current,
      props.product_info.product_id,
    );

    if (course_by_product_res.code === 200) {
      course_by_product_res.data.course_set.map((course) => {
        category.push(course.short_title);
        series.push(Math.round(course.attendance_rate * 100));
        return null;
      });

      chart_options.current.xaxis.categories = category;
      setChartSeries([{ data: series, name: '진행률(%)' }]);
    } else {
      showAlert({
        title: course_by_product_res.message || lang.request.request_error_title,
      });
    }

    // category(chart의 category -> 클래스|패키지 X)별로 chart의 사이즈를 변경
    if (category.length <= 3) {
      setChartHeight(250);
    } else if (category.length <= 6) {
      setChartHeight(400);
    } else if (category.length <= 8) {
      setChartHeight(600);
    } else {
      setChartHeight(1000);
    }

    setIsLoading(false);
  };

  return (
    <Modal isOpen={props.is_toggle_progress_modal} toggle={props.toggleModal} size='lg'>
      <ModalHeader>{props.product_info?.product_title}</ModalHeader>
      <ModalBody
        style={{
          padding: '2.5em',
        }}
      >
        {isLoading ? (
          <div className='text-center'>
            <Loading
              isLoading={isLoading}
              custom_style={{
                display: 'flex',
              }}
              is_card_body={true}
            />
          </div>
        ) : (
          chart_series && (
            <Apexcharts
              type='bar'
              options={chart_options.current}
              series={chart_series}
              height={chart_height}
            />
          )
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color={'primary'}
          onClick={() => {
            props.openEditModal(props.product_info);
          }}
        >
          날짜 변경하기
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProductProgressModal;
