import {
  STORED_COURSE_SUBJECT,
  SET_COURSE_SUBJECT,
  CLEAR_COURSE_SUBJECT,
  ADD_COURSE_SUBJECT,
  DELETE_COURSE_SUBJECT,
} from '../../action_type';

const INIT_STATE = [];

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_COURSE_SUBJECT:
      return INIT_STATE;

    case STORED_COURSE_SUBJECT:
      return action.data;

    case SET_COURSE_SUBJECT:
      const tmp_state = state[action.subject_index];
      tmp_state[action.name] = action.value;
      return [...state];

    case ADD_COURSE_SUBJECT:
      state.push({
        id: null,
        order_number: action.add_index,
        index_name: '',
        title: '',
        chapter_description: '',
        vimeo_id: '',
        preview_youtube_id: '',
        description: '',
        function_description: '',
        image_url: null,
        thumbnail_image_url: null,
      });
      return [...state];

    case DELETE_COURSE_SUBJECT:
      return state.filter((item, i) => {
        return !(action.subject_start_index <= i && i <= action.subject_end_index);
      });
    default:
      return state;
  }
};
