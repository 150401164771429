import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';

import { Card, CardHeader, Col, Container, Row, Button } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';

import Form from 'reactstrap/es/Form';
import SweetAlert from 'sweetalert2';
import CustomInput from '../_common/component/CustomInput';
import { errMessageOccur } from '../../util/common';

const service_info_label = {
  company_name: '회사명',
  manager_name: '대표자',
  pi_manager_name: '개인정보 책임관리자',
  business_number: '사업자번호',
  tel_business_number: '통신판매업 번호',
  contact_email: '회사 이메일',
  contact_tel_number: '회사 연락처',
  company_address: '회사 주소',
  youtube_url: '유튜브URL',
  twitter_url: '트위터URL',
  facebook_url: '네이버URL',
  naver_url: '네이버URL',
  instagram_url: '인스타URL',
};

const { youtube_url, twitter_url, facebook_url, naver_url, instagram_url, ...obj_err_msg } =
  service_info_label;

const ServiceInfo = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [service_info, setServiceInfo] = useState({
    company_name: '',
    manager_name: '',
    pi_manager_name: '',
    business_number: '',
    tel_business_number: '',
    contact_email: '',
    contact_tel_number: '',
    company_address: '',
    youtube_url: '',
    twitter_url: '',
    facebook_url: '',
    naver_url: '',
    instagram_url: '',
  });

  const onInputChange = (e) => {
    setServiceInfo({ ...service_info, [e.target.name]: e.target.value });
  };

  const onInputSubmit = (e) => {
    e.preventDefault();

    const err_msg = errMessageOccur(service_info, obj_err_msg);

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      CommonModel.putServiceInfo(service_info).then(() => {
        setIsLoading(false);
        e.target.disabled = false;
        setIsEdit(false);
        requestDetail();
      });
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const requestDetail = () => {
    CommonModel.getServiceInfo().then(({ service_info }) => {
      setServiceInfo(service_info);
    });
  };

  useEffect(() => {
    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent='서비스 정보' title={`서비스 정보`} />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form
              onSubmit={(e) => {
                onInputSubmit(e);
              }}
            >
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`서비스 정보`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      {
                        <>
                          <Button
                            className='btn-pill'
                            color='primary'
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              if (isEdit) {
                                CommonModel.getServiceInfo().then(({ service_info }) => {
                                  setServiceInfo(service_info);
                                });
                              }
                              setIsEdit(!isEdit);
                            }}
                          >
                            {isEdit ? <>취소</> : <>편집</>}
                          </Button>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    {Object.keys(service_info).map((key, index) => {
                      let md;
                      let required = true;
                      if (index === 0) {
                        md = 2;
                      } else if (index === 4) {
                        md = 4;
                      } else if (index >= 1 && index < 5) {
                        md = 2;
                      } else if (index >= 5 && index < 8) {
                        md = 4;
                      } else {
                        md = 12;
                        required = false;
                      }
                      return (
                        <Col md={`${md} mb-3`}>
                          <CustomInput
                            type={'text'}
                            name={key}
                            disabled={!isEdit}
                            value={service_info[key] || ''}
                            is_required={required}
                            label={service_info_label[key]}
                            onChange={onInputChange}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ServiceInfo;
