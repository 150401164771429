import React, { Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';

const Course = () => {
  // const [course_dashboard, setCourseDashboard] = useState(null);

  useEffect(() => {}, []);

  return (
    <Fragment>
      <Breadcrumb parent='Course' title='List' />
      <Container fluid={true}>
        <Row>
          <Col lg='12 xl-100'>
            <h2>Course Dashboard</h2>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Course;
