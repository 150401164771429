import React, { useState } from 'react';
import { Input, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

// setFilter                 func         : filter_set 을 바꾸는 setFilter 를 넣는다.
// filter_set                object       : filter_set 을 넣으면 된다.
// filter_set 안에 date_start 와 date_end가 있어야 한다.
// filter_set = {
//     ...
//     date_start: params.date_start ? params.date_start : null,
//     date_end:params.date_end ? params.date_end : null,
// }
// style = [{width: 2, label_disable: false}, {width: 2, label_disable: false}, {width: 2, label_disable: false}]
// 기본값 전부 col 2 / label_disable false
const CustomDateRangeFilter = (props) => {
  const [is_date_disabled, setisDateDisabled] = useState(true);

  // 날짜 수정
  const onDateChange = (e, name) => {
    props.setFilter({ ...props.filter_set, [name]: e && moment(e).format('YYYY-MM-DD') });
  };

  const onDateClick = (e) => {
    let date_start = null;
    let date_end = null;
    let add_date;

    if (e.target.value !== 'all' && e.target.value !== 'direct') {
      add_date = Number(e.target.value.split('_')[1]);
      date_end = moment().format('YYYY-MM-DD');
    }

    if (e.target.value === 'direct') {
      date_start = props.filter_set.date_start;
      date_end = props.filter_set.date_end;
      setisDateDisabled(false);
    } else {
      setisDateDisabled(true);
    }

    if (typeof add_date === 'number') {
      date_start = moment().add(-add_date, 'days').format('YYYY-MM-DD');
    }

    props.setFilter({
      ...props.filter_set,
      date_start: date_start,
      date_end: date_end,
    });
  };

  return (
    <div style={{ display: 'flex' }}>
      <div
        className={'mr-3'}
        style={{ width: props.style && props.style[0] ? props.style[0]?.width : '' }}
      >
        {props.style && props.style[0] ? (
          !props.style[0]?.label_disable && (
            <Label htmlFor='createRangeTime'>생성일 범위 설정</Label>
          )
        ) : (
          <Label style={{ fontWeight: 'bold' }} htmlFor='createRangeTime'>
            {props.type === 'bookmark' ? '북마크 업데이트 날짜 범위 설정' : '생성일 범위 설정'}
          </Label>
        )}
        <Input type='select' name='job_class' className='form-control' onChange={onDateClick}>
          <option value='all'>전체</option>
          <option value='direct'>직접설정</option>
          <option value='before_0'>오늘</option>
          <option value='before_1'>어제</option>
          <option value='before_7'>7일전</option>
          <option value='before_14'>14일전</option>
          <option value='before_30'>30일전</option>
          <option value='before_90'>90일전</option>
          <option value='before_180'>180일전</option>
        </Input>
      </div>

      <div
        className={'mr-3'}
        style={{ width: props.style && props.style[1] ? props.style[1]?.width : '' }}
      >
        {props.style && props.style[1] ? (
          !props.style[1]?.label_disable && <Label htmlFor='createRangeTime'>시작 날짜</Label>
        ) : (
          <Label style={{ fontWeight: 'bold' }} htmlFor='createRangeTime'>
            시작 날짜
          </Label>
        )}
        <DatePicker
          className='form-control digits'
          name='date_start'
          dateFormat='yyyy-MM-dd'
          disabled={is_date_disabled}
          onChange={(e) => {
            onDateChange(e, 'date_start');
          }}
          autoComplete='off'
          placeholderText='시작 날짜'
          selectsStart
          selected={props.filter_set.date_start ? new Date(props.filter_set.date_start) : null}
          startDate={props.filter_set.date_start ? new Date(props.filter_set.date_start) : null}
          endDate={props.filter_set.date_end ? new Date(props.filter_set.date_end) : null}
          maxDate={props.filter_set.date_end ? new Date(props.filter_set.date_end) : new Date()}
        />
      </div>

      <div
        className={'mr-3'}
        style={{ width: props.style && props.style[2] ? props.style[2]?.width : '' }}
      >
        {props.style && props.style[2] ? (
          !props.style[2]?.label_disable && <Label htmlFor='createRangeTime'>마지막 날짜</Label>
        ) : (
          <Label style={{ fontWeight: 'bold' }} htmlFor='createRangeTime'>
            마지막 날짜
          </Label>
        )}
        <DatePicker
          className='form-control digits'
          name='date_end'
          disabled={is_date_disabled}
          dateFormat='yyyy-MM-dd'
          onChange={(e) => {
            onDateChange(e, 'date_end');
          }}
          autoComplete='off'
          placeholderText='마지막 날짜'
          selectsEnd
          selected={props.filter_set.date_end ? new Date(props.filter_set.date_end) : null}
          startDate={props.filter_set.date_start ? new Date(props.filter_set.date_start) : null}
          endDate={props.filter_set.date_end ? new Date(props.filter_set.date_end) : null}
          minDate={props.filter_set.date_start ? new Date(props.filter_set.date_start) : null}
          maxDate={new Date()}
        />
      </div>
    </div>
  );
};

export default CustomDateRangeFilter;
