import React from 'react';
import { Table } from 'reactstrap';
import { getDaysArray } from '../../../util/common';
import moment from 'moment';
import { Link } from 'react-router-dom';

const AccountManagementDescriptionTable = ({
  onOrderChange,
  params,
  management_description_set,
  staticIdToName,
}) => {
  return (
    <div className='table-responsive'>
      <Table>
        <thead>
          <tr style={{ textAlign: 'center' }}>
            <th
              onClick={() => {
                onOrderChange('user_course__account_id');
              }}
              style={{ width: '90px', maxWidth: '90px', minWidth: '90px' }}
              className='table-sort-th'
            >
              {params.order_key === 'user_course__account_id' ? (
                <span style={{ color: '#ff4c3b' }}>회원ID</span>
              ) : (
                <span>회원ID</span>
              )}
            </th>
            <th style={{ width: '120px', maxWidth: '120px', minWidth: '120px' }}>이름/닉네임</th>
            <th style={{ width: '190px', maxWidth: '190px', minWidth: '190px' }}>이메일</th>
            <th
              style={{ width: '110px', maxWidth: '110px', minWidth: '110px' }}
              onClick={() => {
                onOrderChange('user_course__account__accountprofile__birth');
              }}
              className='table-sort-th'
            >
              {params.order_key === 'user_course__account__accountprofile__birth' ? (
                <span style={{ color: '#ff4c3b' }}>생년월일</span>
              ) : (
                <span>생년월일</span>
              )}
            </th>
            <th style={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>휴대폰</th>
            <th style={{ width: '130px', maxWidth: '130px', minWidth: '130px' }}>직종</th>
            <th style={{ width: '250px', maxWidth: '250px', minWidth: '250px' }}>주소</th>
            <th style={{ width: '120px', maxWidth: '120px', minWidth: '120px' }}>강의</th>
            <th style={{ width: '120px', maxWidth: '120px', minWidth: '120px' }}>관리용설명</th>
            <th style={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>완강여부</th>
            {params.date_start || params.date_end
              ? getDaysArray(
                  new Date(
                    params.date_start
                      ? params.date_start
                      : moment().add(-30, 'days').format('YYYY-MM-DD'),
                  ),
                  new Date(
                    params.date_end ? params.date_end : moment(new Date()).format('YYYY-MM-DD'),
                  ),
                ).map((date, index) => {
                  return (
                    <th
                      style={{ width: '120px', maxWidth: '120px', minWidth: '120px' }}
                      key={index}
                    >
                      {moment(new Date(date)).format('YYYY-MM-DD')}
                    </th>
                  );
                })
              : null}
          </tr>
        </thead>
        <tbody>
          {management_description_set.length > 0 &&
            management_description_set.map((management_description) => {
              return (
                <tr key={management_description.id} style={{ height: '67px' }}>
                  <td
                    className='text-center'
                    style={{ verticalAlign: 'middle', maxWidth: '90px', minWidth: '90px' }}
                  >
                    <Link to={`/account/${management_description.user_course.user_info_set.id}`}>
                      {management_description.user_course.user_info_set.id}
                    </Link>
                  </td>
                  <td
                    className='text-center'
                    style={{ verticalAlign: 'middle', maxWidth: '120px', minWidth: '120px' }}
                  >
                    {management_description.user_course.user_info_set.name}
                    <br />
                    <small>{management_description.user_course.user_info_set.nickname}</small>
                  </td>
                  <td
                    className='text-center'
                    style={{ verticalAlign: 'middle', maxWidth: '190px', minWidth: '190px' }}
                  >
                    {management_description.user_course.user_info_set.email}
                  </td>
                  <td
                    className='text-center'
                    style={{ verticalAlign: 'middle', maxWidth: '110px', minWidth: '110px' }}
                  >
                    {management_description.user_course.user_info_set.birth}
                  </td>
                  <td
                    className='text-center'
                    style={{ verticalAlign: 'middle', maxWidth: '160px', minWidth: '160px' }}
                  >
                    {management_description.user_course.user_info_set.phone}
                  </td>
                  <td
                    className='text-center'
                    style={{ verticalAlign: 'middle', maxWidth: '130px', minWidth: '130px' }}
                  >
                    <small>
                      {staticIdToName(
                        'job_class',
                        management_description.user_course.user_info_set.job_class_id,
                      )}
                    </small>
                  </td>
                  <td style={{ verticalAlign: 'middle', maxWidth: '250px', minWidth: '250px' }}>
                    {management_description.user_course.user_info_set.address}{' '}
                    {management_description.user_course.user_info_set.address_detail}
                  </td>
                  <td
                    className='text-center'
                    style={{ verticalAlign: 'middle', maxWidth: '120px', minWidth: '120px' }}
                  >
                    <Link to={`/course/${management_description.user_course.course_id}`}>
                      {management_description.user_course.course_short_title}
                    </Link>
                  </td>
                  <td
                    className='text-center'
                    style={{ verticalAlign: 'middle', maxWidth: '120px', minWidth: '120px' }}
                  >
                    {management_description.management_description}
                  </td>
                  <td
                    className='text-center'
                    style={{ verticalAlign: 'middle', maxWidth: '100px', minWidth: '100px' }}
                  >
                    {management_description.user_course.is_completed && 'Y'}
                  </td>
                  {params.date_start || params.date_end
                    ? getDaysArray(
                        new Date(
                          params.date_start
                            ? params.date_start
                            : moment().add(-30, 'days').format('YYYY-MM-DD'),
                        ),
                        new Date(
                          params.date_end
                            ? params.date_end
                            : moment(new Date()).format('YYYY-MM-DD'),
                        ),
                      ).map((date, index) => {
                        let check;

                        check =
                          management_description.user_course.user_info_set.coursesubjectsviewlog_set?.some(
                            (coursesubjectsviewlog) => {
                              return (
                                management_description.user_course.course_id ===
                                  coursesubjectsviewlog.course_id &&
                                (moment(new Date(coursesubjectsviewlog.view_time)).format(
                                  'YYYY-MM-DD',
                                ) === moment(new Date(date)).format('YYYY-MM-DD') ||
                                  moment(new Date(coursesubjectsviewlog.update_time)).format(
                                    'YYYY-MM-DD',
                                  ) === moment(new Date(date)).format('YYYY-MM-DD'))
                              );
                            },
                          );

                        return (
                          <td
                            key={index}
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '120px',
                              minWidth: '120px',
                            }}
                          >
                            {check && 'Y'}
                          </td>
                        );
                      })
                    : null}
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default React.memo(AccountManagementDescriptionTable);
