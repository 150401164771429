import { combineReducers } from 'redux';
import CommonStore from './common/reducer';
import AuthStore from './auth/reducer';
import CourseStore from './course/info/reducer';
import CourseSaleStore from './course/sale/reducer';
import CourseSubjectStore from './course/subject/reducer';
import CourseNoticeStore from './course/notice/reducer';
import RecruitFormStore from './bgd/info/reducer';
// 각 메뉴별 reducer import

import CompanyStore from './b2b_store/company/reducer';
import TemporaryStore from './b2b_store/temporary/reducer';

import EbookStore from './ebook/info/reducer';
import EbookSaleStore from './ebook/sale/reducer';

const redusers = combineReducers({
  AuthStore: AuthStore,
  CommonStore: CommonStore,
  CourseStore: CourseStore,
  CourseSaleStore: CourseSaleStore,
  CourseSubjectStore: CourseSubjectStore,
  CourseNoticeStore: CourseNoticeStore,
  RecruitFormStore: RecruitFormStore,

  // b2b
  company_reducer: CompanyStore,
  temporary_reducer: TemporaryStore,

  EbookStore: EbookStore,
  EbookSaleStore: EbookSaleStore,
});

export default redusers;
