import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

/*
    page_array : 총 표시할 페이지 수로 된 배열
    page_count : 현재 페이지
    setPageCount : 현재 페이지 변경
    incrementPageCount : Next 클릭 시 페이지 변경 함수
    decrementPageCount : Previous 클릭 시 페이지 변경 함수
*/
const Paging = (props) => {
  return props.page_array.length <= 1 ? (
    ''
  ) : (
    <Pagination className='pagination justify-content-center pagination-primary'>
      <ul
        className='pagination pagination-primary'
        style={{
          padding: '.5em 0',
        }}
      >
        {props.page_count === 1 ? (
          <PaginationItem disabled>
            <PaginationLink onClick={() => props.onHandlePageCount('Prev')}>Prev</PaginationLink>
          </PaginationItem>
        ) : (
          <PaginationItem>
            <PaginationLink onClick={() => props.onHandlePageCount('Prev')}>Prev</PaginationLink>
          </PaginationItem>
        )}

        {props.page_array.map((page_num, i) =>
          page_num === props.page_count ? (
            <PaginationItem active key={i}>
              <PaginationLink onClick={(page_num) => props.setPageCount(i + 1)}>
                {page_num}
              </PaginationLink>
            </PaginationItem>
          ) : (
            <PaginationItem key={i}>
              <PaginationLink onClick={(page_num) => props.setPageCount(i + 1)}>
                {page_num}
              </PaginationLink>
            </PaginationItem>
          ),
        )}
        {props.page_count === props.page_array.length ? (
          <PaginationItem disabled>
            <PaginationLink onClick={() => props.onHandlePageCount('Next')}>Next</PaginationLink>
          </PaginationItem>
        ) : (
          <PaginationItem>
            <PaginationLink onClick={() => props.onHandlePageCount('Next')}>Next</PaginationLink>
          </PaginationItem>
        )}
      </ul>
    </Pagination>
  );
};

export default Paging;
