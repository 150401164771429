import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import EmployeeCourseList from './component/EmployeeCourseList';
import { useParameters, useShowDialog } from '../../util/b2b_Hooks';
import EmployeeInfo from './component/EmployeeInfo';
import CompanyModel from '../../model/CompanyModel';
import { lang } from '../../lang';
import { useSelector } from 'react-redux';

const EmployeeDetail = () => {
  const { showAlert } = useShowDialog();
  const { params } = useParameters();
  const company_reducer = useSelector((state) => state.company_reducer);

  // state
  const [current_employee, setCurrentEmployee] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [need_update, setNeedUpdate] = useState(false);

  // ref
  const company_id = useRef(params.company_id);
  const employee_id = useRef(params.employee_id);
  const is_mount = useRef(false);

  useEffect(async () => {
    await updateUi();
  }, [need_update]);

  useEffect(() => {
    is_mount.current = true;
    return () => {
      is_mount.current = false;
    };
  }, []);

  const updateUi = async () => {
    setIsLoading(true);

    // 직원 상세정보 가져오기 API
    const employee_detail_res = await CompanyModel.getEmployeeDetail(
      company_id.current,
      employee_id.current,
    );
    if (is_mount.current) {
      if (employee_detail_res.code === 200) {
        setCurrentEmployee(employee_detail_res.data.employee);
      } else {
        showAlert({
          title: lang.request.request_error_title,
          text: employee_detail_res.message,
        });
      }

      setNeedUpdate(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Breadcrumb
        type='b2b'
        parent='기업'
        subParent={company_reducer.company_info.name}
        title={current_employee && current_employee.name}
      />
      <Container fluid={true}>
        <Row>
          <Col xl='12'>
            <Card>
              <EmployeeInfo
                current_employee={current_employee}
                isLoading={isLoading}
                setNeedUpdate={setNeedUpdate}
              />
            </Card>
          </Col>
          <Col xl='12'>
            <Card>
              <EmployeeCourseList current_employee={current_employee} />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmployeeDetail;
