import { useEffect, useRef, useState } from 'react';
import { set_tag, remove_tag } from '../../../redux/course/info/action';
import { connect } from 'react-redux';

const TagInput = (props) => {
  const [tags, setTags] = useState([]);
  let tag_input = useRef('');

  useEffect(() => {
    setTags(props.tag_set);
  }, [props.tag_set]);

  const removeTag = (index) => {
    const tmp_tags = tags;
    const new_tmp_tags = tmp_tags.filter((item, i) => i !== index);
    setTags(new_tmp_tags);
    props.remove_tag(index);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === 'Enter') {
      const korRegExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
      if (val.trim().length > 0) {
        if (tags.length) {
          let last_tag = tags[tags.length - 1].name;
          if (korRegExp.test(val) && val === last_tag.charAt(last_tag.length - 1)) {
            tag_input.value = '';
            return;
          }
        }
        if (tags.find((tag) => tag.name.toLowerCase() === val.toLowerCase())) {
          return;
        }
        setTags((prevState) => [...prevState, { name: val }]);
        props.set_tag(val);
        tag_input.value = '';
      }
    } else if (e.key === 'Backspace' && !val) {
      removeTag(tags.length - 1);
    }
  };

  const input_style = () => {
    const input_tag_style = {};
    if (props.tag_set.length === 0) {
      input_tag_style.height = '2.5rem';
    }
    if (props.disabled) {
      input_tag_style.backgroundColor = '#e9ecef';
    }
    return input_tag_style;
  };

  return (
    <div className='input-tag' style={input_style()}>
      <ul className='input-tag__tags'>
        {tags.map((tag, i) => (
          <li key={i}>
            {tag.name}
            {!props.disabled && (
              <button
                type='button'
                onClick={() => {
                  removeTag(i);
                }}
              >
                +
              </button>
            )}
          </li>
        ))}
        <li className='input-tag__tags__input'>
          {!props.disabled && (
            <input
              type='text'
              onKeyDown={inputKeyDown}
              ref={(c) => {
                tag_input = c;
              }}
            />
          )}
        </li>
      </ul>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_tag: (value) => dispatch(set_tag(value)),
    remove_tag: (index) => dispatch(remove_tag(index)),
  };
};
export default connect(null, mapDispatchToProps)(TagInput);
