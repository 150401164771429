import axios from 'axios';

class AccountModel {
  async sessionCheck() {
    const {
      data: { code, data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/account/session/check');

    return { code, data };
  }

  async getList(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/account', {
      params: filter,
    });
    return data;
  }

  async getAccount({ account_id }) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/account/' + account_id);
    return data;
  }

  async getAccountByEmail({ account_email }) {
    const {
      data: { data },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/account/email/' + account_email,
    );
    return data;
  }

  async putAccount(account) {
    const { data } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/account/' + account.id,
      account,
    );
    return data;
  }

  async resetAccountPassword(account_id) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/account/pwd-change/' + account_id,
    );
    return data;
  }

  async getAccountPurchase(account_id) {
    const {
      data: { data },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/account/purchase/' + account_id,
    );
    return data;
  }

  async getAccountCourse(account_id) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/account/course/' + account_id);
    return data;
  }

  async postManagementTag(_data) {
    const {
      data: { data },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/account/management-tag',
      _data,
    );
    return data;
  }

  async deleteManagementTag(management_tag_id) {
    const {
      data: { data },
    } = await axios.delete(
      process.env.REACT_APP_API_URL + '/cms-api/v1/account/management-tag/' + management_tag_id,
    );
    return data;
  }

  async getCourseSubjectViewLog({ account_id, course_id }) {
    const {
      data: { data },
    } = await axios.get(
      process.env.REACT_APP_API_URL +
        '/cms-api/v1/account/' +
        account_id +
        '/subject-viewlog/' +
        course_id,
    );
    return data;
  }

  async getCSV(filter) {
    return await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/account/csv', {
      params: filter,
      responseType: 'blob',
    });
  }

  async getManagementDescriptionList(filter) {
    const {
      data: { data },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/account/management-description',
      {
        params: filter,
      },
    );
    return data;
  }

  async getManagementDescriptionCSV(filter) {
    return await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/account/management-description/csv',
      {
        params: filter,
        responseType: 'blob',
      },
    );
  }

  async getGenderDashboard(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/account/gender-dashboard', {
      params: filter,
    });
    return data;
  }

  async getJobDashboard(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/account/job-dashboard', {
      params: filter,
    });
    return data;
  }

  async getBarDashboard(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/account/bar-dashboard', {
      params: filter,
    });
    return data;
  }

  async getSpecificUserInfoDashboard(filter) {
    const {
      data: { data },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/account/specific-user-info-dashboard',
      {
        params: filter,
      },
    );
    return data;
  }

  async getAccountCRMMemo({ account_id }) {
    const {
      data: { data },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/account/crm-memo/' + account_id,
    );
    return data;
  }

  async postAccountCRMMemo(_data) {
    const {
      data: { data },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/account/crm-memo/' + _data.account_id,
      {
        content: _data.content,
      },
    );
    return data;
  }

  async deleteAccountCRMMemo(memo_id) {
    const {
      data: { data },
    } = await axios.delete(
      process.env.REACT_APP_API_URL + '/cms-api/v1/account/crm-memo/memo/' + memo_id,
    );
    return data;
  }

  async getCompanyIdByAccountId() {
    const { data } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/account/company');
    return data;
  }
}

export default new AccountModel();
