import React, { Fragment, useEffect } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import Info from './component/Info';
import Sale from './component/Sale';
import Subject from './component/Subject';
import Notice from './component/Notice';
import Tab from '../_common/Tab';
import BookmarkUser from './component/BookmarkUser';

const CourseDetail = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tab_list = [
    {
      id: '1',
      name: '과목 설정',
      icon: <i className='icofont icofont-edit' />,
      component: Subject,
    },
    {
      id: '2',
      name: '판매 설정',
      icon: <i className='icofont icofont-credit-card' />,
      component: Sale,
    },
    {
      id: '3',
      name: '공지 사항',
      icon: <i className='icofont icofont-notification' />,
      component: Notice,
    },
    {
      id: '4',
      name: '북마크 유저',
      icon: <i className='icofont icofont-book-alt' />,
      component: BookmarkUser,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb parent='강의' title='강의정보' />
      <Container fluid={true}>
        <Info course_id={props.match.params.course_id} />
        {!!Number(props.match.params.course_id) && (
          <Tab obj_id={props.match.params.course_id} tab_list={tab_list} />
        )}
      </Container>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  CourseInfo: state.CourseStore,
  CommonStore: state.CommonStore,
});

export default connect(mapStateToProps, null)(CourseDetail);
