import React, { Fragment, useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { getIntegerArray, getInvoiceType, initComma, toQueryString } from '../../util/common';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Label,
  Row,
  Table,
} from 'reactstrap';
import Loading from '../_common/Loading';
import InvoiceModel from '../../model/InvoiceModel';
import { CSVLink } from 'react-csv';

// 거의 바뀌지 않을 데이터라 위에 선언함
const current_date = new Date();
const current_year = current_date.getFullYear();
const current_month = current_date.getMonth() + 1;
const year_list = getIntegerArray(current_year, 2019, -1);
const month_list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const TotalInvoiceList = (props) => {
  const params = props.location.state ||
    queryString.parse(props.location.search) || {
      year: current_year,
      month: current_month,
    };

  const headers = [
    { label: '강사명', key: 'teacher_name' },
    { label: '상품명', key: 'title' },
    { label: '실매출금액', key: 'purchased_price' },
    { label: '환불금액', key: 'refund_price' },
    { label: '합계금액', key: 'total_price' },
    { label: '공급가', key: 'supply_price' },
    { label: '면세가', key: 'tax_free_price' },
    { label: '부가세', key: 'vat' },
    { label: '수익분배율', key: 'commission_rate' },
    { label: '정산유형', key: 'invoice_user_type' },
    { label: '정산금액', key: 'invoice_price' },
    { label: '숨김여부', key: 'is_hidden' },
  ];

  const default_filter = useRef({
    year: params.year ? params.year : current_year,
    month: params.month ? params.month : current_month,
  });
  const [filter_set, setFilterSet] = useState(default_filter.current);
  const [data_list, setDataList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [is_filer_spread, setIsFilterSpread] = useState(!!params.is_filer_spread);

  const initData = () => {
    InvoiceModel.getTotalMasterList({
      ...params,
    }).then((data) => {
      const { total_invoice_set } = data;
      total_invoice_set.sort(function (a, b) {
        return b.invoice_price - a.invoice_price;
      });
      setDataList(total_invoice_set);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setFilterSet(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );
    initData();
  }, [props.location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onFilterChange = (e) => {
    setFilterSet({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onFilterSubmit = (e) => {
    setIsLoading(true);
    pushHistory({
      ...filter_set,
      is_filer_spread: is_filer_spread,
    });
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Breadcrumb parent='정산' title='전체정산 목록' />
      <Container fluid={true}>
        {!isLoading ? (
          <>
            <Row>
              <Col sm='12'>
                <Card>
                  <div className='default-according style-1' id='accordionoc'>
                    <CardHeader style={{ padding: '12px 30px' }}>
                      <Button
                        color='black pl-0'
                        data-toggle='collapse'
                        onClick={() => setIsFilterSpread(!is_filer_spread)}
                        data-target='#collapseicon'
                        aria-expanded={is_filer_spread}
                        aria-controls='collapseicon'
                      >
                        <h5 className='mb-0'>검색필터</h5>
                      </Button>
                    </CardHeader>
                  </div>
                  <Collapse isOpen={is_filer_spread}>
                    <CardBody>
                      <div>
                        <div className='form-row form-group'>
                          <Col md='6'>
                            <Label htmlFor='id'>년</Label>
                            <select
                              name='year'
                              className='form-control'
                              onChange={onFilterChange}
                              value={filter_set.year}
                            >
                              {year_list.map((elem, index) => {
                                return (
                                  <option key={index} value={elem}>
                                    {elem}
                                  </option>
                                );
                              })}
                            </select>
                          </Col>
                          <Col md='6'>
                            <Label htmlFor='id'>월</Label>
                            <select
                              name='month'
                              className='form-control'
                              onChange={onFilterChange}
                              value={filter_set.month}
                            >
                              {month_list.map((elem, index) => {
                                return (
                                  <option key={index} value={elem}>
                                    {elem}
                                  </option>
                                );
                              })}
                            </select>
                          </Col>
                        </div>
                        <div className='form-row'>
                          <Col className='text-right mt-auto'>
                            <Button
                              className='btn btn-pill'
                              color='primary'
                              onClick={(e) => {
                                onFilterSubmit(e);
                              }}
                            >
                              검색
                            </Button>
                          </Col>
                        </div>
                      </div>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <Card>
                  <CardHeader>
                    <Row style={{ marginBottom: '-20px' }}>
                      <Col sm={7}>
                        <p style={{ marginTop: '7px' }}>
                          총 결과 :{' '}
                          <span style={{ color: 'coral' }}>
                            {initComma(data_list?.length || 0)}
                          </span>
                          건
                        </p>
                        <Loading isLoading={isLoading} />
                      </Col>
                      <Col className='text-right'>
                        <span style={{ color: '#7E37D8', fontSize: '10px' }}>
                          * 0원인 경우 수강권 또는 할인을 통해 구입한 내역입니다.
                        </span>
                        <CSVLink
                          data={data_list}
                          headers={headers}
                          filename={`total-invoice-${current_year}-${current_month}.csv`}
                        >
                          <Button
                            className='btn btn-sm btn-pill'
                            outline
                            color='primary-2x'
                            style={{ marginBottom: '5px', marginLeft: '10px' }}
                          >
                            CSV 다운로드
                          </Button>
                        </CSVLink>
                      </Col>
                    </Row>
                  </CardHeader>
                  <div className='table-responsive'>
                    <Table>
                      <tbody>
                        <tr style={{ textAlign: 'center' }}>
                          <th>강사명</th>
                          <th>상품명</th>
                          <th>① 실 매출금액</th>
                          <th>② 환불금액</th>
                          <th>③ 합계금액 (① - ②)</th>
                          <th>④ 공급가 (③ - ⑥)</th>
                          <th>⑤ 면세가</th>
                          <th>⑥ 부가세 ((③ - ⑤) * 0.1)</th>
                          <th>⑦ 수익분배율</th>
                          <th>정산유형</th>
                          <th>⑧ 정산금액 (④ * ⑥)</th>
                          <th>숨김여부</th>
                        </tr>
                        {data_list?.map((data, index) => {
                          return (
                            <tr key={index} style={{ textAlign: 'center' }}>
                              <td>{data.teacher_name}</td>
                              <td>{data.title}</td>
                              <td>{initComma(data.purchased_price)}</td>
                              <td>{initComma(data.refund_price)}</td>
                              <td>{initComma(data.total_price)}</td>
                              <td>{data.supply_price ? initComma(data.supply_price) : 0}</td>
                              <td>{data.tax_free_price ? initComma(data.tax_free_price) : 0}</td>
                              <td>{data.vat ? initComma(data.vat) : 0}</td>
                              <td>{data.commission_rate * 100}%</td>
                              <td>{getInvoiceType(data.invoice_user_type)}</td>
                              <td>{initComma(data.invoice_price)}</td>
                              <td>{initComma(data.is_hidden ? '숨김' : '공개')}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '18%' }}>
            <h4>
              <Loading
                custom_style={{ marginLeft: '20px', marginRight: '20px' }}
                isLoading={true}
              />
              로딩중...
              <Loading
                custom_style={{ marginLeft: '20px', marginRight: '20px' }}
                isLoading={true}
              />
            </h4>
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default TotalInvoiceList;
