import React, { Fragment, useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import Form from 'reactstrap/es/Form';
import SweetAlert from 'sweetalert2';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CourseModel from '../../model/CourseModel';
import Loading from '../_common/Loading';
import CommonModel from '../../model/CommonModel';
import CustomInput from '../_common/component/CustomInput';
import CustomTypeahead from '../_common/component/CustomTypeahead';
import { errMessageOccur } from '../../util/common';
import CustomLabel from '../_common/component/CustomLabel';
import CustomSelectInput from '../_common/component/CustomSelectInput';

const obj_err_msg = {
  title: '수강코드명',
  expire_time: '수강코드만료일',
  course_allocation_data: '판매항목',
  ticket_issue: '발행개수',
};

const TicketMultiple = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const isEdit = useState(!Number(props.match.params.ticket_id))[0];
  const [isLoading, setIsLoading] = useState(false);

  // Typeahead Multi 보여지는 용도 (course_allocation_data POST 이용)
  const [course_ticket_sale_info_set_select, setCourseTicketSaleInfoSetSelect] = useState([]);
  // Typeahead Single 보여지는 용도 (course_allocation_data POST 이용)
  const [course_ticket_product_select, setCourseTicketProductSelect] = useState([]);

  const [product_set, setProductSet] = useState([]);
  const [sale_info_set, setSaleInfoSet] = useState([]);

  const [course_ticket, setCourseTicket] = useState({
    id: '',
    course_allocation_data: '',
    title: '',
    description: '',
    ticket_issue: '',
    override_rental_time_in_day: '',
    course_ticket_log: [],
    create_time: '',
    expire_time: '',
  });

  const [log, setLog] = useState('');

  const onCourseTicketInputChange = (e) => {
    setCourseTicket({ ...course_ticket, [e.target.name]: e.target.value });
  };

  const onExpireDateTimeChange = (e) => {
    const date = moment(e);
    if (date.year() < 10000) {
      setCourseTicket({ ...course_ticket, expire_time: e && date.format('YYYY-MM-DDTHH:mm:ss') });
    }
  };

  const onExprieDateTimeSelect = (e) => {
    const {
      target: { value },
    } = e;

    let expire_time = moment();

    if (value === '0') {
      expire_time = '';
    } else if (value === '1') {
      expire_time = expire_time.add(3, 'months');
    } else if (value === '2') {
      expire_time = expire_time.add(6, 'months');
    } else if (value === '3') {
      expire_time = expire_time.add(12, 'months');
    }

    setCourseTicket({
      ...course_ticket,
      expire_time: expire_time && expire_time.format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  const onInputSubmit = async (e) => {
    e.preventDefault();

    let err_msg = errMessageOccur(course_ticket, obj_err_msg);

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;
      SweetAlert.fire({
        title: '아래 내용과 같이 발급하시겠습니까?',
        html:
          "<table style='margin: auto' border='1'>" +
          "<tr><td class='px-2'>수강코드명</td><td class='px-2'>" +
          course_ticket.title +
          '</td></tr>' +
          "<tr><td class='px-2'>설명</td><td class='px-2'>" +
          course_ticket.description +
          '</td></tr>' +
          "<tr><td class='px-2'>대여기간 덮어쓰기</td><td class='px-2'>" +
          course_ticket.override_rental_time_in_day +
          '</td></tr>' +
          "<tr><td class='px-2'>수강코드만료일</td><td class='px-2'>" +
          moment(course_ticket.expire_time).format('YYYY-MM-DD HH:mm:ss') +
          '</td></tr>' +
          "<tr><td class='px-2'>발행개수</td><td class='px-2'>" +
          course_ticket.ticket_issue +
          '</td></tr>' +
          "<tr><td class='px-2'>판매항목(강의)</td><td class='px-2'>" +
          course_ticket_sale_info_set_select.map((sale_info) => sale_info.combine_name).join('\n') +
          '</td></tr>' +
          "<tr><td class='px-2'>판매항목(패키지)</td><td class='px-2'>" +
          (product_set.filter(
            (obj) =>
              String(obj.product_id) ===
              JSON.parse(course_ticket.course_allocation_data)?.productId,
          )[0]?.combine_name || '') +
          '</td></tr>' +
          '</table>',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.value) {
          // 발행개수
          for (let x = 0; x < course_ticket.ticket_issue; x++) {
            await CourseModel.multipleTicket({
              ...course_ticket,
            }).then(({ ticket_text }) => {
              setLog((prevState) => prevState + `${ticket_text}\n`);
            });
          }
          setLog((prevState) => prevState + `발급 완료!\n`);
        }
      });

      e.target.disabled = false;
      setIsLoading(false);
    } else {
      await SweetAlert.fire({
        title: '발급중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  useEffect(async () => {
    await CommonModel.getSearch('cms_accessible_course_ticket_product').then((data) => {
      setProductSet(data);
    });

    await CommonModel.getSearch('product_course_sale_info').then((data) => {
      setSaleInfoSet(data);
    });
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent='수강코드' title={`대량 수강코드 발급`} />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form onSubmit={onInputSubmit}>
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`대량 수강코드 발급`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            발급
                          </Button>
                        </>
                      )}
                      <Button
                        className='btn-pill'
                        color='primary'
                        style={{ marginRight: '8px' }}
                        onClick={() => {
                          // 생성(0)일 경우 수정(0 이상)일 경우
                          props.history.push('/ticket');
                        }}
                      >
                        {isEdit ? '취소' : '편집'}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={7} style={{ borderRight: '2px solid #F5F5F5' }}>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'title'}
                            disabled={!isEdit}
                            style={{ resize: 'none' }}
                            value={course_ticket.title || ''}
                            is_required={true}
                            label={'수강코드명'}
                            onChange={onCourseTicketInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'description'}
                            disabled={!isEdit}
                            value={course_ticket.description || ''}
                            label={'설명'}
                            onChange={onCourseTicketInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12'>
                          <CustomLabel is_required={true} label={'수강코드'} />
                          <FormGroup>
                            <InputGroup>
                              <Input
                                type={'text'}
                                name={'ticket_text'}
                                disabled={true}
                                value={''}
                                placeholder={
                                  '[자동으로 생성됩니다] 수강코드는 중복 될 수 없습니다. (최대 36자)'
                                }
                              />
                              <InputGroupAddon addonType='append' style={{ cursor: 'pointer' }}>
                                <InputGroupText>
                                  <i className='icofont icofont-random mr-2' /> 생성
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <Col md='mb-3 ml-3'>
                          <CustomLabel label={'수강코드만료일'} is_required={true} />
                          <DatePicker
                            autoComplete='off'
                            className='form-control digits mb-3'
                            name='expire_time'
                            disabled={!!Number(props.match.params.ticket_id)}
                            onChange={onExpireDateTimeChange}
                            locale='ko'
                            selected={
                              course_ticket.expire_time ? new Date(course_ticket.expire_time) : null
                            }
                            timeFormat='HH:mm:ss'
                            showTimeSelect
                            dateFormat='yyyy-MM-dd HH:mm:ss'
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'date_range'}
                            disabled={!!Number(props.match.params.ticket_id)}
                            label={'날짜선택'}
                            onChange={onExprieDateTimeSelect}
                            options={[
                              { id: 0, name: '------' },
                              { id: 1, name: '3개월' },
                              { id: 2, name: '6개월' },
                              { id: 3, name: '1년' },
                            ]}
                          />
                        </Col>
                        <Col md='4 mb-3'>
                          <CustomInput
                            type='number'
                            label={'대여기간'}
                            disabled={!!Number(props.match.params.ticket_id)}
                            name={'override_rental_time_in_day'}
                            value={course_ticket.override_rental_time_in_day || ''}
                            onChange={onCourseTicketInputChange}
                            placeholder={'일자(숫자만입력)'}
                            tooltip_desc={
                              '입력하는 경우 각 상품의 대여기간을 따르지 않고,\n포함된 모든 판매항목에 대해 입력한 대여기간으로 설정함.'
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomTypeahead
                            disabled={!!Number(props.match.params.ticket_id)}
                            is_required={true}
                            multiple={true}
                            label={'판매항목(강의)'}
                            tooltip_desc={'판매항목은 수강코드 발급 이후 절대 변경 할 수 없습니다.'}
                            selected={course_ticket_sale_info_set_select}
                            selectedHandler={setCourseTicketSaleInfoSetSelect}
                            labelKey={'combine_name'}
                            placeholder={'강의를 선택 해주세요.'}
                            onChange={(sale_info_ids) => {
                              setCourseTicket((prevState) => {
                                let course_allocation_data = {};

                                if (prevState.course_allocation_data) {
                                  course_allocation_data = JSON.parse(
                                    prevState.course_allocation_data,
                                  );
                                }

                                course_allocation_data['saleInfoIds'] = sale_info_ids.map((obj) =>
                                  String(obj.sale_info_id),
                                );

                                if (!course_allocation_data['saleInfoIds']?.length) {
                                  delete course_allocation_data['saleInfoIds'];
                                }

                                if (Object.keys(course_allocation_data).length === 0) {
                                  course_allocation_data = '';
                                } else {
                                  course_allocation_data = JSON.stringify(course_allocation_data);
                                }

                                return {
                                  ...prevState,
                                  course_allocation_data: course_allocation_data,
                                };
                              });
                            }}
                            // customToken={(target) => {
                            //     return (`${target.name} : ${target.description}`)
                            // }}
                            options={sale_info_set}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          {!!!Number(props.match.params.ticket_id) ? (
                            <CustomTypeahead
                              disabled={!!Number(props.match.params.ticket_id)}
                              is_required={true}
                              multiple={false}
                              label={'판매항목(패키지)'}
                              tooltip_desc={
                                '판매항목은 수강코드 발급 이후 절대 변경 할 수 없습니다.'
                              }
                              onChange={(selected_item) => {
                                setCourseTicketProductSelect(selected_item);

                                setCourseTicket((prevState) => {
                                  let course_allocation_data = {};

                                  if (prevState.course_allocation_data) {
                                    course_allocation_data = JSON.parse(
                                      prevState.course_allocation_data,
                                    );
                                  }

                                  course_allocation_data['productId'] = String(
                                    selected_item[0]?.product_id,
                                  );

                                  if (Object.keys(course_allocation_data).length === 0) {
                                    course_allocation_data = '';
                                  } else {
                                    course_allocation_data = JSON.stringify(course_allocation_data);
                                  }

                                  return {
                                    ...prevState,
                                    course_allocation_data: course_allocation_data,
                                  };
                                });

                                setCourseTicketProductSelect([]);
                              }}
                              selected={course_ticket_product_select}
                              display_options={{
                                keys: ['combine_name'],
                                onDelete: (e) => {
                                  setCourseTicket((prevState) => {
                                    let course_allocation_data = {};

                                    if (prevState.course_allocation_data) {
                                      course_allocation_data = JSON.parse(
                                        prevState.course_allocation_data,
                                      );
                                    }

                                    delete course_allocation_data['productId'];

                                    if (Object.keys(course_allocation_data).length === 0) {
                                      course_allocation_data = '';
                                    } else {
                                      course_allocation_data =
                                        JSON.stringify(course_allocation_data);
                                    }

                                    return {
                                      ...prevState,
                                      course_allocation_data: course_allocation_data,
                                    };
                                  });
                                },
                              }}
                              labelKey={'combine_name'}
                              options={product_set}
                              options_value_normalization={'combine_name'}
                              placeholder={'패키지를 선택 해주세요.'}
                            />
                          ) : course_ticket_product_select.length ? (
                            <>
                              <CustomLabel
                                tooltip_desc={
                                  "생성을 완료하면 '강의 설정' 및 '프로모션 위치' 는 변경 불가능 합니다."
                                }
                                label={'강의 설정'}
                                is_required={true}
                              />
                              <Link
                                className='form-control text-primary'
                                to={'/product/' + course_ticket_product_select[0] || ''}
                              >
                                {course_ticket_product_select[1] || ''}
                              </Link>
                            </>
                          ) : (
                            <>
                              <CustomLabel
                                tooltip_desc={
                                  "생성을 완료하면 '강의 설정' 및 '프로모션 위치' 는 변경 불가능 합니다."
                                }
                                label={'강의 설정'}
                                is_required={true}
                              />
                              <Input className={'form-control text-primary'} disabled={true} />
                            </>
                          )}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={'3 mb-3'}>
                          <CustomInput
                            type={'number'}
                            name={'ticket_issue'}
                            value={course_ticket.ticket_issue || ''}
                            is_required={true}
                            label={'발행개수'}
                            onChange={onCourseTicketInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'textarea'}
                            label={'로그'}
                            value={log}
                            disabled={true}
                            style={{ height: 200 }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TicketMultiple;
