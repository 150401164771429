import { ADD_TEMPORARY_VALUE, CLEAR_TEMPORARY } from '../../action_type';

export const addTemporaryValue = (key, value) => ({
  type: ADD_TEMPORARY_VALUE,
  key,
  value,
});

export const clearTemporary = () => ({
  type: CLEAR_TEMPORARY,
});
