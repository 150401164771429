// store(get), clear(delete), set(update), add(post)

/* common */
export const STORED_AUTH_INFO = 'STORED_AUTH_INFO';
export const SET_DELETE_IMG = 'SET_DELETE_IMG';
export const CLEAR_DELETE_IMG = 'CLEAR_DELETE_IMG';
export const STORED_STATIC_CODE = 'STORED_STATIC_CODE';
export const CLEAR_STATIC_CODE = 'CLEAR_STATIC_CODE';

/* course */
export const STORED_COURSE_INFO = 'STORED_COURSE_INFO';
export const CLEAR_COURSE_INFO = 'CLEAR_COURSE_INFO';
export const SET_COURSE_INFO = 'SET_COURSE_INFO';
export const SET_TAG = 'SET_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const SET_KIT = 'SET_KIT';
export const SET_SUB_CATEGORY = 'SET_SUB_CATEGORY';
export const SET_COURSE_BODY = 'SET_COURSE_BODY';

/* ebook */
export const STORED_EBOOK_INFO = 'STORED_EBOOK_INFO';
export const CLEAR_EBOOK_INFO = 'CLEAR_EBOOK_INFO';
export const SET_EBOOK_INFO = 'SET_EBOOK_INFO';
export const SET_EBOOK_TAG = 'SET_EBOOK_TAG';
export const REMOVE_EBOOK_TAG = 'REMOVE_EBOOK_TAG';
export const SET_EBOOK_KIT = 'SET_EBOOK_KIT';
export const SET_EBOOK_SUB_CATEGORY = 'SET_EBOOK_SUB_CATEGORY';
export const SET_EBOOK_BODY = 'SET_EBOOK_BODY';

/* course sale */
export const STORED_COURSE_SALE = 'STORED_COURSE_SALE';
export const CLEAR_COURSE_SALE = 'CLEAR_COURSE_SALE';
export const SET_COURSE_SALE = 'SET_COURSE_SALE';
export const ADD_COURSE_SALE = 'ADD_COURSE_SALE';
export const DELETE_COURSE_SALE = 'DELETE_COURSE_SALE';
export const SET_COURSE_SALE_PRICE = 'SET_COURSE_SALE_PRICE';

/* ebook sale */
export const STORED_EBOOK_SALE = 'STORED_EBOOK_SALE';
export const CLEAR_EBOOK_SALE = 'CLEAR_EBOOK_SALE';
export const SET_EBOOK_SALE = 'SET_EBOOK_SALE';
export const ADD_EBOOK_SALE = 'ADD_EBOOK_SALE';
export const DELETE_EBOOK_SALE = 'DELETE_EBOOK_SALE';
export const SET_EBOOK_SALE_PRICE = 'SET_EBOOK_SALE_PRICE';

/* course subject */
export const STORED_COURSE_SUBJECT = 'STORED_COURSE_SUBJECT';
export const CLEAR_COURSE_SUBJECT = 'CLEAR_COURSE_SUBJECT';
export const SET_COURSE_SUBJECT = 'SET_COURSE_SUBJECT';
export const ADD_COURSE_SUBJECT = 'ADD_COURSE_SUBJECT';
export const DELETE_COURSE_SUBJECT = 'DELETE_COURSE_SUBJECT';

/* course notice */
export const STORED_COURSE_NOTICE = 'STORED_COURSE_NOTICE';
export const SET_COURSE_NOTICE = 'SET_COURSE_NOTICE';
export const CLEAR_COURSE_NOTICE = 'CLEAR_COURSE_NOTICE';

/* Application from */
export const STORED_RECRUIT_FORM_INFO = 'STORED_RECRUIT_FORM_INFO';
export const SET_RECRUIT_FORM_INFO = 'SET_RECRUIT_FORM_INFO';
export const CLEAR_RECRUIT_FORM_INFO = 'CLEAR_RECRUIT_FORM_INFO';
export const SET_RECRUIT_FORM_BODY = 'SET_RECRUIT_FORM_BODY';

/* b2b - company */
export const ADD_COMPANY_ID = 'SET_COMPANY_ID';
export const ADD_COMPANY_INFO = 'SET_COMPANY_INFO';
export const ADD_COMPANY_EMPLOYEE_SET = 'SET_COMPANY_EMPLOYEE_SET';
export const CLEAR_COMPANY = 'CLEAR_COMPANY';

export const ADD_TEMPORARY_VALUE = 'SET_TEMPORARY_VALUE';
export const CLEAR_TEMPORARY = 'CLEAR_TEMPORARY';
