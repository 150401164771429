import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';

import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';

import Loading from '../_common/Loading';
import Tab from '../_common/Tab';
import Media from 'reactstrap/es/Media';
import AccountModel from '../../model/AccountModel';
import CommonModel from '../../model/CommonModel';
import { accountGender } from '../../util/common';
import AccountPurchaseInfoTable from './component/AccountPurchaseInfoTable';
import AccountCourseTable from './component/AccountCourseTable';
import AccountCRMMemo from './component/AccountCRMMemo';

const AccountDetail = (props) => {
  const [account, accountSet] = useState({});
  const [account_status_set, setAccountStatusSet] = useState([]);
  const [account_type_set, setAccountTypeSet] = useState([]);
  const [isLoading, setIsLoading] = useState({ get: false, put: false });
  const [isEdit, setIsEdit] = useState(false);

  const tab_list = [
    {
      id: '1',
      name: '결제 정보',
      icon: <i className='icofont icofont-money' />,
      component: AccountPurchaseInfoTable,
      component_props: props.match.params.account_id,
    },
    {
      id: '2',
      name: '보유 강의',
      icon: <i className='icofont icofont-ui-video-play' />,
      component: AccountCourseTable,
      component_props: props.match.params.account_id,
    },
  ];

  const getAccount = () => {
    AccountModel.getAccount(props.match.params).then(({ account }) => {
      accountSet(account);
    });
  };
  useEffect(() => {
    setIsLoading((prevState) => ({ ...prevState, get: true }));

    CommonModel.staticCode({ code_name: 'account_status_type_set' }).then(({ code_set }) => {
      setAccountStatusSet(code_set);
    });

    CommonModel.staticCode({ code_name: 'account_type_set' }).then(({ code_set }) => {
      setAccountTypeSet(code_set);
    });

    getAccount();

    setIsLoading((prevState) => ({ ...prevState, get: false }));
  }, []);

  const onStatusSubmit = (e) => {
    setIsLoading({ ...isLoading, put: true });
    e.target.disabled = true;

    let tmp_account = {
      id: account.id,
      account_status_id: account.account_status_id,
      account_type_id: account.account_type_id,
    };

    AccountModel.putAccount(tmp_account).then(() => {
      setIsLoading({ ...isLoading, put: false });
      e.target.disabled = false;
      setIsEdit((prevState) => !prevState);
    });
  };

  const onPasswordReset = (e) => {
    let is_processed = window.confirm('정말로 해당유저의 비밀번호를 초기화 할까요?');
    if (is_processed) {
      setIsLoading({ ...isLoading, put: true });
      e.target.disabled = true;

      AccountModel.resetAccountPassword(props.match.params.account_id).then(({ password }) => {
        alert(`비밀번호가 ${password} 로 초기화 되었습니다.`);
        setIsLoading({ ...isLoading, put: false });
        e.target.disabled = false;
      });
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent='사용자목록' title='사용자정보' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Col className='text-right mb-4'>
              {isEdit && (
                <>
                  <Button
                    className='btn-pill'
                    color='info'
                    onClick={(e) => {
                      onStatusSubmit(e);
                    }}
                    style={{ marginRight: '8px' }}
                  >
                    저장
                  </Button>
                </>
              )}
              <Button
                className='btn-pill'
                color='primary'
                onClick={(e) => {
                  if (isEdit) {
                    setIsEdit((prevState) => !prevState);
                    getAccount();
                  } else {
                    setIsEdit((prevState) => !prevState);
                  }
                }}
              >
                {isEdit ? <>취소</> : <>편집</>}
              </Button>
            </Col>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>개인정보</h3>
                <Loading isLoading={isLoading.get} />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md='3 mb-3'>
                    <Card>
                      <div className='blog-box blog-grid text-center'>
                        <div className='blog-details-main'>
                          <h6 className='blog-bottom-details'>프로필사진</h6>
                        </div>
                        {account?.info?.picture_thumbnail_image_url ? (
                          <Media
                            className='img-fluid bottom-radius-blog'
                            src={account.info.picture_thumbnail_image_url}
                            alt=''
                          />
                        ) : (
                          <Media
                            className='img-fluid bottom-radius-blog'
                            src={`${process.env.REACT_APP_IMG_URL}/static/png/common/no-profile-image.png`}
                            alt=''
                          />
                        )}
                      </div>
                    </Card>
                  </Col>
                  <Col md='9 mb-3'>
                    <Row>
                      <Col md='4 mb-3'>
                        <Label htmlFor='account_name'>아이디</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='account_name'
                          defaultValue={account.account_name}
                          disabled={true}
                        />
                      </Col>
                      <Col md='2 mb-3'>
                        <Label htmlFor='nickname'>닉네임</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='nickname'
                          defaultValue={account?.info?.nickname}
                          disabled={true}
                        />
                      </Col>
                      <Col md='2 mb-3'>
                        <Label htmlFor='name'>이름</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='name'
                          defaultValue={account?.info?.name}
                          disabled={true}
                        />
                      </Col>
                      <Col md='4 mb-3'>
                        <Label htmlFor='email'>이메일</Label>
                        <Input
                          className='form-control'
                          type='email'
                          name='email'
                          defaultValue={account?.info?.email}
                          disabled={true}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md='2 mb-3'>
                        <Label htmlFor='hasCourse'>성별</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='job_class'
                          defaultValue={
                            account?.info?.gender ? accountGender(account.info.gender) : ''
                          }
                          disabled={true}
                        />
                      </Col>
                      <Col md='3 mb-3'>
                        <Label htmlFor='birth'>생년월일</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='birth'
                          defaultValue={account?.info?.birth}
                          disabled={true}
                        />
                      </Col>
                      <Col md='3 mb-3'>
                        <Label htmlFor='phone'>핸드폰</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='phone'
                          defaultValue={account?.info?.phone}
                          disabled={true}
                        />
                      </Col>
                      <Col md='4 mb-3'>
                        <Label htmlFor='job_class'>직업분류</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='job_class'
                          defaultValue={account?.info?.job_class}
                          disabled={true}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md='6 mb-3'>
                        <Label htmlFor='address'>주소</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='address'
                          defaultValue={account?.info?.address}
                          disabled={true}
                        />
                      </Col>
                      <Col md='6 mb-3'>
                        <Label htmlFor='address_detail'>상세주소</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='address_detail'
                          defaultValue={account?.info?.address_detail}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2 mb-3'>
                        <Label htmlFor='address_code'>우편번호</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='address_code'
                          defaultValue={account?.info?.address_code}
                          disabled={true}
                        />
                      </Col>
                      <Col md='3 mb-3'>
                        <Label htmlFor='create_time'>가입일</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='create_time'
                          defaultValue={account.create_time}
                          disabled={true}
                        />
                      </Col>
                      <Col md='3 mb-3'>
                        <Label htmlFor='account_status'>계정상태</Label>
                        <Input
                          type='select'
                          name='account_status'
                          className='form-control'
                          onChange={(e) => {
                            accountSet({ ...account, account_status_id: e.target.value });
                          }}
                          value={account.account_status_id}
                          disabled={!isEdit}
                        >
                          {account_status_set.map((account_status, index) => {
                            return (
                              <option key={index} value={account_status.id}>
                                {account_status.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                      <Col md='2 mb-3'>
                        <Label htmlFor='account_type_id'>계정 타입</Label>
                        <Input
                          type='select'
                          name='account_type_id'
                          className='form-control'
                          onChange={(e) => {
                            accountSet({
                              ...account,
                              account_type_id: Number(e.target.value),
                            });
                          }}
                          value={account.account_type_id}
                          disabled={!isEdit}
                        >
                          {account_type_set.map((account_type, index) => {
                            return (
                              <option key={index} value={account_type.id}>
                                {account_type.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                      <Col md='2 mt-4'>
                        <Button
                          style={{ fontSize: '13px' }}
                          className='btn-pill'
                          color='danger'
                          onClick={(e) => {
                            onPasswordReset(e);
                          }}
                        >
                          비밀번호 초기화
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Tab tab_list={tab_list} />
            <AccountCRMMemo />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AccountDetail;
