import React, { useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import SweetAlert from 'sweetalert2';
import Token from 'react-bootstrap-typeahead/lib/components/Token';
import CustomLabel from './CustomLabel';

// [Mutli = false]
// tooltip_desc                 String       : Tooltip 설명
// disabled                     boolean      : edit 를 못하도록 막기
// label                        String       : Typeahead 상단에 어떤 역할을 할 지 글씨로 나타냄
// is_required                  boolean      : 라벨 옆에 빨간색 * 가 생김
// multiple                     boolean      : multi 용 Typeahead 사용
// onChange                     function     : 바뀌었을 경우 작업할 용
// selected                     [{}] or []   : 기본으로 선택 되어있는 것

// display_options              object       : 선택이 될 경우 라벨 옆에 값 나오는 것에 관한 설정
// (keys : object의 기준 키, default_object: 초기 값, onDelete: 제거 버튼이 나오며 누르면 일어날 함수 작성)
// ㄴ {keys:["title", "예제"], default_object: {id: 1, title: "something", 예제: "ddmmdm"}, onDelete: ()=>{}}

// onDisplayDeleted             function     : 선택되어있는 값 isEdit 일 경우 삭제 클릭하면 일어나는 일
// prev_selected_display_obj    [{}]         : 처음 초기값으로 object 설정하기 (기준 display_name_keys)
// options                      [{}] or []   : select box 안에 들어갈 값들
// options_value_normalization  String       : 옵션 배열 안에 들어간 object의 값에 '개행문자' 삭제
// labelKey                     String       : options에서 obj안 key를 선택하면 select box key에 대응하는 value 값이 보여짐
// placeholder                  String       : placeholder 역할

// [Multi = true]
// tooltip_desc                 String       : Tooltip 설명
// disabled                     boolean      : edit 를 못하도록 막기
// label                        String       : Typeahead 상단에 어떤 역할을 할 지 글씨로 나타냄
// is_required                  boolean      : 라벨 옆에 빨간색 * 가 생김
// multiple                     boolean      : multi 용 Typeahead 사용
// selected                     [{}], []     : 기본으로 선택 되어있는 state
// selectedHandler              setState_func: 기본으로 선택 되어있는 state 변경하기 위함
// onTokenClick                 func         : 선택한 토큰을 선택했을 경우 (매게변수로는 클릭한 Token 의 정보가 들어간다)
// customToken                  func         : 토큰 형태 직접 만들기 string 반환 => (매게변수로는 기본 Token 의 정보가 들어간다)
// options                      [{}], []     : select box 안에 들어갈 값들
// labelKey                     String       : options에서 obj 에서 select box 에 나오게 될 값
// placeholder                  String       : placeholder 역할

const CustomTypeahead = (props) => {
  const [selected_item, setSelectedItem] = useState([props.display_options?.default_object]);
  const typeahead_input = useRef();

  const tooltip_name = useRef('_' + String(Math.random()).replace('.', ''));

  const { disabled, onTokenClick, customToken, reduxSelectedHandler, ...filtered_props } = props;
  const { display_options } = props;

  // Multi
  const onMultiSelectedChange = async (state, setState, ref, selected) => {
    // 삭제할 경우
    if (state.length > selected.length) {
      // 변한 것을 알기 위함 여집합
      ref.current.blur();
      // if (있는 녀석을 삭제할 경우)
      SweetAlert.fire({
        title: '목록에서 삭제할까요?',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      }).then((result) => {
        if (result.value) {
          setState(selected);
          props.onChange && props.onChange(selected);
        }
      });
      // 생성할 경우
    } else {
      setState(selected);
      props.onChange && props.onChange(selected);
    }
  };

  // 개행 문자 삭제 (type 는 어느 위치의 개행 문자를 삭제할 것인지)
  const transformEscapeWord = (array, type) => {
    return array.map((obj) => {
      // 개행 문자 삭제
      if (obj[type]) {
        return { ...obj, [type]: obj[type].replace(/[\r\n\t]/gi, ' ').replace(/\s+/g, ' ') };
      } else {
        return obj;
      }
    });
  };

  return (
    <>
      <CustomLabel
        tooltip_desc={props.tooltip_desc}
        label={props.label}
        is_required={props.is_required}
      />
      {!props.multiple && display_options?.keys ? (
        <>
          {selected_item[0] ? (
            <div className='ml-3 mb-2 f-w-900'>
              <span>
                {`${
                  selected_item[0]
                    ? display_options.keys
                        .map((key) => {
                          return selected_item[0][key];
                        })
                        .join(', ')
                    : ''
                }`}
              </span>
              {display_options?.onDelete && selected_item[0] && (
                <button
                  className={'btn-sm btn-danger ml-3'}
                  onClick={(e) => {
                    display_options.onDelete();
                    setSelectedItem([]);
                  }}
                >
                  제거
                </button>
              )}
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      {!props.multiple ? (
        // Single 버전
        <Typeahead
          clearButton
          {...props}
          onChange={(selected) => {
            // 키보드 검색으로 찾고, 클릭하면 객체가 사라지는 경우가 있다.
            // 그래서 객체가 있는 순간만 저장하도록 설정
            setSelectedItem(selected);
            props.onChange(selected);
            if (selected.length === 0) {
              typeahead_input.current.clear();
              typeahead_input.current.focus();
            }
          }}
          emptyLabel={'선택할 아이템이 없어요.'}
          ref={typeahead_input}
          id={`${tooltip_name}-typeahead`}
          options={transformEscapeWord(props.options, props.options_value_normalization)}
        />
      ) : (
        // Multi 버전
        <Typeahead
          {...filtered_props}
          disabled={disabled}
          onChange={(selected) => {
            // 선택하여 바뀌는 경우 선택
            onMultiSelectedChange(props.selected, props.selectedHandler, typeahead_input, selected);
            if (reduxSelectedHandler) {
              props.reduxSelectedHandler(selected);
            }
          }}
          inputProps={{ className: 'multiple-custom-typeahead' }}
          emptyLabel={'선택할 아이템이 없어요.'}
          onFocus={(e) => {
            if (e.type === 'focus' && disabled) {
              typeahead_input.current.blur();
            }
          }}
          ref={typeahead_input}
          id={`${tooltip_name.current}-typeahead`}
          renderToken={(option, props, index) => {
            return (
              <Token
                disabled={disabled}
                option={option}
                key={index}
                onClick={() => {
                  if (onTokenClick) {
                    onTokenClick(option);
                  }
                }}
                onRemove={() => {
                  if (!disabled) {
                    props.onRemove(option);
                  }
                }}
              >
                {customToken ? customToken(option) : option[props.labelKey]}
              </Token>
            );
          }}
        />
      )}
    </>
  );
};

export default CustomTypeahead;
