import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';

import { Card, CardHeader, Col, Container, Row, Button, Input, Label } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import ImageUploader from '../_common/component/ImageUploader';

import Form from 'reactstrap/es/Form';
import SweetAlert from 'sweetalert2';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import { errMessageOccur } from '../../util/common';
import ProductModel from '../../model/ProductModel';
import TextEditor from '../_common/component/TextEditor';
import CustomLabel from '../_common/component/CustomLabel';
import CustomTypeahead from '../_common/component/CustomTypeahead';
import { Link } from 'react-router-dom';
import CustomTagInput from '../_common/component/CustomTagInput';

import '../../assets/scss/custom-ckeditor5.scss';

const invoice_price_info = (
  <div>
    <h6>결제에서 키트를 바르기 위한 가격 (강사에게 정산해줄 때, 필요하다)</h6>
    <div>사용자가 결제를 하면 우리가 그 기록을 가지고 있는데, 그 기록에 총가격이 들어있다.</div>
    <div>
      만약 키트가 포함된 결제라면 이 총 금액에서 "결합상품용 차감가"를 빼면 키트를 제외한 순수
      가격이 나오는 것이다.
    </div>
    <div>이 속성이 필요한 이유는 "결합상품" 때문에 생긴다.</div>
    <p></p>
    <div>
      "결합상품"이란 강의 혹은 패키지와 같이 키트를 판매하는데, 이때 키트 가격이 일반 가격보다 싸고,
      강의 혹은 패키지가 더 비싸지는 경우를 말하는 것이다.
    </div>
    <div>예) 갓 신입 완벅적응 이벤트 참고</div>
    <p></p>
    <div>
      "엑셀" 강의에 "키트A"를 "결합상품"에 포함하는 경우, "엑셀" 가격을 일반 가격(20,000원)보다 높게
      설정한 후(30,000원)
    </div>
    <div>"키트A" "판매가"를 0 원으로 설정하고, "제휴사공급가"는 10,000 원으로 설정한다.</div>
    <p></p>
    <div>
      일반적으로 강의 가격과 키트 가격을 분리하기 위해서 총 결제 금액에서 키트 "판매가"를 뺀다.
    </div>
    <div>왜 "결합상품용 차감가"가 생겼는지 아래 경우를 통해 알려주겠다.</div>
    <p></p>
    <div>"엑셀" 강사에게 정산을 해준다고 가정하자.</div>
    <div>
      1. 위 "결합상품"을 구매했을 경우, 사용자는 30,000 원으로 구매한다. (기록에 30,000 원이
      저장된다.)
    </div>
    <div>
      2. "엑셀" 강사는 30,000 원을 정산 받는 것이 아니라, "키트A" 가격이 빠진 만큼 정산을 해줘야
      한다.
    </div>
    <div>
      3. 쿠궁! "키트A"의 "판매가"를 빼야 순수 "엑셀" 가격을 알 수 있지만 "판매가"가 0 원이다!
    </div>
    <div>4. 이때, "엑셀" 강사에게 정산을 30,000 원 해주는 게 맞는가?</div>
    <div>
      5. 아니다! "결합상품"은 강의 혹은 패키지와 같이 키트를 판매 하는데, 이때 키트 가격이 일반
      가격보다 싸고, 강의 혹은 패키지가 더 비싸지는 경우라고 했다.
    </div>
    <div>6. "엑셀" 순수 비용을 구하기 위해서 "결합상품용 차감가"를 이용하는 것이다.</div>
    <div>
      7. 위와 같이 "판매가"가 "제휴사공급가" 보다 작으면 "결합상품용 차감가"는 "제휴사공급가"가
      되어야 한다.("결합상품" 경우)
    </div>
    <div>
      8. 만약 "판매가"가 "제휴사공급가" 보다 크면 "결합상품용 차감가"는 "판매가"가 되어야
      한다.(일반적인 경우)
    </div>
    <p></p>
    <div>이해가 안되셨으면 개발팀 문의!!</div>
  </div>
);

const tooptip_word = {
  title:
    '키트의 이름과 설명은, 생성 후 절대 변경 할 수 없습니다.\n' +
    '가격 변동이 아닌 상품의 명칭과 이름이 변경/추가 되어야되는 경우는 새로 키트를 만들어 주세요.',
  description: '유저에게 노출될 수 있으므로 신중히 기입해주세요.',
  inventory_count: '최대 65,535 까지 입력 할 수 있습니다.',
  is_external_kit:
    '외부키트로 설정되는 경우, 강사 정산에서 집계처리 되지 않습니다.\n' +
    '또한 최초 등록 이후 외부키트여부를 변경하게 되면 정산시에도 반영되므로 주의하세요.',
  is_private_mode:
    '비공개 모드로 설정되는 경우, 키트 상세페이지에 접근 불가능하며.\n' +
    '키트가 단독적으로 구매되는것을 막습니다.',
  show_in_application:
    '서비스내 표시될수있는 상품의 경우 리스트 등의 형태로 표시 될수 있습니다.\n그렇지 않은경우는 절대 노출 하지 않습니다.',
  shipping_method_id:
    '배송 방법에따라 선택하며, 배송이 되어야 하는 상품인 경우 적합한 옵션을 선택하세요.\n' +
    '디지털콘텐츠는 배송옵션을 선택하는 경우, 따로 실물이 택배등으로 배송지에 배송되지 않아도 되므로, 결제 페이지에서 고객의 배송지와 관련된 데이터가 표시되지 않습니다 (유저가 헷갈리지 않게하는 장치).',
  provide_price:
    '제휴사공급가는 키트 판매를 통한 내부이익 추산을위해, 사용되는 단순 참고용 가격입니다.',
  our_sale_price: '키트의 판매가는 해당 판매상품 가격에서 추가되는 가격입니다.',
  tax_free_price: '면세 가격으로 무조건 판매가 보다 작거나 같아야 한다.',
  invoice_price: invoice_price_info,
  max_buy_count: '주문시 본 키트의 최대 구매 가능 갯수입니다. (기본값: 10)',
  is_default_option:
    '기본값으로 설정되는 경우, 상세페이지등에서 키트가 기본적으로 선택되어있도록 합니다.\n(기본값: 아니요)',
  main_image_url:
    // "키트 상세페이지를 구성하려는 경우 본 콘텐츠를 반드시 작성하세요.\n" +
    '(201221) 강의 메인이미지와 사이즈 스펙 동일.',
  circle_image_url: '',
  content_html:
    '1. 키트 상세페이지를 구성하려는 경우 본 콘텐츠를 반드시 작성하세요.\n' +
    '2. 상세페이지의 키트 설명 이미지 부분이라도 꼭 입력해주세요.',
  can_discount: '할인 가능 여부 (필요 시, 개발본부에 문의하세요)',
};

const obj_err_msg = {
  title: '키트명',
  description: '키트설명',
  inventory_count: '재고 수',
  is_external_kit: '외부키트 여부',
  is_private_mode: '비공개 모드',
  show_in_application: '서비스내표시여부',
  shipping_method_id: '배송방법',
  max_buy_count: '최대구매가능갯수',
  is_default_option: '기본값여부',
};

const KitDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.kit_id));
  const [search_text, setSearchText] = useState([]);

  const [invoice_account_set, setInvoiceAccountSet] = useState([]);

  const [currency_code_set, setCurrencyCodeSet] = useState([]);
  const [shipping_method_set, setShippingMethodSet] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [kit, setKit] = useState({
    id: '',
    title: '',
    description: '',
    invoice_account: null,
    inventory_count: '',
    is_external_kit: 0,
    is_private_mode: 0,
    show_in_application: 1,
    shipping_method_id: '',
    kit_price_info_set: [
      {
        id: '',
        currency_code_id: '',
        our_sale_price: '',
        provide_price: '',
        tax_free_price: '',
        invoice_price: '',
      },
    ],
    max_buy_count: 10,
    is_default_option: 0,
    main_image_url: '',
    main_thumbnail_image_url: '',
    circle_image_url: '',
    content_html: '',
    can_discount: 0,
  });

  const [kit_tag_set, setKitTagSet] = useState([]);

  const MemoCustomTypeahead = useMemo(() => {
    return (
      <CustomTypeahead
        disabled={!isEdit}
        multiple={false}
        label={'판매금액 정산받을 계정'}
        onChange={(selected_item) => {
          setSearchText(selected_item);

          setKit((prevState) => ({
            ...prevState,
            invoice_account: {
              id: selected_item[0]?.id,
              account_name: selected_item[0]?.account_name,
              account_profile_id: selected_item[0]?.account_profile_id,
              name: selected_item[0]?.name,
            },
          }));

          // Input 값 비우기
          setSearchText([]);
        }}
        selected={search_text}
        display_options={{
          keys: ['account_name', 'name'], // keys 없을 경우 안함 (기준)
          default_object: kit.invoice_account, // prev_selected_display_obj
          onDelete: (e) => {
            // onDelete
            setKit((prevState) => ({
              ...prevState,
              invoice_account: null,
            }));
          },
        }}
        labelKey={(option) => `${option.account_name}`}
        options={invoice_account_set}
        options_value_normalization={'account_name'}
        placeholder={'계정을 선택 해주세요.'}
      />
    );
  }, [invoice_account_set, search_text]);

  const onInputChange = (e) => {
    setKit({ ...kit, [e.target.name]: e.target.value });
  };

  const onKitPriceInputChange = (e) => {
    let value = e.target.value;
    // 면세가는 제휴사 공급가를 넘길 수 없다.
    const max_value = Math.max(
      Number(kit.kit_price_info_set[0].our_sale_price),
      Number(kit.kit_price_info_set[0].invoice_price),
    );

    if (e.target.name === 'tax_free_price') {
      if (Number(value) > max_value) {
        value = max_value;
      }
    } else if (e.target.name === 'invoice_price') {
      if (kit.kit_price_info_set[0].provide_price > kit.kit_price_info_set[0].our_sale_price) {
        if (Number(value) > Number(kit.kit_price_info_set[0].provide_price)) return false;
      } else {
        if (Number(value) > Number(kit.kit_price_info_set[0].our_sale_price)) return false;
      }
    }

    setKit((prevState) => ({
      ...prevState,
      kit_price_info_set: [{ ...prevState['kit_price_info_set'][0], [e.target.name]: value }],
    }));
  };

  const onInputSubmit = (e) => {
    const err_msg = errMessageOccur(kit, obj_err_msg, { use: false });

    if (!kit.kit_price_info_set[0]?.currency_code_id) {
      err_msg.push("'화폐종류'");
    }
    if (kit.kit_price_info_set[0]?.provide_price === '') {
      err_msg.push("'제휴사공급가'");
    }
    if (kit.kit_price_info_set[0]?.our_sale_price === '') {
      err_msg.push("'판매가'");
    }
    if (kit.kit_price_info_set[0]?.tax_free_price === '') {
      err_msg.push("'면세가'");
    }
    if (kit.kit_price_info_set[0]?.invoice_price === '') {
      err_msg.push("'정산가'");
    }
    if (err_msg.length) {
      err_msg.push('입력하세요.');
    }

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      setKit((prevState) => ({
        ...prevState,
      }));

      if (kit.id) {
        ProductModel.putKitDetail({
          ...kit,
          kit_tag_set,
        }).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        ProductModel.postKit({
          ...kit,
          kit_tag_set,
        }).then(({ kit_id }) => {
          props.history.replace('/kit/' + kit_id);
          window.location.reload();
        });
      }
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const requestDetail = () => {
    ProductModel.getKitDetail(Number(props.match.params.kit_id)).then(({ kit }) => {
      if (kit) {
        const { tag_set, ...kit_without_tag } = kit;
        setKit(kit_without_tag);
        setKitTagSet(tag_set);
        // 키트 태그 추가
        if (kit_without_tag.invoice_account) {
          setSearchText([
            kit_without_tag.invoice_account?.id,
            kit_without_tag.invoice_account?.account_name,
            kit_without_tag.invoice_account?.name,
          ]);
        }
      }
    });
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'currency_code_set' }).then(async ({ code_set }) => {
      await setCurrencyCodeSet(code_set);
      if (!Number(props.match.params.kit_id)) {
        setKit((prevState) => ({
          ...prevState,
          kit_price_info_set: [
            {
              ...prevState['kit_price_info_set'][0],
              currency_code_id: code_set[0]?.id,
            },
          ],
        }));
      }
    });

    CommonModel.staticCode({ code_name: 'shipping_method_set' }).then(async ({ code_set }) => {
      await setShippingMethodSet(code_set);

      if (!Number(props.match.params.kit_id)) {
        setKit((prevState) => ({
          ...prevState,
          shipping_method_id: code_set[0]?.id,
        }));
      }
    });

    CommonModel.getSearch('invoice_account').then((data) => {
      setInvoiceAccountSet(data);
    });

    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent='키트'
        title={`키트 ${props.match.params.kit_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form>
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`키트 ${props.match.params.kit_id === '0' ? '생성' : '정보'}`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            onClick={(e) => {
                              onInputSubmit(e);
                            }}
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      {
                        <>
                          {Number(props.match.params.kit_id) && !isEdit ? (
                            <>
                              <a
                                className='btn btn-pill btn-info mr-2'
                                href={`${process.env.REACT_APP_API_URL}/kit/${Number(
                                  props.match.params.kit_id,
                                )}`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                미리보기
                              </a>
                              <Link
                                className='btn btn-pill btn-light mr-2'
                                to={`/shipping/?search_kit_id=${Number(props.match.params.kit_id)}`}
                              >
                                배송관리
                              </Link>
                            </>
                          ) : (
                            ''
                          )}
                          <Button
                            className='btn-pill'
                            color='primary'
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              // 생성(0)일 경우 수정(0 이상)일 경우
                              if (!!Number(props.match.params.kit_id)) {
                                if (isEdit) {
                                  requestDetail();
                                }
                                setIsEdit(!isEdit);
                                setSearchText([]);
                              } else {
                                window.location.href = '/kit';
                              }
                            }}
                          >
                            {isEdit ? '취소' : '편집'}
                          </Button>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={'3 mb-4'} style={{ minWidth: '360px', alignSelf: 'center' }}>
                      <div className='blog-box text-center' style={{ marginBottom: '10px' }}>
                        <ImageUploader
                          id={Number(props.match.params.kit_id)}
                          name='main_image_url'
                          withPreview={true}
                          img_type={'kit'}
                          thumbnail_width={500}
                          min_width={1200}
                          setPropsState={setKit}
                          img_url={kit.main_image_url}
                          title={kit.title}
                          isEdit={!!Number(props.match.params.kit_id) ? isEdit : false}
                          imgExtension={['.jpg', '.gif', '.png', '.gif', '.svg']}
                          height={230}
                        />
                        <CustomLabel
                          label={'메인 이미지'}
                          tooltip_desc={tooptip_word.main_image_url}
                        />
                      </div>
                      <div className='blog-box text-center'>
                        <ImageUploader
                          id={Number(props.match.params.kit_id)}
                          name='circle_image_url'
                          withPreview={true}
                          img_type={'circle_image_kit'}
                          thumbnail_width={210}
                          min_width={210}
                          setPropsState={setKit}
                          img_url={kit.circle_image_url}
                          title={kit.title}
                          isEdit={!!Number(props.match.params.kit_id) ? isEdit : false}
                          imgExtension={['.jpg', '.gif', '.png', '.gif', '.svg']}
                          height={210}
                        />
                        <CustomLabel
                          label={'동그라미 이미지'}
                          tooltip_desc={tooptip_word.circle_image_url}
                        />
                      </div>
                    </Col>
                    <Col md={'9 mb-4'}>
                      <Row>
                        <Col md='5 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'title'}
                            disabled={!!Number(props.match.params.kit_id) || !isEdit}
                            value={kit.title || ''}
                            is_required={true}
                            label={'키트명'}
                            tooltip_desc={tooptip_word.title}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='7 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'description'}
                            disabled={!!Number(props.match.params.kit_id) || !isEdit}
                            value={kit.description || ''}
                            is_required={true}
                            label={'키트설명'}
                            tooltip_desc={tooptip_word.description}
                            onChange={onInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4 mb-3'>
                          <CustomSelectInput
                            name={'currency_code_id'}
                            disabled={!isEdit}
                            value={kit.kit_price_info_set[0]?.currency_code_id}
                            label={'화폐종류'}
                            is_required={true}
                            sub_option_type={'description'}
                            onChange={onKitPriceInputChange}
                            tooltip_desc={tooptip_word.currency_code_id}
                            options={currency_code_set}
                          />
                        </Col>
                        <Col md='4 mb-3'>
                          <CustomInput
                            type={'number'}
                            name={'inventory_count'}
                            disabled={!isEdit}
                            value={String(kit.inventory_count) || ''}
                            is_required={true}
                            label={'재고 수'}
                            tooltip_desc={tooptip_word.inventory_count}
                            validator={(value) => {
                              return value <= 65535;
                            }}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='4 mb-3'>
                          <CustomInput
                            type={'number'}
                            name={'max_buy_count'}
                            disabled={!isEdit}
                            value={kit.max_buy_count || ''}
                            is_required={true}
                            label={'최대구매가능갯수'}
                            tooltip_desc={tooptip_word.max_buy_count}
                            onChange={onInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'number'}
                            name={'our_sale_price'}
                            disabled={!isEdit}
                            value={String(kit.kit_price_info_set[0]?.our_sale_price) || ''}
                            is_required={true}
                            label={'판매가'}
                            tooltip_desc={tooptip_word.our_sale_price}
                            onChange={onKitPriceInputChange}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'number'}
                            name={'provide_price'}
                            disabled={!isEdit}
                            value={String(kit.kit_price_info_set[0]?.provide_price) || ''}
                            is_required={true}
                            label={'제휴사공급가'}
                            tooltip_desc={tooptip_word.provide_price}
                            onChange={onKitPriceInputChange}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'number'}
                            name={'tax_free_price'}
                            disabled={!isEdit}
                            value={String(kit.kit_price_info_set[0]?.tax_free_price) || ''}
                            is_required={true}
                            label={'면세가'}
                            tooltip_desc={tooptip_word.tax_free_price}
                            onChange={onKitPriceInputChange}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'number'}
                            name={'invoice_price'}
                            disabled={!isEdit}
                            value={String(kit.kit_price_info_set[0]?.invoice_price) || ''}
                            is_required={true}
                            placeholder={'판매가 or 제휴사공급가 이하로 입력해주세요.'}
                            label={'결합상품용 차감가'}
                            tooltip_desc={tooptip_word.invoice_price}
                            onChange={onKitPriceInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'is_external_kit'}
                            disabled={!isEdit}
                            value={Number(kit.is_external_kit)}
                            label={'외부키트 여부'}
                            is_required={true}
                            onChange={onInputChange}
                            tooltip_desc={tooptip_word.is_external_kit}
                            options={[
                              {
                                id: 0,
                                name: '아니요',
                              },
                              {
                                id: 1,
                                name: '예',
                              },
                            ]}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'is_private_mode'}
                            disabled={!isEdit}
                            value={Number(kit.is_private_mode)}
                            label={'비공개 모드'}
                            is_required={true}
                            onChange={onInputChange}
                            tooltip_desc={tooptip_word.is_private_mode}
                            options={[
                              {
                                id: 0,
                                name: '아니요',
                              },
                              {
                                id: 1,
                                name: '예',
                              },
                            ]}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'is_default_option'}
                            disabled={!isEdit}
                            value={Number(kit.is_default_option)}
                            label={'기본값여부'}
                            is_required={true}
                            onChange={onInputChange}
                            tooltip_desc={tooptip_word.is_default_option}
                            options={[
                              {
                                id: 0,
                                name: '아니요',
                              },
                              {
                                id: 1,
                                name: '예',
                              },
                            ]}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'can_discount'}
                            disabled={true}
                            value={Number(kit.can_discount)}
                            label={'할인가능여부'}
                            onChange={onInputChange}
                            tooltip_desc={tooptip_word.can_discount}
                            options={[
                              {
                                id: 0,
                                name: '아니요',
                              },
                              {
                                id: 1,
                                name: '예',
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4 mb-3'>
                          {isEdit ? (
                            MemoCustomTypeahead
                          ) : (
                            <>
                              <CustomLabel label={'판매금액 정산받을 계정'} />
                              {search_text.length ? (
                                <a
                                  className='form-control text-primary'
                                  target='_blank'
                                  rel='noreferrer'
                                  href={'/account/' + search_text[0] || ''}
                                >
                                  [회원] {search_text[1] + ', ' + search_text[2] || ''}
                                </a>
                              ) : (
                                <Input disabled={true} />
                              )}
                            </>
                          )}
                        </Col>
                        <Col md='4 mb-3'>
                          <CustomSelectInput
                            name={'shipping_method_id'}
                            disabled={!isEdit}
                            value={kit.shipping_method_id}
                            label={'배송방법'}
                            is_required={true}
                            onChange={onInputChange}
                            sub_option_type={'description'}
                            tooltip_desc={tooptip_word.shipping_method_id}
                            options={shipping_method_set}
                          />
                        </Col>
                        <Col md='4 mb-3'>
                          <CustomSelectInput
                            name={'show_in_application'}
                            disabled={!isEdit}
                            value={Number(kit.show_in_application)}
                            label={'서비스내표시여부'}
                            tooltip_desc={tooptip_word.show_in_application}
                            onChange={onInputChange}
                            is_required={true}
                            options={[
                              { id: 0, name: '하지않음' },
                              { id: 1, name: '서비스내 표시 될수있음' },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <Label>태그 설명</Label>
                          <CustomTagInput
                            tag_set={kit_tag_set || []}
                            setTagSet={setKitTagSet}
                            placeholder={'키트 태그'}
                            max_length={6}
                            disabled={!!Number(props.match.params.kit_id) ? !isEdit : true}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12 mb-3'>
                      <CustomLabel
                        label={'(키트 상세페이지) 콘텐츠영역 작성'}
                        tooltip_desc={tooptip_word.content_html}
                        is_required={true}
                      />
                      <div className='editor-img-100'>
                        <TextEditor
                          id={Number(props.match.params.kit_id)}
                          html_data={kit.content_html || ''}
                          onChange={(value) => {
                            setKit((prevState) => ({
                              ...prevState,
                              content_html: value,
                            }));
                          }}
                          disabled={!!Number(props.match.params.kit_id) ? !isEdit : true}
                          img_type={'kit'}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default KitDetail;
