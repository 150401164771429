import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Form, Row } from 'reactstrap';
import { COMPANY_STATUS_SELECT_OPTIONS } from '../../../constant/options';
import CustomSelect2 from '../../_common/component/CustomSelect2';
import CustomInput2 from '../../_common/component/CustomInput2';
import CustomTypeahead2 from '../../_common/component/CustomTypeahead2';
import EditButton from '../../_common/component/EditButton';
import { useForm } from 'react-hook-form';
import CompanyModel from '../../../model/CompanyModel';
import { useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import { lang } from '../../../lang';
import {
  BROADCAST_ON_FORM_SUBMIT,
  useApplicationContext,
} from '../../../context/ApplicationContextProvider';
import Loading from '../../_common/Loading';
import { useSelector } from 'react-redux';

/*
    props
    company_info: {}                기업정보
    setNeedUpdate: setState(bool)   업데이트 필요시에 -> setNeedUpdate(true)
 */
const CompanyInfo = (props) => {
  const AuthStore = useSelector((state) => state.AuthStore);
  const { setValue, register } = useForm();
  const { showAlert } = useShowDialog();
  const { broadcast } = useApplicationContext();
  const { params } = useParameters();

  // state
  const [isLoading, setIsLoading] = useState(true);
  const [manager_account_set, setManagerAccountSet] = useState([]);

  // ref
  const company_id = useRef(params.company_id);
  const licensed_employee_count = useRef();

  useEffect(() => {
    if (props.company_info) {
      setManagerAccountSet(props.company_info.manager_account_set);
      licensed_employee_count.current = props.company_info.licensed_employee_count;

      setIsLoading(false);
    }
  }, [props.company_info]);

  const ShowStateSelect = () => {
    return (
      <CustomSelect2
        label_title='가입/상태'
        className='form-control custom-select'
        name='status_id'
        defaultValue={props.company_info && props.company_info.status_id}
        setValue={setValue}
        options={COMPANY_STATUS_SELECT_OPTIONS}
        with_edit_button={true}
        read_only={true}
      />
    );
  };

  const onSubmitUpdateCompanyInfo = async () => {
    // 변경사항 없음
    if (licensed_employee_count.current === props.company_info.licensed_employee_count) {
      showAlert({
        title: lang.common.fail_change_message,
      });

      // 사용 인원을 한명 이하로 설정
    } else if (licensed_employee_count.current < 1) {
      showAlert({
        title: lang.company.need_fill_company_maximum_employee_count,
      });
    } else {
      // 기업정보 업데이트 API
      const update_company_res = await CompanyModel.updateCompanyInfo(company_id.current, {
        manager_account_name_set: manager_account_set.current,
        licensed_employee_count: licensed_employee_count.current,
      });

      if (update_company_res.code === 200) {
        showAlert({
          title: lang.common.success_change_message,
          icon: 'success',
        });

        broadcast.send(BROADCAST_ON_FORM_SUBMIT, {
          is_editable: false,
          disable_input: true,
        });

        props.setNeedUpdate(true);
      } else {
        showAlert({
          title: lang.request.request_error_title,
          text: update_company_res.message,
        });
      }
    }
  };

  return (
    <Card>
      <CardHeader
        className='f-20 f-w-700'
        style={{
          padding: '20px 20px 20px 30px',
        }}
      >
        <Row>
          <Col className='col-8'>
            <span>기업 정보</span>
            {isLoading && <Loading isLoading={isLoading} />}
          </Col>
          {AuthStore.account_type_id === 5 && (
            <Col className='col text-right'>
              <EditButton
                onSubmit={() => {
                  onSubmitUpdateCompanyInfo();
                }}
              />
            </Col>
          )}
        </Row>
      </CardHeader>
      <CardBody
        style={{
          padding: '30px',
        }}
      >
        <Form className='theme-form'>
          <div className='form-row'>
            {!isLoading && (
              <>
                <Col>
                  <ShowStateSelect />
                </Col>
                <Col md='2'>
                  <CustomInput2
                    label_title='기업명'
                    is_require={true}
                    name='company_name'
                    defaultValue={props.company_info.name}
                    setValue={setValue}
                    with_edit_button={true}
                    read_only={true}
                  />
                </Col>
                <Col md='7'>
                  <CustomTypeahead2
                    label_title='기업관리자(이메일)'
                    is_require={true}
                    options={[]}
                    defaultSelected={manager_account_set}
                    labelKey={'account_name'}
                    multiple={true}
                    need_edit_button={true}
                    edit_button_title={'관리자계정 편집하기'}
                    disabled={true}
                    company_info={props.company_info}
                  />
                </Col>
                <Col>
                  <CustomInput2
                    label_title='사용인원'
                    is_require={true}
                    type='number'
                    name='licensed_employee_count'
                    defaultValue={props.company_info.licensed_employee_count}
                    setValue={setValue}
                    with_edit_button={true}
                    on_change={(e) =>
                      (licensed_employee_count.current = Number(e.currentTarget.value))
                    }
                    innerRef={register({
                      required: true,
                    })}
                  />
                </Col>
              </>
            )}
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default CompanyInfo;
