import React, { Fragment, useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  FormGroup,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import Form from 'reactstrap/es/Form';
import SweetAlert from 'sweetalert2';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CouponModel from '../../model/CouponModel';
import Loading from '../_common/Loading';
import CommonModel from '../../model/CommonModel';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import CustomTypeahead from '../_common/component/CustomTypeahead';
import { errMessageOccur } from '../../util/common';
import CustomLabel from '../_common/component/CustomLabel';
import TextEditor from '../_common/component/TextEditor';

const objErrMsg = {
  title: '쿠폰명',
  coupon_issue: '발행개수',
  can_unlimited_use: '일회성여부',
  expire_time: '쿠폰만료일',
  can_use_at_extend_course: '강의연장유무',
  currency_code_id: '화폐종류',
  discounts: '할인금액',
};

const emailErrMsg = {
  email_title: '메일제목',
  email_body: '메일내용',
  email_address: '발송할이메일들',
};

const CouponSend = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const isEdit = useState(!Number(props.match.params.coupon_id))[0];
  const [isLoading, setIsLoading] = useState(false);

  const [currencyCodeSet, SetCurrencyCodeSet] = useState([]);

  // 선택 정보 가지고 있기(get_or_create), 삭제한 정보 가지고 있기(delete)
  const [courseSelect, setCourseSelect] = useState([]);
  const [couponAllowProductTypeSelect, setCouponAllowProductTypeSelect] = useState([]);
  const [productSelect, setProductSelect] = useState([]);
  const [onlyBearuCourseSet, setOnlyBearuCourseSet] = useState([]);
  const [courseSet, setCourseSet] = useState([]);
  const [productTypeSet, setProductTypeSet] = useState([]);
  const [productSet, setProductSet] = useState([]);

  const [mailCustomTemplateSet, setMailCustomTemplateSet] = useState([]);

  const [coupon, setCoupon] = useState({
    id: '',
    title: '',
    description: '',
    management_description: '',
    price_display_name: '',
    discounts: '',
    coupon_issue: '',
    coupon_count: 1,
    currency_code_id: '',
    can_unlimited_use: 0,
    can_use_at_extend_course: 1,
    create_time: '',
    expire_time: '',
    coupon_course_set: [],
    coupon_product_set: [],
    coupon_allow_product_type_set: [],
  });

  const [email, setEmail] = useState({
    email_title: '',
    email_body: '',
    email_address: '',
  });

  const [log, setLog] = useState('');

  const requestEmailTemplate = () => {
    CommonModel.getSearch('mail_custom_template').then((data) => {
      data.forEach((obj) => {
        obj['name'] = `${JSON.parse(obj.value)?.title}`;
        obj['html'] = `${JSON.parse(obj.value)?.html}`;
      });
      setMailCustomTemplateSet([{ id: 0, name: '선택안함 (비우기)', value: '' }, ...data]);
    });
  };

  const onCouponInputChange = (e) => {
    setCoupon({ ...coupon, [e.target.name]: e.target.value });
  };

  const onEmailInputChange = (e) => {
    setEmail({ ...email, [e.target.name]: e.target.value });
  };

  const onMailCustomTemplateSelect = (selectedIndex, selectedValue) => {
    if (selectedIndex) {
      const { title, html } = JSON.parse(mailCustomTemplateSet[selectedIndex].value);
      setEmail((prevState) => ({
        ...prevState,
        email_template_id: Number(selectedValue),
        email_title: title,
        email_body: html,
      }));
    } else {
      setEmail((prevState) => ({
        ...prevState,
        email_template_id: 0,
        email_title: '',
        email_body: '',
      }));
    }
  };

  const onMailCustomTemplateChange = (e) => {
    const {
      target: { selectedIndex, value: selectedValue },
    } = e;

    if (email.email_title || email.email_body) {
      SweetAlert.fire({
        title: '기존에 작성한 내용이 존재합니다. 덮어쓰겠습니까?',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      }).then((result) => {
        if (result.value) {
          onMailCustomTemplateSelect(selectedIndex, selectedValue);
        }
      });
    } else {
      onMailCustomTemplateSelect(selectedIndex, selectedValue);
    }
  };

  const onMailCustomTemplateSave = async () => {
    const errMsg = errMessageOccur(email, { email_title: '메일제목', email_body: '메일내용' });

    if (!errMsg.length) {
      SweetAlert.fire({
        title: '아래 내용과 같이 메일 정보를 저장하시겠습니까?',
        html:
          "<table style='margin: auto' border='1'>" +
          "<tr><td class='px-2'>메일제목</td><td class='px-2'>" +
          email.email_title +
          '</td></tr>' +
          "<tr><td class='px-2'>메일내용</td><td class='px-2'>" +
          email.email_body +
          '</td></tr>' +
          '</table>',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.value) {
          // email_title 기준으로 중복이 있는지 확인
          let isExists = false;
          let mailCustomTemplateId = 0;

          mailCustomTemplateSet.forEach((obj) => {
            if (obj.name === email.email_title) {
              isExists = true;
              mailCustomTemplateId = obj.id;
              return false;
            }
          });

          if (isExists) {
            await SweetAlert.fire({
              title: '기존 메일 템플릿이 존재합니다. 덮어쓰시겠습니까?',
              showCancelButton: true,
              confirmButtonText: '확인',
              cancelButtonText: '취소',
            }).then(async (result) => {
              if (result.value) {
                // 업데이트 하기
                await CommonModel.putMailCustomTemplateDetail({
                  id: mailCustomTemplateId,
                  email_json: JSON.stringify({ title: email.email_title, html: email.email_body }),
                });
              }
            });
          } else {
            // 작성하기
            await CommonModel.postMailCustomTemplate({
              email_json: JSON.stringify({ title: email.email_title, html: email.email_body }),
            });
          }
          await requestEmailTemplate();
        }
      });
    } else {
      await SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: errMsg.join('\n'),
      });
    }
  };

  const onMailCustomTemplateDelete = async () => {
    if (email.email_template_id) {
      await SweetAlert.fire({
        title: '현재템플릿을 정말 삭제하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.value) {
          // 삭제하기
          await CommonModel.deleteMailCustomTemplateDetail(email.email_template_id).then(
            async () => {
              await setEmail((prevState) => ({
                ...prevState,
                email_template_id: 0,
                email_title: '',
                email_body: '',
              }));

              await requestEmailTemplate();
            },
          );
        }
      });
    }
  };

  const onExpireDateTimeChange = (e) => {
    const date = moment(e);
    if (date.year() < 10000) {
      setCoupon({ ...coupon, expire_time: e && date.format('YYYY-MM-DDTHH:mm:ss') });
    }
  };

  const onExprieDateTimeSelect = (e) => {
    const {
      target: { value },
    } = e;

    let expire_time = moment();

    if (value === '0') {
      expire_time = '';
    } else if (value === '1') {
      expire_time = expire_time.add(3, 'months');
    } else if (value === '2') {
      expire_time = expire_time.add(6, 'months');
    } else if (value === '3') {
      expire_time = expire_time.add(12, 'months');
    }

    setCoupon({
      ...coupon,
      expire_time: expire_time && expire_time.format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  const onInputSubmit = async (e) => {
    e.preventDefault();

    let errMsg = errMessageOccur(coupon, objErrMsg, { use: false });

    const errMsg2 = errMessageOccur(email, emailErrMsg, { use: false });

    errMsg = errMsg.concat(errMsg2);
    if (errMsg.length) {
      errMsg.push('입력해주세요.');
    }

    if (!errMsg.length) {
      const resultCourseSelect = courseSelect.map((e) => e.id);
      const resultProductSelect = productSelect.map((e) => e.id);
      const resultCouponAllowProductTypeSelect = couponAllowProductTypeSelect
        .filter((e) => e.id !== 4)
        .map((e) => e.id);

      setIsLoading(true);
      e.target.disabled = true;

      const email_address_set = email.email_address.replace(/[\r\n\t\s]/gi, '').split(',');
      const err_email = [];

      // 이메일 유효한지 체크
      const regex = /^[^\s@]+@[^\s@]+$/;

      email_address_set.forEach((email_address) => {
        if (!regex.test(email_address)) {
          err_email.push(email_address);
        }
      });

      if (err_email.length) {
        await SweetAlert.fire({
          title: '입력하신 이메일 방식을 확인하세요.',
          text: `[문제 이메일]\n${err_email.join('\n')}`,
          confirmButtonText: '확인',
        });
      } else {
        SweetAlert.fire({
          title: '아래 내용과 같이 발송하시겠습니까?',
          html:
            "<table style='margin: auto' border='1'>" +
            "<tr><td class='px-2'>쿠폰명</td><td class='px-2'>" +
            coupon.title +
            '</td></tr>' +
            "<tr><td class='px-2'>설명</td><td class='px-2'>" +
            coupon.description +
            '</td></tr>' +
            "<tr><td class='px-2'>가격표시대체 문자열</td><td class='px-2'>" +
            coupon.price_display_name +
            '</td></tr>' +
            "<tr><td class='px-2'>화폐종류</td><td class='px-2'>" +
            coupon.currency_code_id +
            '</td></tr>' +
            "<tr><td class='px-2'>할인금액</td><td class='px-2'>" +
            coupon.discounts +
            '</td></tr>' +
            "<tr><td class='px-2'>일회성여부</td><td class='px-2'>" +
            coupon.can_unlimited_use +
            '</td></tr>' +
            "<tr><td class='px-2'>쿠폰만료일</td><td class='px-2'>" +
            moment(coupon.expire_time).format('YYYY-MM-DD HH:mm:ss') +
            '</td></tr>' +
            "<tr><td class='px-2'>메일제목</td><td class='px-2'>" +
            email.email_title +
            '</td></tr>' +
            "<tr><td class='px-2'>발송자수</td><td class='px-2'>" +
            email_address_set.length +
            '</td></tr>' +
            "<tr><td class='px-2'>발송자</td><td class='px-2'>" +
            email_address_set.join('\n') +
            '</td></tr>' +
            '</table>',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
        }).then(async (result) => {
          if (result.value) {
            setLog(`단체메일 전송 시작! 총 ${email_address_set.length} 건`);

            for (const [index, email_address] of email_address_set.entries()) {
              await CouponModel.sendCoupon({
                ...coupon,
                ...email,
                email_address: email_address,
                result_course_select: resultCourseSelect,
                result_product_select: resultProductSelect,
                result_coupon_allow_product_type_select: resultCouponAllowProductTypeSelect,
              }).then(({ coupon_text_set, email_response }) => {
                setLog(
                  (prevState) =>
                    prevState +
                    `\n--> (${email_address}) 메일 ${
                      !!email_response ? '전송 완료!' : '전송 실패... 😥😥'
                    } (남은 갯수 : ${email_address_set.length - (index + 1)}건)`,
                );

                !!email_response &&
                  SweetAlert.fire({
                    title: `${log} 발급 완료!`,
                    html:
                      "<table style='margin: auto' border='1'>" +
                      "<tr><td class='px-2'>쿠폰명</td><td class='px-2'>" +
                      coupon.title +
                      '</td></tr>' +
                      "<tr><td class='px-2'>설명</td><td class='px-2'>" +
                      coupon.description +
                      '</td></tr>' +
                      "<tr><td class='px-2'>가격표시대체 문자열</td><td class='px-2'>" +
                      coupon.price_display_name +
                      '</td></tr>' +
                      "<tr><td class='px-2'>화폐종류</td><td class='px-2'>" +
                      coupon.currency_code_id +
                      '</td></tr>' +
                      "<tr><td class='px-2'>할인금액</td><td class='px-2'>" +
                      coupon.discounts +
                      '</td></tr>' +
                      "<tr><td class='px-2'>일회성여부</td><td class='px-2'>" +
                      coupon.can_unlimited_use +
                      '</td></tr>' +
                      "<tr><td class='px-2'>쿠폰만료일</td><td class='px-2'>" +
                      moment(coupon.expire_time).format('YYYY-MM-DD HH:mm:ss') +
                      '</td></tr>' +
                      "<tr><td class='px-2'>메일제목</td><td class='px-2'>" +
                      email.email_title +
                      '</td></tr>' +
                      "<tr><td class='px-2'>발송자수</td><td class='px-2'>" +
                      email_address_set.length +
                      '</td></tr>' +
                      "<tr><td class='px-2'>발송자</td><td class='px-2'>" +
                      email_address_set.join('\n') +
                      '</td></tr>' +
                      '</table>',
                  });
              });
            }

            setLog((prevState) => prevState + `\n\n전송 완료!`);
          }
        });
      }
      e.target.disabled = false;
      setIsLoading(false);
    } else {
      await SweetAlert.fire({
        title: '발송중 문제를 발견했습니다.',
        text: errMsg.join('\n'),
      });
    }
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'currency_code_set' }).then(({ code_set }) => {
      SetCurrencyCodeSet(code_set);

      if (!Number(props.match.params.coupon_id)) {
        setCoupon({ ...coupon, currency_code_id: code_set[0]?.id });
      }
    });

    CommonModel.getSearch('accessible_course_price_displaying').then((data) => {
      const new_data = data.map(({ course_id: id, combine_name: title }) => {
        return { id, title };
      });
      setCourseSet(new_data);
    });

    CommonModel.staticCode({ code_name: 'product_type_set' }).then(({ code_set }) => {
      setProductTypeSet([...code_set]);
    });

    CommonModel.getSearch('only_bearu_course').then((data) => {
      const new_data = data.map(({ id: id, combine_name: title }) => {
        return { id, title };
      });
      setOnlyBearuCourseSet(new_data);
    });

    CommonModel.getSearch('cms_accessible_product').then((data) => {
      setProductSet(data);
    });

    requestEmailTemplate();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent='쿠폰' title={`대량 쿠폰 발송`} />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form onSubmit={onInputSubmit}>
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`대량 쿠폰 발송`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{
                              marginRight: '8px',
                            }}
                          >
                            발송
                          </Button>
                        </>
                      )}
                      <Button
                        className='btn-pill'
                        color='primary'
                        style={{ marginRight: '8px' }}
                        onClick={() => {
                          // 생성(0)일 경우 수정(0 이상)일 경우
                          props.history.push('/coupon');
                        }}
                      >
                        {isEdit ? '취소' : '편집'}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={7} style={{ borderRight: '2px solid #F5F5F5' }}>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'title'}
                            disabled={!isEdit}
                            style={{
                              resize: 'none',
                            }}
                            value={coupon.title || ''}
                            is_required={true}
                            label={'쿠폰명'}
                            onChange={onCouponInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'description'}
                            disabled={!isEdit}
                            value={coupon.description || ''}
                            label={'설명'}
                            onChange={onCouponInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'management_description'}
                            disabled={!isEdit}
                            value={coupon.management_description || ''}
                            label={'관리용 설명'}
                            onChange={onCouponInputChange}
                            validator={(value) => {
                              return value.length <= 12;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12'>
                          <CustomLabel label={'쿠폰텍스트'} />
                          <FormGroup>
                            <InputGroup>
                              <Input
                                type={'text'}
                                name={'coupon_text'}
                                disabled={true}
                                value={''}
                                placeholder={
                                  '[자동으로 생성됩니다] 쿠폰텍스트는 중복 될 수 없습니다. (최대 36자)'
                                }
                              />
                              <InputGroupAddon addonType='append' style={{ cursor: 'pointer' }}>
                                <InputGroupText>
                                  <i className='icofont icofont-random mr-2' /> 생성
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'currency_code_id'}
                            disabled={!!Number(props.match.params.coupon_id)}
                            value={coupon.currency_code_id || ''}
                            label={'화폐종류'}
                            is_required={true}
                            onChange={onCouponInputChange}
                            options={currencyCodeSet}
                            sub_option_type={'description'}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'discounts'}
                            disabled={!!Number(props.match.params.coupon_id)}
                            value={coupon.discounts || ''}
                            label={'할인금액'}
                            is_required={true}
                            onChange={onCouponInputChange}
                            validator={(value) => {
                              const regexp = /^[0-9\b]*$/;
                              return regexp.test(value);
                            }}
                          />
                        </Col>
                        <Col md='6 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'price_display_name'}
                            disabled={!isEdit}
                            value={coupon.price_display_name || ''}
                            label={'가격표시 대체 문자열'}
                            tooltip_desc={
                              '입력하는 경우 서비스내 할인금액 표시부분에서 할인금액 대신 이 문자열로 표시됩니다.\n고객은 구체적인 할인금액 내용을 인지 할 수 없게 됩니다.'
                            }
                            onChange={onCouponInputChange}
                            placeholder={'입력하게되면 할인 금액 대신 이 문자열을 표시합니다'}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md='12' className={'text-right'}>
                          <button
                            type='button'
                            className={'btn btn-danger btn-sm btn-pill mr-2'}
                            onClick={onMailCustomTemplateDelete}
                          >
                            현재템플릿삭제
                          </button>
                          <button
                            type='button'
                            className={'btn btn-primary btn-sm btn-pill'}
                            onClick={onMailCustomTemplateSave}
                          >
                            템플릿으로저장
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomSelectInput
                            name={'currency_code_id'}
                            disabled={!!Number(props.match.params.coupon_id)}
                            value={email.email_template_id}
                            label={'메일템플릿'}
                            onChange={onMailCustomTemplateChange}
                            options={mailCustomTemplateSet}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'textarea'}
                            name={'email_address'}
                            disabled={!isEdit}
                            value={email.email_address || ''}
                            label={'발송할 이메일들'}
                            is_required={true}
                            onChange={onEmailInputChange}
                            placeholder={'콤마(,)로 구분'}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'email_title'}
                            disabled={!isEdit}
                            value={email.email_title || ''}
                            label={'메일제목'}
                            is_required={true}
                            onChange={onEmailInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomLabel label={'메일내용'} is_required={true} />
                          <TextEditor
                            html_data={email.email_body || ''}
                            onChange={(value) => {
                              setEmail((prevState) => ({
                                ...prevState,
                                email_body: value,
                              }));
                            }}
                            img_type={'email'}
                            disabled={!isEdit}
                            items={[
                              'heading',
                              '|',
                              'bold',
                              'italic',
                              'Underline',
                              'Strikethrough',
                              '|',
                              'FontSize',
                              'FontColor',
                              'FontBackgroundColor',
                              '|',
                              'imageUpload',
                              'link',
                              '|',
                              'blockQuote',
                              'bulletedList',
                              'numberedList',
                              'alignment',
                              '|',
                              'undo',
                              'redo',
                            ]}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <Col md='mb-3 ml-3'>
                          <CustomLabel label={'쿠폰만료일'} is_required={true} />
                          <DatePicker
                            autoComplete='off'
                            className='form-control digits'
                            name='expire_time'
                            disabled={!!Number(props.match.params.coupon_id)}
                            onChange={onExpireDateTimeChange}
                            locale='ko'
                            selected={coupon.expire_time ? new Date(coupon.expire_time) : null}
                            timeFormat='HH:mm:ss'
                            showTimeSelect
                            dateFormat='yyyy-MM-dd HH:mm:ss'
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'date_range'}
                            disabled={!!Number(props.match.params.coupon_id)}
                            label={'날짜선택'}
                            onChange={onExprieDateTimeSelect}
                            options={[
                              { id: 0, name: '------' },
                              { id: 1, name: '3개월' },
                              { id: 2, name: '6개월' },
                              { id: 3, name: '1년' },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'number'}
                            name={'coupon_count'}
                            disabled={true}
                            value={1}
                            is_required={true}
                            label={'사용가능횟수'}
                            tooltip_desc={
                              '해당 쿠폰으로 임의의 유저들이 얼마나 사용 가능한지에 대한 횟수\n(예를들어 10인경우는, 해당 쿠폰텍스트로 10명의 유저가 구매 가능함, 1인 경우는 1명만 구매가 가능함).'
                            }
                          />
                        </Col>
                        <Col md='4 mb-3'>
                          <CustomSelectInput
                            name={'can_unlimited_use'}
                            disabled={true}
                            value={coupon.can_unlimited_use || ''}
                            label={'일회성여부'}
                            is_required={true}
                            tooltip_desc={
                              '발급받은 쿠폰으로 유저가 여러번 사용할 수 있는지 여부\n(예를들어 일회성인 경우 구매시에 사용자가 단 한번만 사용할 수 있음, 제한없음인 경우 사용자가 동일한 쿠폰을 여러번  사용할 수 있음).'
                            }
                            onChange={onCouponInputChange}
                            options={[
                              { id: '0', name: '일회성' },
                              { id: '1', name: '제한없음' },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='8 mb-3'>
                          <CustomTypeahead
                            disabled={!!Number(props.match.params.coupon_id)}
                            multiple={true}
                            label={'사용가능처 지정'}
                            tooltip_desc={
                              '사용가능처를 지정하지 않으면 모든 상품 구매에 이 쿠폰이 사용 가능하게 됩니다.\n사용가능처를 지정하면 특정 상품 구매에만 사용 가능하도록 설정됩니다.\n' +
                              '본 설정은 지정한 "대상 강의들"(또는 이벤트 해당강의 등) 옵션보다 먼저 사용됩니다.'
                            }
                            onChange={(e) => {
                              // 추가한 정보 알아오기...
                              let selected;

                              // 차집합
                              if (couponAllowProductTypeSelect.length < e.length) {
                                selected = e.filter(
                                  (value) =>
                                    !couponAllowProductTypeSelect
                                      .map((val) => val.id)
                                      .includes(value.id),
                                )[0];
                              }

                              setCourseSelect([]);
                              setProductSelect([]);

                              if (
                                e.some((el) => el.name === '커스텀선택') &&
                                e.some((el) => el.name === '베어유 강의')
                              ) {
                                const customIndex = e.findIndex(
                                  (item) => item.name === '커스텀선택',
                                );
                                const bearLectureIndex = e.findIndex(
                                  (item) => item.name === '베어유 강의',
                                );
                                const biggerIndex = Math.max(customIndex, bearLectureIndex);
                                const result = [e[biggerIndex]];
                                setCouponAllowProductTypeSelect(result);
                                if (e[biggerIndex].name === '베어유 강의') {
                                  setCourseSelect(onlyBearuCourseSet);
                                }
                              } else {
                                if (
                                  e.some((el) => el.name === '커스텀선택') ||
                                  e.some((el) => el.name === '베어유 강의')
                                ) {
                                  setCouponAllowProductTypeSelect([selected]);
                                  if (selected.name === '베어유 강의') {
                                    setCourseSelect(onlyBearuCourseSet);
                                  }
                                }
                              }
                            }}
                            selected={couponAllowProductTypeSelect}
                            selectedHandler={setCouponAllowProductTypeSelect}
                            labelKey={'name'}
                            customToken={(target) => {
                              return `${target.name} : ${target.description}`;
                            }}
                            options={productTypeSet}
                          />
                        </Col>
                        <Col>
                          <CustomSelectInput
                            name={'can_use_at_extend_course'}
                            disabled={!isEdit}
                            value={coupon.can_use_at_extend_course || ''}
                            label={'강의연장유무'}
                            tooltip_desc={"'강의 연장하기'에서 해당 쿠폰을 사용 못하도록 설정"}
                            is_required={true}
                            onChange={onCouponInputChange}
                            options={[
                              { id: 0, name: '불가' },
                              { id: 1, name: '가능' },
                            ]}
                          />
                        </Col>
                      </Row>
                      {['커스텀선택', '베어유 강의'].map((type) => {
                        const isCustomSelect = type === '커스텀선택';
                        const element = couponAllowProductTypeSelect.find(
                          (element) => element?.name === type,
                        );

                        return (
                          element && (
                            <React.Fragment key={type}>
                              {isCustomSelect && (
                                <Row>
                                  <Col md='12 mb-3'>
                                    <CustomTypeahead
                                      disabled={!!Number(props.match.params.coupon_id)}
                                      multiple={true}
                                      label={'대상 패키지들'}
                                      tooltip_desc={
                                        '신규 등록시에만 입력 가능합니다, 수정되지 않으므로 반드시 확인하세요.\n(입력 항목이 없으면 자동으로 모든 패키지를 구매할 수 있도록 설정됩니다.)'
                                      }
                                      selected={productSelect}
                                      selectedHandler={setProductSelect}
                                      onTokenClick={(target) => {
                                        window.open('/product/' + target.id, '_blank');
                                      }}
                                      labelKey={'combine_name'}
                                      customToken={(target) => `${target.combine_name}`}
                                      options={productSet}
                                    />
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                <Col md='12 mb-3'>
                                  <CustomTypeahead
                                    disabled={!!Number(props.match.params.coupon_id)}
                                    multiple={true}
                                    label={'대상 강의들'}
                                    tooltip_desc={
                                      '신규 등록시에만 입력 가능합니다, 수정되지 않으므로 반드시 확인하세요.\n(입력 항목이 없으면 자동으로 모든 강의를 구매할 수 있도록 설정됩니다.)'
                                    }
                                    selected={courseSelect}
                                    selectedHandler={setCourseSelect}
                                    onTokenClick={(target) => {
                                      window.open('/course/' + target.id, '_blank');
                                    }}
                                    labelKey={'title'}
                                    customToken={(target) => `${target.title}`}
                                    options={courseSet}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                        );
                      })}
                      <hr />
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'number'}
                            name={'coupon_issue'}
                            disabled={!isEdit}
                            value={coupon.coupon_issue || ''}
                            is_required={true}
                            label={'발행개수'}
                            tooltip_desc={'메일 당 코드가 발행되는 개수'}
                            onChange={onCouponInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'textarea'}
                            label={'로그'}
                            value={log}
                            disabled={true}
                            style={{ height: 200 }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CouponSend;
