import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';

import { Card, CardHeader, Col, Container, Row, Button, Label } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import ImageUploader from '../_common/component/ImageUploader';

import Form from 'reactstrap/es/Form';
import SweetAlert from 'sweetalert2';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import { errMessageOccur } from '../../util/common';
import {
  BANNER_TYPE_MAIN,
  BANNER_TYPE_MYPAGE,
  BANNER_TYPE_SUB_BANNER,
  BANNER_TYPE_TOP_LINE,
} from '../../constants';
import CustomLabel from '../_common/component/CustomLabel';
import TextEditor from '../_common/component/TextEditor';
import CourseModel from '../../model/CourseModel';
import CustomTypeahead from '../_common/component/CustomTypeahead';

const obj_err_msg = {
  title: "'제목'",
  value: "'값'",
  trigger_event_type: "'이벤트 타입'",
  trigger_match_type: "'매칭 타입'",
  platform_type: "'플랫폼 타입'",
};

const TriggerDetail = (props) => {
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.trigger_id));
  const [isLoading, setIsLoading] = useState(false);
  const [trigger_event_type_set, setTriggerEventTypeSet] = useState([]);
  const [trigger_match_type_set, setTriggerMatchTypeSet] = useState([]);
  const [platform_type_set, setPlatformTypeSet] = useState([]);

  const [banner_select, setBannerSelect] = useState([]);
  const [banner_set, setBannerSet] = useState([]);
  const [trigger, setTrigger] = useState({
    id: '',
    title: '',
    value: '',
    trigger_event_type: 1,
    trigger_match_type: 1,
    platform_type: 1,
  });

  const onInputChange = (e) => {
    setTrigger({ ...trigger, [e.target.name]: e.target.value });
  };

  const onInputSubmit = async (e) => {
    e.preventDefault();

    const banner_set = banner_select.map((e) => e.id);
    const err_msg = errMessageOccur(trigger, obj_err_msg);

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      if (trigger.id) {
        await CommonModel.putTriggerDetail({
          ...trigger,
          banner_set: banner_set,
        }).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        CommonModel.postTrigger({
          ...trigger,
          banner_set: banner_set,
        }).then(({ trigger_id }) => {
          props.history.replace('/trigger/' + trigger_id);
          window.location.reload();
        });
      }
    } else {
      err_msg.push('\n위 항목들의 값이 입력되었는지 다시 한번 확인해주세요.');

      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const requestDetail = () => {
    CommonModel.getTriggerDetail(Number(props.match.params.trigger_id)).then(({ trigger }) => {
      if (trigger) {
        setTrigger(trigger);

        const _banner_select = trigger.banner_set.map((e) => {
          let obj = {};
          obj['id'] = e.id;
          obj['combine_name'] = e.combine_name;
          return obj;
        });
        setBannerSelect(_banner_select);
      }
    });
  };

  useEffect(() => {
    CommonModel.getSearch('cms_pop_up_banner').then((data) => {
      setBannerSet(data);
    });

    CommonModel.staticCode({ code_name: 'trigger_event_type' }).then(({ code_set }) => {
      setTriggerEventTypeSet(code_set);

      if (!Number(props.match.params.trigger_id)) {
        setTrigger({ ...trigger, trigger_event_type_id: code_set[0]?.id });
      }
    });

    CommonModel.staticCode({ code_name: 'trigger_match_type' }).then(({ code_set }) => {
      setTriggerMatchTypeSet(code_set);

      if (!Number(props.match.params.trigger_id)) {
        setTrigger({ ...trigger, trigger_match_type_id: code_set[0]?.id });
      }
    });

    CommonModel.staticCode({ code_name: 'platform_type' }).then(({ code_set }) => {
      setPlatformTypeSet(code_set);

      if (!Number(props.match.params.trigger_id)) {
        setTrigger({ ...trigger, platform_type_id: code_set[0]?.id });
      }
    });

    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent='트리거'
        title={`트리거 ${props.match.params.trigger_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form onSubmit={onInputSubmit}>
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`트리거 ${props.match.params.trigger_id === '0' ? '생성' : '정보'}`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      {
                        <>
                          <Button
                            className='btn-pill'
                            color='primary'
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              // 생성(0)일 경우 수정(0 이상)일 경우
                              if (!!Number(props.match.params.trigger_id)) {
                                if (isEdit) {
                                  requestDetail();
                                }
                                setIsEdit(!isEdit);
                              } else {
                                window.location.href = '/trigger';
                              }
                            }}
                          >
                            {isEdit ? <>취소</> : <>편집</>}
                          </Button>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <Row>
                        <Col md='3 mb-4'>
                          <CustomInput
                            type={'text'}
                            name={'title'}
                            disabled={!isEdit}
                            value={trigger.title || ''}
                            is_required={true}
                            label={'제목'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='3 mb-4'>
                          <CustomInput
                            type={'text'}
                            name={'value'}
                            disabled={!isEdit}
                            value={trigger.value || ''}
                            is_required={true}
                            label={'값'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='3 mb-4'>
                          <CustomSelectInput
                            type={'text'}
                            name={'trigger_event_type'}
                            disabled={!isEdit}
                            value={trigger.trigger_event_type || 1}
                            is_required={true}
                            label={'이벤트 타입'}
                            onChange={onInputChange}
                            options={trigger_event_type_set}
                          />
                        </Col>
                        <Col md='3 mb-4'>
                          <CustomSelectInput
                            type={'text'}
                            name={'trigger_match_type'}
                            disabled={!isEdit}
                            value={trigger.trigger_match_type || 1}
                            is_required={true}
                            label={'매칭 타입'}
                            onChange={onInputChange}
                            options={trigger_match_type_set}
                          />
                        </Col>
                        <Col md='3 mb-4'>
                          <CustomSelectInput
                            type={'text'}
                            name={'platform_type'}
                            disabled={!isEdit}
                            value={trigger.platform_type || 1}
                            is_required={true}
                            label={'플랫폼 타입'}
                            onChange={onInputChange}
                            options={platform_type_set}
                          />
                        </Col>

                        <Col md='3 mb-3'>
                          <Label>생성일시</Label>
                          <DatePicker
                            className='form-control'
                            dateFormat='yyyy-MM-dd hh:mm:ss'
                            name='create_date'
                            selected={trigger.create_time ? new Date(trigger.create_time) : null}
                            readOnly={true}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <Label>수정일시</Label>
                          <DatePicker
                            className='form-control'
                            dateFormat='yyyy-MM-dd hh:mm:ss'
                            name='update_date'
                            selected={trigger.update_time ? new Date(trigger.update_time) : null}
                            readOnly={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomTypeahead
                            disabled={!isEdit}
                            multiple={true}
                            label={'배너 목록'}
                            tooltip_desc={
                              '해당 트리거에 연결할 배너를 선택하세요.\n' +
                              '⚠️ 가장 최근에 생성한 배너 하나만 노출됩니다.\n' +
                              '⚠️ 비활성화된 배너는 노출시키지 않습니다.'
                            }
                            selected={banner_select}
                            selectedHandler={setBannerSelect}
                            onTokenClick={(target) => {
                              window.open('/banner/' + target.id, '_blank');
                            }}
                            labelKey={'combine_name'}
                            customToken={(target) => {
                              return `${target.combine_name}`;
                            }}
                            options={banner_set}
                            placeholder={'연결할 배너를 선택 해주세요.'}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TriggerDetail;
