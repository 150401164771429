import React, { Fragment, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import PurchaseModel from '../../model/PurchaseModel';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import { initComma, toQueryString } from '../../util/common';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Table,
  Collapse,
  Input,
  Label,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import queryString from 'query-string';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import CustomLabel from '../_common/component/CustomLabel';
import { PAGE_SIZE } from '../../constants';

const Sale = (props) => {
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
    merchant_uid: params.merchant_uid ? params.merchant_uid : '',
    product_name: params.product_name ? params.product_name : '',
    account_info: params.account_info ? params.account_info : '',
    type_filter: params.type_filter ? params.type_filter : '',
    purchase_status_id: params.purchase_status_id ? params.purchase_status_id : '',
    purchase_paymethod_id: params.purchase_paymethod_id ? params.purchase_paymethod_id : '',
    currency_code_id: params.currency_code_id ? params.currency_code_id : '',
    product_price_start: params.product_price_start ? params.product_price_start : '',
    product_price_end: params.product_price_end ? params.product_price_end : '',
    purchased_price_start: params.purchased_price_start ? params.purchased_price_start : '',
    purchased_price_end: params.purchased_price_end ? params.purchased_price_end : '',
    discounted_price_start: params.discounted_price_start ? params.discounted_price_start : '',
    discounted_price_end: params.discounted_price_end ? params.discounted_price_end : '',
    refund_price_start: params.refund_price_start ? params.refund_price_start : '',
    refund_price_end: params.refund_price_end ? params.refund_price_end : '',
    create_time_start: params.create_time_start ? params.create_time_start : null,
    create_time_end: params.create_time_end ? params.create_time_end : null,
    purchased_time_start: params.purchased_time_start ? params.purchased_time_start : null,
    purchased_time_end: params.purchased_time_end ? params.purchased_time_end : null,
    refund_time_start: params.refund_time_start ? params.refund_time_start : null,
    refund_time_end: params.refund_time_end ? params.refund_time_end : null,
    coupon_id: params.coupon_id ? params.coupon_id : '',
    event_id: params.event_id ? params.event_id : '',
  });

  const [sale_set, setSaleSet] = useState([]);
  const [sale_count, setSaleCount] = useState([]);

  const [purchase_status_set, setPurchaseStatusSet] = useState([]);
  const [purchase_paymethod_set, setPurchasePaymethodSet] = useState([]);
  const [currency_code_set, setCurrencyCodeSet] = useState([]);

  const [purchase_status_key_is_id_object, setPurchaseStatusKeyIsIdObject] = useState({});
  const [purchase_paymethod_key_is_id_object, setPurchasePaymethodKeyIsIdObject] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [is_download, setDownload] = useState(false);
  const [is_filer_spread, setIsFilterSpread] = useState(!!params.is_filer_spread);
  const [filter_set, setFilter] = useState(default_filter.current);
  const [paramsTypeFilter, setParamsTypeFilter] = useState([]);

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'purchase_status_set' }).then(({ code_set }) => {
      setPurchaseStatusSet(code_set);

      code_set.forEach((code) => {
        setPurchaseStatusKeyIsIdObject((prevState) => ({ ...prevState, [code.id]: code.name }));
      });
    });

    CommonModel.staticCode({ code_name: 'purchase_paymethod_set' }).then(({ code_set }) => {
      setPurchasePaymethodSet(code_set);

      code_set.forEach((code) => {
        setPurchasePaymethodKeyIsIdObject((prevState) => ({ ...prevState, [code.id]: code.name }));
      });
    });

    CommonModel.staticCode({ code_name: 'currency_code_set' }).then(({ code_set }) => {
      setCurrencyCodeSet(code_set);
    });

    let type_filter_list = [];
    if (params.type_filter !== undefined) {
      setCheckedInputs([...params.type_filter]);

      type_filter_list = [...params.type_filter];
    }

    let name = 'type_filter';
    //필터 추가 제한
    if (type_filter_list.length > 0) {
      for (let i = 0; i <= 6; i++) {
        document.getElementsByName(name)[i].disabled = true;
      }

      for (let i = 0; i < type_filter_list.length; i++) {
        if (type_filter_list[i] <= 4) {
          for (let j = 0; j <= 3; j++) {
            document.getElementsByName(name)[j].disabled = false;
          }
        } else {
          document.getElementsByName(name)[type_filter_list[i] - 1].disabled = false;
        }
      }
    } else {
      for (let i = 0; i <= 6; i++) {
        document.getElementsByName(name)[i].disabled = false;
      }
    }
  }, []);

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    PurchaseModel.getPurchase({
      ...params,
      // paging 초기 값
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ purchaseinfo_set, total_count }) => {
      setSaleSet(purchaseinfo_set);
      setSaleCount(total_count);
      setIsLoading(false);
    });
  }, [props.location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onCSVRequset = async (e) => {
    if (!is_download) {
      e.target.disabled = true;
      setDownload(true);

      const _a = document.createElement('a');
      _a.style = 'display: none';
      const _file = await PurchaseModel.getPurchaseCSV({
        ...params,
      });

      const url = window.URL.createObjectURL(_file.data);
      _a.href = url;
      _a.download = `user-info-${moment(new Date()).format('YYYY-MM-DD')}.csv`;
      _a.click();
      window.URL.revokeObjectURL(url);

      e.target.disabled = false;
      setDownload(false);
    }
  };

  const onFilterSubmit = (e) => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
      is_filer_spread: is_filer_spread,
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      order_key: order_key,
      is_filer_spread: is_filer_spread,
      order_type: !filter_set.order_type || filter_set.order_type === 'asc' ? 'desc' : 'asc', // 정렬을 기존 기준 -> "없을 경우 or asc = desc로 변경" / "desc 일 경우 = asc 로 변경"
    };

    // 이전에 정렬한적 있고, 새로운 정렬을 적용.
    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }

    pushHistory(newFilterSet);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      is_filer_spread: is_filer_spread,
    });
    window.scrollTo(0, 0);
  };

  // 날짜 수정
  const onDateChange = (e, name) => {
    setFilter({ ...filter_set, [name]: e && moment(e).format('YYYY-MM-DD') });
  };

  const onDateClick = (e, start_date_name, end_date_name) => {
    let date_start = null;
    let date_end = null;
    let add_date;

    if (e.target.value !== 'all' && e.target.value !== 'direct') {
      add_date = Number(e.target.value.split('_')[1]);
      date_end = moment().format('YYYY-MM-DD');
    }

    if (e.target.value === 'direct') {
      date_start = filter_set[start_date_name];
      date_end = filter_set[end_date_name];
    }

    if (typeof add_date === 'number') {
      date_start = moment().add(-add_date, 'days').format('YYYY-MM-DD');
    }

    setFilter({
      ...filter_set,
      [start_date_name]: date_start,
      [end_date_name]: date_end,
    });
  };

  const getDateFilterStates = () => {
    const {
      create_time_start,
      create_time_end,
      purchased_time_start,
      purchased_time_end,
      refund_time_start,
      refund_time_end,
    } = filter_set;
    return [
      {
        date_start: { value: create_time_start, name: 'create_time_start' },
        date_end: { value: create_time_end, name: 'create_time_end' },
        label: '결제요청일시 범위',
      },
      {
        date_start: { value: purchased_time_start, name: 'purchased_time_start', label: '' },
        date_end: { value: purchased_time_end, name: 'purchased_time_end', label: '' },
        label: '결제일시 범위',
      },
      {
        date_start: { value: refund_time_start, name: 'refund_time_start', label: '' },
        date_end: { value: refund_time_end, name: 'refund_time_end', label: '' },
        label: '환불일시 범위',
      },
    ];
  };

  const userDataJsonParse = (user_data, key) => {
    let data;

    if (user_data) {
      data = JSON.parse(user_data)[key];
    } else {
      data = null;
    }

    if (key === 'courseId') {
      data = <Link to={`/course/${data}`}>{data}</Link>;
    } else if (key === 'productId') {
      data = <Link to={`/product/${data}`}>{data}</Link>;
    } else if (key === 'courseIds') {
      // 중복 제거
      data = [...new Set(data)];
      data =
        Array.isArray(data) &&
        data.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Link to={`/course/${item}`}>{item}</Link>
              {data.length !== index + 1 && ', '}
            </React.Fragment>
          );
        });
    } else if (key === 'kitInfoIds') {
      data = [...new Set(data)];
      data =
        Array.isArray(data) &&
        data.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Link to={`/kit/${item}`}>{item}</Link>
              {data.length !== index + 1 && ', '}
            </React.Fragment>
          );
        });
    }
    return data;
  };

  const type_filter_set = [
    { id: '1', name: '빡공단' },
    { id: '2', name: '슈퍼z' },
    { id: '3', name: '연장' },
    { id: '4', name: '와디즈' },
    { id: '5', name: '일반' },
    { id: '6', name: '일반(실제금액0원)' },
    { id: '7', name: '제휴&제공' },
  ];

  const [checkedInputs, setCheckedInputs] = useState([]);
  useEffect(() => {
    let checkedData = '';
    if (checkedInputs.length !== 0) {
      for (let i = 0; i < checkedInputs.length; i++) {
        checkedData = checkedData + checkedInputs[i];
      }
    }
    setFilter({ ...filter_set, ['type_filter']: checkedData });
  }, [checkedInputs]);

  const changeHandler = (checked, id, name) => {
    if (checked) {
      setCheckedInputs([...checkedInputs, id]);
    } else {
      // 체크 해제
      setCheckedInputs(checkedInputs.filter((el) => el !== id));
    }

    //필터 추가 제한
    const target = document.querySelectorAll("input[name='" + name + "']:checked");
    for (let i = 0; i <= 6; i++) {
      document.getElementsByName(name)[i].disabled = true;
    }

    if (target.length > 0) {
      if (id <= 4) {
        for (let i = 0; i < 4; i++) {
          document.getElementsByName(name)[i].disabled = false;
        }
      } else {
        document.getElementsByName(name)[id - 1].disabled = false;
        //document.getElementsByName(name)[(type_filter_list[i] - 1)].disabled = false;
      }
    } else {
      for (let i = 0; i <= 6; i++) {
        document.getElementsByName(name)[i].disabled = false;
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent='매출' title='판매목록' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <div className='default-according style-1' id='accordionoc'>
                <CardHeader style={{ padding: '12px 30px' }}>
                  <Button
                    color='black pl-0'
                    data-toggle='collapse'
                    onClick={() => setIsFilterSpread(!is_filer_spread)}
                    data-target='#collapseicon'
                    aria-expanded={is_filer_spread}
                    aria-controls='collapseicon'
                  >
                    <h5 className='mb-0'>검색필터</h5>
                  </Button>
                </CardHeader>
              </div>
              <Collapse isOpen={is_filer_spread}>
                <CardBody>
                  <div>
                    <div className='form-row form-group'>
                      <Col md='2'>
                        <CustomInput
                          type={'text'}
                          name={'merchant_uid'}
                          value={filter_set.merchant_uid || ''}
                          label={'주문번호'}
                          placeholder={'주문번호'}
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit();
                            }
                          }}
                        />
                      </Col>
                      <Col md='4' className='d-flex flex-column px-2'>
                        <div className='mb-2'>상품 종류</div>
                        <ul className='d-flex flex-wrap'>
                          {type_filter_set.map((item, idx) => {
                            return (
                              <li key={idx} className='d-flex align-items-center mr-4 mb-3'>
                                <Input
                                  type={'checkbox'}
                                  id={'type_filter' + item.id}
                                  name='type_filter'
                                  value={idx + 1}
                                  className='checkbox_animated'
                                  onChange={(e) => {
                                    changeHandler(e.currentTarget.checked, item.id, 'type_filter');
                                  }}
                                  checked={checkedInputs.includes(item.id) ? true : false}
                                  //disabled={checkedInputs.includes(item.id) ? true : false}
                                />
                                <Label for={'type_filter' + item.id} className='m-0'>
                                  {item.name}
                                </Label>
                              </li>
                            );
                          })}
                        </ul>
                      </Col>
                      <Col md='3'>
                        <CustomInput
                          type={'text'}
                          name={'product_name'}
                          value={filter_set.product_name || ''}
                          label={'상품명'}
                          placeholder={'상품명'}
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit();
                            }
                          }}
                        />
                      </Col>
                      <Col md='3'>
                        <CustomInput
                          type={'text'}
                          name={'account_info'}
                          value={filter_set.account_info || ''}
                          label={'계정정보'}
                          placeholder={'이메일, 핸드폰, 이름, 계정명'}
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit();
                            }
                          }}
                        />
                      </Col>
                      <Col md='1'>
                        <CustomSelectInput
                          name={'purchase_status_id'}
                          value={filter_set.purchase_status_id?.split(',')[0]}
                          label={'결제상태'}
                          onChange={onFilterChange}
                          options={[
                            { id: '', name: '전체', description: '결재 전체' },
                            ...purchase_status_set,
                          ]}
                        />
                      </Col>
                      <Col md='2'>
                        <CustomSelectInput
                          name={'purchase_paymethod_id'}
                          value={filter_set.purchase_paymethod_id}
                          label={'결제방법'}
                          onChange={onFilterChange}
                          options={[
                            { id: '', name: '전체', description: '전체 결재' },
                            ...purchase_paymethod_set,
                          ]}
                        />
                      </Col>
                      <Col md='1'>
                        <CustomSelectInput
                          name={'currency_code_id'}
                          value={filter_set.currency_code_id}
                          label={'화폐'}
                          onChange={onFilterChange}
                          options={[
                            {
                              id: '',
                              name: '전체',
                              unit_name: '',
                              description: '',
                            },
                            ...currency_code_set,
                          ]}
                        />
                      </Col>
                    </div>
                    <hr style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }} />
                    <div className='form-row form-group'>
                      <Col>
                        <CustomLabel label={'제품 금액 범위'} />
                        <Input
                          type={'number'}
                          name={'product_price_start'}
                          value={filter_set.product_price_start || ''}
                          placeholder={'제품 금액 범위 시작'}
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit();
                            }
                          }}
                        />
                        <Input
                          type={'text'}
                          name={'product_price_end'}
                          value={filter_set.product_price_end || ''}
                          placeholder={'제품 금액 범위 종료'}
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit();
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <CustomLabel label={'실결제 금액 범위'} />
                        <Input
                          type={'text'}
                          name={'purchased_price_start'}
                          value={filter_set.purchased_price_start || ''}
                          placeholder={'실결제 금액 범위 시작'}
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit();
                            }
                          }}
                        />
                        <Input
                          type={'text'}
                          name={'purchased_price_end'}
                          value={filter_set.purchased_price_end || ''}
                          placeholder={'실결제 금액 범위 종료'}
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit();
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <CustomLabel label={'할인 금액 범위'} />
                        <Input
                          type={'number'}
                          name={'discounted_price_start'}
                          value={filter_set.discounted_price_start || ''}
                          placeholder={'할인 금액 범위 시작'}
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit();
                            }
                          }}
                        />
                        <Input
                          type={'number'}
                          name={'discounted_price_end'}
                          value={filter_set.discounted_price_end || ''}
                          placeholder={'할인 금액 범위 종료'}
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit();
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <CustomLabel label={'환불 금액 범위'} />
                        <Input
                          type={'number'}
                          name={'refund_price_start'}
                          value={filter_set.refund_price_start || ''}
                          placeholder={'환불 금액 범위 시작'}
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit();
                            }
                          }}
                        />
                        <Input
                          type={'number'}
                          name={'refund_price_end'}
                          value={filter_set.refund_price_end || ''}
                          placeholder={'환불 금액 범위 종료'}
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit();
                            }
                          }}
                        />
                      </Col>
                    </div>
                    <hr style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }} />
                    <div className='form-row form-group'>
                      {getDateFilterStates().map((date_info, index) => (
                        <Col key={index}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <CustomLabel label={date_info.label} />
                            <DatePicker
                              autoComplete='off'
                              className='form-control digits'
                              name={date_info.date_start.name}
                              dateFormat='yyyy-MM-dd'
                              onChange={(e) => {
                                onDateChange(e, date_info.date_start.name);
                              }}
                              placeholderText='시작 날짜'
                              selectsStart
                              selected={
                                filter_set[date_info.date_start.name]
                                  ? new Date(filter_set[date_info.date_start.name])
                                  : null
                              }
                              startDate={
                                filter_set[date_info.date_start.name]
                                  ? new Date(filter_set[date_info.date_start.name])
                                  : null
                              }
                              endDate={
                                filter_set[date_info.date_end.name]
                                  ? new Date(filter_set[date_info.date_end.name])
                                  : null
                              }
                              maxDate={
                                filter_set[date_info.date_end.name]
                                  ? new Date(filter_set[date_info.date_end.name])
                                  : new Date()
                              }
                            />

                            <DatePicker
                              autoComplete='off'
                              className='form-control digits'
                              name={date_info.date_end.name}
                              dateFormat='yyyy-MM-dd'
                              onChange={(e) => {
                                onDateChange(e, date_info.date_end.name);
                              }}
                              placeholderText='마지막 날짜'
                              selectsEnd
                              selected={
                                filter_set[date_info.date_end.name]
                                  ? new Date(filter_set[date_info.date_end.name])
                                  : null
                              }
                              startDate={
                                filter_set[date_info.date_start.name]
                                  ? new Date(filter_set[date_info.date_start.name])
                                  : null
                              }
                              endDate={
                                filter_set[date_info.date_end.name]
                                  ? new Date(filter_set[date_info.date_end.name])
                                  : null
                              }
                              minDate={
                                filter_set[date_info.date_start.name]
                                  ? new Date(filter_set[date_info.date_start.name])
                                  : null
                              }
                              maxDate={new Date()}
                            />

                            <Input
                              type='select'
                              name='create_time'
                              className='form-control'
                              onChange={(e) => {
                                onDateClick(e, date_info.date_start.name, date_info.date_end.name);
                              }}
                              defaultValue={'all'}
                            >
                              <option value='all'>선택</option>
                              <option value='before_0'>오늘</option>
                              <option value='before_1'>어제</option>
                              <option value='before_7'>7일전</option>
                              <option value='before_14'>14일전</option>
                              <option value='before_30'>30일전</option>
                              <option value='before_60'>60일전</option>
                              <option value='before_90'>90일전</option>
                            </Input>
                          </div>
                        </Col>
                      ))}
                    </div>
                    <hr style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }} />
                    <div className='form-row form-group'>
                      <Col md='3'>
                        <CustomInput
                          type={'text'}
                          name={'coupon_id'}
                          value={filter_set.coupon_id || ''}
                          label={'사용한 쿠폰'}
                          placeholder={'쿠폰ID'}
                          onChange={onFilterChange}
                        />
                      </Col>

                      <Col md='3'>
                        <CustomInput
                          type={'text'}
                          name={'event_id'}
                          value={filter_set.event_id || ''}
                          label={'참여 이벤트'}
                          placeholder={'이벤트ID'}
                          onChange={onFilterChange}
                        />
                      </Col>
                    </div>
                    <div className='form-row form-group'>
                      <Col md='10'></Col>
                      <Col md='1'>
                        <Label htmlFor='size'>
                          검색단위 <small>(개수)</small>
                        </Label>
                        <Input
                          className='form-control'
                          name='size'
                          type='number'
                          placeholder='검색단위'
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onFilterSubmit();
                            }
                          }}
                          value={filter_set.size}
                        />
                      </Col>
                      <Col md='1' className='mt-auto'>
                        <Button
                          className='btn btn-primary btn-pill'
                          color='success'
                          onClick={(e) => {
                            onFilterSubmit(e);
                          }}
                        >
                          검색
                        </Button>
                      </Col>
                    </div>
                  </div>
                </CardBody>
              </Collapse>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={8} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총: <span style={{ color: 'coral' }}>{initComma(sale_count)}</span>개
                        </p>
                        <Loading isLoading={isLoading || is_download} />
                      </Col>
                      <Col className='text-right'>
                        <Button
                          className='btn btn-primary btn-pill'
                          color='info'
                          onClick={onCSVRequset}
                        >
                          CSV 다운로드
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <div className='table-responsive'>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th
                        style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('id');
                        }}
                      >
                        {filter_set.order_key === 'id' ? (
                          <span style={{ color: '#ff4c3b' }}>ID</span>
                        ) : (
                          <span>ID</span>
                        )}
                      </th>
                      <th style={{ width: '230px', minWidth: '230px', maxWidth: '230px' }}>
                        주문번호/상품명
                      </th>
                      <th style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}>
                        구매자
                      </th>
                      <th
                        style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('purchase_status');
                        }}
                      >
                        {filter_set.order_key === 'purchase_status' ? (
                          <span style={{ color: '#ff4c3b' }}>결제상태</span>
                        ) : (
                          <span>결제상태</span>
                        )}
                      </th>
                      <th
                        style={{ width: '85px', minWidth: '85px', maxWidth: '85px' }}
                        onClick={() => {
                          onOrderChange('purchase_paymethod');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'purchase_paymethod' ? (
                          <span style={{ color: '#ff4c3b' }}>결제방법</span>
                        ) : (
                          <span>결제방법</span>
                        )}
                      </th>
                      <th
                        style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('product_price');
                        }}
                      >
                        {filter_set.order_key === 'product_price' ? (
                          <span style={{ color: '#ff4c3b' }}>제품금액</span>
                        ) : (
                          <span>제품금액</span>
                        )}
                      </th>
                      <th
                        style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('purchased_price');
                        }}
                      >
                        {filter_set.order_key === 'purchased_price' ? (
                          <span style={{ color: '#ff4c3b' }}>실결제금액</span>
                        ) : (
                          <span>실결제금액</span>
                        )}
                      </th>
                      <th
                        style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('discounted_price');
                        }}
                      >
                        {filter_set.order_key === 'discounted_price' ? (
                          <span style={{ color: '#ff4c3b' }}>할인금액</span>
                        ) : (
                          <span>할인금액</span>
                        )}
                      </th>
                      <th style={{ width: '110px', minWidth: '110px', maxWidth: '100px' }}>
                        <Tooltip
                          html={
                            <div style={{ whiteSpace: 'pre' }} className='p-2 rounded bg-dark'>
                              이벤트 할인 적용 id
                            </div>
                          }
                          title=''
                          position='top'
                          trigger='mouseenter'
                        >
                          <i style={{ marginRight: '5px' }} className='pe-7s-help1' />
                        </Tooltip>
                        <span>event_id</span>
                      </th>
                      <th style={{ width: '110px', minWidth: '150px', maxWidth: '100px' }}>
                        <Tooltip
                          html={
                            <div style={{ whiteSpace: 'pre' }} className='p-2 rounded bg-dark'>
                              쿠폰 할인 적용 id 들
                            </div>
                          }
                          title=''
                          position='top'
                          trigger='mouseenter'
                        >
                          <i style={{ marginRight: '5px' }} className='pe-7s-help1' />
                        </Tooltip>
                        <span>coupon_ids</span>
                      </th>
                      <th
                        style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('refund_price');
                        }}
                      >
                        {filter_set.order_key === 'refund_price' ? (
                          <span style={{ color: '#ff4c3b' }}>환불금액</span>
                        ) : (
                          <span>환불금액</span>
                        )}
                      </th>
                      <th
                        style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                        onClick={() => {
                          onOrderChange('id');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'id' ? (
                          <span style={{ color: '#ff4c3b' }}>결제요청일시</span>
                        ) : (
                          <span>결제요청일시</span>
                        )}
                      </th>
                      <th
                        style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                        onClick={() => {
                          onOrderChange('purchased_time');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'purchased_time' ? (
                          <span style={{ color: '#ff4c3b' }}>결제일시</span>
                        ) : (
                          <span>결제일시</span>
                        )}
                      </th>
                      <th
                        style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                        onClick={() => {
                          onOrderChange('refund_time');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'refund_time' ? (
                          <span style={{ color: '#ff4c3b' }}>환불일시</span>
                        ) : (
                          <span>환불일시</span>
                        )}
                      </th>
                      <th style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}>
                        강의ID
                      </th>
                      <th style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}>
                        강의들ID
                      </th>
                      <th style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}>
                        키트ID
                      </th>
                      <th style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}>
                        패키지ID
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sale_set.map((sale) => {
                      return (
                        <tr key={sale.id}>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '80px',
                              maxWidth: '80px',
                            }}
                          >
                            <Link to={`/sale/${sale.id}`}>{sale.id}</Link>
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '230px',
                              maxWidth: '230px',
                            }}
                          >
                            <div style={{ width: '230px' }} className='text-truncate f-w-600'>
                              {sale.merchant_uid}
                            </div>
                            <div style={{ width: '230px' }} className='text-truncate'>
                              {sale.product_name}
                            </div>
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '200px',
                              maxWidth: '200px',
                            }}
                          >
                            <Link to={`/account/${sale.account_id}`}>
                              <div>{sale.account_account_name}</div>
                              <div>({sale.account_id})</div>
                            </Link>
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '100px',
                              maxWidth: '100px',
                            }}
                          >
                            {purchase_status_key_is_id_object[sale.purchase_status_id]}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '85px',
                              maxWidth: '85px',
                            }}
                          >
                            {purchase_paymethod_key_is_id_object[sale.purchase_paymethod_id]}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '100px',
                              maxWidth: '100px',
                            }}
                          >
                            {initComma(sale.product_price)}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '100px',
                              maxWidth: '100px',
                            }}
                          >
                            {initComma(sale.purchased_price)}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '100px',
                              maxWidth: '100px',
                            }}
                          >
                            {initComma(sale.discounted_price)}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '110px',
                              maxWidth: '110px',
                            }}
                          >
                            {<Link to={`/event/${sale.event_id}`}>{sale.event_id}</Link>}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '110px',
                              maxWidth: '110px',
                            }}
                          >
                            {sale.coupon_ids}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '100px',
                              maxWidth: '100px',
                            }}
                          >
                            {initComma(sale.refund_price)}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '130px',
                              maxWidth: '130px',
                            }}
                          >
                            {sale.create_time &&
                              moment(sale.create_time).format('YYYY-MM-DD HH:mm:ss')}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '130px',
                              maxWidth: '130px',
                            }}
                          >
                            {(sale.purchased_time &&
                              moment(sale.purchased_time).format('YYYY-MM-DD HH:mm:ss')) ||
                              '-'}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '130px',
                              maxWidth: '130px',
                            }}
                          >
                            {(sale.refund_time &&
                              moment(sale.refund_time).format('YYYY-MM-DD HH:mm:ss')) ||
                              '-'}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '100px',
                              maxWidth: '100px',
                            }}
                          >
                            {userDataJsonParse(sale.user_data, 'courseId')}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '100px',
                              maxWidth: '100px',
                            }}
                          >
                            {userDataJsonParse(sale.user_data, 'courseIds') &&
                              userDataJsonParse(sale.user_data, 'courseIds').map((courseId) => {
                                return courseId;
                              })}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '100px',
                              maxWidth: '100px',
                            }}
                          >
                            {userDataJsonParse(sale.user_data, 'kitInfoIds') &&
                              userDataJsonParse(sale.user_data, 'kitInfoIds').map((kitInfoId) => {
                                return kitInfoId;
                              })}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '100px',
                              maxWidth: '100px',
                            }}
                          >
                            {userDataJsonParse(sale.user_data, 'productId')}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size ? sale_count / params.size : sale_count / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Sale;
