import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Table,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import { copyToClipBoardWithPlainText, initComma, toQueryString } from '../../util/common';
import PurchaseModel from '../../model/PurchaseModel';
import StatusModal from '../_common/component/StatusModal';
import { PAGE_SIZE } from '../../constants';

const Shipping = (props) => {
  // 기존 props.history.push 로는 state를 가져온다 / 직접 url에 값을 때려 박으면 state에 값이 없어서 search에서 가져와야한다 / 이도저도 아닐 경우
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    search_text: params.search_text ? params.search_text : '',
    search_kit_id: params.search_kit_id ? params.search_kit_id : '',
    shipping_status: params.shipping_status ? params.shipping_status : 1,
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const [user_kit_ship_set, setUserKitShipSet] = useState([]);
  const [user_kit_ship_count, setUserKitShipCount] = useState(0);

  const [shipping_status_set, setShippingStatusSet] = useState([]);
  const [shipper_set, setShipperSet] = useState([]);

  const table_ref = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilter] = useState(default_filter.current);

  const [delete_toggle, setDeleteToggle] = useState(false);
  const [all_checked, setAllChecked] = useState(false);
  const [delete_id_set, setDeleteIdSet] = useState([]);

  const onClickToggleBtn = () => {
    setDeleteIdSet([]);
    setAllChecked(false);
    setUserKitShipSet((prevState) => {
      return [
        ...prevState.map((elem) => {
          elem.checked = false;
          return elem;
        }),
      ];
    });
    setDeleteToggle(!delete_toggle);
  };

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onShippingStatusChange = (e) => {
    pushHistory({
      ...filter_set,
      shipping_status: e.target.value,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterSubmit = (e) => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      order_key: order_key,
      order_type: !filter_set.order_type || filter_set.order_type === 'asc' ? 'desc' : 'asc', // 정렬을 기존 기준 -> "없을 경우 or asc = desc로 변경" / "desc 일 경우 = asc 로 변경"
    };

    // 이전에 정렬한적 있고, 새로운 정렬을 적용.
    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }

    pushHistory(newFilterSet);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    });
    window.scrollTo(0, 0);
  };

  const onInputChange = (e, index) => {
    const tmp_user_kit_ship = { ...user_kit_ship_set[index], [e.target.name]: e.target.value };

    user_kit_ship_set[index] = tmp_user_kit_ship;
    setUserKitShipSet([...user_kit_ship_set]);

    PurchaseModel.putShipping({
      ...tmp_user_kit_ship,
      target: e.target.name,
    }).then(() => {});
  };

  const setToastMessage = () => {
    toast.success('복사 되었습니다.', {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const onClipBoardClick = () => {
    copyToClipBoardWithPlainText(table_ref.current, setToastMessage);
  };

  const allChecked = (checked) => {
    const id_set = [];
    if (checked) {
      setUserKitShipSet((prevState) => {
        return [
          ...prevState.map((elem) => {
            id_set.push(elem.id);
            elem.checked = checked;
            return elem;
          }),
        ];
      });
    } else {
      id_set.splice(0);
      setUserKitShipSet((prevState) => {
        return [
          ...prevState.map((elem) => {
            elem.checked = checked;
            return elem;
          }),
        ];
      });
    }
    setDeleteIdSet(id_set);
    setAllChecked(checked);
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'shipping_status_set' }).then(({ code_set }) => {
      setShippingStatusSet(code_set);
    });

    CommonModel.staticCode({ code_name: 'shipper_set' }).then(({ code_set }) => {
      setShipperSet(code_set);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    PurchaseModel.getShipping({
      ...params,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ user_kit_ship_set, total_count }) => {
      setUserKitShipSet(user_kit_ship_set);
      setUserKitShipCount(total_count);
      setIsLoading(false);
    });
  }, [props.location.search]);

  return (
    <Fragment>
      <Breadcrumb parent='서비스' title='배송 목록' />
      <Container fluid={true}>
        <Col className='text-right mb-4'>
          {!delete_toggle ? (
            <Button className='btn-pill' color='primary' onClick={onClickToggleBtn}>
              일괄 선택 변경
            </Button>
          ) : (
            <>
              <StatusModal
                reply_status_set={shipping_status_set}
                update_id_list={delete_id_set}
                type={'shipping'}
                redirect_url={props.location.pathname + props.location.search}
              />
              <Button className='btn-pill' color='primary' onClick={onClickToggleBtn}>
                취소
              </Button>
            </>
          )}
        </Col>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={4} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총:{' '}
                          <span style={{ color: 'coral' }}>{initComma(user_kit_ship_count)}</span>개
                        </p>
                        {params.search_kit_id && (
                          <small
                            style={{
                              marginTop: '7px',
                              display: 'inline',
                              fontWeight: 'bold',
                            }}
                            className={'ml-2'}
                          >{`키트 ID : ${params.search_kit_id} 조회`}</small>
                        )}
                        <Loading isLoading={isLoading} />
                      </Col>
                      <Col md={3} className={'text-right'}>
                        <Button className='btn btn-primary btn-pill' onClick={onClipBoardClick}>
                          클립보드에 복사
                        </Button>
                      </Col>
                      <Col md={2}>
                        <Input
                          type='select'
                          name='shipping_status'
                          className='form-control'
                          onChange={(e) => {
                            onShippingStatusChange(e);
                          }}
                          value={filter_set.shipping_status}
                        >
                          <option value='all'>전체</option>
                          {shipping_status_set.map((shipping_status) => {
                            return (
                              <option key={shipping_status.id} value={shipping_status.id}>
                                {shipping_status.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                      <Col md={3} className='text-right'>
                        <FormGroup>
                          <InputGroup>
                            <Input
                              className='form-control'
                              type='text'
                              name='search_text'
                              onChange={onFilterChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onFilterSubmit(e);
                                }
                              }}
                              value={filter_set.search_text}
                              placeholder='주문명/주문자계정을 입력하세요.'
                            />
                            <InputGroupAddon
                              addonType='append'
                              style={{ cursor: 'pointer' }}
                              onClick={onFilterSubmit}
                            >
                              <InputGroupText>
                                <i className='icofont icofont-ui-search' />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <div className='table-responsive' ref={table_ref}>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      {delete_toggle && (
                        <th className='table-sort-th' onClick={(e) => allChecked(!all_checked)}>
                          {all_checked ? (
                            <i className='typcn typcn-tick' />
                          ) : (
                            <i className='typcn typcn-tick-outline' />
                          )}
                        </th>
                      )}
                      <th
                        style={{ width: '70px', maxWidth: '70px', minWidth: '70px' }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('id');
                        }}
                      >
                        {filter_set.order_key === 'id' ? (
                          <span style={{ color: '#ff4c3b' }}>ID</span>
                        ) : (
                          <span>ID</span>
                        )}
                      </th>
                      <th
                        style={{ width: '75px', maxWidth: '75px', minWidth: '75px' }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('purchase_kit_info__purchase_info');
                        }}
                      >
                        {filter_set.order_key === 'purchase_kit_info__purchase_info' ? (
                          <span style={{ color: '#ff4c3b' }}>주문ID</span>
                        ) : (
                          <span>주문ID</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '200px',
                          maxWidth: '200px',
                          minWidth: '200px',
                          textAlign: 'left',
                        }}
                      >
                        주문명
                      </th>
                      <th
                        style={{
                          width: '200px',
                          maxWidth: '200px',
                          minWidth: '200px',
                          textAlign: 'left',
                        }}
                      >
                        주문정보
                      </th>
                      <th style={{ width: '70px', maxWidth: '70px', minWidth: '70px' }}>갯수</th>
                      <th
                        style={{
                          width: '200px',
                          maxWidth: '200px',
                          minWidth: '200px',
                          textAlign: 'left',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('shipper__name');
                        }}
                      >
                        {filter_set.order_key === 'shipper__name' ? (
                          <span style={{ color: '#ff4c3b' }}>택배사</span>
                        ) : (
                          <span>택배사</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '120px',
                          maxWidth: '120px',
                          minWidth: '120px',
                          textAlign: 'left',
                        }}
                      >
                        배송정보
                      </th>
                      <th
                        style={{
                          width: '150px',
                          maxWidth: '150px',
                          minWidth: '150px',
                          textAlign: 'left',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('shipping_number');
                        }}
                      >
                        {filter_set.order_key === 'shipping_number' ? (
                          <span style={{ color: '#ff4c3b' }}>송장번호</span>
                        ) : (
                          <span>송장번호</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '200px',
                          maxWidth: '200px',
                          minWidth: '200px',
                          textAlign: 'left',
                        }}
                      >
                        배송지
                      </th>
                      <th style={{ width: '90px', maxWidth: '90px', minWidth: '90px' }}>
                        우편번호
                      </th>
                      <th style={{ width: '200px', maxWidth: '200px', minWidth: '200px' }}>
                        주문자계정
                      </th>
                      <th style={{ width: '150px', maxWidth: '150px', minWidth: '150px' }}>
                        주문자전화번호
                      </th>
                      <th style={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>
                        주문자
                      </th>
                      <th
                        style={{
                          width: '200px',
                          maxWidth: '200px',
                          minWidth: '200px',
                          textAlign: 'left',
                        }}
                      >
                        요청사항
                      </th>
                      <th
                        style={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('purchase_kit_info__purchase_info__purchased_time');
                        }}
                      >
                        {filter_set.order_key ===
                        'purchase_kit_info__purchase_info__purchased_time' ? (
                          <span style={{ color: '#ff4c3b' }}>결제일</span>
                        ) : (
                          <span>결제일</span>
                        )}
                      </th>
                      <th style={{ width: '60px', maxWidth: '60px', minWidth: '60px' }}>작업</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user_kit_ship_set.map((user_kit_ship, index) => {
                      return (
                        <tr key={user_kit_ship.id}>
                          {delete_toggle && (
                            <td style={{ verticalAlign: 'middle' }}>
                              <FormGroup className='custom-radio-ml'>
                                <div className='checkbox checkbox-primary'>
                                  <Input
                                    id={'checkbox-primary-' + user_kit_ship.id}
                                    name='delete_id_set'
                                    checked={user_kit_ship.checked ? user_kit_ship.checked : false}
                                    type='checkbox'
                                    value={user_kit_ship.id}
                                    onChange={(e) => {
                                      setUserKitShipSet((prevState) => {
                                        const state = prevState[index];
                                        state.checked = e.target.checked;
                                        return [...prevState];
                                      });
                                      if (e.target.checked) {
                                        const new_delete_id_set = delete_id_set;
                                        new_delete_id_set.push(user_kit_ship.id);
                                        setDeleteIdSet(new_delete_id_set);
                                      } else {
                                        const new_delete_id_set = delete_id_set.filter(
                                          (elem) => elem !== user_kit_ship.id,
                                        );
                                        setDeleteIdSet(new_delete_id_set);
                                      }
                                    }}
                                  />
                                  <Label for={'checkbox-primary-' + user_kit_ship.id} />
                                </div>
                              </FormGroup>
                            </td>
                          )}
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '70px',
                              minWidth: '70px',
                            }}
                          >
                            {user_kit_ship.id}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '75px',
                              minWidth: '75px',
                            }}
                          >
                            <Link
                              to={'/sale/' + user_kit_ship.purchase_kit_info?.purchase_info?.id}
                            >
                              {user_kit_ship.purchase_kit_info?.purchase_info?.id}
                            </Link>
                          </td>
                          <td
                            className='text-left text-truncate'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '200px',
                              minWidth: '200px',
                            }}
                          >
                            {user_kit_ship.purchase_kit_info?.purchase_info?.product_name}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '200px',
                              minWidth: '200px',
                            }}
                          >
                            <Link
                              className='text-truncate'
                              style={{
                                display: 'block',
                                maxWidth: '200px',
                                minWidth: '200px',
                              }}
                              to={'/kit/' + user_kit_ship.purchase_kit_info?.kit_info?.id}
                            >
                              {user_kit_ship.purchase_kit_info?.kit_info?.title}
                            </Link>
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '70px',
                              minWidth: '70px',
                            }}
                          >
                            {user_kit_ship.purchase_kit_info?.ordered_count}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '200px',
                              minWidth: '200px',
                            }}
                          >
                            <Input
                              type='select'
                              name='shipper_id'
                              className='form-control form-control-sm'
                              onChange={(e) => {
                                onInputChange(e, index);
                              }}
                              value={user_kit_ship.shipper_id || ''}
                            >
                              <option value=''>지정안됨</option>
                              {shipper_set.map((shipper) => {
                                return (
                                  <option key={shipper.id} value={shipper.id}>
                                    {shipper.name}
                                  </option>
                                );
                              })}
                            </Input>
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '120px',
                              minWidth: '120px',
                            }}
                          >
                            <Input
                              type='select'
                              name='shipping_status_id'
                              className='form-control form-control-sm'
                              onChange={(e) => {
                                onInputChange(e, index);
                              }}
                              value={user_kit_ship.shipping_status_id}
                            >
                              {shipping_status_set.map((shipping_status) => {
                                return (
                                  <option key={shipping_status.id} value={shipping_status.id}>
                                    {shipping_status.name}
                                  </option>
                                );
                              })}
                            </Input>
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '150px',
                              minWidth: '150px',
                            }}
                          >
                            <Input
                              className='form-control form-control-sm'
                              name={'shipping_number'}
                              value={user_kit_ship.shipping_number || ''}
                              onChange={(e) => {
                                onInputChange(e, index);
                              }}
                            />
                          </td>
                          <td
                            className='text-left'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '200px',
                              minWidth: '200px',
                            }}
                          >
                            {user_kit_ship.ordered_address}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '90px',
                              minWidth: '90px',
                            }}
                          >
                            {user_kit_ship.ordered_address_code}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                            }}
                          >
                            <Link
                              to={
                                '/account/' +
                                user_kit_ship.purchase_kit_info?.purchase_info?.account?.id
                              }
                            >
                              {
                                user_kit_ship.purchase_kit_info?.purchase_info?.account
                                  ?.account_name
                              }
                            </Link>
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '150px',
                              minWidth: '150px',
                            }}
                          >
                            {user_kit_ship.ordered_user_phone}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '100px',
                              minWidth: '100px',
                            }}
                          >
                            {user_kit_ship.ordered_user_name}
                          </td>
                          <td
                            className='text-left'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '200px',
                              minWidth: '200px',
                            }}
                          >
                            {user_kit_ship.shipping_requested_message}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '100px',
                              minWidth: '100px',
                            }}
                          >
                            {user_kit_ship.purchase_kit_info?.purchase_info?.purchased_time &&
                              moment(
                                user_kit_ship.purchase_kit_info?.purchase_info?.purchased_time,
                              ).format('YYYY-MM-DD HH:mm:ss')}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              padding: '0 5px',
                              maxWidth: '60px',
                              minWidth: '60px',
                            }}
                          >
                            <Link to={'/shipping/' + user_kit_ship.id}>조회</Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size
                      ? user_kit_ship_count / params.size
                      : user_kit_ship_count / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </Fragment>
  );
};

export default Shipping;
