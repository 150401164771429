import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import AccountModel from '../../../model/AccountModel';
import { copyStringToClipboard, initComma, purchaseStatus } from '../../../util/common';
import Loading from '../../_common/Loading';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';

const AccountPurchaseInfoTable = (props) => {
  const [purchase_set, setPurchaseSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    AccountModel.getAccountPurchase(props.additionalProps).then(({ purchaseinfo }) => {
      setPurchaseSet(purchaseinfo);
      setIsLoading(false);
    });
  }, []);

  const columns = [
    {
      name: '주문번호',
      selector: 'merchant_uid',
      sortable: true,
      center: true,
      width: '250px',
      cell: (row) => (
        <>
          <Link to={`/sale/${row.id}`}>{row.merchant_uid}</Link>
          <button
            onClick={(e) => {
              row.merchant_uid && copyStringToClipboard(row.merchant_uid);
              toast.success('복사 되었습니다.', {
                position: toast.POSITION.TOP_RIGHT,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                row.merchant_uid && copyStringToClipboard(row.merchant_uid);
                toast.success('복사 되었습니다.', {
                  position: toast.POSITION.TOP_RIGHT,
                });
                e.preventDefault();
              }
            }}
            tabIndex='0'
            className='icofont icofont-ui-copy ml-2 btn-sm btn-light'
          ></button>
        </>
      ),
    },
    {
      name: '주문명',
      selector: 'product_name',
      sortable: true,
      center: true,
      width: '300px',
    },
    {
      name: '결제상태',
      selector: 'purchase_status_name',
      sortable: true,
      center: true,
      width: '110px',
      cell: (row) => (
        <div
          className={((e) => {
            return purchaseStatus(row.purchase_status_name);
          })()}
        >
          {row.purchase_status_name}
        </div>
      ),
    },
    {
      name: '결제금액',
      selector: 'purchased_price',
      sortable: true,
      center: true,
      width: '110px',
      cell: (row) => (
        <div>
          {initComma(row.purchased_price)} {row.currency_code_unit_name}
        </div>
      ),
    },
    {
      name: '결제방식',
      selector: 'purchase_paymethod_name',
      sortable: true,
      center: true,
      width: '150px',
    },
    {
      name: '결제요청일',
      selector: 'create_time',
      sortable: true,
      center: true,
      width: '200px',
      cell: (row) => <div>{row.create_time ? row.create_time.replace('T', ' ') : ''}</div>,
    },
    {
      name: '결제승인일',
      selector: 'purchased_time',
      sortable: true,
      center: true,
      width: '200px',
      cell: (row) => <div>{row.purchased_time ? row.purchased_time.replace('T', ' ') : ''}</div>,
    },
    {
      name: '수강코드',
      selector: 'courseticketlog__course_ticket__title',
      sortable: true,
      center: true,
      sortFunction: (row1, row2) =>
        ((e) => {
          return row1.course_ticket_log_set.length - row2.course_ticket_log_set.length;
        })(),
      cell: (row) =>
        ((e) => {
          if (row.course_ticket_log_set) {
            const _ticket_titles = row.course_ticket_log_set.map((courseticketlog, index) => {
              return (
                <Link
                  target={'_blank'}
                  key={index}
                  style={{ whiteSpace: 'nowrap' }}
                  to={`/ticket/${courseticketlog.course_ticket}`}
                >
                  {courseticketlog.ticket_title}
                </Link>
              );
            });
            return _ticket_titles;
          }
        })(),
    },
    {
      name: '쿠폰',
      selector: 'couponlog__coupon__title',
      sortable: true,
      center: true,
      style: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
      },
      sortFunction: (row1, row2) =>
        ((e) => {
          return row1.coupon_log_set.length - row2.coupon_log_set.length;
        })(),
      cell: (row) =>
        ((e) => {
          if (row.coupon_log_set) {
            const _coupon_titles = row.coupon_log_set.map((couponlog, index) => {
              return (
                <Link
                  target={'_blank'}
                  key={index}
                  style={{ whiteSpace: 'nowrap' }}
                  to={`/coupon/${couponlog.coupon}`}
                >
                  {couponlog.coupon_title}
                </Link>
              );
            });
            return _coupon_titles;
          }
        })(),
    },
    {
      name: '이벤트',
      selector: 'event_log__event__title',
      sortable: true,
      center: true,
      style: {
        width: 'max-content',
      },
      sortFunction: (row) => (row.event_log ? row.event_log.event : 0),
      cell: (row) =>
        row.event_log ? (
          <Link target={'_blank'} to={`/event/${row.event_log.event}`}>
            {row.event_log.event_title}
          </Link>
        ) : (
          ''
        ),
    },
  ];

  return (
    <div className='table-responsive'>
      <div style={isLoading ? { display: 'block' } : { display: 'none' }} className='mb-4'>
        <Loading isLoading={isLoading} />
      </div>
      <DataTable
        noDataComponent={isLoading ? '잠시만 기다려주세요.' : '데이터가 없습니다.'}
        keyField={'id'}
        columns={columns}
        data={purchase_set}
        striped={true}
        center={true}
        noHeader={true}
        persistTableHead
      />
      {props.activeTab === '1' && <ToastContainer />}
    </div>
  );
};

export default AccountPurchaseInfoTable;
