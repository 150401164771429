import {
  SET_DELETE_IMG,
  CLEAR_DELETE_IMG,
  STORED_STATIC_CODE,
  CLEAR_STATIC_CODE,
} from '../action_type';

const INIT_STATE = {
  delete_img: {
    course_info_img: [],
  },
  job_class_set: [],
  provider_type_set: [],
  account_type_set: [],
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DELETE_IMG:
      return state;

    case CLEAR_DELETE_IMG:
      return {
        ...state,
        delete_img: {
          [action.img_type]: [],
        },
      };

    case STORED_STATIC_CODE:
      return {
        ...state,
        [action.key]: [...action.code_set],
      };

    case CLEAR_STATIC_CODE:
      return INIT_STATE;

    default:
      return state;
  }
};
