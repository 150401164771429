import {
  STORED_EBOOK_INFO,
  CLEAR_EBOOK_INFO,
  SET_EBOOK_INFO,
  SET_EBOOK_TAG,
  REMOVE_EBOOK_TAG,
  SET_EBOOK_KIT,
  SET_EBOOK_SUB_CATEGORY,
  SET_EBOOK_BODY,
} from '../../action_type';

const INIT_STATE = {
  title: '',
  description: '',
  total_pages: 0,
  author_name: '',
  ebook_sale_status_id: 4,
  hide_in_list: false,
  main_image_url: null,
  category_id: 1,
  body: {},
  author_account_id: 0,
  reservation_url: null,
  pdf_url: null,
  watermark_url: null,
  opacity: 0.3,
  contract_expire_date: null,
  to_be_open_date: null,
  sale_status_update_time: null,
  sale_comment: '',
  cannot_use_coupon: false,
  badge_type_id: null,
  tag_set: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_EBOOK_INFO:
      return INIT_STATE;

    case STORED_EBOOK_INFO:
      return action.data;

    case SET_EBOOK_INFO:
      return {
        ...state,
        [action.name]: action.value,
      };

    case SET_EBOOK_TAG:
      const set_tag_set = state.tag_set;
      set_tag_set.push({ name: action.value });
      return {
        ...state,
        tag_set: set_tag_set,
      };

    case REMOVE_EBOOK_TAG:
      const tag_set = state.tag_set;
      tag_set.splice(action.index, 1);
      return {
        ...state,
        tag_set: tag_set,
      };

    case SET_EBOOK_KIT:
      return {
        ...state,
        kit_set: action.kit_set,
      };

    case SET_EBOOK_SUB_CATEGORY:
      return {
        ...state,
        sub_category_set: action.sub_category_set,
      };

    case SET_EBOOK_BODY:
      return {
        ...state,
        body: {
          ...state.body,
          [action.name]: action.value,
        },
      };

    default:
      return { ...state };
  }
};
