import React, { useRef, useState } from 'react';
import { Col, Table } from 'reactstrap';
import TableHead from '../../_common/component/TableHead';
import { useMountEffect } from '../../../util/b2b_Hooks';
import { autoHyphenPhoneNumber } from '../../../util/b2b_utils';
import CommonModel from '../../../model/CommonModel';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { stored_static_code } from '../../../redux/common/action';
import { is_empty_value } from '../../../util/common';

const BookmarkUserTable = (props) => {
  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);

  const [account_type_set, setAccountTypeSet] = useState([]);
  const [job_class_set, setJobClassSet] = useState([]);
  const [provider_type_set, setProviderTypeSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const target_set = useRef(props.table_head?.map((key) => key.target));

  useMountEffect(() => {
    updateUi();
  });

  const updateUi = async () => {
    setIsLoading(true);

    // account_type_set, job_class_set, provider_type_set 상수 값 가져오기
    let get_static_code_res;
    if (!CommonStore.account_type_set || CommonStore.account_type_set.length <= 1) {
      get_static_code_res = await CommonModel.staticCode({ code_name: 'account_type_set' });
      if (get_static_code_res.code === 200) {
        setAccountTypeSet(get_static_code_res.code_set);
        dispatch(stored_static_code('account_type_set', get_static_code_res.code_set));
      }
    } else {
      setAccountTypeSet(CommonStore.account_type_set);
    }

    if (!CommonStore.job_class_set || CommonStore.job_class_set.length <= 1) {
      get_static_code_res = await CommonModel.staticCode({ code_name: 'job_class_set' });
      if (get_static_code_res.code === 200) {
        setJobClassSet(get_static_code_res.code_set);
        dispatch(stored_static_code('job_class_set', get_static_code_res.code_set));
      }
    } else {
      setJobClassSet(CommonStore.job_class_set);
    }

    if (!CommonStore.provider_type_set || CommonStore.provider_type_set.length <= 1) {
      get_static_code_res = await CommonModel.staticCode({ code_name: 'provider_type_set' });
      if (get_static_code_res.code === 200) {
        setProviderTypeSet(get_static_code_res.code_set);
        dispatch(stored_static_code('provider_type_set', get_static_code_res.code_set));
      }
    } else {
      setProviderTypeSet(CommonStore.provider_type_set);
    }

    setIsLoading(false);
  };

  const getTypeNameTd = (type_set, id, index) => {
    if (type_set.length) {
      let type_index = type_set.findIndex((type) => type.id === id);
      return <td key={index}>{type_set[type_index].name}</td>;
    } else {
      return null;
    }
  };

  const getTableDataByTarget = (value, target, index) => {
    if (is_empty_value(value)) {
      return <td key={index} />;
    } else {
      if (target === 'gender') {
        return <td key={index}>{value === 'male' ? '남' : '여'}</td>;
      } else if (target === 'phone') {
        return <td key={index}>{autoHyphenPhoneNumber(value)}</td>;
      } else if (
        target === 'job_class_id' ||
        target === 'provider_type_id' ||
        target === 'account_type_id'
      ) {
        return getTypeNameTd(
          target === 'job_class_id'
            ? job_class_set
            : target === 'provider_type_id'
              ? provider_type_set
              : target === 'account_type_id'
                ? account_type_set
                : null,
          value,
          index,
        );
      } else if (target === 'update_time') {
        return <td key={index}>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</td>;
      } else if (target === 'has_course') {
        return (
          <td key={index}>
            <span className={value === 1 ? 'badge badge-info' : 'badge badge-danger'}>
              {value === 1 ? '보유중' : '미보유'}
            </span>
          </td>
        );
      } else {
        return <td key={index}>{value}</td>;
      }
    }
  };

  return (
    <>
      <Col md={'12'}>
        <Table className='table-hover text-center'>
          <TableHead table_head={props.table_head} />
          <tbody>
            {isLoading ? (
              <tr>
                <td
                  colSpan='10'
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <div className='loader-box'>
                    <div className='loader-15' />
                  </div>
                </td>
              </tr>
            ) : props.bookmark_user_set.length ? (
              props.bookmark_user_set.map((user, i) => {
                return (
                  <tr key={i}>
                    {target_set.current.map((target, index) => {
                      return getTableDataByTarget(user[target], target, index);
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>현재 북마크한 유저가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </>
  );
};

export default BookmarkUserTable;
