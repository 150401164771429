import React, { useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';

import {
  BROADCAST_EDITABLE,
  useApplicationContext,
} from '../../../context/ApplicationContextProvider';
import CustomTooltip from './CustomTooltip';

/**
 *
 * @param className
 * @param name
 * @param innerRef
 * @param setValue          // 수정하지 않고 취소하면 다시 rollback을 위한 setValue
 * @param defaultValue
 * @param options           // options = [{name: option의 표시값, id: option의 value}, {}...]  - options.js 참고
 * @param with_edit_button  // 편집 버튼을 눌렀을 때 활성화 (기본값 disabled) - 편집버튼과 함께 쓰이진 않지만 disabled하고 싶을 때 true로 줄 것
 * @param read_only         // 편집 버튼을 눌러도 활성화 X
 * @param label_title
 * @param is_require        // 라벨의 * 표시
 * @param need_tooltip      // 툴팁의 필요 여부 (true이면 반드시 tooltip_content가 필요하다)
 * @param tooltip_content   // 툽팁으로 보여줄 내용
 * @param on_change         // on_change 가 있으면 innerRef 가 불필요
 */

const CustomSelect2 = ({
  className = 'custom-select editable',
  name,
  innerRef = null,
  setValue = null,
  defaultValue = null,
  options = [],
  with_edit_button = false,
  read_only = false,
  label_title,
  is_require = false,
  need_tooltip = false,
  tooltip_content = null,
  on_change = null,
}) => {
  const { broadcast } = useApplicationContext();

  // state
  const [is_editable, setEditable] = useState(with_edit_button);
  const [is_cancel_button_clicked, setCancelButtonClicked] = useState(false);

  useEffect(() => {
    const unlisten = broadcast.listen([BROADCAST_EDITABLE], ({ value }) => {
      if (!read_only) {
        setEditable(value.disable_input);
        setCancelButtonClicked(value.is_cancel_button_click);
      }
    });

    return () => {
      broadcast.unlisten(unlisten);
    };
  }, [broadcast, read_only]);

  useEffect(() => {
    if (is_cancel_button_clicked) {
      setValue && setValue(name, defaultValue);
    }
  }, [is_cancel_button_clicked, name, defaultValue, setValue]);

  return (
    <>
      <Label
        {...(need_tooltip && {
          style: {
            cursor: 'pointer',
          },
          id: `tooltip_target_${name}`,
        })}
      >
        {need_tooltip && (
          <>
            <i className='icon-help-alt' />
            &nbsp;
            <CustomTooltip target={`tooltip_target_${name}`} content={tooltip_content} />
          </>
        )}
        {is_require && (
          <span
            style={{
              color: '#FF0000',
            }}
          >
            *&nbsp;
          </span>
        )}
        <b>{label_title}</b>
      </Label>
      <Input
        disabled={is_editable}
        defaultValue={defaultValue}
        className={className}
        name={name}
        type='select'
        innerRef={innerRef}
        onChange={on_change}
      >
        {options.map((option, i) => (
          <option value={option.id} key={i}>
            {option.name}
          </option>
        ))}
      </Input>
    </>
  );
};

export default CustomSelect2;
