import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import React from 'react';

const CustomPagination = (props) => {
  return (
    <Pagination aria-label='Page navigation example' className='pagination-primary m-3'>
      {props.current_page - 1 > 0 ? (
        <>
          <PaginationItem>
            <PaginationLink
              onClick={() => {
                props.onPageChange(props.current_page - 1);
              }}
            >
              Previous
            </PaginationLink>
          </PaginationItem>

          {props.current_page - 3 > 0 ? (
            <PaginationItem>
              <PaginationLink
                onClick={() => {
                  props.onPageChange(1);
                }}
              >
                {1}
              </PaginationLink>
            </PaginationItem>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}

      {props.current_page - 2 > 0 ? (
        <>
          {props.current_page - 3 > 0 ? (
            <PaginationItem>
              <PaginationLink
                onClick={() => {
                  let cur_page = props.current_page - 10;
                  if (cur_page < 1) {
                    cur_page = 1;
                  }
                  props.onPageChange(cur_page);
                }}
              >
                ...
              </PaginationLink>
            </PaginationItem>
          ) : (
            ''
          )}
          <PaginationItem>
            <PaginationLink
              onClick={() => {
                props.onPageChange(props.current_page - 2);
              }}
            >
              {props.current_page - 2}
            </PaginationLink>
          </PaginationItem>
        </>
      ) : (
        ''
      )}
      {props.current_page - 1 > 0 ? (
        <PaginationItem>
          <PaginationLink
            onClick={() => {
              props.onPageChange(props.current_page - 1);
            }}
          >
            {props.current_page - 1}
          </PaginationLink>
        </PaginationItem>
      ) : (
        ''
      )}

      <PaginationItem active>
        <PaginationLink>{props.current_page}</PaginationLink>
      </PaginationItem>

      {props.current_page + 1 < props.max_page ? (
        <PaginationItem>
          <PaginationLink
            onClick={() => {
              props.onPageChange(props.current_page + 1);
            }}
          >
            {props.current_page + 1}
          </PaginationLink>
        </PaginationItem>
      ) : (
        ''
      )}
      {props.current_page + 2 < props.max_page ? (
        <>
          <PaginationItem>
            <PaginationLink
              onClick={() => {
                props.onPageChange(props.current_page + 2);
              }}
            >
              {props.current_page + 2}
            </PaginationLink>
          </PaginationItem>

          <PaginationItem>
            <PaginationLink
              onClick={() => {
                let cur_page = props.current_page + 10;
                if (cur_page > props.max_page) {
                  cur_page = props.max_page;
                }
                props.onPageChange(cur_page);
              }}
            >
              ...
            </PaginationLink>
          </PaginationItem>
        </>
      ) : (
        ''
      )}
      {props.max_page && props.current_page !== props.max_page ? (
        <>
          <PaginationItem>
            <PaginationLink
              onClick={() => {
                props.onPageChange(props.max_page);
              }}
            >
              {props.max_page}
            </PaginationLink>
          </PaginationItem>

          <PaginationItem>
            <PaginationLink
              onClick={() => {
                props.onPageChange(props.current_page + 1);
              }}
            >
              Next
            </PaginationLink>
          </PaginationItem>
        </>
      ) : (
        ''
      )}
    </Pagination>
  );
};

export default CustomPagination;
