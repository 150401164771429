import React, { useEffect, useRef } from 'react';
import { Button, Col, Input, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { autoHyphenPhoneNumber } from '../../../util/b2b_utils';
import { dialog_type_confirm, useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import {
  BROADCAST_NEED_UPDATE,
  useApplicationContext,
} from '../../../context/ApplicationContextProvider';
import { lang } from '../../../lang';
import CompanyModel from '../../../model/CompanyModel';

/*
    props
    is_remove_modal_open: bool              모달의 표시 여부
    toggleManagerRemoveModal: fnc => void   모달의 toggle func
    toggleParentModal: func => void         편집방법 선택 모달의 toggle func
    company_info: {}                        현재 기업의 정보(기업이름, status_id, 관리자, 사용인원)
 */
const ManagerRemoveModal = (props) => {
  const { showAlert } = useShowDialog();
  const { params } = useParameters();
  const { broadcast } = useApplicationContext();

  const remove_manager_set = useRef([]);
  const company_id = useRef(params.company_id);

  useEffect(() => {
    if (!props.is_add_modal_open) {
      // 모달을 닫으면 초기화
      remove_manager_set.current = [];
    }
  }, [props.is_add_modal_open]);

  const onCheckManagerAccount = (manager_account, e) => {
    if (e.currentTarget.checked) {
      remove_manager_set.current.push(manager_account.account_name);
    } else {
      remove_manager_set.current = remove_manager_set.current.filter(
        (exist_manager_account) => manager_account.account_name !== exist_manager_account,
      );
    }
  };

  const onSubmitManagerAccount = async () => {
    // 선택한 계정이 없을 경우
    if (!remove_manager_set.current.length) {
      showAlert({
        title: lang.company.need_check_manager_account,
      });

      // 전부 선택한 경우
    } else if (
      remove_manager_set.current.length === props.company_info.manager_account_set.length
    ) {
      showAlert({
        title: lang.company.can_not_remove_all_manager,
        text: lang.company.need_exist_more_than_one_manager,
      });
    } else {
      let current_manager_account_name_set = props.company_info.manager_account_set.map(
        (manager) => manager.account_name,
      );
      let post_manager_account_name_set = current_manager_account_name_set.filter((manager) => {
        let flag = false;
        flag = !remove_manager_set.current.includes(manager);
        return flag;
      });

      showAlert({
        type: dialog_type_confirm,
        title: lang.company.confirm_remove_company_manager,
        showCancelButton: true,
        confirmButtonText: lang.common.confirm_button_remove,
        true_fn: async () => {
          const update_company_res = await CompanyModel.updateCompanyInfo(company_id.current, {
            manager_account_name_set: post_manager_account_name_set,
            licensed_employee_count: props.company_info.licensed_employee_count,
          });

          if (update_company_res.code === 200) {
            showAlert({
              icon: 'success',
              title: lang.common.success_remove_message,
            });

            props.toggleManagerRemoveModal();
            props.toggleParentModal();

            broadcast.send(BROADCAST_NEED_UPDATE, true);
          } else {
            showAlert({
              title: update_company_res.message,
            });
          }
        },
      });
    }
  };

  return (
    <Modal
      isOpen={props.is_remove_modal_open}
      toggle={props.toggleManagerRemoveModal}
      centered
      size={'lg'}
    >
      <ModalHeader>제거하고자 하는 관리자를 선택 후 저장해주세요</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <Table className='table-hover text-center'>
              <thead>
                <tr>
                  <th className={'col-2'}>선택</th>
                  <th className={'col-2'}>이름</th>
                  <th>계정</th>
                  <th>전화번호</th>
                </tr>
              </thead>
              <tbody>
                {props.company_info?.manager_account_set?.length ? (
                  props.company_info.manager_account_set.map((manager_account, i) => (
                    <tr key={i}>
                      <td>
                        <Input
                          className='checkbox_animated'
                          type={'checkbox'}
                          onClick={(e) => {
                            onCheckManagerAccount(manager_account, e);
                          }}
                        />
                      </td>
                      <td>{manager_account.name}</td>
                      <td>{manager_account.account_name}</td>
                      <td>{autoHyphenPhoneNumber(manager_account.phone)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>등록된 기업관리자 계정이 없습니다</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={'text-right'}>
            <Button className={'btn'} color={'danger'} onClick={onSubmitManagerAccount}>
              제거하기
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ManagerRemoveModal;
