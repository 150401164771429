import axios from 'axios';

class LogModel {
  async getCouponLog(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/log/coupon', {
      params: filter,
    });
    return data;
  }

  async getCourseTicketLog(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/log/course-ticket', {
      params: filter,
    });
    return data;
  }
}

export default new LogModel();
