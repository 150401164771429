import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import ConfigureStore from './store/index';
import { routes } from './route/route';
import './index.scss';
import App from './App';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import SignIn from './views/_common/SignIn';
import { ApplicationContextProvider } from './context/ApplicationContextProvider';
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.headers = {
  Authorization: 'CMS97fdb87ff6643444e8e3cbee11d37452',
};

const { store, persistor } = ConfigureStore();

const Root = (props) => {
  return (
    <Fragment>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApplicationContextProvider>
            <BrowserRouter basename={'/'}>
              <Switch>
                <Route exact path={`/`} component={SignIn} />
                <App>
                  {routes.map(({ path, Component }) => (
                    <Route key={path} exact path={path} component={Component} />
                  ))}
                </App>
              </Switch>
            </BrowserRouter>
          </ApplicationContextProvider>
        </PersistGate>
      </Provider>
    </Fragment>
  );
};
ReactDOM.render(<Root />, document.getElementById('root'));
