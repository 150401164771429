import {
  STORED_COURSE_SUBJECT,
  CLEAR_COURSE_SUBJECT,
  SET_COURSE_SUBJECT,
  ADD_COURSE_SUBJECT,
  DELETE_COURSE_SUBJECT,
} from '../../action_type';

export const stored_course_subject = (data) => ({
  type: STORED_COURSE_SUBJECT,
  data,
});

export const clear_course_subject = () => ({
  type: CLEAR_COURSE_SUBJECT,
});

export const set_course_subject = (subject_index, name, value) => ({
  type: SET_COURSE_SUBJECT,
  subject_index,
  name,
  value,
});

export const add_course_subject = (add_index) => ({
  type: ADD_COURSE_SUBJECT,
  add_index,
});

export const delete_course_subject = (subject_start_index, subject_end_index) => ({
  type: DELETE_COURSE_SUBJECT,
  subject_start_index,
  subject_end_index,
});
