import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Table,
} from 'reactstrap';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import moment from 'moment';
import { initComma, toQueryString } from '../../util/common';
import CourseModel from '../../model/CourseModel';
import queryString from 'query-string';
import CustomPagination from '../_common/CustomPagination';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';
import StatusModal from '../_common/component/StatusModal';
import { Link } from 'react-router-dom';

const CourseQna = (props) => {
  const params = props.location.state || queryString.parse(props.location.search) || {};
  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: 20,
    search_text: params.search_text ? params.search_text : '',
    reply_status: params.reply_status ? params.reply_status : 1,
    order_key: params.order_key ? params.order_key : 'create_time',
    order_type: params.order_type ? params.order_type : 'desc',
  });
  const [qna_set, setQnaSet] = useState([]);
  const [qna_count, setQnaCount] = useState(0);
  const [reply_status_set, setReplyStatusSet] = useState([]);
  const [filter_set, setFilterSet] = useState(default_filter.current);
  const [isLoading, setIsLoading] = useState(false);
  const [delete_toggle, setDeleteToggle] = useState(false);
  const [all_checked, setAllChecked] = useState(false);
  const [delete_id_set, setDeleteIdSet] = useState([]);

  const initData = () => {
    CourseModel.getQnaList({
      size: 20,
      ...params,
    }).then(({ qna_set, qna_count }) => {
      setQnaSet(qna_set);
      setQnaCount(qna_count);
    });
  };

  useEffect(() => {
    setFilterSet(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    CommonModel.staticCode({ code_name: 'reply_status_set' }).then(({ code_set }) => {
      setReplyStatusSet(code_set);
    });
    initData();
  }, [props.location.search]);

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      order_key: order_key,
      order_type: filter_set.order_type === 'asc' ? 'desc' : 'asc',
    };

    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }
    pushHistory(newFilterSet);
  };

  const onSubmit = () => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: 'create_time',
      order_type: 'desc',
    });
  };

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
    });

    window.scrollTo(0, 0);
  };

  const onReplyTypeChange = (e) => {
    setDeleteIdSet([]);
    setAllChecked(false);
    setDeleteToggle(false);
    pushHistory({
      ...filter_set,
      reply_status: e.target.value,
      page: 1,
      order_key: 'create_time',
      order_type: 'desc',
    });
  };

  const statusUpdateSubmit = async (e, qna_id) => {
    setIsLoading(true);
    const status_id = e.target.value;
    const { update_qna_id_set } = await CourseModel.putQnaStatus([qna_id], status_id);
    if (update_qna_id_set.length > 0) {
      initData();
    }
    setIsLoading(false);
  };

  const onClickToggleBtn = () => {
    setDeleteIdSet([]);
    setAllChecked(false);
    setQnaSet((prevState) => {
      return [
        ...prevState.map((elem) => {
          elem.checked = false;
          return elem;
        }),
      ];
    });
    setDeleteToggle(!delete_toggle);
  };

  const allChecked = (checked) => {
    const id_set = [];
    if (checked) {
      setQnaSet((prevState) => {
        return [
          ...prevState.map((elem) => {
            id_set.push(elem.id);
            elem.checked = checked;
            return elem;
          }),
        ];
      });
    } else {
      id_set.splice(0);
      setQnaSet((prevState) => {
        return [
          ...prevState.map((elem) => {
            elem.checked = checked;
            return elem;
          }),
        ];
      });
    }
    setDeleteIdSet(id_set);
    setAllChecked(checked);
  };

  return (
    <Fragment>
      <Breadcrumb parent='강의' title='QnA 목록' />
      <Container fluid={true}>
        <Col className='text-right mb-4'>
          {!delete_toggle ? (
            <Button className='btn-pill' color='primary' onClick={onClickToggleBtn}>
              일괄 선택 변경
            </Button>
          ) : (
            <>
              <StatusModal
                reply_status_set={reply_status_set}
                update_id_list={delete_id_set}
                type={'qna'}
                redirect_url={'/course/qna'}
              />
              <Button className='btn-pill' color='primary' onClick={onClickToggleBtn}>
                취소
              </Button>
            </>
          )}
        </Col>
        <Card>
          <CardHeader>
            <Row style={{ marginBottom: '-20px' }}>
              <Col sm={7}>
                <p style={{ marginTop: '7px' }}>
                  총: <span style={{ color: 'coral' }}>{initComma(qna_count)}</span>개
                  <Loading isLoading={isLoading} />
                </p>
              </Col>
              <Col className='text-right'>
                <Row>
                  <Col sm={3}>
                    <Input
                      type='select'
                      name='reply_status'
                      className='form-control'
                      onChange={(e) => {
                        onReplyTypeChange(e);
                      }}
                      value={filter_set.reply_status}
                    >
                      <option value={0}>전체</option>
                      {reply_status_set.map((status) => {
                        return (
                          <option key={status.id} value={status.id}>
                            {status.name}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          className='form-control'
                          type='text'
                          name='search_text'
                          onChange={(e) => {
                            setFilterSet((prevState) => ({
                              ...prevState,
                              search_text: e.target.value,
                            }));
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              onSubmit(e);
                            }
                          }}
                          value={filter_set.search_text}
                          placeholder='찾고자 하는 강의명, 유저ID, 닉네임중 하나를 입력하세요.'
                        />
                        <InputGroupAddon
                          addonType='append'
                          style={{ cursor: 'pointer' }}
                          onClick={onSubmit}
                        >
                          <InputGroupText>
                            <i className='icofont icofont-ui-search' />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Table>
              <thead>
                <tr style={{ textAlign: 'center' }}>
                  {delete_toggle && (
                    <th className='table-sort-th' onClick={(e) => allChecked(!all_checked)}>
                      {all_checked ? (
                        <i className='typcn typcn-tick'></i>
                      ) : (
                        <i className='typcn typcn-tick-outline'></i>
                      )}
                    </th>
                  )}
                  <th style={{ width: '60px', maxWidth: '60px', minWidth: '60px' }}>ID</th>
                  <th
                    style={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}
                    className='table-sort-th'
                    onClick={() => {
                      onOrderChange('parent_count');
                    }}
                  >
                    {filter_set.order_key === 'parent_count' ? (
                      <span style={{ color: '#ff4c3b' }}>답변여부</span>
                    ) : (
                      <span>답변여부</span>
                    )}
                  </th>
                  <th style={{ width: '200px', maxWidth: '200px', minWidth: '200px' }}>강의명</th>
                  <th
                    style={{
                      minWidth: '500px',
                      maxWidth: '500px',
                      width: '500px',
                      textAlign: 'left',
                    }}
                  >
                    내용
                  </th>
                  <th style={{ minWidth: '240px', maxWidth: '240px', width: '240px' }}>댓글상태</th>
                  <th style={{ width: '230px', maxWidth: '230px', minWidth: '230px' }}>
                    작성자 정보
                  </th>
                  <th style={{ width: '70px', maxWidth: '70px', minWidth: '70px' }}>댓글ID</th>
                  <th style={{ width: '90px', maxWidth: '90px', minWidth: '90px' }}>대댓글수</th>
                  <th
                    style={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}
                    className='table-sort-th'
                    onClick={() => {
                      onOrderChange('create_time');
                    }}
                  >
                    {filter_set.order_key === 'create_time' ? (
                      <span style={{ color: '#ff4c3b' }}>생성일</span>
                    ) : (
                      <span>생성일</span>
                    )}
                  </th>
                  <th
                    style={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}
                    className='table-sort-th'
                    onClick={() => {
                      onOrderChange('update_time');
                    }}
                  >
                    {filter_set.order_key === 'update_time' ? (
                      <span style={{ color: '#ff4c3b' }}>수정일</span>
                    ) : (
                      <span>수정일</span>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {qna_set
                  ? qna_set.map((qna, index) => {
                      return (
                        <tr key={index} style={{ textAlign: 'center' }}>
                          {delete_toggle && (
                            <td>
                              <FormGroup className='custom-radio-ml'>
                                <div className='checkbox checkbox-primary'>
                                  <Input
                                    id={'checkbox-primary-' + qna.id}
                                    name='delete_id_set'
                                    checked={qna.checked ? qna.checked : false}
                                    type='checkbox'
                                    value={qna.id}
                                    onChange={(e) => {
                                      setQnaSet((prevState) => {
                                        const state = prevState[index];
                                        state.checked = e.target.checked;
                                        return [...prevState];
                                      });
                                      if (e.target.checked) {
                                        const new_delete_id_set = delete_id_set;
                                        new_delete_id_set.push(qna.id);
                                        setDeleteIdSet(new_delete_id_set);
                                      } else {
                                        const new_delete_id_set = delete_id_set.filter(
                                          (elem) => elem !== qna.id,
                                        );
                                        setDeleteIdSet(new_delete_id_set);
                                      }
                                    }}
                                  />
                                  <Label for={'checkbox-primary-' + qna.id} />
                                </div>
                              </FormGroup>
                            </td>
                          )}
                          <td
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '60px',
                              minWidth: '60px',
                            }}
                          >
                            {qna.id}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '100px',
                              minWidth: '100px',
                            }}
                          >
                            {qna.parent_count > 0 ? (
                              <span className='badge badge-pill badge-primary'>완료</span>
                            ) : (
                              <span className='badge badge-pill badge-info'>대기</span>
                            )}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '200px',
                              minWidth: '200px',
                            }}
                          >
                            <Link
                              className='text-truncate'
                              style={{
                                marginBottom: 0,
                                fontWeight: '600',
                                display: 'block',
                              }}
                              target={'_blank'}
                              to={'/course/' + qna.course}
                            >
                              {qna.course_title}
                            </Link>
                          </td>
                          <td
                            className='text-truncate'
                            style={{
                              cursor: 'pointer',
                              verticalAlign: 'middle',
                              textAlign: 'left',
                              minWidth: '500px',
                              maxWidth: '500px',
                            }}
                            onClick={() => {
                              props.history.push('/course/qna/' + qna.id);
                            }}
                          >
                            {qna.contents}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '230px',
                              minWidth: '230px',
                            }}
                          >
                            <Input
                              type='select'
                              disabled={delete_toggle}
                              name='reply_status'
                              className='form-control form-control-sm'
                              onChange={(e) => {
                                statusUpdateSubmit(e, qna.id);
                              }}
                              value={qna.reply_status}
                            >
                              {reply_status_set.map((status) => {
                                return (
                                  <option key={status.id} value={status.id}>
                                    {status.description}
                                  </option>
                                );
                              })}
                            </Input>
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '230px',
                              minWidth: '230px',
                            }}
                          >
                            <Link
                              className='text-truncate'
                              style={{
                                display: 'block',
                                marginBottom: 0,
                                fontWeight: '600',
                                maxWidth: '205px',
                                minWidth: '205px',
                              }}
                              target={'_blank'}
                              to={'/account/' + qna.account}
                            >
                              {qna.account_name}
                            </Link>
                            이름: {qna.name}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '70px',
                              minWidth: '70px',
                            }}
                          >
                            {qna.parent_course_qna ? (
                              <Link
                                style={{
                                  marginBottom: 0,
                                  fontWeight: '600',
                                }}
                                target={'_blank'}
                                to={'/course/qna/' + qna.parent_course_qna}
                              >
                                {qna.parent_course_qna}
                              </Link>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '90px',
                              minWidth: '90px',
                            }}
                          >
                            {qna.parent_count > 0 ? qna.parent_count : 0}개
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '100px',
                              minWidth: '100px',
                            }}
                          >
                            {moment(qna.create_time).format('YYYY.MM.DD')}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '100px',
                              minWidth: '100px',
                            }}
                          >
                            {!qna.update_time ? '-' : moment(qna.update_time).format('YYYY.MM.DD')}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </div>
          <div className='m-auto'>
            <CustomPagination
              current_page={params.page ? Number(params.page) : 1}
              max_page={Math.ceil(params.size ? qna_count / params.size : qna_count / 20)}
              onPageChange={onPageChange}
            />
          </div>
        </Card>
      </Container>
    </Fragment>
  );
};

export default CourseQna;
