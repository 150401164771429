import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { LINE_TAB_COURSE, LINE_TAB_EMPLOYEE } from '../../../constant/options';
import EmployeeList from './EmployeeList';
import CompanyCourseList from './CompanyCourseList';

/*
    props
    is_new_company: bool        기업등록 전의 상태 -> true
 */
const CompanyNavTab = (props) => {
  // state
  const [line_tab, setLineTab] = useState(LINE_TAB_EMPLOYEE);

  return (
    <>
      <Nav className='border-tab-primary p-t-5' tabs>
        <Col md='6' className='p-0'>
          <NavItem>
            <NavLink
              className={line_tab === LINE_TAB_EMPLOYEE ? 'active' : ''}
              onClick={() => {
                setLineTab(LINE_TAB_EMPLOYEE);
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              <span className='f-18 f-w-400'>임직원 목록</span>
            </NavLink>
          </NavItem>
        </Col>
        <Col md='6' className='p-0'>
          <NavItem>
            <NavLink
              className={line_tab === LINE_TAB_COURSE ? 'active' : ''}
              onClick={() => {
                setLineTab(LINE_TAB_COURSE);
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              <span className='f-18 f-w-400'>강의 내역</span>
            </NavLink>
          </NavItem>
        </Col>
      </Nav>
      <TabContent activeTab={line_tab}>
        <TabPane tabId={LINE_TAB_EMPLOYEE}>
          <EmployeeList is_new_company={props.is_new_company} />
        </TabPane>
        <TabPane tabId={LINE_TAB_COURSE}>
          <CompanyCourseList is_new_company={props.is_new_company} />
        </TabPane>
      </TabContent>
    </>
  );
};

export default CompanyNavTab;
