import React, { useEffect, useState } from 'react';
import { Container, Row, Col, CardBody, Form, FormGroup, Input, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import CommonModel from '../../model/CommonModel';
import { stored_auth_info } from '../../redux/auth/action';
import { useCheckCompanyId } from '../../util/b2b_Hooks';
import { addCompanyId } from '../../redux/b2b_store/company/actions';

const Signin = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: '',
    password: '',
  });
  const { getCompanyId } = useCheckCompanyId();

  const AuthStore = useSelector((state) => state.AuthStore);
  const company_reducer = useSelector((state) => state.company_reducer);

  useEffect(() => {
    if (AuthStore) {
      if (AuthStore.account_type_id === 5) {
        props.history.push('/home');
      } else if (AuthStore.account_type_id === 2) {
        props.history.push('/invoice/' + AuthStore.id);
      } else if (AuthStore.account_type_id === 3) {
        props.history.push('/company/' + company_reducer.company_id);
      }
    }
  }, []);

  const onSignIn = () => {
    if (state.email && state.password) {
      CommonModel.signIn(state).then(async ({ code, auth }) => {
        if (auth) {
          dispatch(stored_auth_info(auth));
          if (auth.account_type_id === 5) {
            props.history.push('/home');
          } else if (auth.account_type_id === 2) {
            props.history.push('/invoice/' + auth.id);
          } else if (auth.account_type_id === 3) {
            // 기업관리자는 본인 계정으로 등록된 회사 대쉬보드로 이동
            getCompanyId(auth.id).then((company_id) => {
              dispatch(addCompanyId(company_id));
              props.history.push('/company/' + company_id);
            });
          }
        } else {
          alert('아이디/비밀번호를 확인해주세요.');
        }
      });
    } else {
      alert('아이디/비밀번호를 확인해주세요.');
    }
  };

  const onChangeInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <div className='page-wrapper'>
      <Container fluid={true} className='p-0'>
        <div className='authentication-main m-0'>
          <Row>
            <Col md='12'>
              <div className='authentication-box'>
                <CardBody className='h-100-d-center'>
                  <div
                    className='cont text-center'
                    style={{ marginTop: '200px', maxWidth: '400px' }}
                  >
                    <img
                      alt='logo'
                      src={`${process.env.REACT_APP_IMG_URL}/static/png/common/bear-u-gray-logo.png`}
                    />
                    <Form className='theme-form'>
                      <FormGroup className='m-t-50'>
                        <Input
                          placeholder='E-Mail'
                          className='btn-pill'
                          name='email'
                          type='text'
                          required={true}
                          onChange={onChangeInput}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          placeholder='Password'
                          className='btn-pill'
                          name='password'
                          type='password'
                          required={true}
                          onChange={onChangeInput}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                              onSignIn();
                            }
                          }}
                        />
                      </FormGroup>
                      <FormGroup className='form-row mt-5'>
                        <Button color='secondary' className='btn-block' onClick={onSignIn}>
                          로그인;;
                        </Button>
                      </FormGroup>
                    </Form>
                  </div>
                </CardBody>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Signin;
