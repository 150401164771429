import {
  STORED_EBOOK_SALE,
  CLEAR_EBOOK_SALE,
  SET_EBOOK_SALE,
  ADD_EBOOK_SALE,
  DELETE_EBOOK_SALE,
  SET_EBOOK_SALE_PRICE,
} from '../../action_type';

export const stored_ebook_sale = (data) => ({
  type: STORED_EBOOK_SALE,
  data,
});

export const clear_ebook_sale = () => ({
  type: CLEAR_EBOOK_SALE,
});

export const set_ebook_sale = (sale_index, name, value) => ({
  type: SET_EBOOK_SALE,
  sale_index,
  name,
  value,
});

export const add_ebook_sale = () => ({
  type: ADD_EBOOK_SALE,
});

export const delete_ebook_sale = (sale_index) => ({
  type: DELETE_EBOOK_SALE,
  sale_index,
});

export const set_ebook_sale_price = (sale_index, name, value) => ({
  type: SET_EBOOK_SALE_PRICE,
  sale_index,
  name,
  value,
});
