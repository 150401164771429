import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { stored_auth_info } from '../redux/auth/action';
import AccountModel from '../model/AccountModel';
import { useCheckCompanyId, useParameters, useShowDialog } from './b2b_Hooks';
import CommonModel from '../model/CommonModel';
import { clearCompany } from '../redux/b2b_store/company/actions';
import { lang } from '../lang';
// permission: admin (최고관리자만)
// permission: master (마스터, 최고관리자)
// permission: company-manager (기업관리자, 최고관리자)
function Permission(Component, permission = 'all') {
  function Auth(props) {
    const dispatch = useDispatch();
    const [check, setCheck] = useState(false);
    const AuthStore = useSelector((state) => state.AuthStore);
    const company_reducer = useSelector((state) => state.company_reducer);
    const { params } = useParameters();
    const { getCompanyId } = useCheckCompanyId();
    const { showAlert } = useShowDialog();

    useEffect(() => {
      if (AuthStore) {
        AccountModel.sessionCheck().then(({ code, data }) => {
          if (data?.user_account_type !== AuthStore.account_type_id) {
            dispatch(stored_auth_info(null));
            props.history.push('/');
          }
          if (code === 401) {
            dispatch(stored_auth_info(null));
            props.history.push('/');
          } else {
            if (permission === 'admin') {
              if (AuthStore.account_type_id !== 5) {
                props.history.push('/');
              } else {
                setCheck(true);
              }
            } else if (permission === 'master') {
              if (AuthStore.account_type_id === 2 || AuthStore.account_type_id === 5) {
                setCheck(true);
              } else {
                props.history.push('/');
              }
            } else if (permission === 'company-manager') {
              if (AuthStore.account_type_id === 5) {
                setCheck(true);
              } else if (AuthStore.account_type_id === 3) {
                // 기업관리자의 본인이외의 회사 url로 접근 차단
                if (Number(params.company_id) === company_reducer.company_id) {
                  setCheck(true);
                } else {
                  getCompanyId().then((company_id) => {
                    if (!company_id) {
                      showAlert({
                        title: lang.company.need_assign_company_account_title,
                        text: lang.company.need_assign_company_account_desc,
                      });
                      CommonModel.signOut().then(({ code }) => {
                        if (code === 200 || code === 401) {
                          // auth, company redux 초기화
                          dispatch(stored_auth_info(null));
                          dispatch(clearCompany());
                          props.history.push('/');
                        } else {
                          alert('처리중 오류가 발생하였습니다.');
                        }
                      });
                    } else {
                      props.history.push('/company/' + company_id);
                      setCheck(true);
                    }
                  });
                }
              }
            } else {
              setCheck(true);
            }
          }
        });
      }
    }, [props.location.pathname, props.location.search]);

    if (check) {
      return <Component {...props} />;
    } else {
      return null;
    }
  }

  return Auth;
}

export default Permission;
