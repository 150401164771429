import moment from 'moment';

// 총 페이지 수 반환
export const getTotalPageCount = (page_array_length, show_count) => {
  const total_page_count = Math.ceil(page_array_length / show_count);
  return { total_page_count };
};

// 페이지 수의 배열 반환
export const getPageArray = (total_page_count) => {
  const page_array = [];
  for (let i = 0; i < total_page_count; i++) {
    page_array.push(i + 1);
  }
  return { page_array };
};

// 한 페이지에서 보여줄 리스트 반환
/**
 *
 * @param array
 * @param show_count
 * @param page_count
 * @param total_page_count
 * @returns {*[]}
 */
export const getShowList = ({
  array = [],
  show_count = null,
  page_count = null,
  total_page_count = null,
}) => {
  let temp_list = [];
  for (let i = 0; i < total_page_count; i++) {
    if (page_count === i + 1) {
      let prev = show_count * i;
      let next = show_count * (i + 1);
      temp_list = array.filter((item, index) => index >= prev && index < next);
    }
  }
  return temp_list;
};

// 아이디값으로 객체배열에서 중복값 제거
export const getDistinctArrayById = (array) => {
  for (let i = 0; i < array.length; i++) {
    for (let j = i + 1; j < array.length; j++) {
      if (array[i].id === array[j].id) {
        if (array[j].status !== undefined) {
          array[i].state = array[j].state;
          array.splice(array.length - 1, 1);
        }
      }
    }
  }
  return array;
};

// 문자열의 길이가 길면 끝을 '...'으로 표현
export const getVisibleString = (str, length) => {
  let str_array = str.split('');
  let tmp_array = [];
  if (str.length >= length) {
    for (let i = 0; i < length - 2; i++) {
      tmp_array.push(str_array[i]);
    }
    tmp_array.push('...');
    return tmp_array.join('');
  } else {
    return str;
  }
};

// 넘겨받은 배열의 전체 소수점 한자리의 % 를 반환
export const getProgressPercent = (current_array, total_array) => {
  return ((current_array.length / total_array.length) * 100).toFixed(1);
};

// 한 직원이 수강중인 전체 강의의 수강률(average_progress)을 반환,
// 전체 직원이 수강중인 한 강의의 수강률(total_progress)를 반환 : 강의내에서 표시할 평균 진도율
export const getTotalProgress = (take_class_array) => {
  let tmp_array = [];
  let final_progress;
  take_class_array.map((one_class) => {
    !one_class.total_progress && tmp_array.push(0);
    one_class.total_progress && tmp_array.push(Number(one_class.total_progress));
    return true;
  });
  final_progress = tmp_array.reduce((prev, cur) => prev + cur);
  return Number((final_progress / take_class_array.length).toFixed(1));
};

// 오늘 시간이 만료일(expire_date)을 지나면 false, 지나기 전이면 true 반환
export const getBooleanDiffDate = (expire_date) => {
  let end = moment(expire_date, 'YYYY-MM-DD hh:mm:ss');
  let start = moment(new Date(), 'YYYY-MM-DD hh:mm:ss');
  return end.diff(start, 'second') >= 1;
};

// return promise
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const insert_at = (array, index, item) => {
  array.splice(index, 0, item);
};

export const storage_get = (key) => {
  let result = null;
  try {
    result = JSON.parse(localStorage.getItem(key));
    if (result.expire_time && result.time + result.expire_time < new Date().getTime()) {
      //expired
      result = null;
    }
  } catch {}
  return result?.value || null;
};

export const storage_set = (key, value, expire_time = null) => {
  let current_time = new Date().getTime();
  localStorage.setItem(
    key,
    JSON.stringify({
      value,
      time: current_time,
      expire_time: expire_time,
    }),
  );
};

export const storage_remove = (key) => {
  localStorage.removeItem(key);
};

// 생년월일에 '-' 자동으로 추가
export const autoHyphenBirthDate = (str) => {
  str = str.replace(/[^0-9]/g, '');
  if (str.length < 5) {
    return str;
  } else if (str.length < 7) {
    return str.substr(0, 4) + '-' + str.substr(4);
  } else {
    return str.substr(0, 4) + '-' + str.substr(4, 2) + '-' + str.substr(6);
  }
};

// 휴대폰에 '-' 자동으로 추가
export const autoHyphenPhoneNumber = (str) => {
  str = str.replace(/[^0-9]/g, '');
  if (str.length < 4) {
    return str;
  } else if (str.length < 7) {
    return str.substr(0, 3) + '-' + str.substr(3);
  } else if (str.length < 11) {
    return str.substr(0, 3) + '-' + str.substr(3, 3) + '-' + str.substr(6);
  } else {
    return str.substr(0, 3) + '-' + str.substr(3, 4) + '-' + str.substr(7);
  }
};

// 배열의 순서 변경 (0 -> 끝 끝 -> 0, 1 -> 끝-1 끝-1 -> 1, ...)
export const getChangeOrderArrayFromStartToEnd = (arr) => {
  let tmp_arr = [...arr];
  for (let i = 0; i < tmp_arr.length / 2; i++) {
    if (tmp_arr[i] !== tmp_arr[tmp_arr.length - (i + 1)]) {
      let tmp = tmp_arr[i];
      tmp_arr[i] = tmp_arr[tmp_arr.length - (i + 1)];
      tmp_arr[tmp_arr.length - (i + 1)] = tmp;
    }
  }

  return tmp_arr;
};
