import { createStore, applyMiddleware, compose } from 'redux';
// middlewares
import thunkMiddleware from 'redux-thunk';
import reducers from '../redux/index';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'bear-u-cms',
  storage,
};

const enhancedReducer = persistReducer(persistConfig, reducers);

export default function ConfigureStore() {
  const store = createStore(
    enhancedReducer,
    compose(
      applyMiddleware(thunkMiddleware),
      window.devToolsExtension
        ? window.devToolsExtension()
        : function (f) {
            return f;
          },
    ),
  );
  const persistor = persistStore(store);
  return { store, persistor };
}
