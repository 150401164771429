import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';

import { Card, CardHeader, Col, Container, Row, Button, Label } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import ImageUploader from '../_common/component/ImageUploader';

import Form from 'reactstrap/es/Form';
import SweetAlert from 'sweetalert2';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import { errMessageOccur } from '../../util/common';
import {
  BANNER_TYPE_MAIN,
  BANNER_TYPE_MYPAGE,
  BANNER_TYPE_SUB_BANNER,
  BANNER_TYPE_TOP_LINE,
  BANNER_TYPE_POP_UP,
} from '../../constants';
import CustomTypeahead from '../_common/component/CustomTypeahead';

registerLocale('ko', ko);

const tooptip_word = {
  home_top: {
    type: '홈-상단 : \n',
    title: '- 홈페이지 큰 배너의 굵은 제목 (~2줄 추천)\n(최대 128자 내외)\n',
    description: '- 홈페이지 큰 배너의 얇은 제목 (줄 바꿈 적용 안함)\n(최대 128자 내외)\n',
    theme_color_hex: '- 배너 이미지 옆쪽의 배경 색깔\n',
  },
  sub_banner: {
    type: '서브 배너 : \n',
    title: '- 이용 안함',
    description: '- 이용 안함',
    theme_color_hex: '- 이용 안함',
  },
};

const obj_err_msg = {
  title: '제목',
  description: '설명',
  redirect_url: '리디렉션URL',
  order_number: '정렬 순서',
};

const regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;

const BannerDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.banner_id));
  const [banner_type_set, setBannerTypeSet] = useState([]);
  const [can_resist_trigger_set, setCanResistTriggerSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [banner, setBanner] = useState({
    id: '',
    title: '',
    description: '',
    theme_color_hex: '',
    accent_color_hex: '',
    redirect_url: '',
    image_url: '',
    thumbnail_image_url: '',
    order_number: '',
    is_enabled: 0,
    banner_type_id: '',
    start_time: '',
    expire_time: '',
    on_air: 0,
    min_width: '',
    min_height: '',
    thumbnail_desc: '',
    trigger_set: [],
  });

  const onInputChange = (e) => {
    if (e.target.name === 'banner_type_id') {
      setBannerMinWidth(e.target.value, true);
    } else {
      setBanner({ ...banner, [e.target.name]: e.target.value });
    }
  };

  const setBannerMinWidth = (banner_type, is_mounted = false) => {
    banner_type = parseInt(banner_type);

    let min_width = null;
    let min_height = null;
    let reset_image = {};
    let thumbnail_desc = null;

    if (banner_type === BANNER_TYPE_MAIN) {
      min_width = 688;
    } else if (banner_type === BANNER_TYPE_SUB_BANNER) {
      min_width = 640;
    } else if (banner_type === BANNER_TYPE_MYPAGE) {
      min_width = 508;
      min_height = 224;
    } else if (banner_type === BANNER_TYPE_TOP_LINE) {
      min_width = 96;
      min_height = 72;
      thumbnail_desc =
        '이미지는 선택적 업로드입니다, 이미지를 삽입하는 경우 표시되는 형태가 달라집니다.';
    } else if (banner_type === BANNER_TYPE_POP_UP) {
      min_width = 368;
      min_height = 368;
    }

    if (is_mounted) {
      reset_image = { image_url: '', thumbnail_image_url: '' };
    }

    setBanner((prevState) => ({
      ...prevState,
      ...reset_image,
      min_width,
      min_height,
      thumbnail_desc,
      banner_type_id: banner_type,
    }));
  };

  const onDateTimeChange = (e, name) => {
    const date = moment(e);
    if (date.year() < 10000) {
      setBanner({ ...banner, [name]: e && date.format('YYYY-MM-DDTHH:mm:ss') });
    }
  };

  const onInputSubmit = async (e) => {
    e.preventDefault();

    const err_msg = errMessageOccur(
      banner,
      obj_err_msg,
      !!Number(props.match.params.banner_id)
        ? { use: false }
        : { use: true, msg: '\n위 항목들의 값이 입력되었는지 다시 한번 확인해주세요.' },
    );

    if (banner.banner_type_id === BANNER_TYPE_TOP_LINE) {
      // 이 타입은 배너 섬네일이 옵셔널이다.
      if (banner.title.length > 20 || banner.description.length > 44) {
        err_msg.push("'제목, 설명'");
      }
    } else {
      if (!!Number(props.match.params.banner_id) && !regex.test(banner.image_url)) {
        err_msg.push("'배너 이미지'");
      }
    }

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      let banner_for_axios = {
        ...banner,
      };
      // banner의 trigger_set 중 combine_name을 제거하여 [id, id...] 로 post 또는 put 하기
      if (banner.trigger_set.length) {
        banner_for_axios = {
          ...banner,
          trigger_set: banner.trigger_set.map((trigger) => trigger.id),
        };
      }

      if (banner_for_axios.id) {
        CommonModel.putBannerDetail(banner_for_axios).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        CommonModel.postBanner(banner_for_axios).then(({ banner_id }) => {
          props.history.replace('/banner/' + banner_id);
          window.location.reload();
        });
      }
    } else {
      err_msg.push('\n위 항목들의 값이 입력되었는지 다시 한번 확인해주세요.');

      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const requestDetail = async () => {
    CommonModel.getBannerDetail(Number(props.match.params.banner_id)).then(({ banner }) => {
      if (banner) {
        setBanner(banner);
        setBannerMinWidth(banner.banner_type_id);
        getCanResistTrigger();
      }
    });
  };

  const getCanResistTrigger = async () => {
    const get_all_trigger_res = await CommonModel.getSearch('trigger');
    if (get_all_trigger_res.length >= 1) {
      setCanResistTriggerSet(get_all_trigger_res);
    }
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'banner_type_set' }).then(({ code_set }) => {
      setBannerTypeSet(code_set);

      if (!Number(props.match.params.banner_id)) {
        setBanner({ ...banner, banner_type_id: code_set[0]?.id });
        getCanResistTrigger();
      }
    });
    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent='배너'
        title={`배너 ${props.match.params.banner_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form
              onSubmit={(e) => {
                onInputSubmit(e);
              }}
            >
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`배너 ${props.match.params.banner_id === '0' ? '생성' : '정보'}`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      {
                        <>
                          <Button
                            className='btn-pill'
                            color='primary'
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              // 생성(0)일 경우 수정(0 이상)일 경우
                              if (!!Number(props.match.params.banner_id)) {
                                if (isEdit) {
                                  CommonModel.getBannerDetail(
                                    Number(props.match.params.banner_id),
                                  ).then(({ banner }) => {
                                    if (banner) {
                                      setBanner(banner);
                                      setBannerMinWidth(banner.banner_type_id);
                                    }
                                  });
                                }
                                setIsEdit(!isEdit);
                              } else {
                                window.location.href = '/banner';
                              }
                            }}
                          >
                            {isEdit ? <>취소</> : <>편집</>}
                          </Button>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col
                      md={3}
                      style={{ minWidth: '360px', maxHeight: '230px', alignSelf: 'center' }}
                    >
                      <div className='blog-box text-center'>
                        <ImageUploader
                          id={Number(props.match.params.banner_id)}
                          name='image_url'
                          withPreview={true}
                          img_type={'banner'}
                          // 기본배너 - 1200 크기, 서브배너 - 640 크기
                          // 기본배너 - 500 크기,  서브배너 - 640 크기
                          min_width={banner.min_width ? banner.min_width : null}
                          min_height={banner.min_height ? banner.min_height : null}
                          thumbnail_width={
                            banner?.banner_type_id && parseInt(banner?.banner_type_id) === 1
                              ? 500
                              : parseInt(banner?.banner_type_id) === 2
                                ? 640
                                : 320
                          }
                          setPropsState={setBanner}
                          img_url={regex.test(banner.image_url) && banner.image_url}
                          title={banner.title}
                          isEdit={!!Number(props.match.params.banner_id) ? isEdit : false}
                          imgExtension={['.jpg', '.gif', '.png', '.gif', '.svg']}
                          height={230}
                        />
                        {!!Number(props.match.params.banner_id) && banner.min_width && (
                          <div>
                            <b>업로드 최소 너비</b> : {`${banner.min_width} px`}
                          </div>
                        )}
                        {!!Number(props.match.params.banner_id) && banner.min_height && (
                          <div>
                            <b>업로드 최소 높이</b> : {`${banner.min_height} px`}
                          </div>
                        )}
                        {!!Number(props.match.params.banner_id) && banner.thumbnail_desc && (
                          <div>
                            <b>{banner.thumbnail_desc}</b>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col md='5 mb-3'>
                          <CustomInput
                            type={'textarea'}
                            name={'title'}
                            disabled={!isEdit}
                            value={banner.title || ''}
                            is_required={true}
                            label={'제목'}
                            tooltip_desc={
                              tooptip_word.home_top.type +
                              tooptip_word.home_top.title +
                              '\n' +
                              tooptip_word.sub_banner.type +
                              tooptip_word.sub_banner.title
                            }
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 128;
                            }}
                          />
                        </Col>
                        <Col md='7 mb-3'>
                          <CustomInput
                            type={'textarea'}
                            name={'description'}
                            disabled={!isEdit}
                            value={banner.description || ''}
                            is_required={true}
                            label={'설명'}
                            tooltip_desc={
                              tooptip_word.home_top.type +
                              tooptip_word.home_top.description +
                              '\n' +
                              tooptip_word.sub_banner.type +
                              tooptip_word.sub_banner.description
                            }
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 128;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <CustomInput
                            type={'url'}
                            name={'redirect_url'}
                            disabled={!isEdit}
                            value={banner.redirect_url || ''}
                            is_required={true}
                            label={'리디렉션URL'}
                            tooltip_desc={
                              '설정한 배너를 클릭 시,\n해당 페이지로 현재 창에서 url이 열림'
                            }
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 512;
                            }}
                          />
                        </Col>
                        <Col md='1 mb-3' style={{ minWidth: '120px' }}>
                          <CustomInput
                            type={'color'}
                            name={'theme_color_hex'}
                            disabled={!isEdit}
                            value={banner.theme_color_hex || '#000000'}
                            is_required={true}
                            label={'테마컬러'}
                            tooltip_desc={
                              tooptip_word.home_top.type +
                              tooptip_word.home_top.theme_color_hex +
                              '\n' +
                              tooptip_word.sub_banner.type +
                              tooptip_word.sub_banner.theme_color_hex
                            }
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='1 mb-3' style={{ minWidth: '120px' }}>
                          <CustomInput
                            type={'color'}
                            name={'accent_color_hex'}
                            disabled={!isEdit}
                            value={banner.accent_color_hex || '#FFFFFF'}
                            label={'강조컬러'}
                            tooltip_desc={'강조 컬러입니다. 텍스트 색상등에 사용 될 수 있습니다.'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='1 mb-3' style={{ minWidth: '120px' }}>
                          <CustomInput
                            type={'number'}
                            name={'order_number'}
                            disabled={!isEdit}
                            value={String(banner.order_number) || ''}
                            is_required={true}
                            label={'정렬순서'}
                            tooltip_desc={'높을수록 상단 배치'}
                            onChange={onInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'banner_type_id'}
                            disabled={!isEdit}
                            value={banner.banner_type_id}
                            label={'배너위치'}
                            is_required={true}
                            sub_option_type={'description'}
                            onChange={onInputChange}
                            options={banner_type_set}
                          />
                        </Col>
                        <Col md='2 mb-3'>
                          <CustomSelectInput
                            name={'is_enabled'}
                            disabled={!!!Number(props.match.params.banner_id) || !isEdit}
                            value={banner.is_enabled ? banner.is_enabled : '0'}
                            tooltip_desc={'저장 후 변경 가능'}
                            label={'상태'}
                            is_required={true}
                            onChange={onInputChange}
                            options={[
                              {
                                id: '0',
                                name: '비활성화',
                              },
                              {
                                id: '1',
                                name: '활성화',
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Row>
                            <Col md={12}>
                              <Label htmlFor='start_time'>
                                시작일 (입력 없는 경우 바로 시작됨)
                              </Label>
                              <DatePicker
                                autoComplete='off'
                                className='form-control digits'
                                name='start_time'
                                selectsStart
                                disabled={!isEdit}
                                onChange={(e) => {
                                  onDateTimeChange(e, 'start_time');
                                }}
                                locale='ko'
                                selected={banner.start_time ? new Date(banner.start_time) : null}
                                startDate={banner.start_time ? new Date(banner.start_time) : null}
                                endDate={banner.expire_time ? new Date(banner.expire_time) : null}
                                maxDate={banner.expire_time ? new Date(banner.expire_time) : null}
                                timeFormat='HH:mm:ss'
                                showTimeSelect
                                dateFormat='yyyy-MM-dd HH:mm:ss'
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6}>
                          <Row>
                            <Col md={12}>
                              <Label htmlFor='expire_time'>
                                종료일 (입력 없는 경우 계속 지속됨)
                              </Label>
                              <DatePicker
                                autoComplete='off'
                                className='form-control digits'
                                name='expire_time'
                                selectsEnd
                                disabled={!isEdit}
                                onChange={(e) => {
                                  onDateTimeChange(e, 'expire_time');
                                }}
                                locale='ko'
                                selected={banner.expire_time ? new Date(banner.expire_time) : null}
                                startDate={banner.start_time ? new Date(banner.start_time) : null}
                                endDate={banner.expire_time ? new Date(banner.expire_time) : null}
                                minDate={banner.start_time ? new Date(banner.start_time) : null}
                                timeFormat='HH:mm:ss'
                                showTimeSelect
                                dateFormat='yyyy-MM-dd HH:mm:ss'
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} style={{ marginTop: '40px' }}>
                      <CustomTypeahead
                        disabled={!isEdit}
                        is_require={false}
                        multiple={true}
                        label={'해당 배너의 참조 트리거'}
                        placeholder={'해당 배너가 보여지는 조건의 트리거를 선택해주세요.'}
                        tooltip_desc={
                          '✅ 선택한 트리거를 조건으로 해당 배너를 띄워줍니다. \n' +
                          ' 트리거 목록에서 원하는 조건의 트리거를 확인 후 선택해주세요.\n' +
                          ' 트리거의 조건은 특정 페이지의 주소를 비교하여 트리거의 조건에 부합한다면 배너를 띄워줍니다.'
                        }
                        selected={banner.trigger_set}
                        options={can_resist_trigger_set}
                        labelKey={'combine_name'}
                        selectedHandler={(selected) => {
                          setBanner({
                            ...banner,
                            trigger_set: selected,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BannerDetail;
