import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { Button, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { set_course_info } from '../../../redux/course/info/action';
import { set_course_subject } from '../../../redux/course/subject/action';
import { set_delete_img } from '../../../redux/common/action';
import { set_ebook_info } from '../../../redux/ebook/info/action';

const ImageUploader = (props) => {
  const [style, setStyle] = useState({});
  const {
    img_type,
    sm,
    img_url,
    img_thumb_url,
    isEdit,
    height,
    id,
    target_index,
    thumbnail_width,
    min_width,
    min_height,
  } = props;

  useEffect(() => {
    if (img_type === 'banner') {
      setStyle({
        maxHeight: '230px',
      });
    } else if (img_type === 'course') {
      setStyle({
        height: height,
        borderRadius: '20px',
        width: '100%',
      });
    } else if (img_type === 'ebook') {
      setStyle({
        height: height,
        borderRadius: '20px',
        width: '100%',
      });
    } else if (img_type === 'circle_image_course') {
      setStyle({
        height: 210,
        borderRadius: '20px',
        width: 210,
      });
    } else if (img_type === 'promotion_banner') {
      setStyle({
        maxHeight: '230px',
      });
    } else if (img_type === 'notice') {
      setStyle({
        maxHeight: '300px',
      });
    } else if (img_type === 'product') {
      setStyle({
        height: height,
        borderRadius: '20px',
        width: '100%',
      });
    } else if (img_type === 'circle_image_product') {
      setStyle({
        height: 210,
        borderRadius: '20px',
        width: 210,
      });
    } else if (img_type === 'kit') {
      setStyle({
        height: height,
        borderRadius: '20px',
        width: '100%',
      });
    } else if (img_type === 'circle_image_kit') {
      setStyle({
        height: 210,
        borderRadius: '20px',
        width: 210,
      });
    }
  }, []);

  const getUploadParams = async ({ meta, file }) => {
    if (min_width && min_width > meta.width) {
      window.alert(`width가 ${min_width}px 보다 큰 이미지가 업로드되어야 합니다.`);
      return false;
    }
    if (min_height && min_height > meta.height) {
      window.alert(`height가 ${min_height}px 보다 큰 이미지가 업로드되어야 합니다.`);
      return false;
    }

    let body = new FormData();
    body.append('id', id);
    body.append('img', file);
    body.append('img_type', img_type);
    body.append('thumbnail_width', thumbnail_width);
    return {
      url: process.env.REACT_APP_API_URL + '/cms-api/v1/common/img',
      body: body,
    };
  };

  const handleChangeStatus = ({ xhr }) => {
    const { img_type } = props;
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          if (img_type === 'banner') {
            props.setPropsState((prevState) => ({
              ...prevState,
              image_url: result.data.img_url,
              thumbnail_image_url: result.data.thumbnail_img_url,
            }));
          } else if (img_type === 'course') {
            props.set_course_info('main_image_url', result.data.img_url);
            props.set_course_info('main_thumbnail_image_url', result.data.thumbnail_img_url);
          } else if (img_type === 'ebook') {
            props.set_ebook_info('main_image_url', result.data.img_url);
            props.set_ebook_info('main_thumbnail_image_url', result.data.thumbnail_img_url);
          } else if (img_type === 'circle_image_course') {
            props.set_course_info('circle_image_url', result.data.img_url);
          } else if (img_type === 'promotion_banner') {
            props.setPropsState((prevState) => ({
              ...prevState,
              banner_image_url: result.data.img_url,
              banner_thumbnail_image_url: result.data.thumbnail_img_url,
            }));
          } else if (img_type === 'notice') {
            props.setPropsState((prevState) => ({
              ...prevState,
              thumbnail_image_url: result.data.thumbnail_img_url,
            }));
          } else if (img_type === 'product') {
            props.setPropsState((prevState) => ({
              ...prevState,
              main_image_url: result.data.img_url,
              main_thumbnail_image_url: result.data.thumbnail_img_url,
            }));
          } else if (img_type === 'circle_image_product') {
            props.setPropsState((prevState) => ({
              ...prevState,
              circle_image_url: result.data.img_url,
            }));
          } else if (img_type === 'kit') {
            props.setPropsState((prevState) => ({
              ...prevState,
              main_image_url: result.data.img_url,
              main_thumbnail_image_url: result.data.thumbnail_img_url,
            }));
          } else if (img_type === 'circle_image_kit') {
            props.setPropsState((prevState) => ({
              ...prevState,
              circle_image_url: result.data.img_url,
            }));
          } else if (img_type === 'event') {
            props.setPropsState((prevState) => ({
              ...prevState,
              main_image_url: result.data.img_url,
              main_thumbnail_image_url: result.data.thumbnail_img_url,
            }));
          } else if (img_type === 'subject') {
            props.set_course_subject(target_index, 'image_url', result.data.img_url);
            props.set_course_subject(
              target_index,
              'thumbnail_image_url',
              result.data.thumbnail_img_url,
            );
          }
        }
      };
    }
  };

  let btn_compo;

  if (isEdit) {
    btn_compo = (
      <Button
        className='btn btn-xs'
        style={{ position: 'absolute', right: '30px', top: '20px' }}
        color='danger'
        onClick={() => {
          if (img_type === 'banner') {
            props.setPropsState((prevState) => ({
              ...prevState,
              image_url: '',
              thumbnail_image_url: '',
            }));
          } else if (img_type === 'course') {
            props.set_course_info('main_image_url', null);
            props.set_course_info('main_thumbnail_image_url', null);
            props.set_delete_img('course', img_url);
            props.set_delete_img('course', img_thumb_url);
          } else if (img_type === 'ebook') {
            props.set_ebook_info('main_image_url', null);
            props.set_ebook_info('main_thumbnail_image_url', null);
          } else if (img_type === 'circle_image_course') {
            props.set_course_info('circle_image_url', null);
            // 어떤 이미지?
            props.set_delete_img('circle_image_course', img_url);
          } else if (img_type === 'promotion_banner') {
            props.setPropsState((prevState) => ({
              ...prevState,
              banner_image_url: '',
              banner_thumbnail_image_url: '',
            }));
          } else if (img_type === 'notice') {
            props.setPropsState((prevState) => ({
              ...prevState,
              thumbnail_image_url: '',
            }));
          } else if (img_type === 'product') {
            props.setPropsState((prevState) => ({
              ...prevState,
              main_image_url: '',
              main_thumbnail_image_url: '',
            }));
          } else if (img_type === 'circle_image_product') {
            props.setPropsState((prevState) => ({
              ...prevState,
              circle_image_url: '',
            }));
          } else if (img_type === 'kit') {
            props.setPropsState((prevState) => ({
              ...prevState,
              main_image_url: '',
              main_thumbnail_image_url: '',
            }));
          } else if (img_type === 'circle_image_kit') {
            props.setPropsState((prevState) => ({
              ...prevState,
              circle_image_url: '',
            }));
          } else if (img_type === 'event') {
            props.setPropsState((prevState) => ({
              ...prevState,
              main_image_url: '',
              main_thumbnail_image_url: '',
            }));
          } else if (img_type === 'subject') {
            props.set_course_subject(target_index, 'image_url', null);
            props.set_course_subject(target_index, 'thumbnail_image_url', null);
            props.set_delete_img('subject', img_url);
            props.set_delete_img('subject', img_thumb_url);
          }
        }}
      >
        삭제
      </Button>
    );
  }
  return (
    <Col sm={sm}>
      <div className='dz-message needsclick'>
        {img_url ? (
          <div style={{ position: 'relative' }}>
            <img alt='' src={img_url} style={style} className='img-fluid bottom-radius-blog' />
            {btn_compo}
          </div>
        ) : (
          <Dropzone
            accept='image/jpeg, image/png, image/jpg, image/gif'
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            maxFiles={1}
            multiple={false}
            inputContent={
              !props.isEdit
                ? '기본 정보를 저장하셔야\n이미지 업로드가 가능합니다.'
                : img_type === 'ebook'
                  ? '이미지 업로드\n(가로 1200px,\n세로 1730px 권장)'
                  : '이미지 업로드'
            }
            disabled={!props.isEdit}
            styles={{
              dropzone: { height: height },
              dropzoneActive: { borderColor: 'green' },
            }}
          />
        )}
      </div>
    </Col>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_course_info: (name, value) => dispatch(set_course_info(name, value)),
    set_course_subject: (subject_index, name, value) =>
      dispatch(set_course_subject(subject_index, name, value)),
    set_delete_img: (img_type, url) => dispatch(set_delete_img(img_type, url)),

    set_ebook_info: (name, value) => dispatch(set_ebook_info(name, value)),
  };
};
export default connect(null, mapDispatchToProps)(ImageUploader);
