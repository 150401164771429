import axios from 'axios';

class CompanyModel {
  async postCompany({ name, manager_account_name_set, licensed_employee_count }) {
    const { data } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/company', {
      name,
      manager_account_name_set,
      licensed_employee_count,
    });
    return data;
  }

  async getSearchCompanyManagerAccounts(account_name) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/company/account/search',
      {
        params: {
          account_name: account_name,
        },
      },
    );
    return data;
  }

  async getCompanyList() {
    const { data } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/company');
    return data;
  }

  async getCompanyDetail(company_id) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}`,
    );
    return data;
  }

  async updateCompanyInfo(company_id, { manager_account_name_set, licensed_employee_count }) {
    const { data } = await axios.put(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}`,
      {
        manager_account_name_set,
        licensed_employee_count,
      },
    );
    return data;
  }

  async getCompanyGraph(company_id) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}/graph`,
    );
    return data;
  }

  async getEmployeeList(company_id) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}/employee`,
    );
    return data;
  }

  async postEmployee(company_id, { name, account_name, password, gender, birth, phone }) {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}/employee`,
      {
        name,
        account_name,
        password,
        gender,
        birth,
        phone,
      },
    );
    return data;
  }

  async getDuplicateEmployeeCheck(account_name) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/employee`,
      {
        params: {
          account_name: account_name,
        },
      },
    );
    return data;
  }

  async getEmployeeCSV(company_id) {
    return await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/csv/${company_id}`,
      {
        responseType: 'blob',
      },
    );
  }

  async updateEmployeeStatus(company_id, employee_id, { status_id }) {
    const { data } = await axios.put(
      process.env.REACT_APP_API_URL +
        `/cms-api/v1/company/${company_id}/employee/${employee_id}/status`,
      {
        status_id: status_id,
      },
    );
    return data;
  }

  async getCompanyCourse(company_id) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}/course`,
    );
    return data;
  }

  async getCompanyPackage(company_id) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}/product`,
    );
    return data;
  }

  async postCourses(company_id, { course_set, rental_time, rental_expire_time }) {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}/course`,
      {
        course_set,
        rental_time,
        rental_expire_time,
      },
    );
    return data;
  }

  async postProducts(company_id, { product_set, rental_time, rental_expire_time }) {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}/product`,
      {
        product_set,
        rental_time,
        rental_expire_time,
      },
    );
    return data;
  }

  async updateCourseDate(company_id, { course_id, rental_time, rental_expire_time }) {
    const { data } = await axios.put(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}/course`,
      {
        course_id,
        rental_time,
        rental_expire_time,
      },
    );
    return data;
  }

  async updateProductDate(company_id, { product_id, rental_time, rental_expire_time }) {
    const { data } = await axios.put(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}/product`,
      {
        product_id,
        rental_time,
        rental_expire_time,
      },
    );
    return data;
  }

  async getCourseByProduct(company_id, product_id) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}/product/${product_id}`,
    );
    return data;
  }

  async getEmployeeDetail(company_id, employee_id) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}/employee/${employee_id}`,
    );
    return data;
  }

  async updateEmployeeDetail(
    company_id,
    employee_id,
    { account_name, name, gender, birth, phone, status_id },
  ) {
    const { data } = await axios.put(
      process.env.REACT_APP_API_URL + `/cms-api/v1/company/${company_id}/employee/${employee_id}`,
      {
        account_name,
        name,
        gender,
        birth,
        phone,
        status_id,
      },
    );
    return data;
  }

  async updateEmployeePassword(company_id, employee_id, { new_password }) {
    const { data } = await axios.put(
      process.env.REACT_APP_API_URL +
        `/cms-api/v1/company/${company_id}/employee/${employee_id}/password`,
      {
        new_password,
      },
    );
    return data;
  }

  async initializationEmployeePassword(company_id, employee_id) {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL +
        `/cms-api/v1/company/${company_id}/employee/${employee_id}/password`,
    );
    return data;
  }

  async getEmployeeCourses(company_id, employee_id) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL +
        `/cms-api/v1/company/${company_id}/employee/${employee_id}/course`,
    );
    return data;
  }

  async getSubjectDetail(company_id, employee_id, course_id) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL +
        `/cms-api/v1/company/${company_id}/employee/${employee_id}/graph?type=vod_log&course_id=${course_id}`,
    );
    return data;
  }
}
export default new CompanyModel();
