import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-bearu';
import '@ckeditor/ckeditor5-build-classic/build/translations/ko';
import axios from 'axios';

// toolbar
// https://ckeditor.com/docs/ckeditor5/latest/api/module_core_editor_editorconfig-EditorConfig.html#member-toolbar

const default_toolbar = {
  items: [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    'alignment',
    '|',
    'outdent',
    'indent',
    '|',
    'imageUpload',
    'blockQuote',
    'insertTable',
    'mediaEmbed',
    '|',
    'undo',
    'redo',
  ],
};
// export const TYPE_DETAIL_PAGE = 1;

const BgdTextEditor = (props) => {
  const id = props.id;
  const img_type = props.img_type;
  const [value, setValue] = useState(props.html_data);

  useEffect(() => {
    setValue(props.html_data);
  }, [props.html_data]);

  const CustomUploadAdapterPlugin = (editor) => {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      new UploadAdapter(loader, id, img_type);
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      disabled={props.disabled}
      data={value}
      config={{
        extraPlugins: [CustomUploadAdapterPlugin],
        image: {
          styles: ['inline'],
          toolbar: ['imageStyle:inline'],
          insert: {
            type: 'inline',
          },
        },
        toolbar: {
          items: props.items || default_toolbar.items,
        },
        mediaEmbed: {
          previewsInData: true,
        },
        language: 'ko',
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        setValue(data);
        props.onChange(data);
      }}
    />
  );
};

class UploadAdapter {
  constructor(loader, id, img_type) {
    this.id = id;
    this.img_type = img_type;
    this.loader = loader;
    this.loader.file.then((pic) => (this.file = pic));
  }

  upload() {
    const form_data = new FormData();
    form_data.append('file', this.file);
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/v1/common/file`, form_data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (e) => {
            console.log(Math.round((e.loaded / e.total) * 100) + ' %');
          },
        })
        .then((response) => {
          if (response) {
            console.log(response);
            resolve({
              default: response.data[0].file_path,
            });
          }
        })
        .catch((error) => {
          reject('Server Error');
          console.log('Server Error : ', error);
        });
    });
  }
}

export default BgdTextEditor;
