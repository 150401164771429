import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import SweetAlert from 'sweetalert2';
import DatePicker from 'react-datepicker';

import {
  stored_course_info,
  set_course_info,
  set_kit,
  set_sub_category,
  clear_course_info,
  set_course_body,
} from '../../../redux/course/info/action';
import { clear_delete_img } from '../../../redux/common/action';
import CourseModel from '../../../model/CourseModel';
import CommonModel from '../../../model/CommonModel';

import TagInput from '../atom/TagInput';
import ImageUploader from '../../_common/component/ImageUploader';
import TextEditor from '../../_common/component/TextEditor';
import CustomInput from '../../_common/component/CustomInput';
import CustomSelectInput from '../../_common/component/CustomSelectInput';
import CustomFileInput from '../../_common/component/CustomFileInput';
import CustomTypeahead from '../../_common/component/CustomTypeahead';
import Loading from '../../_common/Loading';
import CustomLabel from '../../_common/component/CustomLabel';
import { errMessageOccur } from '../../../util/common';

const Info = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isCopyMode, setIsCopyMode] = useState(false);

  // select option set
  const [category_set, setCategorySet] = useState([]);
  const [sale_status_set, setSaleStatusSet] = useState([]);

  //single typeahead (강사, 판매항목)
  const [teacher_set, setTeacherSet] = useState([]);
  const [teacher_select, setTeacherSelect] = useState([]);
  const [course_sale_set, setCourseSaleSet] = useState([]);
  const [course_sale_select, setCourseSaleSelect] = useState([]);

  //multiple typeahead (카테고리, 키트)
  const [kit_set, setKitSet] = useState([]);
  const [kit_select, setKitSelect] = useState([]);

  const initData = () => {
    setIsLoading(true);
    CourseModel.getCourse(props.course_id).then(({ course }) => {
      props.stored_course_info(course);
      props.clear_delete_img('course_info_img');

      setKitSelect(course.kit_set);

      if (course.extend_sale_info) setCourseSaleSelect([course.sale_short_name]);
      else setCourseSaleSelect([]);
      if (course.teacher_account) setTeacherSelect([course.teacher_profile_name]);
      else setTeacherSelect([]);
    });
    CommonModel.getSearch('course_sale', props.course_id).then((data) => {
      setCourseSaleSet(data);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    CommonModel.staticCode({ code_name: 'course_category_set' }).then(({ code_set }) => {
      setCategorySet(code_set);
    });
    CommonModel.staticCode({ code_name: 'course_sale_status_set' }).then(({ code_set }) => {
      setSaleStatusSet(code_set);
    });
    CommonModel.getSearch('kit').then((data) => {
      setKitSet(data);
    });
    CommonModel.getSearch('teacher').then((data) => {
      setTeacherSet(data);
    });
    if (parseInt(props.course_id)) {
      initData();
    } else {
      setIsEdit(true);
      setIsLoading(false);
    }

    return () => {
      props.clear_course_info();
      props.clear_delete_img('course_info_img');
    };
  }, []);

  const CourseInfo = props.CourseInfo;

  const validation_fields = {
    title: '클래스명',
    description: '설명',
    short_title: '짧은클래스명',
    teacher_name: '강사명',
  };

  const saveInfo = async (e) => {
    setIsLoading(true);

    const err_msg = errMessageOccur(CourseInfo, validation_fields, { use: false });
    let sale_status_error = false;
    if (!teacher_select?.length) {
      err_msg.push("'강사 계정 설정'");
    }

    if (CourseInfo.id && !isCopyMode) {
      if (!CourseInfo.body) {
        err_msg.push("'콘텐츠 영역'");
      }
      if (!CourseInfo.main_image_url) {
        err_msg.push("'큰 메인 이미지'");
      }

      if (!CourseInfo.circle_image_url) {
        err_msg.push("'작은 써클 이미지'");
      }

      if (!err_msg.length) {
        const course = await CourseModel.putCourse(CourseInfo);
        if (course.code === 4600) {
          sale_status_error = true;
        }
      }
    } else {
      if (!err_msg.length) {
        try {
          const res = await CourseModel.postCourse({
            title: CourseInfo.title,
            short_title: CourseInfo.short_title,
            description: CourseInfo.description,
            theme_color_hex: CourseInfo.theme_color_hex,
            course_sale_status: CourseInfo.course_sale_status,
            hide_in_list: CourseInfo.hide_in_list,
            is_require_with_kit: CourseInfo.is_require_with_kit,
            only_single_kit: CourseInfo.only_single_kit,
            teacher_name: CourseInfo.teacher_name,
            teacher_account: CourseInfo.teacher_account,
            category: CourseInfo.category,
          });

          if (isCopyMode && !!res.course_id) {
            const newCourseId = res.course_id;

            const { sale_set } = await CourseModel.getCourseSale(props.course_id);

            const sale_set_without_id = sale_set.map(({ id, ...rest }) => rest);
            await CourseModel.postCourseSale(newCourseId, sale_set_without_id);

            const originalCourse = await CourseModel.getCourse(props.course_id);
            const newCourse = await CourseModel.getCourse(newCourseId);
            const copiedCourseInfo = {
              ...newCourse.course,
              id: newCourseId,
              main_image_url: CourseInfo.main_image_url || originalCourse.course.main_image_url,
              main_thumbnail_image_url:
                CourseInfo.main_thumbnail_image_url ||
                originalCourse.course.main_thumbnail_image_url,
              circle_image_url:
                CourseInfo.circle_image_url || originalCourse.course.circle_image_url,
              contract_expire_date:
                CourseInfo.contract_expire_date || originalCourse.course.contract_expire_date,
              to_be_open_date: CourseInfo.to_be_open_date || originalCourse.course.to_be_open_date,
              extend_sale_info:
                CourseInfo.extend_sale_info || originalCourse.course.extend_sale_info,
              reservation_url: CourseInfo.reservation_url || originalCourse.course.reservation_url,
              override_detail_redirect_url:
                CourseInfo.override_detail_redirect_url ||
                originalCourse.course.override_detail_redirect_url,
              document_url: CourseInfo.document_url || originalCourse.course.document_url,
              master_sign_image_url:
                CourseInfo.master_sign_image_url || originalCourse.course.master_sign_image_url,
              youtube_id: CourseInfo.youtube_id || originalCourse.course.youtube_id,
              sale_status_update_time:
                CourseInfo.sale_status_update_time || originalCourse.course.sale_status_update_time,
              sale_comment: CourseInfo.sale_comment || originalCourse.course.sale_comment,
              total_running_sec:
                CourseInfo.total_running_sec || originalCourse.course.total_running_sec,
              cannot_use_coupon:
                CourseInfo.cannot_use_coupon || originalCourse.course.cannot_use_coupon,
              can_trigger_course_completed:
                CourseInfo.can_trigger_course_completed ||
                originalCourse.course.can_trigger_course_completed,
              badge_type: CourseInfo.badge_type || originalCourse.course.badge_type,
              tag_set:
                (CourseInfo.tag_set && CourseInfo.tag_set.map((tag) => ({ name: tag.name }))) ||
                originalCourse.course.tag_set.map((tag) => ({ name: tag.name })),
              sub_category_set:
                CourseInfo.sub_category_set || originalCourse.course.sub_category_set,
              kit_set: CourseInfo.kit_set || originalCourse.course.kit_set,
              body: CourseInfo.body || originalCourse.course.body,
              sale_short_name: CourseInfo.sale_short_name || originalCourse.course.sale_short_name,
            };

            await CourseModel.putCourse(copiedCourseInfo);

            const { subject_set } = await CourseModel.getCourseSubject(props.course_id);
            const copiedSubjectSet = subject_set.map((subject) => ({
              ...subject,
              id: null,
            }));
            await CourseModel.postCourseSubject(newCourseId, copiedSubjectSet);

            alert('강의가 복사되었습니다.');
          }

          window.location.reload();
        } catch (error) {
          console.log(error);
        }
      }
    }
    if (err_msg.length) {
      SweetAlert.fire({
        title: '아래 입력 값을 확인해주세요.',
        text: err_msg.join('\n'),
      });
    } else {
      setIsEdit(false);
    }
    if (sale_status_error) {
      setIsEdit(true);
      SweetAlert.fire({
        title: '저장을 실패하였습니다.',
        text: '판매설정을 최소 1개 이상 생성 후 강의 상태를 판매중으로 변경해주세요.',
      });
    }
    setIsLoading(false);
  };

  const copyCourse = () => {
    const copiedCourseInfo = {
      ...CourseInfo,
      id: null,
      title: `${CourseInfo.title} - 복사본`,
    };
    props.stored_course_info(copiedCourseInfo);
    setIsCopyMode(true);
    setIsEdit(true);
  };

  return (
    <Row>
      <Col lg='12 xl-100'>
        <Card className='input-air-primary'>
          <CardHeader>
            <Row>
              <Col>
                <h4>
                  강의 정보
                  <Loading isLoading={isLoading} />
                </h4>
              </Col>
              <Col sm={6} className='text-right'>
                <Col>
                  {isEdit && (
                    <>
                      <Button
                        className='btn-pill'
                        color='primary'
                        style={{ marginRight: '8px' }}
                        onClick={saveInfo}
                      >
                        저장
                      </Button>
                    </>
                  )}
                  {CourseInfo.id && isEdit && (
                    <Button
                      className={isEdit ? 'btn-pill btn btn-light' : 'btn-pill'}
                      color={isEdit ? '' : 'primary'}
                      style={{ marginRight: '8px' }}
                      onClick={() => {
                        if (isEdit) {
                          initData();
                        }
                        setIsEdit(!isEdit);
                      }}
                    >
                      취소
                    </Button>
                  )}
                  {CourseInfo.id && !isEdit && (
                    <>
                      <Button
                        className={isEdit ? 'btn-pill btn btn-light' : 'btn-pill'}
                        color={isEdit ? '' : 'primary'}
                        style={{ marginRight: '8px' }}
                        onClick={() => {
                          if (isEdit) {
                            initData();
                          }
                          setIsEdit(!isEdit);
                        }}
                      >
                        편집
                      </Button>
                      <Button
                        className={isEdit ? 'btn-pill btn btn-light' : 'btn-pill'}
                        color={isEdit ? '' : 'primary'}
                        style={{ marginRight: '8px' }}
                        onClick={copyCourse}
                      >
                        강의 복사
                      </Button>
                    </>
                  )}
                </Col>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row style={{ marginTop: '-10px' }}>
              <ImageUploader
                sm={3}
                img_type='course'
                thumbnail_width={500}
                min_width={1200}
                img_url={CourseInfo.main_image_url}
                img_thumb_url={CourseInfo.main_thumbnail_image_url}
                isEdit={!!Number(props.course_id) ? isEdit : false}
                id={Number(props.course_id)}
                height={250}
              />
              <ImageUploader
                sm={2}
                img_type='circle_image_course'
                thumbnail_width={210}
                min_width={210}
                img_url={CourseInfo.circle_image_url}
                img_thumb_url={CourseInfo.circle_image_url}
                isEdit={!!Number(props.course_id) ? isEdit : false}
                id={Number(props.course_id)}
                height={210}
              />
              <Col>
                <Row>
                  <Col sm={2}>
                    <CustomInput
                      type={'color'}
                      name={'theme_color_hex'}
                      disabled={!isEdit}
                      value={CourseInfo.theme_color_hex}
                      label={'테마컬러'}
                      is_required={true}
                      onChange={(e) => props.set_course_info(e.target.name, e.target.value)}
                    />
                  </Col>
                  <Col>
                    <CustomSelectInput
                      name={'course_sale_status'}
                      disabled={!parseInt(props.course_id) || !isEdit}
                      value={CourseInfo.course_sale_status || 1}
                      label={'판매 상태'}
                      is_required={true}
                      sub_option_type={'description'}
                      onChange={(e) => props.set_course_info(e.target.name, e.target.value)}
                      options={sale_status_set}
                    />
                  </Col>
                  <Col sm={2}>
                    <CustomSelectInput
                      name={'hide_in_list'}
                      disabled={!isEdit}
                      value={CourseInfo.hide_in_list || 0}
                      label={'리스트 숨김'}
                      is_required={true}
                      tooltip_desc={
                        '"예"로 설정하는 경우 각종 리스트에서 표시되지 않습니다. 유저가 보유하여 소유권을 주장할수 있는 리스트에서는 "예"로 설정하여도 표시됩니다. (기본값: 아니요)'
                      }
                      onChange={(e) => props.set_course_info(e.target.name, e.target.value)}
                      options={[
                        { name: '예', id: 1 },
                        { name: '아니오', id: 0 },
                      ]}
                    />
                  </Col>
                  <Col sm={2}>
                    <CustomSelectInput
                      name={'is_require_with_kit'}
                      disabled={!isEdit}
                      value={CourseInfo.is_require_with_kit || 0}
                      label={'키트 필수'}
                      is_required={true}
                      tooltip_desc={
                        '"예"로 설정하는 경우 (기본값: 아니요) \n구매시 키트를 최소 1개 이상 선택되도록 설정합니다. '
                      }
                      onChange={(e) => props.set_course_info(e.target.name, e.target.value)}
                      options={[
                        { name: '예', id: 1 },
                        { name: '아니오', id: 0 },
                      ]}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col sm={3}>
                    <CustomInput
                      type={'text'}
                      name={'teacher_name'}
                      disabled={!isEdit}
                      value={CourseInfo.teacher_name || ''}
                      label={'강사명'}
                      placeholder={'해당 강의에 강사명을 입력해주세요.'}
                      is_required={true}
                      onChange={(e) => props.set_course_info(e.target.name, e.target.value)}
                    />
                  </Col>
                  <Col sm={3}>
                    <CustomTypeahead
                      disabled={!isEdit}
                      is_required={true}
                      multiple={false}
                      label={'강사 계정 설정'}
                      tooltip_desc={'강사로 등록된 회원을 검색 후 선택해주세요.'}
                      selected={teacher_select}
                      options={teacher_set}
                      labelKey={'teacher_profile_name'}
                      placeholder={'강사를 검색해주세요.'}
                      onChange={(selected) => {
                        setTeacherSelect(selected);
                        if (selected.length > 0) {
                          props.set_course_info('teacher_account', selected[0].teacher_account_id);
                        } else {
                          props.set_course_info('teacher_account', null);
                        }
                      }}
                    />
                  </Col>
                  <Col sm={3}>
                    <CustomSelectInput
                      name={'category'}
                      disabled={!isEdit}
                      value={CourseInfo?.category}
                      label={'대표 카테고리'}
                      is_required={true}
                      tooltip_desc={'강의 대표 카테고리를 선택해주세요.'}
                      onChange={(e) => props.set_course_info(e.target.name, e.target.value)}
                      options={category_set}
                    />
                  </Col>
                  <Col sm={3}>
                    <CustomSelectInput
                      name={'only_single_kit'}
                      disabled={!isEdit}
                      value={CourseInfo.only_single_kit || 0}
                      label={'단일 키트 강의'}
                      is_required={true}
                      tooltip_desc={
                        '"예"로 설정하는 경우 (기본값: 아니요) \n키트 선택시 최대 1개의 키트만 선택되도록 설정합니다.'
                      }
                      onChange={(e) => props.set_course_info(e.target.name, e.target.value)}
                      options={[
                        { name: '예', id: 1 },
                        { name: '아니오', id: 0 },
                      ]}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col>
                    <CustomTypeahead
                      disabled={!parseInt(props.course_id) || !isEdit}
                      is_required={true}
                      multiple={true}
                      label={'키트'}
                      placeholder='키트를 검색후 선택해주세요.'
                      tooltip_desc={'키트가 없을시 빈 상태로 선택하지 않고 저장해주세요.'}
                      selected={kit_select}
                      selectedHandler={setKitSelect}
                      reduxSelectedHandler={props.set_kit}
                      labelKey={'title'}
                      options={kit_set}
                      customToken={(target) => {
                        return `${target.title}`;
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col>
                <Row>
                  <Col>
                    <CustomInput
                      type={'textarea'}
                      name={'title'}
                      label={'클래스명'}
                      is_required={true}
                      placeholder={'강의명을 입력해주세요.'}
                      onChange={(e) => {
                        props.set_course_info(e.target.name, e.target.value);
                      }}
                      value={CourseInfo.title || ''}
                      disabled={!isEdit}
                      maxLength={100}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      type={'textarea'}
                      name={'short_title'}
                      label={'짧은 클래스명'}
                      is_required={true}
                      placeholder={'내용을 입력해주세요.'}
                      onChange={(e) => {
                        props.set_course_info(e.target.name, e.target.value);
                      }}
                      value={CourseInfo.short_title || ''}
                      disabled={!isEdit}
                      maxLength={100}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      type={'textarea'}
                      name={'description'}
                      label={'설명'}
                      is_required={true}
                      placeholder={'설명에 대한 내용을 입력해주세요.'}
                      onChange={(e) => {
                        props.set_course_info(e.target.name, e.target.value);
                      }}
                      value={CourseInfo.description}
                      disabled={!isEdit}
                      maxLength={100}
                    />
                  </Col>
                </Row>
                <hr style={{ marginTop: '40px' }} />
                <Row style={{ marginTop: '30px' }}>
                  <Col>
                    <Label>태그 설명</Label>
                    <TagInput
                      tag_set={CourseInfo.tag_set}
                      disabled={!parseInt(props.course_id) || !isEdit}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col sm={2}>
                    <Label>계약 만료일</Label>
                    <DatePicker
                      className='form-control'
                      dateFormat='yyyy-MM-dd'
                      name='contract_expire_date'
                      selected={
                        CourseInfo.contract_expire_date
                          ? new Date(CourseInfo.contract_expire_date)
                          : null
                      }
                      onChange={(date) => {
                        props.set_course_info(
                          'contract_expire_date',
                          moment(date).format('YYYY-MM-DD'),
                        );
                      }}
                      disabled={!parseInt(props.course_id) || !isEdit}
                    />
                  </Col>
                  <Col sm={2}>
                    <Label>오픈 예정일</Label>
                    <DatePicker
                      className='form-control'
                      dateFormat='yyyy-MM-dd'
                      name='to_be_open_date'
                      selected={
                        CourseInfo.to_be_open_date ? new Date(CourseInfo.to_be_open_date) : null
                      }
                      onChange={(date) => {
                        props.set_course_info('to_be_open_date', moment(date).format('YYYY-MM-DD'));
                      }}
                      disabled={!parseInt(props.course_id) || !isEdit}
                    />
                  </Col>
                  <Col sm={4}>
                    <CustomTypeahead
                      disabled={!parseInt(props.course_id) || !isEdit}
                      multiple={false}
                      label={'연장하기 판매항목'}
                      placeholder='연장 판매항목을 선택해주세요.'
                      tooltip_desc={
                        '강의 연장하기 결제 기능의 근거로 사용됩니다. \n선택되지 않은경우 해당 강의는 연장하기 기능이 없습니다.'
                      }
                      selected={course_sale_select}
                      labelKey={'sale_short_name'}
                      options={course_sale_set}
                      onChange={(selected) => {
                        setCourseSaleSelect(selected);
                        if (selected.length > 0) {
                          props.set_course_info('extend_sale_info', selected[0].id);
                        } else {
                          props.set_course_info('extend_sale_info', null);
                        }
                      }}
                    />
                  </Col>
                  <Col sm={4}>
                    <CustomInput
                      type={'url'}
                      name={'reservation_url'}
                      placeholder={'외부 펀딩, 판매 사이트 URL(https or http로 시작)'}
                      disabled={!parseInt(props.course_id) || !isEdit}
                      value={CourseInfo.reservation_url || ''}
                      label='예약 리디렉션 URL(예약단계용)'
                      tooltip_desc={'외부 펀딩, 판매 사이트 URL(https or http로 시작)'}
                      onChange={(e) => props.set_course_info(e.target.name, e.target.value)}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col sm={2}>
                    <CustomSelectInput
                      name={'badge_type'}
                      disabled={!parseInt(props.course_id) || !isEdit}
                      value={CourseInfo.badge_type || 0}
                      label={'뱃지 설정'}
                      tooltip_desc={
                        '서비스에서 해당 강의를 표시할때 \n관련 아이콘을 표시할 수 있습니다.'
                      }
                      onChange={(e) => props.set_course_info(e.target.name, e.target.value)}
                      options={[
                        { name: 'BEST', id: 1 },
                        { name: '없음', id: 0 },
                      ]}
                    />
                  </Col>
                  <Col sm={2}>
                    <CustomInput
                      type={'number'}
                      name={'total_running_sec'}
                      placeholder={'있는 경우 메인이미지 대신 표시'}
                      disabled={!parseInt(props.course_id) || !isEdit}
                      value={CourseInfo.total_running_sec || 0}
                      label={'[수료증용] 강의 러닝 타임 (초)'}
                      tooltip_desc={
                        '강의러닝타임 수료증 발급시 이용되는 값, \n0 또는 입력하지 않는 경우 시스템상 수료증 발급시 부정확한 값으로 추산함으로 \n[판매중] 단계시 반드시 초 단위로 입력해주세요.'
                      }
                      onChange={(e) => props.set_course_info(e.target.name, e.target.value)}
                    />
                  </Col>
                  <Col sm={2}>
                    <CustomInput
                      type={'text'}
                      name={'youtube_id'}
                      placeholder={'있는 경우 메인이미지 대신 표시'}
                      disabled={!parseInt(props.course_id) || !isEdit}
                      value={CourseInfo.youtube_id || ''}
                      label='유투브 ID'
                      onChange={(e) => props.set_course_info(e.target.name, e.target.value)}
                    />
                  </Col>
                  <Col>
                    <CustomFileInput
                      with_signed={true}
                      id={props.course_id}
                      name={'document_url'}
                      className={'custom-file-input'}
                      resource_type={'course-lecture'}
                      disabled={!parseInt(props.course_id) || !isEdit}
                      label={'강의안 파일'}
                      is_img={false}
                      value={CourseInfo.document_url}
                      tooltip_desc={
                        '업로드된 강의안이 있으면 옆에 버튼을 통해\n강의안을 다운받을 수 있습니다. (강의안 없을시 버튼 없음)'
                      }
                      propOnChange={({ url }) => {
                        props.set_course_info('document_url', url);
                      }}
                      style={{
                        fontSize: '14px',
                        color: '#898989',
                        height: 'auto',
                        paddingLeft: '20px',
                      }}
                    />
                  </Col>
                  <Col>
                    <CustomFileInput
                      id={props.course_id}
                      name={'master_sign_image_url'}
                      className={'custom-file-input'}
                      is_img={true}
                      file_type={'course'}
                      value={CourseInfo.master_sign_image_url}
                      tooltip_desc={
                        '수료증 발급시 이용되는 서명 이미지 입니다.\n없는경우 베어유 서명으로 기재되어 발급됩니다.\n(164 너비로 강제 리사이즈 됩니다.)'
                      }
                      disabled={!parseInt(props.course_id) || !isEdit}
                      label={'[수료증용] 강사서명 이미지'}
                      propOnChange={({ url }) => {
                        props.set_course_info('master_sign_image_url', url);
                      }}
                      style={{
                        fontSize: '14px',
                        color: '#898989',
                        height: 'auto',
                        paddingLeft: '20px',
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col>
                    <CustomLabel
                      tooltip_desc={'상세페이지에 업로드될 이미지를 업로드해주세요.'}
                      label={'콘텐츠 영역'}
                    />
                    <div className='editor-img-100'>
                      <TextEditor
                        id={props.course_id}
                        disabled={!parseInt(props.course_id) || !isEdit}
                        html_data={CourseInfo.body?.content_html ?? ''}
                        img_type={'course'}
                        onChange={(value) => {
                          props.set_course_body('content_html', value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  CourseSale: state.CourseSaleStore,
  CourseInfo: state.CourseStore,
  CommonStore: state.CommonStore,
});

const mapDispatchToProps = (dispatch) => {
  return {
    set_course_info: (name, value) => dispatch(set_course_info(name, value)),
    set_course_body: (name, value) => dispatch(set_course_body(name, value)),
    clear_course_info: () => dispatch(clear_course_info()),
    stored_course_info: (data) => dispatch(stored_course_info(data)),
    set_kit: (kit_set) => dispatch(set_kit(kit_set)),
    set_sub_category: (sub_category_set) => dispatch(set_sub_category(sub_category_set)),
    clear_delete_img: (img_type) => dispatch(clear_delete_img(img_type)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Info);
