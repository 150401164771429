import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {
  getPageArray,
  getShowList,
  getTotalPageCount,
  getVisibleString,
} from '../../../util/b2b_utils';
import {
  CATEGORY_COURSE,
  COURSE_LIST_SHOW_COUNT,
  INIT_PAGE_COUNT,
  TABLE_HEAD_COURSE,
  TABLE_HEAD_PACKAGE,
  TOGGLE_SORT_NUM_INIT,
} from '../../../constant/options';
import { Table } from 'reactstrap';
import { lang } from '../../../lang';
import Paging from '../../_common/Paging';
import TableHead from '../../_common/component/TableHead';
import moment from 'moment';
import CustomTooltip from '../../_common/component/CustomTooltip';

/*
    props
    category: state(int)            클래스(1) || 패키지(2)
    course_set: state([])           기업의 클래스 목록(course)
    product_set: state([])          기업의 패키지 목록(product)
    onSelect: func => void          CompanyCourseList.js의 openEditModal(클래스) || openProductProgressModal(패키지)
 */
const CompanyCourseTable = (props) => {
  // state
  const [page_count, setPageCount] = useState(INIT_PAGE_COUNT);
  const [current_course_list, setCurrentCourseList] = useState([]);
  // toggle_sort : 넘버링 0 || 1
  const [toggle_sort, setToggleSort] = useState(TOGGLE_SORT_NUM_INIT);
  const [target_array, setTargetArray] = useState([]);

  // ref
  const empty_comment = useRef(lang.course.did_not_exist_course);
  const total_page_count = useRef(0);
  const page_array = useRef([]);

  const reloadCurrentCourseList = useCallback(() => {
    setCurrentCourseList(
      getShowList({
        array: props.category === CATEGORY_COURSE ? props.course_set : props.product_set,
        show_count: COURSE_LIST_SHOW_COUNT,
        page_count,
        total_page_count: total_page_count.current,
      }),
    );
  }, [
    props.course_set.length,
    props.product_set.length,
    props.course_set,
    props.product_set,
    page_count,
    total_page_count,
    props.category,
  ]);

  useEffect(() => {
    if (props.course_set) {
      // 카테고리(course || product)에 따라 다른 paging(페이지네이션), target_array(table_head, table body에 쓰임) 설정
      if (props.category === CATEGORY_COURSE) {
        total_page_count.current = getTotalPageCount(
          props.course_set.length,
          COURSE_LIST_SHOW_COUNT,
        ).total_page_count;
        page_array.current = getPageArray(total_page_count.current).page_array;

        setTargetArray(TABLE_HEAD_COURSE.map((head) => head.target));
      } else {
        total_page_count.current = getTotalPageCount(
          props.product_set.length,
          COURSE_LIST_SHOW_COUNT,
        ).total_page_count;
        page_array.current = getPageArray(total_page_count.current).page_array;

        setTargetArray(TABLE_HEAD_PACKAGE.map((head) => head.target));
      }
    }

    reloadCurrentCourseList();
  }, [reloadCurrentCourseList, props.category, props.course_set]);

  useEffect(() => {
    // category 변경시마다 page_count 초기화
    setPageCount(INIT_PAGE_COUNT);
  }, [props.category]);

  const toggleSortByTotalProgress = () => {
    if (props.course_set.length) {
      if (toggle_sort) {
        props.course_set.sort((a, b) => a.attendance_rate - b.attendance_rate);
        reloadCurrentCourseList();
      } else if (!toggle_sort) {
        props.course_set.sort((a, b) => b.attendance_rate - a.attendance_rate);
        reloadCurrentCourseList();
      }
    } else {
      return false;
    }
  };

  const onHandlePageCount = (direction) => {
    if (direction === 'Prev' && page_count > 1) {
      setPageCount(page_count - 1);
    } else if (direction === 'Next' && page_count < total_page_count.current) {
      setPageCount(page_count + 1);
    }
  };

  const CourseTableBodyView = () => {
    return current_course_list.length >= 1 ? (
      current_course_list.map((course, index) => {
        const visible_rental_time = moment(course.rental_time)
          .format('YYYY-MM-DD HH:mm:ss')
          .split(' ');
        const visible_rental_expire_time = moment(course.rental_expire_time)
          .format('YYYY-MM-DD HH:mm:ss')
          .split(' ');
        if (props.category === CATEGORY_COURSE) {
          return (
            <tr
              key={index}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                props.onSelect(course);
              }}
            >
              {target_array.map((target, i) => {
                if (target === 'rental_time') {
                  return (
                    <td
                      key={i}
                      style={{
                        verticalAlign: 'middle',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {visible_rental_time[0]}
                      <br />
                      {visible_rental_time[1]}
                    </td>
                  );
                } else if (target === 'rental_expire_time') {
                  return (
                    <td
                      key={i}
                      style={{
                        verticalAlign: 'middle',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {visible_rental_expire_time[0]}
                      <br />
                      {visible_rental_expire_time[1]}
                    </td>
                  );
                } else if (target === 'course_title') {
                  return (
                    <td
                      key={i}
                      className='text-left'
                      style={{
                        verticalAlign: 'middle',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {course[target]}
                    </td>
                  );
                } else {
                  return (
                    <td
                      key={i}
                      style={{
                        verticalAlign: 'middle',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {course[target] ? (course[target] * 100).toFixed(1) : 0}%
                    </td>
                  );
                }
              })}
            </tr>
          );
        } else {
          return (
            <tr
              key={index}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                props.onSelect(course);
              }}
            >
              {target_array.map((target, i) => {
                if (target === 'rental_time') {
                  return (
                    <td
                      key={i}
                      style={{
                        verticalAlign: 'middle',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {visible_rental_time[0]}
                      <br />
                      {visible_rental_time[1]}
                    </td>
                  );
                } else if (target === 'rental_expire_time') {
                  return (
                    <td
                      key={i}
                      style={{
                        verticalAlign: 'middle',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {visible_rental_expire_time[0]}
                      <br />
                      {visible_rental_expire_time[1]}
                    </td>
                  );
                } else if (target === 'product_title') {
                  return (
                    <td
                      key={i}
                      className='text-left'
                      style={{
                        verticalAlign: 'middle',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {course[target]}
                    </td>
                  );
                } else if (target === 'course_set') {
                  const visible_course_set = course[target]
                    ? getVisibleString(course[target].join(), 25)
                    : '';
                  if (course[target] && course[target].join().length >= 25) {
                    return (
                      <Fragment key={i}>
                        <td
                          id={`course_set_${course.product_id}`}
                          style={{
                            verticalAlign: 'middle',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {visible_course_set}
                        </td>
                        <CustomTooltip
                          target={`course_set_${course.product_id}`}
                          content={course[target].join()}
                        />
                      </Fragment>
                    );
                  } else {
                    return (
                      <td
                        key={i}
                        style={{
                          verticalAlign: 'middle',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {visible_course_set}
                      </td>
                    );
                  }
                } else {
                  return (
                    <td
                      key={i}
                      style={{
                        verticalAlign: 'middle',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {course[target]}%
                    </td>
                  );
                }
              })}
            </tr>
          );
        }
      })
    ) : (
      <tr>
        <td colSpan='4' className='text-center'>
          {empty_comment.current}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table
        className='table-hover text-center'
        bordered
        style={{
          borderTop: '2px solid #e5e5e5',
          marginTop: '1.5em',
        }}
      >
        <TableHead
          table_head={props.category === CATEGORY_COURSE ? TABLE_HEAD_COURSE : TABLE_HEAD_PACKAGE}
          toggle_sort={toggle_sort}
          setToggleSort={setToggleSort}
          toggleSortByTarget={toggleSortByTotalProgress}
        />
        <tbody>
          <CourseTableBodyView />
        </tbody>
      </Table>
      <Paging
        page_array={page_array.current}
        setPageCount={setPageCount}
        page_count={page_count}
        onHandlePageCount={onHandlePageCount}
      />
    </>
  );
};

export default CompanyCourseTable;
