import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import CourseModel from '../../../model/CourseModel';
import CustomInput from '../../_common/component/CustomInput';
import TextEditor from '../../_common/component/TextEditor';
import CustomLabel from '../../_common/component/CustomLabel';
import CustomSelectInput from '../../_common/component/CustomSelectInput';
import { connect } from 'react-redux';
import {
  stored_course_notice,
  set_course_notice,
  clear_course_notice,
} from '../../../redux/course/notice/action';
import Loading from '../../_common/Loading';

const Notice = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const CourseInfo = props.CourseInfo;
  const CourseNotice = props.CourseNotice;

  const initData = async () => {
    setIsLoading(true);
    const { notice } = await CourseModel.getCourseNotice(parseInt(props.obj_id));
    if (notice) props.stored_course_notice(notice);
    else props.clear_course_notice();
    setIsLoading(false);
  };

  useEffect(() => {
    initData();
    return () => {
      props.clear_course_notice();
    };
  }, []);

  const saveNotice = async () => {
    setIsLoading(true);
    props.stored_course_notice(await CourseModel.postCourseNotice(props.obj_id, CourseNotice));
    setIsEdit(false);
    setIsLoading(false);
  };

  return (
    <>
      <Row>
        <Col>
          <Loading isLoading={isLoading} />
        </Col>
        <Col className='text-right'>
          {isEdit && (
            <>
              <Button
                className='btn-pill btn-air-info'
                style={{ marginRight: '8px' }}
                onClick={saveNotice}
              >
                저장
              </Button>
            </>
          )}
          {
            <>
              <Button
                className='btn-pill btn-air-dark'
                color='primary'
                style={{ marginRight: '8px' }}
                onClick={() => {
                  if (isEdit) {
                    initData();
                  }
                  setIsEdit(!isEdit);
                }}
              >
                {isEdit ? <>취소</> : <>편집</>}
              </Button>
            </>
          }
        </Col>
      </Row>
      <Card className='input-air-primary' style={{ marginTop: '20px' }}>
        <CardBody>
          <Row>
            <Col>
              <CustomInput
                type={'textarea'}
                name={'sale_short_name'}
                disabled={true}
                value={CourseInfo.title || ''}
                label={'강의명'}
                is_required={true}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col sm={6}>
              <CustomInput
                type={'text'}
                name={'title'}
                disabled={!isEdit}
                value={CourseNotice.title || ''}
                label={'공지사항 제목'}
                is_required={true}
                onChange={(e) => props.set_course_notice(e.target.name, e.target.value)}
              />
            </Col>
            <Col sm={6}>
              <CustomSelectInput
                disabled={!isEdit}
                name={'is_enabled'}
                value={CourseNotice.is_enabled}
                label={'활성화여부'}
                is_required={true}
                options={[
                  { name: '활성화', id: 1 },
                  { name: '비활성화', id: 0 },
                ]}
                onChange={(e) => props.set_course_notice(e.target.name, e.target.value)}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <CustomLabel label={'콘텐츠 영역'} />
              <div className='editor-img-100'>
                <TextEditor
                  id={props.obj_id}
                  disabled={!isEdit}
                  img_type={'course'}
                  html_data={CourseNotice.content || ''}
                  onChange={(value) => props.set_course_notice('content', value)}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  CourseNotice: state.CourseNoticeStore,
  CourseInfo: state.CourseStore,
});

const mapDispatchToProps = (dispatch) => {
  return {
    set_course_notice: (name, value) => dispatch(set_course_notice(name, value)),
    stored_course_notice: (data) => dispatch(stored_course_notice(data)),
    clear_course_notice: () => dispatch(clear_course_notice()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notice);
