import React, { useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import axios from 'axios';
import CustomLabel from './CustomLabel';
import Loading from '../Loading';

const CustomFileInput = (props) => {
  const [file_name, setFileName] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const {
    id,
    name,
    file_type,
    file_password,
    is_required,
    value,
    label,
    tooltip_desc,
    propOnChange,
    is_img,
    multiple,
    invoice_date,
    resource_type,
    with_signed = false,
    ...attributes
  } = props;

  useEffect(() => {
    if (value !== null) {
      setFileName(extractFileNameInUrl(value));
    }
  }, [value]);

  const extractFileNameInUrl = (url_string) => {
    try {
      let url = new URL(url_string);
      let last_pos = url.pathname.lastIndexOf('/');
      return url.pathname.substring(last_pos + 1 || 0, url.pathname.length);
    } catch (e) {
      return '';
    }
  };

  const onChange = async (e) => {
    setIsLoading(true);

    if (!with_signed) {
      try {
        let input = e.target;
        let body = new FormData();

        if (multiple) {
          body.append('invoice_date', invoice_date);
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(input.files)) {
            body.append('file', value);
          }
        } else {
          body.append('file', input.files[0]);
        }

        body.append('file_type', file_type);
        body.append('id', id);

        axios
          .post(`${process.env.REACT_APP_API_URL}/cms-api/v1/common/file`, body)
          .then((response) => {
            if (response.data.code === 200) {
              const url = response.data.data.file_url;
              const password = response.data.data.password;
              propOnChange({
                url,
                password,
              });
              setFileName(extractFileNameInUrl(url));
              onUploadSuccess();
            }
          });
      } catch {
        setIsLoading(false);
      }
    } else {
      // @TODO isLoading, only single file
      let input = e.target;
      let input_file_length = input.files?.length || 0;
      for (let i = 0; i < input_file_length; i++) {
        let file = input.files[i];
        let body = new FormData();
        body.append('resource_type', resource_type);
        body.append('id', id);
        body.append('file_name', file.name);

        let signed_response = await axios.post(
          `${process.env.REACT_APP_API_URL}/cms-api/v1/common/file-signed-post`,
          body,
        );
        if (signed_response.data.code === 200) {
          let { data } = signed_response.data;
          let url = data.url;
          setFileName(extractFileNameInUrl(url));

          try {
            let signed = data.signed;
            let s3_url = signed.url;
            let fields = signed.fields;
            let s3_form_data = new FormData();

            for (let key in fields) {
              let value = fields[key];
              s3_form_data.append(key, value);
            }
            s3_form_data.append('file', file);

            // @TODO axios 버그...
            // let s3_response = await axios({
            //     method: "post",
            //     url: s3_url,
            //     // headers: {
            //     //     ...s3_form_data.getHeaders()
            //     // },
            //     data : s3_form_data
            // });

            fetch(s3_url, {
              method: 'POST',
              body: s3_form_data,
            })
              .then((response) => response.text())
              .then((result) => {
                let parser = new DOMParser();
                let dom = parser.parseFromString(result, 'text/xml');
                let error_code = (dom.querySelector('Error>Code') || {}).textContent;
                let error_message = (dom.querySelector('Error>Message') || {}).textContent;
                if (error_code) {
                  if (error_code === 'AccessDenied') {
                    onUploadFailed('액세스가 거부 되었습니다.');
                  } else if (error_code === 'EntityTooLarge') {
                    onUploadFailed('사이즈를 초과 하였습니다.');
                  } else {
                    if (error_message) {
                      onUploadFailed('작업에 실패했습니다. (errCode4-' + error_message + ')');
                    } else {
                      onUploadFailed('작업에 실패했습니다. (errCode4)');
                    }
                  }
                } else {
                  // on success
                  propOnChange({
                    url: `${signed.url}/${fields.key}`,
                  });
                  onUploadSuccess();
                }
              })
              .catch((error) => {
                onUploadFailed(
                  '악..! 파일업로드에 실패했습니다 관리자에게 문의해 주세요. (errCode3)',
                );
              });
          } catch (e) {
            console.log(e);
            onUploadFailed('악..! 파일업로드에 실패했습니다 관리자에게 문의해 주세요. (errCode2)');
          }
        } else {
          onUploadFailed('악..! 파일업로드에 실패했습니다 관리자에게 문의해 주세요. (errCode1)');
        }
      }
    }
  };

  const onUploadFailed = (message) => {
    setIsLoading(false);
    window.alert(message);
  };

  const onUploadSuccess = () => {
    setIsLoading(false);
  };

  return (
    <>
      <CustomLabel
        tooltip_desc={tooltip_desc}
        label={label}
        is_required={is_required}
        file_name={value}
        file_password={file_password}
        right_component={isLoading ? <Loading isLoading={true} /> : null}
      />
      <div className='custom-file'>
        <Input
          id={name}
          type='file'
          name={name}
          multiple={true}
          accept={is_img ? 'image/jpeg, image/png, image/jpg' : ''}
          {...attributes}
          onChange={onChange}
        />
        <Label
          className='custom-file-label'
          style={{
            borderColor: '#efefef',
          }}
          htmlFor={name}
        >
          {file_name || '파일 없음'}
        </Label>
      </div>
    </>
  );
};

export default CustomFileInput;
