import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import { initComma, toQueryString } from '../../util/common';
import { PAGE_SIZE } from '../../constants';

const Faq = (props) => {
  // 기존 props.history.push 로는 state를 가져온다 / 직접 url에 값을 때려 박으면 state에 값이 없어서 search에서 가져와야한다 / 이도저도 아닐 경우
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    search_text: params.search_text ? params.search_text : '',
    faq_category: params.faq_category ? params.faq_category : '',
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const [faq_set, setFaqSet] = useState([]);
  const [faq_count, setFaqCountSet] = useState(0);

  const [faq_category_set, setFaqCategorySet] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilter] = useState(default_filter.current);

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'faq_category_set' }).then(({ code_set }) => {
      setFaqCategorySet(code_set);
    });
  }, []);

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    CommonModel.getFaq({
      ...params,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ faq, total_count }) => {
      setFaqSet(faq);
      setFaqCountSet(total_count);
      setIsLoading(false);
    });
  }, [props.location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onFaqTypeChange = (e) => {
    pushHistory({
      ...filter_set,
      faq_category: e.target.value,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterSubmit = (e) => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      order_key: order_key,
      order_type: !filter_set.order_type || filter_set.order_type === 'asc' ? 'desc' : 'asc', // 정렬을 기존 기준 -> "없을 경우 or asc = desc로 변경" / "desc 일 경우 = asc 로 변경"
    };

    // 이전에 정렬한적 있고, 새로운 정렬을 적용.
    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }

    pushHistory(newFilterSet);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    });
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <Breadcrumb parent='서비스' title='FAQ 목록' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Col className='text-right mb-4'>
              <Link className={'btn btn-primary btn-pill'} to={'/faq/0'}>
                FAQ생성
              </Link>
            </Col>
            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={7} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총: <span style={{ color: 'coral' }}>{initComma(faq_count)}</span>개
                        </p>
                        <Loading isLoading={isLoading} />
                      </Col>
                      <Col md={2}>
                        <Input
                          type='select'
                          name='faq_category'
                          className='form-control'
                          onChange={(e) => {
                            onFaqTypeChange(e);
                          }}
                          value={filter_set.faq_category}
                        >
                          <option value=''>전체</option>
                          {faq_category_set.map((faq_category) => {
                            return (
                              <option key={faq_category.id} value={faq_category.id}>
                                {faq_category.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                      <Col md={3} className='text-right'>
                        <FormGroup>
                          <InputGroup>
                            <Input
                              className='form-control'
                              type='text'
                              name='search_text'
                              onChange={onFilterChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onFilterSubmit(e);
                                }
                              }}
                              value={filter_set.search_text}
                              placeholder='내용을 입력하세요.'
                            />
                            <InputGroupAddon
                              addonType='append'
                              style={{ cursor: 'pointer' }}
                              onClick={onFilterSubmit}
                            >
                              <InputGroupText>
                                <i className='icofont icofont-ui-search' />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <div className='table-responsive'>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th
                        className='text-center'
                        style={{
                          width: '70px',
                        }}
                      >
                        ID
                      </th>
                      <th
                        className='text-center'
                        style={{
                          width: '150px',
                          textAlign: 'left',
                        }}
                      >
                        카테고리
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                        }}
                      >
                        질문
                      </th>
                      <th
                        style={{
                          width: '150px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('id');
                        }}
                      >
                        {filter_set.order_key === 'id' ? (
                          <span style={{ color: '#ff4c3b' }}>생성일</span>
                        ) : (
                          <span>생성일</span>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {faq_set.map((faq) => {
                      let url = 'faq/' + faq.id;
                      const onTdClick = () => {
                        props.history.push(url);
                      };
                      return (
                        <tr key={faq.id}>
                          <td
                            className='text-center'
                            onClick={onTdClick}
                            role='button'
                            style={{ verticalAlign: 'middle' }}
                          >
                            {faq.id}
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div>{faq.faq_category_name}</div>
                          </td>
                          <td style={{ verticalAlign: 'middle' }} role='button' onClick={onTdClick}>
                            <div className={'f-w-900'}>{faq.question}</div>
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(faq.write_time)).format('YYYY-MM-DD')}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size ? faq_count / params.size : faq_count / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Faq;
