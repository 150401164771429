import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Collapse } from 'reactstrap';
import Apexcharts from 'react-apexcharts';
import { total_progress_data } from '../../../constant/chart_option';
import Loading from '../../_common/Loading';

/*
    props
    company_graph_data: {}      기업의 그래프 정보
 */
const TotalProgressChart = (props) => {
  // state
  const [is_chart_spread, setChartSpread] = useState(true);
  const [chart_series, setChartSeries] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [is_data_empty, setIsDataEmpty] = useState(false);

  const chart_options = useRef(total_progress_data.options);

  useEffect(() => {
    if (props.company_graph_data) {
      if (
        props.company_graph_data.total_attendance_rate === null ||
        props.company_graph_data.total_attendance_rate === undefined
      ) {
        setIsDataEmpty(true);
      } else {
        setChartSeries([Math.round(props.company_graph_data.total_attendance_rate * 100 || 0)]);
      }
      setIsLoading(false);
    }
  }, [props.company_graph_data]);

  return (
    <Card>
      <div className='default-according style-1' id='accordionoc'>
        <CardHeader style={{ padding: '20px 20px 20px 30px', lineHeight: '38px' }}>
          <Button
            color='black pl-0'
            data-toggle='collapse'
            onClick={() => setChartSpread(!is_chart_spread)}
            data-target='#collapseicon'
            aria-expanded={is_chart_spread}
            aria-controls='collapseicon'
          >
            <h5 className='mb-0'>전체 강의 진도율</h5>
          </Button>
        </CardHeader>
      </div>
      <Collapse isOpen={is_chart_spread}>
        <CardBody className='p-1'>
          <div className='total-progress'>
            {isLoading ? (
              <div className='text-center'>
                <Loading
                  isLoading={isLoading}
                  custom_style={{
                    display: 'flex',
                  }}
                  is_card_body={true}
                />
              </div>
            ) : is_data_empty ? (
              <Col
                md={'12'}
                className={'text-primary text-center p-30'}
                style={{ fontSize: '15px', fontWeight: 'bold' }}
              >
                현재 수강중 또는 학습하신 강의가 없습니다
              </Col>
            ) : (
              <Apexcharts
                options={chart_options.current}
                series={chart_series}
                type='radialBar'
                height={300}
              />
            )}
          </div>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default TotalProgressChart;
