import React, { useEffect, useState } from 'react';
import { Button, CardBody, CardHeader, Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { COMPANY_INIT_SET } from '../../../constant/options';
import { dialog_type_confirm, useShowDialog } from '../../../util/b2b_Hooks';
import { useDispatch, useSelector } from 'react-redux';
import { lang } from '../../../lang';
import { useHistory } from 'react-router-dom';
import CustomInput2 from '../../_common/component/CustomInput2';
import CustomSelect2 from '../../_common/component/CustomSelect2';
import {
  BROADCAST_SET_MAXIMUM_COUNT,
  useApplicationContext,
} from '../../../context/ApplicationContextProvider';
import CustomTypeahead2 from '../../_common/component/CustomTypeahead2';
import CompanyModel from '../../../model/CompanyModel';
import { addTemporaryValue } from '../../../redux/b2b_store/temporary/actions';
import Loading from '../../_common/Loading';

const AddCompanyForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { broadcast } = useApplicationContext();
  const { register, handleSubmit, reset } = useForm();
  const { showAlert } = useShowDialog();
  const temporary_reducer = useSelector((state) => state.temporary_reducer);

  // state
  const [manager_account_set, setManagerAccountSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (temporary_reducer.temp_manager_account_name_set) {
      setManagerAccountSet(temporary_reducer.temp_manager_account_name_set);
    }
  }, [temporary_reducer.temp_manager_account_name_set]);

  const onSubmit = async (data) => {
    // 기업관리자 계정이 비어있는 경우
    if (
      !temporary_reducer.temp_manager_account_name_set ||
      temporary_reducer.temp_manager_account_name_set.length < 1
    ) {
      showAlert({
        title: lang.company.check_manager_account,
        text: lang.company.need_fill_company_account,
      });
      return false;
    }

    const tmp_company = {
      ...COMPANY_INIT_SET,
      name: data.company_name,
      manager_account_name_set: temporary_reducer.temp_manager_account_name_set,
      licensed_employee_count: data.licensed_employee_count,
    };

    setIsLoading(true);
    const post_company_res = await CompanyModel.postCompany(tmp_company);

    if (post_company_res.code === 200) {
      // 같이 등록할 직원, 강의, 패키지가 없는 경우
      if (
        !temporary_reducer.temp_employee_set &&
        !temporary_reducer.temp_course_set &&
        !temporary_reducer.temp_product_set
      ) {
        showAlert({
          type: dialog_type_confirm,
          title: lang.common.success_resist_message_common,
          text: lang.company.confirm_move_to_company_list,
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: lang.company.confirm_button_move_to_company_list,
          cancelButtonText: lang.company.cancel_button_move_to_company_list,
          true_fn: () => {
            history.push('/company-list');
          },
        });
      } else {
        const company_id = post_company_res.data.company_id;
        let _duplicate_account_set = [];
        let course_error_msg;
        let product_error_msg;

        //직원이 있는 경우
        if (temporary_reducer.temp_employee_set) {
          const { duplicate_account_set } = await postCompanyWithEmployee(company_id);
          _duplicate_account_set = duplicate_account_set;
        }

        // 클래스가 있는 경우
        if (temporary_reducer.temp_course_set) {
          const { error_msg } = await postCompanyWithCourse(company_id);
          course_error_msg = error_msg;
        }

        // 패키지가 있는 경우
        if (temporary_reducer.temp_product_set) {
          const { error_msg } = await postCompanyWithProduct(company_id);
          product_error_msg = error_msg;
        }

        if (_duplicate_account_set.length) {
          showAlert({
            title: lang.common.success_resist_message_common,
            text:
              _duplicate_account_set.join(', ') + '의 계정은 타 회사의 직원으로 등록된 계정입니다',
            icon: 'info',
          });
          history.push('/company-list');
        } else if (course_error_msg) {
          showAlert({
            title: lang.course.error_resist_course,
            text: course_error_msg,
            icon: 'info',
          });
          history.push('/company-list');
        } else if (product_error_msg) {
          showAlert({
            title: lang.package.error_resist_package,
            text: product_error_msg,
            icon: 'info',
          });
          history.push('/company-list');
        } else {
          reset(register());
          showAlert({
            type: dialog_type_confirm,
            title: lang.common.success_resist_message_common,
            text: lang.company.confirm_move_to_company_list,
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: lang.company.confirm_button_move_to_company_list,
            cancelButtonText: lang.company.cancel_button_move_to_company_list,
            true_fn: () => {
              history.push('/company-list');
            },
            false_fn: () => {
              reset(register());
            },
          });
        }
      }
    } else {
      showAlert({
        title: lang.request.request_error_title,
        text: post_company_res.message,
      });
      reset(register());
    }

    setIsLoading(false);
  };

  const postCompanyWithEmployee = async (company_id) => {
    let duplicate_account_set = [];

    for (const employee of temporary_reducer.temp_employee_set) {
      // 직원등록 API 호출
      const post_employee_res = await CompanyModel.postEmployee(company_id, employee);
      if (post_employee_res.message === '이미 존재하는 사용자입니다') {
        duplicate_account_set.push(employee.account_name);
      }
    }

    return {
      duplicate_account_set,
    };
  };

  const postCompanyWithCourse = async (company_id) => {
    const post_course_set = getUsefulCourseSet();
    let error_msg;

    for (const course of post_course_set) {
      const post_course_res = await CompanyModel.postCourses(company_id, course);
      if (post_course_res.code !== 200) {
        error_msg = post_course_res.message;
      }
    }

    return { error_msg };
  };

  const postCompanyWithProduct = async (company_id) => {
    const post_product_set = getUsefulProductSet();
    let error_msg;

    for (const product of post_product_set) {
      const post_course_res = await CompanyModel.postProducts(company_id, product);
      if (post_course_res.code !== 200) {
        error_msg = post_course_res.message;
      }
    }

    return { error_msg };
  };

  /*
        temp_reducer에 저장된 값 : {course_id, id, rental_time, rental_expire_time}
        기업의 강의등록 API에 전달할 값 : {id_set, rental_time, rental_expire_time}
        reducer의 값 -> API에 전달할 값으로 변형 (rental_time, rental_expire_time이 같으면 한번에 id_set([])으로 묶어서 한번에 전달
     */
  const getUsefulCourseSet = () => {
    // reducer에 저장된 배열만큼 객체배열을 생성
    let init_array = temporary_reducer.temp_course_set.map((course) => {
      return {
        course_set: [],
        rental_time: course.rental_time,
        rental_expire_time: course.rental_expire_time,
      };
    });

    temporary_reducer.temp_course_set.map((temp_course) => {
      return init_array.map((init_course) => {
        if (
          temp_course.rental_time === init_course.rental_time &&
          temp_course.rental_expire_time === init_course.rental_expire_time
        ) {
          init_course.course_set.push(temp_course.id);
        }
        return null;
      });
    });

    let post_course_set = [];
    for (let i = 0; i < init_array.length; i++) {
      if (
        init_array[i]?.rental_time !== init_array[i + 1]?.rental_time ||
        init_array[i]?.rental_expire_time !== init_array[i + 1]?.rental_expire_time
      ) {
        post_course_set.push(init_array[i]);
      }
    }

    return post_course_set;
  };

  /*
        temp_reducer에 저장된 값 : {product_title, product_id, rental_time, rental_expire_time}
        기업의 강의등록 API에 전달할 값 : {id_set, rental_time, rental_expire_time}
        reducer의 값 -> API에 전달할 값으로 변형 (rental_time, rental_expire_time이 같으면 한번에 id_set([])으로 묶어서 한번에 전달
     */
  const getUsefulProductSet = () => {
    // reducer에 저장된 배열만큼 객체배열을 생성
    let init_array = temporary_reducer.temp_product_set.map((product) => {
      return {
        product_set: [],
        rental_time: product.rental_time,
        rental_expire_time: product.rental_expire_time,
      };
    });

    temporary_reducer.temp_product_set.map((temp_product) => {
      return init_array.map((init_product) => {
        if (
          temp_product.rental_time === init_product.rental_time &&
          temp_product.rental_expire_time === init_product.rental_expire_time
        ) {
          init_product.product_set.push(temp_product.product_id);
        }
        return null;
      });
    });

    let post_product_array = [];
    for (let i = 0; i < init_array.length; i++) {
      if (
        init_array[i]?.rental_time !== init_array[i + 1]?.rental_time ||
        init_array[i]?.rental_expire_time !== init_array[i + 1]?.rental_expire_time
      ) {
        post_product_array.push(init_array[i]);
      }
    }

    return post_product_array;
  };

  return (
    <>
      <CardHeader
        className='f-20 f-w-700'
        style={{
          padding: '20px 20px 20px 30px',
        }}
      >
        <Row>
          <Col className='col-8'>
            <span>기업 추가</span>
            {isLoading && (
              <>
                <Loading isLoading={isLoading} />
                <span
                  className={'txt-primary'}
                  style={{
                    marginLeft: '15px',
                    fontSize: '15px',
                  }}
                >
                  기업생성 중 입니다. 잠시만 기다려주세요...
                </span>
              </>
            )}
          </Col>
          <Col className='col text-right'>
            <Button
              onClick={handleSubmit(onSubmit)}
              className='btn-pill btn-air-dark'
              disabled={isLoading}
              color='primary'
              style={{
                padding: '5px 20px 5px 20px',
              }}
            >
              저장
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody
        style={{
          padding: '30px',
        }}
      >
        <Form className='theme-form' onSubmit={handleSubmit(onSubmit)}>
          <div className='form-row'>
            <Col>
              <CustomSelect2
                label_title='가입/상태'
                options={[{ name: '-', id: '-' }]}
                read_only={true}
                with_edit_button={true}
              />
            </Col>
            <Col md='2'>
              <CustomInput2
                label_title='기업명'
                is_require={true}
                defaultValue={temporary_reducer.temp_company_name}
                name='company_name'
                innerRef={register({ required: true })}
                on_change={(e) => {
                  dispatch(addTemporaryValue('temp_company_name', e.currentTarget.value));
                }}
              />
            </Col>
            <Col md='7'>
              <CustomTypeahead2
                id={'multiple-typeahead'}
                label_title={'기업관리자(이메일)'}
                is_require={true}
                labelKey={'account_name'}
                defaultSelected={manager_account_set}
                multiple={true}
                options={[]}
                need_edit_button={true}
                edit_button_title={'관리자 등록하기'}
                disabled={true}
                need_tool_tip={true}
                tool_tip_content={
                  <>
                    <span>수정이 필요한 경우 관리자 등록을 한번 더 진행해주세요</span>
                  </>
                }
              />
            </Col>
            <Col>
              <CustomInput2
                label_title='사용인원'
                is_require={true}
                name='licensed_employee_count'
                className='form-control'
                defaultValue={temporary_reducer.temp_licensed_employee_count || null}
                type='number'
                innerRef={register({ required: true })}
                on_change={(e) => {
                  broadcast.send(BROADCAST_SET_MAXIMUM_COUNT, e.currentTarget.value);
                  dispatch(
                    addTemporaryValue(
                      'temp_licensed_employee_count',
                      Number(e.currentTarget.value),
                    ),
                  );
                }}
              />
            </Col>
          </div>
        </Form>
      </CardBody>
    </>
  );
};

export default AddCompanyForm;
