import React, { useRef, useState } from 'react';
import { Button, CardBody, CardHeader, Col, Form, Row } from 'reactstrap';
import { REGEX_BIRTH, REGEX_NAME, REGEX_PHONE } from '../../../constant/regexs';
import CustomSelect2 from '../../_common/component/CustomSelect2';
import {
  EMPLOYEE_GENDER_SELECT_OPTIONS,
  EMPLOYEE_STATUS_DELETED,
  EMPLOYEE_STATUS_SELECT_OPTIONS,
} from '../../../constant/options';
import { useForm } from 'react-hook-form';
import { lang } from '../../../lang';
import { dialog_type_confirm, useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import EditButton from '../../_common/component/EditButton';
import CustomInput2 from '../../_common/component/CustomInput2';
import {
  BROADCAST_ON_FORM_SUBMIT,
  useApplicationContext,
} from '../../../context/ApplicationContextProvider';
import CompanyModel from '../../../model/CompanyModel';
import Loading from '../../_common/Loading';
import { useSelector } from 'react-redux';
import CustomTooltip from '../../_common/component/CustomTooltip';
import { useHistory } from 'react-router-dom';
import { autoHyphenPhoneNumber } from '../../../util/b2b_utils';

/*
    props
    current_employee: {}            직원 정보
    isLoading: bool                직원 정보 가져오기의 loading
    setNeedUpdate: setState(bool)   업데이트 필요시 -> setNeedUpdate(true)
 */
const EmployeeInfo = (props) => {
  const { broadcast } = useApplicationContext();
  const { register, handleSubmit, errors, setValue } = useForm();
  const { params } = useParameters();
  const { showAlert } = useShowDialog();
  const history = useHistory();
  const AuthStore = useSelector((state) => state.AuthStore);

  // state
  const [update_loading, setUpdateLoading] = useState(false);

  // ref
  const company_id = useRef(params.company_id);
  const employee_id = useRef(params.employee_id);

  // 직원 정보 업데이트
  const onSubmitUpdateEmployeeInfo = (data) => {
    const edited_employee = {
      ...data,
      status_id: Number(data.status_id),
    };

    // 기존의 직원정보와 동일하면 submit X
    if (
      data.name === props.current_employee.name &&
      data.birth === props.current_employee.birth &&
      data.gender === props.current_employee.gender &&
      data.phone === props.current_employee.phone &&
      Number(data.status_id) === props.current_employee.status_id
    ) {
      showAlert({
        icon: 'warning',
        title: lang.common.fail_change_message,
        confirmButtonText: lang.common.cancel_button_common,
      });
      return false;
    }

    showAlert({
      type: dialog_type_confirm,
      title: lang.common.confirm_change,
      showCancelButton: true,
      confirmButtonText: lang.common.confirm_button_change,
      true_fn: async () => {
        setUpdateLoading(true);

        // 직원 정보 update API
        const update_employee_res = await CompanyModel.updateEmployeeDetail(
          company_id.current,
          employee_id.current,
          edited_employee,
        );
        if (update_employee_res.code === 200) {
          showAlert({
            icon: 'success',
            title: lang.common.success_change_message,
          });

          broadcast.send(BROADCAST_ON_FORM_SUBMIT, {
            disable_input: true,
            is_editable: false,
          });

          props.setNeedUpdate(true);
        } else {
          showAlert({
            title: lang.request.request_error_title,
            text: update_employee_res.message,
          });
        }

        setUpdateLoading(false);
      },
    });
  };

  // 비밀번호 초기화
  const onPasswordInitialization = () => {
    showAlert({
      type: dialog_type_confirm,
      title: lang.employee.confirm_password_initialization,
      showCancelButton: true,
      confirmButtonText: lang.common.confirm_button_initialization,
      true_fn: async () => {
        setUpdateLoading(true);

        // 비밀번호 초기화 API
        const password_initialization_res = await CompanyModel.initializationEmployeePassword(
          company_id.current,
          employee_id.current,
        );
        if (password_initialization_res.code === 200) {
          showAlert({
            type: dialog_type_confirm,
            icon: 'success',
            title: `해당 직원의 비밀번호를 ${password_initialization_res.data.password} 로 초기화했습니다`,
          });
        } else {
          showAlert({
            title: lang.request.request_error_title,
            text: password_initialization_res.message,
          });
        }

        setUpdateLoading(false);
      },
    });
  };

  // 계정 삭제
  const onDeleteAccount = () => {
    showAlert({
      type: dialog_type_confirm,
      title: lang.employee.confirm_delete_account,
      confirmButtonText: lang.common.confirm_button_delete_account,
      showCancelButton: true,
      cancelButtonText: lang.common.cancel_button_resist,
      true_fn: async () => {
        setUpdateLoading(true);

        // 직원 정보 update API
        const update_employee_res = await CompanyModel.updateEmployeeDetail(
          company_id.current,
          employee_id.current,
          {
            ...props.current_employee,
            status_id: EMPLOYEE_STATUS_DELETED,
          },
        );

        if (update_employee_res.code === 200) {
          showAlert({
            icon: 'success',
            title: lang.common.success_delete_message,
          });

          broadcast.send(BROADCAST_ON_FORM_SUBMIT, {
            disable_input: true,
            is_editable: false,
          });

          history.goBack();
        } else {
          showAlert({
            title: lang.request.request_error_title,
            text: update_employee_res.message,
          });
        }

        setUpdateLoading(false);
      },
    });
  };

  return (
    <>
      <CardHeader
        className='f-20 f-w-700'
        style={{
          padding: '20px 20px 20px 30px',
        }}
      >
        <Row>
          <Col className='col-9'>
            <span>직원 정보</span>
            {AuthStore.account_type_id === 3 && (
              <>
                <span
                  id='password_change_info'
                  style={{
                    marginLeft: '5px',
                    cursor: 'pointer',
                  }}
                >
                  <i className='icon-help-alt' />
                </span>
                <CustomTooltip
                  target='password_change_info'
                  placement='right'
                  content={
                    <>
                      <div style={{ marginBottom: '5px' }}>
                        <span style={{ fontWeight: 'bold' }}>1. 비밀번호 초기화</span>
                        <br />
                        <span style={{ color: 'darkgray' }}>
                          베어유 B2B 페이지에서 [비밀번호 찾기]를 눌러주세요
                        </span>
                      </div>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>2. 비밀번호 변경</span>
                        <br />
                        <span style={{ color: 'darkgray' }}>
                          베어유 B2B 페이지에서 [개인정보수정]에서 변경가능합니다
                        </span>
                      </div>
                    </>
                  }
                />
              </>
            )}
            {(props.isLoading || update_loading) && (
              <Loading isLoading={props.isLoading || update_loading} />
            )}
          </Col>
          <EditButton onSubmit={handleSubmit(onSubmitUpdateEmployeeInfo)} />
        </Row>
      </CardHeader>
      {props.current_employee && (
        <CardBody
          style={{
            padding: '30px',
          }}
        >
          <Form className='theme-form' onSubmit={handleSubmit(onSubmitUpdateEmployeeInfo)}>
            <div className='form-row'>
              <Col md='1'>
                <CustomInput2
                  label_title='이름'
                  is_require={true}
                  defaultValue={props.current_employee.name}
                  name='name'
                  placeholder='이름'
                  with_edit_button={true}
                  setValue={setValue}
                  innerRef={register({
                    required: lang.employee.need_fill_all_form,
                    pattern: {
                      value: REGEX_NAME,
                      message: lang.employee.check_name_desc,
                    },
                  })}
                />
              </Col>
              <Col md='3'>
                <CustomInput2
                  label_title='이메일(계정)'
                  defaultValue={props.current_employee.account_name}
                  name='account_name'
                  with_edit_button={true}
                  read_only={true}
                  innerRef={register()}
                />
              </Col>
              <Col md='2'>
                <CustomInput2
                  label_title='생년월일'
                  is_require={true}
                  defaultValue={props.current_employee.birth}
                  name='birth'
                  placeholder='YYYY-MM-DD'
                  max_length={10}
                  with_edit_button={true}
                  setValue={setValue}
                  innerRef={register({
                    required: lang.employee.need_fill_all_form,
                    pattern: {
                      value: REGEX_BIRTH,
                      message: lang.employee.check_birth_desc,
                    },
                  })}
                />
              </Col>
              <Col md='1'>
                <CustomSelect2
                  label_title='성별'
                  is_require={true}
                  name='gender'
                  defaultValue={props.current_employee.gender}
                  setValue={setValue}
                  innerRef={register()}
                  options={EMPLOYEE_GENDER_SELECT_OPTIONS}
                  with_edit_button={true}
                />
              </Col>
              <Col md='2'>
                <CustomInput2
                  label_title='휴대폰'
                  is_require={true}
                  defaultValue={autoHyphenPhoneNumber(props.current_employee.phone)}
                  name='phone'
                  placeholder='010-0000-0000'
                  max_length={13}
                  with_edit_button={true}
                  setValue={setValue}
                  innerRef={register({
                    required: lang.employee.need_fill_all_form,
                    pattern: {
                      value: REGEX_PHONE,
                      message: lang.employee.check_phone_desc,
                    },
                  })}
                />
              </Col>
              <Col>
                <CustomInput2
                  label_title='평균수강률'
                  defaultValue={`${
                    props.current_employee.total_attendance_rate
                      ? (props.current_employee.total_attendance_rate * 100).toFixed(1)
                      : '0'
                  }%`}
                  name='totalProgress'
                  with_edit_button={true}
                  read_only={true}
                />
              </Col>
              <Col>
                <CustomSelect2
                  label_title='상태'
                  is_require={true}
                  name='status_id'
                  defaultValue={props.current_employee.status_id}
                  setValue={setValue}
                  innerRef={register()}
                  options={EMPLOYEE_STATUS_SELECT_OPTIONS}
                  with_edit_button={true}
                />
              </Col>
              <Col md='12 text-center' className='m-t-10'>
                <span
                  style={{
                    color: '#FF0000',
                  }}
                >
                  {errors.name
                    ? errors.name.message
                    : errors.birth
                      ? errors.birth.message
                      : errors.phone
                        ? errors.phone.message
                        : null}
                </span>
              </Col>
            </div>
          </Form>
          {AuthStore.account_type_id === 5 && (
            <Col md='12 text-center' className='m-t-10'>
              <Button
                className='btn-pill btn-air-danger'
                color='danger'
                style={{
                  padding: '.5em .9em',
                }}
                onClick={() => onPasswordInitialization()}
              >
                비밀번호 초기화
              </Button>
              <Button
                className='btn-pill btn-air-info'
                color='info'
                style={{
                  marginLeft: '1em',
                  padding: '.5em .9em',
                }}
                onClick={() => {
                  history.push(
                    `/company/${company_id.current}/employee/${employee_id.current}/pw`,
                    {
                      current_employee: props.current_employee,
                    },
                  );
                }}
              >
                비밀번호 변경
              </Button>
              <Button
                className='btn-pill btn-air-primary'
                style={{
                  marginLeft: '1em',
                  padding: '.5em 1.2em',
                }}
                onClick={onDeleteAccount}
              >
                계정삭제
              </Button>
            </Col>
          )}
        </CardBody>
      )}
    </>
  );
};

export default EmployeeInfo;
