import React, { useRef, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { CSVReader } from 'react-papaparse';
import { useDispatch, useSelector } from 'react-redux';
import { lang } from '../../../lang';
import { dialog_type_confirm, useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import {
  REGEX_BIRTH,
  REGEX_EMAIL,
  REGEX_NAME,
  REGEX_PASSWORD,
  REGEX_PHONE,
} from '../../../constant/regexs';
import { sleep } from '../../../util/b2b_utils';
import CompanyModel from '../../../model/CompanyModel';
import Loading from '../../_common/Loading';
import CustomTooltip from '../../_common/component/CustomTooltip';
import { addTemporaryValue } from '../../../redux/b2b_store/temporary/actions';

/*
    props
    is_csv_upload_modal: state:bool         csv 모달의 열고 닫기 여부
    toggleCsvUploadModal: setState(bool)    is_csv_upload_modal의 변경용 setState
    setAddAccountModal: setState(bool)      EmployeeList.js의 is_add_account_modal의 변경용 setState -> 등록완료 후 모든 모달을 닫기 위함
    employee_set: []                        직원목록
    is_new_company: bool                    기업등록 전의 상태 -> true, 기업등록 이후의 상태 -> false
    licensed_employee_count: state(int)     기업의 사용인원
    setEditable: setState(bool)             EmployeeList.js의 is_editable의 변경용 setState -> 등록완료 후 편집모드 취소를 위함
    new_employee_set: ref([])               새로 추가될 직원 목록 -> csv파일 업로드시에 등록, 모달 닫으면 초기화
    setNeedUpdate: setState(bool)           기업등록 전에 csv파일로 직원 등록 후 업데이트를 위함
 */
const EmployeeCsvUploadModal = (props) => {
  const { showAlert } = useShowDialog();
  const dispatch = useDispatch();
  const { params } = useParameters();
  const company_reducer = useSelector((state) => state.company_reducer);
  const temporary_reducer = useSelector((state) => state.temporary_reducer);

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [submit_loading, setSubmitLoading] = useState(false);
  const [submit_loading_set, setSubmitLoadingSet] = useState([]);
  const [is_downloading, setIsDownLoading] = useState(false);

  // ref
  const csv_button_ref = useRef();
  const company_id = useRef(params.company_id || 1);

  const uploadCsvFile = (e) => {
    if (csv_button_ref.current) {
      csv_button_ref.current.open(e);
    }
  };

  const removeFile = (e) => {
    if (csv_button_ref) {
      csv_button_ref.current.removeFile(e);
    }
  };

  const clear_upload_and_list = async (e) => {
    setIsLoading(true);
    await sleep(500);
    removeFile(e);
    props.new_employee_set.current = [];
    setIsLoading(false);
  };

  const onClickSubmitButton = () => {
    // 새로 등록될 직원이 없는 경우
    if (props.new_employee_set.current.length < 1) {
      showAlert({
        title: lang.csv.did_not_upload_title,
        icon: 'warning',
        text: lang.csv.did_not_upload_desc,
      });
      return false;
    }

    // 기업 등록 전 && 사용인원을 설정하지 않은경우
    if (props.is_new_company && !props.licensed_employee_count) {
      showAlert({
        title: lang.employee.need_set_max_count_title,
        text: lang.employee.need_set_max_count_desc,
        icon: 'warning',
      });
      return false;
    }

    // 현재 직원이 사용인원을 초과하거나 같은 경우
    if (props.employee_set >= props.licensed_employee_count) {
      showAlert({
        title: lang.employee.need_set_max_count_title,
        text: lang.employee.need_set_max_count_desc,
      });
      return false;
    }

    showAlert({
      type: dialog_type_confirm,
      title: lang.common.confirm_resist,
      showCancelButton: true,
      cancelButtonText: lang.common.cancel_button_resist,
      confirmButtonText: lang.common.confirm_button_resist,
      true_fn: async () => {
        setSubmitLoading(true);

        let i = 0;
        let temp_employee_set = temporary_reducer.temp_employee_set || [];

        for (const employee of props.new_employee_set.current) {
          let message;
          let post_employee = {
            ...employee,
            phone: employee.phone.replace(/[-]/g, ''),
          };

          if (!props.is_new_company) {
            // 기업이 등록된 상태라면 postEmployee에서 중복체크
            message = await onSubmitEmployeeAndGetMessage(post_employee);
            props.setNeedUpdate(true);
          } else {
            // 기업이 등록되기 전 상태라면 직접 계정만 중복체크 API를 통해 검증
            temp_employee_set.push(post_employee);
            if (temp_employee_set.length > props.licensed_employee_count) {
              message = '기업에 등록할 수 있는 임직원 수가 초과되었습니다';
              temp_employee_set.pop();
            } else if (await checkDuplicateAccount(post_employee)) {
              message = '이미 존재하는 사용자입니다';
              temp_employee_set.pop();
            } else {
              message = 'success';
            }
          }
          onHandleSubmitLoadingSet(message, i);

          await sleep(500);

          i += 1;
        }

        if (props.is_new_company) {
          dispatch(addTemporaryValue('temp_employee_set', temp_employee_set));
          props.setNeedUpdate(true);
        }

        setSubmitLoading(false);

        let is_error = false;
        submit_loading_set.map(({ success }) => {
          // 등록되는 직원 중 error가 발생한 직원이 있다면 true
          if (!success) {
            is_error = true;
            return false;
          }
          return null;
        });

        if (is_error) {
          showAlert({
            title: lang.employee.resist_except_error,
            icon: 'info',
            text: lang.csv.need_check_csv,
            confirmButtonText: lang.common.success_save_button,
          });
        } else {
          showAlert({
            title: lang.common.success_resist_message_common,
            icon: 'success',
            text: props.is_new_company ? lang.company.need_fill_company_assignment : null,
            confirmButtonText: lang.common.success_save_button,
          });
        }

        removeFile();
        props.toggleCsvUploadModal();
        props.setAddAccountModal(false);
        props.setEditable(false);
      },
      false_fn: () => {
        removeFile();
      },
    });
  };

  const onSubmitEmployeeAndGetMessage = async (employee) => {
    // 직원등록 API
    const post_employee_res = await CompanyModel.postEmployee(company_reducer.company_id, employee);
    return post_employee_res.message;
  };

  // 직원들 개인별로 등록 message handle 함수
  const onHandleSubmitLoadingSet = (message, i) => {
    if (message === 'success') {
      let tmp = [...submit_loading_set];
      tmp[i].loading = false;
      tmp[i].success = true;
      tmp[i].message = (
        <span className={'text-primary'}>
          <i className='icofont icofont-check' />
          등록
        </span>
      );
      setSubmitLoadingSet(tmp);
    } else if (message === '이미 존재하는 사용자입니다') {
      let tmp = [...submit_loading_set];
      tmp[i].loading = false;
      tmp[i].message = (
        <span className={'text-danger'}>
          <i className='icofont icofont-exclamation-circle' />
          &nbsp;계정중복
        </span>
      );
      setSubmitLoadingSet(tmp);
    } else if (message === '기업에 등록할 수 있는 임직원 수가 초과되었습니다') {
      let tmp = [...submit_loading_set];
      tmp[i].loading = false;
      tmp[i].message = (
        <span className={'text-danger'}>
          <i className='icofont icofont-exclamation-circle' />
          인원초과
        </span>
      );
      setSubmitLoadingSet(tmp);
    } else {
      let tmp = [...submit_loading_set];
      tmp[i].loading = false;
      tmp[i].message = (
        <span className={'text-danger'}>
          <i className='icofont icofont-exclamation-circle' />
          오류
        </span>
      );
      setSubmitLoadingSet(tmp);
    }
  };

  // 기업 등록 전 사용하는 계정 중복체크 func
  const checkDuplicateAccount = async (employee) => {
    let duplicate_account;
    let copy_temp_employee_set = [];

    const duplicate_check_res = await CompanyModel.getDuplicateEmployeeCheck(employee.account_name);
    if (duplicate_check_res.code === 4501) {
      // 타 회사에 등록된 중복계정이므로 checkDuplicateAccount가 true를 반환해야 함
      duplicate_account = 1;
    } else if (duplicate_check_res.code === 200) {
      // 타 회사에 등록되지 않은 계정은 temp_employee_set과 비교하여 이미 temp에 있는 계정이라면 중복계정
      if (temporary_reducer.temp_employee_set) {
        copy_temp_employee_set = [...temporary_reducer.temp_employee_set];

        copy_temp_employee_set.pop();

        // temporary_reducer.temp_employee_set과 비교하여 중복체크
        duplicate_account = copy_temp_employee_set.find((exist_employee) => {
          return exist_employee.account_name === employee.account_name;
        });
      }
    }

    return !!duplicate_account;
  };

  const onCSVRequest = async (e) => {
    if (!is_downloading) {
      setIsDownLoading(true);
      e.target.diabled = true;

      const _a = document.createElement('a');
      _a.style = 'display: none';

      // 직원등록용 csv파일 샘플 가져오기 API
      const _file = await CompanyModel.getEmployeeCSV(company_id.current);

      const url = window.URL.createObjectURL(_file.data);
      _a.href = url;
      _a.download = 'BEARU B2B 직원등록 샘플';
      _a.click();
      window.URL.revokeObjectURL(url);

      e.target.disable = false;
      setIsDownLoading(false);
    }
  };

  return (
    <CSVReader
      ref={csv_button_ref}
      onFileLoad={(results) => {
        if (results.length) {
          props.new_employee_set.current = [];
          setSubmitLoadingSet([]);

          setIsLoading(true);

          let error_msg = lang.employee;

          results.map(({ data }, i) => {
            const index_msg = `${data['이름'] || data['이메일(계정)'] || i + 1 + '번'} 직원의 `;

            // 이름이 없거나 정규식 통과 X
            if (!data['이름'] || !REGEX_NAME.test(data['이름'])) {
              showAlert({
                title: index_msg + error_msg.check_name_title,
                text: error_msg.check_name_desc,
              });

              return false;
              // 이메일이 없거나 정규식 통과 X
            } else if (!data['이메일(계정)'] || !REGEX_EMAIL.test(data['이메일(계정)'])) {
              showAlert({
                title: index_msg + error_msg.check_email_title,
                text: error_msg.check_email_desc,
              });

              return false;
              // 비밀번호가 없거나 정규식 통과 X
            } else if (!data['비밀번호'] || !REGEX_PASSWORD.test(data['비밀번호'])) {
              showAlert({
                title: index_msg + error_msg.check_password_title,
                text: error_msg.check_password_desc,
              });

              return false;
              // 성별이 없거나 남 || 여가 아닌경우
            } else if (!data['성별'] || !(data['성별'] === '남' || data['성별'] === '여')) {
              showAlert({
                title: index_msg + error_msg.check_gender_title,
                text: error_msg.check_gender_desc,
              });

              return false;
              // 생년월일이 없어가 정규식 통과 X
            } else if (!data['생년월일'] || !REGEX_BIRTH.test(data['생년월일'])) {
              showAlert({
                title: index_msg + error_msg.check_birth_title,
                text: error_msg.check_birth_desc,
              });

              return false;
              // 휴대폰번호가 없거나 정규식 통과 X
            } else if (!data['휴대폰'] || !REGEX_PHONE.test(data['휴대폰'])) {
              showAlert({
                title: index_msg + error_msg.check_phone_title,
                text: error_msg.check_phone_desc,
              });

              return false;
            }

            let employee = {
              name: data['이름'],
              account_name: data['이메일(계정)'],
              password: data['비밀번호'],
              gender: data['성별'] === '남' ? 'male' : 'female',
              birth: data['생년월일'],
              phone: data['휴대폰'],
            };

            return props.new_employee_set.current.push(employee);
          });
        } else {
          showAlert({
            title: lang.csv.have_no_data,
          });
        }

        // 새로 등록될 직원의 수만큼 submit_loading_set 추가
        props.new_employee_set.current.map(() => {
          return setSubmitLoadingSet((prevState) => [
            ...prevState,
            {
              loading: true,
              message: null,
              success: false,
            },
          ]);
        });

        setIsLoading(false);
      }}
      noClick
      noDrag
      config={{ encoding: 'UTF-16LE', header: true, skipEmptyLines: 'greedy' }}
      style={{}}
    >
      {({ file }) => (
        <Modal
          isOpen={props.is_csv_upload_modal}
          toggle={props.toggleCsvUploadModal}
          centered
          size='lg'
        >
          <ModalHeader style={{ display: 'block' }}>
            <Row>
              <Col md={'8'}>
                <span style={{ color: 'grey' }}>
                  CSV 파일은 옆의 샘플 파일을 통해서 제출해주시길 바랍니다
                </span>
                {is_downloading && <Loading isLoading={is_downloading} />}
              </Col>
              <Col md={'4'}>
                <div className='text-right'>
                  <Button className={'btn-sm'} color={'primary'} onClick={onCSVRequest}>
                    csv 샘플 다운로드
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody style={{ maxHeight: '40em', overflow: 'auto' }}>
            <Row>
              <Col md='12'>
                <h5>업로드 된 직원 목록</h5>
              </Col>
              <Col md='12'>
                <Table striped className='m-t-15' style={{ margin: 'auto' }}>
                  <thead>
                    <tr>
                      <th scope='col'>
                        <>
                          <span style={{ cursor: 'pointer' }} id='ex-name'>
                            이름&nbsp;
                            <div style={{ display: 'inline-flex' }}>
                              <i className='icon-help-alt' />
                            </div>
                          </span>
                          <CustomTooltip target='ex-name' content={'한글/영문/숫자 2자 이상'} />
                        </>
                      </th>
                      <th scope='col'>
                        <span>이메일(계정)&nbsp;</span>
                      </th>
                      <th scope='col'>
                        <>
                          <span style={{ cursor: 'pointer' }} id='ex-password'>
                            비밀번호&nbsp;
                            <div style={{ display: 'inline-flex' }}>
                              <i className='icon-help-alt' />
                            </div>
                          </span>
                          <CustomTooltip target='ex-password' content={'4자 이상'} />
                        </>
                      </th>
                      <th scope='col'>
                        <>
                          <span style={{ cursor: 'pointer' }} id='ex-birth'>
                            생년월일&nbsp;
                            <div style={{ display: 'inline-flex' }}>
                              <i className='icon-help-alt' />
                            </div>
                          </span>
                          <CustomTooltip target='ex-birth' content={'YYYY-MM-DD'} />
                        </>
                      </th>
                      <th scope='col'>
                        <>
                          <span style={{ cursor: 'pointer' }} id='ex-gender'>
                            성별&nbsp;
                            <div style={{ display: 'inline-flex' }}>
                              <i className='icon-help-alt' />
                            </div>
                          </span>
                          <CustomTooltip target='ex-gender' content={'남 또는 여'} />
                        </>
                      </th>
                      <th scope='col'>
                        <>
                          <span style={{ cursor: 'pointer' }} id='ex-phone'>
                            휴대폰&nbsp;
                            <div style={{ display: 'inline-flex' }}>
                              <i className='icon-help-alt' />
                            </div>
                          </span>
                          <CustomTooltip target='ex-phone' content={'010-0000-0000'} />
                        </>
                      </th>
                      {submit_loading ? <th scope='col'>등록여부</th> : null}
                    </tr>
                  </thead>
                  {props.new_employee_set.current.length ? (
                    <tbody>
                      {!isLoading ? (
                        props.new_employee_set.current.map((employee, i) => (
                          <tr key={i}>
                            <td>{employee.name}</td>
                            <td>{employee.account_name}</td>
                            <td>{employee.password}</td>
                            <td>{employee.birth}</td>
                            <td>{employee.gender === 'male' ? '남' : '여'}</td>
                            <td>{employee.phone}</td>
                            {submit_loading ? (
                              <td>
                                {submit_loading_set[i].loading ? (
                                  <Loading isLoading={submit_loading_set[i].loading} />
                                ) : (
                                  submit_loading_set[i].message
                                )}
                              </td>
                            ) : null}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan='6' style={{ textAlign: 'center' }}>
                            <div className='loader-box'>
                              <div className='loader-15' />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan='6' style={{ textAlign: 'center' }}>
                          업로드된 csv파일이 없습니다.
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{ textAlign: 'center' }}>
            <Button
              className='btn-pill btn-air-priamry'
              color='primary'
              onClick={uploadCsvFile}
              style={{
                padding: '0.5em 2em',
                marginLeft: 'auto',
              }}
            >
              업로드하기
            </Button>
            <Button
              className='btn-pill btn-air-info'
              color='info'
              onClick={onClickSubmitButton}
              style={{
                padding: '0.5em 2em',
              }}
            >
              등록하기
            </Button>
            <Button
              className='btn-pill btn-air-danger'
              id='remove-btn'
              color='danger'
              style={{
                padding: '0.5em 2em',
                marginRight: 'auto',
              }}
              onClick={(e) => {
                clear_upload_and_list(e);
              }}
            >
              업로드 파일 제거하기
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </CSVReader>
  );
};

export default EmployeeCsvUploadModal;
