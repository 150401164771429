import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
  CardBody,
} from 'reactstrap';
import CustomInput from '../_common/component/CustomInput';
import CustomFileInput from '../_common/component/CustomFileInput';
import CommonModel from '../../model/CommonModel';
import TabContent from 'reactstrap/es/TabContent';
import TabPane from 'reactstrap/es/TabPane';
import InvoiceModel from '../../model/InvoiceModel';
import { Tooltip } from 'react-tippy';
import FinalInvoiceViewCard from './atom/FinalInvoiceViewCard';
import { useSelector } from 'react-redux';
import { changed_round, getInvoiceType, initComma } from '../../util/common';
import InvoiceInfoTable from './atom/InvoiceInfoTable';
import SweetAlert from 'sweetalert2';
import Loading from '../_common/Loading';
import { isMobile } from 'react-device-detect';

const InvoiceDetail = (props) => {
  const { account_id, invoice_date } = props.match.params;
  const AuthStore = useSelector((state) => state.AuthStore);
  const [isEdit, setIsEdit] = useState(false);
  const [is_admin, setAdmin] = useState(true);
  const [is_closed, setClosed] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [invoice_info_exists, setInvoiceInfoExists] = useState(true);
  const [service_info, setServiceInfo] = useState({});
  const [static_own_invoice_type_set, setOwnStaticInvoiceTypeSet] = useState([]); // static 기본 선택 가능한 범위
  const [static_invoice_type_set, setStaticInvoiceTypeSet] = useState([]); // static 기본 선택 가능한 범위
  const [invoice_info_set, setInvoiceInfoSet] = useState([]);
  const [invoice_channel_set, setInvoiceChannelSet] = useState([]);
  const [account, setAccount] = useState({});
  const [invoice, setInvoice] = useState({});
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [BasicLineTab, setBasicLineTab] = useState('1');

  const calculate_invoice = (invoice_channel_set) => {
    let tmp_invoice = {
      i_total_after_tax_price: 0,
      c_total_after_tax_price: 0,
      tf_total_after_tax_price: 0,
      title: '',
      type_flag: 0,
      total_invoice_price: 0,
      attach_message: invoice.attach_message ? invoice.attach_message : null,
      attach_archive_file_url: invoice.attach_archive_file_url
        ? invoice.attach_archive_file_url
        : null,
      attach_archive_file_password: invoice.attach_archive_file_password
        ? invoice.attach_archive_file_password
        : null,
    };

    for (const invoice_channel of invoice_channel_set) {
      // package is_hidden
      if (invoice_channel.is_hidden) continue;
      if (Number(invoice_channel.invoice_user_type) === 1) {
        tmp_invoice.i_total_after_tax_price += invoice_channel.invoice_price;
        tmp_invoice.title = '원천세 항목';
        tmp_invoice.type_flag = Number(invoice_channel.invoice_user_type);
      } else if (Number(invoice_channel.invoice_user_type) === 2) {
        tmp_invoice.c_total_after_tax_price += invoice_channel.invoice_price;
        tmp_invoice.title = '부가세 항목';
        tmp_invoice.type_flag = Number(invoice_channel.invoice_user_type);
      } else if (Number(invoice_channel.invoice_user_type) === 3) {
        tmp_invoice.tf_total_after_tax_price += invoice_channel.invoice_price;
      }
    }

    tmp_invoice.c_total_after_tax_price = Math.round(tmp_invoice.c_total_after_tax_price * 1.1);
    tmp_invoice.i_total_after_tax_price = Math.round(tmp_invoice.i_total_after_tax_price * 0.967);
    tmp_invoice.tf_total_after_tax_price = Math.round(tmp_invoice.tf_total_after_tax_price);

    if (tmp_invoice.type_flag === 2) {
      tmp_invoice.total_invoice_price =
        tmp_invoice.tf_total_after_tax_price + tmp_invoice.c_total_after_tax_price;
    } else {
      tmp_invoice.total_invoice_price =
        tmp_invoice.tf_total_after_tax_price + tmp_invoice.i_total_after_tax_price;
    }
    setInvoice(tmp_invoice);
  };

  const initData = async () => {
    setIsLoading(true);
    const temp_date = invoice_date.split('-');
    const year = Number(temp_date[0]);
    const month = Number(temp_date[1]);
    setYear(year);
    setMonth(month);
    //회사 정보
    CommonModel.getServiceInfo().then(({ service_info }) => {
      setServiceInfo(service_info);
    });

    // 정산 정보 불러오기
    try {
      const { account, invoice_channel_set, invoice, is_closed, tmp_invoice_set } =
        await InvoiceModel.getMasterInvoiceDetail(account_id, year, month);

      for (const invoice of invoice_channel_set) {
        invoice['commission_rate'] = invoice.commission_rate * 100;
        if (!is_closed) {
          if (invoice['course_id']) invoice['is_hidden'] = false;
          else invoice['is_hidden'] = true;
        }
      }

      for (const tmp_invoice of tmp_invoice_set)
        tmp_invoice['commission_rate'] = tmp_invoice.commission_rate * 100;
      let new_invoice_channel_set = [];
      if (AuthStore.account_type_id === 2) {
        new_invoice_channel_set = [...invoice_channel_set.filter((data) => !data.is_hidden)];
      } else {
        new_invoice_channel_set = [...invoice_channel_set, ...tmp_invoice_set];
      }

      // 유저 정보
      setAccount(account);
      if (!account.invoice_info_set[0]?.invoice_info.length > 0) {
        setInvoiceInfoExists(false);
      }

      //정산 완료 되어 있는지
      setClosed(is_closed);

      //강사 정산 정보 (강의별로)
      let tmp_invoice_info_set = [];
      for (let i = 0; i < account.invoice_info_set.length; i++) {
        let bank_holder = account.invoice_info_set[i]?.invoice_info[0]?.bank_holder
          ? account.invoice_info_set[i].invoice_info[0].bank_holder
          : '';
        let bank_name = account.invoice_info_set[i].invoice_info[0]?.bank_name
          ? account.invoice_info_set[i].invoice_info[0].bank_name
          : '';
        let bank_number = account.invoice_info_set[i].invoice_info[0]?.bank_number
          ? account.invoice_info_set[i].invoice_info[0].bank_number
          : '';
        let invoice_user_type = account.invoice_info_set[i].invoice_info[0]?.invoice_user_type
          ? account.invoice_info_set[i].invoice_info[0].invoice_user_type
          : 1;
        let short_title = account.invoice_info_set[i]?.short_title;
        let course_id = account.invoice_info_set[i]?.id;
        let commission_rate = account.invoice_info_set[i].invoice_info[0]?.commission_rate
          ? account.invoice_info_set[i].invoice_info[0].commission_rate
          : 0;
        tmp_invoice_info_set.push({
          course_id: course_id,
          bank_holder: bank_holder,
          bank_name: bank_name,
          bank_number: bank_number,
          invoice_user_type: invoice_user_type,
          short_title: short_title,
          commission_rate: commission_rate,
        });
      }

      // 정산 정보
      setInvoiceInfoSet(tmp_invoice_info_set);

      // 정산 된 경우
      if (invoice) {
        if (invoice.i_total_after_tax_price > 0) {
          invoice.title = '원천세 항목';
          invoice.type_flag = 1;
        } else {
          invoice.title = '부가세 항목';
          invoice.type_flag = 2;
        }
        setInvoice(invoice);
      } else {
        calculate_invoice(new_invoice_channel_set);
      }
      setInvoiceChannelSet(new_invoice_channel_set);

      // 정산 타입
      CommonModel.staticCode({ code_name: 'master_invoice_type' }).then(({ code_set }) => {
        setOwnStaticInvoiceTypeSet([
          ...code_set.filter(
            (data) => account?.invoice_info_set[0]?.invoice_info[0]?.invoice_user_type === data.id,
          ),
          ...[code_set[code_set.length - 1]],
        ]);
        setStaticInvoiceTypeSet(code_set);
      });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setAdmin(AuthStore.account_type_id === 2 ? false : true);
    initData();
  }, []);

  // add row
  const addInvoiceChannel = () => {
    const add_invoice = {
      title: '',
      purchased_price: 0,
      refund_price: 0,
      total_price: 0,
      supply_price: 0,
      tax_free_price: 0,
      vat: 0,
      invoice_user_type: invoice_info_set.length > 0 ? invoice_info_set[0].invoice_user_type : 3,
      commission_rate: 0,
      invoice_price: 0,
      is_hidden: 1,
      tmp_flag: true,
    };
    setInvoiceChannelSet([...invoice_channel_set, add_invoice]);
    calculate_invoice([...invoice_channel_set, add_invoice]);
  };

  // delete row
  const removeInvoiceChannel = async (index) => {
    let temp_invoice_set = [];
    // 임시저장에 있는거 제거
    if (invoice_channel_set[index].temp_id) {
      await InvoiceModel.deleteInvoiceTemp(invoice_channel_set[index].temp_id);
      SweetAlert.fire({
        icon: 'success',
        text: '성공적으로 삭제되었습니다.',
      });
      initData();
    } else {
      temp_invoice_set = invoice_channel_set.filter((item, i) => i !== index);
    }
    setInvoiceChannelSet(temp_invoice_set);
    calculate_invoice(temp_invoice_set);
  };

  // hide
  const hideInvoiceChannel = (index) => {
    const cur_invoice = invoice_channel_set[index];
    cur_invoice['is_hidden'] = !cur_invoice['is_hidden'];
    setInvoiceChannelSet([...invoice_channel_set]);
    calculate_invoice([...invoice_channel_set]);
  };

  // onChange 금액 계산
  const onChangeCalculate = (e, index) => {
    const { name, value } = e.target;
    const cur_invoice = invoice_channel_set[index];

    cur_invoice[name] = value;

    if (name === 'purchased_price') {
      cur_invoice['total_price'] = Number(value) - cur_invoice['refund_price'];
    } else if (name === 'refund_price') {
      cur_invoice['total_price'] = cur_invoice['purchased_price'] - Number(value);
    } else if (name === 'invoice_user_type' && Number(value) === 3) {
      cur_invoice['commission_rate'] = 100;
    }

    cur_invoice['vat'] = changed_round(
      (cur_invoice['purchased_price'] -
        cur_invoice['refund_price'] -
        cur_invoice['tax_free_price']) *
        0.1,
    );
    if (cur_invoice['vat'] < 0) cur_invoice['vat'] = 0;
    cur_invoice['supply_price'] = cur_invoice['total_price'] - cur_invoice['vat'];
    cur_invoice['invoice_price'] = Math.round(
      cur_invoice['supply_price'] * (cur_invoice['commission_rate'] / 100),
    );

    setInvoiceChannelSet([...invoice_channel_set]);
    calculate_invoice([...invoice_channel_set]);
  };

  // 정산정보 onChange
  const invoiceInfoOnChange = (e) => {
    setInvoice((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // 정산정보 save
  const saveInvoiceInfo = async () => {
    try {
      const invoice_info_list = await InvoiceModel.postInvoiceInfo(account_id, invoice_info_set);
      if (invoice_info_list.length > 0) {
        setIsEdit(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 임시저장
  const tempInvoiceSave = async () => {
    const temp_invoice_set = invoice_channel_set.filter((data) => data.tmp_flag | data.temp_id);

    if (temp_invoice_set.length > 0) {
      const tmp_invoice_set = await InvoiceModel.postInvoiceTemp(
        account_id,
        temp_invoice_set,
        year,
        month,
      );
      if (tmp_invoice_set.length > 0) {
        SweetAlert.fire({
          icon: 'success',
          text: '성공적으로 저장이 완료되었습니다.',
        });
        initData();
      }
    } else {
      SweetAlert.fire({
        icon: 'warning',
        text: '저장할 데이터가 없습니다.',
      });
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent='정산' title='정산 상세페이지' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card className='input-air-primary'>
              <CardBody>
                <div className='invoice'>
                  <Row>
                    <Col sm='6'>
                      <Media>
                        <Media body className='m-l-20'>
                          <h4 className='media-heading'>주식회사 텐덤</h4>
                          <p>
                            이메일:{' '}
                            <a href={`mailto:${service_info.contact_email}`}>
                              {service_info.contact_email}
                            </a>
                            <br />
                            연락처:{' '}
                            <span className='digits'>{service_info.contact_tel_number}</span>
                            <br />
                            주소: <span className='digits'>{service_info.company_address}</span>
                          </p>
                        </Media>
                      </Media>
                    </Col>
                    <Col sm='6'>
                      <div className='text-md-right' style={isMobile ? { marginTop: '15px' } : {}}>
                        {isMobile ? (
                          <h4>
                            <Loading isLoading={isLoading} />
                            <span
                              style={{
                                marginLeft: '15px',
                                fontWeight: '600',
                                color: '#7e37d8',
                              }}
                            >
                              {year}년 {month}월
                            </span>{' '}
                            정산 내역
                          </h4>
                        ) : (
                          <h3>
                            <Loading isLoading={isLoading} />
                            <span
                              style={{
                                marginLeft: '15px',
                                fontWeight: '600',
                                color: '#7e37d8',
                              }}
                            >
                              {year}년 {month}월
                            </span>{' '}
                            정산 내역
                          </h3>
                        )}
                        <p>
                          {is_closed ? (
                            <span className='badge badge-primary'>정산 완료</span>
                          ) : (
                            <span className='badge badge-warning'>정산 예정</span>
                          )}
                          <br />
                          매출 기간
                          <br />
                          <span className='digits'>
                            {`${year}-${month}-01`} ~ {`${year}-${month}-30`}
                          </span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <hr />
                <Row>
                  <Col xl='4' lg='12' md='4'>
                    <Media>
                      <Media body className='m-l-20'>
                        <h4 className='media-heading'>{account.nickname}</h4>
                        <p>
                          이메일 : {account.email}
                          <br />
                          연락처 : <span className='digits'>{account.phone}</span>
                        </p>
                      </Media>
                    </Media>
                  </Col>
                  <Col xl='8' lg='12' md='8'>
                    <div className='text-md-right' id='project'>
                      <h6>설명</h6>
                      <p>
                        이름 : {account.name}
                        <br />
                        주소 : {account.address}
                      </p>
                    </div>
                  </Col>
                </Row>
                <div style={{ marginTop: '30px' }} className='table-responsive'>
                  <Table>
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <th>
                          <Col className={'p-2 invoice-field-name'}>상품명</Col>
                        </th>
                        <th
                          style={
                            is_admin
                              ? {
                                  minWidth: '140px',
                                  maxWidth: '140px',
                                  width: '140px',
                                }
                              : {
                                  minWidth: '160px',
                                  maxWidth: '160px',
                                  width: '160px',
                                }
                          }
                        >
                          <Row>
                            <Tooltip
                              html={
                                <div style={{ whiteSpace: 'pre' }} className='p-2 rounded bg-dark'>
                                  강의 매출 금액
                                </div>
                              }
                              position='top'
                              className='p-2 mb-0'
                              style={{
                                marginLeft: '15%',
                                marginTop: '2px',
                                marginRight: '-10px',
                              }}
                              trigger='mouseenter'
                            >
                              <i className='pe-7s-help1' />
                            </Tooltip>
                            <p
                              className={
                                is_closed
                                  ? 'p-2 mb-0 invoice-closed-field-name'
                                  : 'p-2 mb-0 invoice-field-name'
                              }
                            >
                              실 매출금액
                            </p>
                          </Row>
                        </th>
                        <th
                          style={
                            is_admin
                              ? {
                                  minWidth: '120px',
                                  maxWidth: '120px',
                                  width: '120px',
                                }
                              : {
                                  minWidth: '140px',
                                  maxWidth: '140px',
                                  width: '140px',
                                }
                          }
                        >
                          <Row>
                            <Tooltip
                              html={
                                <div style={{ whiteSpace: 'pre' }} className='p-2 rounded bg-dark'>
                                  강의 환불 금액
                                </div>
                              }
                              position='top'
                              className='p-2 mb-0'
                              style={{
                                marginLeft: '15%',
                                marginTop: '2px',
                                marginRight: '-10px',
                              }}
                              trigger='mouseenter'
                            >
                              <i className='pe-7s-help1' />
                            </Tooltip>
                            <p
                              className={
                                is_closed
                                  ? 'p-2 mb-0 invoice-closed-field-name'
                                  : 'p-2 mb-0 invoice-field-name'
                              }
                            >
                              환불금액
                            </p>
                          </Row>
                        </th>
                        <th
                          style={{
                            minWidth: '150px',
                            maxWidth: '150px',
                            width: '150px',
                          }}
                        >
                          <Row>
                            <Tooltip
                              html={
                                <div style={{ whiteSpace: 'pre' }} className='p-2 rounded bg-dark'>
                                  (실 매출금액 - 환불금액)
                                </div>
                              }
                              position='top'
                              className='p-2 mb-0'
                              style={{
                                marginLeft: '20%',
                                marginTop: '2px',
                                marginRight: '-10px',
                              }}
                              trigger='mouseenter'
                            >
                              <i className='pe-7s-help1' />
                            </Tooltip>
                            <p
                              className={
                                is_closed
                                  ? 'p-2 mb-0 invoice-closed-field-name'
                                  : 'p-2 mb-0 invoice-field-name'
                              }
                            >
                              합계금액
                            </p>
                          </Row>
                        </th>
                        <th
                          style={{
                            minWidth: '130px',
                            maxWidth: '130px',
                            width: '130px',
                          }}
                        >
                          <Row>
                            <Tooltip
                              html={
                                <div style={{ whiteSpace: 'pre' }} className='p-2 rounded bg-dark'>
                                  (면세금액)
                                </div>
                              }
                              position='top'
                              className='p-2 mb-0'
                              style={{
                                marginLeft: '20%',
                                marginTop: '2px',
                                marginRight: '-10px',
                              }}
                              trigger='mouseenter'
                            >
                              <i className='pe-7s-help1' />
                            </Tooltip>
                            <p
                              className={
                                is_closed
                                  ? 'p-2 mb-0 invoice-closed-field-name'
                                  : 'p-2 mb-0 invoice-field-name'
                              }
                            >
                              면세가
                            </p>
                          </Row>
                        </th>
                        <th
                          style={{
                            minWidth: '130px',
                            maxWidth: '130px',
                            width: '130px',
                          }}
                        >
                          <Row>
                            <Tooltip
                              html={
                                <div style={{ whiteSpace: 'pre' }} className='p-2 rounded bg-dark'>
                                  (합계금액 / 1.1)
                                </div>
                              }
                              position='top'
                              className='p-2 mb-0'
                              style={{
                                marginLeft: '20%',
                                marginTop: '2px',
                                marginRight: '-10px',
                              }}
                              trigger='mouseenter'
                            >
                              <i className='pe-7s-help1' />
                            </Tooltip>
                            <p
                              className={
                                is_closed
                                  ? 'p-2 mb-0 invoice-closed-field-name'
                                  : 'p-2 mb-0 invoice-field-name'
                              }
                            >
                              공급가
                            </p>
                          </Row>
                        </th>
                        <th
                          style={{
                            minWidth: '130px',
                            maxWidth: '130px',
                            width: '130px',
                          }}
                        >
                          <Row>
                            <Tooltip
                              html={
                                <div style={{ whiteSpace: 'pre' }} className='p-2 rounded bg-dark'>
                                  (0)
                                </div>
                              }
                              position='top'
                              className='p-2 mb-0'
                              style={{
                                marginLeft: '17%',
                                marginTop: '2px',
                                marginRight: '-10px',
                              }}
                              trigger='mouseenter'
                            >
                              <i className='pe-7s-help1' />
                            </Tooltip>
                            <p
                              className={
                                is_closed
                                  ? 'p-2 mb-0 invoice-closed-field-name'
                                  : 'p-2 mb-0 invoice-field-name'
                              }
                            >
                              부가세
                            </p>
                          </Row>
                        </th>
                        <th
                          style={{
                            minWidth: '110px',
                            maxWidth: '110px',
                            width: '110px',
                          }}
                        >
                          <p style={{ fontSize: '14px', fontWeight: '550' }} className='p-2 mb-0'>
                            수익분배율
                          </p>
                        </th>
                        <th
                          style={{
                            minWidth: '110px',
                            maxWidth: '110px',
                            width: '110px',
                          }}
                        >
                          <p style={{ fontSize: '14px', fontWeight: '550' }} className='p-2 mb-0'>
                            정산유형
                          </p>
                        </th>
                        <th
                          style={
                            is_admin
                              ? {
                                  minWidth: '150px',
                                  maxWidth: '150px',
                                  width: '150px',
                                }
                              : {
                                  minWidth: '190px',
                                  maxWidth: '190px',
                                  width: '190px',
                                }
                          }
                        >
                          <Row>
                            <Tooltip
                              html={
                                <div style={{ whiteSpace: 'pre' }} className='p-2 rounded bg-dark'>
                                  (공급가 * 수익분배율)
                                </div>
                              }
                              position='top'
                              className='p-2 mb-0'
                              style={
                                is_admin
                                  ? {
                                      marginLeft: '20%',
                                      marginTop: '2px',
                                      marginRight: '-10px',
                                    }
                                  : {
                                      marginLeft: '29%',
                                      marginTop: '2px',
                                      marginRight: '-10px',
                                    }
                              }
                              trigger='mouseenter'
                            >
                              <i className='pe-7s-help1' />
                            </Tooltip>
                            <p
                              className={
                                is_closed
                                  ? 'p-2 mb-0 invoice-closed-field-name'
                                  : 'p-2 mb-0 invoice-field-name'
                              }
                            >
                              정산금액
                            </p>
                          </Row>
                        </th>
                        {is_admin && !is_closed && (
                          <>
                            <th
                              style={{
                                minWidth: '80px',
                                maxWidth: '80px',
                                width: '80px',
                              }}
                            >
                              <p
                                style={{ fontSize: '14px', fontWeight: '550' }}
                                className='p-2 mb-0'
                              >
                                삭제
                              </p>
                            </th>
                            <th
                              style={{
                                minWidth: '80px',
                                maxWidth: '80px',
                                width: '80px',
                              }}
                            >
                              <p
                                style={{ fontSize: '14px', fontWeight: '550' }}
                                className='p-2 mb-0'
                              >
                                숨김
                              </p>
                            </th>
                          </>
                        )}
                      </tr>
                      {invoice_channel_set
                        ? invoice_channel_set.map((invoice, invoice_index) => {
                            // table css check
                            let tr_style = {};
                            if (!invoice.id) {
                              if (invoice.temp_id) {
                                if (invoice.is_hidden)
                                  tr_style = {
                                    opacity: 0.3,
                                    backgroundColor: '#7E37D8',
                                  };
                                else tr_style = { backgroundColor: '#7E37D8' };
                              } else {
                                if (invoice.is_hidden) tr_style = { opacity: 0.3 };
                              }
                            } else {
                              if (invoice.is_hidden) tr_style = { backgroundColor: '#e9ecef' };
                            }
                            return (
                              <tr key={invoice_index} style={tr_style}>
                                <td className='text-center'>
                                  {is_admin ? (
                                    <Input
                                      name='title'
                                      onChange={(e) => {
                                        onChangeCalculate(e, invoice_index);
                                      }}
                                      disabled={is_closed}
                                      type='text'
                                      style={
                                        isMobile
                                          ? { width: 'auto', fontSize: '13px' }
                                          : { fontSize: '13px' }
                                      }
                                      value={invoice.title || ''}
                                    />
                                  ) : (
                                    <p
                                      style={{
                                        fontSize: '13px',
                                        marginTop: '6px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {invoice.title}
                                    </p>
                                  )}
                                </td>
                                <td className='text-center'>
                                  {is_admin ? (
                                    <Row className='ml-1'>
                                      <Input
                                        name='purchased_price'
                                        onChange={(e) => {
                                          const regexp = /^[0-9\b]*$/;
                                          if (regexp.test(e.target.value)) {
                                            onChangeCalculate(e, invoice_index);
                                          }
                                        }}
                                        disabled={is_closed}
                                        type='text'
                                        style={{ fontSize: '13px', width: '80%' }}
                                        value={invoice.purchased_price}
                                      />
                                      <span style={{ lineHeight: 'calc(1.5em + 0.75rem + 2px)' }}>
                                        원
                                      </span>
                                    </Row>
                                  ) : (
                                    <p style={{ fontSize: '13px', marginTop: '6px' }}>
                                      {invoice.purchased_price}원
                                    </p>
                                  )}
                                </td>
                                <td className='text-center'>
                                  {is_admin ? (
                                    <Row className='ml-1'>
                                      <Input
                                        name='refund_price'
                                        onChange={(e) => {
                                          const regexp = /^[0-9\b]*$/;
                                          if (regexp.test(e.target.value)) {
                                            onChangeCalculate(e, invoice_index);
                                          }
                                        }}
                                        type='text'
                                        disabled={is_closed}
                                        style={{ fontSize: '13px', width: '80%' }}
                                        value={invoice.refund_price}
                                      />
                                      <span style={{ lineHeight: 'calc(1.5em + 0.75rem + 2px)' }}>
                                        원
                                      </span>
                                    </Row>
                                  ) : (
                                    <p style={{ fontSize: '13px', marginTop: '6px' }}>
                                      {invoice.refund_price}원
                                    </p>
                                  )}
                                </td>
                                <td className='text-center'>
                                  <p style={{ fontSize: '13px', marginTop: '6px' }}>
                                    {parseInt(invoice.invoice_user_type) === 3 &&
                                    invoice.total_price === 0
                                      ? '-'
                                      : initComma(invoice.total_price)}
                                    {parseInt(invoice.invoice_user_type) === 3 &&
                                    invoice.total_price === 0
                                      ? ''
                                      : '원'}
                                  </p>
                                </td>
                                <td className='text-center'>
                                  {is_admin ? (
                                    <Row className='ml-1'>
                                      <Input
                                        name='tax_free_price'
                                        onChange={(e) => {
                                          const regexp = /^[0-9\b]*$/;
                                          if (regexp.test(e.target.value)) {
                                            onChangeCalculate(e, invoice_index);
                                          }
                                        }}
                                        type='text'
                                        disabled={is_closed}
                                        style={{ fontSize: '13px', width: '80%' }}
                                        value={invoice.tax_free_price}
                                      />
                                      <span style={{ lineHeight: 'calc(1.5em + 0.75rem + 2px)' }}>
                                        원
                                      </span>
                                    </Row>
                                  ) : (
                                    <p style={{ fontSize: '13px', marginTop: '6px' }}>
                                      {invoice.tax_free_price}원
                                    </p>
                                  )}
                                </td>
                                <td className='text-center'>
                                  <p style={{ fontSize: '13px', marginTop: '6px' }}>
                                    {initComma(invoice.supply_price)}
                                    {invoice.supply_price ? '원' : ''}
                                  </p>
                                </td>
                                <td className='text-center'>
                                  <p style={{ fontSize: '13px', marginTop: '6px' }}>
                                    {initComma(invoice.vat)}
                                    {invoice.vat ? '원' : ''}
                                  </p>
                                </td>
                                <td className='text-center'>
                                  {is_admin ? (
                                    <Row className='ml-1'>
                                      <Input
                                        name='commission_rate'
                                        onChange={(e) => {
                                          const regexp = /^[0-9\b]*$/;
                                          if (regexp.test(e.target.value)) {
                                            onChangeCalculate(e, invoice_index);
                                          }
                                        }}
                                        type='text'
                                        disabled={is_closed}
                                        style={{ fontSize: '13px', width: '80%' }}
                                        value={invoice.commission_rate}
                                      />
                                      <span style={{ lineHeight: 'calc(1.5em + 0.75rem + 2px)' }}>
                                        %
                                      </span>
                                    </Row>
                                  ) : (
                                    <p style={{ fontSize: '13px', marginTop: '6px' }}>
                                      {invoice.commission_rate}%
                                    </p>
                                  )}
                                </td>
                                <td className='text-center'>
                                  {is_admin ? (
                                    <Input
                                      style={{ fontSize: '13px' }}
                                      type='select'
                                      disabled={is_closed}
                                      name='invoice_user_type'
                                      value={invoice.invoice_user_type || 3}
                                      onChange={(e) => {
                                        onChangeCalculate(e, invoice_index);
                                      }}
                                    >
                                      {static_own_invoice_type_set.length > 0 &&
                                        static_own_invoice_type_set.map((type) => (
                                          <option key={type.id} value={type.id}>
                                            {type.name}
                                          </option>
                                        ))}
                                    </Input>
                                  ) : (
                                    <p style={{ fontSize: '13px', marginTop: '6px' }}>
                                      {getInvoiceType(invoice.invoice_user_type)}
                                    </p>
                                  )}
                                </td>
                                <td className='text-center'>
                                  <p
                                    style={{
                                      fontSize: '13px',
                                      marginTop: '6px',
                                      fontWeight: '600',
                                    }}
                                  >
                                    {initComma(invoice.invoice_price)}원
                                  </p>
                                </td>
                                {is_admin && !is_closed && (
                                  <>
                                    <td className='text-center'>
                                      <Button
                                        className='btn-xs btn btn-danger btn-pill'
                                        style={{ marginTop: '6px' }}
                                        onClick={() => removeInvoiceChannel(invoice_index)}
                                      >
                                        삭제
                                      </Button>
                                    </td>
                                    <td className='text-center'>
                                      <Button
                                        className='btn-xs btn btn-pill'
                                        style={{ marginTop: '6px' }}
                                        onClick={() => hideInvoiceChannel(invoice_index)}
                                      >
                                        숨기기
                                      </Button>
                                    </td>
                                  </>
                                )}
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </Table>
                </div>
                <hr style={{ marginTop: '1px' }} />
                <Row style={{ marginBottom: '20px' }}>
                  <Col>
                    {is_admin && !is_closed && (
                      <>
                        <Button
                          className='btn btn-sm btn-pill'
                          style={{ width: '130px' }}
                          outline
                          color='primary-2x'
                          onClick={() => {
                            addInvoiceChannel();
                          }}
                        >
                          정산 추가
                        </Button>
                        <Button
                          className='btn btn-sm btn-pill'
                          style={{ width: '130px', marginLeft: '20px' }}
                          outline
                          color='primary-2x'
                          onClick={() => {
                            tempInvoiceSave();
                          }}
                        >
                          임시 저장
                        </Button>
                        {is_admin && (
                          <span style={{ marginLeft: '15px', color: '#7E37D8', fontSize: '10px' }}>
                            배경색이 보라색인 경우 임시저장된 정보를 나타냅니다.
                          </span>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
                <hr />
                <Row
                  className='justify-content-end'
                  style={{ marginTop: '25px', marginBottom: '-15px' }}
                >
                  <Col className={isMobile ? 'col-12' : 'col-4'} style={{ marginBottom: '0px' }}>
                    <FinalInvoiceViewCard
                      is_closed={is_closed}
                      invoice={invoice}
                      is_admin={is_admin}
                      invoice_info_exists={invoice_info_exists}
                      invoice_channel_set={invoice_channel_set}
                      account_id={account_id}
                      year={year}
                      month={month}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <Card className='input-air-primary'>
              <CardBody>
                <Nav className='border-tab-primary' tabs>
                  <NavItem style={is_admin ? { width: '50%' } : { width: '100%' }}>
                    <NavLink
                      role='button'
                      className={BasicLineTab === '1' ? 'active' : ''}
                      onClick={() => setBasicLineTab('1')}
                    >
                      <i className='icofont icofont-list' />
                      정산 문서 관리
                    </NavLink>
                  </NavItem>
                  {is_admin && (
                    <NavItem style={{ width: '50%' }}>
                      <NavLink
                        role='button'
                        className={BasicLineTab === '2' ? 'active' : ''}
                        onClick={() => setBasicLineTab('2')}
                      >
                        <i className='icofont icofont-settings-alt' />
                        정산 정보 관리
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={BasicLineTab}>
                  {BasicLineTab === '2' && (
                    <>
                      <Col className='text-right'>
                        <Col>
                          {isEdit && (
                            <>
                              <Button
                                className='btn-pill'
                                color='primary'
                                style={{ marginRight: '8px' }}
                                onClick={saveInvoiceInfo}
                              >
                                저장
                              </Button>
                            </>
                          )}
                          <Button
                            className={isEdit ? 'btn-pill btn btn-light' : 'btn-pill'}
                            color={isEdit ? '' : 'primary'}
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              if (isEdit) {
                                initData();
                              }
                              setIsEdit(!isEdit);
                            }}
                          >
                            {isEdit ? <>취소</> : <>편집</>}
                          </Button>
                        </Col>
                      </Col>
                    </>
                  )}
                  <TabPane className='fade show' tabId='1'>
                    <Row>
                      <Col>
                        <CustomInput
                          label={'추가메세지'}
                          type={'textarea'}
                          name={'attach_message'}
                          value={invoice?.attach_message || ''}
                          disabled={!is_admin || is_closed}
                          style={{ fontSize: '13px' }}
                          onChange={(e) => {
                            invoiceInfoOnChange(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                      <Col>
                        <CustomFileInput
                          id={account_id}
                          name={'attach_archive_file_url'}
                          className={'custom-file-input'}
                          is_img={false}
                          multiple={true}
                          invoice_date={invoice_date}
                          file_type={'invoice'}
                          file_password={invoice?.attach_archive_file_password}
                          value={invoice?.attach_archive_file_url || ''}
                          tooltip_desc={
                            '정산에 관련된 문서를 업로드할 수 있습니다. (패스워드 필수 설정)'
                          }
                          disabled={!is_admin || is_closed}
                          label={'정산 관련 파일 업로드'}
                          propOnChange={({ url, password }) => {
                            setInvoice((prevState) => ({
                              ...prevState,
                              attach_archive_file_url: url,
                              attach_archive_file_password: password,
                            }));
                          }}
                          style={{
                            fontSize: '14px',
                            color: '#898989',
                            height: 'auto',
                            paddingLeft: '20px',
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  {is_admin && (
                    <InvoiceInfoTable
                      invoice_info_set={invoice_info_set}
                      static_invoice_type_set={static_invoice_type_set}
                      account_name={account.name}
                      isEdit={isEdit}
                    />
                  )}
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default InvoiceDetail;
