import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import {
  apiDatetimeReplace,
  initComma,
  onAirStatus,
  onBooleanBadgeStyle,
  toQueryString,
} from '../../util/common';
import { PAGE_SIZE } from '../../constants';

const Banner = (props) => {
  // 기존 props.history.push 로는 state를 가져온다 / 직접 url에 값을 때려 박으면 state에 값이 없어서 search에서 가져와야한다 / 이도저도 아닐 경우
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    search_text: params.search_text ? params.search_text : '',
    banner_type: params.banner_type ? params.banner_type : '',
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const [banner_set, setBannerSet] = useState([]);
  const [banner_count, setBannerCountSet] = useState(0);

  const [banner_type_set, setBannerTypeSet] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilter] = useState(default_filter.current);

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'banner_type_set' }).then(({ code_set }) => {
      setBannerTypeSet(code_set);
    });
  }, []);

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    CommonModel.getBanner({
      ...params,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ banner, total_count }) => {
      setBannerSet(banner);
      setBannerCountSet(total_count);
      setIsLoading(false);
    });
  }, [props.location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onBannerTypeChange = (e) => {
    pushHistory({
      ...filter_set,
      banner_type: e.target.value,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterSubmit = (e) => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      order_key: order_key,
      order_type: !filter_set.order_type || filter_set.order_type === 'asc' ? 'desc' : 'asc', // 정렬을 기존 기준 -> "없을 경우 or asc = desc로 변경" / "desc 일 경우 = asc 로 변경"
    };

    // 이전에 정렬한적 있고, 새로운 정렬을 적용.
    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }

    pushHistory(newFilterSet);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    });
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <Breadcrumb parent='서비스' title='배너 목록' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Col className='text-right mb-4'>
              <Link className={'btn btn-primary btn-pill'} to={'/banner/0'}>
                배너 생성
              </Link>
            </Col>
            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={7} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총: <span style={{ color: 'coral' }}>{initComma(banner_count)}</span>개
                        </p>
                        <Loading isLoading={isLoading} />
                      </Col>
                      <Col md={2}>
                        <Input
                          type='select'
                          name='banner_type'
                          className='form-control'
                          onChange={(e) => {
                            onBannerTypeChange(e);
                          }}
                          value={filter_set.banner_type}
                        >
                          <option value=''>전체</option>
                          {banner_type_set.map((banner_type) => {
                            return (
                              <option key={banner_type.id} value={banner_type.id}>
                                {banner_type.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                      <Col md={3} className='text-right'>
                        <FormGroup>
                          <InputGroup>
                            <Input
                              className='form-control'
                              type='text'
                              name='search_text'
                              onChange={onFilterChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onFilterSubmit(e);
                                }
                              }}
                              value={filter_set.search_text}
                              placeholder='배너명을 입력하세요.'
                            />
                            <InputGroupAddon
                              addonType='append'
                              style={{ cursor: 'pointer' }}
                              onClick={onFilterSubmit}
                            >
                              <InputGroupText>
                                <i className='icofont icofont-ui-search' />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <div className='table-responsive'>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th
                        style={{
                          width: '70px',
                          maxWidth: '70px',
                          minWidth: '70px',
                        }}
                      >
                        ID
                      </th>
                      <th
                        style={{
                          width: '300px',
                          maxWidth: '300px',
                          minWidth: '300px',
                          textAlign: 'left',
                        }}
                      >
                        제목/설명
                      </th>
                      <th
                        style={{
                          textAlign: 'left',
                          width: '300px',
                          maxWidth: '300px',
                          minWidth: '300px',
                        }}
                      >
                        리디렉션URL
                      </th>
                      <th
                        style={{
                          width: '100px',
                          maxWidth: '100px',
                          minWidth: '100px',
                        }}
                      >
                        이미지
                      </th>
                      <th
                        style={{
                          width: '90px',
                          maxWidth: '90px',
                          minWidth: '90px',
                        }}
                      >
                        테마컬러
                      </th>
                      <th style={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>
                        <span>위치</span>
                      </th>
                      <th
                        style={{
                          width: '110px',
                          maxWidth: '110px',
                          minWidth: '110px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('start_time');
                        }}
                      >
                        {filter_set.order_key === 'start_time' ? (
                          <span style={{ color: '#ff4c3b' }}>시작일</span>
                        ) : (
                          <span>시작일</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '110px',
                          maxWidth: '110px',
                          minWidth: '110px',
                        }}
                        onClick={() => {
                          onOrderChange('expire_time');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'expire_time' ? (
                          <span style={{ color: '#ff4c3b' }}>종료일</span>
                        ) : (
                          <span>종료일</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '110px',
                          maxWidth: '110px',
                          minWidth: '110px',
                        }}
                        onClick={() => {
                          onOrderChange('id');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'id' ? (
                          <span style={{ color: '#ff4c3b' }}>생성일</span>
                        ) : (
                          <span>생성일</span>
                        )}
                      </th>
                      <th
                        onClick={() => {
                          onOrderChange('order_number');
                        }}
                        style={{ width: '90px', maxWidth: '90px', minWidth: '90px' }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'order_number' ? (
                          <span style={{ color: '#ff4c3b' }}>정렬순서</span>
                        ) : (
                          <span>정렬순서</span>
                        )}
                      </th>
                      <th
                        onClick={() => {
                          onOrderChange('on_air');
                        }}
                        style={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'on_air' ? (
                          <span style={{ color: '#ff4c3b' }}>onAir</span>
                        ) : (
                          <span>onAir</span>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {banner_set.map((banner) => {
                      let url = 'banner/' + banner.id;
                      const onTdClick = () => {
                        props.history.push(url);
                      };
                      return (
                        <tr key={banner.id}>
                          <td
                            className='text-center'
                            onClick={onTdClick}
                            role='button'
                            style={{ verticalAlign: 'middle' }}
                          >
                            {banner.id}
                          </td>
                          <td style={{ verticalAlign: 'middle' }} role='button' onClick={onTdClick}>
                            <div className={'text-truncate f-w-900'} style={{ width: '300px' }}>
                              {banner.title}
                            </div>
                            <div
                              className={'text-truncate'}
                              style={{
                                width: '300px',
                                maxWidth: '300px',
                                minWidth: '300px',
                              }}
                            >
                              {banner.description}
                            </div>
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '280px',
                              minWidth: '280px',
                            }}
                          >
                            <a
                              className='text-truncate'
                              style={{
                                display: 'block',
                                width: '280px',
                                maxWidth: '280px',
                                minWidth: '280px',
                              }}
                              href={banner.redirect_url}
                              target='_blank'
                              rel='noreferrer'
                            >
                              {banner.redirect_url}
                            </a>
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <img
                              style={{
                                maxHeight: '62px',
                                maxWidth: '100px',
                                minWidth: '100px',
                              }}
                              alt='banner_img'
                              src={banner.image_url}
                            />
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '90px',
                              minWidth: '90px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div
                              style={{
                                background: banner.theme_color_hex,
                                border: 'solid 1px',
                                width: '20px',
                                height: '20px',
                                margin: 'auto',
                              }}
                            />
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '100px',
                              minWidth: '100px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {banner.banner_type_name}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '110px',
                              minWidth: '110px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {apiDatetimeReplace(banner.start_time) || '-'}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '110px',
                              minWidth: '110px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {apiDatetimeReplace(banner.expire_time) || '-'}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '110px',
                              minWidth: '110px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {apiDatetimeReplace(banner.create_time)}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '90px',
                              minWidth: '90px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {banner.order_number}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              maxWidth: '100px',
                              minWidth: '100px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <span className={onBooleanBadgeStyle(banner.on_air)}>
                              {onAirStatus(banner.on_air)}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size ? banner_count / params.size : banner_count / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Banner;
