import React, { useRef, useState } from 'react';
import { CardBody, Table } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useMountEffect, useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import { lang } from '../../../lang';
import CompanyModel from '../../../model/CompanyModel';
import moment from 'moment';

/*
    props
    current_employee: {}        직원 정보
 */
const EmployeeCourseList = (props) => {
  const { params } = useParameters();
  const { showAlert } = useShowDialog();
  const history = useHistory();
  const [isMount] = useMountEffect();

  // state
  const [employee_course_set, setEmployeeCourseSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // ref
  const company_id = useRef(params.company_id);
  const employee_id = useRef(params.employee_id);

  useMountEffect(async () => {
    await updateUi();
  });

  const updateUi = async () => {
    setIsLoading(true);

    // 직원의 강의 목록 가져오기 API
    const employee_course_res = await CompanyModel.getEmployeeCourses(
      company_id.current,
      employee_id.current,
    );
    if (isMount()) {
      if (employee_course_res.code === 200) {
        setEmployeeCourseSet(employee_course_res.data.courses);
      } else {
        showAlert({
          title: lang.request.request_error_title,
          text: employee_course_res.message,
        });
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <CardBody
        style={{
          padding: '30px',
        }}
      >
        <div className='table-responsive'>
          <Table striped className='m-t-15'>
            <thead>
              <tr>
                <th scope='col' style={{ whiteSpace: 'nowrap' }}>
                  수강 강의명
                </th>
                <th scope='col' className='text-center' style={{ whiteSpace: 'nowrap' }}>
                  최초 수강시간
                </th>
                <th scope='col' className='text-center' style={{ whiteSpace: 'nowrap' }}>
                  마지막 수강시간
                </th>
                <th scope='col' className='text-center' style={{ whiteSpace: 'nowrap' }}>
                  시청기록보기
                </th>
                <th scope='col' className='text-center' style={{ whiteSpace: 'nowrap' }}>
                  수강률
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan='5' style={{ textAlign: 'center' }}>
                    <div className='loader-box'>
                      <div className='loader-15' />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {employee_course_set && employee_course_set.length === 0 ? (
                  <tr>
                    <td colSpan='5' className='text-center' style={{ whiteSpace: 'nowrap' }}>
                      현재 수강중인 강의가 없습니다
                    </td>
                  </tr>
                ) : (
                  employee_course_set &&
                  employee_course_set.map((one_course, i) => (
                    <tr key={i}>
                      <td style={{ whiteSpace: 'nowrap' }}>{one_course.title}</td>
                      <td className='text-center' style={{ whiteSpace: 'nowrap' }}>
                        {one_course.first_attendance_time
                          ? moment(one_course.first_attendance_time).format('YYYY-MM-DD HH:mm:ss')
                          : '수강전'}
                      </td>
                      <td className='text-center' style={{ whiteSpace: 'nowrap' }}>
                        {one_course.last_attendance_time
                          ? moment(one_course.last_attendance_time).format('YYYY-MM-DD HH:mm:ss')
                          : ''}
                      </td>
                      <td className='text-center txt-primary' style={{ whiteSpace: 'nowrap' }}>
                        <span
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            history.push({
                              pathname: `/company/${company_id.current}/employee/${employee_id.current}/course/${one_course.id}`,
                            });
                          }}
                        >
                          기록보기
                        </span>
                      </td>
                      <td className='text-center' style={{ whiteSpace: 'nowrap' }}>
                        {one_course.attendance_rate
                          ? (one_course.attendance_rate * 100).toFixed(1)
                          : 0}
                        %
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            )}
          </Table>
        </div>
      </CardBody>
    </>
  );
};

export default EmployeeCourseList;
