import React, { useEffect, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import {
  BROADCAST_EDITABLE,
  useApplicationContext,
} from '../../../context/ApplicationContextProvider';
import CustomTooltip from './CustomTooltip';
import { autoHyphenBirthDate, autoHyphenPhoneNumber } from '../../../util/b2b_utils';

/**
 *
 * @param class_name
 * @param id               // password 안 보임(기본값) <-> 보임
 * @param name
 * @param type
 * @param innerRef
 * @param max_length
 * @param setValue         // 수정하지 않고 취소하면 다시 rollback을 위한 setValue
 * @param on_blur
 * @param placeholder
 * @param defaultValue
 * @param on_change
 * @param with_edit_button // 편집 버튼을 눌렀을 때 활성화 (기본값 disabled) - 편집버튼과 함께 쓰이진 않지만 disabled하고 싶을 때 true로 줄 것
 * @param read_only        // 편집 버튼을 눌러도 활성화 X
 * @param label_title
 * @param is_require       // 라벨의 * 표시
 * @param need_tooltip      // 툴팁의 필요 여부 (true이면 반드시 tooltip_content가 필요하다)
 * @param tooltip_content   // 툴팁으로 보여줄 내용
 */

const CustomInput2 = ({
  class_name = 'form-control',
  id = null,
  name,
  type = 'text',
  innerRef,
  max_length = null,
  setValue = null,
  on_blur = null,
  placeholder = null,
  defaultValue = null,
  on_change = null,
  with_edit_button = false,
  read_only = false,
  label_title,
  is_require = false,
  need_tooltip = false,
  tooltip_content = null,
}) => {
  const { broadcast } = useApplicationContext();

  // state
  const [is_password_visible, setPasswordVisible] = useState(false);
  const [is_editable, setEditable] = useState(with_edit_button);

  useEffect(() => {
    const unlisten = broadcast.listen([BROADCAST_EDITABLE], ({ value }) => {
      if (!read_only) {
        // EditButton에서 취소버튼을 누르면 초기화 및 편집 취소
        if (value.is_cancel_button_click) {
          setValue(name, defaultValue);
        }
        setEditable(value.disable_input);
      }
    });

    return () => {
      broadcast.unlisten(unlisten);
    };
  }, [broadcast, read_only, defaultValue]);

  const onHandlePasswordVisible = () => {
    let password_visible = document.getElementById('password');
    if (password_visible.getAttribute('type') === 'password') {
      password_visible.setAttribute('type', 'text');
      password_visible.focus();
    } else {
      password_visible.setAttribute('type', 'password');
      password_visible.focus();
    }
  };

  return type === 'password' ? (
    <>
      <Label
        style={{
          cursor: 'pointer',
        }}
        id='password-info'
      >
        <i className='icon-help-alt' />
        &nbsp;
        {is_require && (
          <span
            style={{
              color: '#FF0000',
            }}
          >
            *&nbsp;
          </span>
        )}
        <b>{label_title}</b>
      </Label>
      <CustomTooltip
        target='password-info'
        content={
          <>
            <i className='typcn typcn-key' />
            비밀번호 생성조건
            <br /> 공백 이외의 문자 4자 이상
          </>
        }
      />
      <InputGroup>
        <InputGroupAddon
          addonType='append'
          onClick={() => {
            setPasswordVisible(!is_password_visible);
            onHandlePasswordVisible();
          }}
          style={{
            cursor: 'pointer',
            width: '44.5px',
          }}
        >
          <InputGroupText>
            {is_password_visible ? <i className='fa fa-eye' /> : <i className='fa fa-eye-slash' />}
          </InputGroupText>
        </InputGroupAddon>
        <Input
          disabled={is_editable}
          defaultValue={defaultValue}
          name={name}
          id={id}
          className={class_name}
          type={type}
          placeholder={placeholder}
          innerRef={innerRef}
        />
      </InputGroup>
    </>
  ) : (
    <>
      <Label
        {...(need_tooltip && {
          style: {
            cursor: 'pointer',
          },
          id: `tooltip_target_${name}`,
        })}
      >
        {need_tooltip && (
          <>
            <i className='icon-help-alt' />
            &nbsp;
            <CustomTooltip target={`tooltip_target_${name}`} content={tooltip_content} />
          </>
        )}
        {is_require && (
          <span
            style={{
              color: '#FF0000',
            }}
          >
            *&nbsp;
          </span>
        )}
        <b>{label_title}</b>
      </Label>
      <Input
        disabled={is_editable}
        defaultValue={defaultValue}
        className={class_name}
        name={name}
        type={type}
        maxLength={max_length}
        placeholder={placeholder}
        innerRef={innerRef}
        onKeyUp={(e) => {
          if (name === 'birth') {
            e.currentTarget.value = autoHyphenBirthDate(e.currentTarget.value);
          } else if (name === 'phone') {
            e.currentTarget.value = autoHyphenPhoneNumber(e.currentTarget.value);
          }
        }}
        onBlur={(e) => on_blur && on_blur(e)}
        min={type === 'number' ? 0 : null}
        onChange={(e) => on_change && on_change(e)}
      />
    </>
  );
};

export default CustomInput2;
