import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';

import { Card, CardHeader, Col, Container, Row, Button, Label } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import ImageUploader from '../_common/component/ImageUploader';

import Form from 'reactstrap/es/Form';
import SweetAlert from 'sweetalert2';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import { errMessageOccur } from '../../util/common';
import ProductModel from '../../model/ProductModel';
import TextEditor from '../_common/component/TextEditor';
import CustomLabel from '../_common/component/CustomLabel';
import CardInputSeletor from '../_common/component/CardInputSeletor';
import CustomTagInput from '../_common/component/CustomTagInput';

const tooptip_word = {
  title: '유저에게 노출됨으로 신중히 기입해주세요.',
  short_title: '',
  description: '유저에게 노출됨으로 신중히 기입해주세요.',
  management_description:
    '본 패키지를 통해 구매하여 강의가 할당되는경우,\n입력하신 관리용설명을 유저 보유 강의에서 확인할수있습니다.',
  classification_name:
    '가능 한 경우 리스트에서 표시할때 해당 값을 통해 카테고라이징 할 수 있습니다.',
  main_image_url: '(201221) 강의 메인이미지와 사이즈 스펙 동일.\n저장 후, 업로드 가능',
  circle_image_url: '',
  currency_code_id: '화폐종류는 신규 등록 이후, 절대 변경 할 수 없습니다.',
  price: '',
  original_price: '',
  tax_free_price: '면세 가격으로 무조건 판매가 보다 작거나 같아야 한다.',
  override_rental_time_in_day:
    '입력하는 경우 각 상품의 대여기간을 따르지 않고,\n포함된 모든 판매항목에 대해 입력한 대여기간으로 설정함.',
  our_revenue_share_weight: '본 판매값에서 우리의 몫\n(3:7로 하고싶은 경우 3입력)',
  course_revenue_share_weight: '본 판매값에서 나머지 몫\n(3:7로 하고싶은 경우 7입력)',
  show_in_application:
    '서비스내 표시될수있는 상품의 경우 리스트 등의 형태로 표시 될수 있습니다.\n그렇지 않은경우는 절대 노출 하지 않습니다.',
  is_require_with_kit:
    "'예'로 설정하는 경우, 구매시 키트를 최소 1개 이상 선택되도록 설정합니다.\n(기본값: 아니요)",
  only_single_kit:
    "'예'로 설정하는 경우, 키트 선택시 최대 1개의 키트만 선택되도록 설정합니다.\n(기본값: 아니요)",
  info_description:
    '유저에게 노출됨으로 신중히 기입해주세요.\n' +
    "해당 항목은 예를들어 아래와같이 표현됨으로 다/나/까 등으로 끝내시면 안됩니다. ('패키지 입니다' 라는 단어가 시스템적으로 강제 반영되어 보여지게됨으로)",
  point_description:
    '유저에게 노출됨으로 신중히 기입해주세요.\n' +
    'ex) 아직도 야근하세요? 2021년부터 베어유 프로 야근러들을 위한 업무스킬 강화 패키지를 듣고 절대 야근하지 마세요! 베어유의 유능한 마스터들이 도와드립니다!',
  can_use_coupon: '쿠폰 사용이 가능하더라도,\n규정상 키트금액은 할인되지 않습니다.',
  is_enabled:
    '비활성화 되는경우 유저가 상세페이지/주문 등에 접근 할 수 없게 됩니다.\n(구매등을 통해 이미 부여된 강의들에게는 해당없음)',
};

const obj_err_msg = {
  title: '상품명',
  short_title: '짧은상품명',
  currency_code_id: '화폐종류',
  price: '판매가',
  original_price: '정가',
  tax_free_price: '면세가',
  show_in_application: '서비스내 표시여부',
  is_require_with_kit: '구매시 키트를 반드시 포함',
  only_single_kit: '하나의 키트만 구매 가능',
  info_description: '패키지 정포 설명',
  point_description: '포인트 정보 설명',
  can_use_coupon: '쿠폰사용 가능여부',
  is_enabled: '활성화',
};

const ProductDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.product_id));
  const [currency_code_set, setCurrencyCodeSet] = useState([]);

  const [sale_info_select, setSaleInfoSelect] = useState([]);
  const [kit_select, setKitSelect] = useState([]);

  const [sale_info_set, setSaleInfoSet] = useState([]);
  const [kit_set, setKitSet] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [product, setProduct] = useState({
    id: '',
    title: '',
    short_title: '',
    description: '',
    management_description: '',
    main_image_url: '',
    main_thumbnail_image_url: '',
    circle_image_url: '',
    currency_code_id: '',
    price: '',
    original_price: '',
    tax_free_price: '',
    override_rental_time_in_day: '',
    show_in_application: 0,
    // 대상 판매 항목
    product_sale_info_set: [],
    // 관련 키트
    product_kit_set: [],
    is_require_with_kit: 0,
    only_single_kit: 0,
    info_description: '',
    point_description: '',
    classification_name: '',
    can_use_coupon: 0,
    content_html: '',
    is_enabled: 0,
  });

  const [product_tag_set, setProductTagSet] = useState([]);

  const onInputChange = (e) => {
    let value = e.target.value;

    // 면세가는 제휴사 공급가를 넘길 수 없다.
    if (e.target.name === 'tax_free_price') {
      if (Number(e.target.value) > Number(product.price)) {
        value = product.price;
      }
    }

    setProduct({ ...product, [e.target.name]: value });
  };

  const onInputSubmit = (e) => {
    e.preventDefault();

    const err_msg = errMessageOccur(product, obj_err_msg);

    if (product.show_in_application && !product.circle_image_url) {
      err_msg.push("'원형 이미지'");
    }

    // 패키지를 하기 위해서는 판매 항목을 무조건 넣어야한다.
    if (!sale_info_select.length) {
      err_msg.push("'대상 판매항목'을 추가해주세요.");
    } else {
      // 패키지 안의 값 확인
      let sale_info_err_find = false;
      // 카드 안에 값이 없을 경우 에러 띄우기
      sale_info_select.forEach((item) => {
        if (item['order_number'] === '') {
          err_msg.push(`${item['combine_name']} '정렬값'을 입력해주세요.`);
          sale_info_err_find = true;
        }
        if (item['revenue_share_weight'] === '') {
          err_msg.push(`${item['combine_name']} '수익분배가중치'를 입력해주세요.`);
          sale_info_err_find = true;
        }
        if (sale_info_err_find) {
          return false;
        }
      });
    }

    // 키트 값 확인
    let kit_err_find = false;
    // 카드 안에 값이 없을 경우 에러 띄우기
    kit_select.forEach((item) => {
      if (item['order_number'] === '') {
        err_msg.push(`${item['title']} '정렬값'을 입력해주세요.`);
        kit_err_find = true;
      }
      if (kit_err_find) {
        return false;
      }
    });

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      setProduct((prevState) => ({
        ...prevState,
        product_sale_info_set: sale_info_select,
        product_kit_set: kit_select,
      }));

      if (product.id) {
        ProductModel.putProductDetail({
          ...product,
          product_tag_set,
          product_sale_info_set: sale_info_select,
          product_kit_set: kit_select,
        }).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        ProductModel.postProduct({
          ...product,
          product_tag_set,
          product_sale_info_set: sale_info_select,
          product_kit_set: kit_select,
        }).then(({ product_id }) => {
          props.history.replace('/product/' + product_id);
          window.location.reload();
        });
      }
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const requestDetail = () => {
    ProductModel.getProductDetail(Number(props.match.params.product_id)).then(({ product }) => {
      if (product) {
        const { tag_set, ...product_without_tag } = product;
        // Tag 세팅 및 패키지 내용 세팅
        setProduct(product_without_tag);
        setProductTagSet(tag_set);

        // 강의 가격 선택
        const _product_sale_info_set = product_without_tag.product_sale_info_set.map((e) => {
          return e;
        });
        setSaleInfoSelect(_product_sale_info_set);

        // 키트 선택
        const _product_kit_set = product_without_tag.product_kit_set.map((e) => {
          return e;
        });
        setKitSelect(_product_kit_set);
      }
    });
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'currency_code_set' }).then(({ code_set }) => {
      setCurrencyCodeSet(code_set);

      if (!Number(props.match.params.product_id)) {
        setProduct({ ...product, currency_code_id: code_set[0]?.id });
      }
    });

    CommonModel.getSearch('product_course_sale_info').then((data) => {
      setSaleInfoSet(data);
    });

    CommonModel.getSearch('product_kit').then((data) => {
      setKitSet(data);
    });

    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent='패키지'
        title={`패키지 ${props.match.params.product_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form>
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`패키지 ${props.match.params.product_id === '0' ? '생성' : '정보'}`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            onClick={(e) => {
                              onInputSubmit(e);
                            }}
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      {
                        <>
                          {Number(props.match.params.product_id) && !isEdit ? (
                            <>
                              <a
                                className='btn btn-pill btn-info mr-2'
                                href={`${process.env.REACT_APP_API_URL}/package/${Number(
                                  props.match.params.product_id,
                                )}`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                미리보기
                              </a>
                            </>
                          ) : (
                            ''
                          )}
                          <Button
                            className='btn-pill'
                            color='primary'
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              // 생성(0)일 경우 수정(0 이상)일 경우
                              if (!!Number(props.match.params.product_id)) {
                                if (isEdit) {
                                  requestDetail();
                                }
                                setIsEdit(!isEdit);
                              } else {
                                window.location.href = '/product';
                              }
                            }}
                          >
                            {isEdit ? '취소' : '편집'}
                          </Button>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col
                      md={'3 mb-4'}
                      style={{ minWidth: '360px', maxHeight: '230px', alignSelf: 'center' }}
                    >
                      <div className='blog-box text-center'>
                        <ImageUploader
                          id={Number(props.match.params.product_id)}
                          name='main_image_url'
                          withPreview={true}
                          img_type={'product'}
                          thumbnail_width={500}
                          min_width={1200}
                          setPropsState={setProduct}
                          img_url={product.main_image_url}
                          title={product.title}
                          isEdit={!!Number(props.match.params.product_id) ? isEdit : false}
                          imgExtension={['.jpg', '.gif', '.png', '.gif', '.svg']}
                          height={230}
                        />
                        <CustomLabel
                          label={'메인 이미지'}
                          tooltip_desc={tooptip_word.main_image_url}
                        />
                      </div>
                    </Col>
                    <Col md={'9 mb-4'}>
                      <Row>
                        <Col md='7 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'title'}
                            disabled={!isEdit}
                            value={product.title || ''}
                            is_required={true}
                            label={'상품명'}
                            tooltip_desc={tooptip_word.title}
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 128;
                            }}
                          />
                        </Col>
                        <Col md='5 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'short_title'}
                            disabled={!isEdit}
                            value={product.short_title || ''}
                            is_required={true}
                            label={'짧은상품명'}
                            tooltip_desc={tooptip_word.short_title}
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 32;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='6 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'description'}
                            disabled={!isEdit}
                            value={product.description || ''}
                            label={'상품설명'}
                            tooltip_desc={tooptip_word.description}
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 128;
                            }}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'management_description'}
                            disabled={!isEdit}
                            value={product.management_description || ''}
                            label={'관리용설명'}
                            tooltip_desc={tooptip_word.management_description}
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 12;
                            }}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'classification_name'}
                            disabled={!isEdit}
                            value={product.classification_name || ''}
                            label={'구분명'}
                            tooltip_desc={tooptip_word.classification_name}
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 10;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4 mb-3'>
                          <CustomSelectInput
                            name={'currency_code_id'}
                            disabled={!isEdit}
                            value={product.currency_code_id}
                            label={'화폐종류'}
                            is_required={true}
                            sub_option_type={'description'}
                            onChange={onInputChange}
                            tooltip_desc={tooptip_word.currency_code_id}
                            options={currency_code_set}
                          />
                        </Col>
                        <Col md='2 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'original_price'}
                            disabled={!isEdit}
                            value={String(product.original_price) || ''}
                            is_required={true}
                            label={'정가'}
                            tooltip_desc={tooptip_word.original_price}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='2 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'price'}
                            disabled={!isEdit}
                            value={String(product.price) || ''}
                            is_required={true}
                            label={'판매가'}
                            tooltip_desc={tooptip_word.price}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='2 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'tax_free_price'}
                            disabled={!isEdit}
                            value={String(product.tax_free_price) || ''}
                            is_required={true}
                            label={'면세가'}
                            tooltip_desc={tooptip_word.tax_free_price}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='2 mb-3'>
                          <CustomInput
                            type={'number'}
                            name={'override_rental_time_in_day'}
                            disabled={!isEdit}
                            value={product.override_rental_time_in_day || ''}
                            label={'대여기간'}
                            tooltip_desc={tooptip_word.override_rental_time_in_day}
                            onChange={onInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4 mb-3'>
                          <CustomSelectInput
                            name={'is_require_with_kit'}
                            disabled={!isEdit}
                            value={product.is_require_with_kit}
                            label={'구매시 키트를 반드시 포함'}
                            is_required={true}
                            tooltip_desc={tooptip_word.is_require_with_kit}
                            onChange={onInputChange}
                            options={[
                              {
                                id: 0,
                                name: '아니요',
                              },
                              {
                                id: 1,
                                name: '예',
                              },
                            ]}
                          />
                        </Col>
                        <Col md='4 mb-3'>
                          <CustomSelectInput
                            name={'only_single_kit'}
                            disabled={!isEdit}
                            value={product.only_single_kit}
                            label={'하나의 키트만 구매 가능'}
                            is_required={true}
                            tooltip_desc={tooptip_word.only_single_kit}
                            onChange={onInputChange}
                            options={[
                              {
                                id: 0,
                                name: '아니요',
                              },
                              {
                                id: 1,
                                name: '예',
                              },
                            ]}
                          />
                        </Col>
                        <Col md='4 mb-3'>
                          <CustomSelectInput
                            name={'can_use_coupon'}
                            disabled={!isEdit}
                            value={product.can_use_coupon}
                            label={'쿠폰사용 가능여부'}
                            is_required={true}
                            onChange={onInputChange}
                            tooltip_desc={tooptip_word.can_use_coupon}
                            options={[
                              {
                                id: 0,
                                name: '불가능',
                              },
                              {
                                id: 1,
                                name: '가능',
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='5 mb-3'>
                      <CustomInput
                        type={'text'}
                        name={'info_description'}
                        disabled={!isEdit}
                        value={product.info_description || ''}
                        is_required={true}
                        label={'패키지 정보 설명'}
                        tooltip_desc={tooptip_word.info_description}
                        onChange={onInputChange}
                        validator={(value) => {
                          return value.length <= 32;
                        }}
                      />
                    </Col>
                    <Col md='7 mb-3'>
                      <CustomInput
                        type={'text'}
                        name={'point_description'}
                        disabled={!isEdit}
                        value={product.point_description || ''}
                        is_required={true}
                        label={'포인트 정보 설명'}
                        tooltip_desc={tooptip_word.point_description}
                        onChange={onInputChange}
                        validator={(value) => {
                          return value.length <= 128;
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md='3 mb-3'>
                      <CustomSelectInput
                        name={'show_in_application'}
                        disabled={!!!Number(props.match.params.product_id) || !isEdit}
                        value={product.show_in_application}
                        label={'서비스내 표시여부'}
                        is_required={true}
                        tooltip_desc={tooptip_word.show_in_application}
                        onChange={onInputChange}
                        options={[
                          {
                            id: 0,
                            name: '하지않음',
                          },
                          {
                            id: 1,
                            name: '서비스내 표시 될수있음',
                          },
                        ]}
                      />
                    </Col>
                    <Col md='3 mb-3'>
                      <CustomSelectInput
                        name={'is_enabled'}
                        disabled={!!!Number(props.match.params.product_id) || !isEdit}
                        value={product.is_enabled}
                        label={'활성화'}
                        is_required={true}
                        onChange={onInputChange}
                        tooltip_desc={tooptip_word.is_enabled}
                        options={[
                          {
                            id: 0,
                            name: '아니오',
                          },
                          {
                            id: 1,
                            name: '예',
                          },
                        ]}
                      />
                    </Col>
                    <Col md='7 mb-3'>
                      <Label>태그 설명</Label>
                      <CustomTagInput
                        tag_set={product_tag_set || []}
                        setTagSet={setProductTagSet}
                        placeholder={'패키지 태그'}
                        max_length={6}
                        disabled={!!Number(props.match.params.product_id) ? !isEdit : true}
                      />
                    </Col>
                    <Col
                      md={'3 mb-4'}
                      style={{ minWidth: '360px', maxHeight: '230px', alignSelf: 'center' }}
                    >
                      <div className='blog-box text-center'>
                        <ImageUploader
                          id={Number(props.match.params.product_id)}
                          name='circle_image_url'
                          withPreview={true}
                          img_type={'circle_image_product'}
                          thumbnail_width={210}
                          min_width={210}
                          setPropsState={setProduct}
                          img_url={product.circle_image_url}
                          title={product.title}
                          isEdit={!!Number(props.match.params.product_id) ? isEdit : false}
                          imgExtension={['.jpg', '.gif', '.png', '.gif', '.svg']}
                          height={210}
                        />
                        <CustomLabel
                          label={'동그라미 이미지'}
                          tooltip_desc={tooptip_word.circle_image_url}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12 mb-3'>
                      <CustomLabel
                        label={'(상세페이지 전용) 콘텐츠영역 작성'}
                        tooltip_desc={'저장 후 작성 가능'}
                        is_required={true}
                      />
                      <div className='editor-img-100'>
                        <TextEditor
                          id={Number(props.match.params.product_id)}
                          html_data={product.content_html || ''}
                          onChange={(value) => {
                            setProduct((prevState) => ({
                              ...prevState,
                              content_html: value,
                            }));
                          }}
                          disabled={!!Number(props.match.params.product_id) ? !isEdit : true}
                          img_type={'product'}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardInputSeletor
              unique_key={'sale_info_id'}
              get_list={sale_info_select}
              setGetList={setSaleInfoSelect}
              static_list={sale_info_set}
              static_list_label_key={'combine_name'}
              card_label_key={'combine_name'}
              card_label_link={(e) => {
                return '/course/' + e.course_id;
              }}
              card_input_set={[
                {
                  type: 'number',
                  label: '수익분배가중치',
                  name: 'revenue_share_weight',
                  is_required: true,
                  default_value: 1,
                  disabled: true,
                },
                {
                  type: 'number',
                  label: '정렬값',
                  name: 'order_number',
                  is_required: true,
                  default_value: 0,
                },
              ]}
              isEdit={isEdit}
              label={'대상 판매항목'}
              tooltip_desc={
                '※ 판매항목 변경 하는경우, 이미 기존에 유저가 등록한 수강코드에 영향을 미치지 않으므로 반드시 주의하세요.\n' +
                '1.판매항목별 수익분배가중치는 예를들어 판매항목별 가중치값이 1, 1, 1, 1 이고 나머지 산출된 수익분배 금액이 10,000원인 경우. 정확히 2,500씩 정산받게됨\n' +
                '2.정렬값은 높은순으로 먼저 표시됩니다.'
              }
              className={'mb-3'}
              placeholder={'판매 항목을 선택하세요.'}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CardInputSeletor
              unique_key={'kit_info_id'}
              get_list={kit_select}
              setGetList={setKitSelect}
              static_list={kit_set}
              static_list_label_key={'title'}
              card_label_key={'title'}
              card_label_link={(e) => {
                return '/kit/' + e.kit_info_id;
              }}
              card_input_set={[
                {
                  type: 'number',
                  label: '정렬값',
                  name: 'order_number',
                  is_required: true,
                  default_value: 0,
                },
              ]}
              isEdit={isEdit}
              label={'관련키트'}
              tooltip_desc={'상세페이지에서 함께 노출할 키트 선택'}
              className={'mb-3'}
              placeholder={'키트를 선택하세요.'}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ProductDetail;
