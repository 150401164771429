import {
  STORED_COURSE_INFO,
  CLEAR_COURSE_INFO,
  SET_COURSE_INFO,
  SET_TAG,
  REMOVE_TAG,
  SET_KIT,
  SET_SUB_CATEGORY,
  SET_COURSE_BODY,
} from '../../action_type';

const INIT_STATE = {
  title: '',
  short_title: '',
  description: '',
  theme_color_hex: '#000000',
  teacher_name: '',
  teacher_account: null,
  extend_sale_info: null,
  reservation_url: null,
  override_detail_redirect_url: null,
  document_url: null,
  main_image_url: null,
  main_thumbnail_image_url: null,
  circle_image_url: null,
  purchase_info_image_url: null,
  master_sign_image_url: null,
  youtube_id: null,
  course_sale_status: 4,
  contract_expire_date: null,
  to_be_open_date: null,
  sale_status_update_time: null,
  sale_comment: '',
  total_running_sec: 0,
  hide_in_list: 0,
  is_require_with_kit: 0,
  only_single_kit: 0,
  cannot_use_coupon: 0,
  can_trigger_course_completed: 1,
  badge_type: '',
  category: 10,
  sub_category_set: [],
  kit_set: [],
  tag_set: [],
  body: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_COURSE_INFO:
      return INIT_STATE;

    case STORED_COURSE_INFO:
      return action.data;

    case SET_COURSE_INFO:
      return {
        ...state,
        [action.name]: action.value,
      };

    case SET_TAG:
      const set_tag_set = state.tag_set;
      set_tag_set.push({ name: action.value });
      return {
        ...state,
        tag_set: set_tag_set,
      };

    case REMOVE_TAG:
      const tag_set = state.tag_set;
      tag_set.splice(action.index, 1);
      return {
        ...state,
        tag_set: tag_set,
      };

    case SET_KIT:
      return {
        ...state,
        kit_set: action.kit_set,
      };

    case SET_SUB_CATEGORY:
      return {
        ...state,
        sub_category_set: action.sub_category_set,
      };

    case SET_COURSE_BODY:
      return {
        ...state,
        body: { [action.name]: action.value },
      };

    default:
      return { ...state };
  }
};
