import React from 'react';
import PropTypes from 'prop-types';
import CustomLabel from './CustomLabel';

const CustomSelectInput = (props) => {
  return (
    <div className='mb-2'>
      <CustomLabel
        tooltip_desc={props.tooltip_desc}
        label={props.label}
        is_required={props.is_required}
      />
      <select
        name={props.name}
        ref={(el) => {
          if (el) {
            el.style.setProperty('color', '#495057', 'important');
          }
        }}
        className='form-control'
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange}
      >
        {props.options.map((elem, index) => {
          let sub_text = '';
          if (props.sub_option_type) {
            sub_text = `, ${elem[props.sub_option_type]}`;
          }

          return (
            <option key={index} value={elem.id}>
              {elem.name}
              {sub_text}
            </option>
          );
        })}
      </select>
    </div>
  );
};

CustomSelectInput.propTypes = {
  property: PropTypes.shape({
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    is_required: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.array,
  }),
};

export default CustomSelectInput;
