import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Card, CardHeader, Col, Row, Table } from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment/moment';

import RecruitFormModel from '../../../model/RecruitFormModel';
import { initComma, toQueryString } from '../../../util/common';
import { PAGE_SIZE, TOTAL_PAGE_SIZE } from '../../../constants';

import Loading from '../../_common/Loading';
import CustomPagination from '../../_common/CustomPagination';
import {
  clear_recruit_form_info,
  set_recruit_form_body,
  set_recruit_form_info,
  stored_recruit_form_info,
} from '../../../redux/bgd/info/action';
import { clear_delete_img } from '../../../redux/common/action';

const RecruitFormRes = ({ additionalProps, RecruitFormInfo }) => {
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [formResponses, setFormResponses] = useState([]);
  const [formResponsesCount, setFormResponsesCount] = useState(0);

  const initData = () => {
    setIsLoading(true);
    RecruitFormModel.getRecruitFormResponses(additionalProps.recruit_form_id, {
      ...params,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ responses, total_count }) => {
      setFormResponses(responses);
      setFormResponsesCount(total_count);
      setIsLoading(false);
    });

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (parseInt(additionalProps.recruit_form_id)) {
      initData();
    } else {
      setIsLoading(false);
    }
  }, [params.page]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    history.push(`?${queryString}`, params);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    });
  };

  const [isDownload, setDownload] = useState(false);

  const onDetailCSVRequest = async (e) => {
    if (!isDownload) {
      e.target.disabled = true;
      setDownload(true);

      const _a = document.createElement('a');
      _a.style = 'display: none';

      try {
        const recruitData = await RecruitFormModel.getRecruitFormResponses(
          additionalProps.recruit_form_id,
          {
            ...params,
            page: 1,
            size: params.size ? parseInt(params.size) : TOTAL_PAGE_SIZE,
          },
        );
        const csvContent = convertToCSV(recruitData.responses);

        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        _a.href = url;
        _a.download = `recruit-detail-info-${moment(new Date()).format('YYYY-MM-DD')}.csv`;
        document.body.appendChild(_a);
        _a.click();
      } catch (error) {
        alert('CSV 파일을 생성하는 중 오류가 발생했습니다. 다시 시도해주세요.');
      } finally {
        e.target.disabled = false;
        setDownload(false);
        if (_a.parentNode) {
          _a.parentNode.removeChild(_a);
        }
      }
    }
  };

  const convertToCSV = (data) => {
    if (!Array.isArray(data)) {
      throw new TypeError('배열 형식의 응답 데이터가 필요합니다.');
    }

    const columns = [
      '모집폼_타이틀',
      '모집시작일',
      '모집마감일',
      '이벤트코드',
      '계정번호',
      '이름',
      '연락처',
      '이메일',
      '결제상태',
      '결제강의',
      '결제일',
      '등록일',
      '유저정보URL',
    ];
    const csvRows = [];

    csvRows.push(columns.join(','));
    data.forEach((response) => {
      const row = [
        RecruitFormInfo?.title || '-',
        RecruitFormInfo?.course_register_start_time || '-',
        RecruitFormInfo?.course_register_end_time || '-',
        RecruitFormInfo?.event_code || '-',
        response.account_id || '-',
        response.user_name || '-',
        response.phone || '-',
        response.email || '-',
        response.billing_status || '-',
        response.course_title || '-',
        response.billing_at || '-',
        response.created_at || '-',
        response.account_id
          ? `${process.env.REACT_APP_API_URL}/account/${response.account_id}`
          : '-',
      ];
      csvRows.push(row.join(','));
    });

    return csvRows.join('\n');
  };

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <p style={{ display: 'inline' }}>
                      총: <span style={{ color: 'coral' }}>{initComma(formResponsesCount)}</span>건
                    </p>
                    <Loading isLoading={isLoading} />
                  </div>
                  <Button
                    id={'help-download-course-detail-favorite-csv'}
                    className='btn btn-pill'
                    color='info'
                    style={{
                      marginRight: '10px',
                      padding: '6px 20px',
                    }}
                    onClick={onDetailCSVRequest}
                  >
                    csv 다운로드
                  </Button>
                </Col>
              </Row>
            </CardHeader>

            <div>
              <Table className='table-hover text-center'>
                <thead>
                  <tr style={{ textAlign: 'center' }}>
                    <th>
                      <span>이름/ID</span>
                    </th>
                    <th>
                      <span>이메일/휴대폰</span>
                    </th>
                    <th>
                      <span>응답</span>
                    </th>
                    <th>
                      <span>결제 과정</span>
                    </th>
                    <th>
                      <span>결제 상태</span>
                    </th>
                    <th>
                      <span>결제일</span>
                    </th>
                    <th>
                      <span>등록일</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {formResponses?.map((formResponse) =>
                    formResponse.answers.map((answer, idx) => (
                      <tr key={idx}>
                        {idx === 0 && (
                          <>
                            <td
                              rowSpan={formResponse.answers.length}
                              style={{ verticalAlign: 'middle', width: '8%' }}
                            >
                              {formResponse.user_name}
                              <br />
                              <a href={`/account/${formResponse.account_id}`}>
                                ({formResponse.account_id})
                              </a>
                            </td>
                            <td
                              rowSpan={formResponse.answers.length}
                              style={{ verticalAlign: 'middle', width: '15%' }}
                            >
                              {formResponse.email}
                              <br />
                              {formResponse.phone}
                            </td>
                          </>
                        )}
                        <td
                          style={{
                            verticalAlign: 'middle',
                            textAlign: 'left',
                          }}
                        >
                          {answer.value.split('||').map((val, valIdx) => (
                            <div key={valIdx}>{val}</div>
                          ))}
                        </td>
                        {idx === 0 && (
                          <>
                            <td
                              rowSpan={formResponse.answers.length}
                              style={{ verticalAlign: 'middle', width: '10%' }}
                            >
                              {formResponse.course_title || '-'}
                            </td>
                            <td
                              rowSpan={formResponse.answers.length}
                              style={{ verticalAlign: 'middle', width: '10%' }}
                            >
                              {formResponse.billing_status}
                            </td>
                            <td
                              rowSpan={formResponse.answers.length}
                              style={{ verticalAlign: 'middle', width: '10%' }}
                            >
                              {formResponse.billing_at
                                ? moment(formResponse.billing_at).format('YYYY.MM.DD HH:mm')
                                : '-'}
                            </td>
                            <td
                              rowSpan={formResponse.answers.length}
                              style={{ verticalAlign: 'middle', width: '10%' }}
                            >
                              {moment(formResponse.created_at).format('YYYY.MM.DD HH:mm')}
                            </td>
                          </>
                        )}
                      </tr>
                    )),
                  )}
                </tbody>
              </Table>
            </div>
            <div className='m-auto'>
              <CustomPagination
                current_page={params.page ? Number(params.page) : 1}
                max_page={Math.ceil(
                  params.size ? formResponsesCount / params.size : formResponsesCount / PAGE_SIZE,
                )}
                onPageChange={onPageChange}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  RecruitFormInfo: state.RecruitFormStore,
  CommonStore: state.CommonStore,
});

const mapDispatchToProps = (dispatch) => {
  return {
    set_recruit_form_info: (name, value) => dispatch(set_recruit_form_info(name, value)),
    set_recruit_form_body: (name, value) => dispatch(set_recruit_form_body(name, value)),
    clear_recruit_form_info: () => dispatch(clear_recruit_form_info()),
    stored_recruit_form_info: (data) => dispatch(stored_recruit_form_info(data)),
    clear_delete_img: (img_type) => dispatch(clear_delete_img(img_type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruitFormRes);
