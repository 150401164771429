import React, { Fragment, useEffect, useState } from 'react';
import AccountModel from '../../model/AccountModel';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import Loading from '../_common/Loading';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import { initComma, toQueryString, zip } from '../../util/common';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomInput from '../_common/component/CustomInput';
import AccountBarChart from './component/AccountBarChart';
import AccountHorizontalBarChart from './component/AccountHorizontalBarChart';
import AccountPieChart from './component/AccountPieChart';

const AccountDashboard = (props) => {
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const [bar_chart_loading, setBarChartLoading] = useState(false);
  const [gender_chart_loading, setGenderChartLoading] = useState(false);
  const [job_chart_loading, setJobChartLoading] = useState(false);
  const [specific_user_info_loading, setSpecificUserInfoLoading] = useState(false);

  // 필터용
  const [is_date_disabled, setisDateDisabled] = useState(true);
  const [is_data_selectable, setisDateSelectable] = useState(false);
  const [date_range, setDateRange] = useState({
    date_start: params.date_start ? params.date_start : '',
    date_end: params.date_end ? params.date_end : '',
  });

  const [age_range, setAgeRange] = useState({
    age_start: params.age_start ? params.age_start : '',
    age_end: params.age_end ? params.age_end : '',
  });

  // barChart 그래프
  const [account_age_set, setAccountAgeSet] = useState([]);

  // pieArea 그래프 (남/여)
  const [gender_count_set, setGenderCountSet] = useState([]);

  // pieArea 그래프 (직업)
  const [job_count_set, setJobCountSet] = useState({});
  const [job_total_count, setJobTotalCount] = useState(0);

  // table (유저 가입, 탈)
  const [specific_user_info, setSpecificUserInfo] = useState({});

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onSearchClick = (e) => {
    pushHistory({
      ...date_range,
      ...age_range,
    });
  };

  const onAgeChange = (e) => {
    setAgeRange({ ...age_range, [e.target.name]: e.target.value });
  };

  // 날짜 수정
  const onDateChange = (e, name) => {
    setDateRange({ ...date_range, [name]: e && moment(e).format('YYYY-MM-DD') });
  };

  const onDateClick = (e) => {
    let date_start = null;
    let date_end = null;
    let add_date;

    if (e.target.value !== 'all' && e.target.value !== 'direct') {
      add_date = Number(e.target.value.split('_')[1]);
      date_end = moment().format('YYYY-MM-DD');
    }

    if (e.target.value === 'direct') {
      date_start = date_range.date_start;
      date_end = date_range.date_end;
      setisDateDisabled(false);
      setisDateSelectable(true);
    } else {
      setisDateDisabled(true);
      setisDateSelectable(false);
    }

    if (typeof add_date === 'number') {
      date_start = moment().add(-add_date, 'days').format('YYYY-MM-DD');
    }

    setDateRange({
      ...date_range,
      date_start: date_start,
      date_end: date_end,
    });

    pushHistory({ ...age_range, ...date_range, date_start: date_start, date_end: date_end });
  };

  const chartLabel = (
    name,
    value,
    unit,
    color_list = ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0'],
  ) => {
    return zip([name, value, color_list]).map((item, index) => {
      return (
        <div key={index} className='m-1'>
          <div
            className='text-truncate'
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px',
            }}
          >
            <div
              style={{
                width: '10px',
                height: '10px',
                marginRight: '3px',
                backgroundColor: `${item[2]}`,
              }}
            />
            {item[0]}
          </div>
          <div>
            <b
              style={{
                fontSize: '12px',
              }}
            >
              {initComma(item[1])} {unit}
            </b>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    if (params.date_start || params.date_end) {
      setisDateSelectable(true);
      setisDateDisabled(false);
    }
  }, []);

  useEffect(() => {
    setGenderChartLoading(true);
    setJobChartLoading(true);
    setBarChartLoading(true);
    setSpecificUserInfoLoading(true);

    AccountModel.getBarDashboard(params)
      .then(({ account_age_set }) => {
        setAccountAgeSet(account_age_set);
      })
      .finally(() => {
        setBarChartLoading(false);
      });

    AccountModel.getJobDashboard(params)
      .then(({ job_count_info, job_total_count }) => {
        setJobCountSet(job_count_info);
        setJobTotalCount(job_total_count);
      })
      .finally(() => {
        setJobChartLoading(false);
      });

    AccountModel.getGenderDashboard(params)
      .then(({ gender_count_info }) => {
        setGenderCountSet(gender_count_info);
      })
      .finally(() => {
        setGenderChartLoading(false);
      });

    AccountModel.getSpecificUserInfoDashboard(params)
      .then((data) => {
        setSpecificUserInfo(data);
      })
      .finally(() => {
        setSpecificUserInfoLoading(false);
      });
  }, [props.location.search]);

  return (
    <Fragment>
      <Breadcrumb parent='SaleDashboard' title='사용자 Dashboard' />
      <Container fluid={true}>
        <Row>
          <Col lg='12 xl-100'>
            <Row>
              <Col md='7'>
                <div style={{ display: 'flex' }}>
                  <div
                    className='form-control'
                    style={{ width: '130px', background: 'transparent', border: '0px' }}
                  >
                    회원가입 날짜
                  </div>
                  <div className={'mr-3'}>
                    <Input
                      type='select'
                      name='job_class'
                      className='form-control'
                      onChange={onDateClick}
                      defaultValue={params.date_start || params.date_end ? 'direct' : 'all'}
                    >
                      <option value='all'>전체</option>
                      <option value='direct'>직접설정</option>
                      <option value='before_0'>오늘</option>
                      <option value='before_1'>어제</option>
                      <option value='before_7'>7일전</option>
                      <option value='before_14'>14일전</option>
                      <option value='before_30'>30일전</option>
                      <option value='before_60'>60일전</option>
                      <option value='before_180'>180일전</option>
                    </Input>
                  </div>

                  <div className={'mr-3'}>
                    <DatePicker
                      autoComplete='off'
                      className='form-control digits'
                      name='date_start'
                      dateFormat='yyyy-MM-dd'
                      disabled={is_date_disabled}
                      onChange={(e) => {
                        onDateChange(e, 'date_start');
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter' || e.code === 'NumpadEnter') onSearchClick(e);
                      }}
                      placeholderText='시작 날짜'
                      selectsStart
                      selected={date_range.date_start ? new Date(date_range.date_start) : null}
                      startDate={date_range.date_start ? new Date(date_range.date_start) : null}
                      endDate={date_range.date_end ? new Date(date_range.date_end) : null}
                      maxDate={date_range.date_end ? new Date(date_range.date_end) : new Date()}
                    />
                  </div>

                  <div className={'mr-3'}>
                    <DatePicker
                      autoComplete='off'
                      className='form-control digits'
                      name='date_end'
                      disabled={is_date_disabled}
                      dateFormat='yyyy-MM-dd'
                      onChange={(e) => {
                        onDateChange(e, 'date_end');
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter' || e.code === 'NumpadEnter') onSearchClick(e);
                      }}
                      placeholderText='마지막 날짜'
                      selectsEnd
                      selected={date_range.date_end ? new Date(date_range.date_end) : null}
                      startDate={date_range.date_start ? new Date(date_range.date_start) : null}
                      endDate={date_range.date_end ? new Date(date_range.date_end) : null}
                      minDate={date_range.date_start ? new Date(date_range.date_start) : null}
                      maxDate={new Date()}
                    />
                  </div>

                  {is_data_selectable && (
                    <Button className='btn btn-primary btn-pill' onClick={onSearchClick}>
                      검색
                    </Button>
                  )}
                </div>
              </Col>
              <Col>
                <Row style={{ justifyContent: 'flex-end' }}>
                  <div
                    className='form-control'
                    style={{ width: '100px', background: 'transparent', border: '0px' }}
                  >
                    나이 범위
                  </div>
                  <CustomInput
                    type={'text'}
                    name={'age_start'}
                    style={{ width: '100px', marginRight: '10px' }}
                    value={age_range.age_start || ''}
                    placeholder={'시작 나이'}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter' || e.code === 'NumpadEnter') onSearchClick(e);
                    }}
                    onChange={onAgeChange}
                    validator={(value) => {
                      const regexp = /^[0-9\b]*$/;
                      return regexp.test(value) && value.length <= 3;
                    }}
                  />
                  <CustomInput
                    type={'text'}
                    name={'age_end'}
                    style={{ width: '100px', marginRight: '10px' }}
                    value={age_range.age_end || ''}
                    placeholder={'끝 나이'}
                    onChange={onAgeChange}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter' || e.code === 'NumpadEnter') onSearchClick(e);
                    }}
                    validator={(value) => {
                      const regexp = /^[0-9\b]*$/;
                      return regexp.test(value) && value.length <= 3;
                    }}
                  />
                  <Button className='btn btn-primary btn-pill' onClick={onSearchClick}>
                    검색
                  </Button>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md='12' className='mt-3'>
                <Card>
                  <CardHeader>
                    <h5>회원 연령 그래프</h5>
                  </CardHeader>
                  <CardBody>
                    {bar_chart_loading ? (
                      <div style={{ margin: 'auto' }}>
                        <Loading isLoading={true} />
                      </div>
                    ) : (
                      <AccountBarChart
                        labels={
                          Object.keys(account_age_set).map((item) => {
                            if (item !== 'null') {
                              return item;
                            } else {
                              return '알수없음';
                            }
                          }) || []
                        }
                        series={[
                          {
                            name: '남자',
                            data: Object.values(account_age_set)
                              .map((gender) => gender.male)
                              .filter((item) => {
                                return item !== undefined;
                              }),
                          },
                          {
                            name: '여자',
                            data: Object.values(account_age_set)
                              .map((gender) => gender.female)
                              .filter((item) => {
                                return item !== undefined;
                              }),
                          },
                          {
                            name: '알수없음',
                            data: Object.values(account_age_set)
                              .map((gender) => gender.null)
                              .filter((item) => {
                                return item !== undefined;
                              }),
                          },
                        ]}
                        x_title_text={'나이'}
                        y_title_text={' 명'}
                        tooltip_y_formatter={(y) => {
                          if (typeof y !== 'undefined') {
                            return initComma(y.toFixed(0)) + ' 명';
                          }
                          return y;
                        }}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md='7' className='mt-3'>
                <Card>
                  <CardHeader>
                    <h5>회원별 직업분포</h5>
                  </CardHeader>
                  <CardBody>
                    {job_chart_loading ? (
                      <div style={{ margin: 'auto' }}>
                        <Loading isLoading={true} />
                      </div>
                    ) : (
                      <AccountHorizontalBarChart
                        data_label_formatter={(val, opts) => {
                          return `${((val / job_total_count) * 100).toFixed(2)}%`;
                        }}
                        tooltip_y_formatter={(y) => {
                          if (typeof y !== 'undefined') {
                            return `${initComma(y.toFixed(0))} 명 (${(
                              (y / job_total_count) *
                              100
                            ).toFixed(2)}%)`;
                          }
                          return y;
                        }}
                        x_categoryies={Object.keys(job_count_set).map((item) => {
                          return item === 'null' ? '알수없음' : item;
                        })}
                        series={[{ name: '회원', data: Object.values(job_count_set) }]}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col md='5' className='mt-3'>
                <Card>
                  <CardHeader>
                    <h5>남/여 비율</h5>
                  </CardHeader>
                  <CardBody style={{ display: 'flex' }}>
                    {gender_chart_loading ? (
                      <div style={{ margin: 'auto' }}>
                        <Loading isLoading={true} />
                      </div>
                    ) : (
                      <AccountPieChart
                        labels={
                          Object.keys(gender_count_set).map((gender_type) => {
                            if (gender_type === 'male') {
                              return '남자';
                            } else if (gender_type === 'female') {
                              return '여자';
                            } else {
                              return '알수없음';
                            }
                          }) || []
                        }
                        tooltip_y_formatter={(y) => {
                          if (typeof y !== 'undefined') {
                            return initComma(y.toFixed(0)) + ' 명';
                          }
                          return y;
                        }}
                        series={Object.values(gender_count_set) || []}
                        chart_label={chartLabel(
                          Object.keys(gender_count_set).map((gender_type) => {
                            if (gender_type === 'male') {
                              return '남자';
                            } else if (gender_type === 'female') {
                              return '여자';
                            } else {
                              return '알수없음';
                            }
                          }),
                          Object.values(gender_count_set),
                          '명',
                          ['#008ffb', '#ff4560', '#feb019'],
                        )}
                      />
                    )}
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h5>회원 정보</h5>
                  </CardHeader>
                  <CardBody style={{ padding: '20px' }}>
                    {specific_user_info_loading ? (
                      <div style={{ margin: 'auto' }}>
                        <Loading isLoading={true} />
                      </div>
                    ) : (
                      <Row style={{ alignItems: 'flex-end' }}>
                        <Col md='6'>
                          <table
                            border='1'
                            style={{
                              borderColor: '#eeeeee',
                              borderCollapse: 'collapse',
                              width: '100%',
                              borderRadius: '4px',
                              borderStyle: 'hidden',
                              boxShadow: '0 0 0 1px #e0e0e0',
                              overflow: 'hidden',
                            }}
                          >
                            <tbody>
                              <tr>
                                <th
                                  className='p-2 text-left'
                                  style={{ backgroundColor: '#f9f9f9' }}
                                >
                                  총 회원 수
                                </th>
                                <td className='text-center'>
                                  {initComma(specific_user_info.total_user_count || 0)} 명
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className='p-2 text-left'
                                  style={{ backgroundColor: '#f9f9f9' }}
                                >
                                  총 강의 보유자 수
                                </th>
                                <td className='text-center'>
                                  {initComma(specific_user_info.total_user_course_exist_count || 0)}{' '}
                                  명
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className='p-2 text-left'
                                  style={{ backgroundColor: '#f9f9f9' }}
                                >
                                  총 실결제자 수<br />
                                  (0원이상&환불X)
                                </th>
                                <td className='text-center'>
                                  {initComma(specific_user_info.total_user_purchased_count || 0)} 명
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                        <Col md='6'>
                          <table
                            border='1'
                            style={{
                              borderColor: '#eeeeee',
                              width: '100%',
                              borderCollapse: 'collapse',
                              borderRadius: '4px',
                              borderStyle: 'hidden',
                              boxShadow: '0 0 0 1px #e0e0e0',
                              overflow: 'hidden',
                            }}
                          >
                            <tbody>
                              <tr>
                                <th
                                  className='p-2 text-left'
                                  style={{ backgroundColor: '#f9f9f9' }}
                                >
                                  가입회원
                                </th>
                                <td className='text-center'>
                                  {initComma(specific_user_info.user_register_count || 0)} 명
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className='p-2 text-left'
                                  style={{ backgroundColor: '#f9f9f9' }}
                                >
                                  탈퇴회원
                                </th>
                                <td className='text-center'>
                                  {initComma(specific_user_info.user_drop_out_count || 0)} 명 <br />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col md='12' className='mt-3' style={{ textAlign: 'right' }}>
                        {params.date_start || params.date_end ? (
                          <div>
                            <b style={{ marginTop: '10px' }}>
                              {'날짜 : ' +
                                (params.date_start || '전체') +
                                '~' +
                                (params.date_end || '전체')}
                            </b>
                            <div>{'(탈퇴 : 탈퇴날짜기준)'}</div>
                          </div>
                        ) : (
                          <div>
                            <b style={{ marginTop: '10px' }}>{'날짜 : 전체'}</b>
                            <div>{'(탈퇴 : 탈퇴날짜기준)'}</div>
                          </div>
                        )}
                        {params.age_start || params.age_end ? (
                          <div>
                            <b style={{ marginTop: '5px' }}>
                              {'나이 : ' +
                                (params.age_start || '전체') +
                                '~' +
                                (params.age_end || '전체')}
                            </b>
                          </div>
                        ) : (
                          <div>
                            <b style={{ marginTop: '5px' }}>{'나이 : 전체'}</b>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AccountDashboard;
