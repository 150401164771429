import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import ProductModel from '../../model/ProductModel';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import { initComma, onBooleanBadgeStyle, toQueryString } from '../../util/common';
import { PAGE_SIZE } from '../../constants';

const Product = (props) => {
  // 기존 props.history.push 로는 state를 가져온다 / 직접 url에 값을 때려 박으면 state에 값이 없어서 search에서 가져와야한다 / 이도저도 아닐 경우
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    search_text: params.search_text ? params.search_text : '',
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const [product_set, setProductSet] = useState([]);
  const [product_count, setProductCountSet] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilter] = useState(default_filter.current);

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    ProductModel.getProduct({
      ...params,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ product_set, total_count }) => {
      setProductSet(product_set);
      setProductCountSet(total_count);
      setIsLoading(false);
    });
  }, [props.location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onFilterSubmit = (e) => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      order_key: order_key,
      order_type: !filter_set.order_type || filter_set.order_type === 'asc' ? 'desc' : 'asc', // 정렬을 기존 기준 -> "없을 경우 or asc = desc로 변경" / "desc 일 경우 = asc 로 변경"
    };

    // 이전에 정렬한적 있고, 새로운 정렬을 적용.
    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }

    pushHistory(newFilterSet);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    });
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <Breadcrumb parent='서비스' title='패키지 목록' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Col className='text-right mb-4'>
              <Link className={'btn btn-primary btn-pill'} to={'/product/0'}>
                패키지생성
              </Link>
            </Col>
            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={9} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총: <span style={{ color: 'coral' }}>{initComma(product_count)}</span>개
                        </p>
                        <Loading isLoading={isLoading} />
                      </Col>
                      <Col md={3} className='text-right'>
                        <FormGroup>
                          <InputGroup>
                            <Input
                              className='form-control'
                              type='text'
                              name='search_text'
                              onChange={onFilterChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onFilterSubmit(e);
                                }
                              }}
                              value={filter_set.search_text}
                              placeholder='내용을 입력하세요.'
                            />
                            <InputGroupAddon
                              addonType='append'
                              style={{ cursor: 'pointer' }}
                              onClick={onFilterSubmit}
                            >
                              <InputGroupText>
                                <i className='icofont icofont-ui-search' />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <div className='table-responsive'>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th style={{ width: '50px', minWidth: '50px', maxWidth: '50px' }}>ID</th>
                      <th
                        style={{
                          textAlign: 'left',
                          width: '500px',
                          minWidth: '500px',
                          maxWidth: '500px',
                        }}
                      >
                        상품명/상품설명
                      </th>
                      <th
                        style={{
                          width: '80px',
                          minWidth: '80px',
                          maxWidth: '80px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('original_price');
                        }}
                      >
                        {filter_set.order_key === 'original_price' ? (
                          <span style={{ color: '#ff4c3b' }}>정가</span>
                        ) : (
                          <span>정가</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '80px',
                          minWidth: '80px',
                          maxWidth: '80px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('price');
                        }}
                      >
                        {filter_set.order_key === 'price' ? (
                          <span style={{ color: '#ff4c3b' }}>판매가</span>
                        ) : (
                          <span>판매가</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '80px',
                          minWidth: '80px',
                          maxWidth: '80px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('tax_free_price');
                        }}
                      >
                        {filter_set.order_key === 'tax_free_price' ? (
                          <span style={{ color: '#ff4c3b' }}>면세가</span>
                        ) : (
                          <span>면세가</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '110px',
                          minWidth: '110px',
                          maxWidth: '110px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('update_time');
                        }}
                      >
                        {filter_set.order_key === 'update_time' ? (
                          <span style={{ color: '#ff4c3b' }}>갱신일</span>
                        ) : (
                          <span>갱신일</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '110px',
                          minWidth: '110px',
                          maxWidth: '110px',
                        }}
                        onClick={() => {
                          onOrderChange('create_time');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'create_time' ? (
                          <span style={{ color: '#ff4c3b' }}>생성일</span>
                        ) : (
                          <span>생성일</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '70px',
                          minWidth: '70px',
                          maxWidth: '70px',
                        }}
                        onClick={() => {
                          onOrderChange('show_in_application');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'show_in_application' ? (
                          <span style={{ color: '#ff4c3b' }}>앱노출</span>
                        ) : (
                          <span>앱노출</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '70px',
                          minWidth: '70px',
                          maxWidth: '70px',
                        }}
                        onClick={() => {
                          onOrderChange('is_enabled');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'is_enabled' ? (
                          <span style={{ color: '#ff4c3b' }}>활성화</span>
                        ) : (
                          <span>활성화</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '140px',
                          minWidth: '140px',
                          maxWidth: '140px',
                        }}
                        onClick={() => {
                          onOrderChange('detail_page_view');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'detail_page_view' ? (
                          <span style={{ color: '#ff4c3b' }}>상세페이지 접근여부</span>
                        ) : (
                          <span>상세페이지 접근여부</span>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {product_set.map((product) => {
                      let url = 'product/' + product.id;
                      const onTdClick = () => {
                        props.history.push(url);
                      };
                      return (
                        <tr key={product.id}>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '50px',
                              maxWidth: '50px',
                            }}
                            className='text-center'
                            onClick={onTdClick}
                            role='button'
                          >
                            {product.id}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              height: '72px',
                              minWidth: '550px',
                              maxWidth: '550px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div className={'text-truncate f-w-900'} style={{ minWidth: '600px' }}>
                              {product.title}
                            </div>
                            <div className={'text-truncate'} style={{ minWidth: '600px' }}>
                              {product.description}
                            </div>
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '85px',
                              maxWidth: '80px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {initComma(product.original_price)}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '85px',
                              maxWidth: '85px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {initComma(product.price)}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '85px',
                              maxWidth: '85px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {initComma(product.tax_free_price)}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '110px',
                              maxWidth: '110px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(product.update_time)).format('YYYY-MM-DD')}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '110px',
                              maxWidth: '110px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {moment(new Date(product.create_time)).format('YYYY-MM-DD')}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '70px',
                              maxWidth: '70px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <span className={onBooleanBadgeStyle(product.show_in_application)}>
                              {product.show_in_application ? '노출' : '숨김'}
                            </span>
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '70px',
                              maxWidth: '70px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <span className={onBooleanBadgeStyle(product.is_enabled)}>
                              {product.is_enabled ? '활성화' : '비활성화'}
                            </span>
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '200px',
                              maxWidth: '200px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <span className={onBooleanBadgeStyle(product.detail_page_view)}>
                              {product.detail_page_view ? '가능' : '불가능'}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size ? product_count / params.size : product_count / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Product;
