import { SET_COURSE_NOTICE, STORED_COURSE_NOTICE, CLEAR_COURSE_NOTICE } from '../../action_type';

export const set_course_notice = (name, value) => ({
  type: SET_COURSE_NOTICE,
  name,
  value,
});

export const stored_course_notice = (data) => ({
  type: STORED_COURSE_NOTICE,
  data,
});

export const clear_course_notice = () => ({
  type: CLEAR_COURSE_NOTICE,
});
