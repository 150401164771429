import React from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import AddCompanyForm from './component/AddCompanyForm';
import CompanyNavTab from './component/CompanyNavTab';
import { useDispatch } from 'react-redux';
import { useMountEffect } from '../../util/b2b_Hooks';
import { clearCompany } from '../../redux/b2b_store/company/actions';

const AddCompany = () => {
  const dispatch = useDispatch();

  useMountEffect(() => {
    dispatch(clearCompany());
  });

  return (
    <>
      <Breadcrumb type='b2b' parent='기업' title='기업추가' />
      <Container fluid={true}>
        <Row>
          <Col xl='12'>
            <Card>
              <AddCompanyForm />
            </Card>
          </Col>
          <Col md='12'>
            <Card>
              <CardBody className='p-3'>
                <CompanyNavTab is_new_company={true} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddCompany;
