import React, { useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CourseModel from '../../../model/CourseModel';
import PurchaseModel from '../../../model/PurchaseModel';
import Loading from '../Loading';

const StatusModal = (props) => {
  const { reply_status_set, update_id_list, redirect_url, type } = props;
  const [status_id, setStatusId] = useState(1);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const statusUpdateSubmit = async () => {
    let target_id_list;
    setIsLoading((prevState) => !prevState);
    if (type === 'qna') {
      const { update_qna_id_set } = await CourseModel.putQnaStatus(update_id_list, status_id);
      target_id_list = update_qna_id_set;
    } else if (type === 'shipping') {
      const { update_shipping_id_set } = await PurchaseModel.putShippingStatus(
        update_id_list,
        status_id,
      );
      target_id_list = update_shipping_id_set;
    } else {
      const { update_review_id_set } = await CourseModel.putReviewStatus(update_id_list, status_id);
      target_id_list = update_review_id_set;
    }

    if (target_id_list.length > 0 && redirect_url) {
      window.location.replace(`${process.env.REACT_APP_API_URL}${redirect_url}`);
    }
    window.location.reload();
  };

  return (
    <>
      <Button
        className='btn-pill btn-air-danger'
        color='danger'
        style={{ marginRight: '8px' }}
        onClick={() => {
          if (update_id_list.length) {
            toggle();
          } else {
            alert('항목을 선택하세요.');
          }
        }}
      >
        변경
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          {type === 'shipping' ? '배송 상태 변경' : '리뷰 상태 변경'}
        </ModalHeader>
        <ModalBody>
          <p>
            상태 변경할 {type === 'shipping' ? '배송' : '리뷰'} ID{' '}
            <span style={{ color: 'red', marginLeft: '5px' }}>[ {update_id_list.join(', ')} ]</span>
          </p>
          <Input
            type='select'
            name='reply_status'
            className='form-control'
            onChange={(e) => {
              setStatusId(e.target.value);
            }}
            value={status_id}
          >
            {reply_status_set.map((status) => {
              return (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              );
            })}
          </Input>
        </ModalBody>
        <ModalFooter>
          {isLoading && <Loading isLoading={isLoading} />}
          <Button color='danger' onClick={statusUpdateSubmit} disabled={isLoading}>
            변경{isLoading ? '중' : ''}
          </Button>
          <Button color='secondary' onClick={toggle}>
            닫기
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default StatusModal;
