import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import { useParameters, useShowDialog } from '../../util/b2b_Hooks';
import CompanyInfo from './component/CompanyInfo';
import TotalProgressChart from './component/TotalProgressChart';
import Top5ProgressChart from './component/Top5ProgressChart';
import MonthlyStudyChart from './component/MonthlyStudyChart';
import CompanyNavTab from './component/CompanyNavTab';
import CompanyModel from '../../model/CompanyModel';
import { useDispatch, useSelector } from 'react-redux';
import { addCompanyId, addCompanyInfo } from '../../redux/b2b_store/company/actions';
import { lang } from '../../lang';
import { useHistory } from 'react-router-dom';
import {
  BROADCAST_NEED_UPDATE,
  useApplicationContext,
} from '../../context/ApplicationContextProvider';
import Loading from '../_common/Loading';

const CompanyDashboard = () => {
  const { params } = useParameters();
  const dispatch = useDispatch();
  const { showAlert } = useShowDialog();
  const history = useHistory();
  const { broadcast } = useApplicationContext();
  const AuthStore = useSelector((state) => state.AuthStore);

  // state
  const [company_info, setCompanyInfo] = useState();
  const [company_graph_data, setCompanyGraphData] = useState();
  const [need_update, setNeedUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //ref
  const company_id = useRef(params.company_id);
  const is_mount = useRef(false);

  useEffect(() => {
    // account_type_id === 3인 경우엔 SignIn에서 로그인 하자마자 dispatch
    if (AuthStore.account_type_id === 5) {
      dispatch(addCompanyId(Number(company_id.current)));
    }
  }, []);

  useEffect(async () => {
    await updateUi();
  }, [need_update]);

  // unMount 컴포넌트의 메모리 누수 방지
  useEffect(() => {
    is_mount.current = true;
    return () => {
      is_mount.current = false;
    };
  }, []);

  useEffect(() => {
    const unlisten = broadcast.listen([BROADCAST_NEED_UPDATE], ({ value }) => {
      if (value) {
        setNeedUpdate(value);
      }
    });

    return () => {
      broadcast.unlisten(unlisten);
    };
  }, [broadcast]);

  const updateUi = async () => {
    setIsLoading(true);

    // 기업상세정보 가져오기 API
    const company_detail_res = await CompanyModel.getCompanyDetail(company_id.current);

    if (company_detail_res.code === 200) {
      dispatch(addCompanyInfo(company_detail_res.data.company));

      if (is_mount.current) {
        setCompanyInfo(company_detail_res.data.company);
      }

      // 기업의 그래프 정보 가져오기 API
      const company_graph_res = await CompanyModel.getCompanyGraph(company_id.current);
      if (company_graph_res.code === 200) {
        if (is_mount.current) {
          setCompanyGraphData(company_graph_res.data);
        }
      } else {
        showAlert({
          title: lang.request.request_error_title,
          text: company_graph_res.message,
        });
      }
    } else {
      showAlert({
        title: lang.request.request_error_title,
        text: company_detail_res.message,
      });
      history.push('/company-list');
    }

    if (is_mount.current) {
      setIsLoading(false);
      setNeedUpdate(false);
    }
  };

  return (
    <>
      <Breadcrumb type='b2b' parent='기업' title={company_info && company_info.name} />
      <Container fluid={true}>
        <Row>
          {isLoading ? (
            <Col md={12} className='text-center'>
              <Card>
                <CardHeader>
                  <Loading isLoading={isLoading} />
                </CardHeader>
              </Card>
            </Col>
          ) : (
            <>
              <Col xl='12'>
                <CompanyInfo company_info={company_info} setNeedUpdate={setNeedUpdate} />
              </Col>
              <Col md='6'>
                <TotalProgressChart company_graph_data={company_graph_data} />
              </Col>
              <Col md='6'>
                <Top5ProgressChart company_graph_data={company_graph_data} />
              </Col>
              <Col md='12'>
                <MonthlyStudyChart company_graph_data={company_graph_data} />
              </Col>
              <Col md='12'>
                <Card>
                  <CardBody className='p-3'>
                    <CompanyNavTab is_new_company={false} />
                  </CardBody>
                </Card>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default CompanyDashboard;
