import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import content from '../lang/ko';
import { useSelector } from 'react-redux';
import AccountModel from '../model/AccountModel';
import { lang } from '../lang';

export const useMountEffect = (on_mount_effect) => {
  const is_mount = useRef(false);
  const mount_count = useRef(0);

  const isMount = useCallback(() => {
    return is_mount.current;
  }, []);

  useLayoutEffect(() => {
    is_mount.current = true;
    mount_count.current = mount_count.current + 1;

    if (mount_count.current === 1) {
      // 최초 한번만 실행
      on_mount_effect && on_mount_effect();
    }

    return () => {
      is_mount.current = false;
    };
  }, [on_mount_effect]);

  return [isMount];
};

export const useScrollEvent = (on_scroll) => {
  const onScroll = useCallback(
    (event) => {
      on_scroll &&
        on_scroll({
          x: window.scrollX,
          y: window.scrollY,
          event,
        });
    },
    [on_scroll],
  );

  useEffect(() => {
    onScroll();
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);
};

// get pathname -> params(object)
// get url parameter -> searchParams.get(string)
// get state -> state(object)
export const useParameters = () => {
  const { state } = useLocation();
  const params = useParams();
  return useMemo(() => {
    return {
      state,
      params,
    };
  }, [state, params]);
};

export const useBeforeUnload = (on_before_unload) => {
  const onBeforeUnload = useCallback(
    (event) => {
      let is_blocking = on_before_unload && on_before_unload(event);

      if (is_blocking) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    [on_before_unload],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });
};

export const dialog_type_alert = 1;
export const dialog_type_confirm = 2;
export const dialog_type_toast_success = 1;
export const dialog_type_toast_info = 2;
export const dialog_type_toast_error = 3;

export const useShowDialog = () => {
  /**
   *
   * @param type : dialog_type_alert || dialog_type_confirm
   * @param title : string
   * @param text : string || null
   * @param icon : string || "error"
   * @param showCancelButton : boolean || false
   * @param cancelButtonText : string || "돌아가기"
   * @param confirmButtonText : string || "확인"
   * @param true_fn : function || null -> confirm의 true일 때 실행
   * @param false_fn : function || null -> confirm의 false일 때 실행
   */
  const showAlert = useCallback(
    ({
      type = dialog_type_alert,
      title,
      text = null,
      icon = type === dialog_type_confirm ? 'question' : 'error',
      showCancelButton = false,
      cancelButtonText = showCancelButton ? content.common.cancel_button_common : null,
      confirmButtonText = icon === 'error'
        ? content.common.cancel_button_common
        : content.common.confirm_button_common,
      true_fn = null,
      false_fn = null,
    }) => {
      let options = {
        title,
        text,
        icon,
        showCancelButton,
        cancelButtonText,
        confirmButtonText,
      };
      if (type === dialog_type_alert) {
        SweetAlert.fire(options);
      } else if (type === dialog_type_confirm) {
        SweetAlert.fire(options).then((result) => {
          if (result.value) {
            true_fn && true_fn();
          } else {
            false_fn && false_fn();
          }
        });
      }
    },
    [],
  );

  return { showAlert };
};

export const useCheckCompanyId = () => {
  const AuthStore = useSelector((state) => state.AuthStore);
  const { showAlert } = useShowDialog();

  const getCompanyId = useCallback(async () => {
    const get_company_id_by_account_id_res = await AccountModel.getCompanyIdByAccountId();

    if (get_company_id_by_account_id_res.code === 200) {
      return get_company_id_by_account_id_res.data.company_id;
    } else {
      showAlert({
        title: lang.request.request_error_title,
        text: get_company_id_by_account_id_res.message,
      });
      window.location = '/member';

      return null;
    }
  }, []);

  useEffect(() => {
    if (AuthStore) {
      if (AuthStore.account_type_id === 5) {
        return true;
      } else if (AuthStore.account_type_id === 3) {
        getCompanyId();
      } else {
        // @TODO 현수: 수정 필요
        // showAlert({
        //     title: "해당 계정은 기업관리자 권한이 필요합니다",
        //     text: "관리자에게 문의해주세요",
        //     icon: "error",
        //     confirmButtonText: "확인",
        // });
        //
        // window.location = "/";
      }
    }
  }, [getCompanyId]);

  return { getCompanyId };
};
