import React, { Fragment, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import Loading from '../_common/Loading';
import { initComma, toQueryString } from '../../util/common';
import PurchaseModel from '../../model/PurchaseModel';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import queryString from 'query-string';
import CustomDateRangeFilter from '../_common/component/CustomDateRangeFilter';
import CustomLabel from '../_common/component/CustomLabel';
import { Link } from 'react-router-dom';

const SaleInfo = (props) => {
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const default_filter = useRef({
    sale_info_type: params.sale_info_type ? params.sale_info_type : 'course',
    search_text: params.search_text ? params.search_text : '',
    order_key: params.order_key ? params.order_key : '',
    date_start: params.date_start ? params.date_start : '',
    date_end: params.date_end ? params.date_end : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const [sale_info_set, setSaleInfoSet] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilter] = useState(default_filter.current);

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    if (params.sale_info_type === 'product') {
      PurchaseModel.getPurchaseProductSale({
        ...params,
      }).then(async ({ product_sale_info_set }) => {
        const new_set = [];

        for (const [key, value] of Object.entries(product_sale_info_set)) {
          const {
            product_title,
            total_product_price,
            total_discounted_price,
            total_refund_price,
            total_purchased_sale_count,
            paid_purchased_sale_count,
            refund_purchased_sale_count,
            total_actual_price: total_actual_purchased_price,
          } = value;

          new_set.push({
            product_title,
            total_product_price,
            total_discounted_price,
            total_refund_price,
            total_actual_purchased_price,
            total_purchased_sale_count,
            paid_purchased_sale_count,
            refund_purchased_sale_count,
            product_id: Number(key),
          });
        }

        setSaleInfoSet(returnOrderedSet(new_set, params.order_key, params.order_type));
        setIsLoading(false);
      });
    } else if (params.sale_info_type === 'kit') {
      PurchaseModel.getPurchaseKitSale({
        ...params,
      }).then(({ kit_sale_info_set, kit_set }) => {
        let new_set = returnOrderedSet(
          [...kit_sale_info_set, ...kit_set],
          params.order_key,
          params.order_type,
        );
        setSaleInfoSet(new_set);
        setIsLoading(false);
      });
    } else {
      PurchaseModel.getPurchaseCourseSale({
        ...params,
      }).then(async ({ course_sale_info_set }) => {
        const new_set = [];

        for (const [key, value] of Object.entries(course_sale_info_set)) {
          const {
            course_title,
            commission_rate,
            total_product_price,
            total_discounted_price,
            total_refund_price,
            total_purchased_sale_count,
            paid_purchased_sale_count,
            refund_purchased_sale_count,
            total_actual_price: total_actual_purchased_price,
          } = value;

          new_set.push({
            course_title,
            commission_rate,
            total_product_price,
            total_discounted_price,
            total_refund_price,
            total_actual_purchased_price,
            total_purchased_sale_count,
            paid_purchased_sale_count,
            refund_purchased_sale_count,
            course_id: Number(key),
          });
        }

        setSaleInfoSet(returnOrderedSet(new_set, params.order_key, params.order_type));
        setIsLoading(false);
      });
    }
  }, [props.location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onFilterSubmit = (e) => {
    pushHistory({
      ...filter_set,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onOrderChange = (order_key) => {
    let current_order_type;

    // order_key 가 다를 경우는 무조건 내림차 순 부터
    if (filter_set.order_type === 'asc' || order_key !== filter_set.order_key) {
      current_order_type = 'desc';
      pushHistory({ ...filter_set, order_key: order_key, order_type: current_order_type });
    } else {
      current_order_type = 'asc';
      pushHistory({ ...filter_set, order_key: order_key, order_type: current_order_type });
    }
  };

  const returnOrderedSet = (before_state, order_key = 'id', current_order_type = 'asc') => {
    before_state.sort((a, b) => {
      if (order_key === 'id' || order_key === '') {
        order_key = params.sale_info_type + '_id';
      }

      let nameA = a[order_key];
      let nameB = b[order_key];
      if (current_order_type === 'asc' || current_order_type === '') {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      } else {
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
      }
      // 이름이 같을 경우
      return 0;
    });
    return before_state;
  };

  const changeSaleInfoType = (type) => {
    pushHistory({ ...filter_set, sale_info_type: type, search_text: '' });
  };

  const displaySaleInfoType = (type) => {
    let sale_info_name;
    if (type === 'product') {
      sale_info_name = '패키지명';
    } else if (type === 'kit') {
      sale_info_name = '키트명';
    } else {
      sale_info_name = '강의명';
    }
    return sale_info_name;
  };

  return (
    <Fragment>
      <Breadcrumb parent='매출' title='판매정보' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <Row>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '50px',
                    }}
                  >
                    <button
                      style={{
                        width: '33%',
                        margin: '0px 5px',
                        background: 'transparent',
                        border: '2px solid transparent',
                        padding: '5px 10px',
                        fontSize: '20px',
                        color: `${
                          params.sale_info_type === 'course' || !params.sale_info_type
                            ? '#7e37d8'
                            : 'black'
                        }`,
                        borderBottom: `2px solid ${
                          params.sale_info_type === 'course' || !params.sale_info_type
                            ? '#7e37d8'
                            : 'rgba(171,169,172,0.30)'
                        }`,
                      }}
                      onClick={() => {
                        changeSaleInfoType('course');
                      }}
                    >
                      강의
                    </button>
                    <button
                      style={{
                        width: '33%',
                        margin: '0px 5px',
                        background: 'transparent',
                        border: '2px solid transparent',
                        padding: '5px 10px',
                        fontSize: '20px',
                        color: `${params.sale_info_type === 'product' ? '#7e37d8' : 'black'}`,
                        borderBottom: `2px solid ${
                          params.sale_info_type === 'product' ? '#7e37d8' : 'rgba(171,169,172,0.30)'
                        }`,
                      }}
                      onClick={() => {
                        changeSaleInfoType('product');
                      }}
                    >
                      패키지
                    </button>
                    <button
                      style={{
                        width: '33%',
                        margin: '0px 5px',
                        background: 'transparent',
                        border: '2px solid transparent',
                        padding: '5px 10px',
                        fontSize: '20px',
                        color: `${params.sale_info_type === 'kit' ? '#7e37d8' : 'black'}`,
                        borderBottom: `2px solid ${
                          params.sale_info_type === 'kit' ? '#7e37d8' : 'rgba(171,169,172,0.30)'
                        }`,
                      }}
                      onClick={() => {
                        changeSaleInfoType('kit');
                      }}
                    >
                      키트
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} style={{ margin: 'auto' }}>
                    <p style={{ marginTop: '7px', display: 'inline' }}>
                      총: {sale_info_set.length}
                    </p>
                    <Loading isLoading={isLoading} />
                  </Col>
                  <CustomDateRangeFilter
                    style={[
                      { col: 2, label_disable: true },
                      { label_disable: true },
                      { label_disable: true },
                    ]}
                    setFilter={setFilter}
                    filter_set={filter_set}
                  />
                  <Col md={3} className='text-right'>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          className='form-control'
                          type='text'
                          name='search_text'
                          onChange={onFilterChange}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              onFilterSubmit(e);
                            }
                          }}
                          value={filter_set.search_text}
                          placeholder={`${displaySaleInfoType(
                            params.sale_info_type,
                          )} 를 입력하세요.`}
                        />
                        <InputGroupAddon
                          addonType='append'
                          style={{ cursor: 'pointer' }}
                          onClick={onFilterSubmit}
                        >
                          <InputGroupText>
                            <i className='icofont icofont-ui-search' />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <div className='table-responsive'>
                  <Table>
                    <thead>
                      <tr style={{ textAlign: 'center' }}>
                        <th
                          style={{
                            width: '70px',
                            maxWidth: '70px',
                            mimWidth: '70px',
                          }}
                          className='table-sort-th'
                          onClick={() => {
                            onOrderChange('id');
                          }}
                        >
                          {filter_set.order_key === 'id' ? (
                            <span style={{ color: '#ff4c3b' }}>ID</span>
                          ) : (
                            <span>ID</span>
                          )}
                        </th>
                        <th
                          style={{
                            width: '450px',
                            minWidth: '420px',
                            maxWidth: '420px',
                            textAlign: 'left',
                          }}
                        >
                          {displaySaleInfoType(params.sale_info_type)}
                        </th>
                        {params.sale_info_type !== 'product' && params.sale_info_type !== 'kit' && (
                          <th
                            className='table-sort-th'
                            style={{
                              width: '110px',
                              maxWidth: '110px',
                              mimWidth: '110px',
                            }}
                            onClick={() => {
                              onOrderChange('commission_rate');
                            }}
                          >
                            {filter_set.order_key === 'commission_rate' ? (
                              <span style={{ color: '#ff4c3b' }}>수익분배율</span>
                            ) : (
                              <span>수익분배율</span>
                            )}
                          </th>
                        )}
                        <th
                          style={{
                            width: '120px',
                            maxWidth: '120px',
                            mimWidth: '120px',
                          }}
                          className='table-sort-th'
                          onClick={() => {
                            onOrderChange('total_product_price');
                          }}
                        >
                          {filter_set.order_key === 'total_product_price' ? (
                            <span style={{ color: '#ff4c3b' }}>①매출금액</span>
                          ) : (
                            <span>①매출금액</span>
                          )}
                        </th>
                        <th
                          style={{
                            width: '120px',
                            maxWidth: '120px',
                            mimWidth: '120px',
                          }}
                          className='table-sort-th'
                          onClick={() => {
                            onOrderChange('total_discounted_price');
                          }}
                        >
                          {filter_set.order_key === 'total_discounted_price' ? (
                            <span style={{ color: '#ff4c3b' }}>②할인금액</span>
                          ) : (
                            <span>②할인금액</span>
                          )}
                        </th>
                        <th
                          style={{
                            width: '120px',
                            maxWidth: '120px',
                            mimWidth: '120px',
                          }}
                          className='table-sort-th'
                          onClick={() => {
                            onOrderChange('total_refund_price');
                          }}
                        >
                          {filter_set.order_key === 'total_refund_price' ? (
                            <span style={{ color: '#ff4c3b' }}>③환불금액</span>
                          ) : (
                            <span>③환불금액</span>
                          )}
                        </th>
                        <th
                          style={{
                            width: '150px',
                            maxWidth: '150px',
                            mimWidth: '150px',
                          }}
                          className='table-sort-th'
                          onClick={() => {
                            onOrderChange('total_actual_purchased_price');
                          }}
                        >
                          {filter_set.order_key === 'total_actual_purchased_price' ? (
                            <span style={{ color: '#ff4c3b' }}>
                              {params.sale_info_type !== 'kit' && (
                                <CustomLabel
                                  tooltip_desc={'키트구매금액 또한 제거합니다. (①-②-③)'}
                                />
                              )}
                              실매출금액
                            </span>
                          ) : (
                            <span>
                              {params.sale_info_type !== 'kit' && (
                                <CustomLabel
                                  tooltip_desc={'키트구매금액 또한 제거합니다. (①-②-③)'}
                                />
                              )}
                              실매출금액
                            </span>
                          )}
                        </th>
                        <th
                          style={{
                            width: '100px',
                            maxWidth: '100px',
                            mimWidth: '100px',
                          }}
                          className='table-sort-th'
                          onClick={() => {
                            onOrderChange('total_purchased_sale_count');
                          }}
                        >
                          {filter_set.order_key === 'total_purchased_sale_count' ? (
                            <span style={{ color: '#ff4c3b' }}>총주문수</span>
                          ) : (
                            <span>총주문수</span>
                          )}
                        </th>
                        <th
                          style={{
                            width: '120px',
                            maxWidth: '120px',
                            mimWidth: '120px',
                          }}
                          className='table-sort-th'
                          onClick={() => {
                            onOrderChange('paid_purchased_sale_count');
                          }}
                        >
                          {filter_set.order_key === 'paid_purchased_sale_count' ? (
                            <span style={{ color: '#ff4c3b' }}>결제된주문수</span>
                          ) : (
                            <span>결제된주문수</span>
                          )}
                        </th>
                        <th
                          style={{
                            width: '120px',
                            maxWidth: '120px',
                            mimWidth: '120px',
                          }}
                          className='table-sort-th'
                          onClick={() => {
                            onOrderChange('refund_purchased_sale_count');
                          }}
                        >
                          {filter_set.order_key === 'refund_purchased_sale_count' ? (
                            <span style={{ color: '#ff4c3b' }}>환불된주문수</span>
                          ) : (
                            <span>환불된주문수</span>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoading &&
                        sale_info_set.map((sale_info, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className='text-center'
                                style={{
                                  verticalAlign: 'middle',
                                  maxWidth: '70px',
                                  mimWidth: '70px',
                                }}
                              >
                                <Link
                                  to={`/${params.sale_info_type || 'course'}/${
                                    sale_info[(params.sale_info_type || 'course') + '_id']
                                  }`}
                                >
                                  {sale_info[(params.sale_info_type || 'course') + '_id']}
                                </Link>
                              </td>
                              <td
                                style={{
                                  verticalAlign: 'middle',
                                }}
                              >
                                <Link
                                  // className="text-truncate"
                                  to={`/${params.sale_info_type || 'course'}/${
                                    sale_info[(params.sale_info_type || 'course') + '_id']
                                  }`}
                                >
                                  {sale_info[(params.sale_info_type || 'course') + '_title']}
                                </Link>
                              </td>
                              {params.sale_info_type !== 'product' &&
                                params.sale_info_type !== 'kit' && (
                                  <td
                                    className='text-center'
                                    style={{
                                      verticalAlign: 'middle',
                                      maxWidth: '110px',
                                      mimWidth: '110px',
                                    }}
                                  >
                                    {sale_info['commission_rate']
                                      ? sale_info['commission_rate'] + '%'
                                      : '-'}
                                  </td>
                                )}
                              <td
                                className='text-center'
                                style={{
                                  verticalAlign: 'middle',
                                  maxWidth: '120px',
                                  mimWidth: '120px',
                                }}
                              >
                                {initComma(sale_info.total_product_price ?? '0')}
                              </td>
                              <td
                                className='text-center'
                                style={{
                                  verticalAlign: 'middle',
                                  maxWidth: '120px',
                                  mimWidth: '120px',
                                }}
                              >
                                {initComma(sale_info.total_discounted_price ?? '0')}
                              </td>
                              <td
                                className='text-center'
                                style={{
                                  verticalAlign: 'middle',
                                  maxWidth: '120px',
                                  mimWidth: '120px',
                                }}
                              >
                                {initComma(sale_info.total_refund_price ?? '0')}
                              </td>
                              <td
                                className='text-center'
                                style={{
                                  verticalAlign: 'middle',
                                  maxWidth: '120px',
                                  mimWidth: '120px',
                                }}
                              >
                                {initComma(sale_info.total_actual_purchased_price ?? '0')}
                              </td>
                              <td
                                className='text-center'
                                style={{
                                  verticalAlign: 'middle',
                                  maxWidth: '100px',
                                  mimWidth: '100px',
                                }}
                              >
                                {initComma(sale_info.total_purchased_sale_count ?? '0')}
                              </td>
                              <td
                                className='text-center'
                                style={{
                                  verticalAlign: 'middle',
                                  maxWidth: '120px',
                                  mimWidth: '120px',
                                }}
                              >
                                {initComma(sale_info.paid_purchased_sale_count ?? '0')}
                              </td>
                              <td
                                className='text-center'
                                style={{
                                  verticalAlign: 'middle',
                                  minWidth: '130px',
                                  maxWidth: '130px',
                                }}
                              >
                                {initComma(sale_info.refund_purchased_sale_count ?? '0')}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SaleInfo;
