import {
  STORED_RECRUIT_FORM_INFO,
  CLEAR_RECRUIT_FORM_INFO,
  SET_RECRUIT_FORM_INFO,
  SET_RECRUIT_FORM_BODY,
} from '../../action_type';

const INIT_STATE = {
  type: '빡공단',
  title: '',
  event_id: -1,
  event_code: '',
  recruit_form_sale_status_set: 4,
  apply_start_time: '',
  apply_end_time: '',
  course_register_start_time: '',
  course_register_end_time: '',
  result_notice_time: '',
  hide_in_list: 1,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_RECRUIT_FORM_INFO:
      return INIT_STATE;

    case STORED_RECRUIT_FORM_INFO:
      return action.data;

    case SET_RECRUIT_FORM_INFO:
      return {
        ...state,
        [action.name]: action.value,
      };

    case SET_RECRUIT_FORM_BODY:
      return {
        ...state,
        [action.name]: action.value,
      };

    default:
      return { ...state };
  }
};
