import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  EMPLOYEE_LIST_SHOW_COUNT,
  EMPLOYEE_STATUS_DOSE_NOT_USE,
  EMPLOYEE_STATUS_USING,
  INIT_PAGE_COUNT,
  TOGGLE_SORT_NUM_INIT,
} from '../../../constant/options';
import { lang } from '../../../lang';
import {
  autoHyphenPhoneNumber,
  getPageArray,
  getShowList,
  getTotalPageCount,
} from '../../../util/b2b_utils';
import { FormGroup, Input, Table } from 'reactstrap';
import Paging from '../../_common/Paging';
import TableHead from '../../_common/component/TableHead';
import { useParameters } from '../../../util/b2b_Hooks';
import moment from 'moment';

/*
    props
    table_head: {}                          options.js의 TABLE_HEAD_EMPLOYEE 참고
    is_editable: bool                       EmployeeList의 is_editable -> 편집모드
    employee_set: []                        직원목록
    onChangeEmployeeState: fnc()=>void      직원의 상태변경 함수
    is_status_update_loading: bool          상태변경 API의 loading
    is_new_company: bool                    기업등록 이전의 상태 -> true
 */
const EmployeeTable = (props) => {
  const history = useHistory();
  const { params } = useParameters();
  const { total_page_count } = getTotalPageCount(
    props.employee_set?.length,
    EMPLOYEE_LIST_SHOW_COUNT,
  );
  const { page_array } = getPageArray(total_page_count);

  // state
  const [page_count, setPageCount] = useState(INIT_PAGE_COUNT);
  const [current_employee_set, setCurrentEmployeeSet] = useState([]);
  // toggle_sort : 넘버링 1 ~ 3 || 0
  const [toggle_sort, setToggleSort] = useState(TOGGLE_SORT_NUM_INIT);
  const [is_sorted, setIsSorted] = useState(false);

  // ref
  const empty_comment = useRef(lang.employee.did_not_exist_employee);
  const target_array = useRef(props.table_head.map((item) => item.target));
  const company_id = useRef(params.company_id);

  const reloadCurrentEmployeeSet = useCallback(() => {
    setCurrentEmployeeSet(
      getShowList({
        array: props.employee_set,
        show_count: EMPLOYEE_LIST_SHOW_COUNT,
        page_count,
        total_page_count,
      }),
    );
  }, [page_count, total_page_count, props.employee_set.length, props.employee_set]);

  useEffect(() => {
    reloadCurrentEmployeeSet();
  }, [reloadCurrentEmployeeSet]);

  const onHandlePageCount = (direction) => {
    if (direction === 'Prev' && page_count > 1) {
      setPageCount(page_count - 1);
    } else if (direction === 'Next' && page_count < total_page_count) {
      setPageCount(page_count + 1);
    }
  };

  const toggleSortByTarget = (key_target) => {
    if (props.employee_set.length >= 1) {
      key_target === 'last_attendance_time'
        ? executeSortByLastAttendanceTime()
        : executeSort(key_target);
    }
  };

  // 마지막 수강시간의 정렬
  const executeSortByLastAttendanceTime = () => {
    if (is_sorted) {
      let tmp_array = [];
      props.employee_set.map((employee) => {
        return employee.last_attendance_time && tmp_array.push(employee);
      });
      tmp_array.sort((a, b) =>
        a.last_attendance_time < b.last_attendance_time
          ? 1
          : a.last_attendance_time > b.last_attendance_time
            ? -1
            : 0,
      );

      tmp_array.map((tmp_item) => {
        let index = props.employee_set.findIndex((employee) => employee.id === tmp_item.id);
        props.employee_set.splice(index, 1);
        props.employee_set.unshift(tmp_item);
        return null;
      });

      setIsSorted(!is_sorted);
      reloadCurrentEmployeeSet();
    } else if (!is_sorted) {
      let tmp_array = [];
      props.employee_set.map((employee) => {
        return employee.last_attendance_time && tmp_array.push(employee);
      });
      tmp_array.sort((a, b) =>
        a.last_attendance_time < b.last_attendance_time
          ? -1
          : a.last_attendance_time > b.last_attendance_time
            ? 1
            : 0,
      );

      tmp_array.map((tmp_item) => {
        let index = props.employee_set.findIndex((employee) => employee.id === tmp_item.id);
        props.employee_set.splice(index, 1);
        props.employee_set.unshift(tmp_item);
        return null;
      });

      setIsSorted(!is_sorted);
      reloadCurrentEmployeeSet();
    }
  };

  // 이름, 전체 진도율의 정렬
  const executeSort = (key_target) => {
    if (is_sorted) {
      props.employee_set.sort((a, b) =>
        a[key_target] > b[key_target] ? 1 : a[key_target] < b[key_target] ? -1 : 0,
      );
      setIsSorted(!is_sorted);
      reloadCurrentEmployeeSet();
    } else if (!is_sorted) {
      props.employee_set.sort((a, b) =>
        a[key_target] < b[key_target] ? 1 : a[key_target] > b[key_target] ? -1 : 0,
      );
      setIsSorted(!is_sorted);
      reloadCurrentEmployeeSet();
    }
  };

  const EmployeeTableBodyView = () => {
    return current_employee_set.length >= 1 ? (
      current_employee_set.map((employee, index) => {
        const visible_last_attendance_time =
          employee.last_attendance_time &&
          moment(employee.last_attendance_time).format('YYYY-MM-DD HH:mm:ss').split(' ');
        return (
          <tr
            style={
              props.is_new_company || props.is_editable
                ? { cursor: 'default' }
                : { cursor: 'pointer' }
            }
            key={index}
            onClick={() => {
              if (!props.is_new_company) {
                !props.is_editable &&
                  history.push(`/company/${company_id.current}/employee/${employee.id}`);
              }
            }}
          >
            {target_array.current.map((target, i) => {
              if (target === 'last_attendance_time') {
                return employee.status_id === EMPLOYEE_STATUS_USING &&
                  visible_last_attendance_time ? (
                  <td
                    key={i}
                    style={{
                      verticalAlign: 'middle',
                      height: '67.5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {visible_last_attendance_time[0]}
                    <br />
                    {visible_last_attendance_time[1]}
                  </td>
                ) : employee.status_id === EMPLOYEE_STATUS_DOSE_NOT_USE ? (
                  <td
                    key={i}
                    style={{
                      verticalAlign: 'middle',
                      height: '67.5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    -
                  </td>
                ) : (
                  <td
                    key={i}
                    style={{
                      verticalAlign: 'middle',
                      height: '67.5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    수강전
                  </td>
                );
              } else if (target === 'total_attendance_rate') {
                return employee.status_id === EMPLOYEE_STATUS_USING ? (
                  <td
                    key={i}
                    style={{
                      verticalAlign: 'middle',
                      height: '67.5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {(employee[target] * 100).toFixed(1)}%
                  </td>
                ) : (
                  <td
                    key={i}
                    style={{
                      verticalAlign: 'middle',
                      height: '67.5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    0%
                  </td>
                );
              } else if (target === 'status_id') {
                return props.is_editable ? (
                  <td key={i}>
                    <FormGroup
                      style={{
                        margin: '0',
                      }}
                    >
                      {props.is_status_update_loading ? (
                        <div
                          className='loader-box text-center'
                          style={{
                            display: 'flex',
                            width: '84px',
                            height: '33px',
                          }}
                        >
                          <div className='loader-15' />
                        </div>
                      ) : (
                        <Input
                          id={`e-id-${employee.id}`}
                          data-id={employee.id}
                          type='select'
                          className='custom-select'
                          defaultValue={Number(employee.status_id)}
                          onChange={(e) => {
                            props.onChangeEmployeeState(e);
                          }}
                        >
                          <option value={EMPLOYEE_STATUS_USING}>이용중</option>
                          <option value={EMPLOYEE_STATUS_DOSE_NOT_USE}>비활성</option>
                        </Input>
                      )}
                    </FormGroup>
                  </td>
                ) : (
                  <td
                    key={i}
                    style={{
                      verticalAlign: 'middle',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {employee[target] === EMPLOYEE_STATUS_USING
                      ? '이용중'
                      : employee[target] === EMPLOYEE_STATUS_DOSE_NOT_USE
                        ? '비활성'
                        : '이용대기'}
                  </td>
                );
              } else if (target === 'phone') {
                return (
                  <td
                    key={i}
                    style={{
                      verticalAlign: 'middle',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {autoHyphenPhoneNumber(employee[target])}
                  </td>
                );
              } else {
                return (
                  <td
                    key={i}
                    style={{
                      verticalAlign: 'middle',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {employee[target]}
                  </td>
                );
              }
            })}
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan='8' className='text-center'>
          {empty_comment.current}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table className='table-hover text-center'>
        <TableHead
          table_head={props.table_head}
          toggle_sort={toggle_sort}
          setToggleSort={setToggleSort}
          toggleSortByTarget={toggleSortByTarget}
        />
        <tbody>
          <EmployeeTableBodyView />
        </tbody>
      </Table>
      <Paging
        page_array={page_array}
        setPageCount={setPageCount}
        page_count={page_count}
        onHandlePageCount={onHandlePageCount}
      />
    </>
  );
};

export default EmployeeTable;
