import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import AddAccountForm from './component/AddAccountForm';
import { useSelector } from 'react-redux';
import Loading from '../_common/Loading';
import { useParameters, useShowDialog } from '../../util/b2b_Hooks';
import { useHistory } from 'react-router-dom';
import CompanyModel from '../../model/CompanyModel';
import { lang } from '../../lang';

const AddAccount = () => {
  const { params } = useParameters();
  const { showAlert } = useShowDialog();
  const history = useHistory();
  const company_reducer = useSelector((state) => state.company_reducer);
  const temporary_reducer = useSelector((state) => state.temporary_reducer);

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [is_new_company, setIsNewCompany] = useState(false);
  const [can_resist_employee_count, setCanResistEmployeeCount] = useState(0);
  const [need_update, setNeedUpdate] = useState(false);

  // ref
  const company_id = useRef(params.company_id);

  useEffect(() => {
    updateUi();
  }, [need_update]);

  const updateUi = async () => {
    setIsLoading(true);

    if (company_id.current === 'new') {
      // 기업 등록 전 직원 등록 가능 인원 설정 (사용인원(temp_reducer) - 현재 직원 수(temp_reducer))
      setIsNewCompany(true);
      if (temporary_reducer.temp_licensed_employee_count) {
        if (temporary_reducer.temp_employee_set) {
          setCanResistEmployeeCount(
            temporary_reducer.temp_licensed_employee_count -
              temporary_reducer.temp_employee_set.length,
          );
        } else {
          setCanResistEmployeeCount(temporary_reducer.temp_licensed_employee_count);
        }
      }
    } else {
      // 직원목록 가져오기 API
      const employee_list_res = await CompanyModel.getEmployeeList(company_id.current);
      if (employee_list_res.code === 200) {
        // 기업 등록 후 직원 등록 가능 인원 설정 (사용인원(company_reducer) - 현재 직원 수(api))
        setCanResistEmployeeCount(
          company_reducer.company_info?.licensed_employee_count -
            employee_list_res.data.employee_set.length,
        );
      } else {
        showAlert({
          title: lang.request.request_error_title,
          text: employee_list_res.message,
        });
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <Breadcrumb
        type='b2b'
        parent='기업'
        subParent={company_reducer.company_info?.name || null}
        title='계정생성'
      />
      <Container fluid={true}>
        <Row>
          <Col xl='12'>
            <Card>
              <CardHeader
                className='f-20 f-w-700'
                style={{
                  padding: '20px 20px 20px 30px',
                }}
              >
                <Row>
                  <Col className='col-8'>
                    <span>계정 정보</span>
                    {isLoading && <Loading isLoading={isLoading} />}
                  </Col>
                  <Col className='col text-right'>
                    <Button
                      className='btn-pill btn-air-primary'
                      color='primary'
                      style={{
                        padding: '5px 20px 5px 20px',
                      }}
                      onClick={() => {
                        document.getElementById('add-account-form-submit-btn').click();
                      }}
                    >
                      추가
                    </Button>
                    <Button
                      className='btn-pill btn-air-danger'
                      color='danger'
                      style={{
                        padding: '5px 20px 5px 20px',
                        marginLeft: '10px',
                      }}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      돌아가기
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              {!isLoading && (
                <div className='ribbon-wrapper card'>
                  <div
                    className={
                      can_resist_employee_count >= 1
                        ? 'ribbon ribbon-clip ribbon-info'
                        : 'ribbon ribbon-clip ribbon-danger'
                    }
                  >
                    등록가능 인원 : {can_resist_employee_count} 명
                    {can_resist_employee_count <= 0 && ' [사용인원 수정 필요]'}
                  </div>
                  <AddAccountForm
                    setIsLoading={setIsLoading}
                    is_new_company={is_new_company}
                    can_resist_employee_count={can_resist_employee_count}
                    setNeedUpdate={setNeedUpdate}
                  />
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddAccount;
