import {
  CLEAR_COMPANY,
  ADD_COMPANY_INFO,
  ADD_COMPANY_ID,
  ADD_COMPANY_EMPLOYEE_SET,
} from '../../action_type';

export const addCompanyId = (company_id) => ({
  type: ADD_COMPANY_ID,
  company_id,
});

export const addCompanyInfo = (info) => ({
  type: ADD_COMPANY_INFO,
  info,
});

export const addCompanyEmployeeSet = (employee_set) => ({
  type: ADD_COMPANY_EMPLOYEE_SET,
  employee_set,
});

export const clearCompany = () => ({
  type: CLEAR_COMPANY,
});
