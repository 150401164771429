import React, { useEffect, useLayoutEffect, useState } from 'react';
import { AlignCenter, LogOut, Maximize2, MoreHorizontal } from 'react-feather';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { stored_auth_info } from '../../../redux/auth/action';
import CommonModel from '../../../model/CommonModel';
import { accountType } from '../../../util/common';
import { clearCompany } from '../../../redux/b2b_store/company/actions';

const Header = (props) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const AuthStore = useSelector((state) => state.AuthStore);
  const [navmenu, setNavmenu] = useState(false);
  const [sidebar, setSidebar] = useState('iconsidebar-menu');
  const width = useWindowSize();

  const logOut = async () => {
    CommonModel.signOut().then(({ code }) => {
      if (code === 200 || code === 401) {
        // auth, company redux 초기화
        dispatch(stored_auth_info(null));
        dispatch(clearCompany());
        window.location = '/';
      } else {
        alert('처리중 오류가 발생하였습니다.');
      }
    });
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }

      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    if (AuthStore.account_type_id === 2 || AuthStore.account_type_id === 3) {
      document.querySelector('.iconsidebar-menu').classList.add('iconbar-second-close');
    } else {
      if (width < 991) {
        setSidebar('iconbar-second-close');
        document.querySelector('.iconsidebar-menu').classList.add('iconbar-second-close');
      } else {
        setSidebar('iconsidebar-menu');
        document.querySelector('.iconsidebar-menu').classList.remove('iconbar-second-close');
      }
    }
  }, [width]);

  const openCloseSidebar = (sidebartoggle) => {
    // iconsidebar-menu
    if (sidebartoggle === 'iconsidebar-menu') {
      setSidebar('iconbar-mainmenu-close');
      document.querySelector('.iconsidebar-menu').classList.remove('iconbar-second-close');
    } else if (sidebartoggle === 'iconbar-mainmenu-close') {
      setSidebar('iconbar-second-close');
      document.querySelector('.iconsidebar-menu').classList.add('iconbar-mainmenu-close');
    } else {
      setSidebar('iconsidebar-menu');
      document.querySelector('.iconsidebar-menu').classList.remove('iconbar-mainmenu-close');
      document.querySelector('.iconsidebar-menu').classList.add('iconbar-second-close');
    }
  };

  //full screen function
  const goFull = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  const Navmenuhideandshow = () => {
    if (navmenu) {
      setNavmenu(!navmenu);
      document.querySelector('.nav-menus').classList.add('open');
    } else {
      setNavmenu(!navmenu);
      document.querySelector('.nav-menus').classList.remove('open');
    }
  };

  // const openCloseSearch = () => {
  //     if (searchinput) {
  //         setSearchinput(!searchinput);
  //         document.querySelector(".Typeahead-input").classList.add("open");
  //     } else {
  //         setSearchinput(!searchinput);
  //         document.querySelector(".Typeahead-input").classList.b2b_remove("open");
  //     }
  // };

  return (
    <div className='page-main-header'>
      <div className='main-header-right'>
        <div className='main-header-left text-center'>
          <div className='logo-wrapper'>
            <Link to='/'>
              <h4 style={{ fontWeight: 'bold' }}>BEAR-U</h4>
            </Link>
          </div>
        </div>
        <div className='mobile-sidebar'>
          <div className='media-body text-right switch-sm'>
            <label className='switch ml-3'>
              <AlignCenter className='font-primary' onClick={() => openCloseSidebar(sidebar)} />
            </label>
          </div>
        </div>
        <div className='nav-right col pull-right right-menu'>
          <ul className='nav-menus'>
            <li></li>
            <li>
              <a className='text-right' href='#!' onClick={goFull}>
                <Maximize2 />
              </a>
            </li>
            <li className='onhover-dropdown'>
              <span className='media user-header text-center'>
                <p
                  style={{
                    margin: 0,
                    lineHeight: '48px',
                    borderRadius: '50px',
                    fontSize: '28px',
                    width: '48px',
                    height: '48px',
                    fontWeight: 'bold',
                    color: '#ffffff',
                    background: '#444444',
                  }}
                >
                  {AuthStore.account_nickname ? AuthStore.account_nickname[0] : '관'}
                </p>
              </span>
              <ul className='onhover-show-div profile-dropdown'>
                <li className='gradient-primary'>
                  <p className='mb-2'>{accountType(AuthStore.account_type_id)}</p>
                  <h5 className='f-w-600 mb-0'>
                    {AuthStore.account_nickname ? AuthStore.account_nickname : '관리자'}
                  </h5>
                  <span>{AuthStore.account_name}</span>
                </li>
                <li onClick={logOut}>
                  <LogOut />
                  Logout
                </li>
              </ul>
            </li>
          </ul>
          <div className='d-lg-none mobile-toggle pull-right' onClick={Navmenuhideandshow}>
            <MoreHorizontal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
