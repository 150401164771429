import axios from 'axios';

class EbookModel {
  async getList(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/ebook', {
      params: filter,
    });
    return data;
  }

  async getEbook(ebook_id) {
    const {
      data: {
        data: { ebook },
      },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/ebook/' + ebook_id);
    return { ebook };
  }

  async postEbook({
    title,
    description,
    total_pages,
    author_name,
    author_account_id,
    ebook_sale_status_id,
    hide_in_list,
    category_id,
    main_image_url,
    pdf_url,
    watermark_url,
    opacity,
  }) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/ebook/', {
      title,
      description,
      total_pages,
      ebook_sale_status_id,
      hide_in_list,
      author_name,
      author_account_id,
      category_id,
      main_image_url,
      pdf_url,
      watermark_url,
      opacity,
    });
    return data;
  }

  async putEbook(ebook) {
    const { data } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/ebook/' + ebook.id,
      ebook,
    );
    return data;
  }

  async getEbookSale(ebook_id) {
    const {
      data: {
        data: { sale_set },
      },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/ebook/' + ebook_id + '/sale');

    return { sale_set };
  }

  async postEbookSale(ebook_id, obj_set) {
    const {
      data: {
        data: { sale_set },
      },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/ebook/' + ebook_id + '/sale',
      {
        sale_set: obj_set,
      },
    );
    return sale_set;
  }

  async putEbookSaleStatus(ebook_id, sale_id, is_deleted) {
    const {
      data: { data },
    } = await axios.put(process.env.REACT_APP_API_URL + '/cms-api/v1/ebook/' + ebook_id + '/sale', {
      sale_id: sale_id,
      is_deleted: is_deleted,
    });
    return data;
  }

  async getCourseNotice(course_id) {
    const {
      data: {
        data: { notice },
      },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/course/' + course_id + '/notice',
    );
    return { notice };
  }

  async postCourseNotice(course_id, obj) {
    const {
      data: {
        data: { notice },
      },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/course/' + course_id + '/notice',
      {
        title: obj.title,
        content: obj.content,
        is_enabled: obj.is_enabled,
      },
    );
    return notice;
  }

  async getCourseSubject(course_id) {
    const {
      data: {
        data: { subject_set },
      },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/course/' + course_id + '/subject',
    );
    return { subject_set };
  }

  async postCourseSubject(course_id, obj_set) {
    const {
      data: {
        data: { subject_set },
      },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/course/' + course_id + '/subject',
      {
        subject_set: obj_set,
      },
    );
    return subject_set;
  }

  async getPromotionCourse(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/course/promotion', {
      params: filter,
    });
    return data;
  }

  async postPromotionCourse(promotion_course) {
    const {
      data: { data },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/course/promotion',
      promotion_course,
    );
    return data;
  }

  async getPromotionCourseDetail(promotion_course_id) {
    if (promotion_course_id) {
      const {
        data: { data },
      } = await axios.get(
        process.env.REACT_APP_API_URL + '/cms-api/v1/course/promotion/' + promotion_course_id,
      );
      return data;
    } else {
      return { promotion_course: false };
    }
  }

  async putPromotionCourseDetail(promotion_course) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/course/promotion/' + promotion_course.id,
      promotion_course,
    );
    return data;
  }

  async getCuration(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/course/curation', {
      params: filter,
    });
    return data;
  }

  async postCuration(curation) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/course/curation', curation);
    return data;
  }

  async getCurationDetail(curation_id) {
    if (curation_id) {
      const {
        data: { data },
      } = await axios.get(
        process.env.REACT_APP_API_URL + '/cms-api/v1/course/curation/' + curation_id,
      );
      return data;
    } else {
      return { curation: false };
    }
  }

  async putCurationDetail(curation) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/course/curation/' + curation.id,
      curation,
    );
    return data;
  }

  async getTicket(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/course/course-ticket', {
      params: filter,
    });
    return data;
  }

  async postTicket(ticket) {
    const {
      data: { data },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/course/course-ticket',
      ticket,
    );
    return data;
  }

  async getTicketDetail(ticket_id) {
    if (ticket_id) {
      const {
        data: { data },
      } = await axios.get(
        process.env.REACT_APP_API_URL + '/cms-api/v1/course/course-ticket/' + ticket_id,
      );
      return data;
    } else {
      return { coupon: false };
    }
  }

  async putTicketDetail(ticket) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/course/course-ticket/' + ticket.id,
      ticket,
    );
    return data;
  }

  async checkTicketText(ticket_text) {
    const {
      data: { data },
    } = await axios.get(
      process.env.REACT_APP_API_URL +
        '/cms-api/v1/course/course-ticket/unique-check/' +
        ticket_text,
    );
    return data;
  }

  async expireTicket(ticket_id) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL +
        '/cms-api/v1/course/course-ticket/' +
        ticket_id +
        '/expire-time',
    );
    return data;
  }

  async sendTicket(ticket) {
    const {
      data: { data },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/course/course-ticket/course-ticket-send',
      ticket,
    );
    return data;
  }

  async multipleTicket(ticket) {
    const {
      data: { data },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/course/course-ticket/course-ticket-multiple',
      ticket,
    );
    return data;
  }

  async getQnaList(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/course/qna', {
      params: filter,
    });
    return data;
  }

  async postQnaReply({ parent_qna_id, course_id, contents }) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/course/qna', {
      parent_qna_id,
      course_id,
      contents,
    });
    return data;
  }

  async putQnaStatus(qna_id_set, status_id) {
    const {
      data: { data },
    } = await axios.put(process.env.REACT_APP_API_URL + '/cms-api/v1/course/qna', {
      status_id: status_id,
      qna_id_set: qna_id_set,
    });
    return data;
  }

  async getQnaDetail(qna_id) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/course/qna/' + qna_id);
    return data;
  }

  async getReviewList(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/course/review', {
      params: filter,
    });
    return data;
  }

  async getReviewDetail(review_id) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/course/review/' + review_id);
    return data;
  }

  async putReviewStatus(review_id_set, status_id) {
    const {
      data: { data },
    } = await axios.put(process.env.REACT_APP_API_URL + '/cms-api/v1/course/review', {
      review_id_set: review_id_set,
      status_id: status_id,
    });
    return data;
  }

  async postReviewReply({ parent_review_id, course_id, contents }) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/course/review', {
      parent_review_id,
      course_id,
      contents,
    });
    return data;
  }

  async getFavoriteUserDetail(ebook_id, filter_set) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/ebook/${ebook_id}/favorite`,
      {
        params: filter_set,
      },
    );
    return data;
  }

  async getCourseFavoriteCSV(filter_set) {
    return await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/course/favorite/csv', {
      params: filter_set,
      responseType: 'blob',
    });
  }

  async getEbookDetailFavoriteCSV(ebook_id, filter_set) {
    return await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/ebook/${ebook_id}/favorite/csv`,
      {
        params: filter_set,
        responseType: 'blob',
      },
    );
  }

  async patchCourseTransferAccount(user_course_id, account_id) {
    const { data } = await axios.patch(
      process.env.REACT_APP_API_URL +
        `/cms-api/v1/course/course-transfer/${user_course_id}/${account_id}`,
      {
        user_course_id: user_course_id,
        account_id: account_id,
      },
    );
    return data;
  }

  async patchCourseEndDateChange(user_course_id, date_time) {
    const { data } = await axios.patch(
      process.env.REACT_APP_API_URL +
        `/cms-api/v1/course/course-transfer/${user_course_id}/${date_time}`,
      {
        user_course_id: user_course_id,
        date_time: date_time,
      },
    );
    return data;
  }

  async patchCourseChange(user_course_id, course_change_id) {
    const { data } = await axios.patch(
      process.env.REACT_APP_API_URL +
        `/cms-api/v1/course/course-change/${user_course_id}/${course_change_id}`,
      {
        user_course_id: user_course_id,
        course_change_id: course_change_id,
      },
    );
    return data;
  }
}

export default new EbookModel();
