import SaleDashboard from '../views/sale/SaleDashboard';
import Course from '../views/course/Course';
import CourseDetail from '../views/course/CourseDetail';
import CourseDashboard from '../views/course/CourseDashboard';
import Account from '../views/account/Account';
import Permission from '../util/Permission';
import AccountDetail from '../views/account/AccountDetail';
import PurchaseDetail from '../views/purchase/PurchaseDetail';
import UserCourseSubjectViewLog from '../views/account/UserCourseSubjectViewLog';
import Banner from '../views/service/Banner';
import BannerDetail from '../views/service/BannerDetail';
import PromotionCourse from '../views/service/PromotionCourse';
import PromotionCourseDetail from '../views/service/PromotionCourseDetail';
import Curation from '../views/service/Curation';
import CurationDetail from '../views/service/CurationDetail';
import Notice from '../views/service/Notice';
import NoticeDetail from '../views/service/NoticeDetail';
import Faq from '../views/service/Faq';
import FaqDetail from '../views/service/FaqDetail';
import ServiceInfo from '../views/service/ServiceInfo';
import Certificate from '../views/service/Certificate';
import Product from '../views/product/Product';
import ProductDetail from '../views/product/ProductDetail';
import Kit from '../views/product/Kit';
import KitDetail from '../views/product/KitDetail';
import Shipping from '../views/product/Shipping';
import ShippingDetail from '../views/product/ShippingDetail';
import Coupon from '../views/promotion/Coupon';
import CouponLog from '../views/promotion/CouponLog';
import CouponDetail from '../views/promotion/CouponDetail';
import CouponSend from '../views/promotion/CouponSend';
import CouponMultiple from '../views/promotion/CouponMultiple';
import Ticket from '../views/promotion/Ticket';
import TicketLog from '../views/promotion/TicketLog';
import TicketDetail from '../views/promotion/TicketDetail';
import TicketMultiple from '../views/promotion/TicketMultiple';
import TicketSend from '../views/promotion/TicketSend';
import Event from '../views/promotion/Event';
import EventDetail from '../views/promotion/EventDetail';
import CouponEvent from '../views/promotion/CouponEvent';
import CouponEventDetail from '../views/promotion/CouponEventDetail';
import CourseQna from '../views/course/CourseQna';
import CourseReview from '../views/course/CourseReview';
import QnaDetail from '../views/course/component/QnaDetail';
import ReviewDetail from '../views/course/component/ReviewDetail';
import Sale from '../views/sale/Sale';
import SaleInfo from '../views/sale/SaleInfo';
import MasterList from '../views/invoice/MasterList';
import MasterDetail from '../views/invoice/MasterDetail';
import InvoiceDetail from '../views/invoice/InvoiceDetail';
import AccountDashboard from '../views/account/AccountDashboard';
import AccountManagementDescription from '../views/account/AccountMangementDescription';
import TotalInvoiceList from '../views/invoice/TotalInvoiceList';
import CompanyList from '../views/b2b_company/CompanyList';
import CompanyDashboard from '../views/b2b_company/CompanyDashboard';
import AddCompany from '../views/b2b_company/AddCompany';
import AddAccount from '../views/b2b_employee/AddAccount';
import EmployeeDetail from '../views/b2b_employee/EmployeeDetail';
import EmployeeCourseDetail from '../views/b2b_employee/EmployeeCourseDetail';
import EmployeePasswordChange from '../views/b2b_employee/EmployeePasswordChange';
import HardStudy from '../views/service/HardStudy';
import HardStudyDetail from '../views/service/HardStudyDetail';
import Trigger from '../views/service/Trigger';
import TriggerDetail from '../views/service/TriggerDetail';

import Ebook from '../views/ebook/Ebook';
import EbookDetail from '../views/ebook/EbookDetail';

import Bgd from '../views/service/Bgd';
import BgdDetail from '../views/service/BgdDetail';
import RecruitForm from '../views/service/RecruitForm';
import RecruitFormDetail from '../views/service/RecruitFormDetail';

export const routes = [
  { path: '/home', Component: Permission(SaleDashboard, 'admin') },

  { path: '/course', Component: Permission(Course, 'admin') },
  { path: '/course/:course_id(\\d+)', Component: Permission(CourseDetail, 'admin') },
  { path: '/course/qna', Component: Permission(CourseQna, 'admin') },
  { path: '/course/qna/:qna_id', Component: Permission(QnaDetail, 'admin') },
  { path: '/course/review', Component: Permission(CourseReview, 'admin') },
  { path: '/course/review/:review_id', Component: Permission(ReviewDetail, 'admin') },
  { path: '/course-dashboard', Component: Permission(CourseDashboard, 'admin') },

  { path: '/ebook', Component: Permission(Ebook, 'admin') },
  { path: '/ebook/:ebook_id(\\d+)', Component: Permission(EbookDetail, 'admin') },

  { path: '/account', Component: Permission(Account, 'admin') },
  { path: '/account-dashboard', Component: Permission(AccountDashboard, 'admin') },
  {
    path: '/account-management-description',
    Component: Permission(AccountManagementDescription, 'admin'),
  },
  { path: '/account/:account_id', Component: Permission(AccountDetail, 'admin') },
  {
    path: '/account/:account_id/subject-viewlog/:course_id',
    Component: Permission(UserCourseSubjectViewLog, 'admin'),
  },

  { path: '/banner', Component: Permission(Banner, 'admin') },
  { path: '/banner/:banner_id', Component: Permission(BannerDetail, 'admin') },

  { path: '/trigger', Component: Permission(Trigger, 'admin') },
  { path: '/trigger/:trigger_id', Component: Permission(TriggerDetail, 'admin') },

  { path: '/promotion', Component: Permission(PromotionCourse, 'admin') },
  {
    path: '/promotion/:promotion_course_id',
    Component: Permission(PromotionCourseDetail, 'admin'),
  },

  { path: '/curation', Component: Permission(Curation, 'admin') },
  { path: '/curation/:curation_id', Component: Permission(CurationDetail, 'admin') },

  { path: '/notice', Component: Permission(Notice, 'admin') },
  { path: '/notice/:notice_id', Component: Permission(NoticeDetail, 'admin') },

  { path: '/hardstudy', Component: Permission(HardStudy, 'admin') },
  { path: '/hardstudy/:hardstudy_id', Component: Permission(HardStudyDetail, 'admin') },

  { path: '/bgd', Component: Permission(Bgd, 'admin') },
  { path: '/bgd/:bgd_id', Component: Permission(BgdDetail, 'admin') },

  { path: '/recruit-form', Component: Permission(RecruitForm, 'admin') },
  {
    path: '/recruit-form/:recruit_form_id(\\d+)',
    Component: Permission(RecruitFormDetail, 'admin'),
  },

  { path: '/faq', Component: Permission(Faq, 'admin') },
  { path: '/faq/:faq_id', Component: Permission(FaqDetail, 'admin') },

  { path: '/service-info', Component: Permission(ServiceInfo, 'admin') },

  { path: '/certificate', Component: Permission(Certificate, 'admin') },

  { path: '/product', Component: Permission(Product, 'admin') },
  { path: '/product/:product_id', Component: Permission(ProductDetail, 'admin') },

  { path: '/kit', Component: Permission(Kit, 'admin') },
  { path: '/kit/:kit_id', Component: Permission(KitDetail, 'admin') },

  { path: '/shipping', Component: Permission(Shipping, 'admin') },
  { path: '/shipping/:shipping_id', Component: Permission(ShippingDetail, 'admin') },

  { path: '/coupon', Component: Permission(Coupon, 'admin') },
  { path: '/coupon/:coupon_id(\\d+)', Component: Permission(CouponDetail, 'admin') },
  { path: '/coupon/coupon-send', Component: Permission(CouponSend, 'admin') },
  { path: '/coupon/coupon-multiple', Component: Permission(CouponMultiple, 'admin') },
  { path: '/coupon-log', Component: Permission(CouponLog, 'admin') },

  { path: '/ticket', Component: Permission(Ticket, 'admin') },
  { path: '/ticket/:ticket_id(\\d+)', Component: Permission(TicketDetail, 'admin') },
  { path: '/ticket/ticket-send', Component: Permission(TicketSend, 'admin') },
  { path: '/ticket/ticket-multiple', Component: Permission(TicketMultiple, 'admin') },
  { path: '/ticket-log', Component: Permission(TicketLog, 'admin') },

  { path: '/event', Component: Permission(Event, 'admin') },
  { path: '/event/:event_id(\\d+)', Component: Permission(EventDetail, 'admin') },

  { path: '/coupon-event', Component: Permission(CouponEvent, 'admin') },
  {
    path: '/coupon-event/:coupon_event_id(\\d+)',
    Component: Permission(CouponEventDetail, 'admin'),
  },

  { path: '/sale', Component: Permission(Sale, 'admin') },
  { path: '/sale-dashboard', Component: Permission(SaleDashboard, 'admin') },
  { path: '/sale-info', Component: Permission(SaleInfo, 'admin') },
  { path: '/sale/:purchaseinfo_id', Component: Permission(PurchaseDetail, 'admin') },

  { path: '/invoice', Component: Permission(MasterList, 'admin') },
  { path: '/invoice-total', Component: Permission(TotalInvoiceList, 'admin') },
  { path: '/invoice/:account_id(\\d+)', Component: Permission(MasterDetail, 'master') },
  {
    path: '/invoice/:account_id(\\d+)/:invoice_date',
    Component: Permission(InvoiceDetail, 'master'),
  },

  /* b2b */
  // b2b - company
  { path: '/company-list', Component: Permission(CompanyList, 'company-manager') },
  { path: '/company/:company_id', Component: Permission(CompanyDashboard, 'company-manager') },
  { path: '/add-company', Component: Permission(AddCompany, 'company-manager') },
  {
    path: '/company/:company_id/add-account',
    Component: Permission(AddAccount, 'company-manager'),
  },

  // b2b - employee
  {
    path: '/company/:company_id/employee/:employee_id',
    Component: Permission(EmployeeDetail, 'company-manager'),
  },
  {
    path: '/company/:company_id/employee/:employee_id/course/:course_id',
    Component: Permission(EmployeeCourseDetail, 'company-manager'),
  },
  {
    path: '/company/:company_id/employee/:employee_id/pw',
    Component: Permission(EmployeePasswordChange, 'company-manager'),
  },
];
