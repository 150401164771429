import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import {
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Button,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  Collapse,
  CardBody,
} from 'reactstrap';

import EbookModel from '../../model/EbookModel';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import { badgeStyle, courseSaleStatus, initComma, toQueryString } from '../../util/common';
import CustomPagination from '../_common/CustomPagination';
import Loading from '../_common/Loading';
import CustomDateRangeFilter from '../_common/component/CustomDateRangeFilter';

const PAZE_SIZE = 20;

const Ebook = (props) => {
  const params = props.location.state || queryString.parse(props.location.search) || {};
  const [isLoading, setIsLoading] = useState(false);
  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: 20,
    search_text: params.search_text ? params.search_text : '',
    order_key: params.order_key ? params.order_key : 'write_time',
    order_type: params.order_type ? params.order_type : 'desc',
  });
  const [ebook_set, setEbookSet] = useState([]);
  const [ebook_count, setEbookCount] = useState(0);
  const [filter_set, setFilterSet] = useState(default_filter.current);
  const [is_filer_spread, setIsFilterSpread] = useState(!!params.is_filer_spread);

  const initData = () => {
    setIsLoading(true);

    EbookModel.getList({ size: PAZE_SIZE, ...params }).then(({ ebook_set, ebook_count }) => {
      setEbookSet(ebook_set);
      setEbookCount(ebook_count);
    });

    setIsLoading(false);
  };

  useEffect(() => {
    setFilterSet(
      Object.keys(params).length > 0
        ? { ...default_filter.current, ...params }
        : default_filter.current,
    );

    initData();
  }, [props.location.search]);

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      order_key: order_key,
      order_type: filter_set.order_type === 'asc' ? 'desc' : 'asc',
    };

    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }
    pushHistory(newFilterSet);
  };

  const onSubmit = () => {
    pushHistory({ ...filter_set, page: 1, order_key: '', order_type: '' });
  };

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onFilterSubmit = () => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
      is_filer_spread: is_filer_spread,
    });
  };

  const onPageChange = (page) => {
    pushHistory({ ...params, page: page });

    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <Breadcrumb parent='전자책' title='전자책 목록' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <div className='default-according style-1' id='accordionoc'>
                <CardHeader style={{ padding: '12px 30px' }}>
                  <Button
                    color='black pl-0'
                    data-toggle='collapse'
                    onClick={() => setIsFilterSpread(!is_filer_spread)}
                    data-target='#collapseicon'
                    aria-expanded={is_filer_spread}
                    aria-controls='collapseicon'
                  >
                    <h5 className='mb-0'>북마크 필터</h5>
                  </Button>
                </CardHeader>
              </div>
              <Collapse isOpen={is_filer_spread}>
                <CardBody>
                  <div>
                    <div className='form-row form-group'>
                      <Col md={8}>
                        <CustomDateRangeFilter
                          type={'bookmark'}
                          setFilter={setFilterSet}
                          filter_set={filter_set}
                        />
                      </Col>
                      <Col className='text-right mt-auto'>
                        <Button
                          className='btn btn-primary btn-pill'
                          color='success'
                          onClick={(e) => {
                            onFilterSubmit();
                          }}
                        >
                          검색
                        </Button>
                      </Col>
                    </div>
                  </div>
                </CardBody>
              </Collapse>
            </Card>
          </Col>
        </Row>
        <Col
          className='text-right mb-4'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Button
            className='btn-pill'
            color='primary'
            style={{ marginLeft: '10px' }}
            onClick={() => {
              props.history.push('ebook/0');
            }}
          >
            전자책 생성
          </Button>
        </Col>
        <Card>
          <CardHeader>
            <Row style={{ marginBottom: '-20px' }}>
              <Col sm={7}>
                <p
                  style={{
                    marginTop: '7px',
                  }}
                >
                  총: <span style={{ color: 'coral' }}>{initComma(ebook_count)}</span>개
                  <Loading isLoading={isLoading} />
                </p>
              </Col>
              <Col className='text-right'>
                <FormGroup>
                  <InputGroup>
                    <Input
                      className='form-control'
                      type='text'
                      name='search_text'
                      onChange={(e) => {
                        setFilterSet((prevState) => ({
                          ...prevState,
                          search_text: e.target.value,
                        }));
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSubmit(e);
                        }
                      }}
                      value={filter_set.search_text}
                      placeholder='강의명을 입력하세요.'
                    />
                    <InputGroupAddon
                      addonType='append'
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={onSubmit}
                    >
                      <InputGroupText>
                        <i className='icofont icofont-ui-search' />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Table>
              <thead>
                <tr
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <th
                    style={{
                      width: '60px',
                      maxWidth: '60px',
                      minWidth: '60px',
                    }}
                  >
                    ID
                  </th>
                  <th
                    style={{
                      width: '100px',
                      maxWidth: '100px',
                      minWidth: '100px',
                    }}
                  >
                    대표이미지
                  </th>
                  <th
                    style={{
                      textAlign: 'left',
                      width: '350px',
                      maxWidth: '350px',
                      minWidth: '350px',
                    }}
                  >
                    전자책 제목
                  </th>
                  <th
                    style={{
                      textAlign: 'left',
                      width: '350px',
                      maxWidth: '350px',
                      minWidth: '350px',
                    }}
                  >
                    설명
                  </th>
                  <th
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    작가
                  </th>
                  <th
                    style={{
                      width: '50px',
                      maxWidth: '50px',
                      minWidth: '50px',
                    }}
                    className='table-sort-th'
                    onClick={() => {
                      onOrderChange('is_favorite_count');
                    }}
                  >
                    {filter_set.order_key === 'is_favorite_count' ? (
                      <span
                        style={{
                          color: '#ff4c3b',
                        }}
                      >
                        북마크
                      </span>
                    ) : (
                      <span>북마크</span>
                    )}
                  </th>
                  <th
                    style={{
                      width: '100px',
                      maxWidth: '100px',
                      minWidth: '100px',
                    }}
                  >
                    판매상태
                  </th>
                  <th
                    style={{
                      width: '100px',
                      maxWidth: '100px',
                      minWidth: '100px',
                    }}
                    className='table-sort-th'
                    onClick={() => {
                      onOrderChange('write_time');
                    }}
                  >
                    {filter_set.order_key === 'write_time' ? (
                      <span
                        style={{
                          color: '#ff4c3b',
                        }}
                      >
                        생성일
                      </span>
                    ) : (
                      <span>생성일</span>
                    )}
                  </th>
                  <th
                    style={{
                      width: '100px',
                      maxWidth: '100px',
                      minWidth: '100px',
                    }}
                    className='table-sort-th'
                    onClick={() => {
                      onOrderChange('update_time');
                    }}
                  >
                    {filter_set.order_key === 'update_time' ? (
                      <span
                        style={{
                          color: '#ff4c3b',
                        }}
                      >
                        수정일
                      </span>
                    ) : (
                      <span>수정일</span>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {ebook_set
                  ? ebook_set.map((ebook, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            cursor: 'pointer',
                            textAlign: 'center',
                            lineHeight: '4',
                          }}
                          onClick={() => {
                            props.history.push('ebook/' + ebook.id);
                          }}
                        >
                          <td
                            className='mobile-none'
                            style={{
                              maxWidth: '60px',
                              minWidth: '60px',
                            }}
                          >
                            {ebook.id}
                          </td>
                          <td className='mobile-none'>
                            <div
                              style={{
                                position: 'relative',
                                width: '100px',
                                maxWidth: '100px',
                                minWidth: '100px',
                                height: '60px',
                                margin: 'auto',
                              }}
                            >
                              {ebook.main_image_url ? (
                                <img
                                  src={ebook.main_image_url}
                                  alt={ebook.description}
                                  style={{
                                    position: 'relative',
                                    width: '100%',
                                    maxHeight: '60px',
                                  }}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td
                            className='mobile-none text-truncate'
                            style={{
                              fontWeight: '600',
                              maxWidth: '350px',
                              minWidth: '350px',
                              textAlign: 'left',
                            }}
                          >
                            {ebook.title}
                          </td>
                          <td
                            className='mobile-none text-truncate'
                            style={{
                              maxWidth: '350px',
                              minWidth: '350px',
                              textAlign: 'left',
                            }}
                          >
                            {ebook.description}
                          </td>
                          <td
                            className='mobile-none text-truncate'
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {ebook.author_name}
                          </td>

                          <td
                            className='mobile-none'
                            style={{
                              maxWidth: '100px',
                              minWidth: '100px',
                            }}
                          >
                            {ebook.favorite_count}
                          </td>
                          <td
                            style={{
                              maxWidth: '100px',
                              minWidth: '100px',
                            }}
                            className='mobile-none'
                          >
                            <span className={badgeStyle(ebook.course_sale_status_id)}>
                              {courseSaleStatus(ebook.ebook_sale_status_id)}
                            </span>
                          </td>
                          <td
                            style={{
                              maxWidth: '100px',
                              minWidth: '100px',
                            }}
                            className='mobile-none'
                          >
                            {moment(ebook.write_time).format('YYYY.MM.DD')}
                          </td>
                          <td
                            style={{
                              maxWidth: '100px',
                              minWidth: '100px',
                            }}
                            className='mobile-none'
                          >
                            {moment(ebook.update_time).format('YYYY.MM.DD')}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </div>
          <div className='m-auto'>
            <CustomPagination
              current_page={params.page ? Number(params.page) : 1}
              max_page={Math.ceil(
                params.size ? ebook_count / params.size : ebook_count / PAZE_SIZE,
              )}
              onPageChange={onPageChange}
            />
          </div>
        </Card>
      </Container>
    </Fragment>
  );
};

Ebook.propTypes = {
  ebook_set: PropTypes.array,
};

export default Ebook;
