import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import {
  CATEGORY_COURSE,
  CATEGORY_PACKAGE,
  COURSE_DATE_OBJECT_INIT_SET,
  TOGGLE_DATE_NUM_ONE_MONTH,
  TOGGLE_DATE_NUM_ONE_YEAR,
  TOGGLE_DATE_NUM_SIX_MONTH,
  TOGGLE_DATE_NUM_THREE_MONTH,
} from '../../../constant/options';
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { dialog_type_confirm, useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import CompanyModel from '../../../model/CompanyModel';
import { lang } from '../../../lang';
import CommonModel from '../../../model/CommonModel';
import { useDispatch, useSelector } from 'react-redux';
import { addTemporaryValue } from '../../../redux/b2b_store/temporary/actions';
import ProductModel from '../../../model/ProductModel';

/*
    props
    course_of_product_info: state({})         클래스 || 패키지 정보
    is_toggle_edit_modal: state(bool)         edit 모달의 열고 닫기 여부
    toggleModal: func => void                 edit 모달의 열고 닫기 func -> CompanyCourseList.js의 openEditModal 참고
    toggleProductModal: func => void          progress 모달의 열고 닫기 func -> 날짜 변경 완료 후 모든 모달 닫기 위함
    category: state(int)                      클래스(1) || 패키지(2)
    setNeedUpdate: setState(bool)             CompanyCourseList.js의 need_update 변경용 setState -> 날짜 변경 완료 후 업데이트 위함
    course_set: state([])                     기업의 클래스 목록
    product_set: state([])                    기업의 패키지 목록
    is_new_company: bool                      기업등록 전의 상태 -> true
 */
const CourseOrProductEditModal = (props) => {
  const AuthStore = useSelector((state) => state.AuthStore);
  const { params } = useParameters();
  const { showAlert } = useShowDialog();
  const dispatch = useDispatch();
  const temporary_reducer = useSelector((state) => state.temporary_reducer);

  // state
  const [category, setCategory] = useState(props.category);
  const [is_editable, setEditable] = useState(false);
  const [can_resist_course_set, setCanResistCourseSet] = useState([]);
  const [can_resist_product_set, setCanResistProductSet] = useState([]);
  const [date_object, setDateObject] = useState(COURSE_DATE_OBJECT_INIT_SET);
  const [button_active_num, setButtonActiveNum] = useState();

  // ref
  const company_id = useRef(params.company_id);
  const typeahead_ref = useRef();
  const resist_set = useRef([]);

  useEffect(() => {
    if (props.course_set || props.product_set) {
      if (AuthStore.account_type_id === 5) {
        updateUi();
      }
    }
  }, [props.course_set.length, props.product_set.length]);

  const updateUi = async () => {
    // 모든 course_set 가져오기 API
    const get_search_course_res = await CommonModel.getSearch('cms_curation_accessible_course');
    if (get_search_course_res) {
      let resist_course_set = [];

      // 기업등록 전의 상태
      if (props.is_new_company) {
        // 이전에 temp_reducer에 등록한 course가 있는 경우 -> 등록된 course 제외
        if (temporary_reducer.temp_course_set) {
          resist_course_set = get_search_course_res.filter((course) => {
            let flag = true;
            temporary_reducer.temp_course_set.forEach((exist_course) => {
              if (exist_course.id === course.id) {
                flag = false;
              }
            });
            return flag;
          });
        } else {
          // 이전에 등록한 course가 없는 경우 -> 모든 course_set
          resist_course_set = get_search_course_res;
        }
      } else {
        // 현재 기업이 구매한 강의를 제외한 모든 강의 목록
        resist_course_set = get_search_course_res.filter((course) => {
          let flag = true;
          props.course_set.forEach((exist_course) => {
            if (exist_course.id === course.id) {
              flag = false;
            }
          });
          return flag;
        });
      }

      setCanResistCourseSet(resist_course_set);
    } else {
      showAlert({
        title: lang.request.request_error_title,
        text: lang.request.request_error_desc,
      });
    }

    // 모든 product_set 가져오기 API
    const get_search_product_res = await ProductModel.getProduct({
      paging_type: 'all',
      order_type: 'asc',
      order_key: 'id',
    });

    const all_product_set = get_search_product_res.product_set;

    if (all_product_set) {
      let resist_product_set = [];

      // 기업등록 전의 상태
      if (props.is_new_company) {
        // 이전에 temp_reducer에 등록한 product가 있는 경우 -> 등록된 product 제외
        if (temporary_reducer.temp_product_set) {
          resist_product_set = all_product_set.filter((product) => {
            let flag = true;
            temporary_reducer.temp_product_set.forEach((exist_course) => {
              if (exist_course.product_id === product.id) {
                flag = false;
              }
            });
            return flag;
          });
        } else {
          // 이전에 등록한 product가 없는 경우 -> 모든 product_set
          resist_product_set = all_product_set;
        }
      } else {
        // 현재 기업 구매한 패키지를 제외한 모든 패키지 목록
        resist_product_set = all_product_set.filter((product) => {
          let flag = true;
          props.product_set.forEach((exist_product) => {
            if (exist_product.product_id === product.id) {
              flag = false;
            }
          });
          return flag;
        });
      }

      setCanResistProductSet(resist_product_set);
    } else {
      showAlert({
        title: lang.request.request_error_title,
        text: lang.request.request_error_desc,
      });
    }
  };

  useEffect(() => {
    if (props.course_or_product_info) {
      setDateObject({
        rental_time: moment(props.course_or_product_info.rental_time).format('YYYY-MM-DD HH:mm:ss'),
        rental_expire_time: moment(props.course_or_product_info.rental_expire_time).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      });

      defaultActiveDateButton(props.course_or_product_info);
    } else {
      setDateObject({
        rental_time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        rental_expire_time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      });
    }

    return () => {
      // unMount시에 date_object, resist_set, is_editable, button_active_num 초기화
      setDateObject(COURSE_DATE_OBJECT_INIT_SET);

      resist_set.current = [];

      setEditable(false);
      setButtonActiveNum();
    };
  }, [props.course_or_product_info]);

  useEffect(() => {
    setCategory(props.category);
  }, [props.category]);

  useEffect(() => {
    // 카테고리 변경할 때마다 resist_set, typeahead reset하기
    if (typeahead_ref.current) {
      resist_set.current = [];
      typeahead_ref.current.clear();
    }
  }, [category]);

  const toggleEdit = () => {
    setEditable(!is_editable);
  };

  const onTypeaheadChange = (e) => {
    resist_set.current = e;
  };

  // 날짜변경 편집모드 취소시에 date_object 초기화
  const onCancelChangeDate = () => {
    if (props.course_or_product_info) {
      setDateObject({
        rental_time: moment(props.course_or_product_info.rental_time).format('YYYY-MM-DD HH:mm:ss'),
        rental_expire_time: moment(props.course_or_product_info.rental_expire_time).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      });
    }
  };

  // 날짜 직접 수정의 경우
  const onCustomChangeDate = (e, key_name) => {
    setDateObject({
      ...date_object,
      [key_name]: moment(e).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  // 기간 계산 후 button_active_num 결정
  const defaultActiveDateButton = (course) => {
    let diff = moment(course.rental_expire_time).diff(moment(course.rental_time), 'month');

    switch (diff) {
      case 1:
        return setButtonActiveNum(TOGGLE_DATE_NUM_ONE_MONTH);
      case 3:
        return setButtonActiveNum(TOGGLE_DATE_NUM_THREE_MONTH);
      case 6:
        return setButtonActiveNum(TOGGLE_DATE_NUM_SIX_MONTH);
      case 12:
        return setButtonActiveNum(TOGGLE_DATE_NUM_ONE_YEAR);
      default:
        return setButtonActiveNum();
    }
  };

  const onHandleButtonColor = (active_num) => {
    if (!props.course_or_product_info) {
      return button_active_num === active_num ? 'primary' : 'light';
    } else {
      if (!is_editable) {
        return 'dark';
      } else {
        return button_active_num === active_num ? 'primary' : 'light';
      }
    }
  };

  // 1, 3, 6, 12개월의 버튼으로 날짜 변경 func
  const onButtonChangeDate = (button_name) => {
    if (button_name === 'one_month') {
      setDateObject({
        ...date_object,
        rental_expire_time: moment(date_object.rental_time)
          .add(1, 'month')
          .format('YYYY-MM-DD HH:mm:ss'),
      });

      setButtonActiveNum(TOGGLE_DATE_NUM_ONE_MONTH);
    } else if (button_name === 'three_month') {
      setDateObject({
        ...date_object,
        rental_expire_time: moment(date_object.rental_time)
          .add(3, 'month')
          .format('YYYY-MM-DD HH:mm:ss'),
      });

      setButtonActiveNum(TOGGLE_DATE_NUM_THREE_MONTH);
    } else if (button_name === 'six_month') {
      setDateObject({
        ...date_object,
        rental_expire_time: moment(date_object.rental_time)
          .add(6, 'month')
          .format('YYYY-MM-DD HH:mm:ss'),
      });

      setButtonActiveNum(TOGGLE_DATE_NUM_SIX_MONTH);
    } else if (button_name === 'one_year') {
      setDateObject({
        ...date_object,
        rental_expire_time: moment(date_object.rental_time)
          .add(1, 'year')
          .format('YYYY-MM-DD HH:mm:ss'),
      });

      setButtonActiveNum(TOGGLE_DATE_NUM_ONE_YEAR);
    }
  };

  const onSubmitCourseOrProduct = async (e) => {
    e.preventDefault();
    // typeahead 선택 X
    if (resist_set.current.length === 0) {
      showAlert({
        title: lang.course.did_not_exist_course,
      });
      return false;
    }
    let id_set = [];
    let tmp_object;
    let dispatch_set = [];

    // 기업등록 전의 상태에선 dispatch 후 CourseTable에서 보여주기위한 데이터를 dispatch_set에 저장
    if (props.is_new_company) {
      if (category === CATEGORY_COURSE) {
        dispatch_set = temporary_reducer.temp_course_set || [];
        resist_set.current.map((course) => {
          tmp_object = {
            id: course.id,
            course_title: course.combine_name,
            rental_time: date_object.rental_time,
            rental_expire_time: date_object.rental_expire_time,
          };
          dispatch_set.push(tmp_object);
          return null;
        });
      } else {
        dispatch_set = temporary_reducer.temp_product_set || [];
        resist_set.current.map((product) => {
          tmp_object = {
            product_id: product.id,
            product_title: product.combine_name,
            rental_time: date_object.rental_time,
            rental_expire_time: date_object.rental_expire_time,
          };
          dispatch_set.push(tmp_object);
          return null;
        });
      }
    } else {
      resist_set.current.map((course_or_product) => {
        return id_set.push(course_or_product.id);
      });

      tmp_object =
        category === CATEGORY_COURSE
          ? {
              course_set: id_set,
              rental_time: date_object.rental_time,
              rental_expire_time: date_object.rental_expire_time,
            }
          : {
              product_set: id_set,
              rental_time: date_object.rental_time,
              rental_expire_time: date_object.rental_expire_time,
            };
    }

    showAlert({
      type: dialog_type_confirm,
      title: lang.course.confirm_resist_course,
      confirmButtonText: lang.common.confirm_button_resist,
      showCancelButton: true,
      true_fn: async () => {
        if (props.is_new_company) {
          // 기업등록 전의 강의, 패키지 등록
          if (category === CATEGORY_COURSE) {
            dispatch(addTemporaryValue('temp_course_set', dispatch_set));
          } else {
            dispatch(addTemporaryValue('temp_product_set', dispatch_set));
          }
        } else {
          // 기업등록 후의 강의, 패키지 등록
          let post_courses_or_products_res;
          if (category === CATEGORY_COURSE) {
            // 기업의 클래스 추가 API
            post_courses_or_products_res = await CompanyModel.postCourses(
              company_id.current,
              tmp_object,
            );
          } else {
            // 기업의 패키지 추가 API
            post_courses_or_products_res = await CompanyModel.postProducts(
              company_id.current,
              tmp_object,
            );
          }

          if (post_courses_or_products_res.code === 200) {
            showAlert({
              title: lang.common.success_resist_message_common,
              icon: 'success',
            });
          } else {
            showAlert({
              title: lang.request.request_error_title,
              text: post_courses_or_products_res.message,
            });
          }
        }
        props.toggleModal();
        props.setNeedUpdate(true);
      },
    });
  };

  const onSubmitChangeDate = (e) => {
    e.preventDefault();

    // 기간이 변경되지 않았으면 api 요청 X
    if (
      moment(props.course_or_product_info.rental_time).format('YYYY-MM-DD HH:mm:ss') ===
        date_object.rental_time &&
      moment(props.course_or_product_info.rental_expire_time).format('YYYY-MM-DD HH:mm:ss') ===
        date_object.rental_expire_time
    ) {
      showAlert({
        title: lang.course.did_not_change_date,
      });
      return false;
    }
    let tmp_object;

    if (props.is_new_company) {
      // 기업등록 이전의 상태에선 dispatch 후 CourseTable에서 보여주기위해 course_title을 추가
      tmp_object =
        category === CATEGORY_COURSE
          ? {
              id: props.course_or_product_info.id,
              course_title: props.course_or_product_info.course_title,
              rental_time: date_object.rental_time,
              rental_expire_time: date_object.rental_expire_time,
            }
          : {
              product_id: props.course_or_product_info.product_id,
              product_title: props.course_or_product_info.product_title,
              rental_time: date_object.rental_time,
              rental_expire_time: date_object.rental_expire_time,
            };
    } else {
      // 기업등록 이후의 상태에선 tmp_object를 API에 전달할 객체
      tmp_object =
        category === CATEGORY_COURSE
          ? {
              course_id: props.course_or_product_info.id,
              rental_time: date_object.rental_time,
              rental_expire_time: date_object.rental_expire_time,
            }
          : {
              product_id: props.course_or_product_info.product_id,
              rental_time: date_object.rental_time,
              rental_expire_time: date_object.rental_expire_time,
            };
    }
    showAlert({
      type: dialog_type_confirm,
      title: lang.course.confirm_change_date,
      showCancelButton: true,
      confirmButtonText: lang.common.confirm_button_change,
      true_fn: async () => {
        if (props.is_new_company) {
          // 기업등록 이전에 강의 날짜변경
          if (category === CATEGORY_COURSE) {
            let changed_temp_course_set = [...temporary_reducer.temp_course_set];
            let current_course_index = changed_temp_course_set.findIndex((exist_course) => {
              return exist_course.id === tmp_object.id;
            });

            current_course_index >= 0 &&
              changed_temp_course_set.splice(current_course_index, 1, tmp_object);

            dispatch(addTemporaryValue('temp_course_set', changed_temp_course_set));
          } else {
            let changed_temp_product_set = [...temporary_reducer.temp_product_set];
            let current_product_index = changed_temp_product_set.findIndex((exist_product) => {
              return exist_product.product_id === tmp_object.product_id;
            });

            current_product_index >= 0 &&
              changed_temp_product_set.splice(current_product_index, 1, tmp_object);

            dispatch(addTemporaryValue('temp_product_set', changed_temp_product_set));
          }
        } else {
          let update_date_res;
          if (category === CATEGORY_COURSE) {
            // 기업의 클래스 날짜변경 API
            update_date_res = await CompanyModel.updateCourseDate(company_id.current, tmp_object);
          } else {
            // 기업의 패키지 날짜변경 API
            update_date_res = await CompanyModel.updateProductDate(company_id.current, tmp_object);
          }

          if (update_date_res.code === 200) {
            showAlert({
              title: lang.common.success_change_message,
              icon: 'success',
            });
          } else {
            showAlert({
              title: lang.request.request_error_title,
              text: update_date_res.message,
              icon: 'error',
            });
          }

          if (category !== CATEGORY_COURSE) {
            props.toggleProductModal();
          }
        }
        props.toggleModal();
        props.setNeedUpdate(true);
      },
    });
  };

  const isPossibleDay = (date) => {
    const currentDate = new Date(date_object.rental_time);
    const selectedDate = new Date(date);
    return currentDate <= selectedDate;
  };

  return (
    <Modal isOpen={props.is_toggle_edit_modal} toggle={props.toggleModal} centered size='lg'>
      <ModalBody
        style={{
          padding: '2.5em',
        }}
      >
        <Form className='theme-form' onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <Row>
              <Col>
                {props.course_or_product_info ? (
                  <Input
                    type='select'
                    className='col-md-2 text-left custom-select'
                    disabled
                    style={{
                      fontWeight: 'bold',
                      height: '3em',
                      backgroundColor: '#e9ecef',
                    }}
                    defaultValue={category}
                    onChange={(e) => setCategory(Number(e.currentTarget.value))}
                  >
                    <option value={CATEGORY_COURSE}>클래스</option>
                    <option value={CATEGORY_PACKAGE}>패키지</option>
                  </Input>
                ) : (
                  <Input
                    type='select'
                    className='col-md-2 text-left custom-select'
                    style={{
                      fontWeight: 'bold',
                      height: '3em',
                    }}
                    defaultValue={category}
                    onChange={(e) => setCategory(Number(e.currentTarget.value))}
                  >
                    <option value={CATEGORY_COURSE}>클래스</option>
                    <option value={CATEGORY_PACKAGE}>패키지</option>
                  </Input>
                )}
              </Col>
              {AuthStore.account_type_id === 5 && (
                <Col md='4 text-right'>
                  {!props.course_or_product_info ? (
                    <>
                      <Button
                        className='btn-pill btn-air-primary'
                        color='primary'
                        onClick={(e) => onSubmitCourseOrProduct(e)}
                        style={{
                          padding: '.5em 1.7em',
                        }}
                      >
                        추가
                      </Button>
                      <Button
                        className='btn-pill btn-air-danger m-l-5'
                        color='danger'
                        onClick={props.toggleModal}
                        style={{
                          padding: '.5em 1.7em',
                        }}
                      >
                        취소
                      </Button>
                    </>
                  ) : !is_editable ? (
                    <Button
                      className='btn-pill btn-air-dark'
                      color='dark'
                      onClick={toggleEdit}
                      style={{
                        padding: '.5em 1.7em',
                      }}
                    >
                      편집
                    </Button>
                  ) : (
                    <>
                      <Button
                        className='btn-pill btn-air-primary'
                        color='primary'
                        onClick={(e) => onSubmitChangeDate(e)}
                        style={{
                          padding: '.5em 1.7em',
                        }}
                      >
                        변경
                      </Button>
                      <Button
                        className='btn-pill btn-air-danger m-l-5'
                        color='danger'
                        onClick={() => {
                          toggleEdit();
                          onCancelChangeDate();
                        }}
                        style={{
                          padding: '.5em 1.7em',
                        }}
                      >
                        취소
                      </Button>
                    </>
                  )}
                </Col>
              )}
              <Col md='12 text-right' className='m-t-20'>
                {props.course_or_product_info ? (
                  <Typeahead
                    id='course-typeahead'
                    defaultSelected={
                      category === CATEGORY_COURSE
                        ? [props.course_or_product_info.course_title]
                        : [props.course_or_product_info.product_title]
                    }
                    labelKey='combine_name'
                    multiple
                    disabled
                    options={[props.course_or_product_info]}
                  />
                ) : (
                  <Typeahead
                    onChange={(e) => onTypeaheadChange(e)}
                    id='course-typeahead'
                    ref={typeahead_ref}
                    clearButton
                    labelKey='combine_name'
                    multiple
                    options={
                      category === CATEGORY_COURSE ? can_resist_course_set : can_resist_product_set
                    }
                    placeholder={
                      category === CATEGORY_COURSE
                        ? '구매하실 클래스를 선택해주세요'
                        : '구매하실 패키지를 선택해주세요'
                    }
                  />
                )}
              </Col>
              {props.course_or_product_info ? (
                <>
                  <Col md='6' className='m-t-30 text-center'>
                    <Label>수강시작일</Label>
                    {!is_editable && (
                      <Input
                        readOnly={true}
                        style={{
                          backgroundColor: '#e9ecef',
                        }}
                        defaultValue={moment(new Date(date_object.rental_time)).format(
                          'yyyy-MM-DD HH:mm:ss',
                        )}
                      />
                    )}
                    {is_editable && (
                      <DatePicker
                        className='form-control digits'
                        showPopperArrow={false}
                        selected={new Date(date_object.rental_time)}
                        showTimeSelect
                        timeIntervals={60}
                        dateFormat='yyyy-MM-dd HH:mm:ss'
                        fixedHeight
                        showYearDropdown
                        scrollableYearDropdown
                        disabled={!is_editable}
                        onChange={(e) => onCustomChangeDate(e, 'rental_time')}
                      />
                    )}
                  </Col>
                  <Col md='6' className='m-t-30 text-center'>
                    <Label>수강만료일</Label>
                    {!is_editable && (
                      <Input
                        readOnly={true}
                        style={{
                          backgroundColor: '#e9ecef',
                        }}
                        defaultValue={moment(new Date(date_object.rental_expire_time)).format(
                          'yyyy-MM-DD HH:mm:ss',
                        )}
                      />
                    )}
                    {is_editable && (
                      <DatePicker
                        className='form-control digits'
                        showPopperArrow={false}
                        selected={new Date(date_object.rental_expire_time)}
                        filterDate={isPossibleDay}
                        showTimeSelect
                        timeIntervals={60}
                        dateFormat='yyyy-MM-dd HH:mm:ss'
                        fixedHeight
                        showYearDropdown
                        scrollableYearDropdown
                        disabled={!is_editable}
                        onChange={(e) => onCustomChangeDate(e, 'rental_expire_time')}
                      />
                    )}
                  </Col>
                </>
              ) : (
                <>
                  <Col md='6' className='m-t-30 text-center'>
                    <Label>수강시작일</Label>
                    <DatePicker
                      className='form-control digits'
                      showPopperArrow={false}
                      selected={new Date(date_object.rental_time)}
                      showTimeSelect
                      timeIntervals={60}
                      dateFormat='yyyy-MM-dd HH:mm:ss'
                      fixedHeight
                      showYearDropdown
                      scrollableYearDropdown
                      onChange={(e) => onCustomChangeDate(e, 'rental_time')}
                    />
                  </Col>
                  <Col md='6' className='m-t-30 text-center'>
                    <Label>수강만료일</Label>
                    <DatePicker
                      className='form-control digits'
                      showPopperArrow={false}
                      selected={new Date(date_object.rental_expire_time)}
                      filterDate={isPossibleDay}
                      showTimeSelect
                      timeIntervals={60}
                      dateFormat='yyyy-MM-dd HH:mm:ss'
                      fixedHeight
                      showYearDropdown
                      scrollableYearDropdown
                      onChange={(e) => onCustomChangeDate(e, 'rental_expire_time')}
                    />
                  </Col>
                </>
              )}
              {AuthStore.account_type_id === 5 && (
                <Col className={'col-md-12 text-left m-t-25'}>
                  <Button
                    style={{
                      padding: '5px 20px',
                      marginRight: '15px',
                    }}
                    className='btn-pill'
                    color={onHandleButtonColor(TOGGLE_DATE_NUM_ONE_MONTH)}
                    disabled={!props.course_or_product_info ? false : !is_editable}
                    onClick={() => onButtonChangeDate('one_month')}
                  >
                    + 1개월
                  </Button>
                  <Button
                    style={{
                      padding: '5px 20px',
                      marginRight: '15px',
                    }}
                    className='btn-pill'
                    color={onHandleButtonColor(TOGGLE_DATE_NUM_THREE_MONTH)}
                    disabled={!props.course_or_product_info ? false : !is_editable}
                    onClick={() => onButtonChangeDate('three_month')}
                  >
                    + 3개월
                  </Button>
                  <Button
                    style={{
                      padding: '5px 20px',
                      marginRight: '15px',
                    }}
                    className='btn-pill'
                    color={onHandleButtonColor(TOGGLE_DATE_NUM_SIX_MONTH)}
                    disabled={!props.course_or_product_info ? false : !is_editable}
                    onClick={() => onButtonChangeDate('six_month')}
                  >
                    + 6개월
                  </Button>
                  <Button
                    style={{
                      padding: '5px 20px',
                      marginRight: '15px',
                    }}
                    className='btn-pill'
                    color={onHandleButtonColor(TOGGLE_DATE_NUM_ONE_YEAR)}
                    disabled={!props.course_or_product_info ? false : !is_editable}
                    onClick={() => onButtonChangeDate('one_year')}
                  >
                    + 12개월
                  </Button>
                </Col>
              )}
            </Row>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CourseOrProductEditModal;
