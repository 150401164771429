import React, { Fragment, useEffect } from 'react';
import Header from './views/_common/header/Header';
import Sidebar from './views/_common/sidebar/Sidebar';
import Footer from './views/_common/footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useMountEffect } from './util/b2b_Hooks';
import { clearTemporary } from './redux/b2b_store/temporary/actions';

const App = ({ children }) => {
  const AuthStore = useSelector((state) => state.AuthStore);
  const dispatch = useDispatch();

  useMountEffect(() => {
    // temp store 초기화
    dispatch(clearTemporary());
  });

  useEffect(() => {
    if (!AuthStore) {
      window.location = '/';
    }
  }, []);

  return (
    <Fragment>
      {AuthStore && (
        <div className='page-wrapper'>
          <div className='page-body-wrapper'>
            <Header />
            <Sidebar />
            <div className='page-body'>{children}</div>
            <Footer />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default App;
