import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import EventModel from '../../model/EventModel';
import Loading from '../_common/Loading';

import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label,
} from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import Form from 'reactstrap/es/Form';

import SweetAlert from 'sweetalert2';
import CommonModel from '../../model/CommonModel';
import moment from 'moment';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import CustomTypeahead from '../_common/component/CustomTypeahead';
import { createRandomBigSmallAlphabetNumber, errMessageOccur, select } from '../../util/common';
import CustomLabel from '../_common/component/CustomLabel';
import DatePicker from 'react-datepicker';
import ImageUploader from '../_common/component/ImageUploader';
import { Link } from 'react-router-dom';

const EventDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.event_id));
  const [isLoading, setIsLoading] = useState(false);

  const [sale_type, setSaleType] = useState(null);
  const [product_type, setProductType] = useState(null);

  const [currency_code_set, SetCurrencyCodeSet] = useState([]);

  const [course_select, setCourseSelect] = useState([]);
  const [event_replaced_sale_info_select, setEventReplacedSaleInfoSelect] = useState([]);
  const [event_product_select, setEventProductSelect] = useState([]);

  const [course_set, setCourseSet] = useState([]);
  const [course_sale_info_set, setCourseSaleInfoSet] = useState([]);
  const [product_set, setProductSet] = useState([]);

  const [need_show_title, setNeedShowTitle] = useState(false);

  const [event, setEvent] = useState({
    id: '',
    title: '',
    description: '',
    management_description: '',
    event_code: '',
    main_image_url: '',
    main_thumbnail_image_url: '',
    is_enabled: 0,
    can_purchase_multiple: 0,
    can_use_with_coupon: 1,
    is_trial_version: 0,
    show_in_application: 0,
    show_in_application_title: '',
    show_in_application_sub_title: '',
    is_display_time: 1,
    start_time: '',
    expire_time: '',
    currency_code_id: '',
    discount_price: '',
    override_price: '',
    event_course_set: [],
    event_product_set: [],
    event_replaced_sale_info_set: [],
  });

  const obj_err_msg = useMemo(() => {
    return {
      title: '제목',
      event_code: '이벤트코드',
      is_enabled: '활성화여부',
      can_purchase_multiple: '중복참여가능여부',
      can_use_with_coupon: '쿠폰과함께사용가능여부',
      is_trial_version: '체험판여부',
      show_in_application: '타임특가배너노출여부',
      is_display_time: '남은참여기간표시',
      currency_code_id: '화폐종류',
      discount_price: '할인금액',
      override_price: '판매금액',
      expire_time: '종료일',
    };
  }, []);

  const onProductTypeChange = (e) => {
    setProductType(e.target.value);

    // 각 선택 강의 혹은 패키지 초기화 시키기
    if (e.target.value === 'product') {
      setCourseSelect([]);
      setEventReplacedSaleInfoSelect([]);
    } else if (e.target.value === 'course') {
      setEventProductSelect([]);
    }
  };

  const onInputChange = (e) => {
    let value;
    if (e.target.name === 'show_in_application') {
      value = Number(e.target.value);
      if (!value) {
        //
      }
    } else {
      value = e.target.value;
    }
    setEvent({ ...event, [e.target.name]: value });
  };

  const onDateTimeChange = (e, name) => {
    const date = moment(e);
    if (date.year() < 10000) {
      setEvent({ ...event, [name]: e && date.format('YYYY-MM-DDTHH:mm:ss') });
    }
  };

  const onInputSubmit = async (e) => {
    e.preventDefault();

    // 처음 이미지 작성 없이 생성 가능
    if (!!!event.id) {
      delete obj_err_msg['main_image_url'];
    }

    if (sale_type === 'override') {
      delete obj_err_msg['discount_price'];
      obj_err_msg['override_price'] = '판매금액';
    } else if (sale_type === 'discount') {
      delete obj_err_msg['override_price'];
      obj_err_msg['discount_price'] = '할인금액';
    }

    if (sale_type === null) {
      delete obj_err_msg['discount_price'];
      delete obj_err_msg['override_price'];
    }

    const err_msg = errMessageOccur(event, obj_err_msg, false);

    if (sale_type === null) {
      err_msg.push("'판매종류'");
    }

    const result_course_select = course_select.map((e) => e.id);
    const result_product_select = event_product_select.map((e) => e.id);

    if (!(result_course_select.length || result_product_select.length)) {
      err_msg.push("'이벤트에 적용할 강의, 패키지 항목을 선택해주세요.'");
    }

    if (event.show_in_application) {
      if (!event.show_in_application_title || !event.show_in_application_sub_title) {
        err_msg.push('타임특가 배너에 표시될 제목, 하위 제목의 입력을 확인해주세요');
      }
    } else {
      setEvent({
        ...event,
        show_in_application_title: '',
        show_in_application_sub_title: '',
      });
    }

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      const result_event_replaced_sale_info_select = event_replaced_sale_info_select.map(
        (e) => e.sale_info_id,
      );

      if (event.id) {
        await EventModel.putEventDetail({
          ...event,
          result_course_select: result_course_select,
          result_product_select: result_product_select,
          result_event_replaced_sale_info_select: result_event_replaced_sale_info_select,
        }).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        EventModel.checkEventCode(event.event_code).then(({ is_valid }) => {
          if (is_valid) {
            EventModel.postEvent({
              ...event,
              result_course_select: result_course_select,
              result_product_select: result_product_select,
              result_event_replaced_sale_info_select: result_event_replaced_sale_info_select,
            }).then(({ event_id }) => {
              props.history.replace('/event/' + event_id);
              window.location.reload();
            });
          } else {
            SweetAlert.fire({
              title: '저장중 문제를 발견했습니다.',
              text: "'이벤트텍스트'가 중복된 값이 존재합니다.",
            });
            setIsLoading(false);
          }
        });
      }
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const requestDetail = () => {
    EventModel.getEventDetail(Number(props.match.params.event_id)).then(({ event }) => {
      if (event) {
        setEvent(event);

        if (event.show_in_application) {
          setNeedShowTitle(true);
        }

        // 선택되어있는 강의
        const _course_select = event.event_course_set.map((e) => {
          let obj = {};
          obj['id'] = e.course_id;
          obj['title'] = e.combine_name;
          return obj;
        });
        setCourseSelect(_course_select);

        // 선택되어있는 패키지
        const _event_product_select = event.event_product_set.map((e) => {
          let obj = {};
          obj['id'] = e.product_id;
          obj['combine_name'] = e.combine_name;
          return obj;
        });
        setEventProductSelect(_event_product_select);

        // 선택되어있는 event_replaced -> saleinfo
        const _event_replaced_sale_info_select = event.event_replaced_sale_info_set.map((e) => {
          let obj = {};
          obj['course_id'] = e.course_id;
          obj['sale_info_id'] = e.sale_info_id;
          obj['combine_name'] = `[${e.course_short_title}] ${e.sale_info_sale_short_name}`;
          return obj;
        });
        setEventReplacedSaleInfoSelect(_event_replaced_sale_info_select);

        // 초기 이벤트 타입 결정하는 조건
        if (String(event.override_price) !== '0') {
          setSaleType('override');
          if (_course_select.length > 0) {
            setProductType('course');
          } else {
            setProductType('product');
          }
        } else if (String(event.discount_price) !== '0') {
          setSaleType('discount');
        }
      }
    });
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'currency_code_set' }).then(({ code_set }) => {
      SetCurrencyCodeSet(code_set);

      if (!Number(props.match.params.event_id)) {
        setEvent({ ...event, currency_code_id: code_set[0]?.id });
      }
    });

    CommonModel.getSearch('accessible_course_price_displaying').then((data) => {
      const new_data = data.map(({ course_id: id, combine_name: title }) => {
        return { id, title };
      });
      setCourseSet(new_data);
    });

    CommonModel.getSearch('event_replaced_sale_info').then((data) => {
      setCourseSaleInfoSet(data);
    });

    CommonModel.getSearch('cms_accessible_product').then((data) => {
      setProductSet(data);
    });

    requestDetail();
  }, []);

  useEffect(() => {
    setEventReplacedSaleInfoSelect((prevState) => {
      return prevState.filter((prev) => {
        return course_select.map((select) => select.id).includes(prev.course_id);
      });
    });
  }, [course_select]);

  return (
    <Fragment>
      <Breadcrumb
        parent='이벤트'
        title={`이벤트 ${props.match.params.event_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form onSubmit={onInputSubmit}>
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`이벤트 ${props.match.params.event_id === '0' ? '생성' : '정보'}`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {select(
                        Number(props.match.params.event_id) && !isEdit,
                        <>
                          <a
                            className='btn btn-pill btn-info mr-2'
                            href={`${process.env.REACT_APP_API_URL}/event/time/${event.event_code}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            미리보기
                          </a>
                        </>,
                      )}
                      {select(
                        Number(props.match.params.event_id) && !isEdit,
                        <>
                          <Link
                            className='btn btn-pill btn-info mr-2'
                            to={`/sale/?purchase_status_id=${encodeURIComponent('2')}&event_id=${
                              event.id
                            }`}
                            // to={`/sale/?purchase_status_id=${encodeURIComponent("2,3")}&event_id=${event.id}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            판매이력조회
                          </Link>
                        </>,
                      )}
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      <Button
                        className='btn-pill'
                        color='primary'
                        style={{ marginRight: '8px' }}
                        onClick={() => {
                          // 생성(0)일 경우 수정(0 이상)일 경우
                          if (!!Number(props.match.params.event_id)) {
                            // 초기화
                            if (isEdit) {
                              requestDetail();
                            }
                            setIsEdit(!isEdit);
                          } else {
                            props.history.push('/event');
                          }
                        }}
                      >
                        {isEdit ? '취소' : '편집'}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={7} style={{ borderRight: '2px solid #F5F5F5' }}>
                      {isEdit && (
                        <Row>
                          <Col className={'text-right'}>
                            <Button
                              style={{ marginRight: '5px' }}
                              onClick={() => {
                                setSaleType('discount');
                                setProductType(null);
                                setEvent((prevState) => ({
                                  ...prevState,
                                  override_price: '0',
                                  discount_price: '',
                                }));
                              }}
                              color={sale_type === 'discount' ? 'primary' : 'danger'}
                            >
                              할인 판매
                            </Button>
                            <Button
                              onClick={() => {
                                setSaleType('override');
                                setCourseSelect([]);
                                setEventProductSelect([]);
                                setEventReplacedSaleInfoSelect([]);
                                setEvent((prevState) => ({
                                  ...prevState,
                                  override_price: '',
                                  discount_price: '0',
                                }));
                              }}
                              color={sale_type === 'override' ? 'primary' : 'danger'}
                            >
                              정량가 판매
                            </Button>
                          </Col>
                        </Row>
                      )}
                      {sale_type === 'override' && isEdit && (
                        <Row>
                          <Col className={'text-right mt-1'}>
                            <FormGroup className='m-t-15 m-checkbox-inline mb-0 custom-radio-ml'>
                              <div className='radio radio-primary'>
                                <Input
                                  id='radioinline1'
                                  type='radio'
                                  name='radio1'
                                  value='product'
                                  onChange={onProductTypeChange}
                                  checked={product_type === 'product'}
                                />
                                <Label className='mb-0' for='radioinline1'>
                                  <span className='digits'>패키지</span>
                                </Label>
                              </div>
                              <div className='radio radio-primary'>
                                <Input
                                  id='radioinline2'
                                  type='radio'
                                  name='radio1'
                                  value='course'
                                  onChange={onProductTypeChange}
                                  checked={product_type === 'course'}
                                />
                                <Label className='mb-0' for='radioinline2'>
                                  <span className='digits'>강의</span>
                                </Label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'title'}
                            disabled={!isEdit}
                            style={{ resize: 'none' }}
                            value={event.title || ''}
                            is_required={true}
                            label={'제목'}
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 128;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'description'}
                            disabled={!isEdit}
                            value={event.description || ''}
                            label={'설명'}
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 128;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'management_description'}
                            disabled={!isEdit}
                            value={event.management_description || ''}
                            label={'관리용설명'}
                            placeholder='특별한 경우가 아니면 입력하지 않습니다'
                            tooltip_desc={
                              '본 이벤트를 통해 구매하여 강의가 할당되는경우, 입력하신 관리용설명을 유저 보유 강의에서 확인할수있습니다.'
                            }
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 12;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12'>
                          <CustomLabel
                            is_required={true}
                            label={'이벤트코드'}
                            tooltip_desc={'이벤트코드는 한번 생성하면 변경 할 수 없습니다.'}
                          />
                          <FormGroup>
                            <InputGroup>
                              <Input
                                type={'text'}
                                name={'event_code'}
                                disabled={!!Number(props.match.params.event_id)}
                                value={event.event_code || ''}
                                onChange={(e) => {
                                  if (e.target.value.length <= 36) {
                                    onInputChange(e);
                                  }
                                }}
                                placeholder={'이벤트텍스트는 중복 될 수 없습니다. (최대 36자)'}
                              />
                              <InputGroupAddon
                                addonType='append'
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  if (!!!Number(props.match.params.event_id)) {
                                    setEvent((prevState) => ({
                                      ...prevState,
                                      event_code: createRandomBigSmallAlphabetNumber(10),
                                    }));
                                  }
                                }}
                              >
                                <InputGroupText>
                                  <i className='icofont icofont-random mr-2' /> 생성
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={'2 mb-3'}>
                          <CustomSelectInput
                            name={'is_enabled'}
                            disabled={!!!Number(props.match.params.event_id) || !isEdit}
                            value={event.is_enabled}
                            label={'활성화여부'}
                            tooltip_desc={
                              '비활성화 하는경우 시작일, 종료일 상관없이 이벤트가 비활성화됨. (저장후 활성화할 수 있습니다)'
                            }
                            is_required={true}
                            options={[
                              { id: 0, name: '비활성화' },
                              { id: 1, name: '활성화' },
                            ]}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md={'3 mb-3'}>
                          <CustomSelectInput
                            name={'can_purchase_multiple'}
                            disabled={!isEdit}
                            value={event.can_purchase_multiple}
                            label={'중복참여가능여부'}
                            tooltip_desc={
                              '해당 이벤트코드로 유저가 여러 강의 구매에 사용할 수 있는지 여부\n(예를들어 불가능인 경우, 해당 이벤트 코드로 1번밖에 할인 혜택을 받을 수 없음).'
                            }
                            is_required={true}
                            options={[
                              { id: 0, name: '불가능' },
                              { id: 1, name: '가능' },
                            ]}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md={'4 mb-3'}>
                          <CustomSelectInput
                            name={'can_use_with_coupon'}
                            disabled={!isEdit}
                            value={event.can_use_with_coupon}
                            label={'쿠폰과함께사용가능여부'}
                            tooltip_desc={
                              '쿠폰과 함께 사용 가능여부, 불가능인 경우 별도의 쿠폰을 적용할 수 없음.'
                            }
                            is_required={true}
                            options={[
                              { id: 0, name: '불가능' },
                              { id: 1, name: '가능' },
                            ]}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md={'3 mb-3'}>
                          <CustomSelectInput
                            name={'is_trial_version'}
                            disabled={!isEdit}
                            value={event.is_trial_version}
                            label={'체험판여부'}
                            tooltip_desc={
                              '체험판으로 설정하는경우, 최초 구매(구체적으로는 강의 미보유자)에 해당 되는 경우에만 구매가 가능하도록 설정됩니다.'
                            }
                            is_required={true}
                            options={[
                              { id: 0, name: '아니요' },
                              { id: 1, name: '예' },
                            ]}
                            onChange={onInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={'6 mb-3'}>
                          <Row>
                            <Col md={12}>
                              <Label htmlFor='start_time'>
                                시작일 (입력 없는 경우 바로 시작됨)
                              </Label>
                              <DatePicker
                                autoComplete='off'
                                className='form-control digits'
                                name='start_time'
                                selectsStart
                                disabled={!isEdit}
                                onChange={(e) => {
                                  onDateTimeChange(e, 'start_time');
                                }}
                                locale='ko'
                                selected={event.start_time ? new Date(event.start_time) : null}
                                startDate={event.start_time ? new Date(event.start_time) : null}
                                endDate={event.expire_time ? new Date(event.expire_time) : null}
                                maxDate={event.expire_time ? new Date(event.expire_time) : null}
                                showTimeSelect
                                dateFormat='yyyy-MM-dd HH:mm:ss'
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={'6 mb-3'}>
                          <Row>
                            <Col md={12}>
                              <CustomLabel is_required={true} label={'종료일'} tooltip_desc={''} />
                              <DatePicker
                                autoComplete='off'
                                className='form-control digits'
                                name='expire_time'
                                selectsEnd
                                disabled={!isEdit}
                                onChange={(e) => {
                                  onDateTimeChange(e, 'expire_time');
                                }}
                                locale='ko'
                                selected={event.expire_time ? new Date(event.expire_time) : null}
                                startDate={event.start_time ? new Date(event.start_time) : null}
                                endDate={event.expire_time ? new Date(event.expire_time) : null}
                                minDate={event.start_time ? new Date(event.start_time) : null}
                                showTimeSelect
                                dateFormat='yyyy-MM-dd HH:mm:ss'
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'currency_code_id'}
                            disabled={!isEdit}
                            value={event.currency_code_id || ''}
                            label={'화폐종류'}
                            is_required={true}
                            onChange={onInputChange}
                            options={currency_code_set}
                            sub_option_type={'description'}
                          />
                        </Col>
                        {sale_type === 'discount' && (
                          <Col md='5 mb-3'>
                            <CustomInput
                              type={'text'}
                              name={'discount_price'}
                              disabled={!isEdit}
                              value={event.discount_price || ''}
                              label={'할인금액'}
                              tooltip_desc={
                                '해당 가격만큼 유저 구매액에서 차감됩니다. (0인경우 설정이 안된것으로 취급)'
                              }
                              is_required={true}
                              onChange={onInputChange}
                              validator={(value) => {
                                const regexp = /^[0-9\b]*$/;
                                return regexp.test(value);
                              }}
                              placeholder={'할인금액'}
                            />
                          </Col>
                        )}
                        {sale_type === 'override' && (
                          <Col md='5 mb-3'>
                            <CustomInput
                              type={'text'}
                              is_required={true}
                              name={'override_price'}
                              disabled={!isEdit}
                              value={event.override_price || ''}
                              label={'판매금액'}
                              tooltip_desc={
                                '입력하는 경우 실제 구매가에서 [할인금액]을 반영하지 않고, 설정된 값으로 판매가 진행됨.\n(0인경우 설정이 안된것으로 취급)'
                              }
                              onChange={onInputChange}
                              validator={(value) => {
                                const regexp = /^[0-9\b]*$/;
                                return regexp.test(value);
                              }}
                              placeholder={'판매금액'}
                            />
                          </Col>
                        )}
                        <Col md={'4 mb-3'}>
                          <CustomSelectInput
                            name={'is_display_time'}
                            disabled={!isEdit}
                            value={event.is_display_time}
                            label={'남은참여기간표시'}
                            tooltip_desc={
                              '상세페이지 등, 해당 이벤트의 남은 기간을 표시하지 않으려 할 때 사용합니다.'
                            }
                            is_required={true}
                            options={[
                              { id: 1, name: '네' },
                              { id: 0, name: '아니요' },
                            ]}
                            onChange={onInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={'4 mb-3'}>
                          <CustomSelectInput
                            name={'show_in_application'}
                            disabled={!isEdit}
                            value={event.show_in_application}
                            label={'타임특가 배너 노출 여부'}
                            is_required={true}
                            options={[
                              { id: 0, name: '하지않음' },
                              { id: 1, name: '타임특가 용 이벤트' },
                            ]}
                            onChange={(e) => {
                              if (Number(e.target.value)) {
                                setNeedShowTitle(true);
                              } else {
                                setNeedShowTitle(false);
                              }
                              onInputChange(e);
                            }}
                          />
                        </Col>
                      </Row>
                      {need_show_title && (
                        <Row>
                          <Col md='12 mb-3'>
                            <CustomInput
                              type={'textarea'}
                              name={'show_in_application_title'}
                              disabled={!isEdit}
                              style={{ resize: 'none' }}
                              value={event.show_in_application_title || ''}
                              label={'타임특가 배너 표시 제목'}
                              tooltip_desc={
                                '타임특가 이벤트는 반드시 서비스에서 노출시킬 제목을 작성해야 합니다 (40글자 이하)' +
                                '\n\n서비스에 제목을 두 줄로 노출시키려면 줄바꿈을 해야 합니다 (최대 두 줄로 작성하는 것을 권장합니다)'
                              }
                              onChange={onInputChange}
                              validator={(value) => {
                                return value.length <= 40;
                              }}
                            />
                          </Col>
                          <Col md='12 mb-3'>
                            <CustomInput
                              type={'text'}
                              name={'show_in_application_sub_title'}
                              disabled={!isEdit}
                              style={{ resize: 'none' }}
                              value={event.show_in_application_sub_title || ''}
                              label={'타임특가 배너 표시 하위 제목'}
                              tooltip_desc={
                                '타임특가 이벤트는 반드시 서비스에서 노출시킬 하위 제목을 작성해야 합니다 (28글자 이하)'
                              }
                              onChange={onInputChange}
                              validator={(value) => {
                                return value.length <= 28;
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col md={5}>
                      <Row>
                        <Col
                          md={'3 mb-4'}
                          style={{
                            minWidth: '360px',
                            alignSelf: 'center',
                            margin: 'auto',
                          }}
                        >
                          <div className='blog-box text-center'>
                            <ImageUploader
                              name='main_image_url'
                              id={Number(props.match.params.event_id)}
                              withPreview={true}
                              img_type={'event'}
                              min_width={1920}
                              thumbnail_width={500}
                              setPropsState={setEvent}
                              img_url={event.main_image_url}
                              title={event.title}
                              isEdit={!!Number(props.match.params.event_id) ? isEdit : false}
                              imgExtension={['.jpg', '.gif', '.png', '.gif', '.svg']}
                              height={230}
                            />
                            <CustomLabel
                              is_required={true}
                              label={'메인 이미지'}
                              tooltip_desc={''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomTypeahead
                            disabled={
                              !isEdit || (sale_type !== 'discount' && product_type !== 'product')
                            }
                            multiple={true}
                            label={'이벤트 패키지항목'}
                            selected={event_product_select}
                            selectedHandler={setEventProductSelect}
                            onTokenClick={(target) => {
                              window.open('/product/' + target.id, '_blank');
                            }}
                            labelKey={'combine_name'}
                            customToken={(target) => {
                              return `${target.combine_name}`;
                            }}
                            options={product_set}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomTypeahead
                            // false,
                            disabled={
                              !isEdit || (sale_type !== 'discount' && product_type !== 'course')
                            }
                            multiple={true}
                            label={'이벤트 해당강의'}
                            selected={course_select}
                            selectedHandler={setCourseSelect}
                            onTokenClick={(target) => {
                              window.open('/course/' + target.id, '_blank');
                            }}
                            labelKey={'title'}
                            customToken={(target) => {
                              return `${target.title}`;
                            }}
                            options={course_set}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={'12 mb-3'}>
                          <Button
                            disabled={
                              !isEdit || (sale_type !== 'discount' && product_type !== 'course')
                            }
                            onClick={() => {
                              setCourseSelect(course_set);
                            }}
                          >
                            전체강의 불러오기
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomTypeahead
                            disabled={
                              !isEdit || (sale_type !== 'discount' && product_type !== 'course')
                            }
                            multiple={true}
                            label={'대체 판매항목'}
                            tooltip_desc={
                              '대체할 판매항목을 입력하면 해당 강의의 판매상품을 노출할때 대체 판매항목으로 입력한 항목들로만 표시합니다.\n(해당 강의에 대체 판매항목이 없는경우는 기본 판매항목을 표시함, 해당 판매항목이 숨김처리 되었어도 표시됨)'
                            }
                            selected={event_replaced_sale_info_select}
                            selectedHandler={setEventReplacedSaleInfoSelect}
                            onTokenClick={(target) => {
                              // window.open("/course/" + target.id, "_blank");
                            }}
                            labelKey={'combine_name'}
                            customToken={(target) => {
                              return `${target.combine_name}`;
                            }}
                            options={course_sale_info_set.filter((course_sale_info) => {
                              return course_select
                                .map((select) => select.id)
                                .includes(course_sale_info.course_id);
                            })}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default EventDetail;
