import {
  STORED_COURSE_SALE,
  CLEAR_COURSE_SALE,
  SET_COURSE_SALE,
  ADD_COURSE_SALE,
  DELETE_COURSE_SALE,
  SET_COURSE_SALE_PRICE,
} from '../../action_type';

export const stored_course_sale = (data) => ({
  type: STORED_COURSE_SALE,
  data,
});

export const clear_course_sale = () => ({
  type: CLEAR_COURSE_SALE,
});

export const set_course_sale = (sale_index, name, value) => ({
  type: SET_COURSE_SALE,
  sale_index,
  name,
  value,
});

export const add_course_sale = () => ({
  type: ADD_COURSE_SALE,
});

export const delete_course_sale = (sale_index) => ({
  type: DELETE_COURSE_SALE,
  sale_index,
});

export const set_course_sale_price = (sale_index, name, value) => ({
  type: SET_COURSE_SALE_PRICE,
  sale_index,
  name,
  value,
});
