import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CouponModel from '../../model/CouponModel';
import Loading from '../_common/Loading';

import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
} from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import Form from 'reactstrap/es/Form';

import SweetAlert from 'sweetalert2';
import CommonModel from '../../model/CommonModel';
import moment from 'moment';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import CustomTypeahead from '../_common/component/CustomTypeahead';
import { createRandomSmallAlphabet, errMessageOccur } from '../../util/common';
import CustomLabel from '../_common/component/CustomLabel';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';

const objErrMsg = {
  title: '쿠폰명',
  coupon_text: '쿠폰텍스트',
  coupon_count: '사용가능횟수',
  can_unlimited_use: '일회성여부',
  can_use_at_extend_course: '강의연장유무',
  expire_time: '쿠폰만료일',
  currency_code_id: '화폐종류',
  discounts: '할인금액',
};

const CouponDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.coupon_id));
  const [isLoading, setIsLoading] = useState(false);
  // 더보기 쿠폰 사용한 사람 조회
  const [isMore, setIsMore] = useState(10);

  const [currencyCodeSet, SetCurrencyCodeSet] = useState([]);

  // 선택 정보 가지고 있기(get_or_create), 삭제한 정보 가지고 있기(delete)
  const [courseSelect, setCourseSelect] = useState([]);
  const [couponAllowProductTypeSelect, setCouponAllowProductTypeSelect] = useState([]);
  const [productSelect, setProductSelect] = useState([]);
  const [onlyBearuCourseSet, setOnlyBearuCourseSet] = useState([]);
  const [courseSet, setCourseSet] = useState([]);
  const [productTypeSet, setProductTypeSet] = useState([]);
  const [productSet, setProductSet] = useState([]);

  const [coupon, setCoupon] = useState({
    id: '',
    title: '',
    description: '',
    management_description: '',
    coupon_text: '',
    price_display_name: '',
    discounts: '',
    coupon_count: '',
    currency_code_id: '',
    can_unlimited_use: 0,
    can_use_at_extend_course: 1,
    create_time: '',
    expire_time: '',
    coupon_course_set: [],
    coupon_product_set: [],
    coupon_allow_product_type_set: [],
  });

  const onInputChange = (e) => {
    setCoupon({ ...coupon, [e.target.name]: e.target.value });
  };

  const onExpireDateTimeChange = (e) => {
    const date = moment(e);
    if (date.year() < 10000) {
      setCoupon({ ...coupon, expire_time: e && date.format('YYYY-MM-DDTHH:mm:ss') });
    }
  };

  const onExprieDateTimeSelect = (e) => {
    const {
      target: { value },
    } = e;

    let expire_time = moment();

    if (value === '0') {
      expire_time = '';
    } else if (value === '1') {
      expire_time = expire_time.add(3, 'months');
    } else if (value === '2') {
      expire_time = expire_time.add(6, 'months');
    } else if (value === '3') {
      expire_time = expire_time.add(12, 'months');
    }

    setCoupon({
      ...coupon,
      expire_time: expire_time && expire_time.format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  const onInputSubmit = async (e) => {
    e.preventDefault();

    const errMsg = errMessageOccur(coupon, objErrMsg);

    if (!errMsg.length) {
      const resultCourseSelect = courseSelect.map((e) => e.id);
      const resultProductSelect = productSelect.map((e) => e.id);
      const resultCouponAllowProductTypeSelect = couponAllowProductTypeSelect.map((e) => e.id);

      setIsLoading(true);
      e.target.disabled = true;

      if (coupon.id) {
        await CouponModel.putCouponDetail({
          ...coupon,
          result_course_select: resultCourseSelect,
          result_product_select: resultProductSelect,
          result_coupon_allow_product_type_select: resultCouponAllowProductTypeSelect,
        }).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        CouponModel.checkCouponText(coupon.coupon_text).then(({ is_valid }) => {
          if (is_valid) {
            CouponModel.postCoupon({
              ...coupon,
              result_course_select: resultCourseSelect,
              result_product_select: resultProductSelect,
              result_coupon_allow_product_type_select: resultCouponAllowProductTypeSelect,
            }).then(({ coupon_id }) => {
              props.history.replace('/coupon/' + coupon_id);
              window.location.reload();
            });
          } else {
            SweetAlert.fire({
              title: '저장중 문제를 발견했습니다.',
              text: "'쿠폰텍스트'가 중복된 값이 존재합니다.",
            });
            setIsLoading(false);
          }
        });
      }
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: errMsg.join('\n'),
      });
    }
  };

  const onExpireCouponClick = () => {
    SweetAlert.fire({
      title: '본 쿠폰은 만료시키겠습니까?',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    }).then((result) => {
      if (result.value) {
        CouponModel.expireCoupon(Number(props.match.params.coupon_id)).then(({ expire_time }) => {
          setCoupon((prevState) => ({ ...prevState, expire_time: expire_time }));
        });
      }
    });
  };

  const requestDetail = () => {
    CouponModel.getCouponDetail(Number(props.match.params.coupon_id)).then(({ coupon }) => {
      if (coupon) {
        setCoupon(coupon);

        const _courseSelect = coupon.coupon_course_set.map((e) => {
          let obj = {};
          obj['id'] = e.course_id;
          obj['title'] = e.combine_name;
          return obj;
        });
        setCourseSelect(_courseSelect);

        const _productSelect = coupon.coupon_product_set.map((e) => {
          let obj = {};
          obj['id'] = e.product_id;
          obj['combine_name'] = e.combine_name;
          return obj;
        });
        setProductSelect(_productSelect);

        const _couponAllowProductTypeSelect =
          coupon.coupon_allow_product_type_set?.length > 0
            ? coupon.coupon_allow_product_type_set.map((e) => {
                let obj = {};
                obj['id'] = e.product_type_id;
                obj['name'] = e.name;
                obj['description'] = e.description;
                return obj;
              })
            : [];
        setCouponAllowProductTypeSelect(_couponAllowProductTypeSelect);
      }
    });
  };

  const countCouponLogUser = (coupon_log_set) => {
    if (!Array.isArray(coupon_log_set)) {
      return;
    }

    const coupon_user_count = {};

    coupon_log_set.forEach((coupon_log) => {
      const { account_name, account } = coupon_log;
      if (coupon_user_count[account_name]) {
        coupon_user_count[account_name].count += 1;
      } else {
        coupon_user_count[account_name] = { account_id: account, count: 1 };
      }
    });

    return Object.entries(coupon_user_count);
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'currency_code_set' }).then(({ code_set }) => {
      SetCurrencyCodeSet(code_set);

      if (!Number(props.match.params.coupon_id)) {
        setCoupon({ ...coupon, currency_code_id: code_set[0]?.id });
      }
    });

    CommonModel.getSearch('accessible_course_price_displaying').then((data) => {
      const new_data = data.map(({ course_id: id, combine_name: title }) => {
        return { id, title };
      });
      setCourseSet(new_data);
    });

    CommonModel.staticCode({ code_name: 'product_type_set' }).then(({ code_set }) => {
      setProductTypeSet([...code_set]);
    });

    CommonModel.getSearch('only_bearu_course').then((data) => {
      const new_data = data.map(({ id: id, combine_name: title }) => {
        return { id, title };
      });
      setOnlyBearuCourseSet(new_data);
    });

    CommonModel.getSearch('cms_accessible_product').then((data) => {
      setProductSet(data);
    });

    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent='쿠폰'
        title={`쿠폰 ${props.match.params.coupon_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form onSubmit={onInputSubmit}>
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`쿠폰 ${props.match.params.coupon_id === '0' ? '생성' : '정보'}`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      {Number(props.match.params.coupon_id) && !isEdit ? (
                        <>
                          <Button
                            className='btn btn-pill btn-danger mr-2'
                            onClick={(e) => {
                              onExpireCouponClick();
                            }}
                          >
                            본 쿠폰 만료 시키기
                          </Button>
                        </>
                      ) : (
                        ''
                      )}
                      <Button
                        className='btn-pill'
                        color='primary'
                        style={{ marginRight: '8px' }}
                        onClick={() => {
                          // 생성(0)일 경우 수정(0 이상)일 경우
                          if (!!Number(props.match.params.coupon_id)) {
                            // 초기화
                            if (isEdit) {
                              requestDetail();
                            }
                            setIsEdit(!isEdit);
                          } else {
                            props.history.push('/coupon');
                          }
                        }}
                      >
                        {isEdit ? '취소' : '편집'}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={7} style={{ borderRight: '2px solid #F5F5F5' }}>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'title'}
                            disabled={!isEdit}
                            style={{ resize: 'none' }}
                            value={coupon.title || ''}
                            is_required={true}
                            label={'쿠폰명'}
                            onChange={onInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'description'}
                            disabled={!isEdit}
                            value={coupon.description || ''}
                            label={'설명'}
                            onChange={onInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'management_description'}
                            disabled={!isEdit}
                            value={coupon.management_description || ''}
                            label={'관리용 설명'}
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 12;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12'>
                          <CustomLabel is_required={true} label={'쿠폰텍스트'} />
                          <FormGroup>
                            <InputGroup>
                              <Input
                                type={'text'}
                                name={'coupon_text'}
                                disabled={!!Number(props.match.params.coupon_id)}
                                value={coupon.coupon_text || ''}
                                onChange={(e) => {
                                  if (e.target.value.length <= 36) {
                                    onInputChange(e);
                                  }
                                }}
                                placeholder={'쿠폰텍스트는 중복 될 수 없습니다. (최대 36자)'}
                              />
                              <InputGroupAddon
                                addonType='append'
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  if (!!!Number(props.match.params.coupon_id)) {
                                    setCoupon((prevState) => ({
                                      ...prevState,
                                      coupon_text: createRandomSmallAlphabet(10),
                                    }));
                                  }
                                }}
                              >
                                <InputGroupText>
                                  <i className='icofont icofont-random mr-2' /> 생성
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'currency_code_id'}
                            disabled={!!Number(props.match.params.coupon_id)}
                            value={coupon.currency_code_id || ''}
                            label={'화폐종류'}
                            is_required={true}
                            onChange={onInputChange}
                            options={currencyCodeSet}
                            sub_option_type={'description'}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'discounts'}
                            disabled={!!Number(props.match.params.coupon_id)}
                            value={coupon.discounts || ''}
                            label={'할인금액'}
                            is_required={true}
                            onChange={onInputChange}
                            validator={(value) => {
                              const regexp = /^[0-9\b]*$/;
                              return regexp.test(value);
                            }}
                          />
                        </Col>
                        <Col md='6 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'price_display_name'}
                            disabled={!isEdit}
                            value={coupon.price_display_name || ''}
                            label={'가격표시 대체 문자열'}
                            tooltip_desc={
                              '입력하는 경우 서비스내 할인금액 표시부분에서 할인금액 대신 이 문자열로 표시됩니다.\n고객은 구체적인 할인금액 내용을 인지 할 수 없게 됩니다.'
                            }
                            onChange={onInputChange}
                            placeholder={'입력하게되면 할인 금액 대신 이 문자열을 표시합니다'}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <Col md='mb-3 ml-3'>
                          <CustomLabel label={'쿠폰만료일'} is_required={true} />
                          <DatePicker
                            autoComplete='off'
                            className='form-control digits mb-3'
                            name='expire_time'
                            disabled={!!Number(props.match.params.coupon_id)}
                            onChange={onExpireDateTimeChange}
                            locale='ko'
                            selected={coupon.expire_time ? new Date(coupon.expire_time) : null}
                            timeFormat='HH:mm:ss'
                            showTimeSelect
                            dateFormat='yyyy-MM-dd HH:mm:ss'
                          />
                        </Col>
                        {!!!Number(props.match.params.coupon_id) && (
                          <Col md='3 mb-3'>
                            <CustomSelectInput
                              name={'date_range'}
                              disabled={!!Number(props.match.params.coupon_id)}
                              label={'날짜선택'}
                              onChange={onExprieDateTimeSelect}
                              options={[
                                { id: 0, name: '------' },
                                { id: 1, name: '3개월' },
                                { id: 2, name: '6개월' },
                                { id: 3, name: '1년' },
                              ]}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'number'}
                            name={'coupon_count'}
                            disabled={!!Number(props.match.params.coupon_id)}
                            value={coupon.coupon_count || ''}
                            is_required={true}
                            label={'사용가능횟수'}
                            tooltip_desc={
                              '해당 쿠폰으로 임의의 유저들이 얼마나 사용 가능한지에 대한 횟수\n(예를들어 10인경우는, 해당 쿠폰텍스트로 10명의 유저가 구매 가능함, 1인 경우는 1명만 구매가 가능함).'
                            }
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='4 mb-3'>
                          <CustomSelectInput
                            name={'can_unlimited_use'}
                            disabled={!!Number(props.match.params.coupon_id)}
                            value={coupon.can_unlimited_use || ''}
                            label={'일회성여부'}
                            is_required={true}
                            tooltip_desc={
                              '발급받은 쿠폰으로 유저가 여러번 사용할 수 있는지 여부\n(예를들어 일회성인 경우 구매시에 사용자가 단 한번만 사용할 수 있음, 제한없음인 경우 사용자가 동일한 쿠폰을 여러번  사용할 수 있음).'
                            }
                            onChange={onInputChange}
                            options={[
                              {
                                id: '0',
                                name: '일회성',
                              },
                              {
                                id: '1',
                                name: '제한없음',
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='8 mb-3'>
                          <CustomTypeahead
                            disabled={!!Number(props.match.params.coupon_id)}
                            multiple={true}
                            label={'사용가능처 지정'}
                            tooltip_desc={
                              '사용가능처를 지정하지 않으면 모든 상품 구매에 이 쿠폰이 사용 가능하게 됩니다.\n사용가능처를 지정하면 특정 상품 구매에만 사용 가능하도록 설정됩니다.\n' +
                              '본 설정은 지정한 "대상 강의들"(또는 이벤트 해당강의 등) 옵션보다 먼저 사용됩니다.'
                            }
                            onChange={(e) => {
                              // 추가한 정보 알아오기...
                              let selected;

                              // 차집합
                              if (couponAllowProductTypeSelect.length < e.length) {
                                selected = e.filter(
                                  (value) =>
                                    !couponAllowProductTypeSelect
                                      .map((val) => val.id)
                                      .includes(value.id),
                                )[0];
                              }

                              setCourseSelect([]);
                              setProductSelect([]);

                              if (
                                e.some((el) => el.name === '커스텀선택') &&
                                e.some((el) => el.name === '베어유 강의')
                              ) {
                                const customIndex = e.findIndex(
                                  (item) => item.name === '커스텀선택',
                                );
                                const bearLectureIndex = e.findIndex(
                                  (item) => item.name === '베어유 강의',
                                );
                                const biggerIndex = Math.max(customIndex, bearLectureIndex);
                                const result = [e[biggerIndex]];
                                setCouponAllowProductTypeSelect(result);
                                if (e[biggerIndex].name === '베어유 강의') {
                                  setCourseSelect(onlyBearuCourseSet);
                                }
                              } else {
                                if (
                                  e.some((el) => el.name === '커스텀선택') ||
                                  e.some((el) => el.name === '베어유 강의')
                                ) {
                                  setCouponAllowProductTypeSelect([selected]);
                                  if (selected.name === '베어유 강의') {
                                    setCourseSelect(onlyBearuCourseSet);
                                  }
                                }
                              }
                            }}
                            selected={couponAllowProductTypeSelect}
                            selectedHandler={setCouponAllowProductTypeSelect}
                            labelKey={'name'}
                            customToken={(target) => {
                              return `${target.name} : ${target.description}`;
                            }}
                            options={productTypeSet}
                          />
                        </Col>
                        <Col>
                          <CustomSelectInput
                            name={'can_use_at_extend_course'}
                            disabled={!isEdit}
                            value={coupon.can_use_at_extend_course || ''}
                            label={'강의연장유무'}
                            tooltip_desc={"'강의 연장하기'에서 해당 쿠폰을 사용 못하도록 설정"}
                            is_required={true}
                            onChange={onInputChange}
                            options={[
                              {
                                id: 0,
                                name: '불가',
                              },
                              {
                                id: 1,
                                name: '가능',
                              },
                            ]}
                          />
                        </Col>
                      </Row>

                      {['커스텀선택', '베어유 강의'].map((type) => {
                        const isCustomSelect = type === '커스텀선택';
                        const element = couponAllowProductTypeSelect.find(
                          (element) => element?.name === type,
                        );

                        return (
                          element && (
                            <React.Fragment key={type}>
                              {isCustomSelect && (
                                <Row>
                                  <Col md='12 mb-3'>
                                    <CustomTypeahead
                                      disabled={!!Number(props.match.params.coupon_id)}
                                      multiple={true}
                                      label={'대상 패키지들'}
                                      tooltip_desc={
                                        '신규 등록시에만 입력 가능합니다, 수정되지 않으므로 반드시 확인하세요.\n(입력 항목이 없으면 자동으로 모든 패키지를 구매할 수 있도록 설정됩니다.)'
                                      }
                                      selected={productSelect}
                                      selectedHandler={setProductSelect}
                                      onTokenClick={(target) => {
                                        window.open('/product/' + target.id, '_blank');
                                      }}
                                      labelKey={'combine_name'}
                                      customToken={(target) => `${target.combine_name}`}
                                      options={productSet}
                                    />
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                <Col md='12 mb-3'>
                                  <CustomTypeahead
                                    disabled={!!Number(props.match.params.coupon_id)}
                                    multiple={true}
                                    label={'대상 강의들'}
                                    tooltip_desc={
                                      '신규 등록시에만 입력 가능합니다, 수정되지 않으므로 반드시 확인하세요.\n(입력 항목이 없으면 자동으로 모든 강의를 구매할 수 있도록 설정됩니다.)'
                                    }
                                    selected={courseSelect}
                                    selectedHandler={setCourseSelect}
                                    onTokenClick={(target) => {
                                      window.open('/course/' + target.id, '_blank');
                                    }}
                                    labelKey={'title'}
                                    customToken={(target) => `${target.title}`}
                                    options={courseSet}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                        );
                      })}
                    </Col>
                  </Row>
                  <Row>
                    {Number(props.match.params.coupon_id) ? (
                      <Col md='12'>
                        <div className='text-right'>
                          생성일 : {moment(new Date(coupon.create_time)).format('YYYY-MM-DD') || ''}
                        </div>
                        <div className='text-right'>
                          본 쿠폰이{' '}
                          <span className={'text-seconary f-w-900'}>
                            {coupon.coupon_log_set?.length}
                          </span>
                          회 사용 되었습니다.
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            flexWrap: 'wrap',
                          }}
                        >
                          {coupon.coupon_log_set &&
                            countCouponLogUser(coupon.coupon_log_set)
                              .slice(0, isMore)
                              .map(([account_name, { account_id, count }]) => {
                                return (
                                  <div className={'badge'}>
                                    <Link to={`/account/${account_id}`}>{account_name}</Link> :{' '}
                                    {count}회
                                  </div>
                                );
                              })}
                        </div>
                        {coupon.coupon_log_set?.length > isMore && (
                          <div
                            className={'text-right f-w-900'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setIsMore(coupon.coupon_log_set?.length);
                            }}
                          >
                            ▼ 더보기
                          </div>
                        )}
                      </Col>
                    ) : (
                      ''
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CouponDetail;
