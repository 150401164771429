import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import { CATEGORY_COURSE, CATEGORY_PACKAGE } from '../../../constant/options';
import CompanyCourseTable from './CompanyCourseTable';
import CompanyModel from '../../../model/CompanyModel';
import Loading from '../../_common/Loading';
import CourseOrProductEditModal from './CourseOrProductEditModal';
import ProductProgressModal from './ProductProgressModal';
import { lang } from '../../../lang';
import { useSelector } from 'react-redux';

/*
    props
    is_new_company: bool        기업등록 전의 상태 -> true
 */
const CompanyCourseList = (props) => {
  const AuthStore = useSelector((state) => state.AuthStore);
  const { params } = useParameters();
  const { showAlert } = useShowDialog();
  const temporary_reducer = useSelector((state) => state.temporary_reducer);

  // state
  const [course_set, setCourseSet] = useState([]);
  const [product_set, setProductSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [is_toggle_edit_modal, setIsToggleEditModal] = useState(false);
  const [is_toggle_progress_modal, setIsToggleProgressModal] = useState(false);
  const [category, setCategory] = useState(CATEGORY_COURSE);
  const [course_or_product_info, setCourseOrProductInfo] = useState();
  const [product_info, setProductInfo] = useState();
  const [need_update, setNeedUpdate] = useState(false);

  // ref
  const company_id = useRef(params.company_id);
  const is_mount = useRef(false);

  useEffect(() => {
    is_mount.current = true;

    updateUi();
    setNeedUpdate(false);

    return () => {
      is_mount.current = false;
    };
  }, [need_update]);

  const updateUi = async () => {
    setIsLoading(true);

    // 기업의 클래스 목록 가져오기 API
    if (!props.is_new_company) {
      const company_course_res = await CompanyModel.getCompanyCourse(company_id.current);
      if (company_course_res.code === 200) {
        if (is_mount.current) {
          setCourseSet(company_course_res.data.course_set);
        }
      } else {
        showAlert({
          title: lang.request.request_error_title,
          text: company_course_res.message,
        });
      }

      // 기업의 패키지 목록 가져오기 API
      const company_product_res = await CompanyModel.getCompanyPackage(company_id.current);
      if (company_product_res.code === 200) {
        if (is_mount.current) {
          setProductSet(company_product_res.data.product_set);
        }
      } else {
        showAlert({
          title: lang.request.request_error_title,
          text: company_product_res.message,
        });
      }
    } else {
      temporary_reducer.temp_course_set && setCourseSet(temporary_reducer.temp_course_set);
      temporary_reducer.temp_product_set && setProductSet(temporary_reducer.temp_product_set);
    }

    if (is_mount.current) {
      setIsLoading(false);
    }
  };

  // 날짜 수정, 클래스 || 패키지 추가 용 모달(edit 모달)열기 func
  const openEditModal = (course_or_product) => {
    if (course_or_product) {
      setCourseOrProductInfo(course_or_product);
    } else {
      setCourseOrProductInfo();
    }

    toggleEditModal();
  };

  // edit모달 열고 닫기 func
  const toggleEditModal = () => {
    setIsToggleEditModal(!is_toggle_edit_modal);

    if (is_toggle_edit_modal) {
      // 모달 닫힌 상태
      setCourseOrProductInfo();
    }
  };

  // 패키지의 진행률 그래프 모달(progress 모달) 열기 func
  const openProductProgressModal = (product) => {
    setProductInfo(product);

    toggleProductModal();
  };

  // progress 모달 열고 닫기 용
  const toggleProductModal = () => {
    setIsToggleProgressModal(!is_toggle_progress_modal);

    if (is_toggle_progress_modal) {
      // 모달 닫힌 상태
      setProductInfo();
    }
  };

  return (
    <>
      <Row>
        <Col className={'text-left'}>
          <Input
            type='select'
            className='col-md-2 text-left custom-select'
            style={{
              fontWeight: 'bold',
              height: '3em',
            }}
            defaultValue={category}
            onChange={(e) => setCategory(Number(e.currentTarget.value))}
          >
            <option value={CATEGORY_COURSE}>클래스</option>
            <option value={CATEGORY_PACKAGE}>패키지</option>
          </Input>
        </Col>
        {AuthStore.account_type_id === 5 && (
          <Col md='4 text-right'>
            <Button
              className='btn-pill btn-air-dark'
              color='dark'
              style={{
                padding: '5px 20px 5px 20px',
              }}
              onClick={() => {
                openEditModal();
              }}
            >
              <span className='f-16'>추가</span>
            </Button>
          </Col>
        )}
      </Row>
      <div className='table-responsive'>
        {isLoading ? (
          <div className='text-center'>
            <Loading isLoading={isLoading} custom_style={{ display: 'flex' }} is_card_body={true} />
          </div>
        ) : (
          <CompanyCourseTable
            category={category}
            course_set={course_set}
            product_set={product_set}
            onSelect={
              category === CATEGORY_COURSE
                ? openEditModal
                : props.is_new_company
                  ? openEditModal
                  : openProductProgressModal
            }
          />
        )}
      </div>

      <CourseOrProductEditModal
        course_or_product_info={course_or_product_info}
        is_toggle_edit_modal={is_toggle_edit_modal}
        toggleModal={openEditModal}
        toggleProductModal={toggleProductModal}
        category={category}
        setNeedUpdate={setNeedUpdate}
        course_set={course_set}
        product_set={product_set}
        is_new_company={props.is_new_company}
      />

      {product_info && (
        <ProductProgressModal
          product_info={product_info}
          is_toggle_progress_modal={is_toggle_progress_modal}
          toggleModal={toggleProductModal}
          openEditModal={openEditModal}
        />
      )}
    </>
  );
};

export default CompanyCourseList;
