import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Table,
} from 'reactstrap';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import moment from 'moment';
import { initComma, toQueryString } from '../../util/common';
import Rating from 'react-rating';
import CourseModel from '../../model/CourseModel';
import queryString from 'query-string';
import CustomPagination from '../_common/CustomPagination';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';
import StatusModal from '../_common/component/StatusModal';
import { Link } from 'react-router-dom';
import { CSVLink, CSVDownload } from 'react-csv';
import PurchaseModel from '../../model/PurchaseModel';

const CourseReview = (props) => {
  const params = props.location.state || queryString.parse(props.location.search) || {};
  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: 20,
    search_text: params.search_text ? params.search_text : '',
    reply_status: params.reply_status ? params.reply_status : 1,
    order_key: params.order_key ? params.order_key : 'create_time',
    order_type: params.order_type ? params.order_type : 'desc',
  });
  const [review_set, setReviewSet] = useState([]);
  const [review_count, setReviewCount] = useState(0);
  const [reply_status_set, setReplyStatusSet] = useState([]);
  const [filter_set, setFilterSet] = useState(default_filter.current);
  const [isLoading, setIsLoading] = useState(false);
  const [delete_toggle, setDeleteToggle] = useState(false);
  const [all_checked, setAllChecked] = useState(false);
  const [update_id_set, setUpdateIdSet] = useState([]);

  const initData = () => {
    CourseModel.getReviewList({
      size: 20,
      ...params,
    }).then(({ review_set, review_count }) => {
      setReviewSet(review_set);
      setReviewCount(review_count);
    });
  };

  useEffect(() => {
    setFilterSet(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    CommonModel.staticCode({ code_name: 'reply_status_set' }).then(({ code_set }) => {
      setReplyStatusSet(code_set);
    });
    initData();
  }, [props.location.search]);

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      order_key: order_key,
      order_type: filter_set.order_type === 'asc' ? 'desc' : 'asc',
    };

    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }
    pushHistory(newFilterSet);
  };

  const onSubmit = () => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: 'create_time',
      order_type: 'desc',
    });
  };

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
    });

    window.scrollTo(0, 0);
  };

  const onReplyTypeChange = (e) => {
    setUpdateIdSet([]);
    setAllChecked(false);
    setDeleteToggle(false);
    pushHistory({
      ...filter_set,
      reply_status: e.target.value,
      page: 1,
      order_key: 'create_time',
      order_type: 'desc',
    });
  };

  const statusUpdateSubmit = async (e, review_id) => {
    setIsLoading(true);
    const status_id = e.target.value;
    const { update_review_id_set } = await CourseModel.putReviewStatus([review_id], status_id);
    if (update_review_id_set.length > 0) {
      initData();
    }
    setIsLoading(false);
  };

  const onClickToggleBtn = () => {
    setUpdateIdSet([]);
    setAllChecked(false);
    setReviewSet((prevState) => {
      return [
        ...prevState.map((elem) => {
          elem.checked = false;
          return elem;
        }),
      ];
    });
    setDeleteToggle(!delete_toggle);
  };

  const allChecked = (checked) => {
    const id_set = [];
    if (checked) {
      setReviewSet((prevState) => {
        return [
          ...prevState.map((elem) => {
            id_set.push(elem.id);
            elem.checked = checked;
            return elem;
          }),
        ];
      });
    } else {
      id_set.splice(0);
      setReviewSet((prevState) => {
        return [
          ...prevState.map((elem) => {
            elem.checked = checked;
            return elem;
          }),
        ];
      });
    }
    setUpdateIdSet(id_set);
    setAllChecked(checked);
  };
  const [is_download, setDownload] = useState(false);
  const [waitCsv, setWaitCsv] = useState(false);

  const csvRequest = async (e) => {
    setWaitCsv(true);
    let csvData2 = [];

    CourseModel.getReviewList({
      order_key: filter_set.order_key,
      order_type: filter_set.order_type,
      reply_status: filter_set.reply_status,
      search_text: filter_set.search_text,
      size: 999999,
      page: 1,
    }).then((result) => {
      csvData2 = result.review_set;
      console.log(csvData2);
      CommonModel.csvDownload(csvData2);
      setWaitCsv(false);
    });
  };

  return (
    <Fragment>
      <Breadcrumb parent='강의' title='리뷰 목록' />
      <Container fluid={true}>
        <Col className='text-right mb-4'>
          <Row>
            <Col style={{ margin: 'auto' }} className='text-right'>
              {!delete_toggle ? (
                <Button className='btn-pill' color='primary' onClick={onClickToggleBtn}>
                  일괄 선택 변경
                </Button>
              ) : (
                <>
                  <StatusModal
                    reply_status_set={reply_status_set}
                    update_id_list={update_id_set}
                    type={'review'}
                    redirect_url={'/course/review'}
                  />
                  <Button className='btn-pill' color='primary' onClick={onClickToggleBtn}>
                    취소
                  </Button>
                </>
              )}
              {waitCsv ? (
                <div className='btn btn-primary btn-pill ml-2 btn btn-info min-w-44'>
                  <Loading isLoading={true} type='loader-white' />
                </div>
              ) : (
                <button
                  type='button'
                  className='btn btn-primary btn-pill ml-2 btn btn-info'
                  onClick={csvRequest}
                >
                  CSV 다운로드
                </button>
              )}
            </Col>
          </Row>
        </Col>
        <Card>
          <CardHeader>
            <Row style={{ marginBottom: '-20px' }}>
              <Col sm={7}>
                <p style={{ marginTop: '7px' }}>
                  총: <span style={{ color: 'coral' }}>{initComma(review_count)}</span>개
                  <Loading isLoading={isLoading} />
                </p>
              </Col>
              <Col className='text-right'>
                <Row>
                  <Col sm={3}>
                    <Input
                      type='select'
                      name='reply_status'
                      className='form-control'
                      onChange={(e) => {
                        onReplyTypeChange(e);
                      }}
                      value={filter_set.reply_status}
                    >
                      <option value={0}>전체</option>
                      {reply_status_set.map((status) => {
                        return (
                          <option key={status.id} value={status.id}>
                            {status.name}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          className='form-control'
                          type='text'
                          name='search_text'
                          onChange={(e) => {
                            setFilterSet((prevState) => ({
                              ...prevState,
                              search_text: e.target.value,
                            }));
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              onSubmit(e);
                            }
                          }}
                          value={filter_set.search_text}
                          placeholder='찾고자 하는 강의명, 유저ID, 닉네임중 하나를 입력하세요.'
                        />
                        <InputGroupAddon
                          addonType='append'
                          style={{ cursor: 'pointer' }}
                          onClick={onSubmit}
                        >
                          <InputGroupText>
                            <i className='icofont icofont-ui-search' />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Table>
              <thead>
                <tr style={{ textAlign: 'center' }}>
                  {delete_toggle && (
                    <th className='table-sort-th' onClick={(e) => allChecked(!all_checked)}>
                      {all_checked ? (
                        <i className='typcn typcn-tick'></i>
                      ) : (
                        <i className='typcn typcn-tick-outline'></i>
                      )}
                    </th>
                  )}
                  <th style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}>ID</th>
                  <th
                    style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                    className='table-sort-th'
                    onClick={() => {
                      onOrderChange('parent_count');
                    }}
                  >
                    {filter_set.order_key === 'parent_count' ? (
                      <span
                        style={{
                          color: '#ff4c3b',
                          minWidth: '90px',
                          maxWidth: '90px',
                          width: '90px',
                        }}
                      >
                        답변여부
                      </span>
                    ) : (
                      <span style={{ minWidth: '90px', maxWidth: '90px', width: '90px' }}>
                        답변여부
                      </span>
                    )}
                  </th>
                  <th style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}>강의명</th>
                  <th
                    style={{
                      textAlign: 'left',
                      minWidth: '450px',
                      maxWidth: '450px',
                      width: '450px',
                    }}
                  >
                    내용
                  </th>
                  <th style={{ minWidth: '240px', maxWidth: '240px', width: '240px' }}>댓글상태</th>
                  <th style={{ width: '240px', minWidth: '240px', maxWidth: '240px' }}>
                    작성자 정보
                  </th>
                  <th style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}>댓글ID</th>
                  <th style={{ minWidth: '95px', maxWidth: '95px', width: '95px' }}>별점</th>
                  <th
                    style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                    className='table-sort-th'
                    onClick={() => {
                      onOrderChange('create_time');
                    }}
                  >
                    {filter_set.order_key === 'create_time' ? (
                      <span style={{ color: '#ff4c3b' }}>생성일</span>
                    ) : (
                      <span>생성일</span>
                    )}
                  </th>
                  <th
                    style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                    className='table-sort-th'
                    onClick={() => {
                      onOrderChange('update_time');
                    }}
                  >
                    {filter_set.order_key === 'update_time' ? (
                      <span style={{ color: '#ff4c3b' }}>수정일</span>
                    ) : (
                      <span>수정일</span>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {review_set
                  ? review_set.map((review, index) => {
                      return (
                        <tr key={index} style={{ textAlign: 'center' }}>
                          {delete_toggle && (
                            <td>
                              <FormGroup className='custom-radio-ml'>
                                <div className='checkbox checkbox-primary'>
                                  <Input
                                    id={'checkbox-primary-' + review.id}
                                    name='delete_id_set'
                                    checked={review.checked ? review.checked : false}
                                    type='checkbox'
                                    value={review.id}
                                    onChange={(e) => {
                                      setReviewSet((prevState) => {
                                        const state = prevState[index];
                                        state.checked = e.target.checked;
                                        return [...prevState];
                                      });
                                      if (e.target.checked) {
                                        const new_update_id_set = update_id_set;
                                        new_update_id_set.push(review.id);
                                        setUpdateIdSet(new_update_id_set);
                                      } else {
                                        const new_update_id_set = update_id_set.filter(
                                          (elem) => elem !== review.id,
                                        );
                                        setUpdateIdSet(new_update_id_set);
                                      }
                                    }}
                                  />
                                  <Label for={'checkbox-primary-' + review.id} />
                                </div>
                              </FormGroup>
                            </td>
                          )}
                          <td
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '70px',
                              maxWidth: '70px',
                            }}
                          >
                            {review.id}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '90px',
                              maxWidth: '90px',
                            }}
                          >
                            {review.is_completed ? (
                              <span className='badge badge-pill badge-primary'>완료</span>
                            ) : (
                              <span className='badge badge-pill badge-info'>대기</span>
                            )}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '200px',
                              maxWidth: '200px',
                            }}
                          >
                            <Link
                              style={{
                                marginBottom: 0,
                                fontWeight: '600',
                                maxWidth: '200px',
                                minWidth: '200px',
                              }}
                              target={'_blank'}
                              to={'/course/' + review.course}
                            >
                              {review.course_title}
                            </Link>
                          </td>
                          <td
                            style={{
                              textAlign: 'left',
                              cursor: 'pointer',
                              verticalAlign: 'middle',
                              minWidth: '450px',
                              maxWidth: '450px',
                            }}
                            onClick={() => {
                              props.history.push('/course/review/' + review.id);
                            }}
                          >
                            {review.contents}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '240px',
                              maxWidth: '240px',
                            }}
                          >
                            <Input
                              type='select'
                              disabled={delete_toggle}
                              name='reply_status'
                              className='form-control form-control-sm'
                              onChange={(e) => {
                                statusUpdateSubmit(e, review.id);
                              }}
                              value={review.reply_status}
                            >
                              {reply_status_set.map((status) => {
                                return (
                                  <option key={status.id} value={status.id}>
                                    {status.description}
                                  </option>
                                );
                              })}
                            </Input>
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '240px',
                              maxWidth: '240px',
                            }}
                          >
                            <Link
                              className='text-truncate'
                              style={{
                                display: 'block',
                                marginBottom: 0,
                                fontWeight: '600',
                                maxWidth: '215px',
                                minWidth: '215px',
                              }}
                              target={'_blank'}
                              to={'/account/' + review.account}
                            >
                              {review.account_name}
                            </Link>
                            이름: {review.name}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '70px',
                              maxWidth: '70px',
                            }}
                          >
                            {review.parent_course_review ? (
                              <Link
                                className='text-truncate'
                                style={{
                                  display: 'block',
                                  marginBottom: 0,
                                  fontWeight: '600',
                                }}
                                target={'_blank'}
                                to={'/course/review/' + review.parent_course_review}
                              >
                                {review.parent_course_review}
                              </Link>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '95px',
                              maxWidth: '95px',
                            }}
                          >
                            <Rating
                              initialRating={review.rating}
                              emptySymbol='typcn typcn-star-outline'
                              fullSymbol='typcn typcn-star'
                              readonly
                            />
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '100px',
                              maxWidth: '100px',
                            }}
                          >
                            {moment(review.create_time).format('YYYY.MM.DD')}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '100px',
                              maxWidth: '100px',
                            }}
                          >
                            {!review.update_time
                              ? '-'
                              : moment(review.update_time).format('YYYY.MM.DD')}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </div>
          <div className='m-auto'>
            <CustomPagination
              current_page={params.page ? Number(params.page) : 1}
              max_page={Math.ceil(params.size ? review_count / params.size : review_count / 20)}
              onPageChange={onPageChange}
            />
          </div>
        </Card>
      </Container>
    </Fragment>
  );
};

export default CourseReview;
