import { ADD_TEMPORARY_VALUE, CLEAR_TEMPORARY } from '../../action_type';

const init_state = {};

export default (state = init_state, action) => {
  switch (action.type) {
    case ADD_TEMPORARY_VALUE:
      return {
        ...state,
        [action.key]: action.value,
      };

    case CLEAR_TEMPORARY:
      return init_state;

    default:
      return state;
  }
};
