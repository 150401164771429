import React, { useEffect, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, Label, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import {
  BROADCAST_EDITABLE,
  BROADCAST_ON_SELECT_MANAGER_ACCOUNT,
  useApplicationContext,
} from '../../../context/ApplicationContextProvider';
import ManagerAddModal from '../../b2b_company/component/ManagerAddModal';
import CustomTooltip from './CustomTooltip';
import { useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import { useSelector } from 'react-redux';
import ManagerRemoveModal from '../../b2b_company/component/ManagerRemoveModal';
import { lang } from '../../../lang';

const CustomTypeahead2 = ({
  id = 'class-typeahead',
  defaultSelected = [],
  labelKey,
  multiple = false,
  options = [],
  label_title = null,
  is_require = false,
  on_change = null,
  need_edit_button = false,
  edit_button_title = null,
  placeholder = null,
  with_edit_button = false,
  disabled = false,
  need_tool_tip = false,
  tool_tip_content = null,
  company_info = null,
}) => {
  const { broadcast } = useApplicationContext();
  const { params } = useParameters();
  const { showAlert } = useShowDialog();
  const temporary_reducer = useSelector((state) => state.temporary_reducer);
  const AuthStore = useSelector((state) => state.AuthStore);

  const [is_editable, setEditable] = useState(with_edit_button);
  const [is_choice_modal_open, setIsChoiceModalOpen] = useState(false);
  const [is_add_modal_open, setIsAddModalOpen] = useState(false);
  const [is_remove_modal_open, setIsRemoveModalOpen] = useState(false);

  const typeahead_ref = useRef();
  const company_id = useRef(params.company_id);

  useEffect(() => {
    const unlisten = broadcast.listen([BROADCAST_EDITABLE], ({ value }) => {
      // EditButton에서 취소버튼을 누르면 초기화 및 편집 취소
      if (value.is_cancel_button_click) {
        // on_change(defaultSelected);
        typeahead_ref.current.value = defaultSelected;
        typeahead_ref.current.state.selected = defaultSelected;
      }
      setEditable(value.disable_input);
    });

    return () => {
      broadcast.unlisten(unlisten);
    };
  }, [broadcast, defaultSelected]);

  useEffect(() => {
    // ManagerEditModal에서 선택한 manager_account를 표시
    const unlisten = broadcast.listen([BROADCAST_ON_SELECT_MANAGER_ACCOUNT], ({ value }) => {
      typeahead_ref.current.value = value;
      typeahead_ref.current.state.selected = value;
    });

    return () => {
      broadcast.unlisten(unlisten);
    };
  });

  useEffect(() => {
    // 계정 직접 입력으로 페이지 이동 -> 돌아왔을 때 typeahead값을 그대로 유지
    if (!company_id.current) {
      if (temporary_reducer.temp_manager_account_name_set) {
        typeahead_ref.current.value = temporary_reducer.temp_manager_account_name_set;
        typeahead_ref.current.state.selected = temporary_reducer.temp_manager_account_name_set;
      }
    }
  }, [temporary_reducer.temp_manager_account_name_set, company_id.current]);

  const toggleManagerChoiceModal = () => {
    setIsChoiceModalOpen(!is_choice_modal_open);
  };

  const toggleManagerAddModal = () => {
    setIsAddModalOpen(!is_add_modal_open);
  };

  const toggleManagerRemoveModal = () => {
    setIsRemoveModalOpen(!is_remove_modal_open);
  };

  return (
    <>
      {label_title && (
        <div
          style={{
            position: 'relative',
          }}
        >
          <Label id={'typeahead-label'}>
            {need_tool_tip && <i style={{ marginRight: '5px' }} className='icon-help-alt' />}
            {is_require && (
              <span
                style={{
                  color: '#FF0000',
                }}
              >
                *&nbsp;
              </span>
            )}
            <b>{label_title}</b>
          </Label>
          {need_tool_tip && <CustomTooltip target='typeahead-label' content={tool_tip_content} />}
          {need_edit_button && AuthStore.account_type_id === 5 && (
            <Button
              style={{
                position: 'absolute',
                top: '-5px',
                right: '0',
                marginRight: '.5em',
                padding: '4px 15px',
              }}
              className='btn-pill btn-air-info'
              color='info'
              onClick={() => {
                if (company_id.current) {
                  toggleManagerChoiceModal();
                } else {
                  toggleManagerAddModal();
                }
              }}
            >
              {edit_button_title}
            </Button>
          )}
        </div>
      )}
      <Typeahead
        id={id}
        defaultSelected={defaultSelected}
        labelKey={labelKey}
        multiple={multiple}
        disabled={disabled ? disabled : is_editable}
        options={options}
        onChange={(e) => on_change(e)}
        ref={typeahead_ref}
        placeholder={placeholder}
      />

      <Modal isOpen={is_choice_modal_open} toggle={toggleManagerChoiceModal} centered>
        <ModalHeader>편집 유형을 선택해주세요</ModalHeader>
        <ModalFooter
          style={{
            textAlign: 'center',
          }}
        >
          <Button
            className='btn-pill'
            color='info'
            onClick={toggleManagerAddModal}
            style={{
              padding: '0.8em 2.5em',
              marginLeft: 'auto',
            }}
          >
            관리자 계정 추가하기
          </Button>
          <Button
            className='btn-pill'
            color='danger'
            style={{
              padding: '0.8em 2.5em',
              marginRight: 'auto',
            }}
            onClick={() => {
              if (company_info.manager_account_set.length <= 1) {
                showAlert({
                  title: lang.company.error_company_account_more_than_one,
                  text: lang.company.need_add_company_manager,
                });
              } else {
                toggleManagerRemoveModal();
              }
            }}
          >
            관리자 계정 제거하기
          </Button>
        </ModalFooter>
      </Modal>
      <ManagerAddModal
        is_add_modal_open={is_add_modal_open}
        toggleManagerAddModal={toggleManagerAddModal}
        toggleParentModal={toggleManagerChoiceModal}
        company_info={company_info}
      />
      <ManagerRemoveModal
        is_remove_modal_open={is_remove_modal_open}
        toggleManagerRemoveModal={toggleManagerRemoveModal}
        toggleParentModal={toggleManagerChoiceModal}
        company_info={company_info}
      />
    </>
  );
};

export default CustomTypeahead2;
