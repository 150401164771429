import React, { useState } from 'react';
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap';
import TabContent from 'reactstrap/es/TabContent';
import TabPane from 'reactstrap/es/TabPane';

// props (ex)
//[{
//    id: "1", // int
//    name: "결제 정보", // str
//    icon: <i className="icofont icofont-ui-home"/>, // icon
//    component: AccountPurchaseInfo // Component Function
//    component_props: Component에 보낼 Props
//}, ...]

const Tab = (props) => {
  const [basic_item_set] = useState(props.tab_list);
  const [basic_line_tab, setBasicLineTab] = useState('1');

  return (
    <Card className='input-air-primary'>
      <CardBody>
        <Nav className='border-tab-primary' tabs>
          {basic_item_set.map((item) => {
            return (
              <NavItem key={item.id} style={{ width: '50%' }}>
                <NavLink
                  role='button'
                  className={basic_line_tab === item.id ? 'active' : ''}
                  onClick={() => setBasicLineTab(item.id)}
                >
                  {item.icon}
                  {item.name}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>

        <TabContent activeTab={basic_line_tab}>
          {basic_item_set.map((item) => {
            return (
              <TabPane key={item.id} className='fade show' tabId={item.id}>
                <div className='mb-0 m-t-30'>
                  <item.component
                    obj_id={props.obj_id}
                    tab_id={item.id}
                    additionalProps={item.component_props}
                    activeTab={basic_line_tab}
                  />
                </div>
              </TabPane>
            );
          })}
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default Tab;
