import React, { useRef } from 'react';
import { CardBody, Col, Form } from 'reactstrap';
import {
  REGEX_BIRTH,
  REGEX_EMAIL,
  REGEX_NAME,
  REGEX_PASSWORD,
  REGEX_PHONE,
} from '../../../constant/regexs';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { dialog_type_confirm, useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import { lang } from '../../../lang';
import { ADD_ACCOUNT_GENDER_SELECT_OPTIONS } from '../../../constant/options';
import CustomInput2 from '../../_common/component/CustomInput2';
import CustomSelect2 from '../../_common/component/CustomSelect2';
import CompanyModel from '../../../model/CompanyModel';
import { addTemporaryValue } from '../../../redux/b2b_store/temporary/actions';

/*
    props
    setIsLoading: setState(bool)       AddAccount.js의 isLoading 변경용 setState
    is_new_company: state(bool)        기업등록 이전의 상태 -> true
    can_resist_employee_count: int     등록가능한 직원 수
    setNeedUpdate: setState(bool)      api호출 이후 업데이트 필요 -> setState(true)
 */
const AddAccountForm = (props) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const { params } = useParameters();
  const { showAlert } = useShowDialog();
  const dispatch = useDispatch();
  const temporary_reducer = useSelector((state) => state.temporary_reducer);

  // ref
  const company_id = useRef(params.company_id);
  const profile = useRef({});

  const checkMaximumEmployee = () => {
    if (!props.can_resist_employee_count) {
      showAlert({
        title: lang.employee.over_max_count_title,
        text: lang.request.request_to_manager,
      });
      reset({ register });
      profile.current = {};
      return false;
    }
    return true;
  };

  const onAddEmployee = async (profile) => {
    if (props.is_new_company) {
      // 기업 등록 전이면 중복체크 후 temporary_reducer에 저장
      const duplicate_employee_check_res = await CompanyModel.getDuplicateEmployeeCheck(
        profile.account_name,
      );

      if (duplicate_employee_check_res.code === 200) {
        let new_employee_set = temporary_reducer?.temp_employee_set || [];
        new_employee_set.push({
          ...profile,
          phone: profile.phone.replace(/[-]/g, ''),
        });
        dispatch(addTemporaryValue('temp_employee_set', new_employee_set));

        return true;
      } else {
        showAlert({
          title: duplicate_employee_check_res.message,
        });
        return false;
      }
    } else {
      // 직원등록 API
      const add_employee_res = await CompanyModel.postEmployee(company_id.current, {
        ...profile,
        phone: profile.phone.replace(/[-]/g, ''),
      });

      if (add_employee_res.code === 200) {
        return true;
      } else {
        showAlert({
          title: add_employee_res.message,
        });
        return false;
      }
    }
  };

  const onSubmit = (data) => {
    profile.current = data;

    if (checkMaximumEmployee()) {
      showAlert({
        type: dialog_type_confirm,
        title: lang.common.confirm_resist,
        text: lang.employee.check_one_more,
        showCancelButton: true,
        confirmButtonText: lang.common.confirm_button_resist,
        true_fn: async () => {
          props.setIsLoading(true);

          if (await onAddEmployee(profile.current)) {
            showAlert({
              title: lang.common.success_resist_message_common,
              icon: 'success',
            });
            reset({ register });
          }

          props.setIsLoading(false);

          // can_resist_employee_count 업데이트를 위한 setNeedUpdate
          props.setNeedUpdate(true);
        },
      });
    }
  };

  return (
    <>
      <div id='add-account-form-submit-btn' onClick={handleSubmit(onSubmit)} />
      <CardBody
        style={{
          padding: '30px',
        }}
      >
        <Form className='theme-form' id='account-form' onSubmit={handleSubmit(onSubmit)}>
          <div className='form-row'>
            <Col md='3'>
              <CustomInput2
                label_title='이름'
                is_require={true}
                name='name'
                placeholder='이름'
                innerRef={register({
                  required: lang.employee.need_fill_all_form,
                  pattern: {
                    value: REGEX_NAME,
                    message: lang.employee.check_name_desc,
                  },
                })}
              />
              <span className='f-12 txt-danger'>{errors.name && errors.name.message}</span>
            </Col>
            <Col md='4'>
              <CustomInput2
                label_title='이메일(계정)'
                is_require={true}
                name='account_name'
                placeholder='이메일을 입력해주세요'
                innerRef={register({
                  required: lang.employee.need_fill_all_form,
                  pattern: {
                    value: REGEX_EMAIL,
                    message: lang.employee.check_email_desc,
                  },
                })}
              />
              <span className='f-12 txt-danger'>{errors.account && errors.account.message}</span>
            </Col>
            <Col md='5'>
              <CustomInput2
                label_title='비밀번호'
                is_require={true}
                id='password'
                name='password'
                type='password'
                placeholder='비밀번호'
                innerRef={register({
                  required: lang.employee.need_fill_all_form,
                  pattern: {
                    value: REGEX_PASSWORD,
                    message: lang.employee.check_password_desc,
                  },
                })}
              />
              <span className='f-12 txt-danger'>{errors.password && errors.password.message}</span>
            </Col>
            <Col md='4' className='mt-4'>
              <CustomInput2
                label_title='생년월일'
                is_require={true}
                name='birth'
                placeholder='YYYY-MM-DD'
                max_length={10}
                innerRef={register({
                  required: lang.employee.need_fill_all_form,
                  pattern: {
                    value: REGEX_BIRTH,
                    message: lang.employee.check_birth_desc,
                  },
                })}
              />
              <span className='f-12 txt-danger'>{errors.birth && errors.birth.message}</span>
            </Col>
            <Col md='3' className='mt-4'>
              <CustomSelect2
                label_title='성별'
                is_require={true}
                container='account'
                options={ADD_ACCOUNT_GENDER_SELECT_OPTIONS}
                name='gender'
                defaultValue={''}
                innerRef={register({
                  required: '필수선택사항입니다',
                })}
              />
              <span className='f-12 txt-danger'>{errors.gender && errors.gender.message}</span>
            </Col>
            <Col md='5' className='mt-4'>
              <CustomInput2
                label_title='휴대폰'
                is_require={true}
                name='phone'
                placeholder='010-0000-0000'
                max_length={13}
                innerRef={register({
                  required: lang.employee.need_fill_all_form,
                  pattern: {
                    value: REGEX_PHONE,
                    message: lang.employee.check_phone_desc,
                  },
                })}
              />
              <span className='f-12 txt-danger'>{errors.phone && errors.phone.message}</span>
            </Col>
          </div>
        </Form>
      </CardBody>
    </>
  );
};

export default AddAccountForm;
