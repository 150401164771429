import React, { Fragment, useEffect, useRef, useState } from 'react';
import CustomInput from '../../_common/component/CustomInput';
import { Button, Col, Container, Row } from 'reactstrap';
import Loading from '../../_common/Loading';
import domtoimage from 'dom-to-image';
import moment from 'moment';
import { CSVReader } from 'react-papaparse';
import { errMessageOccur, getBase64 } from '../../../util/common';
import SweetAlert from 'sweetalert2';
import CustomLabel from '../../_common/component/CustomLabel';

import certificate_bg from '../../../assets/images/certificate_bg.png';

const BearustudyCertificate = (props) => {
  const [certificate_info, setCertificateInfo] = useState({
    year: { value: '', label: '기수' },
    issue_date: { value: '', label: '발급일자' },
    activity_duration: { value: '', label: '활동기간' },
    company_name: { value: '', label: '발급기관' },
  });

  const [user_display, setUserDisply] = useState({
    user_name: 'csv.userName',
    class_name: 'csv.className',
  });
  const [user_set, setUserSet] = useState([]);
  const [log, setLog] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [scale, setScale] = useState(0);
  const [height, setHeight] = useState(0);

  const [background, setBackground] = useState(null);

  const download_certificate_dom = useRef(false);
  const pre_certificate_dom = useRef(false);
  const pre_certificate_dom_parent = useRef(false);
  const buttonRef = useRef(false);

  const certificate_img = (_ref, _scale, _color = false) => {
    return (
      <div
        ref={_ref}
        style={{
          fontFamily: 'NotoSansCJKkr-Medium, sans-serif',
          position: 'relative',
          height: '1754px',
          width: '1240px',
          background: `url(${background})`,
          transform: 'scale(' + _scale + ')',
          transformOrigin: 'top left',
        }}
      >
        <div
          className='elem-title'
          style={{
            position: 'absolute',
            left: '120px',
            top: '177px',
            letterSpacing: '31px',
            marginLeft: '17px',
            textAlign: 'center',
            width: '1000px',
            fontSize: '150px',
          }}
        >
          수료증
        </div>
        <div
          className='elem-sub-title'
          style={{
            position: 'absolute',
            left: '120px',
            top: '435px',
            letterSpacing: '3px',
            textAlign: 'center',
            width: '1000px',
            fontSize: '34px',
          }}
        >
          빡공단{' '}
          {certificate_info.year.value || (
            <span style={_color ? { color: _color } : {}}>{'{기수}'}</span>
          )}{' '}
          기 활동 수료 인증서
        </div>
        <div
          className='elem-user-name'
          style={{
            position: 'absolute',
            left: '330px',
            top: '641px',
            letterSpacing: '0px',
            textAlign: 'left',
            width: '650px',
            fontSize: '34px',
          }}
        >
          성명 : {user_display.user_name}
        </div>
        <div
          className='elem-class-name'
          style={{
            position: 'absolute',
            left: '330px',
            top: '710px',
            letterSpacing: '0px',
            textAlign: 'left',
            width: '650px',
            fontSize: '34px',
          }}
        >
          클래스명 : {user_display.class_name} 클래스
        </div>
        <div
          className='elem-activity-duration'
          style={{
            position: 'absolute',
            left: '330px',
            top: '779px',
            letterSpacing: '0px',
            textAlign: 'left',
            width: '650px',
            fontSize: '34px',
          }}
        >
          활동기간 :{' '}
          {certificate_info.activity_duration.value || (
            <span style={_color ? { color: _color } : {}}>{'{활동기간}'}</span>
          )}
        </div>
        <div
          className='elem-content'
          style={{
            position: 'absolute',
            left: '120px',
            top: '987px',
            letterSpacing: '3px',
            lineHeight: '220%',
            textAlign: 'center',
            width: '1000px',
            fontSize: '34px',
          }}
        >
          위 사람은 베어유 빡공단{' '}
          {certificate_info.year.value || (
            <span style={_color ? { color: _color } : {}}>{'{기수}'}</span>
          )}
          기로서 자기계발을 위해
          <br />
          베어유에서 주관하는 '{user_display.class_name} 클래스'의 교육과정을
          <br />
          성실히 수행하였으므로 이 증서를 수여합니다.
        </div>
        <div
          className='elem-issue-date'
          style={{
            position: 'absolute',
            left: '140px',
            top: '1485px',
            width: '265px',
            textAlign: 'center',
            fontSize: '27px',
          }}
        >
          {certificate_info.issue_date.value || (
            <span style={_color ? { color: _color } : {}}>{'{발급일자}'}</span>
          )}
        </div>
        <div
          className='elem-company-name'
          style={{
            position: 'absolute',
            left: '835px',
            top: '1485px',
            width: '265px',
            textAlign: 'center',
            fontSize: '27px',
          }}
        >
          {certificate_info.company_name.value || (
            <span style={_color ? { color: _color } : {}}>{'{발급기관}'}</span>
          )}
        </div>
      </div>
    );
  };

  const onInputChange = (e) => {
    setCertificateInfo((prevState) => ({
      ...prevState,
      [e.target.name]: { ...prevState[e.target.name], value: e.target.value },
    }));
  };

  const onCertificateDownload = (e) => {
    let obj_err_msg = {};
    let validate_value = {};

    Object.keys(certificate_info).forEach((key) => {
      validate_value[key] = certificate_info[key].value;
      obj_err_msg[key] = certificate_info[key].label;
    });

    const err_msg = errMessageOccur(validate_value, obj_err_msg);

    if (!user_set.length) {
      err_msg.push("'CSV 파일' 을 확인해주세요.");
    }

    if (!err_msg.length) {
      SweetAlert.fire({
        title: `총 ${user_set.length} 건 진행할까요?`,
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      }).then(async (result) => {
        const count_data = user_set.length;

        if (result.value) {
          setIsLoading(true);
          e.target.disabled = true;
          const _node = download_certificate_dom.current;
          setLog('1/' + count_data + ' 다운로드 중... 0 % 진행 ...\n');

          for (const [index, item] of user_set.entries()) {
            await setUserDisply(item);

            // domtoimage library used
            await domtoimage
              .toBlob(_node)
              .then(function (blob) {
                // blob to a tag
                const _a = document.createElement('a');
                _a.style = 'display: none';

                setLog(
                  index +
                    2 +
                    '/' +
                    count_data +
                    ' 다운로드 중... ' +
                    (((index + 1) / count_data) * 100).toFixed(2) +
                    ' % 진행\n',
                );

                _a.href = window.URL.createObjectURL(new Blob([blob], { type: 'image/png' }));
                _a.download = `${item.user_name}_${item.class_name}`;
                _a.click();
              })
              .catch(function (error) {
                console.error('oops, something went wrong!', error);
              });
          }

          e.target.disabled = false;
          setIsLoading(false);
          setLog(`완료 [ ${moment(new Date()).format('YYYY-MM-DD HH:mm')} ]\n`);
        }
      });
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  useEffect(() => {
    getBase64(certificate_bg, (data) => {
      setBackground(data);
    });
  }, []);

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (_data) => {
    let user_name_index;
    let class_name_index;
    let user_class_array = [];

    for (const [index, { data }] of _data.entries()) {
      if (index === 0) {
        user_name_index = data.findIndex((e) => e === 'userName');
        class_name_index = data.findIndex((e) => e === 'className');
        // userName 혹은 className 없을 경우
        if (user_name_index < 0 || class_name_index < 0) {
          if (buttonRef.current) {
            buttonRef.current.removeFile();
            setUserSet([]);
            SweetAlert.fire({
              title: '업로드 중 오류',
              text: "'userName' 과 'className' 헤더를 가진\nCSV를 업로드해주세요.",
            });
            return false;
          }
        }
      } else {
        if (data[0] && data[1]) {
          user_class_array.push({
            user_name: data[user_name_index],
            class_name: data[class_name_index],
          });
        }
      }
    }

    setUserSet(user_class_array);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    setUserSet([]);
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  // resize Pre-CertificateImg
  const onResizeCertificateImg = () => {
    setScale(pre_certificate_dom_parent.current.clientWidth / 1240);
    setHeight(
      pre_certificate_dom.current.clientHeight *
        (pre_certificate_dom_parent.current.clientWidth / 1240),
    );
  };

  useEffect(() => {
    setScale(pre_certificate_dom_parent.current.clientWidth / 1240);
    setHeight(
      pre_certificate_dom.current.clientHeight *
        (pre_certificate_dom_parent.current.clientWidth / 1240),
    );
    window.addEventListener('resize', onResizeCertificateImg);
    return () => {
      window.removeEventListener('resize', onResizeCertificateImg);
    };
  }, [props]);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md='6 mb-3'>
            <Row>
              <Col md='12 mb-3'>
                <CSVReader
                  ref={buttonRef}
                  onFileLoad={handleOnFileLoad}
                  onError={handleOnError}
                  noClick
                  noDrag
                  config={{}}
                  style={{}}
                  onRemoveFile={handleOnRemoveFile}
                >
                  {({ file }) => (
                    <>
                      <CustomLabel
                        label={'CSV'}
                        tooltip_desc={
                          <div className={'text-center'}>
                            <div className={'mb-1'}>
                              CSV UTF-8 호환
                              <br />
                              'userName', 'className' 헤더를 가진 CSV 필요
                            </div>
                            <img
                              alt={'csv_tooltip'}
                              src={
                                'https://s3.ap-northeast-2.amazonaws.com/dev-img.bear-u.com/static/png/certificate/certi_csv.png'
                              }
                            />
                          </div>
                        }
                        is_required={true}
                      />
                      <div>
                        <button
                          className={'btn btn-sm btn-light mr-3 border border-dark btn-pill'}
                          onChange={handleOpenDialog}
                          onClick={handleOpenDialog}
                          style={{ border: '1px solid' }}
                        >
                          파일 선택
                        </button>
                        {file && (
                          <>
                            <span className={'f-w-900 mr-3'}>{file.name}</span>
                            <button className={'btn-sm btn-danger'} onClick={handleRemoveFile}>
                              제거
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </CSVReader>
              </Col>
            </Row>
            {Object.keys(certificate_info).map((key, index) => {
              return (
                <Row key={index}>
                  <Col md='12 mb-3'>
                    <CustomInput
                      type={'text'}
                      name={key}
                      value={certificate_info[key].value || ''}
                      is_required={true}
                      label={certificate_info[key].label}
                      tooltip_desc={certificate_info[key].label}
                      onChange={onInputChange}
                    />
                  </Col>
                </Row>
              );
            })}
            <Row>
              <Col md='9 mb-3'>
                <CustomInput
                  type={'text'}
                  disabled={true}
                  style={{ textAlign: 'center' }}
                  name={'log'}
                  value={log || ''}
                  is_required={true}
                  label={'로그'}
                  tooltip_desc={'로그'}
                  onChange={onInputChange}
                />
              </Col>
              <Col md='3 mb-3'>
                <div className={'mb-2'}>
                  <Loading isLoading={isLoading} />
                </div>
                <Button
                  className='btn btn-primary btn-pill'
                  style={{ width: 'inherit' }}
                  onClick={onCertificateDownload}
                >
                  다운로드
                </Button>
              </Col>
            </Row>
          </Col>
          <div
            style={{
              width: '0px',
              height: '0px',
              // zIndex: 999,
              overflow: 'hidden',
            }}
          >
            {certificate_img(download_certificate_dom, 1)}
          </div>
          <Col md='6 mb-3'>
            <div style={{ height: height }} ref={pre_certificate_dom_parent}>
              {certificate_img(pre_certificate_dom, scale.toFixed(2), 'hotpink')}
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BearustudyCertificate;
