import React from 'react';

// account_type_id
// 5: admin
// 2: master
export const MENUITEMS = [
  {
    title: '홈',
    icon: <i className='pe-7s-home pe-lg' />,
    type: 'sub',
    active: true,
    account_type_id: 5,
    children: [
      { title: '홈', type: 'sub' },
      { title: '전체 대시보드', type: 'link', path: '/home', worker: false, is_done: false },
    ],
  },
  {
    title: '강의',
    icon: <i className='pe-7s-note2 pe-lg' />,
    type: 'sub',
    account_type_id: 5,
    children: [
      { title: '강의 관리', type: 'sub' },
      { title: '대시보드', type: 'link', path: '/course-dashboard', worker: false, is_done: false },
      { title: '강의 목록', type: 'link', path: '/course', worker: 3, is_done: true },
      { title: 'Q&A', type: 'link', path: '/course/qna', worker: 3, is_done: true },
      { title: '리뷰 ', type: 'link', path: '/course/review', worker: 3, is_done: true },
    ],
  },
  {
    title: '전자책',
    icon: <i className='pe-7s-note2 pe-lg' />,
    type: 'sub',
    account_type_id: 5,
    children: [
      { title: '전자책', type: 'sub' },
      { title: '전자책 목록', type: 'link', path: '/ebook', worker: 3, is_done: true },
    ],
  },
  {
    title: '상품',
    icon: <i className='pe-7s-box1 pe-lg' />,
    type: 'sub',
    account_type_id: 5,
    children: [
      { title: '상품 관리', type: 'sub' },
      { title: '패키지 목록', type: 'link', path: '/product', worker: 3, is_done: true },
      { title: '키트 목록', type: 'link', path: '/kit', worker: 3, is_done: true },
      { title: '배송 목록', type: 'link', path: '/shipping', worker: 3, is_done: true },
    ],
  },
  {
    title: '서비스',
    icon: <i className='pe-7s-config pe-lg' />,
    type: 'sub',
    account_type_id: 5,
    children: [
      { title: '서비스 관리', type: 'sub' },
      { title: '빡공단', type: 'link', path: '/bgd', worker: 3, is_done: true },
      { title: '빡공단 모집폼', type: 'link', path: '/recruit-form', worker: 3, is_done: true },
      { title: '배너 목록', type: 'link', path: '/banner', worker: 3, is_done: true },
      { title: '트리거 목록', type: 'link', path: '/trigger', worker: 3, is_done: true },
      { title: '큐레이션 목록', type: 'link', path: '/curation', worker: 3, is_done: true },
      { title: '프로모션 목록', type: 'link', path: '/promotion', worker: 3, is_done: true },
      { title: '수료증 발급', type: 'link', path: '/certificate', worker: 3, is_done: true },
      { title: '공지', type: 'link', path: '/notice', worker: 3, is_done: true },
      // { title: '빡공단', type: 'link', path: '/hardstudy', worker: 3, is_done: false },
      { title: 'FAQ', type: 'link', path: '/faq', worker: 3, is_done: true },
      { title: '알림', type: 'link', path: '/notification', worker: false, is_done: false },
      { title: '서비스 정보', type: 'link', path: '/service-info', worker: 3, is_done: true },
    ],
  },
  {
    title: '프로모션',
    icon: <i className='pe-7s-gift pe-lg' />,
    type: 'sub',
    account_type_id: 5,
    children: [
      { title: '프로모션 관리', type: 'sub' },
      { title: '수강코드 목록', type: 'link', path: '/ticket', worker: 3, is_done: true },
      {
        title: '수강코드 사용내역 목록',
        type: 'link',
        path: '/ticket-log',
        worker: 3,
        is_done: true,
      },
      { title: '쿠폰 목록', type: 'link', path: '/coupon', worker: 3, is_done: true },
      { title: '쿠폰 사용내역 목록', type: 'link', path: '/coupon-log', worker: 3, is_done: true },
      { title: '이벤트 목록', type: 'link', path: '/event', worker: 3, is_done: true },
      { title: '이벤트 쿠폰 목록', type: 'link', path: '/coupon-event', worker: 3, is_done: true },
    ],
  },
  {
    title: '사용자',
    icon: <i className='pe-7s-id' />,
    type: 'sub',
    account_type_id: 5,
    children: [
      { title: '사용자 관리', type: 'sub' },
      { title: '대시보드', type: 'link', path: '/account-dashboard', worker: 3, is_done: true },
      { title: '사용자 목록', type: 'link', path: '/account', worker: 3, is_done: true },
      {
        title: '관리용설명 목록',
        type: 'link',
        path: '/account-management-description',
        worker: 3,
        is_done: true,
      },
    ],
  },
  {
    title: '정산',
    icon: <i className='pe-7s-credit pe-lg' />,
    type: 'sub',
    account_type_id: 5,
    children: [
      { title: '정산 관리', type: 'sub' },
      { title: '강사 목록', type: 'link', path: '/invoice', worker: 1, is_done: true },
      { title: '전체정산 목록', type: 'link', path: '/invoice-total', worker: 1, is_done: true },
    ],
  },
  {
    title: '매출',
    icon: <i className='pe-7s-cash pe-lg' />,
    type: 'sub',
    account_type_id: 5,
    children: [
      { title: '매출 관리', type: 'sub' },
      { title: '대시보드', type: 'link', path: '/sale-dashboard', worker: 3, is_done: true },
      { title: '판매 목록', type: 'link', path: '/sale', worker: 3, is_done: true },
      { title: '판매정보', type: 'link', path: '/sale-info', worker: 3, is_done: true },
    ],
  },
  {
    title: 'B2B',
    icon: <i className='fa fa-building' />,
    type: 'sub',
    account_type_id: 5,
    children: [
      { title: 'B2B 관리', type: 'sub' },
      { title: '기업목록', type: 'link', path: '/company-list', worker: 3, is_done: true },
    ],
  },
];

export const MENUITEMS_MASTER = [
  {
    title: '정산',
    icon: <i className='pe-7s-credit pe-lg' />,
    type: 'sub',
    account_type_id: 5,
    active: true,
    children: [
      { title: '정산 관리', type: 'sub' },
      { title: '강사 요약 정보', type: 'link', path: '/invoice', worker: 1, is_done: true },
    ],
  },
];

export const MENUITEMS_COMPANY_MANAGER = [
  {
    title: 'B2B',
    icon: <i className='fa fa-building' />,
    type: 'sub',
    account_type_id: 5,
    active: true,
    children: [
      { title: 'B2B 관리', type: 'sub' },
      { title: '기업대시보드', type: 'link', path: '/company', worker: 3, is_done: true },
    ],
  },
];
