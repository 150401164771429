import axios from 'axios';

class PurchaseModel {
  async getShipping(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/shipping', {
      params: filter,
    });
    return data;
  }

  async putShipping(shipping) {
    const {
      data: { data },
    } = await axios.put(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/shipping', shipping);
    return data;
  }

  async putShippingStatus(shipping_id_set, status_id) {
    const {
      data: { data },
    } = await axios.put(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/shipping/status', {
      shipping_id_set: shipping_id_set,
      status_id: status_id,
    });
    return data;
  }

  async getShippingDetail(shipping_id) {
    const {
      data: { data },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/shipping/' + shipping_id,
    );
    return data;
  }

  async putShippingDetail(shipping) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/shipping/' + shipping.id,
      shipping,
    );
    return data;
  }

  async getPurchase(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase', {
      params: filter,
    });
    return data;
  }

  async getPurchaseCSV(filter) {
    return await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/csv', {
      params: filter,
      responseType: 'blob',
    });
  }

  async getPurchaseDetail(purchaseinfo_id) {
    const {
      data: {
        data: { purchaseinfo },
      },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/' + purchaseinfo_id);
    return { purchaseinfo };
  }

  async getPurchaseCourseSale(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/course-sale', {
      params: filter,
    });
    return data;
  }

  async getPurchaseProductSale(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/product-sale', {
      params: filter,
    });
    return data;
  }

  async getPurchaseKitSale(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/kit-sale', {
      params: filter,
    });
    return data;
  }

  async getPurchaseSummary() {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/summary');
    return data;
  }

  async getPurchaseBarGraph(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/bar-graph', {
      params: filter,
    });
    return data;
  }

  async getCategoryTop5(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/category-top-5', {
      params: filter,
    });
    return data;
  }

  async requestRefund(_data) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/refund', _data);
    return data;
  }

  async courseExpire(_data) {
    const {
      data: { data },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/purchase/course-expire',
      _data,
    );
    return data;
  }
}

export default new PurchaseModel();
