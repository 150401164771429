import { STORED_COURSE_NOTICE, SET_COURSE_NOTICE, CLEAR_COURSE_NOTICE } from '../../action_type';

const INIT_STATE = {
  title: '',
  content: '',
  is_enabled: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STORED_COURSE_NOTICE:
      return action.data;

    case SET_COURSE_NOTICE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case CLEAR_COURSE_NOTICE:
      return INIT_STATE;

    default:
      return { ...state };
  }
};
