import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import SweetAlert from 'sweetalert2';
import DatePicker from 'react-datepicker';

import {
  stored_ebook_info,
  set_ebook_info,
  set_ebook_sub_category,
  clear_ebook_info,
  set_ebook_body,
} from '../../../redux/ebook/info/action';
import { clear_delete_img } from '../../../redux/common/action';
import CommonModel from '../../../model/CommonModel';
import EbookModel from '../../../model/EbookModel';

import TagInput from '../atom/TagInput';
import ImageUploader from '../../_common/component/ImageUploader';
import TextEditor from '../../_common/component/TextEditor';
import CustomInput from '../../_common/component/CustomInput';
import CustomSelectInput from '../../_common/component/CustomSelectInput';
import CustomFileInput from '../../_common/component/CustomFileInput';
import CustomTypeahead from '../../_common/component/CustomTypeahead';
import Loading from '../../_common/Loading';
import CustomLabel from '../../_common/component/CustomLabel';
import { errMessageOccur } from '../../../util/common';

const Info = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // select option set
  const [category_set, setCategorySet] = useState([]);
  const [sale_status_set, setSaleStatusSet] = useState([]);

  //single typeahead (강사, 판매항목)
  const [author_set, setAuthorSet] = useState([]);
  const [author_select, setAuthorSelect] = useState([]);
  const [course_sale_set, setCourseSaleSet] = useState([]);
  const [course_sale_select, setCourseSaleSelect] = useState([]);

  const initData = () => {
    setIsLoading(true);

    EbookModel.getEbook(props.ebook_id).then(({ ebook }) => {
      props.stored_ebook_info(ebook);
      props.clear_delete_img('course_info_img');

      if (ebook.extend_sale_info) setCourseSaleSelect([ebook.sale_short_name]);
      else setCourseSaleSelect([]);
      if (ebook.author_account_id) setAuthorSelect([ebook.author_profile_name]);
      else setAuthorSelect([]);
    });

    CommonModel.getSearch('course_sale', props.ebook_id).then((data) => {
      setCourseSaleSet(data);
    });

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    CommonModel.staticCode({ code_name: 'ebook_category_set' }).then(({ code_set }) => {
      setCategorySet(code_set);
    });

    CommonModel.staticCode({ code_name: 'ebook_sale_status_set' }).then(({ code_set }) => {
      setSaleStatusSet(code_set);
    });

    CommonModel.getSearch('author').then((data) => {
      setAuthorSet(data);
    });

    if (parseInt(props.ebook_id)) {
      initData();
    } else {
      setIsEdit(true);
      setIsLoading(false);
    }

    return () => {
      props.clear_ebook_info();
      props.clear_delete_img('course_info_img');
    };
  }, []);

  const EbookInfo = props.EbookInfo;

  const validation_fields = {
    title: '제목',
    description: '설명',
    author_name: '작가명',
    total_pages: '총 페이지 수',
    pdf_url: 'PDF 파일',
    main_image_url: '메인 이미지',
  };

  const saveInfo = async (e) => {
    setIsLoading(true);

    const err_msg = errMessageOccur(EbookInfo, validation_fields, { use: false });

    let sale_status_error = false;

    if (!author_select?.length) {
      err_msg.push("'작가 계정 설정'");
    }

    if (!EbookInfo.watermark_url) {
      EbookInfo.watermark_url =
        'https://s3.ap-northeast-2.amazonaws.com/img.bear-u.com/ebook/watermark.png';
    }

    if (EbookInfo.id) {
      if (!EbookInfo.body) {
        err_msg.push("'콘텐츠 영역'");
      }
      if (!EbookInfo.main_image_url) {
        err_msg.push("'큰 메인 이미지'");
      }
      if (!err_msg.length) {
        const course = await EbookModel.putEbook(EbookInfo);
        if (course.code === 4600) {
          sale_status_error = true;
        }
      }
    } else {
      if (!err_msg.length) {
        try {
          await EbookModel.postEbook({
            title: EbookInfo.title,
            description: EbookInfo.description,
            total_pages: EbookInfo.total_pages,
            author_name: EbookInfo.author_name,
            author_account_id: EbookInfo.author_account_id,
            ebook_sale_status_id: EbookInfo.ebook_sale_status_id,
            hide_in_list: EbookInfo.hide_in_list,
            category_id: EbookInfo.category_id,
            main_image_url: EbookInfo.main_image_url,
            pdf_url: EbookInfo.pdf_url,
            watermark_url: EbookInfo.watermark_url,
            opacity: EbookInfo.opacity,
          });
          // window.location.reload();
        } catch (error) {
          console.log(error);
        }
      }
    }
    if (err_msg.length) {
      SweetAlert.fire({
        title: '아래 입력 값을 확인해주세요.',
        text: err_msg.join('\n'),
      });
    } else {
      setIsEdit(false);
    }
    if (sale_status_error) {
      setIsEdit(true);
      SweetAlert.fire({
        title: '저장을 실패하였습니다.',
        text: '판매설정을 최소 1개 이상 생성 후 강의 상태를 판매중으로 변경해주세요.',
      });
    }
    setIsLoading(false);
  };

  return (
    <Row>
      <Col lg='12 xl-100'>
        <Card className='input-air-primary'>
          <CardHeader>
            <Row>
              <Col>
                <h4>
                  전자책 정보
                  <Loading isLoading={isLoading} />
                </h4>
              </Col>
              <Col sm={6} className='text-right'>
                <Col>
                  {isEdit && (
                    <>
                      <Button
                        className='btn-pill'
                        color='primary'
                        style={{ marginRight: '8px' }}
                        onClick={saveInfo}
                      >
                        저장
                      </Button>
                    </>
                  )}
                  {EbookInfo.id && (
                    <Button
                      className={isEdit ? 'btn-pill btn btn-light' : 'btn-pill'}
                      color={isEdit ? '' : 'primary'}
                      style={{ marginRight: '8px' }}
                      onClick={() => {
                        if (isEdit) {
                          initData();
                        }
                        setIsEdit(!isEdit);
                      }}
                    >
                      {isEdit ? <>취소</> : <>편집</>}
                    </Button>
                  )}
                </Col>
              </Col>
            </Row>
          </CardHeader>

          <CardBody>
            <Row style={{ marginTop: '-10px' }}>
              <ImageUploader
                sm={3}
                img_type='ebook'
                thumbnail_width={500}
                min_width={1000}
                img_url={EbookInfo.main_image_url}
                img_thumb_url={EbookInfo.main_thumbnail_image_url}
                isEdit={!!Number(props.ebook_id) ? isEdit : true}
                id={Number(props.ebook_id)}
                height={250}
              />
              <Col>
                <Row>
                  <Col>
                    <CustomInput
                      type={'textarea'}
                      name={'title'}
                      label={'제목'}
                      is_required={true}
                      placeholder={'제목을 입력해주세요.'}
                      onChange={(e) => {
                        props.set_ebook_info(e.target.name, e.target.value);
                      }}
                      value={EbookInfo.title || ''}
                      disabled={!isEdit}
                      maxLength={100}
                    />
                  </Col>
                  <Col>
                    <CustomSelectInput
                      name={'ebook_sale_status_id'}
                      disabled={!parseInt(props.ebook_id) || !isEdit}
                      value={EbookInfo.ebook_sale_status_id || 1}
                      label={'판매 상태'}
                      is_required={true}
                      sub_option_type={'description'}
                      onChange={(e) => props.set_ebook_info(e.target.name, e.target.value)}
                      options={sale_status_set}
                    />
                  </Col>
                  <Col sm={2}>
                    <CustomSelectInput
                      name={'hide_in_list'}
                      disabled={!isEdit}
                      value={EbookInfo.hide_in_list || 0}
                      label={'리스트 숨김'}
                      is_required={true}
                      tooltip_desc={
                        '"예"로 설정하는 경우 각종 리스트에서 표시되지 않습니다. 유저가 보유하여 소유권을 주장할수 있는 리스트에서는 "예"로 설정하여도 표시됩니다. (기본값: 아니요)'
                      }
                      onChange={(e) => props.set_ebook_info(e.target.name, e.target.value)}
                      options={[
                        { name: '예', id: 1 },
                        { name: '아니오', id: 0 },
                      ]}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col>
                    <CustomInput
                      type={'textarea'}
                      name={'description'}
                      label={'설명'}
                      is_required={true}
                      placeholder={'설명을 입력해주세요.'}
                      onChange={(e) => {
                        props.set_ebook_info(e.target.name, e.target.value);
                      }}
                      value={EbookInfo.description}
                      disabled={!isEdit}
                      maxLength={100}
                    />
                  </Col>
                  <Col sm={3}>
                    <CustomInput
                      type={'number'}
                      name={'total_pages'}
                      disabled={!isEdit}
                      value={EbookInfo.total_pages || ''}
                      label={'총 페이지 수'}
                      placeholder={'총 페이지 수를 입력해주세요.'}
                      is_required={true}
                      onChange={(e) => props.set_ebook_info(e.target.name, e.target.value)}
                    />
                    <CustomInput
                      type={'text'}
                      name={'author_name'}
                      disabled={!isEdit}
                      value={EbookInfo.author_name || ''}
                      label={'작가명'}
                      placeholder={'작가명을 입력해주세요.'}
                      is_required={true}
                      onChange={(e) => props.set_ebook_info(e.target.name, e.target.value)}
                    />
                  </Col>
                  <Col sm={3}>
                    <CustomSelectInput
                      name={'category_id'}
                      disabled={!isEdit}
                      value={EbookInfo?.category_id}
                      label={'카테고리'}
                      is_required={true}
                      onChange={(e) => props.set_ebook_info(e.target.name, e.target.value)}
                      options={category_set}
                    />
                    <CustomTypeahead
                      disabled={!isEdit}
                      is_required={true}
                      multiple={false}
                      label={'작가 계정'}
                      tooltip_desc={'작가로 등록된 회원을 검색 후 선택해주세요.'}
                      selected={author_select}
                      options={author_set}
                      labelKey={'author_profile_name'}
                      placeholder={'작가를 검색해주세요.'}
                      onChange={(selected) => {
                        setAuthorSelect(selected);
                        if (selected.length > 0) {
                          props.set_ebook_info('author_account_id', selected[0].author_account_id);
                        } else {
                          props.set_ebook_info('author_account_id', null);
                        }
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
              <Col>
                <Row>
                  <Col>
                    <CustomFileInput
                      with_signed={true}
                      id={props.ebook_id}
                      name={'pdf_url'}
                      is_required={true}
                      className={'custom-file-input'}
                      resource_type={'course-lecture'}
                      disabled={parseInt(props.ebook_id) && !isEdit}
                      label={'PDF 파일'}
                      is_img={false}
                      value={EbookInfo.pdf_url}
                      propOnChange={({ url }) => {
                        props.set_ebook_info('pdf_url', url);
                      }}
                      style={{
                        fontSize: '14px',
                        color: '#898989',
                        height: 'auto',
                        paddingLeft: '20px',
                      }}
                    />
                  </Col>
                  <Col>
                    <CustomFileInput
                      with_signed={true}
                      id={props.ebook_id}
                      name={'watermark_url'}
                      className={'custom-file-input'}
                      resource_type={'course-lecture'}
                      disabled={parseInt(props.ebook_id) && !isEdit}
                      label={'워터마크 이미지(배경없는 png파일)'}
                      is_img={false}
                      value={EbookInfo.watermark_url}
                      propOnChange={({ url }) => {
                        props.set_ebook_info('watermark_url', url);
                      }}
                      style={{
                        fontSize: '14px',
                        color: '#898989',
                        height: 'auto',
                        paddingLeft: '20px',
                      }}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      type={'number'}
                      name={'opacity'}
                      disabled={!isEdit}
                      value={EbookInfo.opacity !== null ? EbookInfo.opacity.toString() : ''}
                      label={'워터마크 투명도'}
                      is_required={true}
                      min={0}
                      max={1}
                      step={0.1}
                      onChange={(e) => {
                        let inputValue = e.target.value === '' ? null : parseFloat(e.target.value);
                        if (inputValue !== null) {
                          inputValue = Math.min(1, Math.max(0, inputValue));
                        }
                        props.set_ebook_info(e.target.name, inputValue);
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '30px' }}>
                  <Col>
                    <Label>태그 설명</Label>
                    <TagInput
                      tag_set={EbookInfo.tag_set}
                      disabled={!parseInt(props.ebook_id) || !isEdit}
                    />
                  </Col>
                  <Col sm={2}>
                    <Label>계약 만료일</Label>
                    <DatePicker
                      className='form-control'
                      dateFormat='yyyy-MM-dd'
                      name='contract_expire_date'
                      selected={
                        EbookInfo.contract_expire_date
                          ? new Date(EbookInfo.contract_expire_date)
                          : null
                      }
                      onChange={(date) => {
                        props.set_ebook_info(
                          'contract_expire_date',
                          moment(date).format('YYYY-MM-DD'),
                        );
                      }}
                      disabled={!parseInt(props.ebook_id) || !isEdit}
                    />
                  </Col>
                  <Col sm={2}>
                    <Label>오픈 예정일</Label>
                    <DatePicker
                      className='form-control'
                      dateFormat='yyyy-MM-dd'
                      name='to_be_open_date'
                      selected={
                        EbookInfo.to_be_open_date ? new Date(EbookInfo.to_be_open_date) : null
                      }
                      onChange={(date) => {
                        props.set_ebook_info('to_be_open_date', moment(date).format('YYYY-MM-DD'));
                      }}
                      disabled={!parseInt(props.ebook_id) || !isEdit}
                    />
                  </Col>
                  <Col sm={4}>
                    <CustomInput
                      type={'url'}
                      name={'reservation_url'}
                      placeholder={'외부 펀딩, 판매 사이트 URL(https or http로 시작)'}
                      disabled={!parseInt(props.ebook_id) || !isEdit}
                      value={EbookInfo.reservation_url || ''}
                      label='예약 리디렉션 URL(예약단계용)'
                      tooltip_desc={'외부 펀딩, 판매 사이트 URL(https or http로 시작)'}
                      onChange={(e) => props.set_ebook_info(e.target.name, e.target.value)}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col sm={2}>
                    <CustomSelectInput
                      name={'badge_type_id'}
                      disabled={!parseInt(props.ebook_id) || !isEdit}
                      value={EbookInfo.badge_type_id || 0}
                      label={'뱃지 설정'}
                      tooltip_desc={
                        '서비스에서 해당 강의를 표시할때 \n관련 아이콘을 표시할 수 있습니다.'
                      }
                      onChange={(e) => props.set_ebook_info(e.target.name, e.target.value)}
                      options={[
                        { name: 'BEST', id: 1 },
                        { name: '없음', id: 0 },
                      ]}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col>
                    <CustomLabel label={'콘텐츠 영역'} />
                    <div className='editor-img-100'>
                      <TextEditor
                        id={props.ebook_id}
                        disabled={!parseInt(props.ebook_id) || !isEdit}
                        html_data={EbookInfo.body?.content_html ?? ''}
                        img_type={'course'}
                        onChange={(value) => {
                          props.set_ebook_body('content_html', value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col>
                    <CustomLabel label={'목차 설정'} />
                    <div className='editor-img-100'>
                      <TextEditor
                        id={props.ebook_id}
                        disabled={!parseInt(props.ebook_id) || !isEdit}
                        html_data={EbookInfo.body?.table_of_contents_html ?? ''}
                        img_type={'course'}
                        onChange={(value) => {
                          props.set_ebook_body('table_of_contents_html', value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  CourseSale: state.CourseSaleStore,
  EbookInfo: state.EbookStore,
  CommonStore: state.CommonStore,
});

const mapDispatchToProps = (dispatch) => {
  return {
    set_ebook_info: (name, value) => dispatch(set_ebook_info(name, value)),
    set_ebook_body: (name, value) => dispatch(set_ebook_body(name, value)),
    clear_ebook_info: () => dispatch(clear_ebook_info()),
    stored_ebook_info: (data) => dispatch(stored_ebook_info(data)),
    set_ebook_sub_category: (sub_category_set) =>
      dispatch(set_ebook_sub_category(sub_category_set)),
    clear_delete_img: (img_type) => dispatch(clear_delete_img(img_type)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Info);
