import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { useForm } from 'react-hook-form';
import CompanyModel from '../../../model/CompanyModel';
import { dialog_type_confirm, useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import { autoHyphenPhoneNumber } from '../../../util/b2b_utils';
import { useDispatch } from 'react-redux';
import { lang } from '../../../lang';
import { addTemporaryValue } from '../../../redux/b2b_store/temporary/actions';
import {
  BROADCAST_NEED_UPDATE,
  BROADCAST_ON_SELECT_MANAGER_ACCOUNT,
  useApplicationContext,
} from '../../../context/ApplicationContextProvider';

/*
    props
    is_add_modal_open: bool             모달의 표시 여부
    toggleManagerAddModal: fnc => void  모달의 toggle func
    toggleParentModal: func => void     편집방법 선택 모달의 toggle func
    company_info: {}                    현재 기업의 정보(기업이름, status_id, 관리자, 사용인원)
 */
const ManagerAddModal = (props) => {
  const { register, handleSubmit } = useForm();
  const { showAlert } = useShowDialog();
  const { params } = useParameters();
  const dispatch = useDispatch();
  const { broadcast } = useApplicationContext();

  const [search_result_set, setSearchResultSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const company_id = useRef(params.company_id);
  const resist_manager_set = useRef([]);
  const empty_msg = useRef(lang.search.did_not_match_manager);

  useEffect(() => {
    if (!props.is_add_modal_open) {
      // 모달을 닫으면 초기화
      setSearchResultSet([]);
      resist_manager_set.current = [];
    }
  }, [props.is_add_modal_open]);

  const onManagerSearch = async (data) => {
    setIsLoading(true);

    const search_manager_account_set = await CompanyModel.getSearchCompanyManagerAccounts(
      data.account_name,
    );

    if (search_manager_account_set.code === 200) {
      if (search_manager_account_set.data.manager_account_set) {
        setSearchResultSet(search_manager_account_set.data.manager_account_set);
      } else {
        setSearchResultSet([]);
      }
    } else {
      showAlert({
        title: search_manager_account_set.message,
      });
    }

    setIsLoading(false);
  };

  const onCheckManagerAccount = (manager_account, e) => {
    if (e.currentTarget.checked) {
      resist_manager_set.current.push(manager_account.account_name);
    } else {
      resist_manager_set.current = resist_manager_set.current.filter(
        (exist_manager_account) => manager_account.account_name !== exist_manager_account,
      );
    }
  };

  const onSubmitManagerAccount = () => {
    if (!resist_manager_set.current.length) {
      showAlert({
        title: lang.company.need_check_manager_account,
      });
    } else {
      showAlert({
        type: dialog_type_confirm,
        title: lang.common.confirm_resist,
        showCancelButton: true,
        confirmButtonText: lang.common.confirm_button_resist,
        true_fn: async () => {
          if (company_id.current) {
            // 기업 등록 후의 상태
            let exist_manager = props.company_info.manager_account_set.map((manager) => {
              return manager.account_name;
            });
            let total_manager_account_set = exist_manager.concat(resist_manager_set.current);

            const update_company_res = await CompanyModel.updateCompanyInfo(company_id.current, {
              manager_account_name_set: total_manager_account_set,
              licensed_employee_count: props.company_info.licensed_employee_count,
            });

            if (update_company_res.code === 200) {
              showAlert({
                icon: 'success',
                title: lang.common.success_resist_message_common,
              });
              props.toggleManagerAddModal();
              props.toggleParentModal();

              broadcast.send(BROADCAST_NEED_UPDATE, true);
            } else {
              showAlert({
                title: update_company_res.message,
              });
            }
          } else {
            // 기업 등록 전의 상태
            broadcast.send(BROADCAST_ON_SELECT_MANAGER_ACCOUNT, resist_manager_set.current);
            dispatch(
              addTemporaryValue('temp_manager_account_name_set', resist_manager_set.current),
            );
            props.toggleManagerAddModal();
          }
        },
      });
    }
  };

  return (
    <Modal
      isOpen={props.is_add_modal_open}
      toggle={props.toggleManagerAddModal}
      centered
      size={'lg'}
    >
      <ModalHeader>등록하고자 하는 관리자를 검색 후 선택, 저장해주세요</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={7} />
          <Col md={4} className={'m-b-10'}>
            <span
              style={{
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              기업관리자 계정(이메일) 검색
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={7} />
          <Col md={4} className={'text-right'}>
            <Form className='theme-form' onSubmit={handleSubmit(onManagerSearch)}>
              <div className='form-row'>
                <Input
                  type={'text'}
                  name={'account_name'}
                  innerRef={register({ required: true })}
                />
              </div>
            </Form>
          </Col>
          <Col
            className={'text-left'}
            style={{
              padding: 0,
            }}
          >
            <Button
              className={'btn-pill'}
              color={'success'}
              onClick={handleSubmit(onManagerSearch)}
              style={{
                padding: '6px 15px',
              }}
            >
              검색
            </Button>
          </Col>
        </Row>
        <Row className={'m-t-20'}>
          <Col>
            <Table className='table-hover text-center m-b-10'>
              <thead>
                <tr>
                  <th className={'col-2'}>선택</th>
                  <th className={'col-2'}>이름</th>
                  <th>계정</th>
                  <th>전화번호</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={4}>
                      <div className='loader-box'>
                        <div className='loader-15' />
                      </div>
                    </td>
                  </tr>
                ) : search_result_set.length ? (
                  search_result_set.map((manager_account, i) => (
                    <tr key={i}>
                      <td>
                        <Input
                          className='checkbox_animated'
                          type={'checkbox'}
                          onClick={(e) => {
                            onCheckManagerAccount(manager_account, e);
                          }}
                        />
                      </td>
                      <td>{manager_account.name}</td>
                      <td>{manager_account.account_name}</td>
                      <td>{autoHyphenPhoneNumber(manager_account.phone)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>{empty_msg.current}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={'text-right'}>
            <Button className={'btn'} color={'primary'} onClick={onSubmitManagerAccount}>
              저장하기
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ManagerAddModal;
