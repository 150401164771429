import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const CustomTooltip = (props) => {
  // state
  const [is_open, setOpen] = useState(false);

  const toggleTooltip = () => {
    setOpen(!is_open);
  };

  return (
    <Tooltip
      target={props.target}
      isOpen={is_open}
      placement={props.placement || 'top'}
      toggle={toggleTooltip}
    >
      {props.content}
    </Tooltip>
  );
};

export default CustomTooltip;
