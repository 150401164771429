import React, { useRef } from 'react';
import { Col, Input } from 'reactstrap';
import {
  BROADCAST_SEARCH,
  useApplicationContext,
} from '../../../context/ApplicationContextProvider';

const SearchBarInput = () => {
  const { broadcast } = useApplicationContext();

  // ref
  const queryRef = useRef();

  const onSubmit = (query) => {
    broadcast.send(BROADCAST_SEARCH, query);
  };

  return (
    <Col md='12'>
      <Input
        className='form-control text-right f-16'
        name='query'
        type='text'
        style={{
          outline: 'none',
          border: 'none',
          height: '3em',
          width: '96.5%',
          padding: '0 .2em',
        }}
        placeholder='기업명 또는 담당자 이름으로 검색'
        innerRef={queryRef}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            queryRef.current.blur();
          } else if (e.key === 'Enter') {
            onSubmit(queryRef.current.value);
          }
        }}
      />
      <i
        className='icofont icofont-search-alt-1'
        style={{
          position: 'absolute',
          right: '0',
          top: '0',
          width: '3%',
          fontSize: '1.5em',
          color: 'grey',
          lineHeight: '48px',
          zIndex: '99',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          onSubmit(queryRef.current.value);
        }}
      />
    </Col>
  );
};

export default SearchBarInput;
