import React, { Fragment, useEffect } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import Info from './component/Info';
import RecruitFormRes from './component/RecruitFormRes';
import Tab from '../_common/Tab';

const RecruitFormDetail = ({ match }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const recruit_form_id = match.params.recruit_form_id;

  const tab_list = [
    {
      id: '1',
      name: '모집폼 정보',
      icon: <i className='icofont icofont-ui-home' />,
      component: Info,
      component_props: { recruit_form_id },
    },
  ];

  if (parseInt(recruit_form_id)) {
    tab_list.push({
      id: '2',
      name: '응답 현황',
      icon: <i className='icofont icofont-ui-note' />,
      component: RecruitFormRes,
      component_props: { recruit_form_id },
    });
  }

  return (
    <Fragment>
      <Breadcrumb parent='모집폼' title='모집폼' />
      <Container fluid={true}>
        <Tab tab_list={tab_list} />
      </Container>
    </Fragment>
  );
};

export default RecruitFormDetail;
