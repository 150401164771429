import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';

import moment from 'moment';
import AccountModel from '../../../model/AccountModel';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'sweetalert2';

const AccountCRMMemo = (props) => {
  const [crm_memo_set, setCRMMemoSet] = useState([]);
  const crm_memo_input = useRef('');

  const getCRMMemo = () => {
    AccountModel.getAccountCRMMemo(props.match.params).then(({ crm_memo_set }) => {
      setCRMMemoSet(crm_memo_set);
    });
  };
  const createMemo = () => {
    if (crm_memo_input.current.value.trim()) {
      AccountModel.postAccountCRMMemo({
        content: crm_memo_input.current.value.trim(),
        account_id: props.match.params.account_id,
      }).then(({ crm_memo_set }) => {
        crm_memo_input.current.value = '';
        setCRMMemoSet(crm_memo_set);
      });
    } else {
      alert('메모 내용 및 작성자를 꼭 입력해주세요~!');
    }
  };

  const deleteMemo = (memo_id) => {
    SweetAlert.fire({
      title: '삭제하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.value) {
        AccountModel.deleteAccountCRMMemo(memo_id).then(({ crm_memo_set }) => {
          setCRMMemoSet(crm_memo_set);
        });
      }
    });
  };

  useEffect(() => {
    getCRMMemo();
  }, []);

  return (
    <Card>
      <CardHeader>
        <h3 className='mb-0'>메모</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            {crm_memo_set.length > 0 &&
              crm_memo_set.map((crm_memo) => {
                return (
                  <div style={post_it_style} className='mb-3' key={crm_memo.id}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div className='f-w-900'>
                        <div>{moment(crm_memo.create_time).format('YYYY-MM-DD hh:mm:ss')}</div>
                        <div>작성자: {crm_memo.writer}</div>
                      </div>
                      <Button
                        className='btn-xs btn-danger btn-pill'
                        onClick={() => {
                          deleteMemo(crm_memo.id);
                        }}
                      >
                        삭제
                      </Button>
                    </div>
                    <div className='mt-2'>[메모 내용]</div>
                    <div>{crm_memo.content}</div>
                  </div>
                );
              })}
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col md='10'>
            <Input
              type={'text'}
              name={'content'}
              placeholder={'매모 내용'}
              innerRef={crm_memo_input}
              onKeyPress={(e) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  createMemo();
                }
              }}
            />
          </Col>
          <Col md='2'>
            <Button className='btn btn-secondary btn-pill' onClick={createMemo}>
              생성
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withRouter(AccountCRMMemo);

const post_it_style = {
  background: '#ffc',
  boxShadow: '4px 4px 4px rgba(33,33,33,.7)',
  padding: '1em',
};
