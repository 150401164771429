import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Collapse,
  Input,
  Label,
} from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import AccountModel from '../../model/AccountModel';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import { initComma, toQueryString } from '../../util/common';
import CustomInput from '../_common/component/CustomInput';
import CustomLabel from '../_common/component/CustomLabel';
import AccountManagementDescriptionTable from './table/AccountManagementDescriptionTable';
import CustomTagInput from '../_common/component/CustomTagInput';
import { PAGE_SIZE } from '../../constants';

const AccountManagementDescription = (props) => {
  const [params, setParams] = useState(
    props.location.state || queryString.parse(props.location.search) || {},
  );

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    search_text: params.search_text ? params.search_text : '',
    date_start: params.date_start ? params.date_start : '',
    date_end: params.date_end ? params.date_end : '',
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const default_tag_filter = useRef({
    search_management_description: params.search_management_description
      ? params.search_management_description
      : '',
  });

  const [management_description_set, setManagementDescriptionSet] = useState([]);
  const [account_count, setAccountCountSet] = useState(0);

  const [job_set, setJobSet] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [is_download, setDownload] = useState(false);
  const [is_filer_spread, setIsFilterSpread] = useState(!!params.is_filer_spread);

  const [filter_set, setFilter] = useState(default_filter.current);
  const [filter_search_management_description_set, setFilterSearchManagementDescriptionSet] =
    useState(
      default_tag_filter.current.search_management_description
        ? default_tag_filter.current.search_management_description.split(',').map((item) => {
            return { name: item };
          })
        : [],
    );

  // 필터용
  const [is_date_disabled, setisDateDisabled] = useState(true);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onCSVRequset = async (e) => {
    if (!is_download) {
      e.target.disabled = true;
      setDownload(true);

      const _a = document.createElement('a');
      _a.style = 'display: none';
      const _file = await AccountModel.getManagementDescriptionCSV({
        ...params,
      });

      const url = window.URL.createObjectURL(_file.data);
      _a.href = url;
      _a.download = `account-management-description-${moment(new Date()).format('YYYY-MM-DD')}.csv`;
      _a.click();
      window.URL.revokeObjectURL(url);

      e.target.disabled = false;
      setDownload(false);
    }
  };

  const onFilterSubmit = async (e) => {
    const search_management_description = await filter_search_management_description_set
      .map((item) => {
        return item.name;
      })
      .join(',');

    if (!filter_set.date_start && filter_set.date_end) {
      alert('날짜 시작 범위를 설정하세요');
    } else if (filter_set.date_start && !filter_set.date_end) {
      alert('날짜 끝 범위를 설정하세요');
    } else if (filter_set.date_start && filter_set.date_end) {
      if (filter_search_management_description_set.length) {
        pushHistory({
          ...filter_set,
          search_management_description: search_management_description,
          page: 1,
          order_key: '',
          order_type: '',
          is_filer_spread: is_filer_spread,
        });
      } else {
        alert('관리용 설명을 입력해주세요!');
      }
    } else {
      pushHistory({
        ...filter_set,
        search_management_description: search_management_description,
        page: 1,
        order_key: '',
        order_type: '',
        is_filer_spread: is_filer_spread,
      });
    }
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onOrderChange = useCallback(
    (order_key) => {
      const new_params = props.location.state || queryString.parse(props.location.search) || {};

      const newFilterSet = {
        ...new_params,
        page: 1,
        size: new_params.size ? parseInt(new_params.size) : PAGE_SIZE,
        order_key: order_key,
        is_filer_spread: is_filer_spread,
        order_type: !new_params.order_type
          ? 'desc'
          : new_params.order_type === 'asc'
            ? 'desc'
            : 'asc',
      };

      pushHistory(newFilterSet);
    },
    [props.location.search],
  );

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      is_filer_spread: is_filer_spread,
    });
    window.scrollTo(0, 0);
  };

  // 날짜 수정
  const onDateChange = (e, name) => {
    setFilter({ ...filter_set, [name]: e && moment(e).format('YYYY-MM-DD') });
  };

  const onDateClick = (e) => {
    let date_start = null;
    let date_end = null;
    let add_date;

    if (e.target.value !== 'all' && e.target.value !== 'direct') {
      add_date = Number(e.target.value.split('_')[1]);
      date_end = moment().format('YYYY-MM-DD');
    }

    if (e.target.value === 'direct') {
      date_start = filter_set.date_start;
      date_end = filter_set.date_end;
      setisDateDisabled(false);
    } else {
      setisDateDisabled(true);
    }

    if (typeof add_date === 'number') {
      date_start = moment().add(-add_date, 'days').format('YYYY-MM-DD');
    }

    setFilter({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
      is_filer_spread: is_filer_spread,
      date_start: date_start,
      date_end: date_end,
    });
  };

  const staticIdToName = useCallback(
    (static_type, int) => {
      let name = null;

      if (static_type === 'job_class') {
        job_set.forEach((job_class) => {
          if (job_class.id === Number(int)) {
            name = job_class.name;
            return false;
          }
        });
      }

      return name;
    },
    [job_set],
  );

  useEffect(() => {
    if (params.date_start || params.date_end) {
      setisDateDisabled(false);
    }

    CommonModel.staticCode({ code_name: 'job_class_set' }).then(({ code_set }) => {
      setJobSet(code_set);
    });
  }, []);

  useEffect(() => {
    const new_params = props.location.state || queryString.parse(props.location.search) || {};
    setIsLoading(true);
    setParams(new_params);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(new_params).length > 0
        ? {
            ...default_filter.current,
            ...new_params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    AccountModel.getManagementDescriptionList({
      ...new_params,
      // paging 초기 값
      size: new_params.size ? parseInt(new_params.size) : PAGE_SIZE,
    }).then(({ management_description_set, total_count }) => {
      setManagementDescriptionSet(management_description_set);
      setAccountCountSet(total_count);
      setIsLoading(false);
    });
  }, [props.location.search]);

  return (
    <Fragment>
      <Breadcrumb parent='사용자' title='사용자 관리용설명' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <div className='default-according style-1' id='accordionoc'>
                <CardHeader style={{ padding: '12px 30px' }}>
                  <Button
                    color='black pl-0'
                    data-toggle='collapse'
                    onClick={() => setIsFilterSpread(!is_filer_spread)}
                    data-target='#collapseicon'
                    aria-expanded={is_filer_spread}
                    aria-controls='collapseicon'
                  >
                    <h5 className='mb-0'>검색필터</h5>
                  </Button>
                </CardHeader>
              </div>
              <Collapse isOpen={is_filer_spread}>
                <CardBody>
                  <Row>
                    <Col>
                      <CustomInput
                        type={'text'}
                        name={'search_text'}
                        value={filter_set.search_text || ''}
                        label={'검색어'}
                        placeholder={'이름, 닉네임, 이메일, 휴대폰, 주소, 강의 검색'}
                        onChange={onFilterChange}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                            onFilterSubmit();
                          }
                        }}
                      />
                    </Col>
                    <Col>
                      <CustomLabel
                        tooltip_desc={'관리용 명칭을 정확하게 입력해주고, Enter로 적용하세요.'}
                        label={'관리용 설명'}
                        is_required
                      />
                      <CustomTagInput
                        tag_set={filter_search_management_description_set}
                        setTagSet={setFilterSearchManagementDescriptionSet}
                        placeholder={'관리용 설명'}
                      />
                    </Col>
                  </Row>
                  <div className='mt-3'>
                    <div className='form-row'>
                      <Col md={'6'}>
                        <CustomLabel label={'VOD조회 범위 설정(최대60일)'} />
                        <div style={{ display: 'flex' }}>
                          <div className={'mr-3'}>
                            <Input
                              type='select'
                              className='form-control'
                              onChange={onDateClick}
                              defaultValue={params.date_start || params.date_end ? 'direct' : 'all'}
                            >
                              <option value='all'>설정안함</option>
                              <option value='direct'>직접설정</option>
                              <option value='before_0'>오늘</option>
                              <option value='before_1'>어제</option>
                              <option value='before_7'>7일전</option>
                              <option value='before_14'>14일전</option>
                              <option value='before_30'>30일전</option>
                              <option value='before_60'>60일전</option>
                            </Input>
                          </div>

                          <div className={'mr-3'}>
                            <DatePicker
                              autoComplete='off'
                              className='form-control digits'
                              name='date_start'
                              dateFormat='yyyy-MM-dd'
                              disabled={is_date_disabled}
                              onChange={(e) => {
                                onDateChange(e, 'date_start');
                              }}
                              onKeyDown={(e) => {
                                if (e.code === 'Enter' || e.code === 'NumpadEnter')
                                  onFilterSubmit(e);
                              }}
                              placeholderText='시작 날짜'
                              selectsStart
                              selected={
                                filter_set.date_start ? new Date(filter_set.date_start) : null
                              }
                              startDate={
                                filter_set.date_start ? new Date(filter_set.date_start) : null
                              }
                              endDate={filter_set.date_end ? new Date(filter_set.date_end) : null}
                              minDate={
                                new Date(moment(new Date(filter_set.date_end)).add(-60, 'days'))
                              }
                              maxDate={
                                filter_set.date_end ? new Date(filter_set.date_end) : new Date()
                              }
                            />
                          </div>

                          <div className={'mr-3'}>
                            <DatePicker
                              autoComplete='off'
                              className='form-control digits'
                              name='date_end'
                              disabled={is_date_disabled}
                              dateFormat='yyyy-MM-dd'
                              onChange={(e) => {
                                onDateChange(e, 'date_end');
                              }}
                              onKeyDown={(e) => {
                                if (e.code === 'Enter' || e.code === 'NumpadEnter')
                                  onFilterSubmit(e);
                              }}
                              placeholderText='마지막 날짜'
                              selectsEnd
                              selected={filter_set.date_end ? new Date(filter_set.date_end) : null}
                              startDate={
                                filter_set.date_start ? new Date(filter_set.date_start) : null
                              }
                              endDate={filter_set.date_end ? new Date(filter_set.date_end) : null}
                              minDate={
                                filter_set.date_start ? new Date(filter_set.date_start) : null
                              }
                              maxDate={
                                new Date(moment(new Date(filter_set.date_start)).add(60, 'days'))
                              }
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md='4' />
                      <Col md='1'>
                        <Label htmlFor='size'>
                          검색단위 <small>(개수)</small>
                        </Label>
                        <Input
                          className='form-control'
                          name='size'
                          type='number'
                          placeholder='검색단위'
                          onChange={onFilterChange}
                          value={filter_set.size}
                        />
                      </Col>

                      <Col className='text-right mt-auto'>
                        <Button
                          className='btn btn-primary btn-pill'
                          color='success'
                          onClick={(e) => {
                            onFilterSubmit(e);
                          }}
                        >
                          검색
                        </Button>
                      </Col>
                    </div>
                  </div>
                </CardBody>
              </Collapse>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={8} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총: <span style={{ color: 'coral' }}>{initComma(account_count)}</span>개
                        </p>
                        <Loading isLoading={isLoading || is_download} />
                      </Col>
                      <Col className='text-right'>
                        <Button
                          className='btn btn-primary btn-pill'
                          color='info'
                          onClick={onCSVRequset}
                        >
                          CSV 다운로드
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <AccountManagementDescriptionTable
                onOrderChange={onOrderChange}
                staticIdToName={staticIdToName}
                params={params}
                management_description_set={management_description_set}
              />

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size ? account_count / params.size : account_count / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AccountManagementDescription;
