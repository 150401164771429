import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CourseModel from '../../model/CourseModel';
import Loading from '../_common/Loading';

import { Card, CardHeader, Col, Container, Row, Button } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import Form from 'reactstrap/es/Form';

import ImageUploader from '../_common/component/ImageUploader';
import SweetAlert from 'sweetalert2';
import CommonModel from '../../model/CommonModel';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import CustomTypeahead from '../_common/component/CustomTypeahead';
import { errMessageOccur } from '../../util/common';
import CustomLabel from '../_common/component/CustomLabel';

const tooptip_word = {
  home_main: {
    type: '홈-메인 : \n',
    content_text: '- 이용 안함\n',
    content_description: '- 이용 안함\n',
    content_redirect_url: '- 이용 안함\n',
    content_theme_color_hex: '- 이용 안함\n',
    promotion_type_id: '- 이용 안함\n',
  },
  bearu_special: {
    type: '베어유 스페셜 : \n',
    content_text: '- 이용 안함\n',
    content_description: '- 이용 안함\n',
    content_redirect_url: '- 이용 안함\n',
    content_theme_color_hex: '- 이용 안함\n',
    promotion_type_id:
      '- [섹션] 베어유 인기 클래스\n(클래스:전체, 더보기, 클래스 판매등록 신청을 제외한 모든 페이지)\n- 이 강의는 어떠세요 (마이페이지??)\n',
  },
  now_hot_class: {
    type: 'NOW HOT CLASS : \n',
    content_text: '- 이용 안함\n',
    content_description: '- 이용 안함\n',
    content_redirect_url: '- 이용 안함\n',
    content_theme_color_hex: '- 이용 안함\n',
    promotion_type_id: "- '클래스' 페이지 맨 위 창에 들어감\n",
  },
  custom_banner: {
    type: '홈-커스텀배너 : \n',
    content_text: '- 이용 안함',
    content_description: '- 이용 안함',
    content_redirect_url: '- 이용 안함',
    content_theme_color_hex: '- 이용 안함',
    promotion_type_id: '- 이용 안함',
  },
};

const obj_err_msg = {
  course_id: "'강의'",
  content_text: "'제목'",
  content_description: "'설명'",
  promotion_order: "'정렬순서'",
};

const PromotionCourseDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.promotion_course_id));
  const [promotion_type_set, setPromotionTypeSet] = useState([]);
  const [flag_set, setFlagSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [search_text, setSearchText] = useState([]);

  const [course_set, setCourseSet] = useState([]);

  const [promotion_course, setPromotionCourse] = useState({
    id: '',
    course: '',
    course_id: '',
    course_title: '',
    content_text: '',
    content_description: '',
    content_theme_color_hex: '',
    content_redirect_url: '',
    banner_image_url: '',
    banner_thumbnail_image_url: '',
    promotion_order: '',
    promotion_type_id: '',
    flag_id: '',
  });

  const onInputChange = (e) => {
    setPromotionCourse({ ...promotion_course, [e.target.name]: e.target.value });
  };

  const onInputSubmit = (e) => {
    e.preventDefault();

    const err_msg = errMessageOccur(promotion_course, obj_err_msg);

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      if (promotion_course.id) {
        CourseModel.putPromotionCourseDetail(promotion_course).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        CourseModel.postPromotionCourse(promotion_course).then(({ promotion_course_id }) => {
          props.history.replace('/promotion/' + promotion_course_id);
          window.location.reload();
        });
      }
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const requestDetail = () => {
    CourseModel.getPromotionCourseDetail(Number(props.match.params.promotion_course_id)).then(
      ({ promotion_course }) => {
        if (promotion_course) {
          setPromotionCourse(promotion_course);
          setSearchText([promotion_course.course_id, promotion_course.course_title]);
        }
      },
    );
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'promotion_type_set' }).then(({ code_set }) => {
      setPromotionTypeSet(code_set);
      if (!Number(props.match.params.promotion_course_id)) {
        setPromotionCourse((prevState) => ({ ...prevState, promotion_type_id: code_set[0]?.id }));
      }
    });

    CommonModel.staticCode({ code_name: 'flag_set' }).then(({ code_set }) => {
      setFlagSet(code_set);
      if (!Number(props.match.params.promotion_course_id)) {
        setPromotionCourse((prevState) => ({ ...prevState, flag_id: code_set[0]?.id }));
      }
    });

    CommonModel.getSearch('course').then((data) => {
      setCourseSet(data);
    });

    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent='프로모션'
        title={`프로모션 ${props.match.params.promotion_course_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form
              onSubmit={(e) => {
                onInputSubmit(e);
              }}
            >
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`프로모션 ${
                        props.match.params.promotion_course_id === '0' ? '생성' : '정보'
                      }`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      {
                        <>
                          <Button
                            className='btn-pill'
                            color='primary'
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              // 생성(0)일 경우 수정(0 이상)일 경우
                              if (!!Number(props.match.params.promotion_course_id)) {
                                if (isEdit) {
                                  CourseModel.getPromotionCourseDetail(
                                    Number(props.match.params.promotion_course_id),
                                  ).then(({ promotion_course }) => {
                                    if (promotion_course) {
                                      setPromotionCourse(promotion_course);
                                    }
                                  });
                                }
                                setIsEdit(!isEdit);
                              } else {
                                window.location.href = '/promotion';
                              }
                            }}
                          >
                            {isEdit ? <>취소</> : <>편집</>}
                          </Button>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col
                      md={3}
                      style={{ minWidth: '360px', maxHeight: '230px', alignSelf: 'center' }}
                    >
                      <div className='blog-box text-center'>
                        {promotion_course.banner_image_url || isEdit ? (
                          <ImageUploader
                            id={Number(props.match.params.promotion_course_id)}
                            name='image_url'
                            withPreview={true}
                            img_type={'promotion_banner'}
                            min_width={1200}
                            thumbnail_width={500}
                            setPropsState={setPromotionCourse}
                            img_url={promotion_course.banner_image_url}
                            isEdit={
                              !!Number(props.match.params.promotion_course_id) ? isEdit : false
                            }
                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.svg']}
                            height={230}
                          />
                        ) : (
                          <>
                            <img
                              alt={'no_image'}
                              src={`${process.env.REACT_APP_IMG_URL}/static/png/common/no-profile-image.png`}
                            />
                            <div>NO BANNER IMAGE</div>
                          </>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col md='12 mb-3'>
                          {!!!Number(props.match.params.promotion_course_id) ? (
                            <CustomTypeahead
                              disabled={!!Number(props.match.params.promotion_course_id) || !isEdit}
                              is_required={true}
                              multiple={false}
                              label={'강의 설정'}
                              tooltip_desc={
                                '생성을 완료하면 강의 설정 및 프로모션 위치는 변경 불가능 합니다.'
                              }
                              onChange={(selected_item) => {
                                setSearchText(selected_item);

                                setPromotionCourse((prevState) => ({
                                  ...prevState,
                                  course_id: selected_item[0]?.id,
                                  course_title: selected_item[0]?.title,
                                }));

                                // Input 값 비우기
                                setSearchText([]);
                              }}
                              selected={search_text}
                              display_options={{
                                keys: ['title'],
                              }}
                              labelKey={'title'}
                              options={course_set}
                              options_value_normalization={'title'}
                              placeholder={'강의를 선택 해주세요.'}
                            />
                          ) : (
                            <>
                              <CustomLabel
                                tooltip_desc={
                                  "생성을 완료하면 '강의 설정' 및 '프로모션 위치' 는 변경 불가능 합니다."
                                }
                                label={'강의 설정'}
                                is_required={true}
                              />
                              <a
                                className='form-control text-primary'
                                target='_blank'
                                rel='noreferrer'
                                href={'/course/' + search_text[0] || ''}
                              >
                                [강의] {search_text[1] || ''}
                              </a>
                            </>
                          )}
                        </Col>
                        <Col md='5 mb-3'>
                          <CustomInput
                            type={'textarea'}
                            name={'content_text'}
                            disabled={!isEdit}
                            value={promotion_course.content_text || ''}
                            is_required={true}
                            label={'제목'}
                            tooltip_desc={
                              tooptip_word.home_main.type +
                              tooptip_word.home_main.content_text +
                              '\n' +
                              tooptip_word.bearu_special.type +
                              tooptip_word.bearu_special.content_text +
                              '\n' +
                              tooptip_word.now_hot_class.type +
                              tooptip_word.now_hot_class.content_text +
                              '\n' +
                              tooptip_word.custom_banner.type +
                              tooptip_word.custom_banner.content_text
                            }
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 128;
                            }}
                          />
                        </Col>
                        <Col md='7 mb-3'>
                          <CustomInput
                            type={'textarea'}
                            name={'content_description'}
                            disabled={!isEdit}
                            value={promotion_course.content_description || ''}
                            is_required={true}
                            label={'설명'}
                            tooltip_desc={
                              tooptip_word.home_main.type +
                              tooptip_word.home_main.content_description +
                              '\n' +
                              tooptip_word.bearu_special.type +
                              tooptip_word.bearu_special.content_description +
                              '\n' +
                              tooptip_word.now_hot_class.type +
                              tooptip_word.now_hot_class.content_description +
                              '\n' +
                              tooptip_word.custom_banner.type +
                              tooptip_word.custom_banner.content_description
                            }
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 128;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <CustomInput
                            type={'url'}
                            name={'content_redirect_url'}
                            disabled={!isEdit}
                            value={promotion_course.content_redirect_url || ''}
                            is_required={true}
                            label={'리디렉션URL'}
                            tooltip_desc={
                              tooptip_word.home_main.type +
                              tooptip_word.home_main.content_redirect_url +
                              '\n' +
                              tooptip_word.bearu_special.type +
                              tooptip_word.bearu_special.content_redirect_url +
                              '\n' +
                              tooptip_word.now_hot_class.type +
                              tooptip_word.now_hot_class.content_redirect_url +
                              '\n' +
                              tooptip_word.custom_banner.type +
                              tooptip_word.custom_banner.content_redirect_url
                            }
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 512;
                            }}
                          />
                        </Col>
                        <Col md='1 mb-3' style={{ minWidth: '120px' }}>
                          <CustomInput
                            type={'color'}
                            name={'content_theme_color_hex'}
                            disabled={!isEdit}
                            value={promotion_course.content_theme_color_hex || '#000000'}
                            is_required={true}
                            label={'테마컬러'}
                            tooltip_desc={
                              tooptip_word.home_main.type +
                              tooptip_word.home_main.content_theme_color_hex +
                              '\n' +
                              tooptip_word.bearu_special.type +
                              tooptip_word.bearu_special.content_theme_color_hex +
                              '\n' +
                              tooptip_word.now_hot_class.type +
                              tooptip_word.now_hot_class.content_theme_color_hex +
                              '\n' +
                              tooptip_word.custom_banner.type +
                              tooptip_word.custom_banner.content_theme_color_hex
                            }
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='1 mb-3' style={{ minWidth: '120px' }}>
                          <CustomInput
                            type={'number'}
                            name={'promotion_order'}
                            disabled={!isEdit}
                            value={String(promotion_course.promotion_order) || ''}
                            is_required={true}
                            label={'정렬순서'}
                            tooltip_desc={'높을수록 상단 배치'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'promotion_type_id'}
                            disabled={!!Number(props.match.params.promotion_course_id) || !isEdit}
                            value={promotion_course.promotion_type_id}
                            label={'프로모션위치'}
                            is_required={true}
                            tooltip_desc={
                              tooptip_word.home_main.type +
                              tooptip_word.home_main.promotion_type_id +
                              '\n' +
                              tooptip_word.bearu_special.type +
                              tooptip_word.bearu_special.promotion_type_id +
                              '\n' +
                              tooptip_word.now_hot_class.type +
                              tooptip_word.now_hot_class.promotion_type_id +
                              '\n' +
                              tooptip_word.custom_banner.type +
                              tooptip_word.custom_banner.promotion_type_id
                            }
                            sub_option_type={'description'}
                            onChange={onInputChange}
                            options={promotion_type_set}
                          />
                        </Col>
                        <Col md='2 mb-3'>
                          <CustomSelectInput
                            name={'flag_id'}
                            disabled={!!!Number(props.match.params.promotion_course_id) || !isEdit}
                            value={promotion_course.flag_id}
                            label={'상태'}
                            is_required={true}
                            sub_option_type={'description'}
                            onChange={onInputChange}
                            options={flag_set}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PromotionCourseDetail;
