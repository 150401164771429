import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Table } from 'reactstrap';
import {
  COMPANY_LIST_SHOW_COUNT,
  INIT_PAGE_COUNT,
  TOGGLE_SORT_NUM_INIT,
} from '../../../constant/options';
import { lang } from '../../../lang';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  BROADCAST_SEARCH,
  useApplicationContext,
} from '../../../context/ApplicationContextProvider';
import TableHead from '../../_common/component/TableHead';
import {
  autoHyphenPhoneNumber,
  getPageArray,
  getShowList,
  getTotalPageCount,
  getVisibleString,
} from '../../../util/b2b_utils';
import CustomTooltip from '../../_common/component/CustomTooltip';
import { useMountEffect, useShowDialog } from '../../../util/b2b_Hooks';
import CompanyModel from '../../../model/CompanyModel';
import moment from 'moment';
import Paging from '../../_common/Paging';

/*
    props
    table_head: {}      options.js의 TABLE_HEAD_COMPANY 참고
 */
const CompanyListTable = (props) => {
  const company_reducer = useSelector((state) => state.company_reducer);
  const history = useHistory();
  const [isMount] = useMountEffect();
  const { broadcast } = useApplicationContext();
  const { showAlert } = useShowDialog();

  // state
  const [page_count, setPageCount] = useState(INIT_PAGE_COUNT);
  const [company_set, setCompanySet] = useState([]);
  const [current_company_set, setCurrentCompanySet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // toggle_sort : 넘버링 1 ~ 2 || 0
  const [toggle_sort, setToggleSort] = useState(TOGGLE_SORT_NUM_INIT);
  const [is_sorted, setIsSorted] = useState(false);
  const [total_page_count, setTotalPageCount] = useState(0);
  const [page_array, setPageArray] = useState([]);

  // ref
  const empty_comment = useRef(lang.company.did_not_exist_company);
  const target_array = useRef(props.table_head.map((item) => item.target));

  useMountEffect(async () => {
    await updateUi();
  });

  const updateUi = async () => {
    setIsLoading(true);
    const company_list_res = await CompanyModel.getCompanyList();

    if (isMount()) {
      if (company_list_res.code === 200) {
        if (!company_list_res.data.company_info) {
          setCompanySet([]);
        } else {
          setCompanySet(company_list_res.data.company_info);
        }
      } else {
        showAlert({
          title: lang.request.request_error_title,
          text: company_list_res.message,
        });
      }
      setIsLoading(false);
    }
  };

  const searchCompany = useCallback(
    (query) => {
      let search_result = [];
      search_result = company_set.filter((company) =>
        query
          ? (company.name && company.name.includes(query)) ||
            (company.company_name && company.company_name.includes(query))
          : company,
      );

      setPageCount(INIT_PAGE_COUNT);

      if (!search_result.length) {
        empty_comment.current = lang.search.did_not_match_company;
        reloadCurrentCompanySet();
      } else {
        reloadCurrentCompanySet(search_result);
      }
    },
    [company_set],
  );

  const reloadCurrentCompanySet = useCallback(
    (company_set) => {
      if (company_set) {
        setTotalPageCount(
          getTotalPageCount(company_set.length, COMPANY_LIST_SHOW_COUNT).total_page_count,
        );
        setPageArray(
          getPageArray(
            getTotalPageCount(company_set.length, COMPANY_LIST_SHOW_COUNT).total_page_count,
          ).page_array,
        );

        setCurrentCompanySet(
          getShowList({
            array: company_set,
            show_count: COMPANY_LIST_SHOW_COUNT,
            page_count: page_count,
            total_page_count: getTotalPageCount(company_set.length, COMPANY_LIST_SHOW_COUNT)
              .total_page_count,
          }),
        );
      } else {
        setTotalPageCount(0);
        setPageArray([]);
        setCurrentCompanySet([]);
      }
    },
    [page_count, total_page_count.current, company_set],
  );

  useEffect(() => {
    if (company_set.length) {
      reloadCurrentCompanySet(company_set);
    }
  }, [reloadCurrentCompanySet, company_set]);

  useEffect(() => {
    // searchBar.js 에서 검색
    const unlisten = broadcast.listen([BROADCAST_SEARCH], ({ value }) => {
      value ? searchCompany(value) : searchCompany();
    });

    return () => {
      broadcast.unlisten(unlisten);
    };
  }, [company_reducer, broadcast, searchCompany]);

  const toggleSortByTarget = (key_target) => {
    if (is_sorted) {
      company_set.sort((a, b) =>
        a[key_target] < b[key_target] ? 1 : a[key_target] > b[key_target] ? -1 : 0,
      );
      setIsSorted(!is_sorted);
    } else if (!is_sorted) {
      company_set.sort((a, b) =>
        a[key_target] > b[key_target] ? 1 : a[key_target] < b[key_target] ? -1 : 0,
      );
      setIsSorted(!is_sorted);
    }
    reloadCurrentCompanySet(company_set);
  };

  const onHandlePageCount = (direction) => {
    if (direction === 'Prev' && page_count > 1) {
      setPageCount(page_count - 1);
    } else if (direction === 'Next' && page_count < total_page_count) {
      setPageCount(page_count + 1);
    }
  };

  return (
    <>
      <Table className='table-hover text-center'>
        <TableHead
          table_head={props.table_head}
          toggle_sort={toggle_sort}
          setToggleSort={setToggleSort}
          toggleSortByTarget={toggleSortByTarget}
        />
        <tbody>
          {isLoading ? (
            <tr>
              <td
                colSpan='8'
                style={{
                  textAlign: 'center',
                }}
              >
                <div className='loader-box'>
                  <div className='loader-15' />
                </div>
              </td>
            </tr>
          ) : current_company_set.length >= 1 ? (
            current_company_set.map((company, i) => {
              const visible_company_title = getVisibleString(company.company_name, 20);
              return (
                <tr
                  style={{
                    cursor: 'pointer',
                  }}
                  key={i}
                  onClick={() => {
                    history.push(`/company/${company.id}`);
                  }}
                >
                  {target_array.current.length > 1 &&
                    target_array.current.map((target, index) => {
                      if (target === 'status_id') {
                        return (
                          <td className='text-center' key={index}>
                            {company[target] === 1
                              ? '이용대기'
                              : company[target] === 2
                                ? '이용중'
                                : '만료'}
                          </td>
                        );
                      } else if (target === 'course_count') {
                        return (
                          <td className='text-center' key={index}>
                            {company[target]}
                          </td>
                        );
                      } else if (target === 'company_name') {
                        return company[target].length >= 17 ? (
                          <Fragment key={index}>
                            <td
                              className='text-center'
                              id={`title_company_${company.id}`}
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {visible_company_title}
                            </td>
                            <CustomTooltip
                              target={`title_company_${company.id}`}
                              content={company[target]}
                            />
                          </Fragment>
                        ) : (
                          <td className='text-center' key={index}>
                            {company[target]}
                          </td>
                        );
                      } else if (target === 'phone') {
                        return (
                          <td
                            className='text-center'
                            key={index}
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {company[target] ? autoHyphenPhoneNumber(company[target]) : ''}
                          </td>
                        );
                      } else if (
                        target === 'max_rental_expire_time' ||
                        target === 'min_rental_time'
                      ) {
                        return (
                          <td
                            className='text-center'
                            key={index}
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {company[target]
                              ? moment(company[target]).format('YYYY-MM-DD hh:mm:ss')
                              : ''}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            className='text-center'
                            key={index}
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {company[target] ? company[target] : ''}
                          </td>
                        );
                      }
                    })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan='8' className='text-center'>
                {empty_comment.current}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Paging
        page_array={page_array}
        setPageCount={setPageCount}
        page_count={page_count}
        onHandlePageCount={onHandlePageCount}
      />
    </>
  );
};

export default CompanyListTable;
