import axios from 'axios';
import moment from 'moment/moment';

class CommonModel {
  async staticCode({ code_name }) {
    const {
      data: {
        code,
        data: { code_set },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/cms-api/v1/common/code`, {
      params: { code_name },
    });
    return { code, code_set };
  }

  async signIn({ email, password }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/cms-api/v1/common/signin`, {
      email,
      password,
    });
    let auth = null;
    if (code === 200) {
      auth = data.auth;
    }
    return { code, auth };
  }

  async signOut() {
    const {
      data: { code },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/cms-api/v1/common/signout`);
    return { code };
  }

  async getSearch(search_type, id = null) {
    const {
      data: {
        data: { search_set },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/cms-api/v1/common/search`, {
      params: {
        search_type,
        id,
      },
    });
    return search_set;
  }

  async getBanner(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/common/banner', {
      params: filter,
    });
    return data;
  }

  async postBanner(banner) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/common/banner', banner);
    return data;
  }

  async getBannerDetail(banner_id) {
    if (banner_id) {
      const {
        data: { data },
      } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/common/banner/' + banner_id);
      return data;
    } else {
      return { banner: false };
    }
  }

  async putBannerDetail(banner) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/common/banner/' + banner.id,
      banner,
    );
    return data;
  }

  async getNotice(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/common/notice', {
      params: filter,
    });
    return data;
  }

  // 빡공단
  async getHardStudy(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/hardstudy', {
      params: filter,
    });
    return data;
  }

  async getBgd(filter) {
    const { data } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/bgd', {
      params: filter,
    });
    return data;
  }

  async postBgd(bgd) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/bgd', bgd);
    return data;
  }

  async postNotice(notice) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/common/notice', notice);
    return data;
  }

  async getNoticeDetail(notice_id) {
    if (notice_id) {
      const {
        data: { data },
      } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/common/notice/' + notice_id);
      return data;
    } else {
      return { notice: false };
    }
  }

  async putNoticeDetail(notice) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/common/notice/' + notice.id,
      notice,
    );
    return data;
  }

  async postHardStudy(hardstudy) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/hardstudy', hardstudy);
    return data;
  }

  async getHardStudyDetail(hardstudy_id) {
    if (hardstudy_id) {
      const {
        data: { data },
      } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/hardstudy/' + hardstudy_id);
      return data;
    } else {
      return { hardstudy_info: false };
    }
  }

  async getBgdDetail(bgd_id, filter) {
    if (bgd_id) {
      const { data } = await axios.get(
        process.env.REACT_APP_API_URL + '/cms-api/v1/bgd/' + bgd_id,
        {
          params: filter,
        },
      );
      return data;
    } else {
      return { bgd_info: false };
    }
  }

  async putHardStudyDetail(hardStudy) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/hardstudy/' + hardStudy.id,
      hardStudy,
    );
    return data;
  }

  async getFaq(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/common/faq', {
      params: filter,
    });
    return data;
  }

  async postFaq(faq) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/common/faq', faq);
    return data;
  }

  async getFaqDetail(faq_id) {
    if (faq_id) {
      const {
        data: { data },
      } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/common/faq/' + faq_id);
      return data;
    } else {
      return { faq: false };
    }
  }

  async putFaqDetail(faq) {
    const {
      data: { data },
    } = await axios.put(process.env.REACT_APP_API_URL + '/cms-api/v1/common/faq/' + faq.id, faq);
    return data;
  }

  async deleteFaqDetail(faq_id) {
    const {
      data: { data },
    } = await axios.delete(process.env.REACT_APP_API_URL + '/cms-api/v1/common/faq/' + faq_id);
    return data;
  }

  async getServiceInfo() {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/common/service-info');
    return data;
  }

  async putServiceInfo(service_info) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/common/service-info',
      service_info,
    );
    return data;
  }

  async postMailCustomTemplate(mail_custom_template) {
    const {
      data: { data },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/common/mail-custom-template',
      mail_custom_template,
    );
    return data;
  }

  async putMailCustomTemplateDetail(mail_custom_template) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL +
        '/cms-api/v1/common/mail-custom-template/' +
        mail_custom_template.id,
      mail_custom_template,
    );
    return data;
  }

  async deleteMailCustomTemplateDetail(mail_custom_template_id) {
    const {
      data: { data },
    } = await axios.delete(
      process.env.REACT_APP_API_URL +
        '/cms-api/v1/common/mail-custom-template/' +
        mail_custom_template_id,
    );
    return data;
  }

  async deleteTmpImage(delete_file_list) {
    await axios.put(process.env.REACT_APP_API_URL + '/cms-api/v1/common/img', {
      delete_file_list: delete_file_list,
    });
  }

  async getTrigger(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/common/trigger', {
      params: filter,
    });
    return data;
  }

  async postTrigger(trigger) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/common/trigger', trigger);
    return data;
  }

  async putTriggerDetail(trigger) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/common/trigger/' + trigger.id,
      trigger,
    );
    return data;
  }

  async getTriggerDetail(trigger_id) {
    if (trigger_id) {
      const {
        data: { data },
      } = await axios.get(
        process.env.REACT_APP_API_URL + '/cms-api/v1/common/trigger/' + trigger_id,
      );
      return data;
    } else {
      return { banner: false };
    }
  }

  async csvDownload(csvData) {
    const csvDumpData = [];
    const filename = `course-review-${moment(new Date()).format('YYYY-MM-DD')}.csv`;

    // 데이터 배열을 CSV 형식으로 변환합니다.
    const header = Object.keys(csvData[0]).join(',');
    csvDumpData.push(header);

    let chunkSize = 1000;
    let startIndex = 0;
    while (startIndex < csvData.length) {
      const chunk = csvData.slice(startIndex, startIndex + chunkSize);
      let rowData = [];
      for (let row of chunk) {
        let rowValues = [];
        for (let val of Object.values(row)) {
          if (typeof val === 'string') {
            val = val.toString().replace(/"/g, '""');
          }
          rowValues.push('"' + val + '"');
        }
        rowData.push(rowValues.join(','));
      }
      csvDumpData.push(rowData.join('\n'));
      startIndex += chunkSize;
    }

    // CSV 데이터를 URI 인코드합니다.
    let csvContent = '';
    chunkSize = 1000;
    startIndex = 0;
    while (startIndex < csvDumpData.length) {
      const chunk = csvDumpData.slice(startIndex, startIndex + chunkSize);
      csvContent += chunk.join('\n') + '\n';
      startIndex += chunkSize;
    }

    // 엑셀 파일 다운로드 링크를 생성합니다.
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,' + encodeURIComponent('\uFEFF' + csvContent),
    );
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export default new CommonModel();
