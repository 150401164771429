import {
  STORED_EBOOK_SALE,
  SET_EBOOK_SALE,
  CLEAR_EBOOK_SALE,
  ADD_EBOOK_SALE,
  DELETE_EBOOK_SALE,
  SET_EBOOK_SALE_PRICE,
} from '../../action_type';

const INIT_STATE = [];

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_EBOOK_SALE:
      return INIT_STATE;

    case STORED_EBOOK_SALE:
      return action.data;

    case SET_EBOOK_SALE:
      const tmp_state = state[action.sale_index];
      tmp_state[action.name] = action.value;
      return [...state];

    case ADD_EBOOK_SALE:
      state.push({
        sale_short_name: '',
        is_hide: 0,
        order_number: 0,
        purchase_type_id: 1,
        price_info: [
          {
            currency_code_id: 1,
            original_price: 0,
            price: 0,
          },
        ],
        is_deleted: 0,
      });
      return [...state];

    case DELETE_EBOOK_SALE:
      return state.filter((item, i) => i !== action.sale_index);

    case SET_EBOOK_SALE_PRICE:
      const price_set =
        state[action.sale_index].price_info[state[action.sale_index].price_info.length - 1];
      price_set[action.name] = action.value;
      return [...state];

    default:
      return state;
  }
};
