import React from 'react';
import { initComma } from '../../../util/common';
import Apexchart from 'react-apexcharts';

const AccountBarChart = ({ labels, series, x_title_text, y_title_text, tooltip_y_formatter }) => {
  return (
    <Apexchart
      options={{
        chart: {
          type: 'bar',
          height: 350,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: labels,
        xaxis: {
          type: 'number',
          title: {
            text: x_title_text,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              if (typeof y !== 'undefined') {
                return initComma(value.toFixed(0));
              }
              return value.toFixed(0);
            },
          },
          title: {
            text: y_title_text,
          },
          forceNiceScale: true,
          min: 0,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: tooltip_y_formatter,
          },
        },
        colors: ['#008ffb', '#ff4560', '#feb019'],
      }}
      series={series}
      type='bar'
      height={350}
    />
  );
};

export default AccountBarChart;
