import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Loading from '../../_common/Loading';
import CourseModel from '../../../model/CourseModel';
import BookmarkUserTable from '../../course/component/BookmarkUserTable';
import BgdUserTable from './BgdUserTable';
import CustomPagination from '../../_common/CustomPagination';
import { toQueryString } from '../../../util/common';
import CustomSelect2 from '../../_common/component/CustomSelect2';
import CustomDateRangeFilter from '../../_common/component/CustomDateRangeFilter';
import CustomTooltip from '../../_common/component/CustomTooltip';
import CommonModel from '../../../model/CommonModel';
import CustomInput from '../../_common/component/CustomInput';

const ParticipationInfo = ({ obj_id }) => {
  const location = useLocation();
  const history = useHistory();
  const search_params = queryString.parse(location.search);
  const params = useParams();
  const CommonStore = useSelector((state) => state.CommonStore);

  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilterSet] = useState();
  const [is_download, setDownload] = useState(false);

  const bgdId = useRef(params.bgd_id);

  useEffect(() => {
    updateUi();
  }, [location.search]);

  const updateUi = async () => {
    setIsLoading(true);

    let filter = {
      page: search_params.page ? parseInt(search_params.page) : 1,
      user_name: search_params.user_name ? search_params.user_name : null,
      phone: search_params.phone ? search_params.phone : null,
      email: search_params.email ? search_params.email : null,
      is_completed: search_params.is_completed ? search_params.is_completed : null,
    };

    setFilterSet(filter);

    const res = await CommonModel.getBgdDetail(obj_id, filter);

    if (res) {
      setUsersCount(res?.total_count);
      setUsers(res.bgd_details);
    }

    setIsLoading(false);
  };

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    history.push(`?${queryString}`, params);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...search_params,
      page: page,
    });
  };

  const onFilterChange = (e) => {
    setFilterSet({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onFilterSubmit = () => {
    pushHistory({
      ...filter_set,
      page: 1,
    });
  };

  const onDetailCSVRequest = async () => {
    const mapDataToCSV = (users) => {
      let csvContent =
        '"ID","이름","휴대폰","이메일","수강 클래스","시청 기록","학습 일지","커리어 프로필","댓글","커뮤니티글","후기","수료여부"\n';

      users.forEach((user) => {
        user.bgd_course.forEach((course) => {
          let row = [
            user.account.id,
            user.account.account_profile.name,
            user.account.account_profile.phone,
            user.account.account_profile.email,
            course.course.title,
            user.viewed_bgd_study_log_ratio,
            user.board_ratio,
            user.bgd_career_profile ? '제출' : '미제출',
            user.comment_count,
            user.board_count,
            user.bgd_career_profile ? user.bgd_final_review.content : '미제출',
            user.is_completed ? '수료' : '미수료',
          ]
            .map((value) => {
              let result = value?.toString().replace(/"/g, '""');
              return `"${result}"`;
            })
            .join(',');

          csvContent += row + '\n';
        });
      });

      return csvContent;
    };

    try {
      const response = await CommonModel.getBgdDetail(obj_id, filter_set);
      const dataContent = response?.bgd_details;
      const csvData = mapDataToCSV(dataContent);
      const blob = new Blob(['\uFEFF' + csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `회원 목록 - ${moment().format('YYYY-MM-DD')}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(`회원 목록 CSV 다운로드 중 오류 발생:`, error);
    }
  };

  const TABLE_HEAD_BGD_USER = [
    {
      title: 'ID',
      target: 'user_account_id',
      class_name: null,
      style: null,
    },
    {
      title: '이름',
      target: 'name',
      class_name: null,
      style: null,
    },

    {
      title: '휴대폰',
      target: 'phone',
      class_name: null,
      style: null,
    },

    {
      title: '이메일',
      target: 'provider_type_id',
      class_name: null,
      style: null,
    },
    {
      title: '수강 클래스',
      target: 'account_type_id',
      class_name: null,
      style: null,
    },
    {
      title: '시청기록',
      target: 'account_name',
      class_name: null,
      style: null,
    },
    {
      title: '학습일지',
      target: 'update_time',
      class_name: null,
      style: null,
    },

    {
      title: '커리어프로필',
      target: 'has_course',
      class_name: null,
      style: null,
    },
    {
      title: '댓글',
      target: 'has_course',
      class_name: null,
      style: null,
    },
    {
      title: '커뮤니티 글',
      target: 'has_course',
      class_name: null,
      style: null,
    },
    {
      title: '후기',
      target: 'has_course',
      class_name: null,
      style: null,
    },
    {
      title: '수료여부',
      target: 'has_course',
      class_name: null,
      style: null,
    },
  ];

  return (
    <Row>
      {isLoading ? (
        <Col col={'12'} className={'text-center'}>
          <Loading isLoading={isLoading} />
        </Col>
      ) : (
        <>
          <Col md={2}>
            <CustomInput
              type={'text'}
              name={'user_name'}
              // disabled={!isEdit}
              // value={hardStudy.bgd_title || ''}
              // is_required={true}
              defaultValue={search_params.user_name ? search_params.user_name : null}
              label={'이름'}
              onChange={onFilterChange}
            />
          </Col>

          <Col md={2}>
            <CustomInput
              type={'text'}
              name={'phone'}
              // disabled={!isEdit}
              // value={hardStudy.bgd_title || ''}
              // is_required={true}
              defaultValue={search_params.phone ? search_params.phone : null}
              label={'휴대폰 번호'}
              onChange={onFilterChange}
            />
          </Col>

          <Col md={2}>
            <CustomInput
              type={'text'}
              name={'email'}
              // disabled={!isEdit}
              // value={hardStudy.bgd_title || ''}
              // is_required={true}
              defaultValue={search_params.email ? search_params.email : null}
              label={'이메일'}
              onChange={onFilterChange}
            />
          </Col>

          <Col md={2}>
            <CustomSelect2
              name={'is_completed'}
              label_title={'수료여부'}
              options={[
                { name: '전체', id: '' },
                { name: '수료', id: 'true' },
                { name: '미수료', id: 'false' },
              ]}
              defaultValue={search_params.is_completed ? search_params.is_completed : null}
              on_change={onFilterChange}
            />
          </Col>

          <Col className='text-right mt-auto' style={{ display: 'flex', alignItems: 'center' }}>
            <CustomTooltip
              target={'help-download-course-detail-favorite-csv'}
              content={
                '✅ 필터를 적용하지 않은 경우 현재 강의의 모든 북마크 데이터를 다운받습니다\n' +
                '✅ 필터를 적용한 경우 현재 강의의 모든 북마크 데이터 중 필터가 적용된 데이터만 다운받습니다'
              }
            />
            <Button
              id={'help-download-course-detail-favorite-csv'}
              className='btn btn-pill'
              color='info'
              style={{ marginRight: '10px', padding: '6px 20px' }}
              onClick={onDetailCSVRequest}
            >
              csv 다운로드
            </Button>
            <Button
              className='btn btn-pill'
              color='success'
              style={{ padding: '6px 20px' }}
              onClick={() => {
                onFilterSubmit();
              }}
            >
              필터 적용
            </Button>
          </Col>

          <Col sm={7} className={'m-t-20'}>
            <p style={{ margin: '7px 7px 15px 7px' }}>
              총: <span className={'txt-info'}>{usersCount}</span>명{' '}
              <Loading isLoading={isLoading} />
            </p>
          </Col>

          <BgdUserTable users={users} table_head={TABLE_HEAD_BGD_USER} />

          <div className='m-auto'>
            <CustomPagination
              current_page={search_params.page ? Number(search_params.page) : 1}
              max_page={Math.ceil(
                search_params.size ? usersCount / search_params.size : usersCount / 10,
              )}
              onPageChange={onPageChange}
            />
          </div>
        </>
      )}
    </Row>
  );
};

export default ParticipationInfo;
