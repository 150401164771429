import React, { useState } from 'react';
import CardListInput from '../section/CardListInput';
import CustomTypeahead from './CustomTypeahead';
import PropTypes from 'prop-types';

// static_list 값을 선택하여 get_list 배열 안 object, setGetList 추가되는 형태 설명 :
// "static_list 에서 선택된 object" 의 기본 key 와 value 는 유지하며, 추가적으로 get_list 배열 안 첫번째 object를 참조하여
// 첫번째 object의 key 값을 "static_list 에서 선택한 object" 에 동일한 key와 "" 의 값 value를 넣는다.
const propTypes = {
  unique_key: PropTypes.string, // String  | 중복 선택 하지 못하도록 options 에 있는 object 중 하나의 key 값 설정
  get_list: PropTypes.array, // [{...}] | 기본으로 선택 되어있는 배열
  setGetList: PropTypes.func, // func    | get_list state 핸들러 (handler)
  static_list: PropTypes.array, // [{...}] | select box 안에 들어갈 object 배열
  static_list_label_key: PropTypes.string, // String  | options 배열 안 object의 어떤 key 기준으로 선택으로 할 것 인지
  card_label_key: PropTypes.string, // String  | CardListInput 카드에 제목을 어떤 이름으로 설정할지
  card_label_link: PropTypes.func, // func    | CardListInput 카드에 제목을 어떤 링크로 만들 것인지 설정
  card_input_set: PropTypes.array, // [{...}] | CardListInput 카드에 들어갈 Input 정보들
  // get_list 배열 object에 있는 변경할 값이 들어있는 key 값과 card_input_set 중 name 이름을 가진 key의 value 은 같아야 한다
  // 예 )
  // ㄴ [{type: "number", label: "정렬값", name: "order_number", is_required: true, placeholder: "정렬", default_value: 0}, ]
  isEdit: PropTypes.bool, // boolean | 편집 가능 여부
  label: PropTypes.string, // String  | CardInputSeletor 라벨
  tooltip_desc: PropTypes.string, // String  | CardInputSeletor tooltip
  className: PropTypes.string, // String  | CardInputSeletor 클래스
  style: PropTypes.object, // object  | CardInputSeletor 스타일
  placeholder: PropTypes.string, // String  | CardInputSeletor placeholder
};

const CardInputSeletor = (props) => {
  const {
    isEdit,
    get_list,
    setGetList,
    tooltip_desc,
    static_list,
    static_list_label_key,
    placeholder,
    className,
    card_input_set,
    card_label_key,
    card_label_link,
    label,
    unique_key,
    style,
  } = props;
  const [search_text, setSearchText] = useState([]);

  return (
    <div className={className} style={style}>
      <CustomTypeahead
        className={'mb-3'}
        disabled={!isEdit}
        is_required={true}
        multiple={false}
        label={label}
        tooltip_desc={tooltip_desc}
        onChange={(selected_item) => {
          let is_unique = true;
          const new_obj = selected_item[0];

          setSearchText(selected_item);

          // card_input_set 을 기준으로 Card를 생성할 경우
          // card_input_set 에 설정한 name key를 추가한다.
          card_input_set.forEach((obj) => {
            Object.keys(obj).forEach((key) => {
              if (key === 'name') {
                let value = '';
                if (!(obj.default_value === undefined) && String(obj.default_value)) {
                  value = String(obj.default_value);
                }
                new_obj[obj['name']] = value;
              }
            });
          });

          // unique 를 위함
          if (unique_key) {
            get_list.forEach((obj) => {
              if (obj[unique_key] === new_obj[unique_key]) {
                is_unique = false;
              }
            });
          }

          if (is_unique) {
            setGetList((prevState) => [...prevState, new_obj]);
          }

          setSearchText([]);
        }}
        selected={search_text}
        labelKey={static_list_label_key}
        options={static_list}
        options_value_normalization={static_list_label_key}
        placeholder={placeholder}
      />
      <CardListInput
        card_label_key={card_label_key}
        card_label_link={card_label_link}
        card_input_set={card_input_set}
        isEdit={isEdit}
        items={get_list}
        setItems={setGetList}
      />
    </div>
  );
};

CardInputSeletor.propTypes = propTypes;

export default CardInputSeletor;
