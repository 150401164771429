import React, { Fragment, useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  FormGroup,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import Form from 'reactstrap/es/Form';
import SweetAlert from 'sweetalert2';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CouponModel from '../../model/CouponModel';
import Loading from '../_common/Loading';
import CommonModel from '../../model/CommonModel';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import CustomTypeahead from '../_common/component/CustomTypeahead';
import { errMessageOccur } from '../../util/common';
import CustomLabel from '../_common/component/CustomLabel';

const obj_err_msg = {
  title: '쿠폰명',
  coupon_text: '쿠폰텍스트',
  coupon_issue: '발행개수',
  can_unlimited_use: '일회성여부',
  can_use_at_extend_course: '강의연장유무',
  expire_time: '쿠폰만료일',
  currency_code_id: '화폐종류',
  discounts: '할인금액',
};

const CouponMultiple = (props) => {
  const isEdit = useState(!Number(props.match.params.coupon_id))[0];
  const [isLoading, setIsLoading] = useState(false);

  const [currencyCodeSet, SetCurrencyCodeSet] = useState([]);

  const [courseSelect, setCourseSelect] = useState([]);
  const [couponAllowProductTypeSelect, setCouponAllowProductTypeSelect] = useState([]);
  const [productSelect, setProductSelect] = useState([]);
  const [onlyBearuCourseSet, setOnlyBearuCourseSet] = useState([]);
  const [courseSet, setCourseSet] = useState([]);
  const [productTypeSet, setProductTypeSet] = useState([]);
  const [productSet, setProductSet] = useState([]);

  const [coupon, setCoupon] = useState({
    id: '',
    title: '',
    description: '',
    management_description: '',
    price_display_name: '',
    discounts: '',
    coupon_issue: '',
    coupon_count: 1,
    currency_code_id: '',
    can_unlimited_use: 0,
    can_use_at_extend_course: 1,
    create_time: '',
    expire_time: '',
    coupon_course_set: [],
    coupon_product_set: [],
    coupon_allow_product_type_set: [],
  });

  const [log, setLog] = useState('');

  const onCouponInputChange = (e) => {
    setCoupon({ ...coupon, [e.target.name]: e.target.value });
  };

  const onExpireDateTimeChange = (e) => {
    const date = moment(e);
    if (date.year() < 10000) {
      setCoupon({ ...coupon, expire_time: e && date.format('YYYY-MM-DDTHH:mm:ss') });
    }
  };

  const onExprieDateTimeSelect = (e) => {
    const {
      target: { value },
    } = e;

    let expire_time = moment();

    if (value === '0') {
      expire_time = '';
    } else if (value === '1') {
      expire_time = expire_time.add(3, 'months');
    } else if (value === '2') {
      expire_time = expire_time.add(6, 'months');
    } else if (value === '3') {
      expire_time = expire_time.add(12, 'months');
    }

    setCoupon({
      ...coupon,
      expire_time: expire_time && expire_time.format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  const onInputSubmit = async (e) => {
    e.preventDefault();

    let errMsg = errMessageOccur(coupon, obj_err_msg);

    if (!errMsg.length) {
      const resultCourseSelect = courseSelect.map((e) => e.id);
      const resultProductSelect = productSelect.map((e) => e.id);
      const resultCouponAllowProductTypeSelect = couponAllowProductTypeSelect
        .filter((e) => e.id !== 4)
        .map((e) => e.id);

      setIsLoading(true);
      e.target.disabled = true;
      SweetAlert.fire({
        title: '아래 내용과 같이 발급하시겠습니까?',
        // coupon
        html:
          "<table style='margin: auto' border='1'>" +
          "<tr><td class='px-2'>쿠폰명</td><td class='px-2'>" +
          coupon.title +
          '</td></tr>' +
          "<tr><td class='px-2'>설명</td><td class='px-2'>" +
          coupon.description +
          '</td></tr>' +
          "<tr><td class='px-2'>가격표시대체 문자열</td><td class='px-2'>" +
          coupon.price_display_name +
          '</td></tr>' +
          "<tr><td class='px-2'>화폐종류</td><td class='px-2'>" +
          coupon.currency_code_id +
          '</td></tr>' +
          "<tr><td class='px-2'>할인금액</td><td class='px-2'>" +
          coupon.discounts +
          '</td></tr>' +
          "<tr><td class='px-2'>일회성여부</td><td class='px-2'>" +
          coupon.can_unlimited_use +
          '</td></tr>' +
          "<tr><td class='px-2'>쿠폰만료일</td><td class='px-2'>" +
          moment(coupon.expire_time).format('YYYY-MM-DD HH:mm:ss') +
          '</td></tr>' +
          "<tr><td class='px-2'>발행개수</td><td class='px-2'>" +
          coupon.coupon_issue +
          '</td></tr>' +
          '</table>',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.value) {
          // 발행개수
          // setLog(`단체메일 전송 시작! 총 ${email_address_set.length} 건`);
          for (let x = 0; x < coupon.coupon_issue; x++) {
            await CouponModel.multipleCoupon({
              ...coupon,
              result_course_select: resultCourseSelect,
              result_product_select: resultProductSelect,
              result_coupon_allow_product_type_select: resultCouponAllowProductTypeSelect,
            }).then(({ coupon_text }) => {
              setLog((prevState) => prevState + `${coupon_text}\n`);
            });
          }
          setLog((prevState) => prevState + `발급 완료!\n`);

          SweetAlert.fire({
            title: `${log} 발급 완료!`,
            html:
              "<table style='margin: auto' border='1'>" +
              "<tr><td class='px-2'>쿠폰명</td><td class='px-2'>" +
              coupon.title +
              '</td></tr>' +
              "<tr><td class='px-2'>설명</td><td class='px-2'>" +
              coupon.description +
              '</td></tr>' +
              "<tr><td class='px-2'>가격표시대체 문자열</td><td class='px-2'>" +
              coupon.price_display_name +
              '</td></tr>' +
              "<tr><td class='px-2'>화폐종류</td><td class='px-2'>" +
              coupon.currency_code_id +
              '</td></tr>' +
              "<tr><td class='px-2'>할인금액</td><td class='px-2'>" +
              coupon.discounts +
              '</td></tr>' +
              "<tr><td class='px-2'>일회성여부</td><td class='px-2'>" +
              coupon.can_unlimited_use +
              '</td></tr>' +
              "<tr><td class='px-2'>쿠폰만료일</td><td class='px-2'>" +
              moment(coupon.expire_time).format('YYYY-MM-DD HH:mm:ss') +
              '</td></tr>' +
              "<tr><td class='px-2'>발행개수</td><td class='px-2'>" +
              coupon.coupon_issue +
              '</td></tr>' +
              '</table>',
          });
        }
      });

      e.target.disabled = false;
      setIsLoading(false);
    } else {
      await SweetAlert.fire({
        title: '발급중 문제를 발견했습니다.',
        text: errMsg.join('\n'),
      });
    }
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'currency_code_set' }).then(({ code_set }) => {
      SetCurrencyCodeSet(code_set);

      if (!Number(props.match.params.coupon_id)) {
        setCoupon({ ...coupon, currency_code_id: code_set[0]?.id });
      }
    });

    CommonModel.getSearch('accessible_course_price_displaying').then((data) => {
      const new_data = data.map(({ course_id: id, combine_name: title }) => {
        return { id, title };
      });
      setCourseSet(new_data);
    });

    CommonModel.staticCode({ code_name: 'product_type_set' }).then(({ code_set }) => {
      setProductTypeSet([...code_set]);
    });

    CommonModel.getSearch('only_bearu_course').then((data) => {
      const new_data = data.map(({ id: id, combine_name: title }) => {
        return { id, title };
      });
      setOnlyBearuCourseSet(new_data);
    });

    CommonModel.getSearch('cms_accessible_product').then((data) => {
      setProductSet(data);
    });
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent='쿠폰' title={`대량 쿠폰 발급`} />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form onSubmit={onInputSubmit}>
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`대량 쿠폰 발급`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            {isLoading ? '진행중...' : '발급'}
                          </Button>
                        </>
                      )}
                      <Button
                        className='btn-pill'
                        color='primary'
                        style={{ marginRight: '8px' }}
                        onClick={() => {
                          // 생성(0)일 경우 수정(0 이상)일 경우
                          props.history.push('/coupon');
                        }}
                      >
                        {isEdit ? '취소' : '편집'}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={7} style={{ borderRight: '2px solid #F5F5F5' }}>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'title'}
                            disabled={!isEdit}
                            style={{ resize: 'none' }}
                            value={coupon.title || ''}
                            is_required={true}
                            label={'쿠폰명'}
                            onChange={onCouponInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'description'}
                            disabled={!isEdit}
                            value={coupon.description || ''}
                            label={'설명'}
                            onChange={onCouponInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'management_description'}
                            disabled={!isEdit}
                            value={coupon.management_description || ''}
                            label={'관리용 설명'}
                            onChange={onCouponInputChange}
                            validator={(value) => {
                              return value.length <= 12;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12'>
                          <CustomLabel label={'쿠폰텍스트'} />
                          <FormGroup>
                            <InputGroup>
                              <Input
                                type={'text'}
                                name={'coupon_text'}
                                disabled={true}
                                value={''}
                                placeholder={
                                  '[자동으로 생성됩니다] 쿠폰텍스트는 중복 될 수 없습니다. (최대 36자)'
                                }
                              />
                              <InputGroupAddon addonType='append' style={{ cursor: 'pointer' }}>
                                <InputGroupText>
                                  <i className='icofont icofont-random mr-2' /> 생성
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'currency_code_id'}
                            disabled={!!Number(props.match.params.coupon_id)}
                            value={coupon.currency_code_id || ''}
                            label={'화폐종류'}
                            is_required={true}
                            onChange={onCouponInputChange}
                            options={currencyCodeSet}
                            sub_option_type={'description'}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'discounts'}
                            disabled={!!Number(props.match.params.coupon_id)}
                            value={coupon.discounts || ''}
                            label={'할인금액'}
                            is_required={true}
                            onChange={onCouponInputChange}
                            validator={(value) => {
                              const regexp = /^[0-9\b]*$/;
                              return regexp.test(value);
                            }}
                          />
                        </Col>
                        <Col md='6 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'price_display_name'}
                            disabled={!isEdit}
                            value={coupon.price_display_name || ''}
                            label={'가격표시 대체 문자열'}
                            tooltip_desc={
                              '입력하는 경우 서비스내 할인금액 표시부분에서 할인금액 대신 이 문자열로 표시됩니다.\n고객은 구체적인 할인금액 내용을 인지 할 수 없게 됩니다.'
                            }
                            onChange={onCouponInputChange}
                            placeholder={'입력하게되면 할인 금액 대신 이 문자열을 표시합니다'}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <Col md='mb-3 ml-3'>
                          <CustomLabel label={'쿠폰만료일'} is_required={true} />
                          <DatePicker
                            autoComplete='off'
                            className='form-control digits'
                            name='expire_time'
                            disabled={!!Number(props.match.params.coupon_id)}
                            onChange={onExpireDateTimeChange}
                            locale='ko'
                            selected={coupon.expire_time ? new Date(coupon.expire_time) : null}
                            timeFormat='HH:mm:ss'
                            showTimeSelect
                            dateFormat='yyyy-MM-dd HH:mm:ss'
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'date_range'}
                            disabled={!!Number(props.match.params.coupon_id)}
                            label={'날짜선택'}
                            onChange={onExprieDateTimeSelect}
                            options={[
                              { id: 0, name: '------' },
                              { id: 1, name: '3개월' },
                              { id: 2, name: '6개월' },
                              { id: 3, name: '1년' },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'number'}
                            name={'coupon_count'}
                            disabled={true}
                            value={1}
                            is_required={true}
                            label={'사용가능횟수'}
                            tooltip_desc={
                              '해당 쿠폰으로 임의의 유저들이 얼마나 사용 가능한지에 대한 횟수\n(예를들어 10인경우는, 해당 쿠폰텍스트로 10명의 유저가 구매 가능함, 1인 경우는 1명만 구매가 가능함).'
                            }
                          />
                        </Col>
                        <Col md='4 mb-3'>
                          <CustomSelectInput
                            name={'can_unlimited_use'}
                            disabled={true}
                            value={coupon.can_unlimited_use || ''}
                            label={'일회성여부'}
                            is_required={true}
                            tooltip_desc={
                              '발급받은 쿠폰으로 유저가 여러번 사용할 수 있는지 여부\n(예를들어 일회성인 경우 구매시에 사용자가 단 한번만 사용할 수 있음, 제한없음인 경우 사용자가 동일한 쿠폰을 여러번  사용할 수 있음).'
                            }
                            onChange={onCouponInputChange}
                            options={[
                              {
                                id: '0',
                                name: '일회성',
                              },
                              {
                                id: '1',
                                name: '제한없음',
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='8 mb-3'>
                          <CustomTypeahead
                            disabled={!!Number(props.match.params.coupon_id)}
                            multiple={true}
                            label={'사용가능처 지정'}
                            tooltip_desc={
                              '사용가능처를 지정하지 않으면 모든 상품 구매에 이 쿠폰이 사용 가능하게 됩니다.\n사용가능처를 지정하면 특정 상품 구매에만 사용 가능하도록 설정됩니다.\n' +
                              '본 설정은 지정한 "대상 강의들"(또는 이벤트 해당강의 등) 옵션보다 먼저 사용됩니다.'
                            }
                            onChange={(e) => {
                              // 추가한 정보 알아오기...
                              let selected;

                              // 차집합
                              if (couponAllowProductTypeSelect.length < e.length) {
                                selected = e.filter(
                                  (value) =>
                                    !couponAllowProductTypeSelect
                                      .map((val) => val.id)
                                      .includes(value.id),
                                )[0];
                              }

                              setCourseSelect([]);
                              setProductSelect([]);

                              if (
                                e.some((el) => el.name === '커스텀선택') &&
                                e.some((el) => el.name === '베어유 강의')
                              ) {
                                const customIndex = e.findIndex(
                                  (item) => item.name === '커스텀선택',
                                );
                                const bearLectureIndex = e.findIndex(
                                  (item) => item.name === '베어유 강의',
                                );
                                const biggerIndex = Math.max(customIndex, bearLectureIndex);
                                const result = [e[biggerIndex]];
                                setCouponAllowProductTypeSelect(result);
                                if (e[biggerIndex].name === '베어유 강의') {
                                  setCourseSelect(onlyBearuCourseSet);
                                }
                              } else {
                                if (
                                  e.some((el) => el.name === '커스텀선택') ||
                                  e.some((el) => el.name === '베어유 강의')
                                ) {
                                  setCouponAllowProductTypeSelect([selected]);
                                  if (selected.name === '베어유 강의') {
                                    setCourseSelect(onlyBearuCourseSet);
                                  }
                                }
                              }
                            }}
                            selected={couponAllowProductTypeSelect}
                            selectedHandler={setCouponAllowProductTypeSelect}
                            labelKey={'name'}
                            customToken={(target) => {
                              return `${target.name} : ${target.description}`;
                            }}
                            options={productTypeSet}
                          />
                        </Col>
                        <Col>
                          <CustomSelectInput
                            name={'can_use_at_extend_course'}
                            disabled={!isEdit}
                            value={coupon.can_use_at_extend_course || ''}
                            label={'강의연장유무'}
                            tooltip_desc={"'강의 연장하기'에서 해당 쿠폰을 사용 못하도록 설정"}
                            is_required={true}
                            onChange={onCouponInputChange}
                            options={[
                              { id: 0, name: '불가' },
                              { id: 1, name: '가능' },
                            ]}
                          />
                        </Col>
                      </Row>
                      {['커스텀선택', '베어유 강의'].map((type) => {
                        const isCustomSelect = type === '커스텀선택';
                        const element = couponAllowProductTypeSelect.find(
                          (element) => element?.name === type,
                        );

                        return (
                          element && (
                            <React.Fragment key={type}>
                              {isCustomSelect && (
                                <Row>
                                  <Col md='12 mb-3'>
                                    <CustomTypeahead
                                      disabled={!!Number(props.match.params.coupon_id)}
                                      multiple={true}
                                      label={'대상 패키지들'}
                                      tooltip_desc={
                                        '신규 등록시에만 입력 가능합니다, 수정되지 않으므로 반드시 확인하세요.\n(입력 항목이 없으면 자동으로 모든 패키지를 구매할 수 있도록 설정됩니다.)'
                                      }
                                      selected={productSelect}
                                      selectedHandler={setProductSelect}
                                      onTokenClick={(target) => {
                                        window.open('/product/' + target.id, '_blank');
                                      }}
                                      labelKey={'combine_name'}
                                      customToken={(target) => `${target.combine_name}`}
                                      options={productSet}
                                    />
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                <Col md='12 mb-3'>
                                  <CustomTypeahead
                                    disabled={!!Number(props.match.params.coupon_id)}
                                    multiple={true}
                                    label={'대상 강의들'}
                                    tooltip_desc={
                                      '신규 등록시에만 입력 가능합니다, 수정되지 않으므로 반드시 확인하세요.\n(입력 항목이 없으면 자동으로 모든 강의를 구매할 수 있도록 설정됩니다.)'
                                    }
                                    selected={courseSelect}
                                    selectedHandler={setCourseSelect}
                                    onTokenClick={(target) => {
                                      window.open('/course/' + target.id, '_blank');
                                    }}
                                    labelKey={'title'}
                                    customToken={(target) => `${target.title}`}
                                    options={courseSet}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                        );
                      })}
                      <hr />
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'number'}
                            name={'coupon_issue'}
                            value={coupon.coupon_issue || ''}
                            is_required={true}
                            label={'발행개수'}
                            tooltip_desc={'쿠폰 생성 개수'}
                            onChange={onCouponInputChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'textarea'}
                            label={'로그'}
                            value={log}
                            disabled={true}
                            style={{ height: 200 }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CouponMultiple;
