import { Input, Table } from 'reactstrap';
import TabPane from 'reactstrap/es/TabPane';
import React, { useEffect, useState } from 'react';

export default function InvoiceInfoTable(props) {
  const { invoice_info_set, static_invoice_type_set, account_name, isEdit } = props;
  const [cur_invoice_info_set, curSetInvoiceInfoSet] = useState(invoice_info_set);

  useEffect(() => {
    curSetInvoiceInfoSet(invoice_info_set);
  }, [invoice_info_set]);

  const onChange = (e, index) => {
    const { name, value } = e.target;
    if (name === 'commission_rate') {
      const cur_invoice_info = invoice_info_set[index];
      const first_invoice_info = invoice_info_set[0];
      cur_invoice_info[name] = value;
      cur_invoice_info['bank_holder'] = first_invoice_info['bank_holder'];
      cur_invoice_info['bank_name'] = first_invoice_info['bank_name'];
      cur_invoice_info['bank_number'] = first_invoice_info['bank_number'];
      curSetInvoiceInfoSet([...invoice_info_set]);
    } else {
      for (const invoice_info of invoice_info_set) {
        invoice_info[name] = value;
      }
      curSetInvoiceInfoSet([...invoice_info_set]);
    }
  };

  return (
    <TabPane tabId='2'>
      <div className='table-responsive'>
        <Table className='table-border-vertical invoice-table'>
          <thead>
            <tr style={{ borderTop: '2px solid #dee2e6' }}>
              <th className='text-center' style={{ borderLeft: '1px solid #f2f4ff' }}>
                <p style={{ fontSize: '14px', fontWeight: '550' }} className='p-2 mb-0'>
                  고객명
                </p>
              </th>
              <th className='text-center'>
                <p style={{ fontSize: '14px', fontWeight: '550' }} className='p-2 mb-0'>
                  강의명
                </p>
              </th>
              <th className='text-center'>
                <p style={{ fontSize: '14px', fontWeight: '550' }} className='p-2 mb-0'>
                  회원 속성
                </p>
              </th>
              <th className='text-center'>
                <p style={{ fontSize: '14px', fontWeight: '550' }} className='p-2 mb-0'>
                  은행명
                </p>
              </th>
              <th className='text-center'>
                <p style={{ fontSize: '14px', fontWeight: '550' }} className='p-2 mb-0'>
                  계좌번호
                </p>
              </th>
              <th className='text-center'>
                <p style={{ fontSize: '14px', fontWeight: '550' }} className='p-2 mb-0'>
                  예금주
                </p>
              </th>
              <th className='text-center'>
                <p style={{ fontSize: '14px', fontWeight: '550' }} className='p-2 mb-0'>
                  수익분배율
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {cur_invoice_info_set
              ? cur_invoice_info_set.map((invoice_info, info_index) => {
                  return (
                    <tr key={info_index}>
                      {info_index === 0 ? (
                        <>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              borderLeft: '1px solid #f2f4ff',
                              borderBottom: '2px solid #dee2e6',
                            }}
                            rowSpan={invoice_info_set.length}
                          >
                            <p
                              style={{
                                fontSize: '13px',
                                marginTop: '6px',
                                fontWeight: '600',
                              }}
                            >
                              {account_name}
                            </p>
                          </td>
                        </>
                      ) : null}
                      <td
                        className='text-center'
                        style={
                          info_index === invoice_info_set.length - 1
                            ? { borderBottom: '2px solid #dee2e6' }
                            : { borderBottom: '1px solid #dee2e6' }
                        }
                      >
                        <p
                          style={{
                            fontSize: '13px',
                            marginTop: '6px',
                            fontWeight: '600',
                          }}
                        >
                          {invoice_info.short_title}
                        </p>
                      </td>
                      {info_index === 0 ? (
                        <>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              borderBottom: '2px solid #dee2e6',
                            }}
                            rowSpan={invoice_info_set.length}
                          >
                            <Input
                              type={'select'}
                              disabled={!isEdit}
                              style={{ fontSize: '13px' }}
                              name='invoice_user_type'
                              value={Number(invoice_info.invoice_user_type)}
                              onChange={(e) => {
                                onChange(e, info_index);
                              }}
                            >
                              <option value={0}>없음</option>
                              {static_invoice_type_set.length > 0 &&
                                static_invoice_type_set.map((type) => (
                                  <option key={type.id} value={type.id}>
                                    {type.name}
                                  </option>
                                ))}
                            </Input>
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              borderBottom: '2px solid #dee2e6',
                            }}
                            rowSpan={invoice_info_set.length}
                          >
                            <Input
                              type={'text'}
                              disabled={!isEdit}
                              name='bank_name'
                              style={{ fontSize: '13px' }}
                              value={invoice_info.bank_name}
                              onChange={(e) => {
                                onChange(e, info_index);
                              }}
                            />
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              borderBottom: '2px solid #dee2e6',
                            }}
                            rowSpan={invoice_info_set.length}
                          >
                            <Input
                              type={'text'}
                              disabled={!isEdit}
                              name='bank_number'
                              style={{ fontSize: '13px' }}
                              value={invoice_info.bank_number}
                              onChange={(e) => {
                                const regexp = /^[0-9\b]*$/;
                                if (regexp.test(e.target.value)) {
                                  onChange(e, info_index);
                                }
                              }}
                            />
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              borderBottom: '2px solid #dee2e6',
                            }}
                            rowSpan={invoice_info_set.length}
                          >
                            <Input
                              type={'text'}
                              disabled={!isEdit}
                              name='bank_holder'
                              style={{ fontSize: '13px' }}
                              value={invoice_info.bank_holder}
                              onChange={(e) => {
                                onChange(e, info_index);
                              }}
                            />
                          </td>
                        </>
                      ) : null}
                      <td
                        className='text-center'
                        style={
                          info_index === invoice_info_set.length - 1
                            ? { borderBottom: '2px solid #dee2e6' }
                            : { borderBottom: '1px solid #dee2e6' }
                        }
                      >
                        <Input
                          type={'text'}
                          disabled={!isEdit}
                          style={{ fontSize: '13px' }}
                          name='commission_rate'
                          value={invoice_info.commission_rate}
                          onChange={(e) => {
                            onChange(e, info_index);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </div>
    </TabPane>
  );
}
