import axios from 'axios';

class RecruitFormModel {
  async postRecruitForm(payload) {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/bgd/recruit-form',
      payload,
    );
    return data;
  }

  async getRecruitForms(filter) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/bgd/recruit-forms',
      {
        params: filter,
      },
    );

    return data;
  }

  async getRecruitForm(recruitFormId) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/bgd/recruit-form/${recruitFormId}`,
    );
    return data;
  }

  async getRecruitFormResponses(recruitFormId, filter) {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + `/cms-api/v1/bgd/bgd-recruit-form/${recruitFormId}/responses`,
      {
        params: filter,
      },
    );
    return data;
  }

  async postUploadThumbnail(file) {
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/common/file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data;
  }
}

export default new RecruitFormModel();
