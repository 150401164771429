import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';

import {
  stored_course_subject,
  add_course_subject,
  clear_course_subject,
  delete_course_subject,
  set_course_subject,
} from '../../../redux/course/subject/action';
import CourseModel from '../../../model/CourseModel';
import EbookModel from '../../../model/EbookModel';
import SubjectModal from '../atom/SubjectModal';
import Loading from '../../_common/Loading';

const Subject = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [is_modal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [req_type, setReqType] = useState(null);
  const [subject_index, setSubjectIndex] = useState(null);

  const initData = () => {
    if (parseInt(props.obj_id)) {
      EbookModel.getCourseSubject(props.obj_id).then(({ subject_set }) => {
        props.stored_course_subject(subject_set);
      });
    }
  };

  useEffect(() => {
    initData();
    return () => {
      props.clear_course_subject();
    };
  }, []);

  const CourseSubject = props.CourseSubject;

  const onChange = (data_set) => {
    try {
      data_set.forEach((elem) => {
        const column_number = elem[1];
        let name;
        switch (column_number) {
          case 0:
            name = 'id';
            break;
          case 1:
            name = 'order_number';
            break;
          case 2:
            name = 'index_name';
            break;
          case 3:
            name = 'title';
            break;
          case 4:
            name = 'chapter_description';
            break;
          case 5:
            name = 'vimeo_id';
            break;
          case 6:
            name = 'preview_youtube_id';
            break;
          case 7:
            name = 'description';
            break;
          case 8:
            name = 'function_description';
            break;
          case 9:
            name = 'thumbnail_image_url';
            break;
          default:
            break;
        }
        props.set_course_subject(elem[0], name, elem[elem.length - 1]);
      });
    } catch (error) {}
  };

  function imgRenderer(instance, td, row, col, prop, value, cellProperties) {
    const stringified_value = Handsontable.helper.stringify(value);
    if (stringified_value.startsWith('https')) {
      const img_tag = document.createElement('IMG');
      img_tag.src = stringified_value;
      Handsontable.dom.addEvent(img_tag, 'mousedown', (event) => {
        event.preventDefault(); // prevent selection quirk
      });
      Handsontable.dom.empty(td);
      td.appendChild(img_tag);
    } else {
      Handsontable.renderers.TextRenderer.apply(this, arguments);
    }
  }

  const hotSettings = {
    data: CourseSubject,
    manualColumnResize: true,
    manualRowResize: true,
    readOnly: !isEdit,
    colWidths: [50, 40, 60, 300, 300, 130, 150, 400, 200, 500],
    width: '2200',
    height: '500',
    colHeaders: [
      '아이디',
      '순서',
      '순서명',
      '제목',
      '챕터 설명',
      '비메오 ID',
      '유튜브 ID',
      '강의 설명',
      'SEO 설정',
      '썸네일',
    ],
    contextMenu: isEdit
      ? {
          items: {
            row_below: {
              name: 'Insert row above this one (custom name)',
            },
            remove_row: {
              name: 'b2b_remove row',
              callback: function () {
                const selected = this.getSelectedLast();
                props.delete_course_subject(selected[0], selected[2]);
              },
            },
          },
        }
      : null,
    columns: [
      { data: 'id', renderer: 'html', readOnly: true },
      { data: 'order_number', renderer: 'html' },
      { data: 'index_name', renderer: 'html' },
      { data: 'title', renderer: 'html' },
      { data: 'chapter_description', renderer: 'html' },
      { data: 'vimeo_id', renderer: 'html' },
      { data: 'preview_youtube_id', renderer: 'html' },
      { data: 'description', renderer: 'html' },
      { data: 'function_description', renderer: 'html' },
      { data: 'thumbnail_image_url', renderer: imgRenderer, readOnly: true },
    ],
    afterOnCellMouseDown: isEdit
      ? function (event, cell_coords) {
          setSubjectIndex(cell_coords.row);
          if (cell_coords.col === 7) {
            setReqType('txt');
            setIsModal(!is_modal);
          } else if (cell_coords.col === 9) {
            setReqType('img');
            setIsModal(!is_modal);
          }
        }
      : null,
    licenseKey: 'non-commercial-and-evaluation',
  };

  const saveSubject = async () => {
    setIsLoading(true);
    props.stored_course_subject(await CourseModel.postCourseSubject(props.obj_id, CourseSubject));
    setIsEdit(false);
    setIsLoading(false);
  };

  return (
    <div>
      <Row style={{ marginBottom: '-20px' }}>
        <Col sm={7}>
          <p style={{ marginTop: '7px' }}>
            총: <span style={{ color: 'coral' }}>{CourseSubject.length}</span>강{' '}
            <Loading isLoading={isLoading} />
          </p>
        </Col>
        <Col className='text-right'>
          <Col>
            {/*<a*/}
            {/*    className="btn btn-pill btn-info mr-2"*/}
            {/*    href={`https://bear-u.com/course/${Number(props.obj_id)}/viewer`}*/}
            {/*    target="_blank"*/}
            {/*    rel="noreferrer">*/}
            {/*    미리보기*/}
            {/*</a>*/}
            {isEdit && (
              <>
                <Button
                  className='btn-pill'
                  color='primary'
                  style={{ marginRight: '8px' }}
                  onClick={() => {
                    props.add_course_subject(CourseSubject.length ? CourseSubject.length + 1 : 1);
                  }}
                >
                  행 추가
                </Button>
                <Button
                  className='btn-pill'
                  color='primary'
                  style={{ marginRight: '8px' }}
                  onClick={saveSubject}
                >
                  저장
                </Button>
              </>
            )}
            {
              <>
                <Button
                  className={isEdit ? 'btn-pill btn btn-light' : 'btn-pill'}
                  color={isEdit ? '' : 'primary'}
                  style={{ marginRight: '8px' }}
                  onClick={() => {
                    if (isEdit) {
                      initData();
                    }
                    setIsEdit(!isEdit);
                  }}
                >
                  {isEdit ? <>취소</> : <>편집</>}
                </Button>
              </>
            }
          </Col>
        </Col>
      </Row>
      <div className='table-responsive' style={{ marginTop: '40px' }}>
        <div id='hot-app'>
          <SubjectModal
            id={props.obj_id}
            subject_index={subject_index}
            isEdit={isEdit}
            is_modal={is_modal}
            req_type={req_type}
          />
          <HotTable id={'hot'} beforeChange={onChange} settings={hotSettings}></HotTable>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  CourseSubject: state.CourseSubjectStore,
});
const mapDispatchToProps = (dispatch) => {
  return {
    stored_course_subject: (data) => dispatch(stored_course_subject(data)),
    add_course_subject: (add_index) => dispatch(add_course_subject(add_index)),
    clear_course_subject: () => dispatch(clear_course_subject()),
    delete_course_subject: (subject_start_index, subject_end_index) =>
      dispatch(delete_course_subject(subject_start_index, subject_end_index)),
    set_course_subject: (subject_index, name, value) =>
      dispatch(set_course_subject(subject_index, name, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subject);
