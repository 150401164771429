import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import SweetAlert from 'sweetalert2';
import Button from 'reactstrap/es/Button';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import AccountModel from '../../../model/AccountModel';
import CourseModel from '../../../model/CourseModel';
import Loading from '../../_common/Loading';

const AccountCourseTable = (props) => {
  const [course_set, setCourseSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [course_action, setCourseAction] = useState('');
  const [target_account_id, SetTargetAccountId] = useState('');
  const [changeCourse, SetChangeCourse] = useState('');
  const [selected_course_list, setSelectionCourseList] = useState([]);
  const [user_id, setUserId] = useState();

  useEffect(() => {
    setUserId(props.additionalProps);
    setIsLoading(true);
    AccountModel.getAccountCourse(props.additionalProps).then(({ course }) => {
      setCourseSet(course);
      setIsLoading(false);
    });
  }, []);

  const columns = [
    {
      name: '강의명',
      selector: 'course.short_title',
      sortable: true,
      center: true,
      width: '400px',
      cell: (row) => <Link to={`/course/${row.course.id}`}>{row.course.short_title}</Link>,
    },
    {
      name: '대여일',
      selector: 'rental_time',
      sortable: true,
      center: true,
      width: '200px',
      cell: (row) => <div>{row.rental_time ? row.rental_time.replace('T', ' ') : ''}</div>,
    },
    {
      name: '만료일',
      selector: 'rental_expire_time',
      sortable: true,
      center: true,
      width: '200px',
      cell: (row) => (
        <div className='text-center'>
          {(() => {
            if (row.rental_expire_time) {
              let expire_time = row.rental_expire_time.replace('T', ' ');
              let left_day = moment(new Date(expire_time)).diff(moment(new Date()), 'day');
              if (left_day < 0) {
                left_day = '만료됨';
              } else {
                left_day = left_day + '일 남음';
              }
              return (
                <>
                  <div>{expire_time}</div>
                  {left_day === '만료됨' ? (
                    <div className='text-danger'>({left_day})</div>
                  ) : (
                    <div>({left_day})</div>
                  )}
                </>
              );
            } else {
              return '';
            }
          })()}
        </div>
      ),
    },
    {
      name: '시청기록보기',
      selector: 'view_log',
      center: true,
      width: '150px',
      cell: (row) => (
        <Link to={`/account/${props.additionalProps}/subject-viewlog/${row.course.id}`}>
          기록보기
        </Link>
      ),
    },
    {
      name: '',
      selector: 'view_log',
      center: true,
      width: '150px',
      cell: (row) => (
        <Button
          className='btn-sm btn-danger rounded ml-2 my-1'
          value='change'
          onClick={(e) => {
            onCourseChangeClick(e, row.id);
          }}
        >
          강의변경
        </Button>
      ),
    },
    {
      name: '관리용설명',
      selector: 'management_tag',
      sortable: true,
      center: true,
      style: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'center',
        justifyContent: 'flex-end',
      },
      sortFunction: (row1, row2) =>
        ((e) => {
          return row1.management_tag_set.length - row2.management_tag_set.length;
        })(),
      cell: (row) =>
        (() => {
          if (row.management_tag_set) {
            const _management_description = row.management_tag_set.map((managementtag, index) => {
              return (
                <div
                  className='btn-sm btn-dark rounded px-2 mx-1'
                  key={index}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {managementtag.tag_desc}
                  <button
                    className='btn-sm btn-danger rounded icofont icofont-ui-delete ml-2 my-1'
                    onClick={() => {
                      onManagementDelete(managementtag.id);
                    }}
                  ></button>
                </div>
              );
            });
            return (
              <>
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  {_management_description}
                </div>
                <button
                  className='btn-sm btn-success rounded'
                  onClick={() => {
                    onManagementCreate(row.id);
                  }}
                >
                  <i className='icofont icofont-ui-add' />
                </button>
              </>
            );
          }
        })(),
    },
  ];

  // ?? 도당최 useCallback 은 무엇?
  const handleRowSelected = (state) => {
    setSelectionCourseList(state);
  };

  const onManagementDelete = (management_tag_id) => {
    SweetAlert.fire({
      title: '정말로 삭제하시겠습니까?',
      confirmButtonText: '예',
      cancelButtonText: '아니오',
      showCancelButton: true,
    }).then(async (e) => {
      if (e.isConfirmed) {
        setIsLoading(true);
        await AccountModel.deleteManagementTag(management_tag_id);
        AccountModel.getAccountCourse(props.additionalProps).then(({ course }) => {
          setCourseSet(course);
          setIsLoading(false);
        });
      }
    });
  };

  const onManagementCreate = (user_course_id) => {
    SweetAlert.fire({
      title: '추가할 관리용 설명을 적으세요.',
      input: 'text',
      confirmButtonText: '추가',
      cancelButtonText: '취소',
      showCancelButton: true,
      inputAttributes: {
        maxlength: 12,
        autocapitalize: 'off',
        autocorrect: 'off',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.value) {
          const data = {
            description: result.value,
            usercourse_id: user_course_id,
          };
          setIsLoading(true);
          await AccountModel.postManagementTag(data);
          AccountModel.getAccountCourse(props.additionalProps).then(({ course }) => {
            setCourseSet(course);
            setIsLoading(false);
          });
        }
      }
    });
  };

  //강의 변경
  const onCourseChangeClick = (e, course_id) => {
    SweetAlert.fire({
      title: '변경될 강의',
      showCancelButton: true,
      cancelButtonText: 'cancel',
      confirmButtonText: 'Ok',
      reverseButtons: false,
      input: 'text',
      inputLabel: '변경될 강의 코드를 입력해 주세요',
      inputValue: '',
      inputAttributes: {
        autoCapitalize: 'off',
        autoCorrect: 'off',
      },
      inputValidator: (value) => {
        if (value.replace(/ /g, '').replace(/[0-9]/g, '') === '') {
          let response = null;
          response = CourseModel.patchCourseChange(
            course_id,
            value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'),
          );
          //넘어온 promise 값 확인
          response.then((data) => {
            let text =
              data.message === 'success'
                ? '강의 변경에 성공하였습니다.'
                : '강의 변경에 실패하었습니다.';
            SweetAlert.fire({
              title: text,
            })
              .then((result) => {
                if (data.message === 'success') {
                  if (result.value) {
                    setIsLoading(true);
                    AccountModel.getAccountCourse(props.additionalProps).then(({ course }) => {
                      setCourseSet(course);
                      setIsLoading(false);
                    });
                  }
                } else {
                  setCourseAction('');
                  SetTargetAccountId('');
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        } else {
          return '올바른 계정 코드를 입력해주세요!';
        }
      },
    }).then((result) => {
      if (result.dismiss == 'cancel') {
        setCourseAction('');
      }
    });
  };

  if (course_action === 'transfer' && target_account_id == '') {
    SweetAlert.fire({
      title: '강의를 이전받을 계정',
      showCancelButton: true,
      cancelButtonText: 'cancel',
      confirmButtonText: 'Ok',
      reverseButtons: false,
      input: 'text',
      inputLabel: '강의를 이전받을 계정의 코드를 입력해 주세요',
      inputValue: '',
      inputAttributes: {
        autoCapitalize: 'off',
        autoCorrect: 'off',
      },
      inputValidator: (value) => {
        if (value.replace(/ /g, '').replace(/[0-9]/g, '') === '') {
          SetTargetAccountId(value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'));
        } else {
          return '올바른 계정 코드를 입력해주세요!';
        }
      },
    }).then((result) => {
      if (result.dismiss == 'cancel') {
        setCourseAction('');
      }
    });
  }

  //강의 관련 상태 값 변경 : 이전, 연장, 만료
  const onCourseActionClick = (e) => {
    setCourseAction(e.target.value);
  };

  //강의 이전 적용
  const courseTransfer = (selected_course_list, account_id) => {
    let response = null;
    selected_course_list.forEach(
      (element) => (response = CourseModel.patchCourseTransferAccount(element.id, account_id)),
    );
    //넘어온 promise 값 확인
    response.then((data) => {
      let text =
        data.message === 'success'
          ? '강의 ' + courseActionName + '에 성공하였습니다.'
          : '강의 ' + courseActionName + '이 실패하었습니다.';

      SweetAlert.fire({
        title: text,
      })
        .then((result) => {
          if (data.message === 'success') {
            if (result.value) {
              setIsLoading(true);
              AccountModel.getAccountCourse(props.additionalProps).then(({ course }) => {
                setCourseSet(course);
                setIsLoading(false);
              });
            }
          } else {
            setCourseAction('');
            SetTargetAccountId('');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  //강의 연장, 만료 적용
  const courseEndDateChange = (user_course_id, date_time, changeType, selected_course_list) => {
    if (changeType === 'expired') {
      date_time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    }

    let response = null;
    selected_course_list.forEach(
      (element) => (response = CourseModel.patchCourseEndDateChange(element.id, date_time)),
    );

    //넘어온 promise 값 확인
    response.then((data) => {
      let text =
        data.message === 'success'
          ? '강의가 ' + courseActionName + '되었습니다.'
          : '강의 ' + courseActionName + '에 실패하었습니다.';
      SweetAlert.fire({
        title: text,
      })
        .then((result) => {
          if (data.message === 'success') {
            if (result.value) {
              setIsLoading(true);
              AccountModel.getAccountCourse(props.additionalProps).then(({ course }) => {
                setCourseSet(course);
                setIsLoading(false);
              });
            }
          } else {
            setCourseAction('');
            SetTargetAccountId('');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  //강의 수정 취소
  const onCourseActionCancel = (e) => {
    setCourseAction(e.target.value);
    //init
    SetTargetAccountId('');
  };

  const [test, setTest] = useState(new Date());
  const [course_expire_time, setCourseExpireTime] = useState(
    moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  );

  const onDateTimeChange = (e, name) => {
    const date = moment(e);
    if (date.year() < 10000) {
      setCourseExpireTime(date.format('YYYY-MM-DD HH:mm:ss'));
    }
  };

  const [courseActionName, setCourseActionName] = useState('');
  useEffect(() => {
    if (course_action === 'transfer') {
      setCourseActionName('이전');
    } else if (course_action === 'extension') {
      setCourseActionName('연장');
    } else if (course_action === 'expired') {
      setCourseActionName('만료');
    } else if (course_action === 'change') {
      setCourseActionName('변경');
    }
  }, [course_action]);

  return (
    <div className='table-responsive'>
      <div style={isLoading ? { display: 'block' } : { display: 'none' }} className='mb-4'>
        <Loading isLoading={isLoading} />
      </div>
      <div className={'text-right d-flex align-items-center p-0 pb-2 justify-content-between'}>
        {course_action === 'transfer' && target_account_id !== '' ? (
          <div className='col-md-2 p-0'>
            <div className='text-left'>
              <p className='fs-5'>
                강의가 이전될 계정 코드: <b>{target_account_id}</b>
              </p>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {course_action === 'extension' ? (
          <DatePicker
            autoComplete='off'
            className='form-control digits'
            id='course_expire_time'
            name='course_expire_time'
            selectsStart
            //disabled={!isEdit}
            placeholderText={'Please select a date'}
            onChange={(e) => {
              onDateTimeChange(e, 'course_expire_time');
            }}
            locale='ko'
            selected={course_expire_time ? new Date(course_expire_time) : null}
            timeFormat='HH:mm:ss'
            showTimeSelect
            dateFormat='yyyy-MM-dd HH:mm:ss'
          />
        ) : (
          ''
        )}
        {course_action === 'expired' ? (
          <div className='col-md-6 p-0'>
            <p className='text-left fs-5'>
              현재시각: <b>{course_expire_time}</b>
              <br />
              <span>※적용 시 현재 시각으로 즉시 만료됩니다.</span>
            </p>
          </div>
        ) : (
          ''
        )}

        <div className={'p-0 w-100'}>
          {course_action === '' ? (
            <>
              <Button className='m-1 btn-primary' value='transfer' onClick={onCourseActionClick}>
                강의이전
              </Button>
              <Button className='m-1 btn-success' value='extension' onClick={onCourseActionClick}>
                강의연장
              </Button>
              <Button className='m-1 btn-warning' value='expired' onClick={onCourseActionClick}>
                강의만료
              </Button>
            </>
          ) : (
            ''
          )}
          {course_action !== '' ? (
            <>
              <Button
                id='btn-course-extension'
                className='m-1 btn-info'
                onClick={(e) =>
                  course_action == 'transfer'
                    ? courseTransfer(selected_course_list.selectedRows, target_account_id) // 강의이전
                    : courseEndDateChange(
                        user_id,
                        course_expire_time,
                        course_action,
                        selected_course_list.selectedRows,
                      )
                }
              >
                강의 {courseActionName} 적용
              </Button>
            </>
          ) : (
            ''
          )}

          {course_action !== '' ? (
            <Button
              id='btn-cancel'
              className='m-1 btn-danger'
              value=''
              onClick={(e) => onCourseActionCancel(e)}
            >
              취소
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>

      <DataTable
        noDataComponent={isLoading ? '잠시만 기다려주세요.' : '데이터가 없습니다.'}
        keyField={'id'}
        columns={columns}
        data={course_set}
        striped={true}
        center={true}
        noHeader={true}
        persistTableHead
        selectableRows={!!course_action}
        clearSelectedRows={!!course_action}
        onSelectedRowsChange={handleRowSelected}
        // contextActions={contextActions}
        // onSelectedRowsChange={handleRowSelected}
        // clearSelectedRows={toggleCleared}
      />
    </div>
  );
};

export default AccountCourseTable;
