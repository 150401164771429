import React, { useRef, useState } from 'react';
import { dialog_type_confirm, useParameters, useShowDialog } from '../../util/b2b_Hooks';
import { useHistory, useLocation } from 'react-router-dom';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import CustomInput2 from '../_common/component/CustomInput2';
import { lang } from '../../lang';
import { REGEX_PASSWORD } from '../../constant/regexs';
import CompanyModel from '../../model/CompanyModel';
import Loading from '../_common/Loading';

const EmployeePasswordChange = () => {
  const { params } = useParameters();
  const location = useLocation();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const { showAlert } = useShowDialog();
  const company_reducer = useSelector((state) => state.company_reducer);

  const [isLoading, setIsLoading] = useState(false);

  // ref
  const company_id = useRef(params.company_id);
  const employee_id = useRef(params.employee_id);

  const onSubmit = (data) => {
    showAlert({
      type: dialog_type_confirm,
      title: lang.common.confirm_change,
      showCancelButton: true,
      confirmButtonText: lang.common.confirm_button_change,
      true_fn: async () => {
        setIsLoading(true);

        const update_password_res = await CompanyModel.updateEmployeePassword(
          company_id.current,
          employee_id.current,
          { new_password: data.password },
        );

        if (update_password_res.code === 200) {
          showAlert({
            title: lang.common.success_change_message,
            icon: 'success',
          });
          history.goBack();
        } else {
          showAlert({
            title: lang.request.request_error_title,
            text: update_password_res.message,
          });
        }

        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Breadcrumb
        type='b2b'
        parent='기업'
        subParent={company_reducer.company_info.name}
        subSubParent={location.state.current_employee.name}
        title='비밀번호 변경'
      />
      <Container fluid={true}>
        <Row>
          <Col md={'12'}>
            <Card>
              <CardHeader className='f-20 f-w-700'>
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Col className={'col-5'}>
                    <span>비밀번호 변경하기</span>
                    {isLoading && <Loading isLoading={isLoading} />}
                  </Col>
                  <Col className={'text-right'}>
                    <Button
                      className={'btn-pill btn-air-danger'}
                      color={'danger'}
                      style={{
                        padding: '5px 20px',
                        marginRight: '5px',
                      }}
                      onClick={() => history.goBack()}
                    >
                      돌아가기
                    </Button>
                    <Button
                      className={'btn-pill btn-air-dark'}
                      color={'dark'}
                      style={{ padding: '5px 20px' }}
                      onClick={handleSubmit(onSubmit)}
                    >
                      저장
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form className='theme-form' id='account-form' onSubmit={handleSubmit(onSubmit)}>
                  <div className='form-row'>
                    <Col md={'5'}>
                      <CustomInput2
                        label_title='새로운 비밀번호'
                        is_require={true}
                        id='password'
                        name='password'
                        type='password'
                        placeholder='비밀번호'
                        innerRef={register({
                          required: lang.employee.need_fill_all_form,
                          pattern: {
                            value: REGEX_PASSWORD,
                            message: lang.employee.check_password_desc,
                          },
                        })}
                      />
                      <span className='f-12 txt-danger'>
                        {errors.password && errors.password.message}
                      </span>
                    </Col>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmployeePasswordChange;
