import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Media, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import CustomInput from '../component/CustomInput';
import PropTypes from 'prop-types';
import SweetAlert from 'sweetalert2';
import { createUuid } from '../../../util/common';

const propTypes = {
  items: PropTypes.array, // [{...}] | Card에 들어갈 내용들
  setItems: PropTypes.func, // func    | items state 핸들러 (handler)
  card_label_key: PropTypes.string, // String  | 카드에 제목을 items에서 어떤 key 값을 가진 value 로 설정할지
  card_label_link: PropTypes.func, // func    | 카드에 제목을 어떤 링크로 만들 것인지 설정
  card_input_set: PropTypes.array, // [{...}] | 카드에 들어갈 Input 정보들
  // items 배열 object에 있는 변경할 값이 들어있는  key 값과 card_input_set 중 name 이름을 가진 key의 value 은 같아야 한다
  // 예 )
  // ㄴ [{type: "number", label: "정렬값", name: "order_number", is_required: true, placeholder: "정렬값"}, ]
  default_value: PropTypes.any,
  isEdit: PropTypes.bool, // [{...}] | 기본으로 선택 되어있는 배열
};

const CardListInput = (props) => {
  const onInputChange = (e, index) => {
    const tmp_items = { ...props.items[index], [e.target.name]: e.target.value };
    props.setItems((prevState) => {
      prevState[index] = {
        ...tmp_items,
      };
      return prevState;
    });
  };

  const onDeleteClick = (e, index) => {
    const tmp_items = [...props.items];

    SweetAlert.fire({
      title: '목록에서 삭제할까요?',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    }).then((result) => {
      if (result.value) {
        tmp_items.splice(index, 1);
        props.setItems([...tmp_items]);
      }
    });
  };

  return (
    <Row>
      {props.items.map((item, index) => {
        return (
          <Col className={'m-0 p-1'} md='3' key={index}>
            <Card className={'m-0 card-absolute'}>
              {props.isEdit && (
                <CardHeader
                  style={{
                    backgroundColor: 'rgba(0,0,0,0)',
                    border: 'none',
                    right: '0',
                    left: 'auto',
                  }}
                >
                  <Button
                    className='btn-sm btn-danger btn-pill'
                    style={{ fontSize: '10px', padding: '5px 20px' }}
                    onClick={(e) => {
                      onDeleteClick(e, index);
                    }}
                  >
                    삭제
                  </Button>
                </CardHeader>
              )}
              <CardBody>
                <Media>
                  <Media body>
                    <h6 className={'text-center mb-3 f-w-600'}>
                      <Link to={props.card_label_link && props.card_label_link(item)}>
                        {item[props.card_label_key]}
                      </Link>
                    </h6>
                    {props.card_input_set.map((card_input_obj, _index) => {
                      return (
                        <CustomInput
                          key={createUuid()}
                          className={'mb-3'}
                          name={card_input_obj.name}
                          type={card_input_obj.type}
                          disabled={!props.isEdit || card_input_obj.disabled}
                          is_required={card_input_obj.is_required}
                          label={card_input_obj.label}
                          placeholder={card_input_obj.placeholder}
                          defaultValue={
                            (item[card_input_obj.name] !== undefined &&
                              String(item[card_input_obj.name])) ||
                            String(card_input_obj.default_value) ||
                            ''
                          }
                          onChange={(e) => {
                            onInputChange(e, index);
                          }}
                        />
                      );
                    })}
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

CardListInput.propTypes = propTypes;

export default CardListInput;
