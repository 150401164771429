import React, { useEffect, useState } from 'react';
import { CardBody, Button, Card, Col, Row } from 'reactstrap';
import { initComma } from '../../../util/common';
import InvoiceModel from '../../../model/InvoiceModel';
import SweetAlert from 'sweetalert2';
import { isMobile } from 'react-device-detect';

export default function FinalInvoiceViewCard(props) {
  const {
    invoice,
    is_admin,
    is_closed,
    invoice_info_exists,
    invoice_channel_set,
    account_id,
    year,
    month,
  } = props;
  const [view_card_invoice, setViewCardInvoice] = useState(invoice);

  useEffect(() => {
    setViewCardInvoice(invoice);
  }, [invoice]);

  const onClickSave = async () => {
    let validation_chk = true;
    let msg = '';
    if (!invoice.attach_archive_file_url) {
      validation_chk = false;
    }

    if (!invoice.attach_message) {
      validation_chk = false;
    }

    if (!validation_chk) {
      msg =
        "정산 관련 정보['추가메세지', '정산 파일 업로드']가\n 입력되지 않았습니다.\n\n정산 처리를 진행할까요?";
    } else {
      msg = '획인 버튼을 누르면 정산 처리를 진행합니다.\n 계속 진행 하시겠습니까?';
    }

    SweetAlert.fire({
      title: msg,
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    }).then((result) => {
      if (result.value) {
        InvoiceModel.postInvoice(account_id, invoice_channel_set, invoice, year, month).then(
          ({ invoice_channel_id_set }) => {
            if (invoice_channel_id_set.length === invoice_channel_set.length) {
              SweetAlert.fire({
                icon: 'success',
                text: '정산이 정상적으로 완료되었습니다.',
                showConfirmButton: false,
              });
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }
          },
        );
      }
    });
  };

  return (
    <Card
      style={{
        borderRadius: '10px',
        border: '1px solid #efefef',
      }}
    >
      <CardBody>
        {invoice_info_exists ? (
          <>
            <Row className='justify-content-start'>
              <Col>
                <h4>{invoice.title}</h4>
              </Col>
              <Col sm={6} className='text-right'>
                {is_admin && !is_closed && (
                  <Button
                    className='btn btn-sm btn-pill'
                    style={{ width: '125px' }}
                    color='primary'
                    onClick={onClickSave}
                  >
                    정산하기
                  </Button>
                )}
              </Col>
            </Row>
            <Row className='justify-content-between' style={{ marginTop: '25px' }}>
              <Col className={isMobile ? 'col-6' : 'col-4'}>
                <span
                  style={{
                    fontSize: '17px',
                    fontWeight: '500',
                  }}
                >
                  총 정산금액 :{' '}
                </span>
              </Col>
              <Col className={isMobile ? 'col-6 text-right' : 'col-4 text-right'}>
                <span
                  style={{
                    fontSize: '17px',
                    fontWeight: '500',
                  }}
                >
                  {invoice?.type_flag === 2
                    ? initComma(Math.round(invoice.c_total_after_tax_price / 1.1))
                    : initComma(Math.round(view_card_invoice.i_total_after_tax_price / 0.967))}
                  원
                </span>
              </Col>
            </Row>
            <Row className='justify-content-between' style={{ marginTop: '5px' }}>
              <Col className={isMobile ? 'col-6' : 'col-4'}>
                <span
                  style={{
                    fontSize: '17px',
                    fontWeight: '500',
                  }}
                >
                  {invoice?.type_flag === 2 ? '부가세율' : '원천징수율'} :{' '}
                </span>
              </Col>
              <Col className={isMobile ? 'col-6 text-right' : 'col-4 text-right'}>
                <span
                  style={{
                    fontSize: '17px',
                    fontWeight: '500',
                  }}
                >
                  {invoice?.type_flag === 2 ? '10%' : '3.3%'}
                </span>
              </Col>
            </Row>
            <Row className='justify-content-between' style={{ marginTop: '5px' }}>
              <Col className={isMobile ? 'col-6' : 'col-4'}>
                <span
                  style={{
                    fontSize: '17px',
                    fontWeight: '500',
                  }}
                >
                  {invoice?.type_flag === 2 ? '부가가치세' : '원천세'} :{' '}
                </span>
              </Col>
              <Col className={isMobile ? 'col-6 text-right' : 'col-4 text-right'}>
                <span
                  style={{
                    fontSize: '17px',
                    fontWeight: '500',
                  }}
                >
                  {invoice?.type_flag === 2 ? '+ ' : '- '}
                  {invoice.type_flag === 2
                    ? initComma(Math.round((view_card_invoice.c_total_after_tax_price / 1.1) * 0.1))
                    : initComma(
                        Math.round((view_card_invoice.i_total_after_tax_price / 0.967) * 0.033),
                      )}
                  원
                </span>
              </Col>
            </Row>
            <Row className='justify-content-between' style={{ marginTop: '5px' }}>
              <Col className={isMobile ? 'col-6' : 'col-4'}>
                <span
                  style={{
                    fontSize: '17px',
                    fontWeight: '500',
                  }}
                >
                  항목반영 :{' '}
                </span>
              </Col>
              <Col className={isMobile ? 'col-6 text-right' : 'col-4 text-right'}>
                <span
                  style={{
                    fontSize: '17px',
                    fontWeight: '700',
                    color: '#7e37d8',
                  }}
                >
                  {invoice?.type_flag === 2
                    ? initComma(
                        view_card_invoice.c_total_after_tax_price
                          ? initComma(view_card_invoice.c_total_after_tax_price)
                          : 0,
                      )
                    : initComma(
                        view_card_invoice.i_total_after_tax_price
                          ? initComma(view_card_invoice.i_total_after_tax_price)
                          : 0,
                      )}
                  원
                </span>
              </Col>
            </Row>
            <hr />
            {view_card_invoice.tf_total_after_tax_price > 0 ? (
              <>
                <Row className='justify-content-between' style={{ marginTop: '25px' }}>
                  <Col>
                    <h4>면세 항목</h4>
                  </Col>
                </Row>
                <Row className='justify-content-between' style={{ marginTop: '5px' }}>
                  <Col className={isMobile ? 'col-6' : 'col-4'}>
                    <span
                      style={{
                        fontSize: '17px',
                        fontWeight: '500',
                      }}
                    >
                      총 정산 금액 :
                    </span>
                  </Col>
                  <Col className={isMobile ? 'col-6 text-right' : 'col-4 text-right'}>
                    <span
                      style={{
                        fontSize: '17px',
                        fontWeight: '700',
                        color: '#7e37d8',
                      }}
                    >
                      {initComma(view_card_invoice.tf_total_after_tax_price)}원
                    </span>
                  </Col>
                </Row>
                <hr />
              </>
            ) : null}
            <Row className='justify-content-between' style={{ marginTop: '5px' }}>
              <Col className={isMobile ? 'col-6' : 'col-4'}>
                <span
                  style={{
                    fontSize: '17px',
                    fontWeight: '500',
                  }}
                >
                  최종 정산 금액{' '}
                </span>
              </Col>
              <Col className={isMobile ? 'col-6 text-right' : 'col-4 text-right'}>
                <span
                  style={{
                    fontSize: '17px',
                    fontWeight: '700',
                    color: '#7e37d8',
                  }}
                >
                  {view_card_invoice.total_invoice_price
                    ? initComma(view_card_invoice.total_invoice_price)
                    : 0}
                  원
                </span>
              </Col>
            </Row>
          </>
        ) : (
          <Row className='justify-content-start'>
            <Col className='text-center'>
              <h4>정산 정보가 필요합니다. (관리자 문의)</h4>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
}
