import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MENUITEMS, MENUITEMS_COMPANY_MANAGER, MENUITEMS_MASTER } from './Menu';

const useWindowSize = (wrapper) => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  if (wrapper === 'horizontal_sidebar') {
    if (size[0] > 100 && size[0] < 991) {
      document.querySelector('.page-wrapper').className = 'page-wrapper default';
      document.querySelector('.page-body-wrapper').className = 'page-body-wrapper default';
    } else {
      document.querySelector('.page-wrapper').className = 'page-wrapper horizontal_sidebar';
      document.querySelector('.page-body-wrapper').className =
        'page-body-wrapper horizontal_sidebar';
    }
  }

  return size;
};

const Sidebar = () => {
  const [margin, setMargin] = useState(0);
  const [main_menu, setMainMenu] = useState([]);
  const sidebar_type = {};
  const layout_type = 'ltr';
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
  const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
  // eslint-disable-next-line
  const [width, height] = useWindowSize(sidebar_type);
  const AuthStore = useSelector((state) => state.AuthStore);

  useEffect(() => {
    const currentUrl = window.location.pathname;

    if (main_menu.length >= 1) {
      main_menu.filter((items) => {
        if (items.path === currentUrl) setNavActive(items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return items;
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (AuthStore.account_type_id === 5) {
      setMainMenu(MENUITEMS);
    } else if (AuthStore.account_type_id === 2) {
      setMainMenu(MENUITEMS_MASTER);
    } else if (AuthStore.account_type_id === 3) {
      setMainMenu(MENUITEMS_COMPANY_MANAGER);
    }
  }, [AuthStore]);

  const setNavActive = (item) => {
    const temp_menu = main_menu.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item)) menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
            return true;
          } else {
            return false;
          }
        });
      }
      return menuItem;
    });
    item.active = !item.active;
    setMainMenu(temp_menu);
  };

  const toggletNavActive = (item) => {
    if (!item.active) {
      main_menu.forEach((a) => {
        if (main_menu.includes(item)) {
          a.active = false;
        }
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
          if (!b.children) return false;
          // b에 또 children이 있지 않기 때문에 여기서 끝---- : 모든 메인메뉴를 active false로 변경
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
      // 클릭된 요소의 active만 반대로 변경
      item.active = !item.active;
    }
    // setMainMenu({main_menu: MENUITEMS});
    document.querySelector('.iconsidebar-menu').classList.remove('iconbar-mainmenu-close');
  };

  const scrollToRight = () => {
    const elmnt = document.getElementById('myDIV');
    const menuWidth = elmnt.offsetWidth;
    const temp = menuWidth + margin;
    // Checking condition for remaing margin
    if (temp < menuWidth) {
      setMargin(-temp);
      setHideRightArrow(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideLeftArrow(false);
    }
  };

  const scrollToLeft = () => {
    // If Margin is reach between screen resolution
    if (margin >= -width) {
      setMargin(0);
      setHideLeftArrow(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrow(false);
    }
  };

  const scrollToLeftRTL = () => {
    if (margin <= -width) {
      setMargin((margin) => (margin += -width));
      setHideLeftArrowRTL(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideRightArrowRTL(false);
    }
  };

  const scrollToRightRTL = () => {
    const temp = width + margin;
    // Checking condition for remaing margin
    if (temp === 0) {
      setMargin(temp);
      setHideRightArrowRTL(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrowRTL(false);
      setHideLeftArrowRTL(false);
    }
  };

  return (
    <div className='iconsidebar-menu'>
      <div className='sidebar custom-scrollbar'>
        <ul className='iconMenu-bar custom-scrollbar'>
          <li
            className={`left-arrow ${
              layout_type === 'rtl'
                ? hideLeftArrowRTL
                  ? 'd-none'
                  : 'hideLeftArrowRTL'
                : hideLeftArrow
                  ? 'd-none'
                  : 'hideLeftArrow'
            }`}
            onClick={
              sidebar_type === 'horizontal_sidebar' && layout_type === 'rtl'
                ? scrollToLeftRTL
                : scrollToLeft
            }
          >
            <i className='fa fa-angle-left' />
          </li>
          {main_menu.map((menuItem, i) => {
            return (
              <li className={`${menuItem.active ? 'open' : ''}`} key={i}>
                {menuItem.tag}
                <a
                  href='#!'
                  style={{ cursor: 'pointer' }}
                  className='bar-icons'
                  onClick={() => toggletNavActive(menuItem)}
                >
                  {menuItem.icon}
                  <span>{menuItem.title}</span>
                </a>
                {menuItem.children ? (
                  <ul className='iconbar-mainmenu custom-scrollbar'>
                    {menuItem.children.map((childrenItem, index) => {
                      // @TODO twowix - 개발 완료되면 스타일코드 지우기 -------

                      return (
                        <Fragment key={index}>
                          {childrenItem.type === 'sub' ? (
                            <li className='iconbar-header'>{childrenItem.title}</li>
                          ) : (
                            ''
                          )}

                          {childrenItem.type === 'sub-header' ? (
                            <li className='iconbar-header sub-header'>{childrenItem.title}</li>
                          ) : (
                            ''
                          )}

                          {childrenItem.type === 'link' ? (
                            <li
                              className={childrenItem.active ? 'active' : ''}
                              style={
                                childrenItem.is_done
                                  ? {}
                                  : childrenItem.is_working
                                    ? { background: '' }
                                    : { background: '' }
                              }
                            >
                              {AuthStore.account_type_id !== 5 ? (
                                <Link
                                  className={childrenItem.active ? 'active' : ''}
                                  to={`${childrenItem.path}/${AuthStore.id}`}
                                  onClick={() => toggletNavActive(childrenItem)}
                                >
                                  {childrenItem.title}
                                </Link>
                              ) : (
                                <Link
                                  className={childrenItem.active ? 'active' : ''}
                                  to={childrenItem.path}
                                  onClick={() => toggletNavActive(childrenItem)}
                                >
                                  {childrenItem.title}
                                </Link>
                              )}
                            </li>
                          ) : (
                            ''
                          )}
                        </Fragment>
                      );
                    })}
                  </ul>
                ) : (
                  ''
                )}
              </li>
            );
          })}
          <li
            className={`right-arrow ${
              layout_type === 'rtl'
                ? hideRightArrowRTL
                  ? 'd-none'
                  : 'hideRightArrowRTL'
                : hideRightArrow
                  ? 'd-none'
                  : 'hideRightArrow'
            }`}
            onClick={
              sidebar_type === 'horizontal_sidebar' && layout_type === 'rtl'
                ? scrollToRightRTL
                : scrollToRight
            }
          >
            <i className='fa fa-angle-right' />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
