import axios from 'axios';

class CouponModel {
  async getCoupon(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/coupon', {
      params: filter,
    });
    return data;
  }

  async postCoupon(coupon) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/coupon', coupon);
    return data;
  }

  async getCouponDetail(coupon_id) {
    if (coupon_id) {
      const {
        data: { data },
      } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/coupon/' + coupon_id);
      return data;
    } else {
      return { coupon: false };
    }
  }

  async putCouponDetail(coupon) {
    const {
      data: { data },
    } = await axios.put(process.env.REACT_APP_API_URL + '/cms-api/v1/coupon/' + coupon.id, coupon);
    return data;
  }

  async checkCouponText(coupon_text) {
    const {
      data: { data },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/coupon/unique-check/' + coupon_text,
    );
    return data;
  }

  async expireCoupon(coupon_id) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/coupon/' + coupon_id + '/expire-time',
    );
    return data;
  }

  async sendCoupon(coupon) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/coupon/coupon-send', coupon);
    return data;
  }

  async multipleCoupon(coupon) {
    const {
      data: { data },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/coupon/coupon-multiple',
      coupon,
    );
    return data;
  }

  async getCouponEvent(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/coupon/coupon-event', {
      params: filter,
    });
    return data;
  }

  async postCouponEvent(coupon_event) {
    const {
      data: { data },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/coupon/coupon-event',
      coupon_event,
    );
    return data;
  }

  async getCouponEventDetail(coupon_event_id) {
    if (coupon_event_id) {
      const {
        data: { data },
      } = await axios.get(
        process.env.REACT_APP_API_URL + '/cms-api/v1/coupon/coupon-event/' + coupon_event_id,
      );
      return data;
    } else {
      return { coupon_event: false };
    }
  }

  async putCouponEventDetail(coupon_event) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/coupon/coupon-event/' + coupon_event.id,
      coupon_event,
    );
    return data;
  }

  async checkCouponEventText(coupon_event_text) {
    const {
      data: { data },
    } = await axios.get(
      process.env.REACT_APP_API_URL +
        '/cms-api/v1/coupon/coupon-event/unique-check/' +
        coupon_event_text,
    );
    return data;
  }
}

export default new CouponModel();
