import React, { Fragment, useRef, useState } from 'react';
import { Table } from 'reactstrap';
import { getVisibleString } from '../../../util/b2b_utils';
import CustomTooltip from '../../_common/component/CustomTooltip';
import TableHead from '../../_common/component/TableHead';
import { useMountEffect, useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import CompanyModel from '../../../model/CompanyModel';
import { lang } from '../../../lang';
import moment from 'moment';

/*
    props
    table_head : {}     options.js의 TABLE_HEAD_COURSE_DETAIL 참고
 */
const EmployeeCourseDetailTable = (props) => {
  const { params } = useParameters();
  const { showAlert } = useShowDialog();
  const [isMount] = useMountEffect();

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [course_subject_set, setCourseSubjectSet] = useState([]);

  // ref
  const target_array = useRef(props.table_head.map((item) => item.target) || []);
  const company_id = useRef(params.company_id);
  const employee_id = useRef(params.employee_id);
  const course_id = useRef(params.course_id);

  useMountEffect(async () => {
    await updateUi();
  });

  const updateUi = async () => {
    setIsLoading(true);

    // 직원별 subject_set 가져오기 API
    const subject_detail_res = await CompanyModel.getSubjectDetail(
      company_id.current,
      employee_id.current,
      course_id.current,
    );

    if (isMount()) {
      if (subject_detail_res.code === 200) {
        setCourseSubjectSet(subject_detail_res.data.course_subjects);
      } else {
        showAlert({
          title: lang.request.request_error_title,
          text: subject_detail_res.message,
        });
      }

      setIsLoading(false);
    }
  };

  const CourseDetailTableBodyView = () => {
    if (course_subject_set.length >= 1) {
      return course_subject_set.map((subject, i) => {
        const visible_title = getVisibleString(subject.title, 25);
        return (
          <tr key={i}>
            {target_array.current.map((target, i) => {
              if (target === 'title') {
                return subject[target].length >= 25 ? (
                  <Fragment key={i}>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      <span
                        id={`title_${subject.id}`}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        {visible_title}
                      </span>
                    </td>
                    <CustomTooltip target={`title_${subject.id}`} content={subject.title} />
                  </Fragment>
                ) : (
                  <td key={i} style={{ whiteSpace: 'nowrap' }}>
                    {visible_title}
                  </td>
                );
              } else if (target === 'is_viewed') {
                return subject[target] ? (
                  <td className='text-center' key={i} style={{ whiteSpace: 'nowrap' }}>
                    <span className={'txt-primary'}>
                      <i className='fa fa-check' />
                    </span>
                  </td>
                ) : (
                  <td className='text-center' key={i} />
                );
              } else if (target === 'total_second') {
                let visible_total_second;
                if (!subject[target]) {
                  visible_total_second = '';
                } else if (subject[target] >= 61) {
                  let minute = Math.floor(subject[target] / 60);
                  let second = Math.floor(subject[target] % 60);

                  visible_total_second = `${minute}분 ${second}초`;
                } else {
                  visible_total_second = `${subject[target]}초`;
                }

                return (
                  <td className='text-center' key={i} style={{ whiteSpace: 'nowrap' }}>
                    {visible_total_second}
                  </td>
                );
              } else if (target === 'index_name') {
                return (
                  <td className='text-center' key={i} style={{ whiteSpace: 'nowrap' }}>
                    {subject[target] ? subject[target] : ''}
                  </td>
                );
              } else {
                return (
                  <td className='text-center' key={i} style={{ whiteSpace: 'nowrap' }}>
                    {subject[target] ? moment(subject[target]).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </td>
                );
              }
            })}
          </tr>
        );
      });
    }
    return null;
  };

  return (
    <div className='table-responsive'>
      <Table striped className='m-t-15'>
        <TableHead table_head={props.table_head} />
        <tbody>
          {isLoading ? (
            <tr>
              <td
                colSpan='6'
                style={{
                  textAlign: 'center',
                }}
              >
                <div className='loader-box'>
                  <div className='loader-15' />
                </div>
              </td>
            </tr>
          ) : (
            <CourseDetailTableBodyView />
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default EmployeeCourseDetailTable;
