import React, { Fragment } from 'react';
import { Card, Container } from 'reactstrap';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import Tab from '../_common/Tab';
import BearustudyCertificate from './component/BearustudyCertificate';
import BearuCertificate from './component/BearuCertificate';

const tab_list = [
  {
    id: '1',
    name: '베어유 수료증 발급',
    icon: <i className='icofont icofont-hat-alt' />,
    component: BearuCertificate,
  },
  {
    id: '2',
    name: '빡공단 수료증 발급',
    icon: <i className='icofont icofont-group-students' />,
    component: BearustudyCertificate,
  },
];

const Certificate = (props) => {
  return (
    <Fragment>
      <Breadcrumb parent='수료증' title={`수료증 발급`} />
      <Container fluid={true}>
        <Card>
          <Tab tab_list={tab_list} />
        </Card>
      </Container>
    </Fragment>
  );
};

export default Certificate;
