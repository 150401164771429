import React, { useRef, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import EmployeeCourseDetailTable from './component/EmployeeCourseDetailTable';
import { TABLE_HEAD_COURSE_DETAIL } from '../../constant/options';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMountEffect, useParameters, useShowDialog } from '../../util/b2b_Hooks';
import CourseModel from '../../model/CourseModel';
import { lang } from '../../lang';

const EmployeeCourseDetail = () => {
  const { params } = useParameters();
  const { showAlert } = useShowDialog();
  const [isMount] = useMountEffect();
  const history = useHistory();
  const company_reducer = useSelector((state) => state.company_reducer);

  const [current_employee, setCurrentEmployee] = useState();
  const [course_info, setCourseInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const employee_id = useRef(params.employee_id);
  const course_id = useRef(params.course_id);

  useMountEffect(async () => {
    await updateUi();
  });

  const updateUi = async () => {
    setIsLoading(true);

    window.scroll(0, 0);

    if (company_reducer.employee_set) {
      const c_employee = company_reducer.employee_set.find(
        (r_employee) => r_employee.id === Number(employee_id.current),
      );
      setCurrentEmployee(c_employee);
    }
    const get_course_res = await CourseModel.getCourse(course_id.current);
    if (isMount()) {
      if (get_course_res.course) {
        setCourseInfo(get_course_res.course);
      } else {
        showAlert({
          title: lang.request.request_error_title,
          text: lang.request.request_error_desc,
        });

        history.goBack();
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <Breadcrumb
        type='b2b'
        parent='기업'
        subParent={company_reducer.company_info.name}
        subSubParent={current_employee && current_employee.name}
        title='시청기록'
      />
      <Container fluid={true}>
        <Row>
          <Col xl='12'>
            <Card>
              <CardHeader
                style={{
                  padding: '30px',
                }}
              >
                {isLoading ? (
                  <div className='loader-box'>
                    <div className='loader-19' />
                  </div>
                ) : (
                  <h4>
                    <i className='typcn typcn-device-laptop txt-info' />
                    &nbsp;[
                    <span className='txt-primary'>{course_info && course_info.title}</span>]
                    시청기록
                  </h4>
                )}
              </CardHeader>
              <CardBody
                style={{
                  padding: '30px',
                }}
              >
                <EmployeeCourseDetailTable table_head={TABLE_HEAD_COURSE_DETAIL} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmployeeCourseDetail;
