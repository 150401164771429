import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useEffect, useState } from 'react';
import TextEditor from '../../_common/component/TextEditor';
import ImageUploader from '../../_common/component/ImageUploader';
import { connect } from 'react-redux';
import { set_course_subject } from '../../../redux/course/subject/action';

const SubjectModal = (props) => {
  const [modal, setModal] = useState(false);
  const { id, is_modal, req_type, isEdit, subject_index, CourseSubject } = props;
  const [modal_value, setModalValue] = useState('');

  useEffect(() => {
    setModal(is_modal);
    if (req_type === 'txt') {
      setModalValue(CourseSubject[subject_index] ? CourseSubject[subject_index].description : '');
    } else {
      setModalValue(CourseSubject[subject_index] ? CourseSubject[subject_index].image_url : '');
    }
  }, [is_modal, req_type]);

  const toggle = () => {
    setModal(!modal);
    if (req_type === 'txt') {
      props.set_course_subject(subject_index, 'description', modal_value);
    }
  };
  return (
    <Modal centered isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <div
          className='badge-pill badge btc-amount f-w-700 text-center'
          style={{ backgroundColor: '#7e37d8', color: '#fff' }}
        >
          {subject_index + 1}강{req_type === 'txt' ? ' 설명 입력' : ' 썸네일 이미지 업로드'}
        </div>
      </ModalHeader>
      <ModalBody>
        {req_type === 'txt' ? (
          <div className='editor-img-100'>
            <TextEditor
              disabled={!isEdit}
              html_data={CourseSubject[subject_index]?.description}
              img_type={'course'}
              onChange={(value) => {
                setModalValue(value);
              }}
            />
          </div>
        ) : (
          <ImageUploader
            target_index={subject_index}
            img_type='subject'
            img_url={CourseSubject[subject_index]?.image_url}
            img_thumb_url={CourseSubject[subject_index]?.thumbnail_image_url}
            isEdit={isEdit}
            id={Number(id)}
            height={250}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  CourseSubject: state.CourseSubjectStore,
});

const mapDispatchToProps = (dispatch) => {
  return {
    set_course_subject: (subject_index, name, value) =>
      dispatch(set_course_subject(subject_index, name, value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubjectModal);
