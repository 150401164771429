import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Collapse } from 'reactstrap';
import Apexcharts from 'react-apexcharts';
import { monthly_progress_data } from '../../../constant/chart_option';
import Loading from '../../_common/Loading';
import { getChangeOrderArrayFromStartToEnd } from '../../../util/b2b_utils';

/*
    props
    company_graph_data: {}      기업의 그래프 정보
 */
const MonthlyStudyChart = (props) => {
  // state
  const [is_chart_spread, setChartSpread] = useState(true);
  const [chart_series, setChartSeries] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [is_data_empty, setIsDataEmpty] = useState(false);

  // ref
  const chart_options = useRef(monthly_progress_data.options);

  useEffect(() => {
    if (props.company_graph_data) {
      if (!props.company_graph_data.monthly_attendance_status.length) {
        setIsDataEmpty(true);
      } else {
        const labels = [];
        const attendance_employee_count = [];
        const attendance_minute = [];
        props.company_graph_data.monthly_attendance_status.map((one_data) => {
          labels.push(one_data.date?.substring(0, 7) || '');
          attendance_employee_count.push(one_data.attendance_employee_count);
          attendance_minute.push(Math.floor(one_data.attendance_second / 60));
          return null;
        });

        chart_options.current.labels = getChangeOrderArrayFromStartToEnd(labels);
        setChartSeries([
          {
            name: '총 학습 시간 (분)',
            type: 'column',
            data: getChangeOrderArrayFromStartToEnd(attendance_minute),
          },
          {
            name: '학습 인원 (명)',
            type: 'line',
            data: getChangeOrderArrayFromStartToEnd(attendance_employee_count),
          },
        ]);
      }

      setIsLoading(false);
    }
  }, [props.company_graph_data]);

  return (
    <Card>
      <div className='default-according style-1' id='accordionoc'>
        <CardHeader style={{ padding: '20px 20px 20px 30px', lineHeight: '38px' }}>
          <Button
            color='black pl-0'
            data-toggle='collapse'
            onClick={() => setChartSpread(!is_chart_spread)}
            data-target='#collapseicon'
            aria-expanded={is_chart_spread}
            aria-controls='collapseicon'
          >
            <h5 className='mb-0'>월별 학습 현황</h5>
          </Button>
        </CardHeader>
      </div>
      <Collapse isOpen={is_chart_spread}>
        <CardBody className='p-1'>
          <div className='monthly-progress'>
            {isLoading ? (
              <div className='text-center'>
                <Loading
                  isLoading={isLoading}
                  custom_style={{ display: 'flex' }}
                  is_card_body={true}
                />
              </div>
            ) : is_data_empty ? (
              <Col
                md={'12'}
                className={'text-primary text-center p-30'}
                style={{ fontSize: '16px', fontWeight: 'bold' }}
              >
                현재 수강중 또는 학습하신 강의가 없습니다
              </Col>
            ) : (
              <Apexcharts
                style={{
                  transform: 'translateY(20px)',
                }}
                type='line'
                height={300}
                options={chart_options.current}
                series={chart_series}
              />
            )}
          </div>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default MonthlyStudyChart;
