import React, { Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import queryString from 'query-string';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import { initComma, onAirStatus, onBooleanBadgeStyle, toQueryString } from '../../util/common';
import EventModel from '../../model/EventModel';
import { PAGE_SIZE } from '../../constants';

const Event = (props) => {
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    search_text: params.search_text ? params.search_text : '',
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const [event_set, setEventSet] = useState([]);
  const [event_count, setEventCountSet] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilter] = useState(default_filter.current);

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    EventModel.getEvent({
      ...params,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ event_set, total_count }) => {
      setEventSet(event_set);
      setEventCountSet(total_count);
      setIsLoading(false);
    });
  }, [props.location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onFilterSubmit = (e) => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      order_key: order_key,
      order_type: !filter_set.order_type || filter_set.order_type === 'asc' ? 'desc' : 'asc', // 정렬을 기존 기준 -> "없을 경우 or asc = desc로 변경" / "desc 일 경우 = asc 로 변경"
    };

    // 이전에 정렬한적 있고, 새로운 정렬을 적용.
    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }

    pushHistory(newFilterSet);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    });
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <Breadcrumb parent='프로모션' title='이벤트 목록' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Col className='text-right mb-4'>
              <Link className={'btn btn-primary btn-pill'} to={'/event/0'}>
                이벤트생성
              </Link>
            </Col>
            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={9} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총: <span style={{ color: 'coral' }}>{initComma(event_count)}</span>개
                        </p>
                        <Loading isLoading={isLoading} />
                      </Col>
                      <Col md={3} className='text-right'>
                        <FormGroup>
                          <InputGroup>
                            <Input
                              className='form-control'
                              type='text'
                              name='search_text'
                              onChange={onFilterChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onFilterSubmit(e);
                                }
                              }}
                              value={filter_set.search_text}
                              placeholder='이벤트 정보를 입력하세요.'
                            />
                            <InputGroupAddon
                              addonType='append'
                              style={{ cursor: 'pointer' }}
                              onClick={onFilterSubmit}
                            >
                              <InputGroupText>
                                <i className='icofont icofont-ui-search' />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <div className='table-responsive'>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th style={{ width: '70px' }}>ID</th>
                      <th style={{ width: '330px', textAlign: 'left' }}>이벤트코드</th>
                      <th style={{ textAlign: 'left' }}>제목/설명</th>
                      <th
                        style={{ width: '50px' }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('on_air');
                        }}
                      >
                        {filter_set.order_key === 'on_air' ? (
                          <span style={{ color: '#ff4c3b' }}>onAir</span>
                        ) : (
                          <span>onAir</span>
                        )}
                      </th>
                      <th
                        style={{ width: '150px' }}
                        onClick={() => {
                          onOrderChange('start_time');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'start_time' ? (
                          <span style={{ color: '#ff4c3b' }}>시작일</span>
                        ) : (
                          <span>시작일</span>
                        )}
                      </th>
                      <th
                        style={{ width: '150px' }}
                        onClick={() => {
                          onOrderChange('expire_time');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'expire_time' ? (
                          <span style={{ color: '#ff4c3b' }}>만료일</span>
                        ) : (
                          <span>만료일</span>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {event_set.map((event) => {
                      let url = 'event/' + event.id;
                      const onTdClick = () => {
                        props.history.push(url);
                      };
                      return (
                        <tr key={event.id}>
                          <td
                            className='text-center'
                            onClick={onTdClick}
                            role='button'
                            style={{ verticalAlign: 'middle' }}
                          >
                            {event.id}
                          </td>
                          <td style={{ verticalAlign: 'middle', cursor: 'default' }} role='button'>
                            {event.event_code}
                          </td>
                          <td
                            style={{ verticalAlign: 'middle', height: '67px' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div className={'text-truncate f-w-900'} style={{ width: '330px' }}>
                              {event.title}
                            </div>
                            <div>{event.description}</div>
                          </td>

                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <span className={onBooleanBadgeStyle(event.on_air)}>
                              {onAirStatus(event.on_air)}
                            </span>
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {(event.start_time &&
                              moment(new Date(event.start_time)).format('YYYY-MM-DD')) ||
                              '-'}
                          </td>
                          <td
                            className='text-center'
                            style={{ verticalAlign: 'middle' }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {(event.expire_time &&
                              moment(new Date(event.expire_time)).format('YYYY-MM-DD')) ||
                              '-'}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size ? event_count / params.size : event_count / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Event;
