import React, { Fragment, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import { initComma, toQueryString } from '../../util/common';

import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';

import CouponModel from '../../model/CouponModel';
import { PAGE_SIZE } from '../../constants';

const Coupon = (props) => {
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: params.size ? parseInt(params.size) : PAGE_SIZE,
    search_text: params.search_text ? params.search_text : '',
    order_key: params.order_key ? params.order_key : '',
    order_type: params.order_type ? params.order_type : '',
  });

  const [coupon_set, setCouponSet] = useState([]);
  const [coupon_count, setCouponCountSet] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilter] = useState(default_filter.current);

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);

    // 뒤로가기 했을 경우 query params 가 없을 경우, 초기화하기
    setFilter(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    // push 한 params 만으로 리스트 출력
    CouponModel.getCoupon({
      ...params,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    }).then(({ coupon_set, total_count }) => {
      setCouponSet(coupon_set);
      setCouponCountSet(total_count);
      setIsLoading(false);
    });
  }, [props.location.search]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onFilterSubmit = (e) => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: '',
      order_type: '',
    });
  };

  const onFilterChange = (e) => {
    setFilter({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onOrderChange = (order_key) => {
    const newFilterSet = {
      ...params,
      page: 1,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
      order_key: order_key,
      order_type: !filter_set.order_type || filter_set.order_type === 'asc' ? 'desc' : 'asc', // 정렬을 기존 기준 -> "없을 경우 or asc = desc로 변경" / "desc 일 경우 = asc 로 변경"
    };

    // 이전에 정렬한적 있고, 새로운 정렬을 적용.
    if (newFilterSet.order_key !== filter_set.order_key) {
      newFilterSet['order_type'] = 'desc';
    }

    pushHistory(newFilterSet);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
      size: params.size ? parseInt(params.size) : PAGE_SIZE,
    });
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <Breadcrumb parent='프로모션' title='쿠폰 목록' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Col className='text-right mb-4'>
              <Link className={'btn btn-secondary btn-pill mr-3'} to={'/coupon/coupon-send'}>
                대량쿠폰발송
              </Link>
              <Link className={'btn btn-info btn-pill mr-3'} to={'/coupon/coupon-multiple'}>
                대량쿠폰발급
              </Link>
              <Link className={'btn btn-primary btn-pill'} to={'/coupon/0'}>
                쿠폰생성
              </Link>
            </Col>
            <Card>
              <CardHeader>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col>
                    <Row>
                      <Col md={9} style={{ margin: 'auto' }}>
                        <p style={{ marginTop: '7px', display: 'inline' }}>
                          총: <span style={{ color: 'coral' }}>{initComma(coupon_count)}</span>개
                        </p>
                        <Loading isLoading={isLoading} />
                      </Col>
                      <Col md={3} className='text-right'>
                        <FormGroup>
                          <InputGroup>
                            <Input
                              className='form-control'
                              type='text'
                              name='search_text'
                              onChange={onFilterChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onFilterSubmit(e);
                                }
                              }}
                              value={filter_set.search_text}
                              placeholder='쿠폰 정보를 입력하세요.'
                            />
                            <InputGroupAddon
                              addonType='append'
                              style={{ cursor: 'pointer' }}
                              onClick={onFilterSubmit}
                            >
                              <InputGroupText>
                                <i className='icofont icofont-ui-search' />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <div className='table-responsive'>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th
                        style={{
                          width: '80px',
                          minWidth: '80px',
                          maxWidth: '80px',
                        }}
                      >
                        ID
                      </th>
                      <th
                        style={{
                          width: '330px',
                          minWidth: '330px',
                          maxWidth: '330px',
                          textAlign: 'left',
                        }}
                      >
                        쿠폰텍스트
                      </th>
                      <th
                        style={{
                          width: '600px',
                          minWidth: '600px',
                          maxWidth: '600px',
                          textAlign: 'left',
                        }}
                      >
                        쿠폰명/설명
                      </th>
                      <th
                        style={{
                          width: '120px',
                          minWidth: '120px',
                          maxWidth: '120px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('discounts');
                        }}
                      >
                        {filter_set.order_key === 'discounts' ? (
                          <span style={{ color: '#ff4c3b' }}>할인금액</span>
                        ) : (
                          <span>할인금액</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '120px',
                          minWidth: '120px',
                          maxWidth: '120px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('coupon_count');
                        }}
                      >
                        {filter_set.order_key === 'coupon_count' ? (
                          <span style={{ color: '#ff4c3b' }}>소진율</span>
                        ) : (
                          <span>소진율</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '110px',
                          minWidth: '110px',
                          maxWidth: '110px',
                        }}
                        className='table-sort-th'
                        onClick={() => {
                          onOrderChange('id');
                        }}
                      >
                        {filter_set.order_key === 'id' ? (
                          <span style={{ color: '#ff4c3b' }}>생성일</span>
                        ) : (
                          <span>생성일</span>
                        )}
                      </th>
                      <th
                        style={{
                          width: '110px',
                          minWidth: '110px',
                          maxWidth: '110px',
                        }}
                        onClick={() => {
                          onOrderChange('expire_time');
                        }}
                        className='table-sort-th'
                      >
                        {filter_set.order_key === 'expire_time' ? (
                          <span style={{ color: '#ff4c3b' }}>만료일</span>
                        ) : (
                          <span>만료일</span>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {coupon_set.map((coupon) => {
                      let url = 'coupon/' + coupon.id;
                      const onTdClick = () => {
                        props.history.push(url);
                      };
                      return (
                        <tr key={coupon.id}>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '80px',
                              maxWidth: '80px',
                            }}
                            className='text-center'
                            onClick={onTdClick}
                            role='button'
                          >
                            {coupon.id}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              cursor: 'default',
                              minWidth: '330px',
                              maxWidth: '330px',
                            }}
                            role='button'
                          >
                            <div className={'f-w-900 text-truncate'} style={{ width: '330px' }}>
                              {coupon.coupon_text}
                            </div>
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              height: '67px',
                              minWidth: '600px',
                              maxWidth: '600px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            <div className={'f-w-900'}>{coupon.title}</div>
                            <div style={{ width: '600px' }} className={'text-truncate'}>
                              {coupon.description}
                            </div>
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '120px',
                              maxWidth: '120px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {initComma(coupon.discounts)}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '120px',
                              maxWidth: '120px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {initComma(coupon.coupon_log_count)}/{initComma(coupon.coupon_count)}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '110px',
                              maxWidth: '110px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {coupon.create_time &&
                              moment(new Date(coupon.create_time)).format('YYYY-MM-DD')}
                          </td>
                          <td
                            className='text-center'
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '110px',
                              maxWidth: '110px',
                            }}
                            role='button'
                            onClick={onTdClick}
                          >
                            {coupon.expire_time &&
                              moment(new Date(coupon.expire_time)).format('YYYY-MM-DD')}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div className='m-auto'>
                <CustomPagination
                  current_page={params.page ? Number(params.page) : 1}
                  max_page={Math.ceil(
                    params.size ? coupon_count / params.size : coupon_count / PAGE_SIZE,
                  )}
                  onPageChange={onPageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Coupon;
