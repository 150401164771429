import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import PurchaseModel from '../../model/PurchaseModel';
import { initComma, toQueryString, zip } from '../../util/common';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Apexchart from 'react-apexcharts';
import Loading from '../_common/Loading';

const SaleDashboard = (props) => {
  const params = props.location.state || queryString.parse(props.location.search) || {};

  const [bar_chart_loading, setBarChartLoading] = useState(false);
  const [course_chart_loading, setCourseChartLoading] = useState(false);
  const [category_chart_loading, setCategoryChartLoading] = useState(false);
  const [product_chart_loading, setProductChartLoading] = useState(false);
  const [kit_chart_loading, setKitChartLoading] = useState(false);

  // 요약용
  const [purchase_info_summary, setPurchaseInfoSummary] = useState(null);

  // 필터용
  const [is_date_disabled, setisDateDisabled] = useState(true);
  const [is_data_selectable, setisDateSelectable] = useState(false);
  const [date_range, setDateRange] = useState({
    date_start: params.date_start ? params.date_start : '',
    date_end: params.date_end ? params.date_end : '',
  });

  // 막대 그래프
  const [purchase_info_graph_date, setPurchaseInfoGraphDate] = useState([]);
  const [purchase_info_graph_total_product_price_set, setPurchaseInfoGraphTotalProductPriceSet] =
    useState([]);
  const [
    purchase_info_graph_total_discounted_price_set,
    setPurchaseInfoGraphTotalDiscountedPriceSet,
  ] = useState([]);
  const [purchase_info_graph_total_refund_price_set, setPurchaseInfoGraphTotalRefundPriceSet] =
    useState([]);
  const [purchase_info_graph_total_own_price_set, setPurchaseInfoGraphTotalOwnPriceSet] = useState(
    [],
  );

  // pieArea 그래프 (Course)
  const [course_top_5_title, setCourseTop5Title] = useState([]);
  const [course_top_5_actual_price, setCourseTop5ActualPrice] = useState([]);

  // pieArea 그래프 (Category)
  const [category_top_5_name, setCategoryTop5Name] = useState([]);
  const [category_top_5_actual_purchased_price, setCategoryTop5ActualPrice] = useState([]);

  // pieArea 그래프 (Product)
  const [product_top_5_title, setProductTop5Title] = useState([]);
  const [product_top_5_actual_price, setProductTop5ActualPrice] = useState([]);

  // pieArea 그래프 (Kit)
  const [kit_top_5_title, setKitTop5Title] = useState([]);
  const [kit_top_5_actual_price, setKitTop5ActualPrice] = useState([]);

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onSearchClick = (e) => {
    pushHistory({
      ...date_range,
    });
  };

  // 날짜 수정
  const onDateChange = (e, name) => {
    setDateRange({ ...date_range, [name]: e && moment(e).format('YYYY-MM-DD') });
  };

  const onDateClick = (e) => {
    let date_start = null;
    let date_end = null;
    let add_date;

    if (e.target.value !== 'all' && e.target.value !== 'direct') {
      add_date = Number(e.target.value.split('_')[1]);
      date_end = moment().format('YYYY-MM-DD');
    }

    if (e.target.value === 'direct') {
      date_start = date_range.date_start;
      date_end = date_range.date_end;
      setisDateDisabled(false);
      setisDateSelectable(true);
    } else {
      setisDateDisabled(true);
      setisDateSelectable(false);
    }

    if (typeof add_date === 'number') {
      date_start = moment().add(-add_date, 'days').format('YYYY-MM-DD');
    }

    setDateRange({
      ...date_range,
      date_start: date_start,
      date_end: date_end,
    });

    pushHistory({ ...date_range, date_start: date_start, date_end: date_end });
  };

  // array in dictionary sort
  const returnOrderedSet = (before_state, order_key, current_order_type = 'asc') => {
    before_state.sort((a, b) => {
      let nameA = a[order_key];
      let nameB = b[order_key];
      if (current_order_type === 'asc') {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      } else {
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
      }
      // 이름이 같을 경우
      return 0;
    });
    return before_state;
  };

  const arraySum = (array) => {
    if (array.length > 0) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      return array.reduce(reducer);
    } else {
      return 0;
    }
  };

  const chartLabel = (title, price) => {
    return zip([title, price, ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0']]).map(
      (item, index) => {
        return (
          <div key={index} className='m-1'>
            <div
              className='text-truncate'
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
              }}
            >
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  marginRight: '3px',
                  backgroundColor: `${item[2]}`,
                }}
              />
              {item[0]}
            </div>
            <div>
              <b
                style={{
                  fontSize: '12px',
                }}
              >
                {initComma(item[1])} 원
              </b>
            </div>
          </div>
        );
      },
    );
  };

  useEffect(() => {
    PurchaseModel.getPurchaseSummary().then((data) => {
      setPurchaseInfoSummary(data);
    });

    if (params.date_start || params.date_end) {
      setisDateSelectable(true);
      setisDateDisabled(false);
    }
  }, []);

  useEffect(() => {
    setBarChartLoading(true);
    setCourseChartLoading(true);
    setCategoryChartLoading(true);
    setProductChartLoading(true);
    setKitChartLoading(true);
    PurchaseModel.getPurchaseBarGraph(params).then((data) => {
      setPurchaseInfoGraphDate(Object.keys(data.each_day_purchase_info_obj));
      const total_product_price_set = Object.values(data.each_day_purchase_info_obj).map(
        (price) => price.total_product_price,
      );
      const total_discounted_price_set = Object.values(data.each_day_purchase_info_obj).map(
        (price) => price.total_discounted_price,
      );
      const total_refund_price_set = Object.values(data.each_day_purchase_info_obj).map(
        (price) => price.total_refund_price,
      );
      const total_own_price_set = Object.values(data.each_day_purchase_info_obj).map((price) => {
        return price.total_product_price - price.total_discounted_price - price.total_refund_price;
      });
      setPurchaseInfoGraphTotalProductPriceSet(total_product_price_set);
      setPurchaseInfoGraphTotalDiscountedPriceSet(total_discounted_price_set);
      setPurchaseInfoGraphTotalRefundPriceSet(total_refund_price_set);
      setPurchaseInfoGraphTotalOwnPriceSet(total_own_price_set);
      setBarChartLoading(false);
    });

    PurchaseModel.getPurchaseCourseSale({ ...params, size: 5 }).then(
      async ({ course_sale_info_set: course_sale_info_top_5_set }) => {
        const course_top_5 = Object.values(course_sale_info_top_5_set).map((item) => {
          return { course_title: item.course_title, total_actual_price: item.total_actual_price };
        });

        const sorted_set = await returnOrderedSet(course_top_5, 'total_actual_price', 'desc');

        await setCourseTop5ActualPrice(
          sorted_set.map((item) => {
            return item.total_actual_price;
          }),
        );

        await setCourseTop5Title(
          sorted_set.map((item) => {
            return item.course_title;
          }),
        );

        setCourseChartLoading(false);
      },
    );

    PurchaseModel.getCategoryTop5({ ...params, size: 4 }).then(
      async ({ actual_price_category_top }) => {
        const category_top_5 = [];

        Object.entries(actual_price_category_top).forEach(([key, value]) => {
          category_top_5.push({ category_name: key, total_actual_price: value.total_actual_price });
        });

        const sorted_set = await returnOrderedSet(category_top_5, 'total_actual_price', 'desc');

        await setCategoryTop5ActualPrice(
          sorted_set.map((item) => {
            return item.total_actual_price;
          }),
        );

        await setCategoryTop5Name(
          sorted_set.map((item) => {
            return item.category_name;
          }),
        );
        setCategoryChartLoading(false);
      },
    );

    PurchaseModel.getPurchaseProductSale({ ...params, size: 5 }).then(
      async ({ product_sale_info_set: product_sale_info_top_5_set }) => {
        const product_top_5 = Object.values(product_sale_info_top_5_set).map((item) => {
          return { product_title: item.product_title, total_actual_price: item.total_actual_price };
        });

        const sorted_set = await returnOrderedSet(product_top_5, 'total_actual_price', 'desc');

        await setProductTop5ActualPrice(
          sorted_set.map((item) => {
            return item.total_actual_price;
          }),
        );

        await setProductTop5Title(
          sorted_set.map((item) => {
            return item.product_title;
          }),
        );

        setProductChartLoading(false);
      },
    );

    PurchaseModel.getPurchaseKitSale({
      ...params,
      size: 5,
    }).then(async ({ kit_sale_info_set, kit_set }) => {
      let new_set = returnOrderedSet(
        [...kit_sale_info_set],
        'total_actual_purchased_price',
        'desc',
      );
      if (new_set.length > 0) {
        await setKitTop5ActualPrice(
          new_set.map((item) => {
            return item.total_actual_purchased_price;
          }),
        );

        await setKitTop5Title(
          new_set.map((item) => {
            return item.kit_title;
          }),
        );
      } else {
        await setKitTop5ActualPrice(
          kit_set.slice(0, 5).map((item) => {
            return item.total_actual_purchased_price;
          }),
        );
        await setKitTop5Title(
          kit_set.slice(0, 5).map((item) => {
            return item.kit_title;
          }),
        );
      }
      setKitChartLoading(false);
    });
  }, [props.location.search]);

  return (
    <Fragment>
      <Breadcrumb parent='SaleDashboard' title='Dashboard' />
      <Container fluid={true}>
        <Row>
          <Col lg='12 xl-100'>
            <Card>
              <CardBody>
                <Row style={{ verticalAlign: 'middle' }}>
                  <Col
                    className='text-right'
                    style={{
                      borderRight: '2px solid #F5F5F5',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div className='m-3'>
                      <div>
                        <span className='f-w-900'>오늘 총 매출 </span>
                        <i
                          style={{ fontSize: '20px', verticalAlign: '-1px' }}
                          className='text-warning icofont icofont-money-bag'
                        />
                      </div>
                      <h2>
                        {purchase_info_summary ? (
                          initComma(
                            purchase_info_summary?.now_day_purchase_info.total_product_price,
                          ) + ' 원'
                        ) : (
                          <Loading isLoading={true} />
                        )}
                      </h2>
                    </div>
                    <div className='m-3'>
                      <div>
                        <span className='f-w-900'>이번달 총 매출 </span>
                        <i
                          style={{ fontSize: '20px', verticalAlign: '-1px' }}
                          className='text-warning icofont icofont-money-bag'
                        />
                      </div>
                      <h2>
                        {purchase_info_summary ? (
                          initComma(
                            purchase_info_summary?.now_month_purchase_info.total_product_price,
                          ) + ' 원'
                        ) : (
                          <Loading isLoading={true} />
                        )}
                      </h2>
                    </div>
                  </Col>
                  <Col
                    className='text-right'
                    style={{
                      borderRight: '2px solid #F5F5F5',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div className='m-3'>
                      <div>
                        <span className='f-w-900'>오늘 총 할인 </span>
                        <i
                          style={{ fontSize: '18px', verticalAlign: '0px' }}
                          className='icofont icofont-sale-discount'
                        />
                      </div>
                      <h2>
                        {purchase_info_summary ? (
                          initComma(
                            purchase_info_summary?.now_day_purchase_info.total_discounted_price,
                          ) + ' 원'
                        ) : (
                          <Loading isLoading={true} />
                        )}
                      </h2>
                    </div>
                    <div className='m-3'>
                      <div>
                        <span className='f-w-900'>이번달 총 할인 </span>
                        <i
                          style={{ fontSize: '18px', verticalAlign: '0px' }}
                          className='icofont icofont-sale-discount'
                        />
                      </div>
                      <h2>
                        {purchase_info_summary ? (
                          initComma(
                            purchase_info_summary?.now_month_purchase_info.total_discounted_price,
                          ) + ' 원'
                        ) : (
                          <Loading isLoading={true} />
                        )}
                      </h2>
                    </div>
                  </Col>
                  <Col
                    className='text-right'
                    style={{
                      borderRight: '2px solid #F5F5F5',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div className='m-3'>
                      <div>
                        <span className='f-w-900'>오늘 총 환불 </span>
                        <i
                          style={{ fontSize: '17px', verticalAlign: '0px' }}
                          className='text-danger icofont icofont-minus-circle'
                        />
                      </div>
                      <h2>
                        {purchase_info_summary ? (
                          initComma(
                            purchase_info_summary?.now_day_purchase_info.total_refund_price,
                          ) + ' 원'
                        ) : (
                          <Loading isLoading={true} />
                        )}
                      </h2>
                    </div>
                    <div className='m-3'>
                      <div>
                        <span className='f-w-900'>이번달 총 환불 </span>
                        <i
                          style={{ fontSize: '17px', verticalAlign: '0px' }}
                          className='text-danger icofont icofont-minus-circle'
                        />
                      </div>
                      <h2>
                        {purchase_info_summary ? (
                          initComma(
                            purchase_info_summary?.now_month_purchase_info.total_refund_price,
                          ) + ' 원'
                        ) : (
                          <Loading isLoading={true} />
                        )}
                      </h2>
                    </div>
                  </Col>
                  <Col className='text-right' style={{ borderRight: '2px solid #F5F5F5' }}>
                    <div className='m-3'>
                      <div>
                        <span className='f-w-900'>오늘 총 실매출 </span>
                        <i
                          style={{ fontSize: '20px', verticalAlign: '-2px' }}
                          className='text-success icofont icofont-bill-alt'
                        />
                      </div>
                      <h2>
                        {purchase_info_summary ? (
                          initComma(
                            purchase_info_summary?.now_day_purchase_info
                              .total_actual_purchased_price,
                          ) + ' 원'
                        ) : (
                          <Loading isLoading={true} />
                        )}
                      </h2>
                      <div>
                        {purchase_info_summary &&
                          (() => {
                            const price_diff = {};
                            const total_now_day =
                              purchase_info_summary?.now_day_purchase_info
                                .total_actual_purchased_price;
                            const total_prev_day =
                              purchase_info_summary?.prev_day_purchase_info
                                .total_actual_purchased_price;

                            if (total_now_day > total_prev_day) {
                              price_diff['color'] = '#28a745';
                              price_diff['status'] = '▲ ';
                            } else if (total_now_day < total_prev_day) {
                              price_diff['color'] = '#dc3545';
                              price_diff['status'] = '▼ ';
                            } else {
                              price_diff['color'] = '#8a8a8a';
                              price_diff['status'] = ' ';
                            }

                            return (
                              <>
                                전일 대비
                                <span className='ml-2' style={{ color: price_diff.color }}>
                                  {price_diff.status}
                                  {initComma(total_now_day - total_prev_day) + ' 원'}
                                </span>
                              </>
                            );
                          })()}
                      </div>
                    </div>
                    <div className='m-3'>
                      <div>
                        <span className='f-w-900'>이번달 총 실매출 </span>
                        <i
                          style={{ fontSize: '20px', verticalAlign: '-2px' }}
                          className='text-success icofont icofont-bill-alt'
                        />
                      </div>
                      <h2>
                        {purchase_info_summary ? (
                          initComma(
                            purchase_info_summary?.now_month_purchase_info
                              .total_actual_purchased_price,
                          ) + ' 원'
                        ) : (
                          <Loading isLoading={true} />
                        )}
                      </h2>
                      <div>
                        {purchase_info_summary &&
                          (() => {
                            const price_diff = {};
                            const total_now_month =
                              purchase_info_summary?.now_month_purchase_info
                                .total_actual_purchased_price;
                            const total_prev_month =
                              purchase_info_summary?.prev_month_purchase_info
                                .total_actual_purchased_price;

                            if (total_now_month > total_prev_month) {
                              price_diff['color'] = '#28a745';
                              price_diff['status'] = '▲ ';
                            } else if (total_now_month < total_prev_month) {
                              price_diff['color'] = '#dc3545';
                              price_diff['status'] = '▼ ';
                            } else {
                              price_diff['color'] = '#8a8a8a';
                              price_diff['status'] = ' ';
                            }

                            return (
                              <>
                                전달 대비
                                <span
                                  className='ml-2'
                                  style={{
                                    color: price_diff.color,
                                  }}
                                >
                                  {price_diff.status}
                                  {initComma(total_now_month - total_prev_month) + ' 원'}
                                </span>
                              </>
                            );
                          })()}
                      </div>
                      <span style={{ color: '#8a8a8a' }}>
                        {purchase_info_summary ? (
                          '이번달 예상 총 실매출 ' +
                          initComma(
                            (
                              (purchase_info_summary?.now_month_purchase_info
                                .total_actual_purchased_price /
                                moment().date().toFixed(0)) *
                              30
                            ).toFixed(0),
                          ) +
                          ' 원'
                        ) : (
                          <Loading isLoading={true} />
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
              <Col>
                <div style={{ display: 'flex' }}>
                  <div className={'mr-3'}>
                    <Input
                      type='select'
                      name='job_class'
                      className='form-control'
                      onChange={onDateClick}
                      defaultValue={params.date_start || params.date_end ? 'direct' : 'before_7'}
                    >
                      <option value='direct'>직접설정</option>
                      <option value='before_0'>오늘</option>
                      <option value='before_1'>어제</option>
                      <option value='before_7'>7일전</option>
                      <option value='before_14'>14일전</option>
                      <option value='before_30'>30일전</option>
                      <option value='before_60'>60일전</option>
                      <option value='before_180'>180일전</option>
                    </Input>
                  </div>

                  <div className={'mr-3'}>
                    <DatePicker
                      autoComplete='off'
                      className='form-control digits'
                      name='date_start'
                      dateFormat='yyyy-MM-dd'
                      disabled={is_date_disabled}
                      onChange={(e) => {
                        onDateChange(e, 'date_start');
                      }}
                      placeholderText='시작 날짜'
                      selectsStart
                      selected={date_range.date_start ? new Date(date_range.date_start) : null}
                      startDate={date_range.date_start ? new Date(date_range.date_start) : null}
                      endDate={date_range.date_end ? new Date(date_range.date_end) : null}
                      maxDate={date_range.date_end ? new Date(date_range.date_end) : new Date()}
                    />
                  </div>

                  <div className={'mr-3'}>
                    <DatePicker
                      autoComplete='off'
                      className='form-control digits'
                      name='date_end'
                      disabled={is_date_disabled}
                      dateFormat='yyyy-MM-dd'
                      onChange={(e) => {
                        onDateChange(e, 'date_end');
                      }}
                      placeholderText='마지막 날짜'
                      selectsEnd
                      selected={date_range.date_end ? new Date(date_range.date_end) : null}
                      startDate={date_range.date_start ? new Date(date_range.date_start) : null}
                      endDate={date_range.date_end ? new Date(date_range.date_end) : null}
                      minDate={date_range.date_start ? new Date(date_range.date_start) : null}
                      maxDate={new Date()}
                    />
                  </div>

                  {is_data_selectable && (
                    <Button className='btn btn-primary btn-pill' onClick={onSearchClick}>
                      검색
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md='12' className='mt-3'>
                <Card>
                  <CardHeader>
                    <h5>매출 그래프</h5>
                    <Row className='mt-3'>
                      <Col md='4' />
                      <Col md='2' className='text-center'>
                        <div>
                          <span className='f-w-900'>기간 총 매출 </span>
                          <i
                            style={{ fontSize: '20px', verticalAlign: '-1px' }}
                            className='text-warning icofont icofont-money-bag'
                          />
                        </div>
                        <span style={{ fontSize: '25px', verticalAlign: '0px' }}>
                          {purchase_info_graph_total_product_price_set.length ? (
                            initComma(arraySum(purchase_info_graph_total_product_price_set)) + ' 원'
                          ) : (
                            <Loading isLoading={true} />
                          )}
                        </span>
                      </Col>
                      <Col md='2' className='text-center'>
                        <div>
                          <span className='f-w-900'>기간 총 할인 </span>
                          <i
                            style={{ fontSize: '18px', verticalAlign: '0px' }}
                            className='icofont icofont-sale-discount'
                          />
                        </div>
                        <span style={{ fontSize: '25px', verticalAlign: '0px' }}>
                          {purchase_info_graph_total_discounted_price_set.length ? (
                            initComma(arraySum(purchase_info_graph_total_discounted_price_set)) +
                            ' 원'
                          ) : (
                            <Loading isLoading={true} />
                          )}
                        </span>
                      </Col>
                      <Col md='2' className='text-center'>
                        <div>
                          <span className='f-w-900'>기간 총 환불 </span>
                          <i
                            style={{ fontSize: '17px', verticalAlign: '0px' }}
                            className='text-danger icofont icofont-minus-circle'
                          />
                        </div>
                        <span style={{ fontSize: '25px', verticalAlign: '0px' }}>
                          {purchase_info_graph_total_refund_price_set.length ? (
                            initComma(arraySum(purchase_info_graph_total_refund_price_set)) + ' 원'
                          ) : (
                            <Loading isLoading={true} />
                          )}
                        </span>
                      </Col>
                      <Col md='2' className='text-center'>
                        <div>
                          <span className='f-w-900'>기간 총 실매출 </span>
                          <i
                            style={{ fontSize: '20px', verticalAlign: '-2px' }}
                            className='text-success icofont icofont-bill-alt'
                          />
                        </div>
                        <span style={{ fontSize: '25px', verticalAlign: '0px' }}>
                          {purchase_info_graph_total_own_price_set.length ? (
                            initComma(arraySum(purchase_info_graph_total_own_price_set)) + ' 원'
                          ) : (
                            <Loading isLoading={true} />
                          )}
                        </span>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {bar_chart_loading ? (
                      <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <Loading isLoading={true} />
                      </div>
                    ) : (
                      <Apexchart
                        options={{
                          chart: {
                            type: 'bar',
                            height: 350,
                          },
                          stroke: {
                            show: true,
                            width: 2,
                            colors: ['transparent'],
                          },
                          plotOptions: {
                            bar: {
                              horizontal: false,
                              columnWidth: '55%',
                              endingShape: 'rounded',
                            },
                          },
                          fill: {
                            opacity: [1, 1, 1, 1],
                            gradient: {
                              inverseColors: false,
                              shade: 'light',
                              type: 'vertical',
                              opacityFrom: 0.85,
                              opacityTo: 0.55,
                              stops: [0, 100, 100, 100],
                            },
                          },
                          labels: purchase_info_graph_date || [],
                          xaxis: {
                            type: 'date',
                          },
                          yaxis: {
                            labels: {
                              formatter: function (value) {
                                if (typeof y !== 'undefined') {
                                  return initComma(value.toFixed(0));
                                }
                                return value.toFixed(0);
                              },
                            },
                            title: {
                              text: '원',
                            },
                            forceNiceScale: true,
                            min: 0,
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          tooltip: {
                            shared: true,
                            intersect: false,
                            y: {
                              formatter: function (y) {
                                if (typeof y !== 'undefined') {
                                  return initComma(y.toFixed(0)) + ' 원';
                                }
                                return y;
                              },
                            },
                          },
                          colors: ['#ffa500', '#8a8a8a', '#ff0000', '#008000'],
                        }}
                        series={[
                          {
                            name: '총매출',
                            data:
                              (purchase_info_graph_total_product_price_set.length > 0 &&
                                purchase_info_graph_total_product_price_set) ||
                              [],
                          },
                          {
                            name: '총할인',
                            data:
                              (purchase_info_graph_total_discounted_price_set.length > 0 &&
                                purchase_info_graph_total_discounted_price_set) ||
                              [],
                          },
                          {
                            name: '총환불',
                            data:
                              (purchase_info_graph_total_refund_price_set.length > 0 &&
                                purchase_info_graph_total_refund_price_set) ||
                              [],
                          },
                          {
                            name: '총실매출',
                            data:
                              (purchase_info_graph_total_own_price_set.length > 0 &&
                                purchase_info_graph_total_own_price_set) ||
                              [],
                          },
                        ]}
                        type='bar'
                        height={350}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md='8' className='mt-3'>
                <Card>
                  <CardHeader>
                    <h5>강의별 실매출 TOP 5</h5>
                  </CardHeader>
                  <CardBody style={{ display: 'flex' }}>
                    {course_chart_loading ? (
                      <div style={{ margin: 'auto' }}>
                        <Loading isLoading={true} />
                      </div>
                    ) : (
                      <>
                        <Apexchart
                          options={{
                            stroke: {
                              show: true,
                              width: 2,
                              colors: ['transparent'],
                            },
                            plotOptions: {
                              bar: {
                                horizontal: false,
                                columnWidth: '55%',
                                endingShape: 'rounded',
                              },
                            },
                            fill: {
                              opacity: [0.95],
                            },
                            labels: (course_top_5_title.length > 0 && course_top_5_title) || [],
                            legend: {
                              show: false,
                            },
                            tooltip: {
                              shared: true,
                              intersect: false,
                              y: {
                                formatter: function (y) {
                                  if (typeof y !== 'undefined') {
                                    return initComma(y.toFixed(0)) + ' 원';
                                  }
                                  return y;
                                },
                              },
                            },
                          }}
                          series={
                            (course_top_5_actual_price.length > 0 && course_top_5_actual_price) ||
                            []
                          }
                          type='pie'
                          height={180}
                        />
                        <div style={{ alignSelf: 'center' }}>
                          {chartLabel(course_top_5_title, course_top_5_actual_price)}
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col md='4' className='mt-3'>
                <Card>
                  <CardHeader>
                    <h5>강의 카테고리별 실매출 TOP 5</h5>
                  </CardHeader>
                  <CardBody style={{ display: 'flex' }}>
                    {category_chart_loading ? (
                      <div style={{ margin: 'auto' }}>
                        <Loading isLoading={true} />
                      </div>
                    ) : (
                      <>
                        <Apexchart
                          options={{
                            stroke: {
                              show: true,
                              width: 2,
                              colors: ['transparent'],
                            },
                            plotOptions: {
                              bar: {
                                horizontal: false,
                                columnWidth: '55%',
                                endingShape: 'rounded',
                              },
                            },
                            fill: {
                              opacity: [0.95],
                            },
                            legend: {
                              show: false,
                            },
                            dataLabels: {
                              formatter(val, opts) {
                                const name = opts.w.globals.labels[opts.seriesIndex];
                                return [name, val.toFixed(1) + '%'];
                              },
                            },
                            labels: (category_top_5_name.length > 0 && category_top_5_name) || [],
                            tooltip: {
                              shared: true,
                              intersect: false,
                              y: {
                                formatter: function (y) {
                                  if (typeof y !== 'undefined') {
                                    return initComma(y.toFixed(0)) + ' 원';
                                  }
                                  return y;
                                },
                              },
                            },
                          }}
                          series={
                            (category_top_5_actual_purchased_price.length > 0 &&
                              category_top_5_actual_purchased_price) ||
                            []
                          }
                          type='pie'
                          height={180}
                        />
                        <div style={{ alignSelf: 'center' }}>
                          {chartLabel(
                            category_top_5_name.filter((_, index) => index < 5),
                            category_top_5_actual_purchased_price.filter((_, index) => index < 5),
                          )}
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md='7' className='mt-3'>
                <Card>
                  <CardHeader>
                    <h5>패키지별 실매출 TOP 5</h5>
                  </CardHeader>
                  <CardBody style={{ display: 'flex' }}>
                    {product_chart_loading ? (
                      <div style={{ margin: 'auto' }}>
                        <Loading isLoading={true} />
                      </div>
                    ) : (
                      <>
                        <Apexchart
                          options={{
                            stroke: {
                              show: true,
                              width: 2,
                              colors: ['transparent'],
                            },
                            plotOptions: {
                              bar: {
                                horizontal: false,
                                columnWidth: '55%',
                                endingShape: 'rounded',
                              },
                            },
                            fill: {
                              opacity: [0.95],
                            },
                            labels: (product_top_5_title.length > 0 && product_top_5_title) || [],
                            legend: {
                              show: false,
                            },
                            tooltip: {
                              shared: true,
                              intersect: false,
                              y: {
                                formatter: function (y) {
                                  if (typeof y !== 'undefined') {
                                    return initComma(y.toFixed(0)) + ' 원';
                                  }
                                  return y;
                                },
                              },
                            },
                          }}
                          series={
                            (product_top_5_actual_price.length > 0 && product_top_5_actual_price) ||
                            []
                          }
                          type='pie'
                          height={180}
                        />
                        <div style={{ alignSelf: 'center' }}>
                          {chartLabel(product_top_5_title, product_top_5_actual_price)}
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col md='5' className='mt-3'>
                <Card>
                  <CardHeader>
                    <h5>키트별 실매출 TOP 5</h5>
                  </CardHeader>
                  <CardBody style={{ display: 'flex' }}>
                    {kit_chart_loading ? (
                      <div style={{ margin: 'auto' }}>
                        <Loading isLoading={true} />
                      </div>
                    ) : (
                      <>
                        <Apexchart
                          options={{
                            stroke: {
                              show: true,
                              width: 2,
                              colors: ['transparent'],
                            },
                            plotOptions: {
                              bar: {
                                horizontal: false,
                                columnWidth: '55%',
                                endingShape: 'rounded',
                              },
                            },
                            fill: {
                              opacity: [0.95],
                            },
                            legend: {
                              show: false,
                            },
                            labels: (kit_top_5_title.length > 0 && kit_top_5_title) || [],
                            tooltip: {
                              shared: true,
                              intersect: false,
                              y: {
                                formatter: function (y) {
                                  if (typeof y !== 'undefined') {
                                    return initComma(y.toFixed(0)) + ' 원';
                                  }
                                  return y;
                                },
                              },
                            },
                          }}
                          series={
                            (kit_top_5_actual_price.length > 0 && kit_top_5_actual_price) || []
                          }
                          type='pie'
                          height={200}
                        />
                        <div style={{ alignSelf: 'center' }}>
                          {chartLabel(kit_top_5_title, kit_top_5_actual_price)}
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SaleDashboard;
