import React from 'react';
import Apexchart from 'react-apexcharts';

const AccountPieChart = ({ labels, series, tooltip_y_formatter, chart_label }) => {
  return (
    <>
      <Apexchart
        options={{
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded',
            },
          },
          fill: {
            opacity: [0.95],
          },
          legend: {
            show: false,
          },
          labels: labels,
          tooltip: {
            shared: true,
            intersect: false,
            y: { formatter: tooltip_y_formatter },
          },
          colors: ['#008ffb', '#ff4560', '#feb019'],
        }}
        series={series}
        type='pie'
        height={200}
      />
      <div style={{ alignSelf: 'center' }}>{chart_label}</div>
    </>
  );
};

export default AccountPieChart;
