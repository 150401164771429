import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import EmployeeCsvUploadModal from './EmployeeCsvUploadModal';
import { useParameters, useShowDialog } from '../../../util/b2b_Hooks';
import { TABLE_HEAD_EMPLOYEE } from '../../../constant/options';
import EmployeeTable from './EmployeeTable';
import {
  BROADCAST_SET_MAXIMUM_COUNT,
  useApplicationContext,
} from '../../../context/ApplicationContextProvider';
import CompanyModel from '../../../model/CompanyModel';
import Loading from '../../_common/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { lang } from '../../../lang';
import { addCompanyEmployeeSet } from '../../../redux/b2b_store/company/actions';

/*
    props
    is_new_company: bool            기업등록 전의 상태 -> true, 기업등록 후의 상태 -> false
 */
const EmployeeList = (props) => {
  const { broadcast } = useApplicationContext();
  const { params } = useParameters();
  const { showAlert } = useShowDialog();
  const dispatch = useDispatch();
  const history = useHistory();
  const company_reducer = useSelector((state) => state.company_reducer);
  const temporary_reducer = useSelector((state) => state.temporary_reducer);

  //state
  const [is_editable, setEditable] = useState(false);
  const [is_add_account_modal, setAddAccountModal] = useState(false);
  const [is_csv_upload_modal, setCsvUploadModal] = useState(false);
  const [licensed_employee_count, setLicensedEmployeeCount] = useState(0);
  const [registered_employee_count, setRegisteredEmployeeCount] = useState(0);
  const [employee_set, setEmployeeSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [is_status_update_loading, setIsStatusUpdateLoading] = useState(false);
  const [need_update, setNeedUpdate] = useState(false);

  // ref
  const new_employee_set = useRef([]);
  const company_id = useRef(params.company_id);
  const is_mount = useRef(false);

  useEffect(() => {
    is_mount.current = true;

    if (company_reducer.company_info?.licensed_employee_count) {
      if (is_mount.current) {
        setLicensedEmployeeCount(company_reducer.company_info.licensed_employee_count);
      }

      updateUi();
    }

    if (props.is_new_company) {
      if (is_mount.current) {
        setEmployeeSet(temporary_reducer.temp_employee_set || []);
        setLicensedEmployeeCount(temporary_reducer.temp_licensed_employee_count);
      }
    }

    if (is_mount.current) {
      setNeedUpdate(false);
    }

    // unMount 컴포넌트의 메모리 누수 방지
    return () => {
      is_mount.current = false;
    };
  }, [need_update, company_reducer.company_info?.licensed_employee_count]);

  const updateUi = async () => {
    setIsLoading(true);

    // 기업의 직원 목록 가져오기 API
    if (!props.is_new_company) {
      const employee_list_res = await CompanyModel.getEmployeeList(company_id.current);
      if (employee_list_res.code === 200) {
        if (is_mount.current) {
          setEmployeeSet(employee_list_res.data.employee_set);
          dispatch(addCompanyEmployeeSet(employee_list_res.data.employee_set));
          setRegisteredEmployeeCount(employee_list_res.data.registered_employee_count);
        }
      } else {
        showAlert({
          title: lang.request.request_error_title,
          text: employee_list_res.message,
        });
      }
      if (is_mount.current) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    // employeeCsvUploadModal이 닫히면 csv로 기업에 등록되기 전의 직원목록 초기화
    if (!is_csv_upload_modal) {
      new_employee_set.current = [];
    }
  }, [is_csv_upload_modal]);

  useEffect(() => {
    // 기업등록 전의 상태에서 사용인원 broadcast로 가져오기
    const unlisten = broadcast.listen([BROADCAST_SET_MAXIMUM_COUNT], ({ value }) => {
      setLicensedEmployeeCount(value);
    });

    return () => {
      broadcast.unlisten(unlisten);
    };
  }, [broadcast]);

  const onChangeEmployeeState = async (e) => {
    setIsStatusUpdateLoading(true);

    const status_id = e.currentTarget.value;
    const employee_id = parseInt(e.currentTarget.getAttribute('data-id'), 10);

    // 직원의 상태 변경 API
    const update_course_date_res = await CompanyModel.updateEmployeeStatus(
      company_id.current,
      employee_id,
      {
        status_id,
      },
    );

    if (update_course_date_res.code === 200) {
      setRegisteredEmployeeCount(update_course_date_res.data.registered_employee_count);
    } else {
      showAlert({
        title: lang.request.request_error_title,
        text: update_course_date_res.message,
      });
    }

    let tmp_employee_set = [...employee_set];
    let employee_index = tmp_employee_set.findIndex((employee) => employee.id === employee_id);
    tmp_employee_set[employee_index].status_id = Number(status_id);

    setEmployeeSet(tmp_employee_set);

    setIsStatusUpdateLoading(false);
  };

  const toggleAddAccountModal = () => {
    setAddAccountModal(!is_add_account_modal);
  };

  const toggleCsvUploadModal = () => {
    setCsvUploadModal(!is_csv_upload_modal);
  };

  return (
    <>
      <Col md='12' className='p-b-20 text-right'>
        <span
          style={{
            position: 'absolute',
            left: '1em',
            top: '1em',
          }}
        >
          {props.is_new_company ? (
            <b>
              현재 {employee_set.length || 0}
              {licensed_employee_count >= 1 && '/' + licensed_employee_count} 명
            </b>
          ) : (
            <b>
              총 {registered_employee_count}
              {licensed_employee_count >= 1 && '/' + licensed_employee_count}명
            </b>
          )}
        </span>
        {is_editable ? (
          <>
            <Button
              className='btn-pill btn-air-dark'
              color='dark'
              style={{
                padding: '5px 20px 5px 20px',
                marginRight: '0.5em',
              }}
              onClick={toggleAddAccountModal}
            >
              <span className='f-16'>계정 추가</span>
            </Button>
            <Button
              className='btn-pill btn-air-danger'
              color='danger'
              style={{
                padding: '5px 20px 5px 20px',
              }}
              onClick={() => {
                setEditable(false);
                setNeedUpdate(true);
              }}
            >
              <span className='f-16'>완료</span>
            </Button>
          </>
        ) : props.is_new_company ? (
          <>
            <Button
              className='btn-pill btn-air-danger'
              color='danger'
              style={{
                padding: '5px 20px 5px 20px',
              }}
              onClick={() => {
                // 기업 등록 전 && 사용인원 설정 X
                if (!licensed_employee_count || licensed_employee_count === 0) {
                  showAlert({
                    title: lang.employee.need_set_max_count_title,
                    text: lang.employee.need_set_max_count_desc,
                  });
                } else {
                  toggleAddAccountModal();
                }
              }}
            >
              <span className='f-16'>계정추가</span>
            </Button>
          </>
        ) : (
          <>
            <Button
              className='btn-pill btn-air-dark'
              color='dark'
              style={{
                padding: '5px 20px 5px 20px',
              }}
              onClick={() => setEditable(true)}
            >
              <span className='f-16'>편집</span>
            </Button>
          </>
        )}
      </Col>
      <div className='table-responsive'>
        {isLoading ? (
          <div className='text-center'>
            <Loading isLoading={isLoading} custom_style={{ display: 'flex' }} is_card_body={true} />
          </div>
        ) : (
          <EmployeeTable
            table_head={TABLE_HEAD_EMPLOYEE}
            is_editable={is_editable}
            employee_set={employee_set}
            onChangeEmployeeState={onChangeEmployeeState}
            is_status_update_loading={is_status_update_loading}
            is_new_company={props.is_new_company}
          />
        )}
      </div>
      <Modal isOpen={is_add_account_modal} toggle={toggleAddAccountModal} centered>
        <ModalHeader toggle={toggleAddAccountModal}>계정 추가방법을 선택해주세요</ModalHeader>
        <ModalFooter
          style={{
            textAlign: 'center',
          }}
        >
          <Button
            className='btn-pill'
            color='primary'
            onClick={toggleCsvUploadModal}
            style={{
              padding: '0.8em 2.5em',
              marginLeft: 'auto',
            }}
          >
            csv 파일 업로드
          </Button>
          <Button
            className='btn-pill btn-info'
            style={{
              padding: '0.8em 2.5em',
              marginRight: 'auto',
            }}
            onClick={() => {
              if (props.is_new_company) {
                history.push(`/company/new/add-account`, {
                  licensed_employee_count: licensed_employee_count,
                });
              } else {
                history.push(`/company/${company_id.current}/add-account`);
              }
            }}
          >
            계정 직접 입력
          </Button>
        </ModalFooter>
      </Modal>
      <EmployeeCsvUploadModal
        is_csv_upload_modal={is_csv_upload_modal}
        toggleCsvUploadModal={toggleCsvUploadModal}
        setAddAccountModal={setAddAccountModal}
        employee_set={employee_set}
        is_new_company={props.is_new_company}
        licensed_employee_count={licensed_employee_count}
        setEditable={setEditable}
        new_employee_set={new_employee_set}
        setNeedUpdate={setNeedUpdate}
      />
    </>
  );
};

export default EmployeeList;
