import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';

import { Card, CardHeader, Col, Container, Row, Button } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';

import Form from 'reactstrap/es/Form';
import SweetAlert from 'sweetalert2';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import TextEditor from '../_common/component/TextEditor';
import { errMessageOccur } from '../../util/common';

const obj_err_msg = {
  question: "'제목'",
  answer: "'내용'",
};

const FaqDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.faq_id));
  const [faq_category_set, setFaqCategorySet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [faq, setFaq] = useState({
    id: '',
    faq_category_id: '',
    question: '',
    answer: '',
    write_time: '',
  });

  const onInputChange = (e) => {
    setFaq({ ...faq, [e.target.name]: e.target.value });
  };

  const onInputSubmit = (e) => {
    e.preventDefault();

    const err_msg = errMessageOccur(faq, obj_err_msg);

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      if (faq.id) {
        CommonModel.putFaqDetail(faq).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        CommonModel.postFaq(faq).then(({ faq_id }) => {
          props.history.replace('/faq/' + faq_id);
          window.location.reload();
        });
      }
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const onDeleteClick = (e) => {
    SweetAlert.fire({
      title: '정말로 삭제하시겠습니까?',
      cancelButtonText: '취소',
      confirmButtonText: '삭제',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        CommonModel.deleteFaqDetail(Number(props.match.params.faq_id)).then((r) => {
          window.location.href = '/faq';
        });
      }
    });
  };

  const requestDetail = () => {
    CommonModel.getFaqDetail(Number(props.match.params.faq_id)).then(({ faq }) => {
      if (faq) {
        setFaq(faq);
      }
    });
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'faq_category_set' }).then(({ code_set }) => {
      setFaqCategorySet(code_set);
      if (!Number(props.match.params.faq_id)) {
        setFaq({ ...faq, faq_category_id: code_set[0]?.id });
      }
    });

    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent='FAQ'
        title={`FAQ ${props.match.params.faq_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form
              onSubmit={(e) => {
                onInputSubmit(e);
              }}
            >
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`FAQ ${props.match.params.faq_id === '0' ? '생성' : '정보'}`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {!!Number(props.match.params.faq_id) && !isEdit ? (
                        <Button
                          className='btn-pill btn-danger'
                          style={{ marginRight: '8px' }}
                          onClick={onDeleteClick}
                        >
                          삭제
                        </Button>
                      ) : (
                        ''
                      )}
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      {
                        <>
                          <Button
                            className='btn-pill'
                            color='primary'
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              // 생성(0)일 경우 수정(0 이상)일 경우
                              if (!!Number(props.match.params.faq_id)) {
                                if (isEdit) {
                                  CommonModel.getFaqDetail(Number(props.match.params.faq_id)).then(
                                    ({ faq }) => {
                                      if (faq) {
                                        setFaq(faq);
                                      }
                                    },
                                  );
                                }
                                setIsEdit(!isEdit);
                              } else {
                                window.location.href = '/faq';
                              }
                            }}
                          >
                            {isEdit ? <>취소</> : <>편집</>}
                          </Button>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md='3 mb-3'>
                      <CustomSelectInput
                        name={'faq_category_id'}
                        disabled={!isEdit}
                        value={faq.faq_category_id}
                        label={'카테고리'}
                        is_required={true}
                        onChange={onInputChange}
                        options={faq_category_set}
                      />
                    </Col>
                    <Col md='9 mb-3'>
                      <CustomInput
                        type={'text'}
                        name={'question'}
                        disabled={!isEdit}
                        value={faq.question || ''}
                        is_required={true}
                        label={'제목'}
                        onChange={onInputChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12 mb-3'>
                      <div className='editor-img-100'>
                        <TextEditor
                          html_data={faq.answer || ''}
                          onChange={(value) => {
                            setFaq((prevState) => ({ ...prevState, answer: value }));
                          }}
                          disabled={!isEdit}
                          items={[
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            'blockQuote',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'outdent',
                            'indent',
                            '|',
                            'undo',
                            'redo',
                          ]}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default FaqDetail;
