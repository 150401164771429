import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import CourseModel from '../../../model/CourseModel';
import Loading from '../../_common/Loading';
import CustomInput from '../../_common/component/CustomInput';
import CustomSelectInput from '../../_common/component/CustomSelectInput';
import CommonModel from '../../../model/CommonModel';
import DatePicker from 'react-datepicker';
import SweetAlert from 'sweetalert2';
import CustomLabel from '../../_common/component/CustomLabel';
import Rating from 'react-rating';
import Breadcrumb from '../../_common/breadcrumb/Breadcrumb';

const ReviewDetail = ({ match }) => {
  const {
    params: { review_id },
  } = match;
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [is_parent_check, setParentCheck] = useState(false);
  const [review, setReview] = useState({});
  const [reply_status_set, setReplyStatusSet] = useState([]);

  const initData = async () => {
    try {
      const { review } = await CourseModel.getReviewDetail(review_id);
      CommonModel.staticCode({ code_name: 'reply_status_set' }).then(({ code_set }) => {
        setReplyStatusSet(code_set);
      });
      setReview(review);
      if (Number(review.parent_course_review) > 0) {
        setParentCheck(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    initData();
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setReview((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveAnswer = async (e) => {
    if (review.answer) {
      setIsLoading(true);
      try {
        const { review_id } = await CourseModel.postReviewReply({
          parent_review_id: review.id,
          course_id: review.course,
          contents: review.answer,
        });

        if (review_id) {
          await initData();
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    } else {
      e.preventDefault();
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: '빈 값을 입력할 수 없습니다.',
      });
    }
  };

  const statusUpdateSubmit = async (review_id) => {
    setIsLoading(true);
    const { update_review_id_set } = await CourseModel.putReviewStatus(
      [review_id],
      review.reply_status,
    );
    if (update_review_id_set.length > 0) {
      await initData();
      setIsEdit(false);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Fragment>
        <Breadcrumb parent='강의' title='리뷰 정보' />
        <Container fluid={true}>
          <Row>
            <Col lg='12 xl-100'>
              <Card className='input-air-primary'>
                <CardHeader>
                  <Row>
                    <Col>
                      <h4>
                        Review 관리
                        <Loading isLoading={isLoading} />
                      </h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      <Col>
                        {isEdit && (
                          <>
                            <Button
                              className='btn-pill'
                              color='primary'
                              style={{ marginRight: '8px' }}
                              onClick={() => {
                                statusUpdateSubmit(review.id);
                              }}
                            >
                              저장
                            </Button>
                          </>
                        )}
                        {review.id && (
                          <Button
                            className={isEdit ? 'btn-pill btn btn-light' : 'btn-pill'}
                            color={isEdit ? '' : 'primary'}
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              if (isEdit) {
                                initData();
                              }
                              setIsEdit(!isEdit);
                            }}
                          >
                            {isEdit ? <>취소</> : <>편집</>}
                          </Button>
                        )}
                      </Col>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <CustomInput
                        type={'text'}
                        style={{ cursor: 'pointer' }}
                        className={'input-val-hover-evt'}
                        readOnly={true}
                        onClick={() => {
                          window.open('/course/' + review.course, '_blank');
                        }}
                        value={review.course_title || ''}
                        label={'강의명'}
                      />
                    </Col>
                    <Col>
                      <CustomInput
                        type={'text'}
                        style={{ cursor: 'pointer' }}
                        className={'input-val-hover-evt'}
                        readOnly={true}
                        onClick={() => {
                          window.open('/account/' + review.account, '_blank');
                        }}
                        tooltip_desc={'유저 계정명 / 이름'}
                        value={review.account_name + ' / ' + review.name || ''}
                        label={'작성자 정보'}
                      />
                    </Col>
                    <Col>
                      <CustomInput
                        style={is_parent_check ? { cursor: 'pointer' } : null}
                        className={'input-val-hover-evt'}
                        onClick={(e) => {
                          if (is_parent_check)
                            window.open('/course/review/' + review.parent_course_review, '_blank');
                          else e.preventDefault();
                        }}
                        tooltip_desc={
                          "번호로 표시될 때 현재 글은 대댓글이며 \n반대로 '-'일때는 해당 글은 댓글입니다"
                        }
                        type={'text'}
                        readOnly={true}
                        value={is_parent_check ? review.parent_course_review : '-'}
                        label={'원댓글'}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '20px' }}>
                    <Col>
                      <Label>작성일시</Label>
                      <DatePicker
                        className='form-control'
                        dateFormat='yyyy-MM-dd hh:mm:ss'
                        name='contract_expire_date'
                        selected={review.create_time ? new Date(review.create_time) : null}
                        readOnly
                      />
                    </Col>
                    <Col>
                      <Label>수정일시</Label>
                      <DatePicker
                        className='form-control'
                        dateFormat='yyyy-MM-dd hh:mm:ss'
                        name='update_date'
                        selected={review.update_time ? new Date(review.update_time) : null}
                        readOnly={true}
                      />
                    </Col>
                    <Col>
                      <CustomSelectInput
                        name={'reply_status'}
                        value={review.reply_status || undefined}
                        label={'댓글 상태'}
                        disabled={!isEdit}
                        is_required={true}
                        sub_option_type={'description'}
                        onChange={onChange}
                        options={reply_status_set}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '20px' }}>
                    <Col>
                      <Label>
                        내용
                        <Rating
                          style={{ marginLeft: '5px' }}
                          initialRating={review.rating}
                          emptySymbol='typcn typcn-star-outline'
                          fullSymbol='typcn typcn-star'
                          readonly
                        />
                      </Label>
                      <Input
                        innerRef={(el) => {
                          if (el) el.style.setProperty('border-radius', '0.25rem', 'important');
                        }}
                        type={'textarea'}
                        value={review.contents || ''}
                        readOnly={true}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '20px' }}>
                    <Col>
                      <CustomLabel label={'하위댓글리스트'} />
                      <div
                        style={
                          review.sub_course_review_set && review.sub_course_review_set.length > 0
                            ? {
                                display: 'grid',
                                border: '1px solid #ced4da',
                                borderRadius: '0.25rem',
                                padding: '0.375rem 0.75rem',
                              }
                            : {
                                display: 'grid',
                                border: '1px solid #ced4da',
                                borderRadius: '0.25rem',
                                padding: '2rem 0.75rem',
                              }
                        }
                      >
                        {review.sub_course_review_set
                          ? review.sub_course_review_set.map((review, index) => {
                              return (
                                <ul key={index}>
                                  <li>
                                    <Row>
                                      <Col xl='12'>
                                        <h6
                                          className='mt-3'
                                          style={{ fontSize: '13px', color: '#888' }}
                                        >
                                          <i
                                            className='typcn typcn-user'
                                            style={{ color: '#000' }}
                                          />{' '}
                                          {review.account_name}∙{review.name}
                                        </h6>
                                      </Col>
                                    </Row>
                                    <p
                                      style={{
                                        marginBottom: '10px',
                                        marginTop: '-5px',
                                        fontSize: '15px',
                                      }}
                                    >
                                      <i className='typcn typcn-arrow-forward'></i>
                                      {review.contents}
                                    </p>
                                  </li>
                                </ul>
                              );
                            })
                          : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card className='input-air-primary'>
            <CardHeader>
              <Row>
                <Col>
                  <h4>
                    직접 답변하기
                    <Loading isLoading={isLoading} />
                  </h4>
                </Col>
                <Col sm={6} className='text-right'>
                  <Button
                    className='btn-pill'
                    color='primary'
                    style={{ marginRight: '8px' }}
                    onClick={saveAnswer}
                  >
                    저장
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <CustomInput
                    label={'내용'}
                    tooltip_desc={`내용 입력 후 저장을 누르면 하위 댓글리스트에 추가됩니다. \n(위에 보이는 댓글에 대댓글로 작성됩니다)`}
                    type={'textarea'}
                    name={'answer'}
                    value={review.answer ? review.answer : ''}
                    onChange={onChange}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    </>
  );
};
export default ReviewDetail;
