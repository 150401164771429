import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardHeader, Col, Container, Row, Button, Label } from 'reactstrap';
import Form from 'reactstrap/es/Form';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import SweetAlert from 'sweetalert2';
import CardBody from 'reactstrap/es/CardBody';

import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';
import ImageUploader from '../_common/component/ImageUploader';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import TextEditor from '../_common/component/TextEditor';
import { errMessageOccur } from '../../util/common';
import CustomLabel from '../_common/component/CustomLabel';

import StudyLog from './component/StudyLog';
import Reply from './component/Reply';
import CareerProfile from './component/CareerProfile';
import Tab from '../_common/Tab';
import ParticipationInfo from './component/ParticipationInfo';
import FreeBoard from './component/FreeBoard';
import Review from './component/Review';

const obj_err_msg = {
  bgd_title: "'제목'",
  sub_title: "'빡공단 표시'",
  event_code: "'이벤트 ID'",
  start_date: "'활동 시작일'",
  end_date: "'활동 종료일'",
};

const BgdDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.bgd_id));
  const [isLoading, setIsLoading] = useState(false);

  // 빡공단
  const [bgd, setBgd] = useState({
    bgd_title: '',
    sub_title: '',
    event_code: '',
    start_date: '',
    end_date: '',
  });

  const onDateTimeChange = (e, name) => {
    const date = moment(e);
    if (date.year() < 10000) {
      setBgd({ ...bgd, [name]: e && date.format('YYYY-MM-DD') });
    }
  };

  const onInputChange = (e) => {
    setBgd({ ...bgd, [e.target.name]: e.target.value });
  };

  const onInputSubmit = (e) => {
    e.preventDefault();

    const err_msg = errMessageOccur(bgd, obj_err_msg);

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      if (bgd.id) {
        CommonModel.putbgdDetail(bgd).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        CommonModel.postBgd(bgd).then(({ bgd }) => {
          props.history.replace('/bgd/' + bgd.bgd_id);
          window.location.reload();
        });
      }
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const requestDetail = () => {
    CommonModel.getBgdDetail(Number(props.match.params.bgd_id)).then(({ bgd }) => {
      if (bgd) {
        setBgd(bgd);
      }
    });
  };

  useEffect(() => {
    requestDetail();
  }, []);

  const tab_list = [
    {
      id: '1',
      name: '참여 정보',
      icon: <i className='icofont icofont-book-alt' />,
      component: ParticipationInfo,
    },
    {
      id: '2',
      name: '학습일지',
      icon: <i className='icofont icofont-credit-card' />,
      component: StudyLog,
    },
    {
      id: '3',
      name: '댓글',
      icon: <i className='icofont icofont-edit' />,
      component: Reply,
    },
    {
      id: '4',
      name: '커리어 프로필',
      icon: <i className='icofont icofont-notification' />,
      component: CareerProfile,
    },
    {
      id: '5',
      name: '정보게시판',
      icon: <i className='icofont icofont-notification' />,
      component: FreeBoard,
    },
    {
      id: '6',
      name: '후기',
      icon: <i className='icofont icofont-notification' />,
      component: Review,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb
        parent='빡공단'
        title={`빡공단 ${props.match.params.bgd_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form
              onSubmit={(e) => {
                onInputSubmit(e);
              }}
            >
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`빡공단 ${props.match.params.bgd_id === '0' ? '생성' : '정보'}`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <CustomInput
                            type={'text'}
                            name={'bgd_title'}
                            disabled={!isEdit}
                            value={bgd.bgd_title || ''}
                            is_required={true}
                            label={'제목'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col>
                          <CustomInput
                            type={'text'}
                            name={'sub_title'}
                            disabled={!isEdit}
                            value={bgd.sub_title || ''}
                            is_required={true}
                            label={'빡공단 표시'}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col>
                          <CustomInput
                            type={'text'}
                            name={'event_code'}
                            disabled={!isEdit}
                            value={bgd.event_code || ''}
                            is_required={true}
                            label={'이벤트 코드'}
                            onChange={onInputChange}
                          />
                        </Col>

                        <Col>
                          <CustomLabel is_required={true} label={'활동 시작일'} tooltip_desc={''} />
                          <DatePicker
                            autoComplete='off'
                            className='form-control digits'
                            name='start_date'
                            selectsStart
                            disabled={!isEdit}
                            onChange={(e) => {
                              onDateTimeChange(e, 'start_date');
                            }}
                            locale='ko'
                            selected={bgd.start_date ? new Date(bgd.start_date) : null}
                            startDate={bgd.start_date ? new Date(bgd.start_date) : null}
                            endDate={bgd.end_date ? new Date(bgd.end_date) : null}
                            maxDate={bgd.end_date ? new Date(bgd.end_date) : null}
                            minDate={
                              bgd.recruitment_end_time ? new Date(bgd.recruitment_end_time) : null
                            }
                            dateFormat='yyyy-MM-dd'
                          />
                        </Col>

                        <Col>
                          <CustomLabel is_required={true} label={'활동 종료일'} tooltip_desc={''} />
                          <DatePicker
                            autoComplete='off'
                            className='form-control digits'
                            name='end_date'
                            selectsStart
                            disabled={!isEdit}
                            onChange={(e) => {
                              onDateTimeChange(e, 'end_date');
                            }}
                            locale='ko'
                            selected={bgd.end_date ? new Date(bgd.end_date) : null}
                            startDate={bgd.start_date ? new Date(bgd.start_date) : null}
                            endDate={bgd.end_date ? new Date(bgd.end_date) : null}
                            minDate={bgd.start_date ? new Date(bgd.start_date) : null}
                            dateFormat='yyyy-MM-dd'
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>

            {!!Number(props.match.params.bgd_id) && (
              <Tab obj_id={props.match.params.bgd_id} tab_list={tab_list} />
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BgdDetail;
