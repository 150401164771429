import {
  SET_DELETE_IMG,
  CLEAR_DELETE_IMG,
  STORED_STATIC_CODE,
  CLEAR_STATIC_CODE,
} from '../action_type';

export const set_delete_img = (img_type, url) => ({
  type: SET_DELETE_IMG,
  img_type,
  url,
});

export const clear_delete_img = (img_type) => ({
  type: CLEAR_DELETE_IMG,
  img_type,
});

export const clear_delete_file = (file_type) => ({
  type: CLEAR_DELETE_IMG,
  file_type,
});

export const stored_static_code = (key, code_set) => ({
  type: STORED_STATIC_CODE,
  key,
  code_set,
});

export const clear_static_code = () => ({
  type: CLEAR_STATIC_CODE,
});
