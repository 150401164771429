import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Input, Label, Row } from 'reactstrap';
import Loading from '../../_common/Loading';
import moment from 'moment';
import domtoimage from 'dom-to-image';
import CustomLabel from '../../_common/component/CustomLabel';

import bearu_certificate_bg from '../../../assets/images/bearu_certificate_bg_x2.jpg';
import tendom_sign from '../../../assets/images/tendom_sign.png';
import { getBase64 } from '../../../util/common';

const BearuCertificate = (props) => {
  const [certificate_info, setCertificateInfo] = useState({
    course_name: {
      value: '',
      label: '교육 과정명',
      is_disabled: false,
    },
    education_date: {
      value: '',
      label: '교육 기간',
      is_disabled: false,
    },
    company_name: {
      value: '',
      label: '기관명',
      is_disabled: false,
    },
    student_name: {
      value: '',
      label: '학생명',
      is_disabled: false,
    },
    price: {
      value: '',
      label: '수강료',
      is_disabled: false,
    },
    complete_percentage: {
      value: '',
      label: '이수율',
      is_disabled: false,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [scale, setScale] = useState(0);
  const [height, setHeight] = useState(0);

  const download_certificate_dom = useRef(false);
  const pre_certificate_dom = useRef(false);
  const pre_certificate_dom_parent = useRef(false);

  const [background, setBackground] = useState(null);
  const [sign, setSign] = useState(null);

  const certificate_img = (_ref, _scale, _color = false) => {
    return (
      <div
        ref={_ref}
        style={{
          fontFamily: 'initial',
          position: 'relative',
          height: '1684px',
          width: '1191px',
          background: `url('${background}')`,
          transform: 'scale(' + _scale + ')',
          transformOrigin: 'top left',
        }}
      >
        <div
          className='elem-title'
          style={{
            position: 'absolute',
            left: '95.5px',
            top: '177px',
            letterSpacing: '31px',
            marginLeft: '17px',
            textAlign: 'center',
            width: '1000px',
            fontSize: '110px',
          }}
        >
          수강확인증
        </div>

        <div
          className='elem_cert_content'
          style={{
            position: 'absolute',
            left: '196px',
            top: '490px',
            letterSpacing: '0px',
            textAlign: 'left',
            width: '800px',
            fontSize: '24px',
          }}
        >
          {!certificate_info.course_name.is_disabled && (
            <div className='elem_cert_row'>
              <div className='key'>
                <label htmlFor='textarea'>교육 과정명</label>
              </div>
              <div
                style={_color && !certificate_info.course_name.value ? { color: _color } : {}}
                className='value'
              >
                {certificate_info.course_name.value ||
                  '[파워포인트]\n하루10분, 상위 1%로 가는 파워포인트 온라인 클래스'}
              </div>
            </div>
          )}
          {!certificate_info.education_date.is_disabled && (
            <div className='elem_cert_row'>
              <div className='key'>
                <label htmlFor='textarea'>교육 기간</label>
              </div>
              <div
                style={_color && !certificate_info.education_date.value ? { color: _color } : {}}
                className='value'
              >
                {certificate_info.education_date.value || '2021. 3. 24 ~ 2021. 9. 20'}
              </div>
            </div>
          )}
          {!certificate_info.company_name.is_disabled && (
            <div className='elem_cert_row'>
              <div className='key'>
                <label htmlFor='textarea'>기관명</label>
              </div>
              <div
                style={_color && !certificate_info.company_name.value ? { color: _color } : {}}
                className='value'
              >
                {certificate_info.company_name.value || '베어유 (주식회사 텐덤)'}
              </div>
            </div>
          )}
          {!certificate_info.student_name.is_disabled && (
            <div className='elem_cert_row'>
              <div className='key'>
                <label htmlFor='textarea'>학생명</label>
              </div>
              <div
                style={_color && !certificate_info.student_name.value ? { color: _color } : {}}
                className='value'
              >
                {certificate_info.student_name.value || '윤 지 선'}
              </div>
            </div>
          )}
          {!certificate_info.price.is_disabled && (
            <div className='elem_cert_row'>
              <div className='key'>
                <label htmlFor='textarea'>수강료</label>
              </div>
              <div
                style={_color && !certificate_info.price.value ? { color: _color } : {}}
                className='value'
              >
                {certificate_info.price.value || '125,000원'}
              </div>
            </div>
          )}
          {!certificate_info.complete_percentage.is_disabled && (
            <div className='elem_cert_row'>
              <div className='key'>
                <label htmlFor='textarea'>이수율</label>
              </div>
              <div
                style={
                  _color && !certificate_info.complete_percentage.value ? { color: _color } : {}
                }
                className='value'
              >
                {certificate_info.complete_percentage.value ||
                  '총 24강 중 9강 수강완료\n(수강시간 : 272분 중 34% 완료)'}
              </div>
            </div>
          )}
        </div>

        <div
          className='elem-content'
          style={{
            position: 'absolute',
            left: '120px',
            top: '1067px',
            letterSpacing: '3px',
            lineHeight: '220%',
            textAlign: 'center',
            width: '1000px',
            fontSize: '34px',
          }}
        >
          위 학생이 상기 강좌를 수강함을 확인함
        </div>
        <div
          className='elem-issue-date'
          style={{
            position: 'absolute',
            left: '415px',
            top: '1321px',
            textAlign: 'center',
            width: '265px',
            fontSize: '27px',
          }}
        >
          {moment(new Date()).format('YYYY년 M월 D일')}
        </div>
        <div
          className='elem-company-sign'
          style={{
            position: 'absolute',
            left: '860px',
            top: '1341px',
            width: '216px',
            height: '202px',
            background: `url(${sign})`,
          }}
        />
        <div
          className='elem-company-name'
          style={{
            position: 'absolute',
            left: '700px',
            top: '1321px',
            width: '265px',
            textAlign: 'center',
            fontSize: '27px',
          }}
        >
          주식회사 텐덤
        </div>
      </div>
    );
  };

  const onCertificateDownload = async (e) => {
    setIsLoading(true);
    e.target.disabled = true;
    const _node = download_certificate_dom.current;

    // domtoimage library used
    await domtoimage
      .toBlob(_node)
      .then(function (blob) {
        // blob to a tag
        const _a = document.createElement('a');
        _a.style = 'display: none';
        _a.href = window.URL.createObjectURL(new Blob([blob], { type: 'image/png' }));
        _a.download = `베어유_수강확인증`;
        _a.click();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });

    e.target.disabled = false;
    setIsLoading(false);
  };

  const onInputChange = (e) => {
    setCertificateInfo((prevState) => ({
      ...prevState,
      [e.target.name]: { ...prevState[e.target.name], value: e.target.value },
    }));
  };

  const onCheckboxChange = (e) => {
    setCertificateInfo((prevState) => ({
      ...prevState,
      [e.target.name]: {
        ...prevState[e.target.name],
        is_disabled: !prevState[e.target.name].is_disabled,
      },
    }));
  };

  const onResizeCertificateImg = () => {
    setScale(pre_certificate_dom_parent.current.clientWidth / 1191);
    setHeight(
      pre_certificate_dom.current.clientHeight *
        (pre_certificate_dom_parent.current.clientWidth / 1191),
    );
  };

  useEffect(() => {
    setScale(pre_certificate_dom_parent.current.clientWidth / 1191);
    setHeight(
      pre_certificate_dom.current.clientHeight *
        (pre_certificate_dom_parent.current.clientWidth / 1191),
    );
    window.addEventListener('resize', onResizeCertificateImg);
    return () => {
      window.removeEventListener('resize', onResizeCertificateImg);
    };
  }, [props]);

  useEffect(() => {
    getBase64(bearu_certificate_bg, (data) => {
      setBackground(data);
    });

    getBase64(tendom_sign, (data) => {
      setSign(data);
    });
  }, []);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md='6 mb-3'>
            {Object.keys(certificate_info).map((item, index) => {
              return (
                <Row key={index}>
                  <Col md='12 mb-3'>
                    <div className={'d-flex justify-content-between'}>
                      <CustomLabel
                        label={certificate_info[item].label}
                        tooltip_desc={certificate_info[item].label}
                      />
                      <div className='checkbox checkbox-danger'>
                        <Input id={item} type='checkbox' name={item} onChange={onCheckboxChange} />
                        <Label className='m-0' for={item}>
                          사용안함
                        </Label>
                      </div>
                    </div>
                    <Input
                      type={'textarea'}
                      name={item}
                      disabled={certificate_info[item].is_disabled}
                      value={certificate_info[item].value || ''}
                      onChange={onInputChange}
                    />
                  </Col>
                </Row>
              );
            })}
            <Row>
              <Col className='text-right' md='12 mb-3'>
                <Loading isLoading={isLoading} />
                <Button className='ml-3 btn btn-primary btn-pill' onClick={onCertificateDownload}>
                  다운로드
                </Button>
              </Col>
            </Row>
          </Col>

          <div
            style={{
              width: '0px',
              height: '0px',
              overflow: 'hidden',
            }}
          >
            {certificate_img(download_certificate_dom, 1)}
          </div>
          <Col md='6 mb-3'>
            <div style={{ height: height }} ref={pre_certificate_dom_parent}>
              {certificate_img(pre_certificate_dom, scale.toFixed(2), 'hotpink')}
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BearuCertificate;
