import {
  STORED_COURSE_SALE,
  SET_COURSE_SALE,
  CLEAR_COURSE_SALE,
  ADD_COURSE_SALE,
  DELETE_COURSE_SALE,
  SET_COURSE_SALE_PRICE,
} from '../../action_type';

const INIT_STATE = [];

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_COURSE_SALE:
      return INIT_STATE;

    case STORED_COURSE_SALE:
      return action.data;

    case SET_COURSE_SALE:
      const tmp_state = state[action.sale_index];
      tmp_state[action.name] = action.value;
      return [...state];

    case ADD_COURSE_SALE:
      state.push({
        sale_short_name: '',
        rental_time_in_day: 0,
        is_hide: 0,
        order_number: 0,
        purchase_type: 1,
        price_info: [
          {
            currency_code: 1,
            original_price: 0,
            price: 0,
            tax_free_price: 0,
          },
        ],
        is_deleted: 0,
      });
      return [...state];

    case DELETE_COURSE_SALE:
      return state.filter((item, i) => i !== action.sale_index);

    case SET_COURSE_SALE_PRICE:
      const price_set =
        state[action.sale_index].price_info[state[action.sale_index].price_info.length - 1];
      price_set[action.name] = action.value;
      return [...state];

    default:
      return state;
  }
};
