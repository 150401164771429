import axios from 'axios';

class InvoiceModel {
  async getMasterList(filter) {
    const {
      data: { data, code },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/invoice/master', {
      params: filter,
    });

    if (code === 403) {
      window.location.href = '/invoice';
    }

    return data;
  }

  async getMasterSummary(account_id, year, month) {
    const {
      data: { data, code },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/invoice/master/' + account_id,
      {
        params: {
          year: year,
          month: month,
        },
      },
    );

    if (code === 403) {
      window.location.href = '/invoice';
    }

    return data;
  }

  async getMasterSummaryList(account_id, year) {
    const {
      data: { data, code },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/invoice/master/' + account_id + '/list',
      {
        params: {
          year: year,
        },
      },
    );

    if (code === 403) {
      window.location.href = '/invoice';
    }

    return data;
  }

  async getMasterInvoiceDetail(account_id, year, month) {
    const {
      data: { data, code },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/invoice/master/' + account_id + '/detail',
      {
        params: {
          year: year,
          month: month,
        },
      },
    );

    if (code === 403) {
      window.location.href = '/invoice';
    }

    return data;
  }

  async postInvoice(account_id, invoice_set, invoice, year, month) {
    const {
      data: { data },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/invoice/master/' + account_id + '/detail',
      {
        invoice_channel_set: invoice_set,
        invoice: invoice,
        year: year,
        month: month,
      },
    );
    return data;
  }

  async postInvoiceInfo(account_id, invoice_info_set) {
    const {
      data: {
        data: { invoice_info_list },
      },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/invoice/master/' + account_id + '/invoice-info',
      {
        invoice_info_set,
      },
    );
    return invoice_info_list;
  }

  async postInvoiceTemp(account_id, temp_invoice_set, year, month) {
    const {
      data: {
        data: { tmp_invoice_set },
      },
    } = await axios.post(
      process.env.REACT_APP_API_URL + '/cms-api/v1/invoice/master/' + account_id + '/invoice-temp',
      {
        temp_invoice_set,
        year,
        month,
      },
    );
    return tmp_invoice_set;
  }

  async deleteInvoiceTemp(temp_id) {
    const {
      data: { data },
    } = await axios.delete(
      process.env.REACT_APP_API_URL + '/cms-api/v1/invoice/master/invoice-temp/' + temp_id,
    );
    return data;
  }

  async getTotalMasterList(filter) {
    const {
      data: { data },
    } = await axios.get(
      process.env.REACT_APP_API_URL + '/cms-api/v1/invoice/master/invoice-total',
      {
        params: filter,
      },
    );
    return data;
  }
}

export default new InvoiceModel();
