// 숫자 3자리마다 콤마
exports.initComma = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '0';
};

// Eng -> Kor 남/여
exports.accountGender = (gender) => {
  if (gender === 'male') return '남자';
  else if (gender === 'female') return '여자';
};

exports.courseSaleStatus = (code) => {
  if (code === 1) return '판매준비';
  if (code === 2) return '판매중';
  if (code === 3) return '판매중지';
  if (code === 4) return '작성중';
  if (code === 5) return '숨김';
};

exports.onAirStatus = (code) => {
  if (code === 0) return '종료';
  if (code === 1) return '진행';
  if (code === 2) return '대기';
};

exports.accountType = (account_type) => {
  if (account_type === 2) return '마스터';
  if (account_type === 5) return '베어유관리자';
};

exports.toQueryString = (object) => {
  let str = '';
  for (let key in object) {
    if (object[key]) {
      if (str) {
        str += '&';
      }
      str += key + '=' + encodeURIComponent(object[key]);
    }
  }
  return str;
};

/**
 * @param {Object} obj "input state"
 * @param {Object} err_obj "
 * @param {Object} last_msg "to express what should do {use: true, msg: '입력해주세요.'}"
 **/
exports.errMessageOccur = (obj, err_obj, last_msg = { use: true, msg: '입력해주세요.' }) => {
  let err_msg = [];

  Object.entries(err_obj).forEach((val) => {
    let key = val[0];
    let value = val[1];
    if (obj[key] === '' || obj[key] === null) {
      err_msg.push(`'${value}'`);
    }
  });

  if (last_msg.use) {
    if (err_msg.length) {
      err_msg.push(last_msg.msg);
    }
  }

  return err_msg;
};

exports.badgeStyle = (status) => {
  let style = 'badge';
  if (status === 1) {
    style += ' badge-info';
  } else if (status === 2) {
    style += ' badge-success';
  } else if (status === 3) {
    style += ' badge-danger';
  } else if (status === 4) {
    style += ' badge-primary';
  } else if (status === 5) {
    style += ' badge-secondary';
  }
  return style;
};

exports.onBooleanBadgeStyle = (code) => {
  let style = 'badge';
  if (code === 0 || code === false) {
    style += ' badge-danger';
  } else if (code === 1 || code === true) {
    style += ' badge-success';
  } else if (code === 2) {
    style += ' badge-light';
  }
  return style;
};

exports.accountBadgeStyle = (status) => {
  let style = 'badge';
  if (status === 1) {
    style += ' badge-success';
  } else if (status === 2) {
    style += ' badge-info';
  } else if (status === 3) {
    style += ' badge-danger';
  } else if (status === 4) {
    style += ' badge-warning';
  } else if (status === 5) {
    style += ' badge-danger';
  } else if (status === 6) {
    style += ' badge-danger';
  } else if (status === 7) {
    style += ' badge-danger';
  }
  return style;
};

exports.purchaseStatus = (status) => {
  let style;
  if (status === '결재취소') {
    style = 'text-danger';
  } else if (status === '결재준비') {
    style = 'text-warning';
  } else if (status === '결재완료') {
    style = 'text-success';
  }
  return style;
};

exports.apiDatetimeReplace = (datetime) => {
  return datetime ? datetime.replace('T', ' ') : '';
};

exports.copyStringToClipboard = (value) => {
  navigator.clipboard.writeText(value);
};

exports.hexToRgb = (hex) => {
  return hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))
    .join(',');
};

exports.rgbToHex = (rgb) => {
  let color =
    '#' +
    rgb
      .split(',')
      .map((x) => parseInt(x).toString(16).padStart(2, '0'))
      .join('');
  if (color === '#NaN') {
    color = '#000000';
  }
  return color;
};

exports.createUuid = () => {
  let dt = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : r && 0x3 | 0x8).toString(16);
  });
};

// https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
exports.createUuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-mixed-operators
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
};

exports.createRandomSmallAlphabet = (length) => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

exports.createRandomNumber = (length) => {
  let result = '';
  const characters = '1234567890';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

exports.createRandomBigSmallAlphabetNumber = (length) => {
  let result = '';
  const characters = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

exports.copyToClipBoardWithPlainText = (_table, _cb) => {
  let res = '';
  let rows = _table.querySelectorAll('tr');
  let rowSize = rows.length;
  for (let i = 0; i < rowSize; i++) {
    let totalTextContent = '';
    let row = rows[i];
    let cols =
      row.querySelectorAll('th').length > 0
        ? row.querySelectorAll('th')
        : row.querySelectorAll('td');
    let colSize = cols.length;
    for (let j = 0; j < colSize; j++) {
      let colText = '"';
      let col = cols[j];
      let selectNodes = col.querySelectorAll('select');
      let selectNodeLength = selectNodes.length;
      if (selectNodeLength > 0) {
        for (let k = 0; k < selectNodeLength; k++) {
          let child = selectNodes[k];
          colText += child.options[child.selectedIndex].innerText;
          if (k !== selectNodeLength - 1) {
            colText += ', ';
          }
        }
      } else {
        colText += col.innerText;
      }

      colText += '"';
      if (j !== colSize - 1) {
        colText += '\t';
      }
      totalTextContent += colText;
    }
    res += totalTextContent + '\n';
  }
  navigator.clipboard.writeText(res);
  _cb && _cb();
};

exports.zip = (arrays) => {
  return arrays[0].map(function (_, i) {
    return arrays.map(function (array) {
      return array[i];
    });
  });
};

exports.getDaysArray = (start, end) => {
  let arr = [];
  let dt = new Date(start);
  for (; dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

exports.getIntegerArray = (start, end, step) => {
  return Array.from({ length: (end - start) / step + 1 }, (_, i) => start + i * step);
};

exports.getInvoiceType = (type_id) => {
  if (type_id === 1) {
    return '개인';
  } else if (type_id === 2) {
    return '기업';
  }
  return '면세';
};

exports.getBase64 = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

// same of python lib
exports.changed_round = (cal_num) => {
  let res = 0;
  if (cal_num % 1 >= 0.5) {
    res = Math.ceil(cal_num);
  } else {
    res = Math.round(cal_num);
  }
  return res;
};

exports.select = (flag, a = null, b = null) => {
  return flag ? a : b;
};

exports.is_empty_value = (value, with_trim = true) => {
  let is_empty = false;
  if (value === null || undefined) {
    is_empty = true;
  } else {
    if (typeof value === 'string') {
      if (with_trim === true) {
        if (value.trim() === '') {
          is_empty = true;
        }
      } else {
        is_empty = false;
      }
    } else {
      is_empty = false;
    }
  }
  return is_empty;
};
