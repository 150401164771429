import React from 'react';
import { TOGGLE_SORT_NUM_INIT } from '../../../constant/options';

/*
    props
    table_head: {}                      options.js 참고
    toggle_sort: state:int              각 테이블별로 정렬 state -> 0|1|2|3...
    setToggleSort: setState(int)        toggle_sort 변경용 setState
    toggleSortByTarget: fnc => void     정렬용 함수
 */
const TableHead = (props) => {
  return (
    <thead>
      <tr>
        {props.table_head.map((item, index) => {
          return item.need_toggle ? (
            <th
              scope={'col'}
              className={item.class_name}
              style={item.style}
              key={index}
              onClick={() => {
                props.toggle_sort !== item.toggle_num
                  ? props.setToggleSort(item.toggle_num)
                  : props.setToggleSort(TOGGLE_SORT_NUM_INIT);
                props.toggleSortByTarget(item.target);
              }}
            >
              <span>{item.title}</span>
              {props.toggle_sort === item.toggle_num ? (
                <i
                  className='fa fa-caret-up'
                  style={{
                    paddingLeft: '0.3em',
                  }}
                />
              ) : (
                <i
                  className='fa fa-caret-down'
                  style={{
                    paddingLeft: '0.3em',
                  }}
                />
              )}
            </th>
          ) : (
            <th scope={'col'} className={item.class_name} style={item.style} key={index}>
              <span>{item.title}</span>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
