import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

import EbookModel from '../../../model/EbookModel';
import { toQueryString } from '../../../util/common';
import { TABLE_HEAD_BOOKMARK_USER } from '../../../constant/options';

import Loading from '../../_common/Loading';
import BookmarkUserTable from './BookmarkUserTable';
import CustomPagination from '../../_common/CustomPagination';
import CustomSelect2 from '../../_common/component/CustomSelect2';
import CustomDateRangeFilter from '../../_common/component/CustomDateRangeFilter';
import CustomTooltip from '../../_common/component/CustomTooltip';

const BookmarkUser = (props) => {
  const location = useLocation();
  const history = useHistory();
  const search_params = queryString.parse(location.search);
  const params = useParams();
  const CommonStore = useSelector((state) => state.CommonStore);

  const [bookmark_user_set, setBookmarkUserSet] = useState([]);
  const [bookmark_user_count, setBookmarkUserCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filter_set, setFilterSet] = useState();
  const [is_download, setDownload] = useState(false);

  const ebook_id = useRef(params.ebook_id);

  useEffect(() => {
    updateUi();
  }, [location.search]);

  const updateUi = async () => {
    setIsLoading(true);

    let filter = {
      page: search_params.page ? parseInt(search_params.page) : 1,
      gender: search_params.gender ? search_params.gender : null,
      job_class_id: search_params.job_class_id ? search_params.job_class_id : null,
      is_course: search_params.is_course ? search_params.is_course : null,
      date_start: search_params.date_start ? search_params.date_start : null,
      date_end: search_params.date_end ? search_params.date_end : null,
    };

    setFilterSet(filter);

    // 현재 강의 북마크 유저 가져오기
    const get_bookmark_user_res = await EbookModel.getFavoriteUserDetail(props.obj_id, filter);
    if (get_bookmark_user_res.code === 200) {
      setBookmarkUserCount(get_bookmark_user_res.data.total_count);
      setBookmarkUserSet(get_bookmark_user_res.data.favorite_user_set);
    }

    setIsLoading(false);
  };

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    history.push(`?${queryString}`, params);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...search_params,
      page: page,
    });
  };

  const onFilterChange = (e) => {
    setFilterSet({ ...filter_set, [e.target.name]: e.target.value });
  };

  const onFilterSubmit = () => {
    pushHistory({
      ...filter_set,
      page: 1,
    });
  };

  const onDetailCSVRequest = async (e) => {
    if (!is_download) {
      e.target.disabled = true;
      setDownload(true);

      const _a = document.createElement('a');
      _a.style = 'display: none';
      const _file = await EbookModel.getEbookDetailFavoriteCSV(ebook_id.current, filter_set);

      const url = window.URL.createObjectURL(_file.data);
      _a.href = url;
      _a.download = `course-detail-favorite-info-${moment(new Date()).format('YYYY-MM-DD')}.csv`;
      _a.click();
      window.URL.revokeObjectURL(url);

      e.target.disabled = false;
      setDownload(false);
    }
  };

  return (
    <Row>
      {isLoading ? (
        <Col col={'12'} className={'text-center'}>
          <Loading isLoading={isLoading} />
        </Col>
      ) : (
        <>
          <Col md={3}>
            <CustomSelect2
              name={'gender'}
              label_title={'성별'}
              options={[
                { name: '선택', id: '' },
                { name: '남자', id: 'male' },
                { name: '여자', id: 'female' },
              ]}
              defaultValue={search_params.gender ? search_params.gender : null}
              on_change={onFilterChange}
            />
          </Col>
          <Col md={3}>
            <CustomSelect2
              name={'job_class_id'}
              label_title={'직업'}
              options={
                CommonStore.job_class_set &&
                [{ name: '선택', id: '' }].concat(CommonStore.job_class_set)
              }
              defaultValue={search_params.job_class_id ? search_params.job_class_id : null}
              on_change={onFilterChange}
            />
          </Col>
          <Col md={3}>
            <CustomSelect2
              name={'is_course'}
              label_title={'구매여부'}
              options={[
                { name: '선택', id: '' },
                { name: '보유중', id: 'has' },
                { name: '미보유', id: 'has_not' },
              ]}
              defaultValue={search_params.is_course ? search_params.is_course : null}
              on_change={onFilterChange}
            />
          </Col>

          <Col md={8} className={'m-t-15'}>
            <CustomDateRangeFilter
              type={'bookmark'}
              setFilter={setFilterSet}
              filter_set={filter_set}
            />
          </Col>
          <Col
            className='text-right mt-auto'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <CustomTooltip
              target={'help-download-course-detail-favorite-csv'}
              content={
                '✅ 필터를 적용하지 않은 경우 현재 강의의 모든 북마크 데이터를 다운받습니다\n' +
                '✅ 필터를 적용한 경우 현재 강의의 모든 북마크 데이터 중 필터가 적용된 데이터만 다운받습니다'
              }
            />
            <Button
              id={'help-download-course-detail-favorite-csv'}
              className='btn btn-pill'
              color='info'
              style={{
                marginRight: '10px',
                padding: '6px 20px',
              }}
              onClick={onDetailCSVRequest}
            >
              csv 다운로드
            </Button>
            <Button
              className='btn btn-pill'
              color='success'
              style={{
                padding: '6px 20px',
              }}
              onClick={() => {
                onFilterSubmit();
              }}
            >
              필터 적용
            </Button>
          </Col>
          <Col sm={7} className={'m-t-20'}>
            <p style={{ margin: '7px 7px 15px 7px' }}>
              총: <span className={'txt-info'}>{bookmark_user_count}</span>명{' '}
              <Loading isLoading={isLoading} />
            </p>
          </Col>
          <BookmarkUserTable
            bookmark_user_set={bookmark_user_set}
            table_head={TABLE_HEAD_BOOKMARK_USER}
          />
          <div className='m-auto'>
            <CustomPagination
              current_page={search_params.page ? Number(search_params.page) : 1}
              max_page={Math.ceil(
                search_params.size
                  ? bookmark_user_count / search_params.size
                  : bookmark_user_count / 10,
              )}
              onPageChange={onPageChange}
            />
          </div>
        </>
      )}
    </Row>
  );
};

export default BookmarkUser;
