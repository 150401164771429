import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';

import { Card, CardHeader, Col, Container, Row, Button, Label } from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import ImageUploader from '../_common/component/ImageUploader';

import Form from 'reactstrap/es/Form';
import SweetAlert from 'sweetalert2';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import TextEditor from '../_common/component/TextEditor';
import { errMessageOccur } from '../../util/common';
import CustomLabel from '../_common/component/CustomLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const tooptip_word = {
  thumbnail_image_url: '이미지 미리보기\n- 홈페이지 이슈체크\n- 상단 더보기 탭 링크',
  is_notification: '활성화 : \n작성시, 사용자가 알림을 받음\n\n비활성화 :\n알림 없음',
};

const obj_err_msg = {
  title: "'제목'",
  content: "'내용'",
  thumbnail_image_url: "'썸네일'",
};

const NoticeDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.notice_id));
  const [notice_category_set, setNoticeCategorySet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [notice, setNotice] = useState({
    id: '',
    notice_category_id: '',
    title: '',
    content: '',
    thumbnail_image_url: '',
    is_notification: 0,
    is_deleted: 0,
    write_time: '',
    start_time: '',
    expire_time: '',
  });

  const onDateTimeChange = (e, name) => {
    const date = moment(e);
    if (date.year() < 10000) {
      setNotice({ ...notice, [name]: e && date.format('YYYY-MM-DDTHH:mm:ss') });
    }
  };

  const onInputChange = (e) => {
    setNotice({ ...notice, [e.target.name]: e.target.value });
  };

  const onCategoryChange = (e) => {
    if (parseInt(e.target.value) !== 2) {
      setNotice({ ...notice, [e.target.name]: e.target.value, start_time: '', expire_time: '' });
    } else {
      setNotice({ ...notice, [e.target.name]: e.target.value });
    }
  };

  const onInputSubmit = (e) => {
    e.preventDefault();

    const err_msg = errMessageOccur(notice, obj_err_msg);

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      if (notice.id) {
        CommonModel.putNoticeDetail(notice).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        CommonModel.postNotice(notice).then(({ notice_id }) => {
          props.history.replace('/notice/' + notice_id);
          window.location.reload();
        });
      }
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const requestDetail = () => {
    CommonModel.getNoticeDetail(Number(props.match.params.notice_id)).then(({ notice }) => {
      if (notice) {
        setNotice(notice);
      }
    });
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'notice_category_set' }).then(({ code_set }) => {
      setNoticeCategorySet(code_set);
      if (!Number(props.match.params.notice_id)) {
        setNotice({ ...notice, notice_category_id: code_set[0]?.id });
      }
    });
    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent='공지'
        title={`공지 ${props.match.params.notice_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form
              onSubmit={(e) => {
                onInputSubmit(e);
              }}
            >
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`공지 ${props.match.params.notice_id === '0' ? '생성' : '정보'}`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      {
                        <>
                          <Button
                            className='btn-pill'
                            color='primary'
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                              // 생성(0)일 경우 수정(0 이상)일 경우
                              if (!!Number(props.match.params.notice_id)) {
                                if (isEdit) {
                                  CommonModel.getNoticeDetail(
                                    Number(props.match.params.notice_id),
                                  ).then(({ notice }) => {
                                    if (notice) {
                                      setNotice(notice);
                                    }
                                  });
                                }
                                setIsEdit(!isEdit);
                              } else {
                                window.location.href = '/notice';
                              }
                            }}
                          >
                            {isEdit ? <>취소</> : <>편집</>}
                          </Button>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col
                      md={3}
                      style={{ minWidth: '360px', maxHeight: '300px', alignSelf: 'center' }}
                    >
                      <div className='blog-box text-center'>
                        <div>
                          <CustomLabel
                            tooltip_desc={tooptip_word.thumbnail_image_url}
                            label={'썸네일'}
                            is_required={true}
                          />
                        </div>
                        <ImageUploader
                          name='thumbnail_image_url'
                          withPreview={true}
                          min_width={375}
                          thumbnail_width={375}
                          img_type={'notice'}
                          setPropsState={setNotice}
                          img_url={notice.thumbnail_image_url}
                          title={notice.title}
                          isEdit={isEdit}
                          imgExtension={['.jpg', '.gif', '.png', '.gif', '.svg']}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col md='2 mb-3'>
                          <CustomSelectInput
                            name={'notice_category_id'}
                            disabled={!isEdit}
                            value={notice.notice_category_id}
                            label={'카테고리'}
                            is_required={true}
                            onChange={onCategoryChange}
                            options={notice_category_set}
                          />
                        </Col>
                        <Col md='2 mb-3'>
                          <CustomSelectInput
                            name={'notice_category_id'}
                            disabled={!isEdit}
                            value={notice.notice_category_id}
                            label={'카테고리'}
                            is_required={true}
                            onChange={onCategoryChange}
                            options={notice_category_set}
                          />
                        </Col>
                        <Col md='2 mb-3'>
                          <CustomSelectInput
                            name={'is_deleted'}
                            disabled={!isEdit}
                            value={notice.is_deleted}
                            label={'삭제 여부'}
                            is_required={true}
                            onChange={onInputChange}
                            options={[
                              {
                                id: 0,
                                name: '아니요',
                              },
                              {
                                id: 1,
                                name: '네',
                              },
                            ]}
                          />
                        </Col>
                        <Col md='6 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'title'}
                            disabled={!isEdit}
                            value={notice.title || ''}
                            is_required={true}
                            label={'제목'}
                            onChange={onInputChange}
                          />
                        </Col>
                      </Row>
                      {parseInt(notice.notice_category_id) === 2 && (
                        <Row>
                          <Col md='6 mb-3'>
                            <Row>
                              <Col md={12}>
                                <Label htmlFor='start_time'>
                                  시작일 (입력 없는 경우 바로 시작됨)
                                </Label>
                                <DatePicker
                                  autoComplete='off'
                                  className='form-control digits'
                                  name='start_time'
                                  selectsStart
                                  disabled={!isEdit}
                                  onChange={(e) => {
                                    onDateTimeChange(e, 'start_time');
                                  }}
                                  locale='ko'
                                  selected={notice.start_time ? new Date(notice.start_time) : null}
                                  startDate={notice.start_time ? new Date(notice.start_time) : null}
                                  endDate={notice.expire_time ? new Date(notice.expire_time) : null}
                                  maxDate={notice.expire_time ? new Date(notice.expire_time) : null}
                                  timeFormat='HH:mm:ss'
                                  showTimeSelect
                                  dateFormat='yyyy-MM-dd HH:mm:ss'
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md='6 mb-3'>
                            <Row>
                              <Col md={12}>
                                <Label htmlFor='expire_time'>
                                  종료일 (입력 없는 경우 계속 지속됨)
                                </Label>
                                <DatePicker
                                  autoComplete='off'
                                  className='form-control digits'
                                  name='expire_time'
                                  selectsEnd
                                  disabled={!isEdit}
                                  onChange={(e) => {
                                    onDateTimeChange(e, 'expire_time');
                                  }}
                                  locale='ko'
                                  selected={
                                    notice.expire_time ? new Date(notice.expire_time) : null
                                  }
                                  startDate={notice.start_time ? new Date(notice.start_time) : null}
                                  endDate={notice.expire_time ? new Date(notice.expire_time) : null}
                                  minDate={notice.start_time ? new Date(notice.start_time) : null}
                                  timeFormat='HH:mm:ss'
                                  showTimeSelect
                                  dateFormat='yyyy-MM-dd HH:mm:ss'
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md='12 mb-3'>
                          <div className='editor-img-100'>
                            <TextEditor
                              html_data={notice.content || ''}
                              onChange={(value) => {
                                setNotice((prevState) => ({ ...prevState, content: value }));
                              }}
                              disabled={!isEdit}
                              img_type={'notice'}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NoticeDetail;
