import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CouponModel from '../../model/CouponModel';
import Loading from '../_common/Loading';

import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label,
} from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import Form from 'reactstrap/es/Form';

import SweetAlert from 'sweetalert2';
import CommonModel from '../../model/CommonModel';
import moment from 'moment';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import CustomTypeahead from '../_common/component/CustomTypeahead';
import { createUuidv4, errMessageOccur } from '../../util/common';
import CustomLabel from '../_common/component/CustomLabel';
import DatePicker from 'react-datepicker';

const objErrMsg = {
  title: '제목',
  coupon_event_text: '이벤트코드',
  is_enabled: '활성화여부',
  can_un_limited_use: '일회성여부',
  can_use_at_extend_course: '강의연장유무',
  is_multiple_participate: '참여방식설정',
  expire_set_option_id: '쿠폰만료일생성방식',
  currency_code_id: '화폐종류',
  discounts: '할인금액',
};

const CouponEventDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.coupon_event_id));
  const [isLoading, setIsLoading] = useState(false);

  const [currencyCodeSet, setCurrencyCodeSet] = useState([]);
  const [expireSetOptionSet, setExpireSetOptionSet] = useState([]);
  const [onlyBearuCourseSet, setOnlyBearuCourseSet] = useState([]);
  const [couponEventAllowProductTypeSelect, setCouponEventAllowProductTypeSelect] = useState([]);
  const [productTypeSet, setProductTypeSet] = useState([]);

  const [courseSelect, setCourseSelect] = useState([]);

  const [courseSet, setCourseSet] = useState([]);

  const [couponEvent, setCouponEvent] = useState({
    id: '',
    title: '',
    description: '',
    management_description: '',
    coupon_event_text: '',
    discounts: '',
    currency_code_id: '',
    expire_set_option_id: 1,
    is_enabled: 0,
    can_un_limited_use: 0,
    can_use_at_extend_course: 1,
    is_multiple_participate: 0,
    max_issue_count: 0,
    start_time: '',
    expire_time: '',
    expire_after_day: '',
    expire_at_time: '',
    coupon_event_allow_product_type_set: [],
    coupon_event_course_set: [],
  });

  const onInputChange = (e) => {
    setCouponEvent({ ...couponEvent, [e.target.name]: e.target.value });
  };

  const onExpireOptionChange = (e) => {
    onInputChange(e);
    setCouponEvent((prevState) => ({
      ...prevState,
      expire_at_time: '',
      expire_after_day: '',
    }));
  };

  const onExpireAfterDayInputChange = (e) => {
    setCouponEvent({ ...couponEvent, expire_at_time: '', expire_after_day: e.target.value });
  };

  const onDateTimeChange = (e, name) => {
    const date = moment(e);
    if (date.year() < 10000) {
      setCouponEvent({ ...couponEvent, [name]: e && date.format('YYYY-MM-DDTHH:mm:ss') });
    }
  };

  const onExpireDateTimeChange = (e) => {
    setCouponEvent({
      ...couponEvent,
      expire_time: e && moment(e).format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  const onInputSubmit = async (e) => {
    const resultCouponEventAllowProductTypeSelect = couponEventAllowProductTypeSelect
      .filter((e) => e.id !== 4)
      .map((e) => e.id);
    const resultCourseSelect = courseSelect.map((e) => e.id);

    const errMsg = errMessageOccur(couponEvent, objErrMsg, { use: false });

    if (Number(couponEvent.expire_set_option_id) === 1 && !couponEvent.expire_after_day) {
      errMsg.push("'발급일로부터 추정'");
    } else if (Number(couponEvent.expire_set_option_id) === 2 && !couponEvent.expire_at_time) {
      errMsg.push("'강제 만료일 지정'");
    }
    if (errMsg.length) {
      errMsg.push('입력해주세요.');
    }

    if (!errMsg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      if (couponEvent.id) {
        await CouponModel.putCouponEventDetail({
          ...couponEvent,
          result_coupon_event_allow_product_type_select: resultCouponEventAllowProductTypeSelect,
          result_course_select: resultCourseSelect,
        }).then(() => {
          setIsLoading(false);
          e.target.disabled = false;
          setIsEdit(false);
          requestDetail();
        });
      } else {
        CouponModel.checkCouponEventText(couponEvent.coupon_event_text).then(({ is_valid }) => {
          if (is_valid) {
            CouponModel.postCouponEvent({
              ...couponEvent,
              result_coupon_event_allow_product_type_select:
                resultCouponEventAllowProductTypeSelect,
              result_course_select: resultCourseSelect,
            }).then(({ coupon_event_id }) => {
              props.history.replace('/coupon-event/' + coupon_event_id);
              window.location.reload();
            });
          } else {
            SweetAlert.fire({
              title: '저장중 문제를 발견했습니다.',
              text: "'이벤트텍스트'가 중복된 값이 존재합니다.",
            });
            setIsLoading(false);
          }
        });
      }
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: errMsg.join('\n'),
      });
    }
  };

  const requestDetail = () => {
    CouponModel.getCouponEventDetail(Number(props.match.params.coupon_event_id)).then(
      ({ coupon_event }) => {
        if (coupon_event) {
          setCouponEvent(coupon_event);

          // 선택되어있는 강의
          const _courseSelect = coupon_event.coupon_event_course_set.map((e) => {
            let obj = {};
            obj['id'] = e.course_id;
            obj['title'] = e.title;
            return obj;
          });
          setCourseSelect(_courseSelect);

          const _couponEventAllowProductTypeSelect =
            coupon_event.coupon_event_allow_product_type_set?.length > 0
              ? coupon_event.coupon_event_allow_product_type_set.map((e) => {
                  let obj = {};
                  obj['id'] = e.product_type_id;
                  obj['name'] = e.name;
                  obj['description'] = e.description;
                  return obj;
                })
              : [];

          setCouponEventAllowProductTypeSelect(_couponEventAllowProductTypeSelect);
        }
      },
    );
  };

  const onExprieDateTimeSelect = (e) => {
    const {
      target: { value },
    } = e;

    let expire_time = moment();

    if (value === '0') {
      expire_time = '';
    } else if (value === '1') {
      expire_time = expire_time.add(3, 'months');
    } else if (value === '2') {
      expire_time = expire_time.add(6, 'months');
    } else if (value === '3') {
      expire_time = expire_time.add(12, 'months');
    }

    setCouponEvent({
      ...couponEvent,
      expire_after_day: '',
      expire_at_time: expire_time && expire_time.format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'currency_code_set' }).then(({ code_set }) => {
      setCurrencyCodeSet(code_set);

      if (!Number(props.match.params.coupon_event_id)) {
        setCouponEvent((prevState) => ({ ...prevState, currency_code_id: code_set[0]?.id }));
      }
    });

    CommonModel.staticCode({ code_name: 'expire_set_option_set' }).then(({ code_set }) => {
      setExpireSetOptionSet(code_set);

      if (!Number(props.match.params.coupon_event_id)) {
        setCouponEvent((prevState) => ({ ...prevState, expire_set_option_id: code_set[0]?.id }));
      }
    });

    CommonModel.getSearch('accessible_course_price_displaying').then((data) => {
      const new_data = data.map(({ course_id: id, combine_name: title }) => {
        return { id, title };
      });
      setCourseSet(new_data);
    });

    CommonModel.getSearch('only_bearu_course').then((data) => {
      const new_data = data.map(({ id: id, combine_name: title }) => {
        return { id, title };
      });
      setOnlyBearuCourseSet(new_data);
    });

    CommonModel.staticCode({ code_name: 'product_type_set' }).then(({ code_set }) => {
      setProductTypeSet([...code_set]);
    });

    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent='이벤트 쿠폰'
        title={`이벤트 쿠폰 ${props.match.params.coupon_event_id === '0' ? '생성' : '정보'}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form onSubmit={onInputSubmit}>
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`이벤트 쿠폰 ${
                        props.match.params.coupon_event_id === '0' ? '생성' : '정보'
                      }`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            onClick={onInputSubmit}
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      <Button
                        className='btn-pill'
                        color='primary'
                        style={{ marginRight: '8px' }}
                        onClick={() => {
                          // 생성(0)일 경우 수정(0 이상)일 경우
                          if (!!Number(props.match.params.coupon_event_id)) {
                            // 초기화
                            if (isEdit) {
                              requestDetail();
                            }
                            setIsEdit(!isEdit);
                          } else {
                            props.history.push('/coupon-event');
                          }
                        }}
                      >
                        {isEdit ? '취소' : '편집'}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={7} style={{ borderRight: '2px solid #F5F5F5' }}>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'title'}
                            disabled={!isEdit}
                            style={{ resize: 'none' }}
                            value={couponEvent.title || ''}
                            is_required={true}
                            label={'제목'}
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 128;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'description'}
                            disabled={!isEdit}
                            value={couponEvent.description || ''}
                            label={'설명'}
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 128;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'management_description'}
                            disabled={!isEdit}
                            value={couponEvent.management_description || ''}
                            label={'관리용 설명'}
                            onChange={onInputChange}
                            validator={(value) => {
                              return value.length <= 12;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='12'>
                          <CustomLabel
                            is_required={true}
                            label={'이벤트코드'}
                            tooltip_desc={'이벤트코드는 한번 생성하면 변경 할 수 없습니다.'}
                          />
                          <FormGroup>
                            <InputGroup>
                              <Input
                                type={'text'}
                                name={'coupon_event_text'}
                                disabled={!!Number(props.match.params.coupon_event_id)}
                                value={couponEvent.coupon_event_text || ''}
                                onChange={(e) => {
                                  if (e.target.value.length <= 36) {
                                    onInputChange(e);
                                  }
                                }}
                                placeholder={'이벤트텍스트는 중복 될 수 없습니다. (최대 36자)'}
                              />
                              <InputGroupAddon
                                addonType='append'
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  if (!!!Number(props.match.params.coupon_event_id)) {
                                    setCouponEvent((prevState) => ({
                                      ...prevState,
                                      coupon_event_text: createUuidv4(),
                                    }));
                                  }
                                }}
                              >
                                <InputGroupText>
                                  <i className='icofont icofont-random mr-2' />
                                  UUID v4 생성
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={'6 mb-3'}
                          style={{ borderRight: '2px solid #F5F5F5', alignSelf: 'center' }}
                        >
                          <Row>
                            <Col md={'12 mb-3'}>
                              <CustomSelectInput
                                name={'is_enabled'}
                                disabled={!!!Number(props.match.params.coupon_event_id) || !isEdit}
                                value={couponEvent.is_enabled}
                                label={'활성화여부'}
                                tooltip_desc={
                                  '비활성화 하는경우 시작일, 종료일 상관없이 이벤트가 비활성화됨. (저장후 활성화할 수 있습니다)'
                                }
                                is_required={true}
                                options={[
                                  { id: 0, name: '비활성화' },
                                  { id: 1, name: '활성화' },
                                ]}
                                onChange={onInputChange}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={'12 mb-3'}>
                              <CustomSelectInput
                                name={'can_un_limited_use'}
                                disabled={!isEdit}
                                value={couponEvent.can_un_limited_use}
                                label={'일회성여부'}
                                tooltip_desc={
                                  '발급받은 쿠폰으로 유저가 여러 강의 구매에 사용할 수 있는지 여부\n(예를들어 일회성인 경우 구매시에 쿠폰이 사라짐, 제한없음의 경우 쿠폰이 만료될때까지 계속 사용가능).'
                                }
                                is_required={true}
                                options={[
                                  { id: 0, name: '일회성' },
                                  { id: 1, name: '제한없음' },
                                ]}
                                onChange={onInputChange}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={'12 mb-3'}>
                              <CustomSelectInput
                                name={'is_multiple_participate'}
                                disabled={!isEdit}
                                value={couponEvent.is_multiple_participate}
                                label={'참여방식 설정'}
                                tooltip_desc={
                                  '한번만 참여가능 : 특정 조건을 만족할때 첫 한번만 쿠폰을 제공함 (기본옵션).\n' +
                                  '여러번 참여가능 : 특정 조건을 만족할때마다 쿠폰을 제공함.'
                                }
                                is_required={true}
                                options={[
                                  { id: 0, name: '한번만 참여가능' },
                                  { id: 1, name: '여러번 참여가능' },
                                ]}
                                onChange={onInputChange}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={'6 mb-3'}>
                          <Row>
                            <Col md={'12 mb-3'}>
                              <CustomInput
                                type={'text'}
                                name={'max_issue_count'}
                                disabled={!!Number(props.match.params.coupon_event_id)}
                                value={couponEvent.max_issue_count || ''}
                                label={'쿠폰발급 최대개수'}
                                tooltip_desc={
                                  '해당 이벤트로 쿠폰이 최대 발행될수 있는 개수입니다. (입력 없거나 0인 경우 무제한)'
                                }
                                is_required={true}
                                validator={(value) => {
                                  const regexp = /^[0-9\b]*$/;
                                  return regexp.test(value);
                                }}
                                placeholder={'입력 없거나 0인 경우 무제한'}
                                onChange={onInputChange}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={'12 mb-3'}>
                              <CustomInput
                                type={'text'}
                                name={'issue_count'}
                                disabled={true}
                                value={couponEvent.issue_count || ''}
                                label={'발급된 개수'}
                                tooltip_desc={'해당 쿠폰이 현재까지 발급된 개수입니다.'}
                                is_required={true}
                                onChange={onInputChange}
                              />
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            {/*state에 따라 바꿔질 것임*/}
                            <Col md={'12 mb-3'}>
                              <CustomSelectInput
                                name={'expire_set_option_id'}
                                disabled={!isEdit}
                                value={couponEvent.expire_set_option_id}
                                label={'쿠폰 만료일 생성방식'}
                                is_required={true}
                                sub_option_type={'description'}
                                options={expireSetOptionSet}
                                onChange={(e) => {
                                  onExpireOptionChange(e);
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            {/*state에 따라 바꿔질 것임*/}
                            <Col md={'12 mb-3'}>
                              {couponEvent.expire_set_option_id &&
                              Number(couponEvent.expire_set_option_id) === 1 ? (
                                <CustomInput
                                  type={'text'}
                                  name={'expire_after_day'}
                                  disabled={!isEdit}
                                  value={couponEvent.expire_after_day || ''}
                                  label={'발급일로부터 추정'}
                                  tooltip_desc={
                                    '예를들어 10을 입력하면, 발급일로부터 10일 뒤에 이벤트로 발급된 쿠폰이 만료됩니다.'
                                  }
                                  is_required={true}
                                  validator={(value) => {
                                    const regexp = /^[0-9\b]*$/;
                                    return regexp.test(value);
                                  }}
                                  onChange={onExpireAfterDayInputChange}
                                />
                              ) : (
                                <Row>
                                  <Col md='mb-3 ml-3'>
                                    <CustomLabel
                                      label={'강제 만료일 지정'}
                                      is_required={true}
                                      tooltip_desc={'입력 없는 경우 만료없음'}
                                    />
                                    <DatePicker
                                      autoComplete='off'
                                      className='form-control digits mb-3'
                                      name='expire_at_time'
                                      disabled={!isEdit}
                                      onChange={(e) => {
                                        onDateTimeChange(e, 'expire_at_time');
                                      }}
                                      locale='ko'
                                      selected={
                                        couponEvent.expire_at_time
                                          ? new Date(couponEvent.expire_at_time)
                                          : null
                                      }
                                      timeFormat='HH:mm:ss'
                                      showTimeSelect
                                      dateFormat='yyyy-MM-dd HH:mm:ss'
                                    />
                                  </Col>
                                  <Col md='6 mb-3'>
                                    <CustomSelectInput
                                      name={'date_range'}
                                      disabled={!isEdit}
                                      label={'날짜선택'}
                                      onChange={onExprieDateTimeSelect}
                                      options={[
                                        { id: 0, name: '------' },
                                        { id: 1, name: '3개월' },
                                        { id: 2, name: '6개월' },
                                        { id: 3, name: '1년' },
                                      ]}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={'6 mb-3'}>
                          <Row>
                            <Col md={12}>
                              <Label htmlFor='start_time'>
                                시작일 (입력 없는 경우 바로 시작됨)
                              </Label>
                              <DatePicker
                                autoComplete='off'
                                className='form-control digits'
                                name='start_time'
                                selectsStart
                                disabled={!isEdit}
                                onChange={(e) => {
                                  onDateTimeChange(e, 'start_time');
                                }}
                                locale='ko'
                                selected={
                                  couponEvent.start_time ? new Date(couponEvent.start_time) : null
                                }
                                startDate={
                                  couponEvent.start_time ? new Date(couponEvent.start_time) : null
                                }
                                endDate={
                                  couponEvent.expire_time ? new Date(couponEvent.expire_time) : null
                                }
                                maxDate={
                                  couponEvent.expire_time ? new Date(couponEvent.expire_time) : null
                                }
                                showTimeSelect
                                dateFormat='yyyy-MM-dd HH:mm:ss'
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={'6 mb-3'}>
                          <Row>
                            <Col md={12}>
                              <Label htmlFor='expire_time'>
                                종료일 (입력 없는 경우 계속 지속됨)
                              </Label>
                              <DatePicker
                                autoComplete='off'
                                className='form-control digits'
                                name='expire_time'
                                selectsEnd
                                disabled={!isEdit}
                                onChange={onExpireDateTimeChange}
                                locale='ko'
                                selected={
                                  couponEvent.expire_time ? new Date(couponEvent.expire_time) : null
                                }
                                startDate={
                                  couponEvent.start_time ? new Date(couponEvent.start_time) : null
                                }
                                endDate={
                                  couponEvent.expire_time ? new Date(couponEvent.expire_time) : null
                                }
                                minDate={
                                  couponEvent.start_time ? new Date(couponEvent.start_time) : null
                                }
                                showTimeSelect
                                dateFormat='yyyy-MM-dd HH:mm:ss'
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='3 mb-3'>
                          <CustomSelectInput
                            name={'currency_code_id'}
                            disabled={!isEdit}
                            value={couponEvent.currency_code_id || ''}
                            label={'화폐종류'}
                            is_required={true}
                            onChange={onInputChange}
                            options={currencyCodeSet}
                            sub_option_type={'description'}
                          />
                        </Col>
                        <Col md='3 mb-3'>
                          <CustomInput
                            type={'text'}
                            name={'discounts'}
                            disabled={!isEdit}
                            value={couponEvent.discounts || ''}
                            label={'할인금액'}
                            tooltip_desc={'해당 가격만큼 유저 구매액에서 차감됩니다.'}
                            is_required={true}
                            onChange={onInputChange}
                            validator={(value) => {
                              const regexp = /^[0-9\b]*$/;
                              return regexp.test(value);
                            }}
                            placeholder={'할인금액'}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <Col md='8 mb-3'>
                          <CustomTypeahead
                            disabled={!!Number(props.match.params.coupon_event_id)}
                            multiple={true}
                            label={'사용가능처 지정'}
                            tooltip_desc={
                              '사용가능처를 지정하지 않으면 모든 상품 구매에 이 쿠폰이 사용 가능하게 됩니다.\n사용가능처를 지정하면 특정 상품 구매에만 사용 가능하도록 설정됩니다.\n' +
                              '본 설정은 지정한 "대상 강의들"(또는 이벤트 해당강의 등) 옵션보다 먼저 사용됩니다.'
                            }
                            onChange={(e) => {
                              // 추가한 정보 알아오기...
                              let selected;

                              // 차집합
                              if (couponEventAllowProductTypeSelect.length < e.length) {
                                selected = e.filter(
                                  (value) =>
                                    !couponEventAllowProductTypeSelect
                                      .map((val) => val.id)
                                      .includes(value.id),
                                )[0];
                              }

                              setCourseSelect([]);

                              if (
                                e.some((el) => el.name === '커스텀선택') &&
                                e.some((el) => el.name === '베어유 강의')
                              ) {
                                const customIndex = e.findIndex(
                                  (item) => item.name === '커스텀선택',
                                );
                                const bearLectureIndex = e.findIndex(
                                  (item) => item.name === '베어유 강의',
                                );
                                const biggerIndex = Math.max(customIndex, bearLectureIndex);
                                const result = [e[biggerIndex]];
                                setCouponEventAllowProductTypeSelect(result);
                                if (e[biggerIndex].name === '베어유 강의') {
                                  setCourseSelect(onlyBearuCourseSet);
                                }
                              } else {
                                if (
                                  e.some((el) => el.name === '커스텀선택') ||
                                  e.some((el) => el.name === '베어유 강의')
                                ) {
                                  setCouponEventAllowProductTypeSelect([selected]);
                                  if (selected.name === '베어유 강의') {
                                    setCourseSelect(onlyBearuCourseSet);
                                  }
                                }
                              }
                            }}
                            selected={couponEventAllowProductTypeSelect}
                            selectedHandler={setCouponEventAllowProductTypeSelect}
                            labelKey={'name'}
                            customToken={(target) => {
                              return `${target.name} : ${target.description}`;
                            }}
                            options={productTypeSet}
                          />
                        </Col>
                        <Col>
                          <CustomSelectInput
                            name={'can_use_at_extend_course'}
                            disabled={!isEdit}
                            value={couponEvent.can_use_at_extend_course || ''}
                            label={'강의연장유무'}
                            tooltip_desc={"'강의 연장하기'에서 해당 쿠폰을 사용 못하도록 설정"}
                            is_required={true}
                            onChange={onInputChange}
                            options={[
                              {
                                id: 0,
                                name: '불가',
                              },
                              {
                                id: 1,
                                name: '가능',
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                      {['커스텀선택', '베어유 강의'].map((type) => {
                        const element = couponEventAllowProductTypeSelect.find(
                          (element) => element?.name === type,
                        );

                        return (
                          element && (
                            <Row key={type}>
                              <Col md='12 mb-3'>
                                <CustomTypeahead
                                  disabled={!!Number(props.match.params.coupon_event_id)}
                                  multiple={true}
                                  label={'이벤트 해당강의'}
                                  tooltip_desc={
                                    '입력 항목이 없으면 자동으로 모든 강의를 구매할 수 있도록 설정됩니다.'
                                  }
                                  selected={courseSelect}
                                  selectedHandler={setCourseSelect}
                                  onTokenClick={(target) => {
                                    window.open('/course/' + target.id, '_blank');
                                  }}
                                  labelKey={'title'}
                                  customToken={(target) => `${target.title}`}
                                  options={courseSet}
                                />
                              </Col>
                            </Row>
                          )
                        );
                      })}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CouponEventDetail;
