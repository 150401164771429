import React, { useEffect, useState } from 'react';
import { Button, Col } from 'reactstrap';
import {
  BROADCAST_EDITABLE,
  BROADCAST_ON_FORM_SUBMIT,
  useApplicationContext,
} from '../../../context/ApplicationContextProvider';

const EditButton = (props) => {
  const { broadcast } = useApplicationContext();

  // state
  const [is_editable, setEditable] = useState(false);
  const [disable_input, setDisableInput] = useState(true);
  const [is_cancel_button_click, setCancelButtonClick] = useState(false);

  useEffect(() => {
    broadcast.send(BROADCAST_EDITABLE, {
      disable_input,
      is_cancel_button_click,
    });
  }, [disable_input, is_cancel_button_click, broadcast]);

  useEffect(() => {
    const unlisten = broadcast.listen([BROADCAST_ON_FORM_SUBMIT], ({ value }) => {
      setEditable(value.is_editable);
      setDisableInput(value.disable_input);
    });

    return () => {
      broadcast.unlisten(unlisten);
    };
  }, [broadcast]);

  return (
    <Col className='col text-right'>
      {is_editable ? (
        <>
          <Button
            className='btn-pill btn-air-primary'
            color='primary'
            style={{ padding: '5px 20px 5px 20px', marginRight: '.7em' }}
            onClick={() => {
              props.onSubmit();
            }}
          >
            저장
          </Button>
          <Button
            className='btn-pill btn-air-danger'
            color='danger'
            style={{ padding: '5px 20px 5px 20px' }}
            onClick={() => {
              setEditable(false);
              setDisableInput(true);
              setCancelButtonClick(true);
            }}
          >
            취소
          </Button>
        </>
      ) : (
        <Button
          className='btn-pill btn-air-dark'
          color='dark'
          style={{ padding: '5px 20px 5px 20px' }}
          onClick={() => {
            setEditable(true);
            setDisableInput(false);
            setCancelButtonClick(false);
          }}
        >
          편집
        </Button>
      )}
    </Col>
  );
};

export default EditButton;
