import axios from 'axios';

class ProductModel {
  async getProduct(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/product/package', {
      params: filter,
    });
    return data;
  }

  async postProduct(product) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/product/package', product);
    return data;
  }

  async getProductDetail(product_id) {
    if (product_id) {
      const {
        data: { data },
      } = await axios.get(
        process.env.REACT_APP_API_URL + '/cms-api/v1/product/package/' + product_id,
      );
      return data;
    } else {
      return { package: false };
    }
  }

  async putProductDetail(product) {
    const {
      data: { data },
    } = await axios.put(
      process.env.REACT_APP_API_URL + '/cms-api/v1/product/package/' + product.id,
      product,
    );
    return data;
  }

  async getKit(filter) {
    const {
      data: { data },
    } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/product/kit', {
      params: filter,
    });
    return data;
  }

  async postKit(kit) {
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_API_URL + '/cms-api/v1/product/kit', kit);
    return data;
  }

  async getKitDetail(kit_id) {
    if (kit_id) {
      const {
        data: { data },
      } = await axios.get(process.env.REACT_APP_API_URL + '/cms-api/v1/product/kit/' + kit_id);
      return data;
    } else {
      return { kit: false };
    }
  }

  async putKitDetail(kit) {
    const {
      data: { data },
    } = await axios.put(process.env.REACT_APP_API_URL + '/cms-api/v1/product/kit/' + kit.id, kit);
    return data;
  }
}

export default new ProductModel();
