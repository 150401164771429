const content = {
  request: {
    request_error_title: '요청하신 작업에 실패했습니다',
    request_error_desc: '문제가 지속되는 경우 관리자에게 문의해주세요',

    request_to_manager: '관리자에게 문의해주세요',
  },
  login: {
    require_login: '로그인이 필요한 서비스입니다',
    login_error: '로그인중 에러가 발생했습니다',
  },
  common: {
    confirm_resist: '등록하시겠습니까?',
    confirm_button_resist: '등록하기',
    success_resist_message_common: '정상적으로 등록되었습니다',

    confirm_button_remove: '제거하기',
    success_remove_message: '정상적으로 제거되었습니다',

    confirm_change: '변경하시겠습니까?',
    confirm_button_change: '변경하기',
    success_change_message: '정상적으로 변경되었습니다',
    fail_change_message: '변경사항이 없습니다',

    cancel_button_resist: '취소하기',
    success_save_button: '등록완료',
    confirm_button_common: '확인',
    cancel_button_common: '돌아가기',

    confirm_button_initialization: '초기화하기',

    confirm_button_delete_account: '계정삭제',
    success_delete_message: '정상적으로 삭제되었습니다',
  },
  course: {
    confirm_resist_course: '선택하신 강의를 등록하시겠습니까?',
    did_not_select_course: '선택된 강의가 없습니다',

    confirm_change_date: '변경된 기간으로 저장하시겠습니까?',
    did_not_change_date: '기간이 변경되지 않았습니다',

    already_exist_course: '이미 등록된 강의입니다',

    did_not_exist_course: '등록된 강의가 없습니다',

    error_resist_course: '강의 등록 중 에러가 발생했습니다',
  },
  package: {
    error_resist_package: '패키지 등록 중 에러가 발생했습니다',
  },
  company: {
    check_manager_account: '기업관리자 계정을 확인해주세요',
    need_check_manager_account: '한명 이상의 관리자계정을 선택해주세요',
    need_fill_company_account: '기업관리자 입력란이 비었거나 존재하지 않는 계정입니다',

    error_company_account_more_than_one: '기업관리자 계정이 둘 이상일때만 제거할 수 있습니다',
    need_add_company_manager: '현재 관리자 계정이 하나라면 먼저 관리자 계정을 추가해주세요',

    can_not_remove_all_manager: '모든 계정을 제거할 수는 없습니다',
    need_exist_more_than_one_manager: '하나 이상의 계정을 남겨주세요',

    confirm_remove_company_manager: '선택하신 계정을 제거하시겠습니까?',

    need_fill_company_maximum_employee_count: '사용인원을 한명 이상 설정해주세요',

    did_not_exist_account: '존재하지 않는 계정입니다',
    need_resist_bearu: '베어유 회원가입 후 진행해주세요',

    check_permission_company_manager: '기업관리자 권한이 필요한 계정입니다',
    need_permission_company_manager: '권한등록은 사용자정보페이지에서 설정해주세요',

    need_assign_company_account_title: '해당 계정으로 등록된 기업이 없습니다',
    need_assign_company_account_desc: '기업등록은 B2B관리자에게 문의해주세요',

    need_fill_company_assignment: '상단의 기업정보를 마저 저장해 주세요',

    confirm_move_to_company_list: '기업목록 페이지로 돌아가시겠습니까?',
    confirm_button_move_to_company_list: '기업목록 페이지로 이동하기',
    cancel_button_move_to_company_list: '기업 추가 등록하기',

    confirm_button_move_to_account_detail: '사용자정보 페이지로 이동',

    did_not_exist_company: '현재 등록된 기업이 없습니다',
  },
  search: {
    did_not_match_company: '검색어와 일치하는 기업명 또는 담당자의 기업이 없습니다',
    did_not_match_manager: '검색어와 일치하는 계정이 없습니다',
  },
  csv: {
    need_check_csv: 'csv파일을 확인해주세요',

    did_not_upload_title: '업로드된 csv파일이 없습니다',
    did_not_upload_desc: 'csv파일을 업로드 한 뒤 다시 시도해주세요',

    have_no_data: '작성된 직원이 없습니다. csv파일을 확인해주세요',
  },
  employee: {
    check_one_more: '알맞게 입력하셨는지 한 번더 확인해주세요',

    need_fill_all_form: '모든 항목은 필수입력사항입니다',

    need_set_max_count_title: '사용인원을 설정하지 않았습니다',
    need_set_max_count_desc: '상단의 기업정보 중 사용인원을 설정해주세요',

    need_modify_max_count_desc:
      '사용인원이 초기화 되었거나 할당된 계정 수가 초과되었습니다. 사용인원 수를 수정해주세요',

    over_max_count_title: '할당된 계정 수를 초과했습니다',
    over_max_count_desc: '해당 csv파일을 수정해주세요',

    check_name_title: '이름을 확인해주세요',
    check_name_desc: '이름은 한글/영문/숫자로 2자 이상 작성해주세요',

    check_email_title: '이메일을 확인해주세요',
    check_email_desc: '이메일을 정확히 입력해주세요',

    check_password_title: '비밀번호를 확인해주세요',
    check_password_desc: '비밀번호는 4글자 이상으로 작성해주세요',

    check_birth_title: '생년월일를 확인해주세요',
    check_birth_desc: '생년월일은 YYYY-MM-DD 형식으로 작성해주세요',

    check_gender_title: '성별을 확인해주세요',
    check_gender_desc: '성별은 "남" 또는 "여" 로만 작성해주세요',

    check_phone_title: '휴대폰 번호를 확인해주세요',
    check_phone_desc: '휴대폰 번호는 010-0000-0000 형식으로 작성해주세요',

    did_not_exist_employee: '아직 등록된 계정이 없습니다',

    resist_except_error:
      '계정중복 또는 인원수 초과 등의 오류가 발생한 직원들은 등록되지 않았습니다',

    confirm_password_initialization: '정말로 해당 직원의 비밀번호를 초기화할까요?',

    confirm_delete_account: '정말 계정을 삭제하시겠습니까?',
  },
};
export default content;
