import React from 'react';
import Apexchart from 'react-apexcharts';

const AccountHorizontalBarChart = ({
  data_label_formatter,
  tooltip_y_formatter,
  x_categoryies,
  series,
}) => {
  return (
    <Apexchart
      options={{
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'right',
          offsetX: 30,
          formatter: data_label_formatter,
          style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: [
              function (opts) {
                return '#000000';
              },
            ],
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: tooltip_y_formatter,
          },
        },
        fill: {
          opacity: [0.95],
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: x_categoryies,
        },
        colors: ['#7e37d890'],
      }}
      series={series}
      type='bar'
      height={600}
    />
  );
};

export default AccountHorizontalBarChart;
