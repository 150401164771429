import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import { apiDatetimeReplace, copyStringToClipboard, initComma } from '../../util/common';
import { toast, ToastContainer } from 'react-toastify';

import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';

import PurchaseModel from '../../model/PurchaseModel';
import Loading from '../_common/Loading';
import InputGroup from 'reactstrap/es/InputGroup';
import InputGroupAddon from 'reactstrap/es/InputGroupAddon';
import { Link } from 'react-router-dom';
import RefundModal from '../_common/component/RefundModal';
import CourseExpireModal from '../_common/component/CourseExpireModal';

const PurchaseDetail = (props) => {
  const [purchase, purchaseSet] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [is_refund_modal, setIsRefundModal] = useState(false);
  const [is_expire_modal, setIsExpireModal] = useState(false);

  const onRefundModalButtonClick = () => {
    setIsRefundModal(true);
  };

  const onExpireModalButtonClick = () => {
    setIsExpireModal(true);
  };

  useEffect(() => {
    setIsLoading(true);
    PurchaseModel.getPurchaseDetail(props.match.params.purchaseinfo_id).then(({ purchaseinfo }) => {
      purchaseSet(purchaseinfo);
    });
    setIsLoading(false);
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent='판매 목록' title='판매 정보' />
      {is_refund_modal && (
        <RefundModal is_modal={is_refund_modal} setModal={setIsRefundModal} purchase={purchase} />
      )}
      {is_expire_modal && (
        <CourseExpireModal
          is_modal={is_expire_modal}
          setModal={setIsExpireModal}
          purchase={purchase}
        />
      )}
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>판매 정보</h3>
                <div style={isLoading ? { display: 'block' } : { display: 'none' }}>
                  <Loading isLoading={isLoading} />
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md='12 mb-3'>
                    <Row>
                      <Col className='text-right' md='12 mb-3'>
                        {[2, 3].includes(purchase.purchase_status_id) && (
                          <Button
                            className='btn btn-danger btn-pill m-2'
                            onClick={onRefundModalButtonClick}
                          >
                            환불
                          </Button>
                        )}
                        {purchase.purchase_status_id === 2 && (
                          <Button
                            className='btn btn-secondary btn-pill'
                            onClick={onExpireModalButtonClick}
                          >
                            회수
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md='3 mb-3'>
                        <Label htmlFor='merchant_uid'>주문번호</Label>
                        <InputGroup>
                          <Input
                            className='form-control'
                            type='text'
                            name='merchant_uid'
                            defaultValue={purchase.merchant_uid}
                            disabled={true}
                          />
                          <InputGroupAddon addonType='append'>
                            <Button
                              className='btn-light'
                              onClick={(e) => {
                                purchase.merchant_uid &&
                                  copyStringToClipboard(purchase.merchant_uid);
                                toast.success('복사 되었습니다.', {
                                  position: toast.POSITION.TOP_RIGHT,
                                });
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  purchase.merchant_uid &&
                                    copyStringToClipboard(purchase.merchant_uid);
                                  toast.success('복사 되었습니다.', {
                                    position: toast.POSITION.TOP_RIGHT,
                                  });
                                  e.preventDefault();
                                }
                              }}
                            >
                              <i className='icofont icofont-ui-copy'></i>
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                      <Col md='1 mb-3'>
                        <Label htmlFor='imp_uid'>imp_uid</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='imp_uid'
                          defaultValue={purchase.imp_uid}
                          disabled={true}
                        />
                      </Col>
                      <Col md='4 mb-3'>
                        <Label htmlFor='product_name'>제품명</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='product_name'
                          defaultValue={purchase.product_name}
                          disabled={true}
                        />
                      </Col>
                      <Col md='2 mb-3'>
                        <Label htmlFor='purchase_account__name'>구매자명</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='purchase_account__name'
                          defaultValue={purchase.purchase_account__name}
                          disabled={true}
                        />
                      </Col>
                      <Col md='2 mb-3'>
                        <Label htmlFor='purchase_type__name'>구매종류</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='purchase_type__name'
                          defaultValue={purchase.purchase_type__name}
                          disabled={true}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md='2 mb-3'>
                        <Label htmlFor='purchase_provider__name'>결제제공자</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='purchase_provider__name'
                          defaultValue={purchase.purchase_provider__name}
                          disabled={true}
                        />
                      </Col>
                      <Col md='2 mb-3'>
                        <Label htmlFor='purchase_status__name'>결제상태</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='purchase_status__name'
                          defaultValue={purchase.purchase_status__name}
                          disabled={true}
                        />
                      </Col>
                      <Col md='2 mb-3'>
                        <Label htmlFor='purchase_paymethod__name'>결제방법</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='purchase_paymethod__name'
                          defaultValue={purchase.purchase_paymethod__name}
                          disabled={true}
                        />
                      </Col>
                      <Col md='2 mb-3'>
                        <Label htmlFor='currency_code__name'>결제 화폐명</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='currency_code__name'
                          defaultValue={purchase.currency_code__name}
                          disabled={true}
                        />
                      </Col>
                      <Col md='4 mb-3'>
                        <Label htmlFor='receipt_url'>영수증</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='receipt_url'
                          defaultValue={purchase.receipt_url}
                          disabled={true}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md='3 mb-3'>
                        <Label htmlFor='product_price'>제품금액</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='product_price'
                          defaultValue={
                            purchase.product_price ? initComma(purchase.product_price) : ''
                          }
                          disabled={true}
                        />
                      </Col>
                      <Col md='3 mb-3'>
                        <Label htmlFor='purchased_price'>실결제금액</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='purchased_price'
                          defaultValue={
                            purchase.purchased_price ? initComma(purchase.purchased_price) : ''
                          }
                          disabled={true}
                        />
                      </Col>
                      <Col md='3 mb-3'>
                        <Label htmlFor='discounted_price'>할인금액</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='discounted_price'
                          defaultValue={
                            purchase.discounted_price ? initComma(purchase.discounted_price) : ''
                          }
                          disabled={true}
                        />
                      </Col>
                      <Col md='3 mb-3'>
                        <Label htmlFor='refund_price'>환불금액</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='refund_price'
                          defaultValue={
                            purchase.refund_price ? initComma(purchase.refund_price) : ''
                          }
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md='4 mb-3'>
                        <Label htmlFor='create_time'>주문일</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='create_time'
                          defaultValue={apiDatetimeReplace(purchase.create_time)}
                          disabled={true}
                        />
                      </Col>
                      <Col md='4 mb-3'>
                        <Label htmlFor='purchased_time'>결제일</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='purchased_time'
                          defaultValue={apiDatetimeReplace(purchase.purchased_time)}
                          disabled={true}
                        />
                      </Col>
                      <Col md='4 mb-3'>
                        <Label htmlFor='refund_time'>환불일</Label>
                        <Input
                          className='form-control'
                          type='text'
                          name='refund_time'
                          defaultValue={apiDatetimeReplace(purchase.refund_time)}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md='12 mb-3'>
                        <Label htmlFor='account_name'>키트구매정보</Label>
                        <div>
                          {purchase.purchasekitinfo_set && purchase.purchasekitinfo_set.length ? (
                            purchase.purchasekitinfo_set.map((purchaseinfo, index) => {
                              return (
                                <Link
                                  to={`/kit/${purchaseinfo.kit_info.id}`}
                                  className='btn btn-primary btn-pill m-2'
                                  key={index}
                                >
                                  {purchaseinfo.kit_info.title} ({purchaseinfo.ordered_count}개)
                                </Link>
                              );
                            })
                          ) : (
                            <Button className='btn-light'>존재하지않습니다</Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              <ToastContainer />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PurchaseDetail;
