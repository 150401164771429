import {
  STORED_COURSE_INFO,
  CLEAR_COURSE_INFO,
  SET_COURSE_INFO,
  SET_TAG,
  REMOVE_TAG,
  SET_KIT,
  SET_SUB_CATEGORY,
  SET_COURSE_BODY,
} from '../../action_type';

export const stored_course_info = (data) => ({
  type: STORED_COURSE_INFO,
  data,
});

export const clear_course_info = () => ({
  type: CLEAR_COURSE_INFO,
});

export const set_course_info = (name, value) => ({
  type: SET_COURSE_INFO,
  name,
  value,
});

export const set_tag = (value) => ({
  type: SET_TAG,
  value,
});

export const remove_tag = (index) => ({
  type: REMOVE_TAG,
  index,
});

export const set_kit = (kit_set) => ({
  type: SET_KIT,
  kit_set,
});

export const set_sub_category = (sub_category_set) => ({
  type: SET_SUB_CATEGORY,
  sub_category_set,
});

export const set_course_body = (name, value) => ({
  type: SET_COURSE_BODY,
  name,
  value,
});
