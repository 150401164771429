import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import CommonModel from '../../model/CommonModel';
import Loading from '../_common/Loading';

import { Card, CardHeader, Col, Container, Row, Button } from 'reactstrap';
import moment from 'moment';

import CardBody from 'reactstrap/es/CardBody';

import Form from 'reactstrap/es/Form';
import SweetAlert from 'sweetalert2';
import CustomInput from '../_common/component/CustomInput';
import CustomSelectInput from '../_common/component/CustomSelectInput';
import { errMessageOccur } from '../../util/common';
import PurchaseModel from '../../model/PurchaseModel';
import TextEditor from '../_common/component/TextEditor';
import CustomLabel from '../_common/component/CustomLabel';
import { Link } from 'react-router-dom';

const obj_err_msg = {
  title: '배송명',
  description: '배송설명',
  inventory_count: '재고 수',
  is_external_shipping: '외부배송 여부',
  is_private_mode: '비공개 모드',
  shipping_method_id: '배송방법',
  max_buy_count: '최대구매가능갯수',
  is_default_option: '기본값여부',
};

const ShippingDetail = (props) => {
  // 생성(0)일 경우 수정(0 이상)일 경우
  const [isEdit, setIsEdit] = useState(!Number(props.match.params.shipping_id));

  const [shipping_status_set, setUserKitShipStatusSet] = useState([]);
  const [shipper_set, setShipperSet] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [user_kit_ship, setUserKitShip] = useState({
    id: '',
    purchase_kit_info: {},
    shipping_status_id: '',
    shipper_id: '',
    shipping_number: '',
    digital_content_info_html: '',
    ordered_address: '',
    ordered_address_code: '',
  });

  const onInputChange = (e) => {
    setUserKitShip({ ...user_kit_ship, [e.target.name]: e.target.value });
  };

  const onInputSubmit = (e) => {
    e.preventDefault();

    const err_msg = errMessageOccur(user_kit_ship, obj_err_msg, { use: false });

    if (!err_msg.length) {
      setIsLoading(true);
      e.target.disabled = true;

      setUserKitShip((prevState) => ({
        ...prevState,
      }));

      PurchaseModel.putShippingDetail({
        ...user_kit_ship,
      }).then(() => {
        setIsLoading(false);
        e.target.disabled = false;
        setIsEdit(false);
        requestDetail();
      });
    } else {
      SweetAlert.fire({
        title: '저장중 문제를 발견했습니다.',
        text: err_msg.join('\n'),
      });
    }
  };

  const requestDetail = () => {
    PurchaseModel.getShippingDetail(Number(props.match.params.shipping_id)).then(
      ({ user_kit_ship }) => {
        setUserKitShip(user_kit_ship);
      },
    );
  };

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'shipping_status_set' }).then(({ code_set }) => {
      setUserKitShipStatusSet(code_set);
    });

    CommonModel.staticCode({ code_name: 'shipper_set' }).then(({ code_set }) => {
      setShipperSet(code_set);
    });

    requestDetail();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent='배송' title={`배송 정보`} />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form
              onSubmit={(e) => {
                onInputSubmit(e);
              }}
            >
              <Card>
                <CardHeader>
                  <Row style={{ marginBottom: '-20px' }}>
                    <Col>
                      <h4>{`배송 정보`}</h4>
                    </Col>
                    <Col sm={6} className='text-right'>
                      {isEdit && (
                        <>
                          <span style={{ display: isLoading ? 'block' : 'none' }}>
                            <Loading isLoading={isLoading} />
                          </span>
                          <Button
                            className='btn-pill'
                            color='info'
                            type='submit'
                            style={{ marginRight: '8px' }}
                          >
                            저장
                          </Button>
                        </>
                      )}
                      <Button
                        className='btn-pill'
                        color='primary'
                        style={{ marginRight: '8px' }}
                        onClick={() => {
                          // 생성(0)일 경우 수정(0 이상)일 경우
                          if (isEdit) {
                            requestDetail();
                          }
                          setIsEdit(!isEdit);
                        }}
                      >
                        {isEdit ? '취소' : '편집'}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md='3 mb-3'>
                      <CustomLabel label={'결제 주문번호'} />
                      <Link
                        className='form-control text-primary'
                        to={'/sale/' + user_kit_ship.purchase_kit_info?.purchase_info?.id}
                      >
                        {user_kit_ship.purchase_kit_info?.purchase_info?.merchant_uid}
                      </Link>
                    </Col>
                    <Col md='4 mb-3'>
                      <CustomLabel label={'키트명'} />
                      <Link
                        className='form-control text-primary'
                        to={'/kit/' + user_kit_ship.purchase_kit_info?.kit_info?.id}
                      >
                        {user_kit_ship.purchase_kit_info?.kit_info?.title}
                        <small className={'btn-sm btn-primary ml-3'}>
                          {user_kit_ship.purchase_kit_info?.ordered_count}개
                        </small>
                      </Link>
                    </Col>
                    <Col md='3 mb-3'>
                      <CustomLabel label={'주문자계정'} />
                      <Link
                        className='form-control text-primary'
                        to={
                          '/account/' + user_kit_ship.purchase_kit_info?.purchase_info?.account?.id
                        }
                      >
                        {user_kit_ship.purchase_kit_info?.purchase_info?.account?.account_name}
                      </Link>
                    </Col>
                    <Col md='2 mb-3'>
                      <CustomInput
                        type={'text'}
                        name={'shipping_number'}
                        disabled={true}
                        value={
                          user_kit_ship.purchase_kit_info?.purchase_info?.purchased_time
                            ? moment(
                                user_kit_ship.purchase_kit_info?.purchase_info?.purchased_time,
                              ).format('YYYY-MM-DD HH:mm:ss')
                            : ''
                        }
                        label={'결제일'}
                        onChange={onInputChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md='2 mb-3'>
                      <CustomSelectInput
                        name={'shipping_status_id'}
                        disabled={!isEdit}
                        value={user_kit_ship.shipping_status_id}
                        label={'배송 상태'}
                        onChange={onInputChange}
                        options={shipping_status_set}
                      />
                    </Col>
                    <Col md='2 mb-3'>
                      <CustomSelectInput
                        name={'shipper_id'}
                        disabled={!isEdit}
                        value={user_kit_ship.shipper_id || ''}
                        label={'택배사'}
                        onChange={onInputChange}
                        options={[{ id: '', name: '지정안됨' }, ...shipper_set]}
                      />
                    </Col>
                    <Col md='2 mb-3'>
                      <CustomInput
                        type={'text'}
                        name={'shipping_number'}
                        disabled={!isEdit}
                        value={user_kit_ship.shipping_number || ''}
                        label={'송장번호'}
                        onChange={onInputChange}
                      />
                    </Col>
                    <Col md='4 mb-3'>
                      <CustomInput
                        type={'text'}
                        name={'ordered_address'}
                        disabled={!isEdit}
                        value={user_kit_ship.ordered_address || ''}
                        label={'배송지'}
                        onChange={onInputChange}
                      />
                    </Col>
                    <Col md='2 mb-3'>
                      <CustomInput
                        type={'text'}
                        name={'ordered_address_code'}
                        disabled={!isEdit}
                        value={user_kit_ship.ordered_address_code || ''}
                        label={'배송지 우편번호'}
                        onChange={onInputChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12 mb-3'>
                      <CustomLabel label={'디지털콘텐츠 내용'} />
                      <TextEditor
                        id={Number(props.match.params.shipping_id)}
                        html_data={user_kit_ship.digital_content_info_html || ''}
                        onChange={(value) => {
                          setUserKitShip((prevState) => ({
                            ...prevState,
                            digital_content_info_html: value,
                          }));
                        }}
                        disabled={!isEdit}
                        img_type={'user_kit_ship'}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ShippingDetail;
