import React, { Fragment, useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { initComma, toQueryString } from '../../util/common';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';
import {
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from 'reactstrap';
import Loading from '../_common/Loading';
import CustomPagination from '../_common/CustomPagination';
import InvoiceModel from '../../model/InvoiceModel';

const MasterList = (props) => {
  const params = props.location.state || queryString.parse(props.location.search) || {};
  const [master_set, setMasterSet] = useState([]);
  const [master_count, setMasterCount] = useState(0);
  const default_filter = useRef({
    page: params.page ? parseInt(params.page) : 1,
    size: 20,
    search_text: params.search_text ? params.search_text : '',
    reply_status: params.reply_status ? params.reply_status : 1,
    order_key: params.order_key ? params.order_key : 'create_time',
    order_type: params.order_type ? params.order_type : 'desc',
  });
  const [filter_set, setFilterSet] = useState(default_filter.current);
  const [isLoading, setIsLoading] = useState(false);

  const initData = () => {
    setIsLoading(true);
    InvoiceModel.getMasterList({
      size: 20,
      ...params,
    }).then(({ master_set, master_count }) => {
      setMasterSet(master_set);
      setMasterCount(master_count);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    setFilterSet(
      Object.keys(params).length > 0
        ? {
            ...default_filter.current,
            ...params,
          }
        : default_filter.current,
    );

    initData();
  }, [props.location.search]);

  const onSubmit = () => {
    pushHistory({
      ...filter_set,
      page: 1,
      order_key: 'create_time',
      order_type: 'desc',
    });
  };

  const pushHistory = (params) => {
    const queryString = toQueryString(params);
    props.history.push(`?${queryString}`, params);
  };

  const onPageChange = (page) => {
    pushHistory({
      ...params,
      page: page,
    });
  };

  return (
    <Fragment>
      <Breadcrumb parent='정산' title='강사 목록' />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <Row style={{ marginBottom: '-20px' }}>
              <Col sm={7}>
                <p style={{ marginTop: '7px' }}>
                  총 마스터 : <span style={{ color: 'coral' }}>{initComma(master_count)}</span>명
                  <Loading isLoading={isLoading} />
                </p>
              </Col>
              <Col className='text-right'>
                <Col>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        className='form-control'
                        type='text'
                        name='search_text'
                        onChange={(e) => {
                          setFilterSet((prevState) => ({
                            ...prevState,
                            search_text: e.target.value,
                          }));
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            onSubmit(e);
                          }
                        }}
                        value={filter_set.search_text}
                        placeholder='강의명 또는 강사 정보를 입력하세요.'
                      />
                      <InputGroupAddon
                        addonType='append'
                        style={{ cursor: 'pointer' }}
                        onClick={onSubmit}
                      >
                        <InputGroupText>
                          <i className='icofont icofont-ui-search' />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Table>
              <thead>
                <tr style={{ textAlign: 'center' }}>
                  <th>ID</th>
                  <th>회원 ID</th>
                  <th>강사명</th>
                  <th>연락처</th>
                  <th>이메일</th>
                </tr>
              </thead>
              <tbody>
                {master_set
                  ? master_set.map((master, index) => {
                      return (
                        <tr
                          key={index}
                          style={{ cursor: 'pointer', textAlign: 'center', lineHeight: '4' }}
                          onClick={() => {
                            window.open('invoice/' + master.account_id, '_blank');
                          }}
                        >
                          <td>{master.account_id}</td>
                          <td className='mobile-none' style={{ fontWeight: '600' }}>
                            {master.account_name}
                          </td>
                          <td className='mobile-none'>{master.name}</td>
                          <td className='mobile-none'>{master.phone}</td>
                          <td className='mobile-none'>{master.email}</td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </div>
          <div className='m-auto'>
            <CustomPagination
              current_page={params.page ? Number(params.page) : 1}
              max_page={Math.ceil(params.size ? master_count / params.size : master_count / 20)}
              onPageChange={onPageChange}
            />
          </div>
        </Card>
      </Container>
    </Fragment>
  );
};

export default MasterList;
