import { STORED_AUTH_INFO } from '../action_type';

const INIT_STATE = null;

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STORED_AUTH_INFO:
      return action.data;

    default:
      return state;
  }
};
