import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../_common/breadcrumb/Breadcrumb';

import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

import DataTable from 'react-data-table-component';
import AccountModel from '../../model/AccountModel';
import { Tooltip } from 'react-tippy';
import moment from 'moment';

const customStyles = {
  headCells: {
    style: {
      height: '90px',
    },
  },
};

const UserCourseSubjectViewLog = (props) => {
  const [viewlog_set, setViewLogSet] = useState([]);
  const [about_viewlog, setAboutViewLog] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    AccountModel.getCourseSubjectViewLog(props.match.params).then(
      ({ course_subject_view_log, account_name, course_short_title }) => {
        setViewLogSet(course_subject_view_log);
        setAboutViewLog(`[${course_short_title}] ${account_name}`);
        setIsLoading(false);
      },
    );
  }, []);

  const columns = [
    {
      name: '강의순서',
      selector: 'index_name',
      sortable: true,
      center: true,
      width: '150px',
      sortFunction: (row1, row2) =>
        ((e) => {
          return row1.id - row2.id;
        })(),
    },
    {
      name: '과목명',
      selector: 'title',
      sortable: true,
      center: true,
      width: '400px',
    },
    {
      name: (
        <>
          {'수강완료'}{' '}
          <Tooltip
            // options
            html={
              <div className='p-1 rounded bg-dark'>
                본 항목이 모두 'Y'로 설정되면 유저는 웹에서 수료증을 직접 수료증을 발급 받을 수
                있습니다.
              </div>
            }
            title=''
            position='top'
            trigger='mouseenter'
          >
            <i className='icofont icofont-question-circle' />
          </Tooltip>
        </>
      ),
      selector: 'survey',
      sortable: true,
      center: true,
      width: '200px',
      sortFunction: (row1, row2) =>
        ((e) => {
          let custom_row1 = 0;
          let custom_row2 = 0;
          if (row1.course_subjects_view_survey_log_set[0]?.is_viewed) {
            custom_row1 = row1.course_subjects_view_survey_log_set[0].is_viewed;
          }
          if (row2.course_subjects_view_survey_log_set[0]?.is_viewed) {
            custom_row2 = row2.course_subjects_view_survey_log_set[0].is_viewed;
          }
          return custom_row1 - custom_row2;
        })(),
      cell: (row) => <div>{row.course_subjects_view_survey_log_set[0]?.is_viewed ? 'Y' : ''}</div>,
    },
    {
      name: '수강완료시간',
      selector: 'course.short_title',
      sortable: true,
      center: true,
      width: '200px',
      sortFunction: (row1, row2) =>
        ((e) => {
          return moment(row1.course_subjects_view_survey_log_set[0]?.view_time).diff(
            moment(row2.course_subjects_view_survey_log_set[0]?.view_time),
            'day',
          );
        })(),
      cell: (row) => (
        <div>{row.course_subjects_view_survey_log_set[0]?.view_time.replace('T', ' ')}</div>
      ),
    },
    {
      name: '최초조회시간',
      selector: 'coursesubjectsviewlog__view_time',
      sortable: true,
      center: true,
      width: '200px',
      sortFunction: (row1, row2) =>
        ((e) => {
          return moment(row1.course_subjects_view_log_set[0]?.view_time).diff(
            moment(row2.course_subjects_view_log_set[0]?.view_time),
            'day',
          );
        })(),
      cell: (row) => <div>{row.course_subjects_view_log_set[0]?.view_time.replace('T', ' ')}</div>,
    },
    {
      name: (
        <>
          {'최소재생시간'}{' '}
          <Tooltip
            // options
            html={
              <div className='p-1 rounded bg-dark'>
                본 값이 60초 이상인 경우 이용약관상의 수강완료/시작으로 간주합니다.
              </div>
            }
            title=''
            position='top'
            trigger='mouseenter'
          >
            <i className='icofont icofont-question-circle' />
          </Tooltip>
        </>
      ),
      selector: 'coursesubjectsviewlog__client_detected_seconds',
      sortable: true,
      center: true,
      sortFunction: (row1, row2) =>
        ((e) => {
          // 동일한 0 일 경우 0 초 표시만 맨 위에 있어서 0 보다 작게 설정하기 위함
          let custom_row1 = -1;
          let custom_row2 = -1;
          // 0.0001 초 같은 친구 때문에 최초 조회시간은 있지만, 최소재생 시간은 없을 경우
          if (
            row1.course_subjects_view_log_set[0] &&
            String(row1.course_subjects_view_log_set[0].client_detected_seconds)
          ) {
            custom_row1 = row1.course_subjects_view_log_set[0].client_detected_seconds;
          }
          if (
            row2.course_subjects_view_log_set[0] &&
            String(row2.course_subjects_view_log_set[0].client_detected_seconds)
          ) {
            custom_row2 = row2.course_subjects_view_log_set[0].client_detected_seconds;
          }
          return custom_row1 - custom_row2;
        })(),
      cell: (row) => (
        <div>
          {(() => {
            // 0.0001 초 같은 친구 때문에 최초 조회시간은 있지만, 최소재생 시간은 없을 경우
            if (
              row.course_subjects_view_log_set[0] &&
              String(row.course_subjects_view_log_set[0].client_detected_seconds)
            ) {
              return row.course_subjects_view_log_set[0].client_detected_seconds + ' 초';
            } else {
              return '';
            }
          })()}
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Breadcrumb parent='사용자정보' title='시청정보' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>{about_viewlog} 시청정보</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md='12 mb-3'>
                    <DataTable
                      noDataComponent={isLoading ? '잠시만 기다려주세요.' : '데이터가 없습니다.'}
                      keyField={'id'}
                      columns={columns}
                      data={viewlog_set}
                      striped={true}
                      center={true}
                      noHeader={true}
                      persistTableHead
                      customStyles={customStyles}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserCourseSubjectViewLog;
